import { Category } from '@pwa-concept/modules/graphql'
import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { Link } from '@pwa-onilab/ui/components/UI/Link'
import { classNames } from '@pwa-onilab/ui/helpers'
import { addSlashToEndUrl } from 'helpers'
import { Image } from '@pwa-onilab/ui/components/UI/Image'

import styles from './CategoryNavCard.module.scss'

interface CategoryNavCardProps {
    className?: string
    category?: Category
    active?: boolean
}

const CategoryNavCard = (
    {
        className,
        category,
        active = false,
    }: CategoryNavCardProps,
) => {
    if (!category) {
        return
    }

    return (
        <Link
            to={`/${addSlashToEndUrl(category.url)}`}
            className={classNames(styles.wrapper, active && styles.active, className)}
        >
            {
                category?.image && (
                    <MediaBreakpointUp name="lg">
                        <div className={styles.imageWrapper}>
                            <Image
                                className={styles.image}
                                src={category.image}
                            />
                        </div>
                    </MediaBreakpointUp>
                )
            }
            {
                category?.name && (
                    <div className={styles.title}>
                        {category.name}
                    </div>
                )
            }
        </Link>
    )
}

export default CategoryNavCard

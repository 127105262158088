import { useCartGenerateId } from '@pwa-concept/modules/cart'
import { useEffect } from 'react'

const useInitCartData = () => {
    const generateId = useCartGenerateId()

    useEffect(() => {
        generateId()
    }, [])
}

export default useInitCartData

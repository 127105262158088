import { Text } from 'domelementtype'

export const getChildrenTextRecursive = (node) => {
    const getTextFromChild = (child, acc) => {
        if (child.type === Text) {
            return acc.push(child)
        }

        if (child.children?.length) {
            child.children.forEach((childChildren) => {
                getTextFromChild(childChildren, acc)
            })
        }
    }

    const childrensWithText = node?.children?.reduce((acc, child) => {
        getTextFromChild(child, acc)
        return acc
    }, [])

    const text = childrensWithText?.filter?.(({ type }) => type === Text)?.map?.(({ data }) => data)?.join?.(' ')
    return text
}

import {
    REGEX_DIGITS,
    REGEX_LETTERS_LOWERCASE,
    REGEX_LETTERS_UPPERCASE,
    REGEX_NOT_LETTERS_AND_DIGIT,
} from '@pwa-onilab/ui/constants/patterns'

const getPasswordStrengthLevel = (password) => {
    const isPassContainDigit = +REGEX_DIGITS.test(password)
    const isPassContainUppercase = +REGEX_LETTERS_UPPERCASE.test(password)
    const isPassContainLowercase = +REGEX_LETTERS_LOWERCASE.test(password)
    const isPassContainNonDigitLetterSymbols = +REGEX_NOT_LETTERS_AND_DIGIT.test(password)

    return isPassContainDigit + isPassContainUppercase + isPassContainLowercase + isPassContainNonDigitLetterSymbols
}

export const checkPasswordIsStrong = (password = '', strengthMinimalLevel = 1) => {
    const passwordStrength = getPasswordStrengthLevel(password)
    return passwordStrength >= strengthMinimalLevel
}

export const getPasswordStrengthErrorMessage = (strengthLevel = 1) => {
    switch (+strengthLevel) {
        case 1: {
            return 'password.error.length'
        }
        case 2: {
            return 'password.strength.digitsAndLetters'
        }
        case 3: {
            return 'password.strength.digitsAndLetters.lowAndUp'
        }
        case 4: {
            return 'password.strength.fullSet'
        }
        default: {
            return 'password.strength'
        }
    }
}

import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type DeleteCustomerAccountMutationVariables = Types.Exact<{
  deleteCode: Types.Scalars['String'];
}>;


export type DeleteCustomerAccountMutation = { __typename?: 'Mutation', deleteCustomerAccount?: { __typename?: 'DeleteCustomerAccountOutput', status?: boolean | null, errorMessage?: string | null } | null };


export const DeleteCustomerAccountDocument = gql`
    mutation DeleteCustomerAccount($deleteCode: String!) {
  deleteCustomerAccount(deleteCode: $deleteCode) @client {
    status
    errorMessage
  }
}
    `;
export type DeleteCustomerAccountMutationFn = Apollo.MutationFunction<DeleteCustomerAccountMutation, DeleteCustomerAccountMutationVariables>;

/**
 * __useDeleteCustomerAccountMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerAccountMutation, { data, loading, error }] = useDeleteCustomerAccountMutation({
 *   variables: {
 *      deleteCode: // value for 'deleteCode'
 *   },
 * });
 */
export function useDeleteCustomerAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCustomerAccountMutation, DeleteCustomerAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteCustomerAccountMutation, DeleteCustomerAccountMutationVariables>(DeleteCustomerAccountDocument, options);
      }
export type DeleteCustomerAccountMutationHookResult = ReturnType<typeof useDeleteCustomerAccountMutation>;
export type DeleteCustomerAccountMutationResult = Apollo.MutationResult<DeleteCustomerAccountMutation>;
export type DeleteCustomerAccountMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerAccountMutation, DeleteCustomerAccountMutationVariables>;
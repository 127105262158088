import { Product } from '@pwa-concept/modules/graphql'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { calculateProductRatingFromMagentoRating } from 'modules/ProductPage/ReviewsBlock/helpers'

import styles from './ProductRatingBlock.module.scss'

const ProductRatingBlock = ({ ratingSummary }: { ratingSummary: Product['ratingSummary'] }) => {
    if (!ratingSummary) {
        return null
    }

    return (
        <div className={styles.review}>
            <Icon
                name="reviewStar"
                className={styles.starIcon}
            />
            {calculateProductRatingFromMagentoRating(ratingSummary)}
        </div>
    )
}

export default ProductRatingBlock

import Title, { ITitle } from '@pwa-onilab/ui/components/UI/Title'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './AuthorPageRowTitle.module.scss'

const AuthorPageRowTitle = ({ type, text, className }: ITitle) => {
    return <Title type={type} text={text} className={classNames(styles.title, className)} />
}

export default AuthorPageRowTitle

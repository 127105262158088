import { useCustomerOrder } from '@pwa-concept/modules/customer-orders'
import { IOrderComponent, OrderFieldPlaceholder, OrderFieldTitle } from '@pwa-onilab/ui/components/modules/Order'
import { useTranslation } from 'react-i18next'

import styles from './OrderShippingMethod.module.scss'

interface IOrderShippingMethod extends IOrderComponent {
    defaultShippingMethod?: string
}

const OrderShippingMethod = ({ orderNumber, className, defaultShippingMethod }: IOrderShippingMethod) => {
    const { t } = useTranslation()
    const { data: { shippingMethod } = {}, loading } = useCustomerOrder(orderNumber)

    return (
        <div className={className}>
            <OrderFieldTitle text={t('global.shippingMethod.title')} />
            {
                !defaultShippingMethod && loading
                    ? <OrderFieldPlaceholder />
                    : <p className={styles.shippingMethod}>{shippingMethod || defaultShippingMethod}</p>
            }
        </div>
    )
}

export default OrderShippingMethod

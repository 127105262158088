import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'

import styles from './HeaderTopInfo.module.scss'
const HeaderTopInfo = (
    {
        className,
    }: IClassName,
) => {
    const {
        data: { headerGreetingMessage } = {},
        loading: storeConfigLoading,
    } = useStoreConfig()

    if (storeConfigLoading) {
        return <Placeholder className={classNames(styles.placeholderSizes, className)} />
    }

    if (!headerGreetingMessage) {
        return null
    }

    return (
        <div className={classNames(styles.text, className)}>
            {headerGreetingMessage}
        </div>
    )
}

export default HeaderTopInfo

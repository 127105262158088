import { FilterOptionsMobileModal } from '@pwa-onilab/ui/components/modules/Filters'
import { IFilterOptions } from '@pwa-onilab/ui/components/modules/Filters/elements/FilterOptions/IFilterOptions'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import { useModalStates } from '@pwa-onilab/ui/hooks'
import { useTranslation } from 'react-i18next'

import styles from './FilterOptionsModalButtonMobile.module.scss'

const FilterOptionsModalButtonMobile = ({ data: filter, numResults }: Pick<IFilterOptions, 'data' | 'numResults'>) => {
    const { t } = useTranslation()
    const { openModal, closeModal, isModalOpen } = useModalStates()

    return (
        <>
            <ButtonInstance onClick={openModal} className={styles.button}>
                {t('filters.button.showAllOptions', { count: filter.items.length || 0 })}
            </ButtonInstance>
            <FilterOptionsMobileModal
                isOpen={isModalOpen}
                onClose={closeModal}
                data={filter}
                numResults={numResults}
            />
        </>
    )
}

export default FilterOptionsModalButtonMobile

import { DEFAULT_SORT_DIRECTION, SECOND_SORT_DIRECTION } from '@pwa-onilab/ui/components/modules/Filters'

const SORTS_ID_WITH_INVERTED_INITIAL_DIRECTION = ['thc_priority', 'cbd_priority']

export const runDefaultSort = (item, defaultSortId, isSearch = false) => (prev) => {
    const prevDirection = prev?.sort?.[item.id]

    let newDirection
    if (!prevDirection?.length) {
        const isNoActiveSorts = !prev?.sort

        if (isNoActiveSorts && item.id === defaultSortId && !isSearch) {
            newDirection = DEFAULT_SORT_DIRECTION
        } else {
            newDirection = getDefaultSortDirection(item.id)
        }
    } else {
        newDirection = getOppositeSortDirection(prevDirection[0])
    }

    return {
        ...prev,
        sort: {
            [item.id]: [newDirection],
        },
    }
}

export const getDefaultSortDirection = (sortId) => {
    const isSortWithInvertedInitialDirection = SORTS_ID_WITH_INVERTED_INITIAL_DIRECTION.includes(sortId)
    if (isSortWithInvertedInitialDirection) {
        return SECOND_SORT_DIRECTION
    }
    return DEFAULT_SORT_DIRECTION
}

export const getOppositeSortDirection = (currentDirection) => {
    if (currentDirection === SECOND_SORT_DIRECTION) {
        return DEFAULT_SORT_DIRECTION
    }

    return SECOND_SORT_DIRECTION
}

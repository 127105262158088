import { CustomerAddress } from '@pwa-concept/modules/graphql'
import { extractCustomerFullName } from '@pwa-onilab/ui/mappers'

import styles from './AccountAddressCustomerName.module.scss'

const AccountAddressCustomerName = (
    {
        firstName,
        middleName,
        lastName,
    }: Pick<CustomerAddress, 'firstName' | 'middleName' | 'lastName'>,
) => {
    const fullName = extractCustomerFullName(firstName, middleName, lastName)
    return (
        <p className={styles.wrapper}>
            {fullName}
        </p>
    )
}

export default AccountAddressCustomerName

import { CmsBlocksQueryVariables, useCmsBlocksQuery } from '@pwa-concept/modules/cms/graphql/queries/CmsBlocks'

interface IUseCmsBlocks {
    variables: CmsBlocksQueryVariables
    skip?: boolean
}

const useCmsBlocks = ({ variables, skip }: IUseCmsBlocks) => {
    const { data, loading } = useCmsBlocksQuery({
        variables,
        skip: skip || !variables?.identifiers?.length,
    })

    return {
        data: data?.cmsBlocks || undefined,
        loading,
    }
}

export default useCmsBlocks

import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { MOBILE_MENU_BAR_DATA } from '@pwa-onilab/ui/components/modules/MobileMenuBar/constants'
import { MenuItem } from '@pwa-onilab/ui/components/modules/MobileMenuBar/elements/MenuItem'
import { DOM_NODE_APP } from '@pwa-onilab/ui/constants/domNodes'
import { CHECKOUT_ROUTE } from '@pwa-onilab/ui/constants/navigation'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { useEffect } from 'react'
import { useMatch } from 'react-router-dom'

import styles from './MobileMenuBar.module.scss'

const MobileMenuBar = ({ className }: IClassName) => {
    const isCheckout = useMatch(CHECKOUT_ROUTE)
    const isDesktop = useMediaBreakpoint().isBreakpointUp('lg')

    useEffect(() => {
        if (isDesktop || isCheckout) {
            DOM_NODE_APP.classList.remove(styles.appPadding)
        } else {
            DOM_NODE_APP.classList.add(styles.appPadding)
        }
    }, [isDesktop, isCheckout])

    if (isDesktop || isCheckout) {
        return null
    }

    return (
        <div className={classNames(styles.wrapper, className)}>
            {
                MOBILE_MENU_BAR_DATA.map(({ name, icon, link }) => {
                    return (
                        <MenuItem
                            key={link}
                            className={styles.item}
                            name={name}
                            icon={icon}
                            link={link}
                        />
                    )
                })
                }
        </div>
    )
}

export default MobileMenuBar

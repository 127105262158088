import { BreadcrumbModule } from '@pwa-concept/modules/breadcrumb'

import graphqlSchemas from './graphql/schemas'
import stores from './graphql/stores'

const AppBreadcrumbModule = BreadcrumbModule.extend((module, { concat }) => ({
    graphqlSchemas: concat(module?.graphqlSchemas, graphqlSchemas),
    graphqlResolvers: concat(module?.graphqlResolvers, stores?.resolvers),
    graphqlStoreFragments: concat(module?.graphqlStoreFragments, stores?.fragments),
    graphqlStorePossibleTypes: concat(module?.graphqlStorePossibleTypes, stores?.possibleTypes),
}))

export default AppBreadcrumbModule

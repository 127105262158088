import { BenefitsCard } from '@pwa-onilab/ui/components/widgets/Benefits'

import styles from './BenefitsList.module.scss'

interface IBenefitsListProps {
    items: Array<{
        icon_image: string
        link: string
        short_description: string
        title: string
    }>
}

const BenefitsList = (
    {
        items,
    }: IBenefitsListProps,
) => (
    <>
        {
            items.slice(0, 4).map((
                {
                    icon_image: iconImage,
                    link,
                    short_description: shortDescription,
                    title,
                }) => (
                    <BenefitsCard
                        key={link + shortDescription}
                        title={title}
                        iconImage={iconImage}
                        link={link}
                        shortDescription={shortDescription}
                        className={styles.card}
                    />
            ))
        }
    </>
)

export default BenefitsList

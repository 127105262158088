import api from '@pwa-concept/core/api'
import { $wishlist } from '@pwa-concept/core/models'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { GraphQLError } from 'graphql'
import { gql } from 'graphql-tag'

const wishlist: QueryResolvers['wishlist'] = async (_, input) => {
    const {
        id,
        limit,
        page,
    } = input

    const { data: { onilab_wishlist = {} } = {}, errors } = (
        await api.graphql(
            gql`
                query(
                    $uid: String
                    $pageSize: Int = 20
                    $currentPage: Int = 1
                    $isFullProduct: Boolean = false
                ) {
                    onilab_wishlist(
                        uid: $uid
                    ) {
                        uid
                        items(
                            currentPage: $currentPage
                            pageSize: $pageSize
                        ) {
                            items {
                                added_at
                                description
                                id
                                product {
                                    ... ProductInterface
                                }
                            }

                            page_info {
                                current_page
                                page_size
                                total_pages
                            }
                        }
                        items_count
                        sharing_code
                    }
                }
            `,
        ).query({
            uid: id,
            currentPage: page,
            pageSize: limit,
        })
    )

    if (errors) {
        throw new GraphQLError(errors[0].message)
    }

    if (!onilab_wishlist) {
        return null
    }

    if ($wishlist.id !== onilab_wishlist?.uid) {
        $wishlist.id = onilab_wishlist.uid
    }

    return {
        __context: onilab_wishlist,
        __typename: 'Wishlist',
    }
}

export default wishlist

import api from '@pwa-concept/core/api'
import { gql } from 'graphql-tag'

const updateCustomerAddressMutation = async (id, inputFields) => await api.graphql(
    gql`
        mutation($id: Int!, $input: CustomerAddressInput!) {
            updateCustomerAddress(id: $id, input: $input) {
                ... CustomerAddress
            }
        }
    `,
).mutation({
    id: Number(id),
    input: inputFields,
})

export default updateCustomerAddressMutation

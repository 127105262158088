import Icon from '@pwa-onilab/ui/components/UI/Icon'
import Title from '@pwa-onilab/ui/components/UI/Title'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './AccountInfoBlock.module.scss'

interface IAccountInfoBlock {
    title: string
    message: string
    disabled?: boolean
    loading?: boolean
}

const AccountInfoBlock = (
    {
        title,
        message,
        disabled,
        loading
    }: IAccountInfoBlock,
) => {
    if (loading) {
        return <div className={styles.loader} />
    }

    return (
        <div className={classNames(styles.wrapper, disabled && styles.disabled)} role="button">
            <Title type="h2" text={title} className={styles.title} />
            <p className={styles.message}>
                {message}
            </p>
            <Icon
                name="chevron"
                className={styles.icon}
            />
        </div>
    )
}

export default AccountInfoBlock

import {
    FilterChoosenItem,
    FilterClearAll,
    IFilterTag,
    useFilterOptionName,
} from '@pwa-onilab/ui/components/modules/Filters'
import { useFilterApiContext } from '@pwa-onilab/ui/components/modules/Filters/context'
import { classNames } from '@pwa-onilab/ui/helpers'

import css from './FilterActiveOptions.module.scss'

interface FilterActiveOptionsProps {
    className?: string
    tags: IFilterTag[]
    withoutClearAll?: boolean
}

const FilterActiveOptions = (
    {
        className,
        tags = [],
        withoutClearAll = false,
    }: FilterActiveOptionsProps,
) => {
    if (!tags?.length) {
        return null
    }
    const { onRemoveTag } = useFilterApiContext()

    const getOptionName = useFilterOptionName()
    return (
        <div className={classNames(css.wrapper, className)}>
            {
                !withoutClearAll && (
                    <FilterClearAll className={css.clearAll} />
                )
            }
            {
                tags.map((item) => (
                    <FilterChoosenItem
                        key={item.id}
                        onRemoveTag={() => onRemoveTag(item)}
                        name={getOptionName(item)}
                        value={item.name}
                    />
                ))
            }
        </div>
    )
}

export default FilterActiveOptions

import * as Types from '../../../../graphql';

import { gql } from '@apollo/client';
import { StrainFamilyClientFragmentFragmentDoc } from '../fragments/StrainFamilyClientFragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type GetStrainFamilyQueryVariables = Types.Exact<{
  urlKey?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetStrainFamilyQuery = { __typename?: 'Query', getFamilyStrain?: { __typename?: 'FamilyStrain', id?: string | null, createdAt?: string | null, publishTime?: string | null, urlKey?: string | null, thumbnail?: string | null, image?: string | null, name?: string | null, shortDescription?: string | null, description?: string | null, author?: { __typename?: 'BlogAuthor', id?: number | null, name?: string | null, urlKey?: string | null, thumbnailImageUrl?: string | null } | null, meta?: { __typename?: 'FamilyStrainMeta', title?: string | null, description?: string | null, keywords?: string | null, robots?: string | null } | null } | null };


export const GetStrainFamilyDocument = gql`
    query GetStrainFamily($urlKey: String) {
  getFamilyStrain(urlKey: $urlKey) @client {
    ...StrainFamilyClientFragment
  }
}
    ${StrainFamilyClientFragmentFragmentDoc}`;

/**
 * __useGetStrainFamilyQuery__
 *
 * To run a query within a React component, call `useGetStrainFamilyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStrainFamilyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStrainFamilyQuery({
 *   variables: {
 *      urlKey: // value for 'urlKey'
 *   },
 * });
 */
export function useGetStrainFamilyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStrainFamilyQuery, GetStrainFamilyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetStrainFamilyQuery, GetStrainFamilyQueryVariables>(GetStrainFamilyDocument, options);
      }
export function useGetStrainFamilyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStrainFamilyQuery, GetStrainFamilyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetStrainFamilyQuery, GetStrainFamilyQueryVariables>(GetStrainFamilyDocument, options);
        }
export type GetStrainFamilyQueryHookResult = ReturnType<typeof useGetStrainFamilyQuery>;
export type GetStrainFamilyLazyQueryHookResult = ReturnType<typeof useGetStrainFamilyLazyQuery>;
export type GetStrainFamilyQueryResult = Apollo.QueryResult<GetStrainFamilyQuery, GetStrainFamilyQueryVariables>;
import { MutationResolvers } from '@pwa-concept/modules/graphql'

import changePassword from './change-password'
import deleteCustomerAccount from './deleteCustomerAccount'
import sendDeleteAccountMessage from './sendDeleteAccountMessage'
import updateCustomer from './update-customer'
import updateEmail from './update-email'

const Mutation: MutationResolvers = {
    changePassword,
    deleteCustomerAccount,
    sendDeleteAccountMessage,
    updateCustomer,
    updateEmail,
}

export default Mutation

import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { SocialMediaIcons } from '@pwa-onilab/ui/components/modules/Footer'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'

import styles from './SocialMediaIconsWithTitle.module.scss'

const SocialMediaIconsWithTitle = ({ className }: IClassName) => {
    const { data: { socialAndPaymentConfig: { findUsText } = {} } = {} } = useStoreConfig()

    return (
        <div className={classNames(styles.wrapper, className)}>
            {
                findUsText && (
                    <div className={styles.title}>
                        {findUsText}
                    </div>
                )
            }
            <SocialMediaIcons />
        </div>
    )
}

export default SocialMediaIconsWithTitle

import { UpdateCustomerAddressInput } from '@pwa-concept/modules/graphql'

const extractFieldsFromUpdateCustomerAddressInput = (address: UpdateCustomerAddressInput) => {
    return {
        city: address.city,
        company: address.company,
        country_code: address.countryCode,
        default_billing: address.defaultBilling,
        default_shipping: address.defaultShipping,
        firstname: address.firstName,
        middlename: address.middleName,
        lastname: address.lastName,
        postcode: address.postcode,
        region: {
            region_id: address.region?.regionId,
            region_code: address.region?.regionCode,
            region: address.region?.region,
        },
        street: address.street,
        vat_id: address.vatId,
        telephone: address.telephone,
    }
}

export default extractFieldsFromUpdateCustomerAddressInput

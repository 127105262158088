import { StoreFragments } from '@pwa-concept/core'

import CartItemInterface from './CartItemInterface.graphql'
import ShippingCartAddress from './ShippingCartAddress.graphql'

const fragments: StoreFragments = [
    CartItemInterface,
    ShippingCartAddress,
]

export default fragments

import { makeVar, ReactiveVar, useReactiveVar } from '@apollo/client'

const isSaveModalOpen: ReactiveVar<boolean> = makeVar(false)
let isFormSaveBeforeHideActive = false

export type formSaveCallbackType = () => Promise<boolean>

// it made like this for get control over save form modal in Anchor/Link elements and inside component with form
// callback return boolean value, which indicate success state. if success - set all callback to null
class FormSaveBeforeHideModalModel {
    activateModel: () => void

    #callback: formSaveCallbackType
    #onSaveReject: () => void
    #resetCallbacks: () => void

    tryOpenModal: (onSaveReject: () => void) => boolean
    #closeModal: () => void

    setCallback: (callback: formSaveCallbackType) => void
    execCallback: () => Promise<void>

    setOnSaveReject: (callback: () => void) => void
    execOnSaveReject: () => void

    useIsOpen: () => boolean

    constructor() {
        this.activateModel = () => {
            isFormSaveBeforeHideActive = true
        }
        this.tryOpenModal = (onReject?: () => void) => {
            if (isFormSaveBeforeHideActive && this.#callback) {
                if (onReject) {
                    this.setOnSaveReject(onReject)
                }
                isSaveModalOpen(true)
                return true
            }
            return false
        }

        this.#closeModal = () => {
            isSaveModalOpen(false)
        }

        this.#resetCallbacks = () => {
            this.setCallback(null)
            this.setOnSaveReject(null)
        }

        this.setCallback = (newCallback) => {
            this.#callback = newCallback
        }

        this.setOnSaveReject = (newOnSaveReject) => {
            this.#onSaveReject = newOnSaveReject
        }

        this.execOnSaveReject = () => {
            const onSaveRejectCallback = this.#onSaveReject
            this.#closeModal()
            this.#resetCallbacks()
            onSaveRejectCallback?.()
        }

        this.useIsOpen = () => {
            return useReactiveVar(isSaveModalOpen)
        }

        this.execCallback = async () => {
            if (this.#callback) {
                this.#closeModal()
                const isSuccess = await this.#callback()
                if (isSuccess) {
                    this.#resetCallbacks()
                }
            } else {
                this.#closeModal()
                this.#resetCallbacks()
            }
        }
    }
}

export const formSaveBeforeHideModal = new FormSaveBeforeHideModalModel()

import { useCustomerOrder } from '@pwa-concept/modules/customer-orders'
import { Flex } from '@pwa-onilab/ui/components/elements/Flex'
import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { formatOrderDate } from '@pwa-onilab/ui/components/modules/AccountSubpages/AccountOrderHistory/helpers'
import {
    IOrderComponent,
    OrderBlockRecord,
    ReorderButton,
} from '@pwa-onilab/ui/components/modules/Order'
import { useTranslation } from 'react-i18next'

import styles from './OrderStatusInfo.module.scss'

const OrderStatusInfo = ({ orderNumber }: Pick<IOrderComponent, 'orderNumber'>) => {
    const { t } = useTranslation()
    const { data: { date, status } = {}, loading } = useCustomerOrder(orderNumber)
    const formattedDate = formatOrderDate(date || '').date

    return (
        <div className={styles.wrapper}>
            <Flex justifyContent="spaceBetween" alignItems="center">
                <span className={styles.orderNumber}>
                    {t('global.order.number', { orderNumber })}
                </span>
            </Flex>
            <Flex direction="column" className={styles.recordsBlock}>
                <OrderBlockRecord loading={loading} title={t('AccountPage.orders.createdWithColon')}>
                    {formattedDate}
                </OrderBlockRecord>
                <OrderBlockRecord loading={loading} title={t('AccountPage.orders.statusWithColon')}>
                    {status}
                </OrderBlockRecord>
            </Flex>
            <MediaBreakpointUp name="lg">
                <ReorderButton orderNumber={orderNumber} />
            </MediaBreakpointUp>
        </div>
    )
}

export default OrderStatusInfo

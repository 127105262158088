import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Container from '@pwa-onilab/ui/components/UI/Container'
import BlogBreadcrumbs from 'modules/Blog/BlogBreadcrumbs'
import { BlogContentSkeleton } from 'modules/Blog/BlogContent/BlogContent.skeleton'
import { BlogNavigationSkeleton } from 'modules/Blog/BlogNavigation/BlogNavigation.skeleton'

import styles from './BlogPage.module.scss'
const BlogPagePlaceholder = () => {
    return (
        <Container>
            <MediaBreakpointUp name="lg">
                <BlogBreadcrumbs />
            </MediaBreakpointUp>
            <div className={styles.content}>
                <BlogNavigationSkeleton />
                <BlogContentSkeleton />
            </div>
        </Container>
    )
}

export default BlogPagePlaceholder

import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const getBlogAuthorPostsList: QueryResolvers['getBlogAuthorPostsList'] = async (_, { id }) => {
    const { data: { blogPosts: __context } = {} } = await (
        api.graphql(
            gql`
                query( $id: String) {
                    blogPosts(
                        pageSize: 60,
                        filter: {
                            author_id: {
                                eq: $id,
                            }
                        },
                        sort: {
                            publish_time: DESC,
                        }
                    ) {
                        items {
                            id
                            title
                            postUrl: post_url
                        }
                    }
                }
            `,
        ).query({
            id,
        })
    )

    if (!__context) {
        return null
    }

    return {
        items: __context.items,
        __typename: 'BlogAuthorPostsList',
    }
}

export default getBlogAuthorPostsList

import { classNames } from '@pwa-onilab/ui/helpers/classNames'
import { PropsWithChildren } from 'react'

import styles from './Badge.module.scss'

const VARIANTS = {
    cart: styles.variantCart,
}

export interface BadgeProps {
    className?: string
    variant?: keyof typeof VARIANTS
}

const Badge = (
    {
        children,
        className,
        variant,
    }: PropsWithChildren<BadgeProps>,
) => {
    return (
        <div className={classNames(styles.badge, variant && VARIANTS[variant], className)}>
            {children}
        </div>
    )
}

export default Badge

import { REGEX_HTML_TAG } from '@pwa-onilab/ui/constants/patterns'

export const filterFaqTabs = (tabs, benchmark) => {
    const curTab = {}
    const newAccordions = []

    tabs.forEach((element) => {
        const accordions = element.items.filter((accord) => {
            const title = accord?.title?.replace(REGEX_HTML_TAG, '').toLowerCase()
            const description = accord?.description?.replace(REGEX_HTML_TAG, '').toLowerCase()

            return title.indexOf(benchmark) !== -1 || description.indexOf(benchmark) !== -1
        })

        newAccordions.push(...accordions)
    })

    curTab.items = newAccordions
    curTab.benchmark = benchmark

    return curTab
}

const findAllDelimeterIncludes = (string, delimeter) => {
    const reg = new RegExp(delimeter, 'gi')
    return [...string.matchAll(reg)]
}

const findOnlyOneDelimeterInclude = (string, delimeter) => {
    const reg = new RegExp(`(${delimeter})`, 'gi')
    return string.split(reg).filter((el) => el.length)
}

const findIndexOfPointInsideRange = (start, end, array) => array.findIndex((el) => start >= el.index && end <= el.index + el.tag.length)

const tagMapper = (el) => (
    {
        index: el.index,
        tag: el[0],
    }
)

// find all matches of delimiter inside string without tags
// create array(A1) of match lastindexes in string without tags.
// create array(A2) of tag indexes inside string with tags.
// foreach element(x) of A1, which value < element(y) of A2, add y value
// forech x add delimeter length.
// if exist yz wich belongs to range [xz, xz + delimeter length] => increase xz on yz.tag.length (tagname lengh)
// create array on match indexes in string with tags.
// unite and sort A1 & A2
// return substring using indexes from union
const splitStringByDelimeters = (str, delimeter) => {
    const allTagsFromString = [...str.matchAll(REGEX_HTML_TAG)].map((el) => tagMapper(el))
    const stringWithoutTags = str.replace(REGEX_HTML_TAG, '')

    const substringsWithoutTags = findAllDelimeterIncludes(stringWithoutTags, delimeter) || []

    const substringsWithoutTagsIndexes = substringsWithoutTags.map((el) => el.index)

    const allTagsIndexes = allTagsFromString.reduce((acc, el) => [...acc, el.index, el.index + el.tag.length], [])

    const indexesOfStart = []

    for (let i = 0; i < allTagsFromString.length; i += 1) {
        const tagIndex = allTagsFromString[i].index
        const tagName = allTagsFromString[i].tag

        for (let j = 0; j < substringsWithoutTagsIndexes.length; j += 1) {
            if (tagIndex <= substringsWithoutTagsIndexes[j]) {
                substringsWithoutTagsIndexes[j] += tagName.length
            }
        }
    }

    for (let i = 0; i < substringsWithoutTagsIndexes.length; i += 1) {
        const index = substringsWithoutTagsIndexes[i]
        const newIndex = index + delimeter.length
        const indexOfTagInsideSubstring = findIndexOfPointInsideRange(index, newIndex, allTagsFromString)

        const stringRangeStart = substringsWithoutTagsIndexes[i]
        substringsWithoutTagsIndexes[i] += delimeter.length
        if (indexOfTagInsideSubstring !== -1) {
            const { tag } = allTagsFromString[indexOfTagInsideSubstring]
            substringsWithoutTagsIndexes[i] += tag.length
            indexesOfStart.push(stringRangeStart + tag.length)
        } else {
            indexesOfStart.push(stringRangeStart)
        }
    }

    const unionOfIndexes = [...new Set([...indexesOfStart, ...substringsWithoutTagsIndexes, ...allTagsIndexes])].sort((a, b) => a - b)

    const subStrings = []
    let prevIdx = 0

    for (let i = 0; i <= unionOfIndexes.length; i += 1) {
        if (prevIdx === unionOfIndexes[i]) {
            continue
        }
        const substring = str.substring(prevIdx, unionOfIndexes[i])

        subStrings.push(substring)
        prevIdx = unionOfIndexes[i]
    }

    return subStrings
}

export const findAllSubstrings = (string, delimeter) => {
    if (!string) {
        throw new Error('string is undefined')
    }
    if (!delimeter) {
        return [string]
    }
    return splitStringByDelimeters(string, delimeter)
}

const wrappSubStringsWithDottes = (arrayOfSubstrings, shoudWrapBefore = true, shoudWrapAfter = true) => {
    const result = []
    if (shoudWrapBefore) {
        result.push('...')
    }

    result.push(...arrayOfSubstrings)

    if (shoudWrapAfter) {
        result.push('...')
    }
    return result
}

const splitStringByDelimeterOneInclude = (text, benchmark) => {
    const maxLengthOfText = 30
    const textWithoutTags = text.replace(REGEX_HTML_TAG, '')

    const proccessingSubStrings = findOnlyOneDelimeterInclude(textWithoutTags, benchmark)
    const isTextWrapperByDotes = textWithoutTags.length > maxLengthOfText

    const formattedText = textWithoutTags.toLowerCase()
    const formattedBenchmark = benchmark.toLowerCase()

    if (textWithoutTags.length < maxLengthOfText && Array.isArray(proccessingSubStrings)) {
        return proccessingSubStrings
    }

    const firstInclude = proccessingSubStrings?.filter((substr) => substr.toLowerCase() === formattedBenchmark)[0]
    if (!firstInclude) {
        return null
    }

    const firstIncludeIndex = formattedText.indexOf(formattedBenchmark)
    const firstIncludeLastIndex = firstIncludeIndex + firstInclude.length

    if (firstInclude.length >= maxLengthOfText) {
        return wrappSubStringsWithDottes([firstInclude], firstIncludeIndex !== 0, firstIncludeLastIndex < textWithoutTags.length)
    }

    let enabledLength = maxLengthOfText - benchmark.length
    const textBefore = textWithoutTags.substring(firstIncludeIndex - enabledLength, firstIncludeIndex)

    const shouldWrapBefore = textBefore.length !== firstIncludeIndex
    const shouldWrapInTheEnd = firstIncludeLastIndex < textWithoutTags.length

    if (firstIncludeIndex > enabledLength) {
        const result = [textBefore, firstInclude]
        return isTextWrapperByDotes ? wrappSubStringsWithDottes(result, shouldWrapBefore, shouldWrapInTheEnd) : result
    }
    enabledLength -= textBefore.length

    const textAfter = textWithoutTags.substring(firstIncludeLastIndex, firstIncludeLastIndex + enabledLength)
    const result = [textBefore, firstInclude, textAfter]

    return isTextWrapperByDotes ? wrappSubStringsWithDottes(result, shouldWrapBefore, shouldWrapInTheEnd) : result
}

export const findSubstringsWithOneInclude = (string, delimeter) => {
    if (!string) {
        throw new Error('string is undefined')
    }
    if (!delimeter) {
        return [string]
    }
    if (!string.toLowerCase().includes(delimeter.toLowerCase())) {
        return null
    }
    return splitStringByDelimeterOneInclude(string, delimeter)
}

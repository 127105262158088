export enum BreadcrumbsPageTypeEnum {
    BLOG_POST='BLOG_POST',
    BLOG_AUTHOR='BLOG_AUTHOR',
    HOMEPAGE_BLOG='HOMEPAGE_BLOG',
    PRODUCT='PRODUCT',
    BESTSELLERS='BESTSELLERS',
    CATEGORIES_AS_STRAINS='CATEGORIES_AS_STRAINS',
    FAMILY_STRAIN='FAMILY_STRAIN',
    HOMEPAGE_FAMILY_STRAIN='HOMEPAGE_FAMILY_STRAIN',
    LOCATION='LOCATION',
    LOCATION_STATE='LOCATION_STATE',
    WHOLESALE='WHOLESALE',
    WOLRD='WORLD',
}

import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Container from '@pwa-onilab/ui/components/UI/Container'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { LinkArrow } from 'components/UI/LinkArrow'
import { addSlashToEndUrl } from 'helpers'
import Cookies from 'js-cookie'
import { IS_PROMO_NOTIFICATION_INACTIVE, PROMO_NOTIFICATION_INACTIVE_TIME_IN_DAYS } from 'modules/Header'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './HeaderPromoNotification.module.scss'

const HeaderPromoNotification = () => {
    const { t } = useTranslation()

    const [isPromoBannerActive, setPromoBannerActive] = useState(true)

    const {
        data: {
            promoNotification,
        } = {},
    } = useStoreConfig()

    useEffect(() => {
        if (!Cookies.get(IS_PROMO_NOTIFICATION_INACTIVE)) {
            setPromoBannerActive(true)
        } else {
            setPromoBannerActive(false)
        }
    }, [Cookies.get(IS_PROMO_NOTIFICATION_INACTIVE)])

    const closeHeaderPromoNotification = useCallback(() => {
        Cookies.set(IS_PROMO_NOTIFICATION_INACTIVE, 'true', { expires: PROMO_NOTIFICATION_INACTIVE_TIME_IN_DAYS })
        setPromoBannerActive(false)
    }, [])

    if (!isPromoBannerActive || !promoNotification?.text) {
        return null
    }

    const {
        color,
        backgroundColor,
        text,
        url,
    } = promoNotification

    const wrapperStyle = { backgroundColor }
    const notificationTextStyle = { color }

    return (
        <div style={wrapperStyle} className={styles.wrapper}>
            <Container className={styles.container}>
                <div style={notificationTextStyle} className={styles.title}>
                    {text}
                </div>
                {
                    url && (
                        <LinkArrow
                            withArrow
                            className={styles.link}
                            arrowClassName={styles.arrowIcon}
                            additionalStyle={notificationTextStyle}
                            to={addSlashToEndUrl(url)}
                        >
                            {t('global.buttons.view')}
                        </LinkArrow>
                    )
                }
            </Container>
            <ButtonInstance
                onClick={closeHeaderPromoNotification}
                className={styles.closeButton}
            >
                <Icon
                    name="cross"
                    className={styles.crossIcon}
                    style={notificationTextStyle}
                />
            </ButtonInstance>
        </div>
    )
}

export default HeaderPromoNotification

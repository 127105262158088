import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

export type BreadCrumbClientFragmentFragment = { __typename?: 'Breadcrumb', id?: string | null, level?: number | null, name?: string | null, url?: { __typename?: 'BreadcrumbUrl', type?: string | null, to?: string | null, id?: string | null } | null };

export const BreadCrumbClientFragmentFragmentDoc = gql`
    fragment BreadCrumbClientFragment on Breadcrumb {
  id
  level
  name
  url {
    type
    to
    id
  }
}
    `;
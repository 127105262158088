import styles from './CheckoutPlaceholder.module.scss'

const CheckoutPlaceholder = () => {
    return (
        <>
            <div className={styles.header} />
            <div className={styles.checkoutContent}>
                <div>
                    <div className={styles.step}>
                        <div className={styles.label} />
                        <div className={styles.email} />
                    </div>
                    <div className={styles.step}>
                        <div className={styles.label} />
                        <div className={styles.address} />
                    </div>
                    <div className={styles.step}>
                        <div className={styles.label} />
                        <div className={styles.shipping} />
                    </div>
                    <div className={styles.step}>
                        <div className={styles.label} />
                        <div className={styles.payment} />
                    </div>
                    <div className={styles.step}>
                        <div className={styles.label} />
                        <div className={styles.discount} />
                    </div>
                    <div className={styles.newslettersToggler} />
                </div>
                <div className={styles.sidebar} />
            </div>
        </>
    )
}

export default CheckoutPlaceholder

import {
    ProductStockType,
} from '@pwa-concept/modules/graphql'

const sortVariantsByPrice = (variants) => {
    variants.sort((variantA, variantB) => {
        const { product: productA } = variantA
        const { product: productB } = variantB

        return productA.price.current.value - productB.price.current.value
    })

    return variants[0]
}

const sortVariantsByPriceRance = (variants) => {
    variants.sort((variantA, variantB) => {
        const { product: productA } = variantA
        const { product: productB } = variantB

        return productA.price_range.minimum_price.final_price.value - productB.price_range.minimum_price.final_price.value
    })

    return variants[0]
}

export const selectProductDefaultVariant = (product, sku) => {
    if (!product.variants?.length) {
        return null
    }
    const { variants } = product

    const variantBySku = variants.find(variant => variant?.product?.sku === sku)
    if (variantBySku) {
        return variantBySku
    }

    const inStockVariants = variants.filter(({ product }) => {
        return product.stock.type === ProductStockType.InStock
    })

    if (!inStockVariants.length) {
        return sortVariantsByPrice(variants)
    }

    return sortVariantsByPrice(inStockVariants)
}

export const selectCmsProductDefaultVariant = (product) => {
    if (!product?.variants) {
        return null
    }

    const { variants } = product

    const inStockVariants = variants.filter(({ product }) => {
        return product.stock_status === ProductStockType.InStock
    })

    if (!inStockVariants.length) {
        return sortVariantsByPriceRance(variants)
    }

    return sortVariantsByPriceRance(inStockVariants)
}

import { TotalsRow } from '@pwa-onilab/ui/components/elements/Totals/elements'
import Price from '@pwa-onilab/ui/components/UI/Price'
import { ITotals } from 'components/overrides/elements/Totals/ITotals'
import { useTranslation } from 'react-i18next'

import styles from './Totals.module.scss'

const Totals = (
    {
        subtotal,
        discounts,
        delivery,
        deliveryName,
        total,
    }: ITotals) => {
    const { t } = useTranslation()

    return (
        <>
            <div className={styles.totalDetails}>
                <TotalsRow>
                    {t('cart.result.subtotal')}
                    <Price>
                        {subtotal}
                    </Price>
                </TotalsRow>
                <TotalsRow>
                    <span>
                        {t('cart.result.delivery')}
                        {deliveryName && `(${deliveryName})`}
                    </span>
                    <Price>
                        {delivery}
                    </Price>
                </TotalsRow>
                {
                    discounts?.map((discount) => {
                        return (
                            <TotalsRow key={discount.label}>
                                {discount.label}
                                <Price>
                                    {-(discount.money || discount.value)}
                                </Price>
                            </TotalsRow>
                        )
                    })
                }
            </div>
            <TotalsRow className={styles.total}>
                {t('global.totalWithColon')}
                <Price>
                    {total}
                </Price>
            </TotalsRow>
        </>
    )
}

export default Totals

import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const cmsBlocks: QueryResolvers['cmsBlocks'] = async (_, { identifiers }) => {
    const {
        data: { cmsBlocks: __context } = {},
    } = await (
        api.graphql(
            gql`
                query($identifiers: [String]) {
                    cmsBlocks(identifiers: $identifiers) {
                        items {
                            ...CmsBlock
                        }
                    }
                }
            `,
        ).query({ identifiers })
    )

    if (!__context) return null

    return {
        __context,
        __typename: 'CmsBlocksOutput',
    }
}

export default cmsBlocks

import { StoreConfig } from '@pwa-concept/modules/graphql'
import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { IDefaultHelmet } from '@pwa-onilab/ui/components/elements/DefaultHelmet'
import { SchemaHelmet } from 'elements/SchemaHelmet'
import { SCHEMA_HELMET_CONTEXT_VALUE } from 'elements/SchemaHelmet/SchemaHelmetConstants'
import { addSlashToEndUrl } from 'helpers'

const getWebPageSchema = (storeConfig: StoreConfig, meta: IDefaultHelmet['meta']) => {
    const origin = window.location.origin
    const url = addSlashToEndUrl(window.location.href)

    const data = {
        '@context': SCHEMA_HELMET_CONTEXT_VALUE,
        '@type': 'WebPage',
        '@id': url,
        url,
        name: meta.title,
        isPartOf: {
            '@id': `${origin}/#website`,
        },
        about: {
            '@id': `${origin}/#organization`,
        },
        // datePublished: '2020-02-17T11:30:56+00:00', // TODO
        // dateModified: '2023-01-10T00:32:09+00:00', // TODO
        description: meta.description,
        breadcrumb: {
            '@id': `${origin}/#breadcrumb`,
        },
        inLanguage: storeConfig.locale,
        potentialAction: [
            {
                '@type': 'ReadAction',
                target: [url],
            },
        ],
    }

    if (meta?.ogImage?.src) {
        data['primaryImageOfPage'] = {
            '@type': 'ImageObject',
            contentUrl: meta.ogImage.src,
            url: meta.ogImage.src,
            caption: meta.ogImage.alt || '',
        }
    }

    return data
}

const SchemaHelmetWebPage = ({ meta }: { meta: IDefaultHelmet['meta']}) => {
    const { data: storeConfig } = useStoreConfig()

    if (!storeConfig) {
        return null
    }

    return (
        <SchemaHelmet
            jsonConfig={getWebPageSchema(storeConfig, meta)}
        />
    )
}

export default SchemaHelmetWebPage

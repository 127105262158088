import { bootstrap } from '@pwa-concept/core'
import { logger } from '@pwa-concept/modules/helpers/logger'
import { setupLogger } from 'helpers/logger/setupLogger'
import { initSentry } from 'helpers/Sentry/initSentry'

import { AppModule } from './AppModule'

initSentry()
setupLogger()

bootstrap(async ({ create }) => {
    await create(AppModule)
}).catch(logger.error)

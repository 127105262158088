import { Category } from '@pwa-concept/modules/graphql'
import { CategoryBreadcrumbs } from '@pwa-onilab/ui/components/modules/Category'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { Section } from '@pwa-onilab/ui/components/UI/Section'
import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import css from './CategoryLayout.module.scss'

interface ICategoryLayout {
    category: Category
}

const CategoryLayout = (
    {
        children,
        category,
    }: PropsWithChildren<ICategoryLayout>,
) => {
    return (
        <>
            <Section className={classNames(css.breadcrumbs)}>
                <Container>
                    <CategoryBreadcrumbs categoryId={category.id} />
                </Container>
            </Section>
            <main className={css.main}>
                {children}
            </main>
        </>
    )
}

export default CategoryLayout

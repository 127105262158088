import { useFaqAccordion, useFaqSearchInput } from '@pwa-onilab/ui/components/widgets/FaqWidget'
import { useCallback } from 'react'

const useFaq = (items) => {
    const {
        isModalOpen,
        isSearchModalOpen,
        closeSearchModal,
        openSearchModal,
        isTabChoosen,
        isTabFromSearch,
        activeTab,
        createTabFromSearch,
        rollbackToTab,
        setActiveTabHandler,
        onCloseModalHandler,
    } = useFaqAccordion(items)

    const {
        searchValue,
        handleChange,
        clearInput,
    } = useFaqSearchInput(activeTab, items, createTabFromSearch, rollbackToTab, isTabChoosen, isTabFromSearch)

    const onCloseSearchModalHandler = useCallback(() => {
        closeSearchModal()
        clearInput()
    }, [clearInput])

    return {
        searchValue,
        handleChange,
        isModalOpen,
        isSearchModalOpen,
        onCloseSearchModalHandler,
        openSearchModal,
        activeTab,
        setActiveTabHandler,
        onCloseModalHandler,
        clearInput,
    }
}

export default useFaq

import Title, { ITitle } from '@pwa-onilab/ui/components/UI/Title'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './AccountSubpageEmptyTitle.module.scss'

interface ITitleCustom extends ITitle {
    className?: string
}

const AccountSubpageEmptyTitle = (
    {
        text,
        className
    }: Pick<ITitleCustom, 'text'>
) => <Title className={classNames(styles.title, className)} type="h5" text={text} />

export default AccountSubpageEmptyTitle

import { useStoreConfig } from '@pwa-concept/modules/store-config'
import Title from '@pwa-onilab/ui/components/UI/Title'
import { CmsPageMenuItem, CmsPageMenuSkeleton } from '@pwa-onilab/ui/components/widgets/CmsPage'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { Dispatch, SetStateAction, useEffect } from 'react'

import styles from './CmsPageMenu.module.scss'

interface ICmsPageMenuProps extends IClassName {
    cmsId?: string
    setIsCmsPageWithoutMenu: Dispatch<SetStateAction<boolean>>
}

const CmsPageMenu = (
    {
        className,
        cmsId,
        setIsCmsPageWithoutMenu,
    }: ICmsPageMenuProps,
) => {
    const { data: { customerService } = {}, loading } = useStoreConfig()

    const currentCustomerServicePageIndex = customerService?.findIndex((servicePage) => {
        return servicePage.identifier === cmsId
    })

    const isPageWithoutMenu = currentCustomerServicePageIndex === -1

    useEffect(() => {
        if (loading) {
            return null
        }
        setIsCmsPageWithoutMenu(isPageWithoutMenu)
    }, [loading, isPageWithoutMenu])

    if (loading) {
        return <CmsPageMenuSkeleton />
    }

    if (isPageWithoutMenu) {
        return null
    }

    const currentServicePage = customerService[currentCustomerServicePageIndex]

    return (
        <div className={styles.sidebar}>
            <Title type="h2" className={styles.title} text={currentServicePage.title} />
            {
                customerService.length && (
                    <div className={classNames(styles.menu, className)}>
                        {
                            customerService.map(({ identifier, title }) => {
                                return (
                                    <CmsPageMenuItem
                                        key={identifier}
                                        to={identifier}
                                        title={title}
                                    />
                                )
                            })
                        }
                    </div>
                )
            }
        </div>
    )
}

export default CmsPageMenu

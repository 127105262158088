import Overlay from '@pwa-onilab/ui/components/UI/Overlay'
import MenuList from 'components/overrides/modules/Menu/elements/MenuList/MenuList'
import IMenuObjData from 'components/overrides/modules/Menu/IMenuObjData'
import { DropdownMenu } from 'modules/Menu'
import { Dispatch } from 'react'

import styles from './Menu.module.scss'

interface IMenuProps {
    menuData: IMenuObjData[]
    activeCategory: IMenuObjData
    setActiveCategory: Dispatch<IMenuObjData>
}

const Menu = (
    {
        menuData,
        activeCategory,
        setActiveCategory,
    }: IMenuProps,
) => {
    if (!menuData?.length) {
        return null
    }

    const onClick = (event) => {
        if (event.target.tagName !== 'A') {
            event.stopPropagation()
        }
    }

    return (
        <div onClick={onClick}>
            <MenuList
                menuData={menuData}
                activeCategoryName={activeCategory?.name}
                setActiveCategory={setActiveCategory}
            />
            {
                !!activeCategory && <Overlay className={styles.overlay} />
            }
            <DropdownMenu activeCategory={activeCategory} setActiveCategory={setActiveCategory} />
        </div>
    )
}

export default Menu

import { IImageProps } from '@pwa-onilab/ui/components/UI/Image'
import { ITitle } from '@pwa-onilab/ui/components/UI/Title'
import { PageAuthor, PageBackButton, PageMainImage, PageTitle } from 'elements/BlogStatePages/elements'
import { IPageAuthor } from 'elements/BlogStatePages/elements/PageAuthor/PageAuthor'
import { IStatePageBackButton } from 'elements/BlogStatePages/elements/PageBackButton/PageBackButton'

import styles from './PageIntroBlockMobile.module.scss'

interface IPageIntroBlockMobile extends IPageAuthor{
    backBtnUrlKey: IStatePageBackButton['urlKey']
    title: ITitle['text']
    imageSrc: IImageProps['src']
}

const PageIntroBlockMobile = (
    {
        backBtnUrlKey,
        title,
        author,
        createdAt,
        imageSrc,
    }: IPageIntroBlockMobile) => {
    return (
        <>
            <div className={styles.wrapper}>
                <PageBackButton urlKey={backBtnUrlKey} className={styles.backButton} />
                <PageTitle text={title} />
                <PageMainImage className={styles.image} image={imageSrc} />
            </div>
            <PageAuthor author={author} createdAt={createdAt} />
        </>
    )
}

export default PageIntroBlockMobile

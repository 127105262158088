import { useQuery } from '@apollo/client'
import { GetLocationQuery } from 'schemas'

interface IUseLocationQueryVariables {
    locationId?: number
    urlKey?: string
}

const useLocationQuery = (variables: IUseLocationQueryVariables) => {
    const { data, loading } = useQuery(GetLocationQuery, {
        variables,
        skip: !variables || !variables.locationId || !variables.urlKey,
    })

    return {
        data: data?.getLocation || undefined,
        loading,
    }
}

export default useLocationQuery

import { ModalHeaderBackArrow } from '@pwa-onilab/ui/components/elements/Modal/elements'
import { HOME_ROUTE } from '@pwa-onilab/ui/constants'
import { checkIsPrevUrlFromOurSite } from '@pwa-onilab/ui/helpers/urlParams'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'

interface IPageMobileHeaderGoBackButton extends IClassName {
    children: string
}

const PageMobileHeaderGoBackButton = ({ children, className }: PropsWithChildren<IPageMobileHeaderGoBackButton>) => {
    const navigate = useNavigate()
    const navigateBack = () => {
        const isPrevPageFromOurSite = checkIsPrevUrlFromOurSite(document.referrer)
        if (isPrevPageFromOurSite) {
            // FIXME this logic work not good with sorts/filters
            navigate(-1)
        } else {
            navigate(HOME_ROUTE)
        }
    }

    return (
        <ModalHeaderBackArrow onDismiss={navigateBack} className={className}>
            {children}
        </ModalHeaderBackArrow>
    )
}

export default PageMobileHeaderGoBackButton

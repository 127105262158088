import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const getBreadCrumbs: QueryResolvers['getBreadCrumbs'] = async (_, { pageType, urlKey } = {}) => {
    const { data: { breadcrumbs = {} } = {} } = await (
        api.graphql(
            gql`
                query($pageType: BreadcrumbsPageType!, $urlKey: String!) {
                    breadcrumbs(
                        page_type: $pageType
                        filter: {
                            url_key: $urlKey,
                        }
                    ) {
                        items {
                            url_path
                            name
                        }
                    }
                }
            `,
        ).query({
            pageType,
            urlKey,
        })
    )

    if (!breadcrumbs) {
        return null
    }

    // skip first home crumb
    return breadcrumbs.items?.slice(1).map((crumb, idx: number) => {
        const isLastCrumb = idx + 2 === breadcrumbs.items.length

        return {
            id: crumb.name,
            level: idx,
            name: crumb.name,
            url: {
                to: crumb.url_path,
                id: crumb.name,
                type: isLastCrumb ? pageType : null,
            },
            __typename: 'Breadcrumb',
        }
    })
}

export default getBreadCrumbs

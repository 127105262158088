import styles from './MobileMenuPlaceholder.module.scss'

const MobileMenuPlaceholder = () => (
    <div className={styles.wrapper}>
        <div className={styles.title} />
        <div className={styles.item} />
        <div className={styles.item} />
        <div className={styles.item} />
        <div className={styles.item} />
        <div className={styles.item} />
        <div className={styles.item} />
        <div className={styles.item} />
        <div className={styles.title} />
        <div className={styles.item} />
        <div className={styles.title} />
        <div className={styles.title} />
    </div>
)

export default MobileMenuPlaceholder

import { Product } from '@pwa-concept/modules/graphql'
import { useProducts } from '@pwa-concept/modules/product'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { Section } from '@pwa-onilab/ui/components/UI/Section'
import { CarouselSectionSkeleton } from '@pwa-onilab/ui/components/widgets/CarouselSection'
import { IWidgetHeaderProps, WidgetHeader } from '@pwa-onilab/ui/components/widgets/WidgetHeader'
import { classNames } from '@pwa-onilab/ui/helpers'
import CarouselSectionSlider
    from 'components/overrides/widgets/CarouselSection/CarouselSectionSlider/CarouselSectionSlider'
import { DisablePageBuilderStyles } from 'elements/DisablePageBuilderStyles'
import { memo } from 'react'

import styles from './CarouselSection.module.scss'

interface ICarouselSectionProps extends IWidgetHeaderProps {
    products: Product[]
    target_url?: string // TODO check type of this object, in code bellow we use in as object
}

const CarouselSection = ({
    title,
    products,
    target_url: targetUrl,
}: ICarouselSectionProps,
) => {
    const skuArray = products?.map((product) => product.sku)

    const {
        data: { items } = {},
        loading,
    } = useProducts({ sku: skuArray }, { skip: !skuArray?.length })

    if (!skuArray?.length) return null

    if (loading) {
        return <CarouselSectionSkeleton isTo />
    }

    if (!items?.length) {
        return null
    }

    const itemsListName = `${title} carousel widget`

    return (
        <Section className={classNames(styles.wrapper)}>
            <DisablePageBuilderStyles>
                <Container>
                    <WidgetHeader title={title} to={targetUrl?.url} />
                    <CarouselSectionSlider items={items} itemsListName={itemsListName} />
                </Container>
            </DisablePageBuilderStyles>
        </Section>
    )
}

export default memo(CarouselSection)

import { AuthWrapper } from '@pwa-onilab/ui/components/modules/Auth/elements'

import styles from './MobileMenuAccountPage.module.scss'

const MobileMenuAccountPage = () => {
    return (
        <div className={styles.container}>
            <AuthWrapper />
        </div>
    )
}

export default MobileMenuAccountPage

import { CmsRouteType } from '@pwa-concept/modules/graphql'
import { Link, LinkProps } from '@pwa-onilab/ui/components/UI/Link'
import { HOME_ROUTE } from '@pwa-onilab/ui/constants/navigation'
import { useRouter } from '@pwa-onilab/ui/hooks'
import { PropsWithChildren } from 'react'

export interface LinkEntityProps extends Omit<LinkProps, 'state'> {
    data: Partial<{
        __typename: string
        id: string
    }>
}

const CMS_CONTENT_TYPE_TO_PAGE = {
    cms_page_link: CmsRouteType.CmsPage,
    cms_page: CmsRouteType.CmsPage,
    product: CmsRouteType.Product,
    category: CmsRouteType.Category,
}

const cmsStateResolve = (data: any) => {
    if (!data?.typename) {
        return {}
    }

    if (data.entity?.id && data.__typename === 'MenuNode' && CMS_CONTENT_TYPE_TO_PAGE[data.entity.type]) {
        return {
            cms: {
                id: data.entity.id,
                type: CMS_CONTENT_TYPE_TO_PAGE[data.entity.type],
            },
        }
    }

    if (data.content?.id && data.__typename.startsWith?.('Node') && CMS_CONTENT_TYPE_TO_PAGE[data.content.type]) {
        return {
            cms: {
                id: data.content.id,
                type: CMS_CONTENT_TYPE_TO_PAGE[data.content.type],
            },
        }
    }

    if (!data.id) {
        return {}
    }

    if (data.__typename.endsWith?.('Product')) {
        // FIXME we dont use id for frontend caching on product
        return {
            cms: {
                id: data.id,
                type: CmsRouteType.Product,
            },
        }
    }

    if (data.__typename.endsWith?.('Category')) {
        return {
            cms: {
                id: data.id,
                type: CmsRouteType.Category,
            },
        }
    }

    if (data.__typename === 'Cms') {
        return {
            cms: {
                id: data.id,
                type: CmsRouteType.CmsPage,
            },
        }
    }

    return {}
}

const getUrlFormEntityUrl = (entityUrl) => {
    if (typeof entityUrl === 'string') {
        return entityUrl
    }

    return entityUrl?.to || HOME_ROUTE
}

const LinkEntity = (
    {
        data,
        to,
        ...props
    }: PropsWithChildren<LinkEntityProps>,
) => {
    const { getEntityUrl } = useRouter()

    const entityUrl = to ?? getEntityUrl(data)

    const isCustomUrl = data?.type === 'custom_url' || false

    const resultTo = isCustomUrl
        ? data?.url || data?.content?.url || HOME_ROUTE
        : getUrlFormEntityUrl(entityUrl)

    const state = cmsStateResolve(data)

    return (
        <Link {...props} to={resultTo} state={state} />
    )
}

export default LinkEntity

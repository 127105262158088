import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const useIsHideAccountHeader = () => {
    const { pathname } = useLocation()

    return useMemo(() => {
        const parts = pathname.split('/', 3)
        if (parts.length > 1 && parts[parts.length - 1] === '') {
            parts.pop()
        }

        return parts.length > 2
    }, [pathname])
}

export default useIsHideAccountHeader

import { module } from '@pwa-concept/core'
import { AppProductModule } from 'app/modules/product'

import graphqlSchemas from './graphql/schemas'
import stores from './graphql/stores'

const StrainsForSaleModule = module(() => ({
    modules: [
        AppProductModule,
    ],
    graphqlSchemas,
    graphqlResolvers: stores?.resolvers,
    graphqlStoreFragments: stores?.fragments,
    graphqlStorePossibleTypes: stores?.possibleTypes,
}))

export default StrainsForSaleModule

import { AccountOrderBlock, IAccountOrderHistoryWithItems } from '@pwa-onilab/ui/components/modules/AccountSubpages'

import styles from './AccountOrdersMobile.module.scss'

const AccountOrdersMobile = ({ orders }: IAccountOrderHistoryWithItems) => {
    if (!orders?.length) {
        return null
    }
    return (
        <div className={styles.wrapper}>
            {
                orders.map((order) => <AccountOrderBlock key={order.id} order={order} />)
            }
        </div>
    )
}

export default AccountOrdersMobile

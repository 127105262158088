import { useGetStrainFamiliesQuery } from 'app/modules/strain-family/graphql/queries/GetStrainFamilies'
const useGetStrainFamilies = () => {
    const {
        data: { getFamilyStrains: data } = {},
        loading,
    } = useGetStrainFamiliesQuery({
        variables: {
            pageSize: 1000,
            currentPage: 1,
        },
    })

    return {
        data,
        loading,
    }
}

export default useGetStrainFamilies

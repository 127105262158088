import { createContext, memo, PropsWithChildren, useContext } from 'react'
import { Swiper, SwiperProps } from 'swiper/react/swiper-react'

const SliderContext = createContext<{
    value?: Swiper
    slider?: Partial<Parameters<Swiper['_swiper']>[0]>
}>({
    value: {},
    slider: {},
})
const useSliderContext = () => useContext(SliderContext)

interface SliderProviderProps {
    initial: SwiperProps
    slider?: Partial<Parameters<Swiper['_swiper']>[0]> & Partial<Swiper>
}

const SliderProvider = memo((
    {
        children,
        initial = {},
        slider = {},
    }: PropsWithChildren<SliderProviderProps>,
) => {
    const context = {
        value: initial,
        slider,
    }

    return (
        <SliderContext.Provider value={context}>
            {children}
        </SliderContext.Provider>
    )
})

export { SliderProvider, useSliderContext }

import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { ACCOUNT_DETAILS_ROUTE } from '@pwa-onilab/ui/constants/navigation'
import { useTranslation } from 'react-i18next'

import styles from './AccountDeleteBackButton.module.scss'

const AccountDeleteBackButton = () => {
    const { t } = useTranslation()
    return (
        <Anchor to={ACCOUNT_DETAILS_ROUTE} className={styles.anchor}>
            <Icon name="chevron" className={styles.arrow} />
            {t('global.back')}
        </Anchor>
    )
}

export default AccountDeleteBackButton

import { BlogAuthor } from '@pwa-concept/modules/graphql'
import { Breadcrumbs } from '@pwa-onilab/ui/components/modules/Breadcrumbs'
import { BreadcrumbsPageTypeEnum, useBreadCrumbs } from 'app/modules/breadcrumb'

const AuthorPageBreadcrumbs = ({ author }: { author: BlogAuthor}) => {
    const { data: breadcrumbs, loading: breadCrumbsLoading } = useBreadCrumbs({
        pageType: BreadcrumbsPageTypeEnum.BLOG_AUTHOR,
        urlKey: author.urlKey,
    })

    return <Breadcrumbs items={breadcrumbs} loading={breadCrumbsLoading} />
}

export default AuthorPageBreadcrumbs

import { useCustomerAddressCreate } from '@pwa-concept/modules/customer-addresses'
import { ModalProps } from '@pwa-onilab/ui/components/elements/Modal/Modal'
import { AddressModal } from '@pwa-onilab/ui/components/modules/AddressModal'
import { extractAddressFromFormValues } from '@pwa-onilab/ui/mappers'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const AddressModalCreate = ({ isOpen, onDismiss }: Pick<ModalProps, 'isOpen' | 'onDismiss'>) => {
    const { t } = useTranslation()
    const createAddress = useCustomerAddressCreate()

    const submitHandler = useCallback(async (formValues) => {
        const input = extractAddressFromFormValues(formValues)

        await createAddress(input)
            .then(() => {
                toast.success(t('addressForm.create.success'))
                // eslint-disable-next-line  @typescript-eslint/no-floating-promises
                onDismiss()
            })
            .catch((error) => toast.error(error.message))
    }, [])

    return (
        <AddressModal
            isOpen={isOpen}
            onDismiss={onDismiss}
            onSubmit={submitHandler}
            title={t('AccountPage.addresses.addNew')}
        />
    )
}

export default AddressModalCreate

import { useCustomerOrder } from '@pwa-concept/modules/customer-orders'
import { IOrderComponent, OrderSummaryItem, OrderSummaryItemPlaceholder } from '@pwa-onilab/ui/components/modules/Order'
import Title from '@pwa-onilab/ui/components/UI/Title'
import { useTranslation } from 'react-i18next'

import styles from './OrderSummaryItems.module.scss'

const OrderSummaryItems = ({ orderNumber }: IOrderComponent) => {
    const { t } = useTranslation()
    const { data: { products = [] } = {}, loading } = useCustomerOrder(orderNumber)

    const totalQty = products.reduce((acc, product) => acc + product.quantityOrdered, 0)

    return (
        <div className={styles.wrapper}>
            <Title
                type="h4"
                text={t('AccountPage.orders.summary', { count: totalQty })}
                className={styles.ordersTitle}
            />
            {
                loading ? <OrderSummaryItemPlaceholder />
                    : products.map((product) => {
                        return <OrderSummaryItem key={product.id} product={product} />
                    })
            }
        </div>
    )
}

export default OrderSummaryItems

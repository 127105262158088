import { AccountNavigationLink } from '@pwa-onilab/ui/components/modules/Account'
import { ACCOUNT_NAVIGATION_LIST } from '@pwa-onilab/ui/constants/navigation'
import { useTranslation } from 'react-i18next'

import styles from './AccountNavigationList.module.scss'

const AccountNavigationList = () => {
    const { t } = useTranslation()

    return (
        <ul className={styles.navigationList}>
            {ACCOUNT_NAVIGATION_LIST.map(({ url, i18key }) => {
                return <AccountNavigationLink key={url} url={url} title={t(i18key)} />
            })}
        </ul>
    )
}

export default AccountNavigationList

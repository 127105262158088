import { Resolvers } from '@pwa-concept/modules/graphql'

const CategoryAsStrains: Resolvers['CategoryAsStrains'] = {
    categoryName: (_, __, { context }) => {
        return context?.category?.name || null
    },
    categoryUrl: (_, __, { context }) => {
        return context?.url_key || null
    },
}

export default CategoryAsStrains

import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { PropsWithChildren } from 'react'

import IOneTimePasswordModal from './IOneTimePassword'
import styles from './OneTimePasswordModal.module.scss'

const OneTimePasswordModal = ({ children, onDismiss, isOpen }: PropsWithChildren<IOneTimePasswordModal>) => {
    return (
        <Modal
            isOpen={isOpen}
            isDraggable
            isUseBorderRadius
            isOverAll
            onDismiss={onDismiss}
            className={styles.modal}
            isShowCloseButton
            isHideMobileCloseButton
        >
            {children}
        </Modal>
    )
}

export default OneTimePasswordModal

import { module } from '@pwa-concept/core'
import { PaginationModule } from '@pwa-concept/modules/pagination'
import { ProductModule } from '@pwa-concept/modules/product'
import { WISHLIST_KEYFIELD } from '@pwa-concept/modules/wishlist/constants'

import graphqlSchemas from './graphql/schemas'
import stores from './graphql/stores'

const WishlistModule = module(() => ({
    modules: [
        ProductModule,
        PaginationModule,
    ],
    graphqlSchemas,
    graphqlResolvers: stores?.resolvers,
    graphqlStoreFragments: stores?.fragments,
    graphqlStorePossibleTypes: stores?.possibleTypes,
    graphqlPolicies: {
        Wishlist: {
            keyFields: (args) => {
                if (args.sharingCode) {
                    return `${WISHLIST_KEYFIELD}:${args.sharingCode}`
                }
                return WISHLIST_KEYFIELD // we should store only one Wishlist into cache
            },
        },
    },
}))

export default WishlistModule

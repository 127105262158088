import { useQuery } from '@apollo/client'
import { MenuQuery } from '@pwa-onilab/ui/schemas'
import { useMemo } from 'react'

const getMenuDataNode = (node, level: number) => {
    const output = {
        content: node.content,
        type: node.type,
        nodeId: node.nodeId,
        url: node.content.url || node.content.urlPath,
    }
    if (level === 1) {
        output['name'] = node.title
    } else {
        output['title'] = node.title
    }

    if (level < 3) {
        output['items'] = node.nodes?.map((childNode) => getMenuDataNode(childNode, level + 1))
    }

    return output
}

const useCategoryPageMenu = () => {
    const { data, loading } = useQuery(MenuQuery, {
        // no cache was added becouse we can have type of node Product wich will call our default Product resolvers
        fetchPolicy: 'no-cache',
    })

    const menuData = useMemo(() => {
        return data?.menu?.nodes?.map((node) => {
            return getMenuDataNode(node, 1)
        })
    }, [data])

    return {
        stockData: data,
        data: menuData,
        loading,
    }
}

export default useCategoryPageMenu

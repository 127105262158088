import Cart from './Cart.graphql'
import CartAddItem from './CartAddItem.graphql'
import CartAssignToCustomer from './CartAssignToCustomer.graphql'
import CartRemoveItem from './CartRemoveItem.graphql'
import CartReorderItems from './CartReorderItems.graphql'
import CartSetBillingAddress from './CartSetBillingAddress.graphql'
import CartSetGuestEmail from './CartSetGuestEmail.graphql'
import CartSetPaymentMethod from './CartSetPaymentMethod.graphql'
import CartSetShippingAddress from './CartSetShippingAddress.graphql'
import CartSetShippingMethod from './CartSetShippingMethod.graphql'
import CartUpdateItem from './CartUpdateItem.graphql'
import CreateGenerateId from './CreateGenerateId.graphql'

export default [
    Cart,
    CartAddItem,
    CartAssignToCustomer,
    CartRemoveItem,
    CartReorderItems,
    CartSetBillingAddress,
    CartSetGuestEmail,
    CartSetPaymentMethod,
    CartSetShippingAddress,
    CartSetShippingMethod,
    CartUpdateItem,
    CreateGenerateId,
]

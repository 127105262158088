import { MediaBreakpointDown, MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { HOME_ROUTE } from '@pwa-onilab/ui/constants/navigation'
import { Navigate } from 'react-router'
import { Outlet } from 'react-router-dom'

const MobileMenuPage = () => {
    return (
        <>
            <MediaBreakpointUp name="lg">
                <Navigate to={HOME_ROUTE} />
            </MediaBreakpointUp>

            <MediaBreakpointDown name="lg">
                <Outlet />
            </MediaBreakpointDown>
        </>
    )
}

export default MobileMenuPage

import { paginationCellClick } from '@pwa-onilab/ui/components/modules/Pagination/helpers/PaginationCellClick'

export const getPaginationActionDispatcher = (
    {
        setServerPage,
        setClientPage,
        setCachedData,
        loadedPagesSet,
    }) => {
    return (actionType, params) => {
        switch (actionType) {
            case 'paginationClick': {
                const { page } = params
                setServerPage(page)
                setClientPage(page)

                paginationCellClick(setCachedData, loadedPagesSet, params)
                break
            }
            case 'paginationPageChange': {
                const { page } = params
                setClientPage(page)
                break
            }
            case 'paginationLoadMore': {
                setClientPage(prevPage => prevPage + 1)
                break
            }
            default: {
                break
            }
        }
    }
}

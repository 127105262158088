import Container from '@pwa-onilab/ui/components/UI/Container'
import { PromoBannersSlider } from '@pwa-onilab/ui/components/widgets/PromoBanners'
import { filteringByObjects } from '@pwa-onilab/ui/helpers'
import { DisablePageBuilderStyles } from 'elements/DisablePageBuilderStyles'
import { memo } from 'react'

import styles from './PromoBanners.module.scss'

interface IPromoBannersProps {
    data: {
        widget_type: string
        [index: number]: {
            brand: string
            description: string
            desktop_image: string
            link_text: string
            link_url: string
            mobile_image: string
            title: string
            type: string
        }
    }
}

const PromoBanners = (data: IPromoBannersProps) => {
    if (!data) return null

    const items = filteringByObjects(data)

    if (!items?.length) return null

    return (
        <DisablePageBuilderStyles>
            <Container
                isMobileFullWide
                className={styles.container}
            >
                <PromoBannersSlider items={items} />
            </Container>
        </DisablePageBuilderStyles>
    )
}

export default memo(PromoBanners)

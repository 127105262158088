import { useMutation } from '@apollo/client'
import { $auth } from '@pwa-concept/core/models'
import { useCustomer, useCustomerUpdate } from '@pwa-concept/modules/customer'
import { SubscribeEmailToNewsletterMutation } from '@pwa-onilab/ui/schemas'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const useUnSubscribeEmailFromNewsletter = () => {
    const { register, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm()
    const { t } = useTranslation()

    const { data: customer, loading: customerLoading } = useCustomer()
    const updateCustomer = useCustomerUpdate()
    const [subscribeEmailToNewsletterMutation] = useMutation(SubscribeEmailToNewsletterMutation)

    const onSubmitSubscribeEmailForm = useCallback(async ({ email }) => {
        try {
            if ($auth.isToken && email === customer?.email) {
                await updateCustomer({
                    isSubscribed: true,
                })
            } else {
                await subscribeEmailToNewsletterMutation(
                    {
                        variables: {
                            email,
                        },
                        ignoreResults: true,
                    },
                )
            }
            reset()

            toast.success(t('AccountPage.newsletters.subscribe.success'))
        } catch (error) {
            toast.error(error.message)
        }
    }, [customer])

    return {
        register,
        errors,
        handleSubmit,
        onSubmitSubscribeEmailForm,
        subscribeEmailToNewsletterLoading: isSubmitting,
        disabled: ($auth.isToken && customerLoading),
    }
}

export default useUnSubscribeEmailFromNewsletter

import { useState, useEffect } from 'react';
import { gql } from 'graphql-tag';
import api from '@pwa-concept/core/api';

export function AccountCardsQuery() {
    const [customerSavedCards, setCustomerSavedCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const { data: { getCustomerSavedCards = {} } = {}, errors } = await api.graphql(
                    gql`
                        query {
                            getCustomerSavedCards {
                                total_records
                                allCards {
                                    id
                                    customer_id
                                    customer_email
                                    customer_ip
                                    profile_id
                                    payment_id
                                    method
                                    active
                                    created_at
                                    updated_at
                                    last_use
                                    expires
                                    address
                                    additional
                                    hash
                                }
                            }
                        }
                    `,
                ).query(undefined, {
                    errorPolicy: 'all',
                });

                if (getCustomerSavedCards) {
                    setCustomerSavedCards(getCustomerSavedCards.allCards);
                }

                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    return { customerSavedCards, loading, error };
}

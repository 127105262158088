import { FeatureMutationHookReturnType } from '@pwa-concept/core'
import {
    RequestOtpAuthMutationVariables,
} from '@pwa-concept/modules/one-time-password/graphql/mutations/RequestOTPAuth'
import { useRequestOtpResetMutation } from '@pwa-concept/modules/one-time-password/graphql/mutations/RequestOTPReset'

const useRequestResetOTP = () => {
    const [requestResetOTP] = useRequestOtpResetMutation()

    return async (email: RequestOtpAuthMutationVariables['email']): FeatureMutationHookReturnType<typeof useRequestOtpResetMutation, 'requestOTPReset'> => {
        const { data, errors } = await requestResetOTP({
            variables: {
                email,
            },
            ignoreResults: true,
        })

        return {
            data: data?.requestOTPReset,
            errors,
        }
    }
}

export default useRequestResetOTP

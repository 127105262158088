import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './BreadCrumbsPlaceholder.module.scss'

interface IBreadCrumbsPlaceholder {
    className?: string
}

const BreadCrumbsPlaceholder = ({ className }: IBreadCrumbsPlaceholder) => {
    return (
        <div className={classNames(styles.placeholder, className)} />
    )
}

export default BreadCrumbsPlaceholder

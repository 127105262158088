import { Wishlist } from '@pwa-concept/modules/graphql'
import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { WishlistItems, WishlistMobileModalHeader } from '@pwa-onilab/ui/components/modules/Wishlist'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './WishlistMobileModal.module.scss'

interface WishlistMobileModalProps {
    className: string
    wishlist: Wishlist
    loading: boolean
}

const WishlistMobileModal = (
    {
        className,
        wishlist,
        loading,
    }: Partial<WishlistMobileModalProps>,
) => (
    <Modal
        isOpen
        header={(
            <WishlistMobileModalHeader
                numAllItems={wishlist?.items_count}
            />
        )}
        headerClassName={styles.header}
        mainClassName={styles.main}
        className={classNames(className)}
        variant="wishlist"
    >
        <Container>
            <WishlistItems
                wishlist={wishlist}
                loading={loading}
            />
        </Container>
    </Modal>
)

export default WishlistMobileModal

import { TextOnlyButton } from '@pwa-onilab/ui/components/elements/Buttons'
import { Trans, useTranslation } from 'react-i18next'

import styles from './CartItemUndoDeleteMessage.module.scss'

interface CartItemUndoDeleteMessageProps {
    name: string
    onClick: () => void
}

const CartItemUndoDeleteMessage = (
    {
        name,
        onClick,
    }: CartItemUndoDeleteMessageProps,
) => {
    const { t } = useTranslation()

    return (
        <div className={styles.wrapper}>
            <div className={styles.messageWrapper}>
                <Trans
                    i18nKey="cart.removeItem.undoDescription"
                    values={{
                        text: name,
                    }}
                    components={
                        {
                            wrapper: <span className={styles.dottedText} />,
                        }
                    }
                />
            </div>
            <TextOnlyButton isBold isUnderline className={styles.undoButton} onClick={onClick}>
                {t('cart.removeItem.buttons.undo')}
            </TextOnlyButton>
        </div>
    )
}

export default CartItemUndoDeleteMessage

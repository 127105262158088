import { Skeleton } from '@pwa-onilab/ui/components/elements/Skeleton'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './HeaderTopMenuPlaceholder.module.scss'

interface IHeaderTopMenuPlaceholderProps {
    className: string
}

const HeaderTopMenuPlaceholder = (
    {
        className,
    }: Partial<IHeaderTopMenuPlaceholderProps>,
) => (
    <div className={classNames(styles.list, className)}>
        <Skeleton className={styles.item} />
        <Skeleton className={styles.item} />
        <Skeleton className={styles.item} />
        <Skeleton className={styles.item} />
    </div>
)

export default HeaderTopMenuPlaceholder

import { tPaginationDispatcher } from '@pwa-onilab/ui/components/modules/Pagination/paginationInterfaces'
import { Dispatch, RefObject, SetStateAction, useMemo } from 'react'

interface ICategoryItemsPagesApi {
    setIsShowLoadMoreButton: Dispatch<SetStateAction<boolean>>
    topElementRef: RefObject<HTMLElement>
    dispatchPaginationAction: tPaginationDispatcher
}

const usePaginationApi = (
    {
        setIsShowLoadMoreButton,
        topElementRef,
        dispatchPaginationAction,
    }: ICategoryItemsPagesApi,
) => {
    return useMemo(() => {
        return {
            onLoadMoreButtonClick: () => {
                dispatchPaginationAction('paginationLoadMore')
                setIsShowLoadMoreButton(false)
            },
            //
            onScrollDownPagination: async (page) => {
                dispatchPaginationAction('paginationPageChange', { page })
            },
            //
            onPaginationClick: (page: number) => {
                topElementRef.current.scrollIntoView({
                    behavior: 'smooth',
                })
                setIsShowLoadMoreButton(false)

                dispatchPaginationAction('paginationClick', { page })
            },
            onCategoryItemsListScreen: (page: number) => () => {
                dispatchPaginationAction('paginationPageChange', { page })
            },

        }
    }, [])
}

export default usePaginationApi

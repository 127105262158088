import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { AccOrderHistoryPlaceholderMobile, ORDERS_PAGE_SIZE } from '@pwa-onilab/ui/components/modules/AccountSubpages'

import styles from './AccountOrderHistoryPlaceholder.module.scss'

const AccountOrderHistoryPlaceholder = () => {
    const { isBreakpointDown } = useMediaBreakpoint()
    if (isBreakpointDown('lg')) {
        return <AccOrderHistoryPlaceholderMobile />
    }

    const ARRAY_OF_ROWS = Array.from({ length: ORDERS_PAGE_SIZE }, (_, i) => i)

    return (
        <div className={styles.wrapper}>
            <div className={styles.title} />
            <div className={styles.rows}>
                {ARRAY_OF_ROWS.map((rowNumber) => {
                    return <div key={rowNumber} className={styles.row} />
                })}
            </div>
        </div>
    )
}

export default AccountOrderHistoryPlaceholder

import { Resolvers } from '@pwa-concept/modules/graphql'

const StoreConfigStructuredData: Resolvers['StoreConfigStructuredData'] = {
    website: (_, __, { context: { structured_data: context } = {} }) => {
        if (!context?.website) {
            return null
        }

        return {
            description: context.website.description || '',
            name: context.website.name || '',
            __typename: 'StructuredDataWebsite',
        }
    },

    organization: (_, __, { context: { structured_data: { organization } = {} } } = {}) => {
        if (!organization) {
            return null
        }

        return {
            __typename: 'StructuredDataOrganization',
            addressCountry: organization.address_country || '',
            addressLocality: organization.address_locality || '',
            addressPostcode: organization.address_postcode || '',
            addressStreet: organization.address_street || '',
            alternativeName: organization.alternative_name || '',
            brand: organization.brand || '',
            description: organization.description || '',
            email: organization.email || '',
            name: organization.name || '',
            telephone: organization.telephone || '',
        }
    },

    mobilePhoneStore: (_, __, {
        context:
        { structured_data: { mobile_phone_store: context } = {} },
    } = {}) => {
        if (!context) {
            return null
        }

        return {
            __typename: 'StructuredDataMobilePhoneStore',
            address: context.address || '',
            contactPointTelephone: context.contact_point_telephone || '',
            contactPointType: context.contact_point_type || '',
            name: context.name || '',
            telephone: context.telephone || '',
            workDays: context.work_days || [],
            workHoursEnd: context.work_hours_end || '',
            workHoursStart: context.work_hours_start || '',
            priceRange: context.price_range || '',
        }
    },

    offer: (_, __, { context: { structured_data: { offer: context } = {} } = {} }) => {
        if (!context) {
            return null
        }

        return {
            __typename: 'StructuredDataOffer',
            availablePayments: context?.available_payments || null,
        }
    },

}

export default StoreConfigStructuredData

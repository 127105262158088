import { CustomerAddressInput } from '@pwa-concept/modules/graphql'

const extractFieldsFromCustomerAddressInput = (address: CustomerAddressInput) => {
    return {
        default_billing: address.defaultBilling || false,
        default_shipping: address.defaultShipping || false,
        firstname: address.firstName || '',
        middlename: address.middleName || '',
        lastname: address.lastName || '',
        street: address.street || ['', ''],
        telephone: address.telephone || '',
        city: address.city || '',
        company: address.company || '',
        postcode: address.postcode || '',
        country_code: address.countryCode || '',
        region: {
            region_id: address.region?.regionId,
            region_code: address.region?.regionCode,
            region: address.region?.region,
        },
        vat_id: address.vatId || '',
    }
}

export default extractFieldsFromCustomerAddressInput

import { CategoryNavListSkeleton } from '@pwa-onilab/ui/components/modules/Category'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { Section } from '@pwa-onilab/ui/components/UI/Section'
import { classNames } from '@pwa-onilab/ui/helpers'

import css from './CategoryNav.module.scss'

const CategoryNavSkeleton = () => (
    <Section className={classNames(css.navigation)}>
        <Container isMobileFullWide>
            <CategoryNavListSkeleton />
        </Container>
    </Section>
)

export { CategoryNavSkeleton }

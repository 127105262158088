import { DefaultModalContentProps } from '@pwa-onilab/ui/components/elements/Modal/elements'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './ModalHeaderBackArrow.module.scss'

interface ModalHeaderBackArrowProps extends Pick<DefaultModalContentProps, 'onDismiss'> {
    className?: string
    mainClassName?: string
}

const ModalHeaderBackArrow = (
    {
        children,
        onDismiss,
        className,
        mainClassName,
    }: PropsWithChildren<ModalHeaderBackArrowProps>,
) => {
    const { t } = useTranslation()

    return (
        <header className={classNames(styles.wrapper, className)}>
            {onDismiss && (
                <ButtonInstance
                    className={styles.back}
                    onClick={onDismiss}
                    aria-label={t('global.back')}
                >
                    <Icon
                        name="leftArrow"
                        className={styles.leftArrowIcon}
                    />
                </ButtonInstance>
            )}
            <div className={classNames(styles.main, mainClassName)}>
                {children}
            </div>
        </header>
    )
}

export { ModalHeaderBackArrow }

import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './Placeholder.module.scss'

interface IPlaceholder {
    className: string
}

const Placeholder = ({ className }: IPlaceholder) => {
    return <div className={classNames(styles.placeholder, className)} />
}

export default Placeholder

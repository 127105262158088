import { CartItems } from '@pwa-concept/modules/graphql'
import { DeletedItemInterface } from '@pwa-onilab/ui/hooks/Cart/useCartItemUndoOperation'
import { useModalWithStoredId } from '@pwa-onilab/ui/hooks/stateMachines'
import { useCallback, useState } from 'react'

export interface IOpenDeleteItemModal extends Pick<DeletedItemInterface, 'indexInArray'> {
    cartItem: CartItems
}

// TODO mb better rewrite it using reactive var? how do you think?
const useDeletingItemModal = () => {
    const [cartItem, setCartItem] = useState<CartItems>(null)
    const [indexInArray, setIndexInArray] = useState<IOpenDeleteItemModal['indexInArray']>(null)

    const {
        isOpen,
        openModal,
        closeModal,
        id,
    } = useModalWithStoredId<string>()

    const openDeleteItemModalHandler = useCallback((
        {
            indexInArray,
            cartItem,
        }: IOpenDeleteItemModal) => () => {
        openModal(cartItem.id)()

        setCartItem(cartItem)
        setIndexInArray(indexInArray)
    }, [])

    return {
        isOpen,
        openModal: openDeleteItemModalHandler,
        closeModal,
        id,
        possibleDeletedCartItem: cartItem,
        indexInArray,
    }
}

export default useDeletingItemModal

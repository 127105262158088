import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { DefaultHelmet, IDefaultHelmet } from '@pwa-onilab/ui/components/elements/DefaultHelmet'
import { SchemaHelmetWebPage } from 'elements/SchemaHelmet/SchemaHelmetWebPage'
import { useTranslation } from 'react-i18next'

const LocationPageHelmet = ({ location }) => {
    const { t } = useTranslation()
    const { data: storeConfig } = useStoreConfig()

    const meta: IDefaultHelmet['meta'] = {
        ogType: 'article',
    }

    if (location) {
        meta['title'] = location.metaTitle
        meta['description'] = location.metaDescription
        meta['keywords'] = location.metaKeywords
        meta['robots'] = location.metaRobots
    }

    if (storeConfig?.ogArticlePublisher) {
        meta.customProperties = [
            {
                name: 'article:publisher',
                value: storeConfig.ogArticlePublisher,
            },
        ]
    }

    return (
        <>
            <DefaultHelmet
                meta={meta}
                defaultTitle={t('locationsPage.states.meta.title')}
            />
            <SchemaHelmetWebPage meta={meta} />
        </>
    )
}

export default LocationPageHelmet

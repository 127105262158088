import Title, { ITitle } from '@pwa-onilab/ui/components/UI/Title'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './OrderFieldTitle.module.scss'

interface IOrderFieldTitle extends Omit<ITitle, 'type'> {
    type?: ITitle['type']
}

const OrderFieldTitle = (
    {
        className,
        text,
        type = 'h3',
    }: IOrderFieldTitle,
) => {
    return (
        <Title
            type={type}
            text={text}
            className={classNames(styles.title, className)}
        />
    )
}

export default OrderFieldTitle

import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { useCustomProductUrl } from 'hooks'

import styles from './StrainsForSaleProductsItem.module.scss'

interface IStrainsForSaleProductsItemProps {
    url: string
    sku: string
    name: string
}

const StrainsForSaleProductsItem = (
    {
        url,
        sku,
        name,
    }: IStrainsForSaleProductsItemProps,
) => {
    if (!name || !sku || !url) {
        return null
    }

    const {
        productLinkState,
        relativeUrl,
    } = useCustomProductUrl(url, sku)

    return (
        <Anchor
            key={url}
            to={relativeUrl}
            state={productLinkState}
            className={styles.item}
        >
            {name}
        </Anchor>
    )
}

export default StrainsForSaleProductsItem

import { State } from '@pwa-concept/modules/graphql'
import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { DefaultHelmet, IDefaultHelmet } from '@pwa-onilab/ui/components/elements/DefaultHelmet'
import { SchemaHelmetArticle } from 'elements/SchemaHelmet/SchemaHelmetArticle'
import { IArticleSchemaConfig } from 'elements/SchemaHelmet/SchemaHelmetArticle/SchemaHelmetArticle'
import { SchemaHelmetPerson } from 'elements/SchemaHelmet/SchemaHelmetPerson'
import { SchemaHelmetWebPage } from 'elements/SchemaHelmet/SchemaHelmetWebPage'
import { useTranslation } from 'react-i18next'

interface IStatePageHelmet {
    state: State
}

const StatePageHelmet = ({ state }: IStatePageHelmet) => {
    const { t } = useTranslation()
    const { data: storeConfig } = useStoreConfig()

    const authorName = state.author?.name || t('global.anonymous')

    const meta: IDefaultHelmet['meta'] = {
        ...state.meta,
        customProperties: [
            {
                name: 'author',
                value: authorName,
            },
            {
                name: 'article:published_time',
                value: state.createdAt,
            },
            {
                name: 'twitter:label1',
                value: 'Written by',
            },
            {
                name: 'twitter:data1',
                value: authorName,
            },
        ],
    }

    const articleConfig: IArticleSchemaConfig = {
        title: state.pageTitle,
        datePublished: state.createdAt,
        imageSrc: state.image,
    }

    if (storeConfig?.ogArticlePublisher) {
        meta.customProperties.push({
            name: 'article:publisher',
            value: storeConfig.ogArticlePublisher,
        })
    }

    return (
        <>
            <DefaultHelmet
                meta={meta}
                defaultTitle={t('statesPage.meta.title')}
            />
            <SchemaHelmetWebPage meta={meta} />
            <SchemaHelmetArticle meta={meta} config={articleConfig} author={state.author} />
            <SchemaHelmetPerson author={state.author} />
        </>
    )
}

export default StatePageHelmet

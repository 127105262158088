import { useCart } from '@pwa-concept/modules/cart'

const useCartCouponCode = () => {
    const { data = {}, loading } = useCart()
    return {
        data: data?.appliedCoupon,
        loading,
    }
}

export default useCartCouponCode

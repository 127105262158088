import { SimpleButton } from '@pwa-onilab/ui/components/elements/Buttons'
import Modal from '@pwa-onilab/ui/components/elements/Modal'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { ACCOUNT_DELETE_ROUTE } from '@pwa-onilab/ui/constants/navigation'
import { useModalStates } from '@pwa-onilab/ui/hooks'
import { useTranslation } from 'react-i18next'

import styles from './AccountDotsGoToDeletePage.module.scss'

const DOTS_ARRAY = [0, 1, 2]

const AccountDotsGoToDeletePage = () => {
    const { isModalOpen, openModal, closeModal } = useModalStates()
    const { t } = useTranslation()

    return (
        <>
            <SimpleButton className={styles.dots} onClick={openModal}>
                {DOTS_ARRAY.map((idx) => <span className={styles.dot} key={idx} />)}
            </SimpleButton>
            <Modal
                isOpen={isModalOpen}
                onDismiss={closeModal}
                isDraggable
                isUseBorderRadius
                isUseDefaultPadding
                isOverAll
            >
                <Anchor to={ACCOUNT_DELETE_ROUTE} className={styles.anchor}>
                    {t('AccountPage.informationSubpage.deleteAccount')}
                </Anchor>
            </Modal>
        </>
    )
}

export default AccountDotsGoToDeletePage

import { useCustomerOrder } from '@pwa-concept/modules/customer-orders'
import {
    IOrderPaymentMethod,
    OrderFieldPlaceholder,
    OrderFieldTitle,
    OrderPaymentMethodContent,
} from '@pwa-onilab/ui/components/modules/Order'
import { useTranslation } from 'react-i18next'

const OrderPaymentMethod = ({ orderNumber, className, defaultPaymentMethod }: IOrderPaymentMethod) => {
    const { t } = useTranslation()
    const { loading } = useCustomerOrder(orderNumber)

    return (
        <div className={className}>
            <OrderFieldTitle text={t('global.paymentMethod.title')} />
            {
                !defaultPaymentMethod && loading
                    ? <OrderFieldPlaceholder />
                    : <OrderPaymentMethodContent orderNumber={orderNumber} defaultPaymentMethod={defaultPaymentMethod} />
            }
        </div>
    )
}

export default OrderPaymentMethod

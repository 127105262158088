import { FilterItem } from '@pwa-concept/modules/graphql'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'

import styles from './FilterChoosenItem.module.scss'

interface FilterChoosenItemProps {
    className?: string
    onRemoveTag: (item: FilterItem) => void
    name: string
    value: string
}

const FilterChoosenItem = (
    {
        className,
        onRemoveTag,
        name,
        value,
    }: FilterChoosenItemProps,
) => (
    <div className={classNames(styles.wrapper, className)}>
        {
            name && (
                <div className={styles.label}>
                    {name}:
                </div>
            )
        }
        {
            value && (
                <div className={styles.option}>
                    {value}
                </div>
            )
        }
        {
            onRemoveTag && (
                <ButtonInstance
                    onClick={onRemoveTag}
                    className={styles.button}
                >
                    <Icon
                        name="cross"
                        className={styles.icon}
                    />
                </ButtonInstance>
            )
        }
    </div>
)

export default FilterChoosenItem

import { Resolvers } from '@pwa-concept/modules/graphql'

const Customer: Resolvers['Customer'] = {
    id: (_, __, { context }) => context?.email || null,
    firstName: (_, __, { context }) => context?.firstname || null,
    middleName: (_, __, { context }) => context?.middlename || null,
    lastName: (_, __, { context }) => context?.lastname || null,

    fullName: (_, __, { context }) => `${context?.firstname} ${context?.lastname}`,
    email: (_, __, { context }) => context?.email || null,
    dateOfBirth: (_, __, { context }) => context?.date_of_birth || null,
    gender: (_, __, { context }) => context?.gender || null,
    isSubscribed: (_, __, { context }) => context?.is_subscribed || null,
    addresses: (_, __, { context }) => context?.addresses || null,
}

export default Customer

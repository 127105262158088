import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const storeConfig: QueryResolvers['storeConfig'] = async (_) => {
    const { data: { storeConfig: __context = {} } = {} } = await (
        api.graphql(
            gql`
                query {
                    storeConfig {
                        ... StoreConfig
                    }
                }
            `,
        ).query(undefined, {
            errorPolicy: 'all',
        })
    )

    return {
        __context,
        __typename: 'StoreConfig',
    }
}

export default storeConfig

import { CustomerAddress } from '@pwa-concept/modules/graphql'
import { getAddressTypeMessage } from '@pwa-onilab/ui/components/modules/AccountAddress'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './AccountAddressType.module.scss'

const AccountAddressType = (
    {
        defaultShipping: isDefaultShipping,
        defaultBilling: isDefaultBilling,
    }: Pick<CustomerAddress, 'defaultShipping' | 'defaultBilling'>,
) => {
    const { t } = useTranslation()

    const typeText = t(getAddressTypeMessage(isDefaultBilling, isDefaultShipping))

    return (
        <p className={styles.wrapper}>
            {typeText}
        </p>
    )
}

export default memo(AccountAddressType)

import { useSearchValueCtx } from '@pwa-onilab/ui/components/modules/Search'
import { ISearchCategory } from '@pwa-onilab/ui/components/modules/Search/hooks/ISearchCategory'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { findAllSubstrings } from '@pwa-onilab/ui/components/widgets/FaqWidget'
import { useRouter, useSearchModal } from '@pwa-onilab/ui/hooks'
import { Fragment } from 'react'

import styles from './SearchListItems.module.scss'

interface ISearchListItemsProps {
    items: string[] | ISearchCategory[]
}

const SearchListItems = (
    {
        items,
    }: Partial<ISearchListItemsProps>,
) => {
    const searchValue = useSearchValueCtx()
    const { getSearchUrl } = useRouter()

    const { closeSearchModal } = useSearchModal()

    return (
        <ul className={styles.list}>
            {items.map((data) => {
                const name = data.name || data
                const substrings = findAllSubstrings(name, searchValue)
                const url = getSearchUrl(data.to || data)

                return (
                    <Anchor
                        key={data.key || data}
                        to={url}
                        onClick={closeSearchModal}
                        className={styles.item}
                    >
                        {substrings.map((substr) => {
                            if (substr.toLowerCase() === searchValue.toLowerCase()) {
                                return (
                                    <span key={substr} className={styles.findSubstring}>
                                        {substr}
                                    </span>
                                )
                            }
                            return (
                                <Fragment key={substr}>
                                    {substr}
                                </Fragment>
                            )
                        })}
                    </Anchor>
                )
            })}
        </ul>
    )
}

export default SearchListItems

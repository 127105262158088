import { module } from '@pwa-concept/core'
import { AuthModule } from '@pwa-concept/modules/auth'
import { CatalogModule } from '@pwa-concept/modules/catalog'
import { CountriesModule } from '@pwa-concept/modules/countries'
import { CustomerModule } from '@pwa-concept/modules/customer'
import { ConfiguredFilterModule } from '@pwa-concept/modules/filter'
import { OneTimePasswordModule } from '@pwa-concept/modules/one-time-password'
import { SortModule } from '@pwa-concept/modules/sort'
import { WishlistModule } from '@pwa-concept/modules/wishlist'
import { AccountDetails } from '@pwa-onilab/ui/components/modules/Account'
import { AccountAddressBook, AccountDeletePage } from '@pwa-onilab/ui/components/modules/AccountSubpages'
import AccountOrderHistory from '@pwa-onilab/ui/components/modules/AccountSubpages/AccountOrderHistory'
import CheckoutPlaceholder from '@pwa-onilab/ui/components/modules/Checkout/CheckoutPlaceholder'
import { CHECKOUT_ROUTE, CHECKOUT_SUCCESS_ROUTE, HOME_ROUTE } from '@pwa-onilab/ui/constants'
import { FormCloseConfirmDriver, MediaBreakpointDriver, ToastifyDriver } from '@pwa-onilab/ui/drivers'
import NavigationScrollRestorationDriver from '@pwa-onilab/ui/drivers/NavigationScrollRestorationDriver'
import AccountAuthWrapper from '@pwa-onilab/ui/pages/AccountPage/AccountAuthWrapper'
import CartPage from '@pwa-onilab/ui/pages/CartPage'
import { CategoryPageSkeleton } from '@pwa-onilab/ui/pages/CategoryPage'
import { FaqPageSkeleton } from '@pwa-onilab/ui/pages/FaqPage'
import { HomePageSkeleton } from '@pwa-onilab/ui/pages/HomePage/HomePage.skeleton'
import { MobileMenuPage, MobileMenuShopPage, MobileMenuShopSubPage } from '@pwa-onilab/ui/pages/MobileMenuPage'
import { BlogAuthorModule } from 'app/modules/blog-author'
import { AppBreadcrumbModule } from 'app/modules/breadcrumb'
import { AppCartModule } from 'app/modules/cart'
import { AppCategoryModule } from 'app/modules/category'
import { AppCMSModule } from 'app/modules/cms'
import { AppCustomerOrdersModule } from 'app/modules/customer-orders'
import { AppLiveSearchModule } from 'app/modules/live-search'
import { AppProductModule } from 'app/modules/product'
import { StatesModule } from 'app/modules/states'
import { AppStoreConfig } from 'app/modules/store-config'
import { StrainFamilyModule } from 'app/modules/strain-family'
import { StrainsForSaleModule } from 'app/modules/strains-for-sale'
import { CMS_MODULE_CONFIGURATION } from 'constants/modules/cmsModuleConfiguration'
import { FILTER_MODULE_CONFIGURATION } from 'constants/modules/filterModuleConfiguration'
import { REGISTRATION_ROUTE } from 'overrides/@pwa-onilab/ui/constants/navigation/navigation'
import RegistrationHomePage from 'pages/RegistrationHomePage'
import { lazy } from 'react'

import { App } from './App'
import AccountCards from 'components/modules/Account/subpages/AccountCards/AccountCards'

const LazyCheckoutPage = lazy(async () => await import('@pwa-onilab/ui/pages/CheckoutPage'))
const LazyCheckoutSuccessPage = lazy(async () => await import('@pwa-onilab/ui/pages/CheckoutSuccess'))

export const AppModule = module(() => ({
    entry: <App />,
    modules: [
        AppBreadcrumbModule,
        AppProductModule,
        AppCategoryModule,
        AppCartModule,
        AppStoreConfig,
        AppLiveSearchModule,
        AppCustomerOrdersModule,
        ConfiguredFilterModule(FILTER_MODULE_CONFIGURATION),

        CatalogModule,

        AuthModule,
        BlogAuthorModule,
        OneTimePasswordModule,
        SortModule,
        WishlistModule,
        CustomerModule,
        CountriesModule,
        StatesModule,
        AppCMSModule(CMS_MODULE_CONFIGURATION),

        StrainFamilyModule,
        StrainsForSaleModule,
    ],
    routes: [
        {
            element: lazy(async () => await import('@pwa-onilab/ui/pages/SearchPage')),
            fallback: CategoryPageSkeleton,
            path: '/search',
        },
        {
            path: '/menu',
            element: MobileMenuPage,
            children: [
                {
                    path: 'shop',
                    element: MobileMenuShopPage,
                },
                {
                    path: 'shop/*',
                    element: <MobileMenuShopSubPage />,
                },
            ],
        },
        {
            path: HOME_ROUTE,
            element: lazy(async () => await import('@pwa-onilab/ui/pages/HomePage')),
            fallback: HomePageSkeleton,
            children: [
                {
                    path: REGISTRATION_ROUTE,
                    element: RegistrationHomePage,
                },
            ],
        },
        {
            path: 'cart',
            element: <CartPage />,
        },
        {
            element: lazy(async () => await import('@pwa-onilab/ui/pages/WishlistPage')),
            fallback: CategoryPageSkeleton, // TODO own placeholder?
            path: '/wishlist',
        },
        {
            element: lazy(async () => await import('../src/pages/CoinpayPage')),
            path: '/coinpay',
        },
        {
            element: lazy(async () => await import('../src/pages/CoinpayPageCancel')),
            path: '/coinpayments/checkout/failure',
        },
        {
            element: lazy(async () => await import('../src/pages/CoinpayPageHandle')),
            path: '/coinpayments/ipn/handle',
        },
        {
            element: lazy(async () => await import('../src/pages/CatalogPage')),
            path: '/online-cannabis-seed-bank',
        },
        {
            element: lazy(async () => await import('../src/pages/SitemapPage')),
            path: '/site-map',
        },
        {
            element: lazy(async () => await import('@pwa-onilab/ui/pages/WishlistPage')),
            fallback: CategoryPageSkeleton, // TODO own placeholder?
            path: '/shared-wishlist',
        },
        {
            element: lazy(async () => await import('@pwa-onilab/ui/pages/FaqPage')),
            fallback: FaqPageSkeleton,
            path: '/faq',
        },
        {
            path: '/account',
            element: AccountAuthWrapper,
            children: [
                {
                    path: 'details',
                    element: AccountDetails,
                },
                {
                    path: 'addresses',
                    element: AccountAddressBook,
                },
                {
                    path: 'orders',
                    element: AccountOrderHistory,
                },
                {
                    path: 'cards',
                    element: AccountCards,
                },
            ],
        },
        {
            path: '/account/delete',
            element: <AccountDeletePage />,
        },
        {
            path: CHECKOUT_ROUTE,
            element: LazyCheckoutPage,
            fallback: CheckoutPlaceholder,
        },
        {
            path: CHECKOUT_SUCCESS_ROUTE,
            element: LazyCheckoutSuccessPage,
            fallback: CheckoutPlaceholder,
        },
    ],
    providers: [
        ToastifyDriver,
        MediaBreakpointDriver,
        FormCloseConfirmDriver,
        NavigationScrollRestorationDriver,
    ],
}))

import { useApolloClient } from '@apollo/client'
import { Product, ProductQueryInput } from '@pwa-concept/modules/graphql'
import { useProduct } from '@pwa-concept/modules/product'
import {
    ConfigurableProductCommonFieldsFragmentDoc,
} from '@pwa-concept/modules/product/graphql/fragments/ConfigurableProductCommonFields'
import { useMemo } from 'react'

interface IUseCachedProduct {
    skip?: boolean
}

interface IUseCachedProductResponse {
    data: Product
    loading: boolean
}

const PRODUCT_TYPENAME = 'Product'
const CONFIGURABLE_PRODUCT_FRAGMENT_NAME = 'ConfigurableProductCommonFields'

const useCachedProduct = (input: ProductQueryInput, options: IUseCachedProduct): IUseCachedProductResponse => {
    const { data, loading } = useProduct(input, options)
    const client = useApolloClient()

    const fullProduct = useMemo(() => {
        if (!data) {
            return null
        }

        const { configuredSiblingSku } = data
        if (!configuredSiblingSku) {
            return data
        }

        const fragmentOptions = {
            id: `${PRODUCT_TYPENAME}:${configuredSiblingSku}`,
            fragment: ConfigurableProductCommonFieldsFragmentDoc,
            fragmentName: CONFIGURABLE_PRODUCT_FRAGMENT_NAME,
        }
        const parentFragment = client.readFragment(fragmentOptions)

        if (!parentFragment && configuredSiblingSku && (data.variants || data.options)) {
            client.writeFragment({
                ...fragmentOptions,
                data: {
                    __typename: PRODUCT_TYPENAME,
                    variants: data.variants || null,
                    options: data.options || null,
                    sku: configuredSiblingSku,
                },
            })
        }

        if (!parentFragment || (data.variants && data.options)) {
            return data
        }

        return {
            ...data,
            variants: data.variants || parentFragment?.variants,
            options: data.options || parentFragment?.options,
        }
    }, [data])

    return {
        data: fullProduct,
        loading,
    }
}

export default useCachedProduct

import { classNames } from '@pwa-onilab/ui/helpers'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import { useTranslation } from 'react-i18next'

import styles from './CustomerServiceButton.module.scss'

interface ICustomerServiceButtonProps {
    className?: string
    openModal: () => void
}

const CustomerServiceButton = (
    {
        className,
        openModal,
    }: ICustomerServiceButtonProps,
) => {
    const { t } = useTranslation()

    return (
        <ButtonInstance
            onClick={openModal}
            className={classNames(styles.button, className)}
        >
            <div className={styles.iconWrapper}>
                <Icon
                    name="informer"
                    className={styles.icon}
                />
            </div>
            {t('header.customerService')}
        </ButtonInstance>
    )
}

export default CustomerServiceButton

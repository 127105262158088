export const SCHEMA_HELMET_CONTEXT_VALUE = 'https://schema.org/'
export const SCHEMA_HELMET_DEFAULT_SITE_URL = `${window.location.origin}/`

export const SCHEMA_HELMET_TYPES = {
    AggregateOffer: 'AggregateOffer',
    ProductCollection: 'ProductCollection',
    Product: 'Product',
    PaymentMethod: 'PaymentMethod',
    AggregateRating: 'AggregateRating',
    Person: 'Person',
    Review: 'Review',
    Offer: 'Offer',
    CollectionPage: 'CollectionPage',
    SearchResultsPage: 'SearchResultsPage',
}

import { ModalProps } from '@pwa-onilab/ui/components/elements/Modal/Modal'
import { useCustomerPersonalInfo, useResetPassword } from '@pwa-onilab/ui/hooks'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

interface IUseAccountOtpAddPasswordSubmit extends Pick<ModalProps, 'onDismiss'>{
    reset: () => void
    token: string
}

const useAccountOtpAddPasswordSubmit = ({ onDismiss, reset, token }: IUseAccountOtpAddPasswordSubmit) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { email } = useCustomerPersonalInfo()
    const { t } = useTranslation()
    const resetPassword = useResetPassword()

    const submitHandler = useCallback(async ({ newPassword }) => {
        const successHandler = () => {
            toast.success(t('AccountPage.informationSubpage.changedPassword'))
            onDismiss()
            reset()
        }

        setIsLoading(true)
        try {
            await resetPassword(email, newPassword, token)
            successHandler()
        } catch (error) {
            toast.error(error.message)
        }
        setIsLoading(false)
    }, [token, email])

    return {
        submitHandler,
        isLoading,
    }
}

export default useAccountOtpAddPasswordSubmit

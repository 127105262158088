import { useQuery } from '@apollo/client'
import { TrendingRequestsQuery } from '@pwa-onilab/ui/schemas'

const useTrendingRequests = () => {
    const {
        data: { trendingRequests = [] } = {},
        loading: trendingRequestsLoading,
    } = useQuery(TrendingRequestsQuery)

    return {
        trendingRequests: trendingRequests?.slice(0, 3),
        trendingRequestsLoading,
    }
}

export default useTrendingRequests

import { RefObject, useEffect } from 'react'

type tUseInputRangeRefValueListener = (
    inputRef: RefObject<HTMLInputElement | null>,
    currentExtremum: number,
) => void

// need to format input output with storing carriage position use with formatInputRangeFieldEvent onChange
const useInputRangeRefValueListener: tUseInputRangeRefValueListener = (inputRef, currentExtremum) => {
    useEffect(() => {
        inputRef.current.value = currentExtremum.toFixed(2)
    }, [currentExtremum])
}

export default useInputRangeRefValueListener

import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'
import Container from '@pwa-onilab/ui/components/UI/Container'

import styles from './DefaultFallback.module.scss'

const DefaultFallback = () => {
    return (
        <Container>
            <Placeholder className={styles.placeholderSizes} />
        </Container>
    )
}

export default DefaultFallback

import {
    Breadcrumb,
    BreadcrumbUrlPossibleType,
    ProductConfigurableOptionType,
    ProductImage,
    ProductOption,
    ProductOptionKind,
    ProductOptionType,
    ProductOptionValue,
    ProductResolvers,
    ProductStockType,
    ProductVariant,
    ProductVariantOption,
    ProductVatType,
} from '@pwa-concept/modules/graphql'
import {
    Magento2ConfigurableProductOptions,
    Magento2ConfigurableProductOptionsValues,
    Magento2CustomizableOptionInterface,
    Maybe,
} from '@pwa-concept/stores/magento2/graphql'

const id = (context) => String(context?.id)

const TYPE_BY_TYPENAME = {
    CustomizableAreaOption: ProductOptionType.Area,
    CustomizableDateOption: ProductOptionType.Date,
    CustomizableCheckboxOption: ProductOptionType.Checkbox,
    CustomizableDropDownOption: ProductOptionType.DropDown,
    CustomizableFieldOption: ProductOptionType.Field,
    CustomizableFileOption: ProductOptionType.Field,
    CustomizableMultipleOption: ProductOptionType.Multiple,
    CustomizableRadioOption: ProductOptionType.Radio,
}

const CONFIGURABLE_TYPE_BY_NAME = {
    ColorSwatchData: ProductConfigurableOptionType.Color,
    TextSwatchData: ProductConfigurableOptionType.Text,
    ImageSwatchData: ProductConfigurableOptionType.Image,
}

const Product: ProductResolvers = {
    id: (_, __, { context }) => id(context),
    sku: (_, __, { context }) => {
        return context?.sku || null
    },
    name: (_, __, { context }) => context?.name || null,
    url: (_, __, { context }) => {
        if (!context) {
            return null
        }

        return [context.url_key, context.url_suffix].filter(Boolean).join('')
    },
    description: (_, __, { context }) => context?.description?.html || null,
    shortDescription: (_, __, { context }) => context?.short_description?.html || null,
    stock: (_, __, { context }) => ({
        name: context?.stock_status === ProductStockType.InStock ? 'In stock' : 'Out of stock',
        type: context?.stock_status || null,
        __typename: 'ProductStock',
    }),

    configuredSiblingSku: (_, __, { context }) => {
        return context?.configuredSiblingSku || null
    },

    parentSku: (_, __, { context }) => {
        return context?.parentSku || null
    },

    // TODO why we have product wich contain only typename? || product?.variants seems to be never work
    variants: (product, __, { context }) => {
        return context?.variants?.map?.((item): ProductVariant => {
            return {
                product: {
                    __context: {
                        ...item.product,
                        configuredSiblingSku: context.configuredSiblingSku || context.sku || null,
                        parentSku: context.parentSku || null,
                    },
                    __typename: 'Product',
                } as any,
                options: item?.attributes?.map?.((attribute): ProductVariantOption => ({
                    key: attribute?.code || null,
                    value: attribute?.uid || null,
                    __typename: 'ProductVariantOption',
                })),
                __typename: 'ProductVariant',
            }
        }) || product?.variants || null
    },

    breadcrumbs: (_, __, { context }) => [
        ...(context?.categories?.slice(0, 1)?.map?.((category): Breadcrumb => ({
            id: String(category?.id),
            name: category?.name || null,
            url: {
                id: String(category?.id),
                type: BreadcrumbUrlPossibleType.Category,
                to: category?.url_path?.length > 0 ? `/${category.url_path}` : null,
                __typename: 'BreadcrumbUrl',
            },
            __typename: 'Breadcrumb',
        })) || []),
        {
            id: `product-${id(context)}`,
            name: context?.name || null,
            url: null,
            __typename: 'Breadcrumb',
        },
    ],
    images: (_, __, { context }) => {
        if (!context?.media_gallery?.length) {
            return null
        }

        // TODO erase id from image interface, it lead to image duplication in cache
        return context.media_gallery.map((image, index): ProductImage => ({
            id: `${id(context)}_${index}`,
            url: image?.url || null,
            description: image?.label || null,
            __typename: 'ProductImage',
        }))
    },
    // TODO seems like this field was need only for actility and we can remove it
    vat: () => ({
        type: ProductVatType.Excluded,
        name: 'VAT excluded',
        __typename: 'ProductVat',
    }),
    price: (_, __, { context }) => {
        const priceRange = context?.price_range

        const maximumPrice = priceRange?.maximum_price
        const minimumPrice = priceRange?.minimum_price
        const isRange = minimumPrice?.final_price?.value !== maximumPrice?.final_price?.value

        const current = minimumPrice?.final_price
        const initial = minimumPrice?.regular_price

        return {
            // TODO rm badges, it deprecated, used only inside actility
            badges: Math.ceil(maximumPrice?.discount?.percent_off || 0) > 0 ? (
                [
                    {
                        name: `-${Math.ceil(maximumPrice.discount.percent_off || 0)}%`,
                        __typename: 'ProductPriceBadge',
                    },
                ]
            ) : [],
            discount: maximumPrice?.discount?.percent_off || null,
            current: {
                currency: current?.currency || null,
                value: current?.value || null,
                __typename: 'Money',
            },
            initial: {
                currency: initial?.currency || null,
                value: initial?.value || null,
                __typename: 'Money',
            },
            isRange,
            __typename: 'ProductPrice',
        }
    },
    thumbnail: (_, __, { context }) => context?.image ? {
        id: context.image.url,
        url: context.image.url || null,
        description: context.image.label || null,
        __typename: 'ProductImage',
    } : null,

    options: (_, __, { context }) => {
        const result: ProductOption[] = []

        context?.configurable_options?.forEach?.((item: Magento2ConfigurableProductOptions & { __typename: string }) => {
            result.push({
                id: item?.uid,
                key: item?.attribute_code,
                name: item?.label,
                kind: ProductOptionKind.Configurable,
                type: CONFIGURABLE_TYPE_BY_NAME?.[item?.values?.[0]?.swatch_data?.__typename] || ProductConfigurableOptionType.Text,
                required: true,
                values: (
                    item?.values?.map((child: Maybe<Magento2ConfigurableProductOptionsValues & { swatch_data: { value: any, __typename: string } }>): ProductOptionValue => {
                        const childUid = child?.uid
                        let currentVariant = null
                        if (childUid) {
                            currentVariant = context?.variants?.find((variant) => {
                                return variant?.attributes?.find((attribute) => attribute?.uid === childUid)
                            })
                        }

                        const inStockVariants = context?.variants?.filter((variant) => {
                            if (!childUid) return false
                            const optionValue = variant?.attributes?.find((attribute) => attribute?.uid === childUid)
                            return optionValue && variant?.product.stock_status === ProductStockType.InStock
                        })

                        return {
                            value: childUid,
                            name: (
                                child?.swatch_data?.__typename === 'TextSwatchData' && child?.swatch_data?.value
                            ) || child?.store_label || child?.label || child?.default_label,
                            price: null,
                            colorValue: child?.swatch_data?.__typename === 'ColorSwatchData'
                                ? child?.swatch_data?.value
                                : null,
                            isInStock: inStockVariants?.length > 0,
                            url: currentVariant?.product?.url_key || null,
                            __typename: 'ProductOptionValue',
                        }
                    })
                ) || [],
                __typename: 'ProductOption',
            })
        })

        context?.options?.forEach?.((item: Magento2CustomizableOptionInterface & { __typename: string, value: any[] }) => {
            result.push({
                id: item?.uid,
                key: item?.uid,
                name: item?.title,
                kind: ProductOptionKind.Customizable,
                type: TYPE_BY_TYPENAME?.[item?.__typename],
                required: item?.required,
                values: (
                    item
                        ?.value?.filter(({ price_type: priceType }) => priceType?.toLowerCase() === 'fixed')
                        ?.map((child): ProductOptionValue => {
                            return {
                                value: child?.uid,
                                name: child?.title,
                                price: {
                                    value: child?.price,
                                    __typename: 'Money',
                                },
                                //
                                colorValue: null,
                                isInStock: context.stock_status === ProductStockType.InStock,
                                url: context.url_key || null,
                                __typename: 'ProductOptionValue',
                            }
                        })
                ) || [],
                __typename: 'ProductOption',
            })
        })

        return result
    },

    metaDescription: (_, __, { context }) => {
        return context?.meta_description || ''
    },
    metaKeywords: (_, __, { context }) => {
        return context?.meta_keyword || ''
    },
    metaTitle: (_, __, { context }) => {
        return context?.meta_title || ''
    },

    labels: (_, __, { context }) => {
        const labels = []

        const discount = context?.price_range?.minimum_price?.discount?.percent_off || 0
        if (discount) {
            labels.push({
                label: `-${discount} %`,
                backgroundColor: '#EC3811',
                color: '#FFF',
                __typename: 'ProductLabel',
            })
        }

        return labels
    },
}

export default Product

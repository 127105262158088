import { useStoreConfigSearchPageMetaQuery } from 'app/modules/store-config/graphql/queries/StoreConfigSearchPageMeta'

const useStoreConfigSearchPageMeta = () => {
    const { data: { storeConfigSearchPageMeta: data } = {}, loading } = useStoreConfigSearchPageMetaQuery()

    return {
        data,
        loading,
    }
}

export default useStoreConfigSearchPageMeta

import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'

const CustomToastifyCloseButton = ({ closeToast }) => {
    return (
        <ButtonInstance className="Toastify__close-button" onClick={closeToast}>
            <Icon name="cross" />
        </ButtonInstance>
    )
}

export default CustomToastifyCloseButton

import { CustomerAddress } from '@pwa-concept/modules/graphql'
import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { ModalProps } from '@pwa-onilab/ui/components/elements/Modal/Modal'
import { ModalContentTitle } from '@pwa-onilab/ui/components/elements/ModalContentElements'
import { AddressDeleteConfirmationYes } from '@pwa-onilab/ui/components/modules/AccountAddress'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './AccountAddressDeleteConfirmation.module.scss'

// TODO rewrite it using ConfirmModal component
const AccountAddressDeleteConfirmation = (
    {
        isOpen,
        onDismiss,
        id,
    }: Pick<ModalProps & CustomerAddress, 'id' | 'isOpen' | 'onDismiss'>,
) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { t } = useTranslation()

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={onDismiss}
            isDraggable
            isOverAll
            isUseBorderRadius
            isShowCloseButton
            isHideMobileCloseButton
            isUseDefaultPadding
            className={styles.modalWrapper}
        >
            <ModalContentTitle title={t('address.deleteConfirmation.title')} />
            <div className={styles.buttonsWrapper}>
                <Button
                    isBlackTransparent
                    isBold
                    onClick={onDismiss}
                    className={styles.button}
                    disabled={isLoading}
                >
                    {t('global.no')}
                </Button>
                <AddressDeleteConfirmationYes
                    id={id}
                    onDismiss={onDismiss}
                    className={styles.button}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
            </div>
        </Modal>
    )
}

export default AccountAddressDeleteConfirmation

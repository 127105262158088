import { makeVar, ReactiveVar, useReactiveVar } from '@apollo/client'

import localStorageObserver from '../utils/localStorageObserver'

const WISHLIST_UID = 'wishlistUid'

const wishlistIdModel: ReactiveVar<string | null> = makeVar<string | null>(localStorage.getItem(WISHLIST_UID))

const localStorageObservable = localStorageObserver(WISHLIST_UID, ({ newValue }) => {
    if (newValue !== wishlistIdModel()) {
        wishlistIdModel(newValue)
    }
})

export const $wishlist = {
    get id(): string {
        return wishlistIdModel()
    },
    set id(newValue) {
        localStorageObservable.unsubscribe()

        if (newValue) {
            wishlistIdModel(newValue)
            localStorage.setItem(WISHLIST_UID, newValue)
        } else {
            wishlistIdModel(null)
            localStorage.removeItem(WISHLIST_UID)
        }

        localStorageObservable.subscribe()
    },
    useId: (): [string | null, ((newValue: string) => void)] => [
        useReactiveVar(wishlistIdModel),
        (newValue) => {
            $wishlist.id = newValue
        },
    ],
    reset() {
        this.id = null
    },
}

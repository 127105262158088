import { InputSearch } from '@pwa-onilab/ui/components/elements/Inputs/InputSearch'
import { DefaultModalContentProps } from '@pwa-onilab/ui/components/elements/Modal/elements'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import styles from './ModalHeaderBackArrowWithSearch.module.scss'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'

interface IModalHeaderBackArrowWithSearchProps extends Pick<DefaultModalContentProps, 'onDismiss'> {
    className?: string
    onFocus?: () => void
}

const ModalHeaderBackArrowWithSearch = (
    {
        children,
        onDismiss,
        className,
        onFocus,
    }: PropsWithChildren<IModalHeaderBackArrowWithSearchProps>,
) => (
    <div className={classNames(styles.wrapper, className)}>
        <div className={styles.header}>
            {
                onDismiss && (
                    <ButtonInstance
                        className={styles.back}
                        onClick={onDismiss}
                    >
                        <Icon
                            name="leftArrow"
                            className={styles.leftArrowIcon}
                        />
                    </ButtonInstance>
                )
            }
            <div className={classNames(styles.title)}>
                {children}
            </div>
        </div>
        <div className={styles.searchWrapper}>
            <InputSearch
                onFocus={onFocus}
                className={styles.search}
            />
        </div>
    </div>
)

export { ModalHeaderBackArrowWithSearch }

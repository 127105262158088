import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

export type WishlistUserErrorsFragment = { __typename?: 'WishlistUserInputError', code: Types.WishlistUserInputErrorType, message?: string | null };

export const WishlistUserErrorsFragmentDoc = gql`
    fragment WishlistUserErrors on WishlistUserInputError {
  code
  message
}
    `;
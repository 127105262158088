import api from '@pwa-concept/core/api'
import { MutationResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const deleteCustomerAccount: MutationResolvers['deleteCustomerAccount'] = async (_, { deleteCode }) => {
    const { data: { deleteCustomerAccount: __context } = {} } = await (
        api.graphql(
            gql`
                mutation($deleteCode: String!) {
                    deleteCustomerAccount(deleteCode: $deleteCode) {
                        status
                        errorMessage: error_message
                    }
                }
            `,
        ).mutation({ deleteCode })
    )

    return __context || null
}

export default deleteCustomerAccount

import { TableCell } from '@pwa-onilab/ui/components/UI/TableCell'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './TableHeader.module.scss'

interface ITableHeader {
    name: string
    className?: string
}

const TableHeader = ({ name, className }: ITableHeader) => {
    return (
        <TableCell className={classNames(styles.th, className)}>
            {name}
        </TableCell>
    )
}

export default TableHeader

import { useQuery } from '@apollo/client'
import { mapProductReviewsMainData } from '@pwa-onilab/ui/mappers'
import { GetProductReviewsMainDataQuery } from '@pwa-onilab/ui/schemas'

interface IProductReviewMainData {
    sku: string
}

const useProductReviewMainData = ({ sku }: IProductReviewMainData) => {
    const { data: { products } = {}, loading } = useQuery(GetProductReviewsMainDataQuery, {
        fetchPolicy: 'cache-first',
        variables: {
            productSKU: sku,
        },
    })

    return {
        data: mapProductReviewsMainData(products), // FIXME map on apollo level
        loading,
    }
}

export default useProductReviewMainData

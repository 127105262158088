import { Resolvers } from '@pwa-concept/modules/graphql'

const LiveSearchResult: Resolvers['LiveSearchResult'] = {
    categories: (_, __, { context }) => {
        if (!context?.categories) {
            return null
        }

        return context.categories.map((__context) => {
            return {
                id: __context.id || null,
                link: __context.link || null,
                name: __context.name || null,
                productCount: __context.product_count || null,
                __typename: 'LiveSearchCategory',
            }
        })
    },
    suggestions: (_, __, { context }) => context?.suggestions || null,
    products: (_, __, { context }) => {
        return {
            __context: context?.products,
            __typename: 'ProductsInLiveSearch',
        }
    },

}

export default LiveSearchResult

import Container from '@pwa-onilab/ui/components/UI/Container'
import { BrandsSectionCardSkeleton } from '@pwa-onilab/ui/components/widgets/BrandsSection/BrandsSectionCard'
import { IWidgetHeaderSkeletonProps, WidgetHeaderSkeleton } from '@pwa-onilab/ui/components/widgets/WidgetHeader'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './BrandsSection.module.scss'

const BrandsSectionSkeleton = (
    {
        classNameWrapper,
        isSubtitle = false,
        isTo = false,
    }: IWidgetHeaderSkeletonProps,
) => (
    <section className={classNames(styles.wrapper, classNameWrapper)}>
        <Container>
            <WidgetHeaderSkeleton isSubtitle={isSubtitle} isTo={isTo} />
            <Container isRevert className={styles.contentSkeleton}>
                <BrandsSectionCardSkeleton />
            </Container>
        </Container>
    </section>
)

export { BrandsSectionSkeleton }

import { CategoryModule } from '@pwa-concept/modules/category'

import graphqlFragments from './graphql/fragments'
import graphqlSchemas from './graphql/schemas'
import stores from './graphql/stores'

const AppCategoryModule = CategoryModule.extend((m, { concat }) => ({
    graphqlFragments: concat(m.graphqlSchemas, graphqlFragments),
    graphqlSchemas: concat(m.graphqlSchemas, graphqlSchemas),
    graphqlResolvers: concat(m.graphqlResolvers, stores?.resolvers),
    graphqlStoreFragments: concat(m.graphqlStoreFragments, stores?.fragments),
}))

export default AppCategoryModule

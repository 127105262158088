import { memo } from 'react'

import styles from './AccountAddressesTitle.module.scss'
import IAccountAddressesTitle from './IAccountAddressesTitle'

const AccountAddressesTitle = ({ title }: IAccountAddressesTitle) => {
    return (
        <h2 className={styles.title}>
            {title}
        </h2>
    )
}

export default memo(AccountAddressesTitle)

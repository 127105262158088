import styles from './AccountAddressBookPlaceholder.module.scss'

const AccountAddressBookPlaceholder = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.titleLine} />
            <div className={styles.column} />
            <div className={styles.column} />
            <div className={styles.titleLine} />
            <div className={styles.column} />
            <div className={styles.column} />
            <div className={styles.column} />
            <div className={styles.column} />
            <div className={styles.column} />
            <div className={styles.column} />
        </div>
    )
}

export default AccountAddressBookPlaceholder

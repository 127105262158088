import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type RequestOtpResetMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type RequestOtpResetMutation = { __typename?: 'Mutation', requestOTPReset?: { __typename?: 'RequestOTPOutput', email?: string | null } | null };


export const RequestOtpResetDocument = gql`
    mutation requestOTPReset($email: String!) {
  requestOTPReset(email: $email) @client {
    email
  }
}
    `;
export type RequestOtpResetMutationFn = Apollo.MutationFunction<RequestOtpResetMutation, RequestOtpResetMutationVariables>;

/**
 * __useRequestOtpResetMutation__
 *
 * To run a mutation, you first call `useRequestOtpResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOtpResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOtpResetMutation, { data, loading, error }] = useRequestOtpResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestOtpResetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestOtpResetMutation, RequestOtpResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RequestOtpResetMutation, RequestOtpResetMutationVariables>(RequestOtpResetDocument, options);
      }
export type RequestOtpResetMutationHookResult = ReturnType<typeof useRequestOtpResetMutation>;
export type RequestOtpResetMutationResult = Apollo.MutationResult<RequestOtpResetMutation>;
export type RequestOtpResetMutationOptions = Apollo.BaseMutationOptions<RequestOtpResetMutation, RequestOtpResetMutationVariables>;
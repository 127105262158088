import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { STRAIN_FAMILY_ALL_FAMILIES_COLUMN_COUNT } from 'modules/StrainFamily'

import styles from './StrainFamilyAllFamiliesPlaceholder.module.scss'

const StrainFamilyAllFamiliesPlaceholder = () => {
    const isMobile = useMediaBreakpoint().isBreakpointDown('xs')
    const columns = new Array(isMobile ? 1 : STRAIN_FAMILY_ALL_FAMILIES_COLUMN_COUNT).fill(0)

    return (
        <div>
            <Container isRevert>
                <Placeholder className={styles.titlePlaceholderSizes} />
            </Container>
            <div className={styles.columns}>
                {columns.map((column, idx) => {
                    return (
                        <div key={idx} className={styles.column}>
                            <Placeholder className={styles.rowPlaceholderSizes} />
                            <Placeholder className={styles.rowPlaceholderSizes} />
                            <Placeholder className={styles.rowPlaceholderSizes} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default StrainFamilyAllFamiliesPlaceholder

import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import css from './Counter.module.scss'

interface CounterProps {
    className?: string
}

const Counter = (
    {
        children,
        className,
    }: PropsWithChildren<CounterProps>,
) => (
    <div className={classNames(css.counter, className)}>
        {children}
    </div>
)

export default Counter

import { useCustomerAddresses } from '@pwa-concept/modules/customer-addresses'
import { useMemo } from 'react'

const useAccountAdditionalAddresses = () => {
    const { addresses } = useCustomerAddresses()
    return useMemo(() => {
        return addresses?.filter(({ defaultBilling, defaultShipping }) => !(defaultBilling || defaultShipping)) || []
    }, [addresses])
}

export default useAccountAdditionalAddresses

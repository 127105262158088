import { gql } from '@apollo/client'
import api from '@pwa-concept/core/api'
import { $auth, $cart } from '@pwa-concept/core/models'
import { QueryResolvers } from '@pwa-concept/modules/graphql'

const getGuestCart = async (cartIdentifier) => {
    const { data: { cart: __context = null } = {}, errors } = await (
        api.graphql(gql`
            query(
                $cartId: String!
            ) {
                cart(cart_id: $cartId) {
                    ... Cart
                }
            }
        `).query({
            cartId: cartIdentifier,
        })
    )
    return {
        __context,
        errors,
    }
}

const getCustomerCart = async () => {
    const { data: { customerCart: __context = null } = {}, errors } = await (
        api.graphql(gql`
            query {
                customerCart {
                    ... Cart
                }
            }
        `).query()
    )

    return {
        __context,
        errors,
    }
}

const cart: QueryResolvers['cart'] = async (_, { cartId = $cart.id }) => {
    const isCustomerCart = $auth.isToken

    if (!isCustomerCart && cartId) {
        const { __context, errors } = await getGuestCart(cartId)

        if (!__context) return null

        return {
            __context,
            __errors: errors,
            __typename: 'Cart',
        }
    }

    if (isCustomerCart) {
        const { __context, errors } = await getCustomerCart()

        if (!__context) return null

        return {
            __context,
            __errors: errors,
            __typename: 'Cart',
        }
    }

    return null
}

export default cart

import { Category } from '@pwa-concept/modules/graphql'
import { CategoryNavList } from '@pwa-onilab/ui/components/modules/Category'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { Section } from '@pwa-onilab/ui/components/UI/Section'
import { classNames } from '@pwa-onilab/ui/helpers'
import { memo } from 'react'

import css from './CategoryNav.module.scss'

interface CategoryNavProps {
    category: Category
}

const CategoryNav = memo((
    {
        category,
    }: CategoryNavProps,
) => (
    <Section className={classNames(css.navigation)}>
        <Container isMobileFullWide>
            <CategoryNavList category={category} />
        </Container>
    </Section>
))

export default CategoryNav

import { useCart } from '@pwa-concept/modules/cart'

const useCartTotals = () => {
    const { data: { totals } = {}, loading } = useCart()

    return {
        data: {
            subtotal: totals?.sub?.money || 0,
            discountValue: -totals?.discounts?.reduce((acc, el) => acc + +el.money, 0) || 0,
            delivery: totals?.shipping?.money || 0,
            total: totals?.grand?.money || 0,
        },
        loading,
    }
}

export default useCartTotals

import { FilterType } from '@pwa-concept/modules/graphql'
import { FilterOptionsCheckbox, FilterOptionsPrice } from '@pwa-onilab/ui/components/modules/Filters'
import { IFilterOptions } from 'components/overrides/modules/Filters/elements/FilterOptions/IFilterOptions'
import { FILTER_NODE_ACTUAL_PRICE_PER_SEED, FilterOptionsBoolean } from 'modules/Filters'

const FilterOptions = (
    {
        data,
        onChangeValue,
        currentValues,
        onToggleOption,
        isOptionChecked,
        numResults,
        title,
    }: IFilterOptions,
) => {
    if (data?.key === FILTER_NODE_ACTUAL_PRICE_PER_SEED) {
        return (
            <FilterOptionsPrice
                value={currentValues?.filters?.[data.key]}
                data={data}
                onChangeValue={onChangeValue}
            />
        )
    }

    switch (data?.type) {
        case FilterType.Checkbox:
            return (
                <FilterOptionsCheckbox
                    data={data}
                    numResults={numResults}
                    onToggleOption={onToggleOption}
                    isOptionChecked={isOptionChecked}
                />
            )

        case FilterType.Boolean:
            return (
                <FilterOptionsBoolean
                    title={title}
                    onChangeValue={onChangeValue}
                    filterId={data?.id}
                    isActive={!!(currentValues?.filters?.[data?.id]?.length)}
                />
            )

        default:
            return null
    }
}

export default FilterOptions

import { FaqWidget } from '@pwa-onilab/ui/components/widgets/FaqWidget'
import { PromoBanners } from '@pwa-onilab/ui/components/widgets/PromoBanners'
import { PromoCode } from '@pwa-onilab/ui/components/widgets/PromoCode'
import Banners from 'components/overrides/widgets/Banners/Banners'
import BeautyBlogSection from 'components/overrides/widgets/BeautyBlogSection/BeautyBlogSection'
import Benefits from 'components/overrides/widgets/Benefits/Benefits'
import CarouselSection from 'components/overrides/widgets/CarouselSection/CarouselSection'
import Categories from 'components/overrides/widgets/Categories/Categories'
import { DescriptionWidget } from 'components/widgets/DescriptionWidget'
import { FaqItems } from 'components/widgets/FaqItems'
import { LocationStatesWidget } from 'components/widgets/LocationStatesWidget'
import { PopularProducts } from 'components/widgets/PopularProducts'
import { ProductList } from 'components/widgets/ProductList'

export const WIDGETS_LIST = {
    onilab_full_with_banner: PromoBanners,
    onilab_benefits_widget: Benefits,
    popular_categories: Categories,
    categories: PopularProducts,
    onilab_banner_with_text_block: Banners,
    onilab_description_widget: DescriptionWidget,
    onilab_subscribe_widget: PromoCode,
    onilab_blog: BeautyBlogSection,
    onilab_new_products: CarouselSection,
    onilab_bestsellers: CarouselSection,
    product_list: ProductList,
    onilab_faq_widget: FaqWidget,
    onilab_faq_items: FaqItems,
    onilab_location_states: LocationStatesWidget,
}

import { InputSearch } from '@pwa-onilab/ui/components/elements/Inputs/InputSearch'
import { SEARCH_TRIGGER_WRAPPER_ID } from '@pwa-onilab/ui/constants/app'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useSearchModal } from '@pwa-onilab/ui/hooks'
import { ISearchTriggerCommonProps } from 'components/overrides/modules/Search/elements/SearchTrigger/SearchTrigger'

import styles from './SearchTriggerDesktop.module.scss'

const SearchTriggerDesktop = (
    {
        searchValue,
        onInputChange,
        onClear,
        className,
        inputClassName,
    }: Pick<ISearchTriggerCommonProps, 'searchValue' | 'onInputChange' | 'onClear' | 'className' | 'inputClassName'>,
) => {
    const { useIsSearchModalOpen, openSearchModal } = useSearchModal()
    const isActive = useIsSearchModalOpen()

    return (
        <div
            className={classNames(styles.triggerWrapper, isActive && styles.triggerWrapperActive, className)}
            id={SEARCH_TRIGGER_WRAPPER_ID}
        >
            <InputSearch
                onSubmit={openSearchModal}
                onClear={onClear}
                className={classNames(styles.searchField, inputClassName)}
                onChange={onInputChange}
                value={isActive ? searchValue : ''}
                isClean
                variant={isActive ? 'modal' : 'default'}
            />
        </div>
    )
}

export default SearchTriggerDesktop

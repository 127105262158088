import { BackButton } from '@pwa-onilab/ui/components/elements/Buttons'
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'

const NavigationBackButton = () => {
    const navigate = useNavigate()

    const onClick = () => {
        navigate(-1)
    }

    return <BackButton onClick={onClick} />
}

export default memo(NavigationBackButton)

import { Resolvers } from '@pwa-concept/modules/graphql'

const CmsRoute: Resolvers['CmsRoute'] = {
    id: (_, __, { context }) => {
        return (
            context?.id ||
            context?.identifier ||
            context?.location_id ||
            context?.family_id ||
            context?.category_id ||
            context?.type ||
            null
        )
    },
    displayMode: (_, __, { context }) => {
        return context?.display_mode || ''
    },
}

export default CmsRoute

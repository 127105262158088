import { classNames } from '@pwa-onilab/ui/helpers'
import { IStatePageHeaderNavList, StatePageList } from 'elements/BlogStatePages/elements'

import styles from './StatePageSideBar.module.scss'

const StatePageSideBar = ({ className, ...props }: IStatePageHeaderNavList) => {
    return (
        <StatePageList
            className={classNames(className, styles.sidebar)}
            {...props}
        />
    )
}

export default StatePageSideBar

import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'
import { AuthorPageLeftColumn, AuthorPageRightColumn, AuthorPageRowWrapper } from 'modules/AuthorPage'

import styles from './AuthorPageDescription.module.scss'

const AuthorPageDescriptionPlaceholder = () => {
    return (
        <AuthorPageRowWrapper>
            <AuthorPageLeftColumn>
                <Placeholder className={styles.image} />
            </AuthorPageLeftColumn>
            <AuthorPageRightColumn>
                <Placeholder className={styles.titlePlaceholder} />
                <Placeholder className={styles.descriptionPlaceholder} />
                <Placeholder className={styles.socialLinksPlaceholder} />
            </AuthorPageRightColumn>
        </AuthorPageRowWrapper>
    )
}

export default AuthorPageDescriptionPlaceholder

import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type RequestOtpAuthMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type RequestOtpAuthMutation = { __typename?: 'Mutation', requestOTPAuth?: { __typename?: 'RequestOTPOutput', email?: string | null } | null };


export const RequestOtpAuthDocument = gql`
    mutation requestOTPAuth($email: String!) {
  requestOTPAuth(email: $email) @client {
    email
  }
}
    `;
export type RequestOtpAuthMutationFn = Apollo.MutationFunction<RequestOtpAuthMutation, RequestOtpAuthMutationVariables>;

/**
 * __useRequestOtpAuthMutation__
 *
 * To run a mutation, you first call `useRequestOtpAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOtpAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOtpAuthMutation, { data, loading, error }] = useRequestOtpAuthMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestOtpAuthMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestOtpAuthMutation, RequestOtpAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RequestOtpAuthMutation, RequestOtpAuthMutationVariables>(RequestOtpAuthDocument, options);
      }
export type RequestOtpAuthMutationHookResult = ReturnType<typeof useRequestOtpAuthMutation>;
export type RequestOtpAuthMutationResult = Apollo.MutationResult<RequestOtpAuthMutation>;
export type RequestOtpAuthMutationOptions = Apollo.BaseMutationOptions<RequestOtpAuthMutation, RequestOtpAuthMutationVariables>;
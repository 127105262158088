import { VisibleOnScreen } from '@pwa-onilab/ui/components/elements/VisibleOnScreen'
import {
    CATEGORY_ITEMS_THRESHOLD,
    CATEGORY_PAGE_SIZE,
    CategoryItemsList,
    useCategoryItems,
    useCategoryItemsInput,
} from '@pwa-onilab/ui/components/modules/Category'
import { ICategoryItems } from '@pwa-onilab/ui/components/modules/Category/CategoryItems/ICategoryItems'
import { PaginationFloat, PaginationLoadMore } from '@pwa-onilab/ui/components/modules/Pagination'
import usePaginationApi from '@pwa-onilab/ui/components/modules/Pagination/hooks/usePaginationApi'
import { CategoryProductCardSkeleton } from '@pwa-onilab/ui/components/modules/Product'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import css from './CategoryItems.module.scss'

// TODO memoize currentValues on HOC
const CategoryItems = (
    {
        className,
        categoryId,
        currentValues,
        setNumResults,
    }: ICategoryItems,
) => {
    const { t } = useTranslation()
    const topElementRef = useRef<HTMLDivElement>(null)

    const [isShowLoadMoreButton, setIsShowLoadMoreButton] = useState<boolean>(true)

    const {
        data: categoryItemsInput,
        loading: categoryItemsInputLoading,
    } = useCategoryItemsInput({
        currentValues,
        categoryId,
    })

    const {
        data: catalog,
        loading,
        dispatchPaginationAction,

        clientPage,
        serverPage,
    } = useCategoryItems(categoryItemsInput, categoryItemsInputLoading)

    const catalogItems = catalog?.items || []

    const catalogPagination = catalog?.pagination ? {
        ...catalog.pagination,
        current: clientPage,
    } : undefined

    const catalogPagesCount: number = catalogPagination?.count
    const totalPages: number = catalogPagination?.total

    useEffect(() => {
        setNumResults(catalogPagesCount)
    }, [catalogPagesCount])

    const {
        onLoadMoreButtonClick,
        onScrollDownPagination,
        onPaginationClick,
        onCategoryItemsListScreen,

    } = usePaginationApi({
        setIsShowLoadMoreButton,
        topElementRef,
        dispatchPaginationAction,
    })

    const isCatalogLoading = loading || categoryItemsInputLoading

    if (!isCatalogLoading && !catalogItems.length) {
        return (
            <div id="items" className={css.empty} ref={topElementRef}>
                {t('categoryPage.emptyResult')}
            </div>
        )
    }

    const isShowLoadMore = isShowLoadMoreButton && totalPages > 1

    const intermediatePagesCount = Math.ceil(catalogItems.length / CATEGORY_PAGE_SIZE) - 1

    return (
        <div id="items" className={css.wrapper} ref={topElementRef}>
            {totalPages > 1 && (
                <PaginationFloat
                    data={catalogPagination}
                    onLoadPage={onPaginationClick}
                />
            )}
            <div>
                <CategoryItemsList
                    items={catalogItems}
                    onScreen={onCategoryItemsListScreen}
                    intermediatePagesCount={intermediatePagesCount}
                    currentServerPage={serverPage}
                    className={className}
                >
                    <VisibleOnScreen
                        disabled={totalPages === clientPage || isShowLoadMoreButton || isCatalogLoading}
                        threshold={CATEGORY_ITEMS_THRESHOLD}
                        onScreen={onScrollDownPagination}
                        data={clientPage + 1}
                    />
                    {isCatalogLoading && (
                        <CategoryProductCardSkeleton
                            className={css.card}
                            skeletonCount={CATEGORY_PAGE_SIZE}
                        />
                    )}
                    {
                        // FIXME not correct condition in case when it first time loading
                        totalPages !== clientPage && isShowLoadMore && (
                            <PaginationLoadMore
                                data={catalogPagination}
                                onLoadPage={onLoadMoreButtonClick}
                            />
                        )
                    }
                </CategoryItemsList>
            </div>
        </div>
    )
}

export default CategoryItems

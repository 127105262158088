import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Modal from '@pwa-onilab/ui/components/elements/Modal'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IFilterSortByInstance } from '@pwa-onilab/ui/components/modules/Filters/components/FilterSortBy/IFilterSortBy'

import styles from './FilterSortByMobile.module.scss'

const FilterSortByMobile = (
    {
        className,
        onChangeValue,
        items,
        sortId,
    }: IFilterSortByInstance,
) => {
    const { t } = useTranslation()

    const [isSortOpen, setSortOpen] = useState(false)

    const activeSortName = useMemo(() => {
        return items?.find(({ id }) => {
            return id === sortId
        })?.name || ''
    }, [items, sortId])

    return (
        <>
            <ButtonInstance
                className={classNames(styles.sortButton, className)}
                onClick={() => setSortOpen(true)}
            >
                {activeSortName || t('global.sort')}
                <Icon
                    name="topBottomArrows"
                    className={styles.sortIcon}
                />
            </ButtonInstance>
            {
                items?.length > 0 && (
                    <Modal
                        className={styles.sortModal}
                        isOpen={isSortOpen}
                        onDismiss={() => setSortOpen(false)}
                        title={t('global.sortBy')}
                        isDraggable
                        isOverAll
                        titleClassName={styles.title}
                    >
                        <div className={styles.list}>
                            {items.map((option) => (
                                <ButtonInstance
                                    className={styles.button}
                                    key={option.id}
                                    onClick={() => {
                                        onChangeValue(option)
                                        setSortOpen(false)
                                    }}
                                >
                                    <div className={classNames(styles.checkbox, option.id === sortId && styles.active)} />
                                    {option.name}
                                </ButtonInstance>
                            ))}
                        </div>
                    </Modal>
                )
            }
        </>
    )
}

export default FilterSortByMobile

import { Resolvers } from '@pwa-concept/modules/graphql'

const CmsBlocksOutput: Resolvers['CmsBlocksOutput'] = {
    items: (_, __, { context }) => {
        if (!context?.items?.length) {
            return null
        }

        return context.items.map((item) => {
            return {
                title: item.title || null,
                identifier: item.identifier || null,
                content: item.content || null,
                __typename: 'CmsBlock',
            }
        })
    },
}

export default CmsBlocksOutput

import { ModalHeaderBackArrowWithSearch } from '@pwa-onilab/ui/components/elements/Modal/elements'
import {
    MobileMenuModal,
    MobileMenuPlaceholder, useMobileMenuSubPageNode,
} from '@pwa-onilab/ui/components/modules/MobileMenuPage'
import { goBack } from '@pwa-onilab/ui/helpers'
import { useSearchModal } from '@pwa-onilab/ui/hooks'
import MobileMenuNodes from 'components/overrides/modules/MobileMenuPage/MobileMenuNodes/MobileMenuNodes'

const ModalHeader = ({ title }: { title: string }) => {
    const goBackHandler = goBack()
    const { openSearchModal } = useSearchModal()

    return (
        <ModalHeaderBackArrowWithSearch
            onDismiss={goBackHandler}
            onFocus={openSearchModal}
        >
            {title}
        </ModalHeaderBackArrowWithSearch>
    )
}

const MobileMenuShopSubPage = () => {
    const { node, loading } = useMobileMenuSubPageNode()

    if (loading) {
        return (
            <MobileMenuPlaceholder />
        )
    }

    const onlyChildNodes = node.nodes?.filter(({ nodeId }) => nodeId !== node.nodeId)
    const nodes = node.type === 'wrapper'
        ? onlyChildNodes
        : [node, ...onlyChildNodes]

    return (
        <MobileMenuModal header={<ModalHeader title={node.title} />}>
            <MobileMenuNodes
                parent={node}
                nodes={nodes}
            />
        </MobileMenuModal>
    )
}

export default MobileMenuShopSubPage

import { CategoryNumResultsContext } from '@pwa-onilab/ui/components/modules/Category'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { CategoryPageSkeleton } from '@pwa-onilab/ui/pages/CategoryPage'
import Error404Page from '@pwa-onilab/ui/pages/Error404Page'
import { useStoreConfigBestsellers } from 'app/modules/store-config'
import {
    BestsellerPageHelmet,
    BestsellerPageItems,
    BestsellersPageBottomDescription,
    BestsellersPageBreadcrumbs,
    BestsellersPageDescription,
} from 'modules/BestsellersPage'

const BestsellersPage = () => {
    const { data, loading } = useStoreConfigBestsellers()

    if (loading) {
        return <CategoryPageSkeleton />
    }

    if (!data) {
        return <Error404Page />
    }

    return (
        <Container>
            <BestsellersPageBreadcrumbs config={data} />
            <BestsellersPageDescription config={data} />
            <CategoryNumResultsContext>
                <BestsellerPageHelmet meta={data.meta} />
                <BestsellerPageItems />
            </CategoryNumResultsContext>
            <BestsellersPageBottomDescription config={data} />
        </Container>
    )
}

export default BestsellersPage

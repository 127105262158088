import { BREAKPOINTS, currentBreakpointIndex, useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { PropsWithChildren } from 'react'

export interface MediaBreakpointProps {
    direction?: 'up' | 'down'
    name?: string
}

const breakpointHandler = () => {
    const newBreakpointIndex = (
        BREAKPOINTS
            .findIndex(([, width]) => window.innerWidth >= width)
    )

    if (newBreakpointIndex !== currentBreakpointIndex()) {
        currentBreakpointIndex(newBreakpointIndex)
    }
}

breakpointHandler()

const MediaBreakpoint = (
    {
        children,
        direction = 'down',
        name,
    }: PropsWithChildren<MediaBreakpointProps>,
) => {
    const { isBreakpointDown, isBreakpointUp } = useMediaBreakpoint()

    if (!children) {
        return null
    }

    const childrenArgs = { isBreakpointDown, isBreakpointUp }

    if (typeof children === 'function') {
        return children(childrenArgs)
    }

    if (direction === 'up') {
        return isBreakpointUp(name) ? children : null
    }

    return isBreakpointDown(name) ? children : null
}

export default MediaBreakpoint

import { useStoreConfig } from '@pwa-concept/modules/store-config'

import styles from './Copyright.module.scss'

const Copyright = () => {
    const {
        data: { socialAndPaymentConfig: { rightsText } = {} } = {},
    } = useStoreConfig()

    if (!rightsText) {
        return null
    }

    return (
        <div className={styles.wrapper}>
            {rightsText}
        </div>
    )
}

export default Copyright

import {
    Product,
    ProductOption,
    ProductOptionValue,
} from '@pwa-concept/modules/graphql'
import {
    checkIsAllRequiredOptionsSet,
    checkIsOptionActive,
    ICategoryProductDataContext,
    preselectDefaultOptions,
} from '@pwa-onilab/ui/components/modules/Product'
import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react'

// TODO check customizable items support

const context = createContext<ICategoryProductDataContext>({})
context.displayName = 'CategoryProductDataContext'

export const useCategoryProductDataContext = () => useContext(context)

const CategoryProductDataProvider = ({
    children,
    product,
}: PropsWithChildren<{product: Product}>) => {
    const [selected, setSelected] = useState<ICategoryProductDataContext['options']>(preselectDefaultOptions(product))
    const { options, variants } = product

    const value = useMemo<ICategoryProductDataContext>(() => {
        const selectOption = (group: ProductOption, option: ProductOptionValue) => {
            setSelected(prev => ({
                ...prev,
                [group.id]: option.value,
            }))
        }

        const selectedValues = Object.values(selected)

        // FIXME dont work on configurable when customizable options enabled
        const variant = variants?.find(({ options }) => (
            options?.every(({ value }) => selectedValues.includes(value))
        ))

        return {
            options,
            selected,
            selectOption,
            checkIsAllRequiredOptionsSet,
            checkIsOptionActive,
            variant,
            product,
        }
    }, [selected, options, variants])

    return (
        <context.Provider value={value}>
            {children}
        </context.Provider>
    )
}

export default CategoryProductDataProvider

import { Resolvers } from '@pwa-concept/modules/graphql'

const CustomerOrderTotals: Resolvers['CustomerOrderTotals'] = {
    total: (_, __, { context }) => context?.grand_total?.value || null,
    subtotal: (_, __, { context }) => context?.subtotal?.value || null,
    totalShipping: (_, __, { context }) => context?.total_shipping?.value || null,
    taxes: (_, __, { context }) => context?.taxes ? (context?.taxes?.map((__context) => ({
        __context,
        __typename: 'CustomerOrderTotalsTax',
    }))) : null,
    discounts: (_, __, { context }) => {
        if (!context?.discounts) {
            return null
        }
        return context.discounts.map((__context) => {
            return {
                __context,
                __typename: 'CustomerOrderDiscount',
            }
        })
    },
}

export default CustomerOrderTotals

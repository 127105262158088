import { FullNameFormField } from '@pwa-onilab/ui/components/elements/FormFields'
import {
    ADDRESS_FORM_FIELDS,
    ADDRESS_FORM_INPUT_SET_VALUE_OPTIONS,
} from '@pwa-onilab/ui/components/modules/AddressForm'
import { useFormContext } from 'react-hook-form'

const AddressFormNameField = () => {
    const { register, setValue, formState: { errors } } = useFormContext()

    return (
        <FullNameFormField
            register={register}
            setValue={setValue}
            setValueOptions={ADDRESS_FORM_INPUT_SET_VALUE_OPTIONS}
            errors={errors}
            field={ADDRESS_FORM_FIELDS.FULL_NAME}
        />
    )
}

export default AddressFormNameField

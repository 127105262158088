import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const getFamilyStrain: QueryResolvers['getFamilyStrain'] = async (_, { urlKey }) => {
    const { data: { getFamilyStrain: __context } = {} } = await (
        api.graphql(
            gql`
                query( $urlKey: String) {
                    getFamilyStrain(url_key: $urlKey) {
                        ...FamilyStrain
                        products {
                            items {
                                id
                                sku
                            }
                        }
                    }
                }
            `,
        ).query({
            urlKey,
        })
    )

    if (!__context) {
        return null
    }

    return {
        __context,
        __typename: 'FamilyStrain',
    }
}

export default getFamilyStrain

import { Filter } from '@pwa-concept/modules/graphql'
import { CategoryCheckbox } from '@pwa-onilab/ui/components/elements/CategoryCheckbox'
import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { ModalHeaderBackArrow } from '@pwa-onilab/ui/components/elements/Modal/elements'
import { useFilterApiContext } from '@pwa-onilab/ui/components/modules/Filters/context'
import { IFilterOptions } from '@pwa-onilab/ui/components/modules/Filters/elements/FilterOptions/IFilterOptions'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import { useTranslation } from 'react-i18next'

import styles from './FilterOptionsMobileModal.module.scss'

interface FilterOptionsMobileModalProps extends Pick<IFilterOptions, 'data' | 'numResults'> {
    isOpen: boolean
    onClose: () => void
    data: Filter
}

const FilterOptionsMobileModal = (
    {
        isOpen,
        onClose,
        data,
        numResults,
    }: Partial<FilterOptionsMobileModalProps>,
) => {
    const { t } = useTranslation()
    const { isOptionChecked, onToggleOption } = useFilterApiContext()

    return (
        <Modal
            className={styles.modal}
            isOpen={isOpen}
            isOverAll
            header={(
                <ModalHeaderBackArrow
                    onDismiss={onClose}
                    className={styles.header}
                    mainClassName={styles.headerTitle}
                >
                    {data?.name}
                </ModalHeaderBackArrow>
            )}
            footer={(
                <Button
                    isBlack
                    isBold
                    isFullWidth
                    onClick={onClose}
                    className={styles.button}
                >
                    {t('filters.showItems', { numResults })}
                </Button>
            )}
            isDraggable
        >
            <div className={styles.list}>
                {data?.items?.map?.((item) => {
                    if (!item) {
                        return null
                    }
                    const label = `${item.name} (${item.count})`

                    return (
                        <CategoryCheckbox
                            isChecked={isOptionChecked(data, item)}
                            name={item.value}
                            variant="list"
                            onChange={() => {
                                onToggleOption(data, item)
                            }}
                            key={item.id}
                        >
                            {label}
                        </CategoryCheckbox>
                    )
                })}
            </div>
        </Modal>
    )
}

export default FilterOptionsMobileModal

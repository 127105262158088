import { module } from '@pwa-concept/core'
import { CustomerAddressesModule } from '@pwa-concept/modules/customer-addresses'
import { CustomerOrdersModule } from '@pwa-concept/modules/customer-orders'

import graphqlSchemas from './graphql/schemas'
import stores from './graphql/stores'

const CustomerModule = module(() => ({
    modules: [
        CustomerAddressesModule,
        CustomerOrdersModule,
    ],
    graphqlSchemas,
    graphqlResolvers: stores?.resolvers,
    graphqlStoreFragments: stores?.fragments,
    graphqlStorePossibleTypes: stores?.possibleTypes,
    graphqlPolicies: {
        Customer: {
            keyFields: () => 'Customer', // we should store only one customer into cache
        },
    },
}))

export default CustomerModule

import { PageAuthor, PageMainImage, PageTitle } from 'elements/BlogStatePages/elements'

const BlogPageIntroBlockDesktop = ({ post }) => {
    const mainImage = post?.featuredImage || post?.thumbnail

    return (
        <div>
            <PageTitle text={post.title} />
            <PageAuthor author={post.author} createdAt={post.publishTime} />
            <PageMainImage image={mainImage} />
        </div>
    )
}

export default BlogPageIntroBlockDesktop

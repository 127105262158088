import { useCallback, useRef, useState } from 'react'

const useTimer = () => {
    const [timerValue, setTimerValue] = useState<string>('')
    const timerValueRef = useRef<string>('')
    const timerRef = useRef(null)

    const stopTimer = useCallback(() => {
        clearInterval(timerRef.current)
        setTimerValue('')
        timerValueRef.current = ''
    }, [])

    const startTimer = useCallback(() => {
        let timeSeconds = 60

        timerRef.current = setInterval(() => {
            const seconds = timeSeconds < 9 || timeSeconds > 59 ? `0${timeSeconds % 60}` : timeSeconds % 60
            const minutes = timeSeconds / 60 % 60
            if (timeSeconds <= 0) {
                stopTimer()
            } else {
                const strTimerValue = `${Math.trunc(minutes)}:${seconds}`
                setTimerValue(strTimerValue)
                timerValueRef.current = strTimerValue
            }
            timeSeconds -= 1
        }, 1000)
    }, [])

    return {
        stopTimer,
        startTimer,
        timerValue,
        timerValueRef,
    }
}

export default useTimer

import ReactGA4 from "react-ga4";
import { makeVar } from "@apollo/client";

export const isGa4Init = makeVar<boolean>(false)

export const initGA4 = (trackingCode: string) => {
    if (!trackingCode || isGa4Init()) {
        return
    }

    ReactGA4.initialize(trackingCode);
    isGa4Init(true)
}

import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { usePageParsedContent } from 'elements/BlogStatePages/hooks'
import { ParserContentWrapper } from 'elements/ParserContentWrapper'
import { BlockPageIntroBlockMobile, BlogPageIntroBlockDesktop, BlogPostPageBackButton } from 'modules/BlogPost'
import { StatePageSideBar } from 'modules/StatePage'
import { StatePageMobileTable } from 'modules/StatePage/components'
import { useState } from 'react'

import styles from './BlogPostContent.module.scss'

const BlogPostContent = ({ post }) => {
    const [currentVisibleOnScreen, setCurrentVisibleOnScreen] = useState<string | null>(null)

    const { content, headers, refs } = usePageParsedContent(
        {
            content: post.content,
            setCurrentVisibleOnScreen,
        })

    const isDesktop = useMediaBreakpoint().isBreakpointUp('lg')

    return (
        <div className={styles.wrapper}>
            {
                isDesktop && (
                    <div>
                        <BlogPostPageBackButton />
                        <StatePageSideBar
                            activeHeader={currentVisibleOnScreen}
                            headers={headers}
                            headerRefs={refs}
                            className={styles.sidebar}
                            onClick={setCurrentVisibleOnScreen}
                        />
                    </div>
                )
            }
            <ParserContentWrapper>
                {
                    isDesktop
                        ? <BlogPageIntroBlockDesktop post={post} />
                        : <BlockPageIntroBlockMobile post={post} />
                }
                {content}
            </ParserContentWrapper>
            {
                !isDesktop && (
                    <StatePageMobileTable
                        headers={headers}
                        headerRefs={refs}
                        activeHeader={currentVisibleOnScreen}
                    />
                )
            }
        </div>
    )
}

export default BlogPostContent

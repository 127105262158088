import { InputField } from '@pwa-onilab/ui/components/elements/Fields'
import SelectField from '@pwa-onilab/ui/components/elements/Fields/SelectField'
import {
    ADDRESS_FORM_FIELDS,
    ADDRESS_FORM_INPUT_SET_VALUE_OPTIONS,
    useRegionFieldOptions,
    useRegionFieldRules,
} from '@pwa-onilab/ui/components/modules/AddressForm'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const AddressFormRegionField = () => {
    const { t } = useTranslation()

    const { register, setValue, formState: { errors } } = useFormContext()
    const options = useRegionFieldOptions()
    const rules = useRegionFieldRules()

    const isInputField = !options.length

    if (isInputField) {
        return (
            <InputField
                placeholder={t('addressForm.region.text')}
                field={ADDRESS_FORM_FIELDS.REGION}
                register={register}
                setValue={setValue}
                setValueOptions={ADDRESS_FORM_INPUT_SET_VALUE_OPTIONS}
                rules={rules}
                errors={errors}
            />
        )
    }
    return (
        <SelectField
            field={ADDRESS_FORM_FIELDS.REGION_ID}
            placeholder={t('addressForm.region.text')}
            options={options}
            register={register}
            setValue={setValue}
            setValueOptions={ADDRESS_FORM_INPUT_SET_VALUE_OPTIONS}
            defaultValue={options[0].value}
            rules={rules}
            errors={errors}
        />
    )
}

export default AddressFormRegionField

import { UseDefaultBillingCheckbox, UseDefaultShippingCheckbox } from '@pwa-onilab/ui/components/modules/AddressForm'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './CheckboxesSection.module.scss'

export interface ICheckboxesSection {
    className?: string
    isCheckout?: boolean
    isBilling?: boolean
    isShippingDefaultChecked?: boolean
    isBillingDefaultChecked?: boolean
}

const CheckboxesSection = (
    {
        className,
        isCheckout,
        isBilling,
        isShippingDefaultChecked,
        isBillingDefaultChecked,
    }: ICheckboxesSection) => {
    if (isCheckout) {
        return (
            <div className={classNames(styles.wrapper, className)}>
                {
                    isBilling
                        ? <UseDefaultShippingCheckbox isCheckout isShippingDefaultChecked={isShippingDefaultChecked} />
                        : <UseDefaultBillingCheckbox isCheckout isBillingDefaultChecked={isBillingDefaultChecked} />
                }

            </div>
        )
    }

    return (
        <div className={classNames(styles.wrapper, className)}>
            <UseDefaultBillingCheckbox isBillingDefaultChecked={isBillingDefaultChecked} />
            <UseDefaultShippingCheckbox isShippingDefaultChecked={isShippingDefaultChecked} />
        </div>
    )
}

export default CheckboxesSection

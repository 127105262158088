import * as Types from '../../../../../src/app/graphql';

import { gql } from '@apollo/client';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

export type StoreConfigFragment = { __typename?: 'StoreConfig', id?: number | null, productReviewsEnabled?: boolean | null, passwordMinLength?: number | null, passwordMinStrength?: number | null, maxNameLength?: number | null, maxAddressLineLength?: number | null, baseCurrencyCode?: string | null, termsOfDelivery?: string | null, productReturns?: string | null, logoWidth?: number | null, logoHeight?: number | null, defaultTitle?: string | null, locale?: string | null };

export const StoreConfigFragmentDoc = gql`
    fragment StoreConfig on StoreConfig {
  id
  productReviewsEnabled
  passwordMinLength
  passwordMinStrength
  maxNameLength
  maxAddressLineLength
  baseCurrencyCode
  termsOfDelivery
  productReturns
  logoWidth
  logoHeight
  defaultTitle
  locale
}
    `;
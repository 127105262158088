import { gql } from '@apollo/client'
import api from '@pwa-concept/core/api'
import { $cart } from '@pwa-concept/core/models'
import { MutationResolvers } from '@pwa-concept/modules/graphql'

const cartRemoveCouponCode: MutationResolvers['cartRemoveCouponCode'] = async () => {
    const { data: { removeCouponFromCart: { cart: __context = null } = {} } = {}, errors: __errors } = await (
        api.graphql(gql`
                mutation(
                    $input: RemoveCouponFromCartInput!
                ) {
                    removeCouponFromCart(input: $input) {
                        cart {
                            ... Cart
                        }
                    }
                }
            `).mutation({
            input: {
                cart_id: $cart.id,
            },
        })
    )

    return {
        __context,
        __errors,
        __typename: 'Cart',
    }
}

export default cartRemoveCouponCode

import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { IClassName } from '@pwa-onilab/ui/interfaces'

import styles from './PaymentMethods.module.scss'

const PaymentMethods = ({ className }: IClassName,
) => {
    const {
        data: { socialAndPaymentConfig: { paymentText, paymentIcons } = {} } = {},
    } = useStoreConfig()

    if (!paymentText && !paymentIcons?.length) {
        return null
    }

    return (
        <div className={styles.wrapper}>
            <a href="https://verify.authorize.net/anetseal/?pid=18c34cf2-ffef-4203-a462-2a3631bfaacd&rurl=https://kindseed.com/"
               onMouseOver="window.status='http://www.authorize.net/';return true;"
               onMouseOut="window.status='';return true;"
               onClick="window.open('https://verify.authorize.net/anetseal/?pid=18c34cf2-ffef-4203-a462-2a3631bfaacd&rurl=https://kindseed.com/','AuthorizeNetVerification','width=600,height=430,dependent=yes,resizable=yes,scrollbars=yes,menubar=no,toolbar=no,status=no,directories=no,location=yes'); return false;"
               rel="noopener noreferrer"
               target="_blank">
                <img src="https://verify.authorize.net/anetseal/images/secure90x72.gif" alt="Authorize.Net Merchant - Click to Verify" />
            </a>
        </div>
    )
}

export default PaymentMethods

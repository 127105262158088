import { useAuthRegister } from '@pwa-concept/modules/auth'
import { RegisterInput } from '@pwa-concept/modules/graphql'
import { useWishlistMerge } from '@pwa-concept/modules/wishlist'
import { useCustomerAssign } from '@pwa-onilab/ui/hooks'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

interface IRegistrationToastSetting {
    showRegisterSuccessToast?: boolean
    showRegisterErrors?: boolean
}

interface IRegistrationStepsAdditionalHandlers {
    handleCustomerRegister?: () => void
    handleCustomerAssign?: () => void
}

export interface IRegistrationConfig {
    additionalHandlers?: IRegistrationStepsAdditionalHandlers
    toastsSetting?: IRegistrationToastSetting
}

const useAccountRegistration = () => {
    const { t } = useTranslation()

    const customerRegister = useAuthRegister()
    const customerAssign = useCustomerAssign()
    const mergeWishlist = useWishlistMerge()

    return useCallback(async (
        input: RegisterInput,
        config: IRegistrationConfig = {},
    ) => {
        const { toastsSetting, additionalHandlers } = config
        let isRegistrationSuccess = false
        try {
            await customerRegister(input)
            isRegistrationSuccess = true
            toastsSetting?.showRegisterSuccessToast && toast.success(t('loginForm.success'))
            await additionalHandlers?.handleCustomerRegister?.()

            await customerAssign()
            await additionalHandlers?.handleCustomerAssign?.()

            await mergeWishlist()
        } catch (error) {
            toastsSetting?.showRegisterErrors && toast.error(error.message)
        }
        return isRegistrationSuccess
    }, [])
}

export default useAccountRegistration

import { Product } from '@pwa-concept/modules/graphql'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { Section } from '@pwa-onilab/ui/components/UI/Section'
import { PopularProductsSlider } from 'components/widgets/PopularProducts'
import { DisablePageBuilderStyles } from 'elements/DisablePageBuilderStyles'

import styles from './PopularProducts.module.scss'

interface IPopularProductsProps {
    items: Array<{
        products: Product[]
        category: {
            name: string
            url: string
            image: string
        }
    }>
    columns_number: number
}

const PopularProducts = (
    {
        items,
        columns_number: columnsNumber,
    }: IPopularProductsProps,
) => {
    if (!items?.length) {
        return null
    }

    return (
        <Section className={styles.section}>
            <DisablePageBuilderStyles>
                <Container>
                    <PopularProductsSlider items={items} columnsNumber={columnsNumber || items.length} />
                </Container>
            </DisablePageBuilderStyles>
        </Section>
    )
}

export default PopularProducts

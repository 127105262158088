import * as Types from '../../../../graphql';

import { gql } from '@apollo/client';
import { StrainFamiliesClientFragmentFragmentDoc } from '../fragments/StrainFamiliesClientFragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type GetStrainFamiliesQueryVariables = Types.Exact<{
  pageSize?: Types.InputMaybe<Types.Scalars['Int']>;
  currentPage?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetStrainFamiliesQuery = { __typename?: 'Query', getFamilyStrains?: { __typename?: 'FamilyStrains', items?: Array<{ __typename?: 'FamilyStrain', id?: string | null, urlKey?: string | null, thumbnail?: string | null, name?: string | null, shortDescription?: string | null, meta?: { __typename?: 'FamilyStrainMeta', title?: string | null, description?: string | null, keywords?: string | null } | null } | null> | null, pagination?: { __typename?: 'Pagination', count?: number | null, current?: number | null, limit?: number | null, total?: number | null } | null } | null };


export const GetStrainFamiliesDocument = gql`
    query GetStrainFamilies($pageSize: Int, $currentPage: Int) {
  getFamilyStrains(currentPage: $currentPage, pageSize: $pageSize) @client {
    ...StrainFamiliesClientFragment @client
  }
}
    ${StrainFamiliesClientFragmentFragmentDoc}`;

/**
 * __useGetStrainFamiliesQuery__
 *
 * To run a query within a React component, call `useGetStrainFamiliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStrainFamiliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStrainFamiliesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      currentPage: // value for 'currentPage'
 *   },
 * });
 */
export function useGetStrainFamiliesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStrainFamiliesQuery, GetStrainFamiliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetStrainFamiliesQuery, GetStrainFamiliesQueryVariables>(GetStrainFamiliesDocument, options);
      }
export function useGetStrainFamiliesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStrainFamiliesQuery, GetStrainFamiliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetStrainFamiliesQuery, GetStrainFamiliesQueryVariables>(GetStrainFamiliesDocument, options);
        }
export type GetStrainFamiliesQueryHookResult = ReturnType<typeof useGetStrainFamiliesQuery>;
export type GetStrainFamiliesLazyQueryHookResult = ReturnType<typeof useGetStrainFamiliesLazyQuery>;
export type GetStrainFamiliesQueryResult = Apollo.QueryResult<GetStrainFamiliesQuery, GetStrainFamiliesQueryVariables>;
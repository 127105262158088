import Title from '@pwa-onilab/ui/components/UI/Title'
import { AnchorWithArrow } from 'elements/AnchorWithArrow'
import { useGetLocationPageUrl } from 'hooks'
import { LocationShortFragment } from 'schemas/fragments/interfaces'

import styles from './LocationCard.module.scss'

const LocationCard = ({ location }: { location: LocationShortFragment}) => {
    const url = useGetLocationPageUrl()(location.urlKey)

    return (
        <div className={styles.wrapper}>
            <Title type="h3" text={location.pageTitle} />
            {location.shortDescription && (
                <div className={styles.description}>
                    {location.shortDescription}
                </div>
            )}
            <AnchorWithArrow to={url} className={styles.anchor}>
                {location.name}
            </AnchorWithArrow>
        </div>
    )
}

export default LocationCard

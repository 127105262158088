import { useCartApplyCouponCode, useCartRemoveCouponCode } from '@pwa-concept/modules/cart'
import { PROMO_CODE_FIELD } from '@pwa-onilab/ui/constants/formFields'
import { useCallback, useRef, useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { UseFormReturn } from 'react-hook-form/dist/types'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const useCoupon = (
    initialCouponCodeValue: string,
    reset: UseFormReturn['reset'],
    setValue: UseFormSetValue<{ [PROMO_CODE_FIELD]: string }>,
    errorHandler: (error: Error) => void,
) => {
    const { t } = useTranslation()
    const [isCouponSetted, setIsCouponSetted] = useState<boolean>(!!initialCouponCodeValue)
    const isCouponSettedRef = useRef<boolean>(isCouponSetted)

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const applyCode = useCartApplyCouponCode()
    const removeCode = useCartRemoveCouponCode()

    const handleApplyCouponToCart = useCallback(async (couponCode: string, successHandler?: () => void) => {
        try {
            const codeValue = couponCode.trim()

            setIsLoading(true)
            await applyCode(codeValue)

            setIsCouponSetted(true)
            isCouponSettedRef.current = true

            successHandler?.()

            reset({
                [PROMO_CODE_FIELD]: codeValue,
            })
        } catch (error) {
            errorHandler(error)
        }
        setIsLoading(false)
    }, [errorHandler])

    const handleRemoveCouponFromCart = useCallback(async (event, shouldSaveEventValue, successHandler?: () => void) => {
        if (!isCouponSettedRef.current) {
            return
        }

        try {
            setIsLoading(true)
            await removeCode()

            successHandler?.()

            setIsCouponSetted(false)
            isCouponSettedRef.current = false

            if (shouldSaveEventValue) {
                const eventTargetValue = event.target.value
                reset({
                    [PROMO_CODE_FIELD]: '',
                })

                setValue(PROMO_CODE_FIELD, eventTargetValue, { shouldDirty: true })
            } else {
                reset({
                    [PROMO_CODE_FIELD]: '',
                })
            }
        } catch (error) {
            errorHandler(error)
        }
        setIsLoading(false)
    }, [errorHandler])

    return {
        handleApplyCouponToCart,
        handleRemoveCouponFromCart,
        isCouponSetted,
        isLoading,
    }
}

export default useCoupon

import { createElement, FC, forwardRef, PropsWithChildren, ReactHTML } from 'react'

interface SectionProps {
    id?: string
    className?: string
    as?: keyof ReactHTML | FC | string
    asProps?: object
}

const Section = (
    forwardRef<any, PropsWithChildren<SectionProps>>(
        (
            {
                id,
                children,
                className,
                as = 'section',
                asProps,
            },
            ref,
        ) => (
            createElement<any>(
                as,
                {
                    ...asProps,
                    id,
                    ref,
                    className,
                },
                children,
            )
        ),
    )
)

export default Section

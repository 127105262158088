import { Resolvers } from '@pwa-concept/modules/graphql'

const ProductsInLiveSearch: Resolvers['ProductsInLiveSearch'] = {
    items: (_, __, props) => {
        const { context } = props
        if (!context.items) {
            return null
        }

        return context.items.map((__context) => {
            return {
                __context,
                __typename: 'Product',
            }
        })
    },
    pageInfo: (_, __, { context }) => {
        if (!context?.page_info) {
            return null
        }
        return {
            currentPage: context.page_info.current_page,
            pageSize: context.page_info.page_size,
            totalPages: context.page_info.total_pages,
            __typename: 'SearchResultPageInfo',
        }
    },
}

export default ProductsInLiveSearch

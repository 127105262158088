export const SocialMediaData = [
    {
        title: 'Instagram',
        iconName: 'instagram',
        link: 'https://www.instagram.com/',
    }, {
        title: 'Twitter',
        iconName: 'twitter',
        link: 'https://twitter.com/',
    }, {
        title: 'Facebook',
        iconName: 'facebook',
        link: 'https://www.facebook.com/',
    },
]

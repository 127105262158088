import { SYMBOL_SLASH } from 'constants/common'
import { getLastCharacterInString } from 'helpers'

export const addSlashToEndUrl = (url: string): string | null => {
    if (!url?.length) {
        return null
    }

    if (getLastCharacterInString(url) === SYMBOL_SLASH) {
        return url
    }

    return `${url}${SYMBOL_SLASH}`
}

export const deleteSlashToEndUrl = (url: string): string | null => {
    if (!url?.length) {
        return null
    }

    if (getLastCharacterInString(url) === SYMBOL_SLASH) {
        return url.slice(0, -1)
    }

    return url
}

export const getLastUrlKeyFromUrl = (url: string): string => {
    return url.split(SYMBOL_SLASH).filter(Boolean).reverse()[0]
}

import { useCustomerOrder } from '@pwa-concept/modules/customer-orders'
import { IOrderPaymentMethod } from '@pwa-onilab/ui/components/modules/Order'
import { getPaymentTitleByCode } from '@pwa-onilab/ui/helpers'
import { useTranslation } from 'react-i18next'

import styles from './OrderPaymentMethod.module.scss'

const OrderPaymentMethodContent = (
    {
        orderNumber,
        defaultPaymentMethod,
    }: Omit<IOrderPaymentMethod, 'className' >,
) => {
    const { t } = useTranslation()
    const { data: { paymentMethod } = {} } = useCustomerOrder(orderNumber)

    const { code: defaultCode, title } = defaultPaymentMethod || {}
    const { type: paymentCode, name } = paymentMethod || {}

    const paymentText = getPaymentTitleByCode(paymentCode || defaultCode) || name || title

    return (
        <div className={styles.contentText}>
            {t('checkoutPage.payment.pay.via') + ' ' + paymentText}
        </div>
    )
}

export default OrderPaymentMethodContent

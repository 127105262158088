import { MediaBreakpointDown, MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'

import CartDefaultHeader from './CartDefaultHeader'
import CartMobileDefaultHeader from './CartMobileDefaultHeader'
import { CartMobileDefaultNoItemHeader } from './CartMobileDefaultNoItemHeader'

export interface CartHeaderProps {
    itemsCount?: number
}

const CartHeader = ({ itemsCount }: CartHeaderProps) => <>
    <MediaBreakpointUp name="md">
        <CartDefaultHeader itemsCount={itemsCount} />
    </MediaBreakpointUp>
    <MediaBreakpointDown name="md">
        {
            itemsCount
                ? <CartMobileDefaultHeader itemsCount={itemsCount} />
                : <CartMobileDefaultNoItemHeader />
        }
    </MediaBreakpointDown>
</>

export default CartHeader

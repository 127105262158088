import { MediaBreakpoint, MediaBreakpointProps } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { PropsWithChildren } from 'react'

interface MediaBreakpointDownProps extends Pick<MediaBreakpointProps, 'name'>{

}

const MediaBreakpointDown = (props: PropsWithChildren<MediaBreakpointDownProps>) => (
    <MediaBreakpoint {...props} direction="down" />
)

export default MediaBreakpointDown

import styles from './Placeholder.module.scss'

const Placeholder = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.title} />
            <div className={styles.inputPlaceholder} />
            <div className={styles.inputPlaceholder} />
            <div className={styles.changePassword} />
            <div className={styles.buttonPlaceholder} />
            <div className={styles.buttonPlaceholder} />
            <div className={styles.deleteButton} />
        </div>
    )
}

export default Placeholder

import Badge from '@pwa-onilab/ui/components/UI/Badge'
import { classNames } from '@pwa-onilab/ui/helpers/classNames'

import styles from './CartBadge.module.scss'

interface CartBadgeProps {
    quantity: number
    isUseResize: boolean
    className?: string
}

const CartBadge = ({ quantity, isUseResize, className }: CartBadgeProps) => {
    const totalQuantity = quantity

    const isMediumBadge = totalQuantity && totalQuantity > 9 && totalQuantity < 100
    const isLargeBadge = totalQuantity && totalQuantity > 99

    return (
        <Badge
            variant="cart" className={classNames(
                isUseResize && isMediumBadge && styles.mediumSize,
                isUseResize && isLargeBadge && styles.largeSize,
                className,
                !totalQuantity && styles.hidden,
            )}
        >
            {totalQuantity}
        </Badge>
    )
}

CartBadge.defaultProps = {
    quantity: 0,
    isUseResize: false,
}

export default CartBadge

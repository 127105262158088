import { Breadcrumb, StoreConfigBestsellers } from '@pwa-concept/modules/graphql'
import { Breadcrumbs } from '@pwa-onilab/ui/components/modules/Breadcrumbs'
import { PRODUCT_MODULE_BESTSELLER_LABEL } from 'app/modules/product'

const BestsellersPageBreadcrumbs = ({ config }: {config: StoreConfigBestsellers}) => {
    const crumbs: Breadcrumb[] = [
        {
            name: config.breadcrumbLabel || PRODUCT_MODULE_BESTSELLER_LABEL,
        },
    ]

    return <Breadcrumbs items={crumbs} />
}

export default BestsellersPageBreadcrumbs

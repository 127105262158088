import { CartItems, ProductStockType } from '@pwa-concept/modules/graphql'

export const checkIsSomeCartItemsOutOfStock = (items: CartItems[]) => {
    if (!items?.length) {
        return false
    }

    return items.some((item) => {
        // magento can return nullable item in case of out of stock item
        if (!item?.id) {
            return false
        }
        return item?.product?.stock?.type === ProductStockType.OutOfStock
    })
}

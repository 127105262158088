import { useQuery } from '@apollo/client'
import { ProductReviewRatingsMetadataQuery } from '@pwa-onilab/ui/schemas'

const useProductReviewData = () => {
    const {
        data: { productReviewRatingsMetadata: { items } = {} } = {},
        loading,
    } = useQuery(ProductReviewRatingsMetadataQuery, {
        fetchPolicy: 'cache-first',
    })

    return {
        data: items?.[0],
        loading,
    }
}

export default useProductReviewData

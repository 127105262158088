import { Button, IButton } from '@pwa-onilab/ui/components/UI/Button'
import { HOME_ROUTE } from '@pwa-onilab/ui/constants/navigation'
import { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'

interface INavigateButton extends Omit<IButton, 'onClick'> {
    route: string
    onClickAdditional?: () => void
}

const NavigateButton = (
    {
        route,
        onClickAdditional,
        children,
        ...buttonProps
    }: PropsWithChildren<INavigateButton>) => {
    const navigate = useNavigate()

    const onClick = () => {
        navigate(HOME_ROUTE)
        onClickAdditional?.()
    }

    return (
        <Button
            {...buttonProps}
            isBold
            isBlack
            onClick={onClick}
        >
            {children}
        </Button>
    )
}

export default NavigateButton

import { Resolvers } from '@pwa-concept/modules/graphql'

const PromoNotification: Resolvers['PromoNotification'] = {
    backgroundColor: (_, __, { context }) => {
        return context.background_color || null
    },

    color: (_, __, { context }) => {
        return context.color || null
    },

    text: (_, __, { context }) => {
        return context.text || null
    },

    url: (_, __, { context }) => {
        return context.url || null
    },
}

export default PromoNotification

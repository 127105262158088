import { VERIFY_ONE_TIME_PASSWORD_LENGTH } from '@pwa-onilab/ui/constants/customer'
import { BACKSPACE_KEYCODE } from '@pwa-onilab/ui/constants/keys/keycodes'

export const handleKeyDown = (e) => {
    if (e.keyCode === BACKSPACE_KEYCODE) {
        if (e.target.value.length) {
            return
        }
        if (e.target.previousSibling) {
            e.target.previousSibling.focus()
        }
    }
}

export const onPasteCodeInput = (e, checkFormCallback) => {
    e.preventDefault()
    const clipboardData = e.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('Text')?.trim().substring(0, VERIFY_ONE_TIME_PASSWORD_LENGTH)
    const arrPastedData = pastedData.split('')
    let currentTarget = e.target
    for (let i = 0; i < arrPastedData.length; i += 1) {
        currentTarget.value = arrPastedData[i]
        const nextTarget = currentTarget.nextSibling
        if (nextTarget) {
            nextTarget.focus()
            currentTarget = nextTarget
        } else {
            currentTarget.blur()
            break
        }
    }
    checkFormCallback({})
}

export const onChangeCodeInput = (e, setValueCallback, checkFormCallback) => {
    if (e.target.value.length < 2) {
        setValueCallback(e.target.name, e.target.value)
    } else {
        e.target.value = e.target.value.slice(-1)
    }

    if (e.target.value) {
        const nextTarget = e.target.nextSibling
        if (nextTarget) {
            nextTarget.focus()
        } else {
            e.target.blur()
        }
        checkFormCallback({ nextTarget })
    }
}

import { CustomerAddressInput, CustomerAddressRegionInput } from '@pwa-concept/modules/graphql'
import { ADDRESS_FORM_FIELDS_TYPE } from '@pwa-onilab/ui/components/modules/AddressForm'
import { getCustomerNames } from '@pwa-onilab/ui/helpers'

export const extractAddressFromFormValues = (
    {
        city,
        company,
        countryCode,
        defaultShipping,
        defaultBilling,
        fullName,
        postcode,
        region,
        regionId,
        street1,
        street2,
        telephone,
        vatId,
    }: ADDRESS_FORM_FIELDS_TYPE,
) => {
    const { firstname, middlename, lastname } = getCustomerNames(fullName) || {}
    const regionObj: CustomerAddressRegionInput = regionId ? { regionId: +regionId } : { region }

    const addressInput: CustomerAddressInput = {
        city,
        company,
        countryCode,
        defaultBilling,
        defaultShipping,
        firstName: firstname,
        middleName: middlename,
        lastName: lastname,
        postcode,
        region: regionObj,
        street: [street1.trim(), street2?.trim()],
        telephone,
        vatId,
    }

    return addressInput
}

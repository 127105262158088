import { ISearchProviders } from '@pwa-onilab/ui/components/modules/Search/providers/ISearchProviders'
import { useContextPattern } from '@pwa-onilab/ui/hooks'
import { createContext, PropsWithChildren } from 'react'

const SearchValueContext = createContext<string>('')
const useSearchValueCtx: () => string = () => useContextPattern(SearchValueContext)

const SearchValueProvider = (
    {
        children,
        searchValue,
    }: PropsWithChildren<Pick<ISearchProviders, 'searchValue'>>) => {

    return (
        <SearchValueContext.Provider value={searchValue}>
            {children}
        </SearchValueContext.Provider>
    )
}

export { SearchValueProvider, useSearchValueCtx }

import {
    AddressFormCheckboxesSection as CheckboxesSection,
    AddressFormTitle,
    AddressModalAddressSection as AddressSection,
    AddressModalPersonalInfoSection as PersonalInfoSection, useAddressForm,
} from '@pwa-onilab/ui/components/modules/AddressForm'
import { AddressFormSubmitButton } from '@pwa-onilab/ui/components/modules/AddressForm/elements/sections/SubmitButton'
import { FormProvider } from 'react-hook-form'

import styles from './AddressForm.module.scss'
import IAddressForm from './IAddressForm'

const AddressForm = (
    {
        onSubmit,
        title,
        defaultValues = {},
        isCheckout,
        isShippingDefaultChecked,
        isBillingDefaultChecked,
        isBilling,
    }: IAddressForm) => {
    const { isSubmitting, methods, submitHandler } = useAddressForm(defaultValues, onSubmit)
    const { handleSubmit, formState: { isDirty } } = methods

    return (
        <FormProvider {...methods}>
            <AddressFormTitle title={title} />
            <form onSubmit={handleSubmit(submitHandler)} className={styles.addressForm}>
                <PersonalInfoSection className={styles.addressFormSection} />
                <AddressSection className={styles.addressFormSection} />
                <CheckboxesSection
                    className={styles.addressFormSection}
                    isCheckout={isCheckout}
                    isShippingDefaultChecked={isShippingDefaultChecked}
                    isBillingDefaultChecked={isBillingDefaultChecked}
                    isBilling={isBilling}
                />
                <AddressFormSubmitButton
                    isLoading={isSubmitting}
                    className={styles.addressFormSection}
                    disabled={!isDirty}
                />
            </form>
        </FormProvider>
    )
}

export default AddressForm

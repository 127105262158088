import { StrainFamiliesBreadcrumbs, StrainFamiliesListPlaceholder } from 'modules/StrainFamilies'
import StrainFamiliesPageContainer from 'modules/StrainFamilies/elements/StrainFamiliesPageContainer'

const StrainFamiliesPagePlaceholder = () => {
    return (
        <StrainFamiliesPageContainer>
            <StrainFamiliesBreadcrumbs />
            <StrainFamiliesListPlaceholder />
        </StrainFamiliesPageContainer>
    )
}

export default StrainFamiliesPagePlaceholder

import { useApolloClient } from '@apollo/client'
import { $auth, $cart, $wishlist } from '@pwa-concept/core/models'
import { useCartGenerateId } from '@pwa-concept/modules/cart'
import { useCustomerDeleteFromCache } from '@pwa-concept/modules/customer'
import { useCustomerAddressDeleteCache } from '@pwa-concept/modules/customer-addresses'
import { useWishlistDeleteFromCache } from '@pwa-concept/modules/wishlist'
import { HOME_ROUTE } from '@pwa-onilab/ui/constants/navigation'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    CHECKOUT_NEWSLETTER_TOGGLER_STATUS_KEY
} from "@pwa-onilab/ui/components/modules/Checkout/CheckoutProviders/NewslettersTogglerProvider/constants";

// TODO delete customer orders
const useLogOut = () => {
    const { cache } = useApolloClient()
    const generateId = useCartGenerateId()
    const navigate = useNavigate()

    const deleteCustomerAddresses = useCustomerAddressDeleteCache()
    const deleteCustomer = useCustomerDeleteFromCache()
    const deleteWishlist = useWishlistDeleteFromCache()

    return useCallback(async (isSkipNavigation: boolean = false) => {
        $auth.reset()
        $cart.reset()
        $wishlist.reset()

        deleteCustomerAddresses()
        deleteCustomer()
        deleteWishlist()

        localStorage.removeItem(CHECKOUT_NEWSLETTER_TOGGLER_STATUS_KEY)

        cache.gc()
        await generateId()
        if (!isSkipNavigation) {
            navigate(HOME_ROUTE)
        }
    }, [])
}
export default useLogOut

import { useStoreConfig } from '@pwa-concept/modules/store-config'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { CheckboxForm, ICheckboxForm } from '@pwa-onilab/ui/components/UI/Checkbox'
import { FORM_TERMS_AND_CONDITIONS_CHECKBOX } from '@pwa-onilab/ui/constants/formFields'
import { classNames } from '@pwa-onilab/ui/helpers'
import { Trans, useTranslation } from 'react-i18next'

import styles from './Terms.module.scss'

const TermsAndConditionsCheckbox = (
    {
        register,
        disabled,
        errors,
    }: ICheckboxForm) => {
    const { t } = useTranslation()
    const {
        data: { cmsPagesUrls: { termsOfUse, privacyPolicy } = {} } = {},
        loading: storeConfigLoading,
    } = useStoreConfig()

    const anchorClassNames = classNames(
        styles.anchor,
        storeConfigLoading && styles.linkLoader,
    )

    return (
        <CheckboxForm
            register={register}
            id={FORM_TERMS_AND_CONDITIONS_CHECKBOX}
            rules={{
                required: t('global.errors.required'),
            }}
            errors={errors}
            disabled={disabled}
        >
            <Trans
                i18nKey="signUpForm.termsAndConditions"
                components={{
                    AnchorTerms: (
                        <Anchor
                            className={anchorClassNames}
                            to={termsOfUse}
                            isForceAbsoluteUrl
                        />
                    ),
                    AnchorPrivacy: (
                        <Anchor
                            className={anchorClassNames}
                            to={privacyPolicy}
                            isForceAbsoluteUrl
                        />
                    ),
                }}
            />
        </CheckboxForm>
    )
}

export default TermsAndConditionsCheckbox

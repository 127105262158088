import { useCountriesQuery } from '@pwa-concept/modules/countries/graphql/queries/Countries'

const useCountries = () => {
    const { data, error, loading } = useCountriesQuery({
        fetchPolicy: 'cache-first',
    })

    return {
        data: data?.Countries,
        loading,
        error,
    }
}

export default useCountries

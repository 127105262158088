import { BlogAuthor } from '@pwa-concept/modules/graphql'
import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { AnchorWithArrow } from 'elements/AnchorWithArrow'

import styles from './AuthorPageCategoriesList.module.scss'

const getUrl = (blogUrl: string, categoryId: string) => {
    return `${blogUrl}/?filters=category_id:${categoryId}`
}

const AuthorPageCategoriesList = ({ categories }: {categories: BlogAuthor['categories']}) => {
    const { data: storeConfig } = useStoreConfig()
    if (!categories?.length || !storeConfig?.blogHomepageUrlKey) {
        return null
    }

    return (
        <ul className={styles.list}>
            {categories.map(({ id, title }) => {
                const url = getUrl(storeConfig.blogHomepageUrlKey, id)

                return (
                    <li key={title} className={styles.listItem}>
                        <AnchorWithArrow to={url} className={styles.link} isPlaceArrowBeforeChild>
                            {title}
                        </AnchorWithArrow>
                    </li>
                )
            })}
        </ul>
    )
}

export default AuthorPageCategoriesList

import { useCmsBlocks } from '@pwa-concept/modules/cms'
import { StoreConfigBestsellers } from '@pwa-concept/modules/graphql'
import ParserBounded from '@pwa-onilab/ui/components/elements/ParserBounded/ParserBounded'

const BestsellersPageDescription = ({ config }: {config: StoreConfigBestsellers}) => {
    const {
        data: {
            items: {
                0: descriptionBlockCms,
            } = [],
        } = {},
        loading,
    } = useCmsBlocks({
        variables: {
            identifiers: [config.descriptionBlock],
        },
        skip: !config,
    })

    if (loading || !descriptionBlockCms) {
        return null
    }

    return <ParserBounded content={descriptionBlockCms.content} />
}

export default BestsellersPageDescription

import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { DefaultHelmet, IDefaultHelmet } from '@pwa-onilab/ui/components/elements/DefaultHelmet'
import { SchemaHelmetArticle } from 'elements/SchemaHelmet/SchemaHelmetArticle'
import { IArticleSchemaConfig } from 'elements/SchemaHelmet/SchemaHelmetArticle/SchemaHelmetArticle'
import { SchemaHelmetPerson } from 'elements/SchemaHelmet/SchemaHelmetPerson'
import { SchemaHelmetWebPage } from 'elements/SchemaHelmet/SchemaHelmetWebPage'
import { useTranslation } from 'react-i18next'

const BlogPostHelmet = ({ post }) => {
    const { t } = useTranslation()
    const { data: storeConfig } = useStoreConfig()

    const authorName = post.author?.name || t('global.anonymous')

    const meta: IDefaultHelmet['meta'] = {
        title: post.metaTitle || '',
        keywords: post.metaKeywords || '',
        description: post.metaDescription || '',
        robots: post.metaRobots || '',
        ogType: 'article',
        customProperties: [
            {
                name: 'author',
                value: authorName,
            },
            {
                name: 'article:published_time',
                value: post.publishTime,
            },
            {
                name: 'twitter:label1',
                value: 'Written by',
            },
            {
                name: 'twitter:data1',
                value: authorName,
            },
        ],
    }

    const articleConfig: IArticleSchemaConfig = {
        title: post.title || '',
        datePublished: post.publishTime || '',
        imageSrc: post.featuredImage || '',
        thumbnailSrc: post.thumbnail || '',
        categories: post.categories?.map((category) => category.title) || null,
    }

    if (post.featuredImage) {
        meta['ogImage'] = {
            src: post.featuredImage,
            alt: post.featuredImgAlt,
        }
    } else if (post.thumbnail) {
        meta['ogImage'] = {
            src: post.thumbnail,
            alt: post.featuredImgAlt,
        }
    }

    if (storeConfig?.ogArticlePublisher) {
        meta.customProperties.push({
            name: 'article:publisher',
            value: storeConfig.ogArticlePublisher,
        })
    }

    return (
        <>
            <DefaultHelmet
                meta={meta}
                defaultTitle={t('blogPost.title')}
            />
            <SchemaHelmetWebPage meta={meta} />
            <SchemaHelmetArticle meta={meta} config={articleConfig} author={post.author} />
            <SchemaHelmetPerson author={post.author} />
        </>
    )
}

export default BlogPostHelmet

import { FilterResultCount } from '@pwa-onilab/ui/components/modules/Filters'
import { IFilterControls } from '@pwa-onilab/ui/components/modules/Filters/components/FilterControls/IFilterControls'
import FilterSortBy from 'components/overrides/modules/Filters/components/FilterSortBy/FilterSortBy'
import { IFilterSortByData } from 'components/overrides/modules/Filters/components/FilterSortBy/IFilterSortBy'

import styles from './PageSortPanel.module.scss'

const PageSortPanel = (
    {
        numResults,
        currentValues,
        onChangeValue,
        data,
    }: Pick<IFilterControls & IFilterSortByData, 'numResults' | 'currentValues' | 'onChangeValue' | 'data'>,
) => {
    const sortId = Object.keys(currentValues?.sort || {})?.[0]

    return (
        <div className={styles.header}>
            <FilterResultCount
                numResults={numResults}
                className={styles.totalCount}
            />
            <FilterSortBy
                onChangeValue={onChangeValue}
                data={data}
                sortId={sortId}
            />
        </div>
    )
}

export default PageSortPanel

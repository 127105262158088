import { Context, useContext } from 'react'

const useContextPattern = (contextObj: Context<any>) => {
    const ctx = useContext(contextObj)

    if (ctx === null) {
        const contextName = contextObj.displayName || 'Context'
        throw new Error(`${contextName} was called outside context provider`)
    }

    return ctx
}

export default useContextPattern

import { useTranslation } from 'react-i18next'

import styles from './AccountDeleteFormDescription.module.scss'

const AccountDeleteFormDescription = () => {
    const { t } = useTranslation()

    return (
        <p className={styles.description}>
            {t('AccountPage.delete.description')}
        </p>
    )
}

export default AccountDeleteFormDescription

import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './CmsPageMenuItem.module.scss'

interface ICmsPageMenuItemProps {
    className: string
    title: string
    to: string
}

const CmsPageMenuItem = (
    {
        className,
        title,
        to,
    }: Partial<ICmsPageMenuItemProps>,
) => (
    <Anchor
        to={to}
        className={classNames(styles.item, className)}
        activeClassName={styles.active}
    >
        {title}
    </Anchor>
)

export default CmsPageMenuItem

import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './BackButton.module.scss'

interface BackButtonProps {
    className?: string
    onClick?: () => void
}

const BackButton = (
    {
        className,
        onClick,
    }: BackButtonProps,
) => {
    return (
        <ButtonInstance className={classNames(styles.wrapper, className)} onClick={onClick}>
            <Icon name="chevronBold" />
        </ButtonInstance>
    )
}

export default BackButton

import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const storeConfigWholeSale: QueryResolvers['storeConfigWholeSale'] = async (_, inputObj) => {
    const { data: { storeConfig: __context = {} } = {} } = await (
        api.graphql(
            gql`
                query {
                    storeConfig {
                        wholesale_top_description_block
                        wholesale_description_block
                        
                        wholesale_homepage_meta_title
                        wholesale_homepage_meta_keywords
                        wholesale_homepage_meta_description
                        wholesale_homepage_meta_robots
                    }
                }
            `,
        ).query(undefined, {
            errorPolicy: 'all',
        })
    )

    return {
        __context,
        __typename: 'StoreConfigWholeSale',
    }
}

export default storeConfigWholeSale

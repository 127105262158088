import { memo } from 'react'

import styles from './Title.module.scss'

const ModalContentTitle = ({ title }: {title: string}) => {
    return (
        <h2 className={styles.wrapper}>
            {title}
        </h2>
    )
}

export default memo(ModalContentTitle)

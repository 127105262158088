import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Account from '@pwa-onilab/ui/components/modules/HeaderBottom/UserPanel/Account'
import Cart from '@pwa-onilab/ui/components/modules/HeaderBottom/UserPanel/Cart'
import Wishlist from '@pwa-onilab/ui/components/modules/HeaderBottom/UserPanel/Wishlist/Wishlist'
import Container from '@pwa-onilab/ui/components/UI/Container'
import Logo from '@pwa-onilab/ui/components/UI/Logo'
import { HEADER_BOTTOM_ID } from '@pwa-onilab/ui/constants/app'
import IMenuObjData from 'components/overrides/modules/Menu/IMenuObjData'
import Menu from 'components/overrides/modules/Menu/Menu'
import Search from 'components/overrides/modules/Search/Search'
import { useState } from 'react'

import styles from './HeaderBottom.module.scss'

interface IHeaderBottomProps {
    menuData: IMenuObjData[]
}

const HeaderBottom = (
    {
        menuData,
    }: IHeaderBottomProps,
) => {
    const isDesktop = useMediaBreakpoint().isBreakpointUp('lg')
    const [activeCategory, setActiveCategory] = useState<IMenuObjData>(null)

    const closeDropDownMenu = () => {
        if (activeCategory) {
            setActiveCategory(null)
        }
    }

    return (
        <div
            id={HEADER_BOTTOM_ID}
            className={styles.header}
            onClick={closeDropDownMenu}
        >
            <Container className={styles.container}>
                <Logo className={styles.logo} />
                {
                    isDesktop && (
                        <Menu
                            menuData={menuData}
                            activeCategory={activeCategory}
                            setActiveCategory={setActiveCategory}
                        />
                    )
                }
                <Search className={styles.search} inputClassName={styles.input} />
                {
                    isDesktop && (
                        <div className={styles.buttons}>
                            <Wishlist className={styles.wishlist} />
                            <Account className={styles.account} />
                            <Cart />
                        </div>
                    )
                }
            </Container>
        </div>
    )
}

export default HeaderBottom

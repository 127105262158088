import useModalStates from '@pwa-onilab/ui/hooks/modals/useModalStates'
import { useMemo, useState } from 'react'

// TODO add type for open handler
const useModalWithStoredId = <T>() => {
    const [id, setId] = useState<T>(null)
    const {
        isModalOpen: isOpen,
        openModal,
        closeModal,
    } = useModalStates()

    const openModalHandler = (id: T) => () => {
        setId(id)
        openModal()
    }

    const closeModalHandler = () => {
        setId(null)
        closeModal()
    }

    const {
        openModalCallback,
        closeModalCallback,
    } = useMemo(() => {
        return {
            openModalCallback: openModalHandler,
            closeModalCallback: closeModalHandler,
        }
    }, [])

    return {
        isOpen,
        openModal: openModalCallback,
        closeModal: closeModalCallback,
        id,
    }
}

export default useModalWithStoredId

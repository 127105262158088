import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { PropsWithChildren } from 'react'

import styles from './CategoryProductCardAttribute.module.scss'

const CategoryProductCardAttribute = (
    {
        className,
        children,
    }: PropsWithChildren<IClassName>,
) => (
    <div className={classNames(styles.item, className)}>
        {
            children && (
                <span className={styles.value}>
                    {children}
                </span>
            )
        }
    </div>
)

export default CategoryProductCardAttribute

import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { PropsWithChildren } from 'react'

import styles from './AuthorPageRowWrapper.module.scss'

const AuthorPageRowWrapper = ({ children, className }: PropsWithChildren<IClassName>) => {
    return (
        <div className={classNames(styles.wrapper, className)}>
            {children}
        </div>
    )
}

export default AuthorPageRowWrapper

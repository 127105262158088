import { ADDRESS_FORM_FIELDS, ZIP_CODES } from '@pwa-onilab/ui/components/modules/AddressForm'
import { useMemo } from 'react'
import { RegisterOptions, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const usePostalCodeFieldRules = (): RegisterOptions => {
    const { t } = useTranslation()
    const { getValues } = useFormContext()

    return useMemo((): RegisterOptions => {
        return {
            required: t('global.errors.required'),

            validate: (value) => {
                const currentCountry = getValues()[ADDRESS_FORM_FIELDS.COUNTRY_CODE]
                const postalCodeExamples = ZIP_CODES[currentCountry]

                if (!postalCodeExamples) {
                    return true
                }

                if (Array.isArray(postalCodeExamples)) {
                    const isValid = postalCodeExamples.some(({ pattern }) => new RegExp(pattern).test(value))

                    return isValid || t('addressForm.errors.invalidPostalCode', { codeExample: postalCodeExamples[0].example })
                }

                const regExp = new RegExp(postalCodeExamples.pattern_1.pattern)
                const isValid = regExp.test(value)

                return isValid || t('addressForm.errors.invalidPostalCode', { codeExample: postalCodeExamples.pattern_1.example })
            },
        }
    }, [])
}

export default usePostalCodeFieldRules

import { useQuery } from '@apollo/client'
import {
    SearchListPlaceholder,
    useSearchValueCtx,
} from '@pwa-onilab/ui/components/modules/Search'
import { IBlogPostsQuery } from 'modules/Blog/interfaces'
import SearchBlogPostsList from 'modules/Search/components/SearchBlogPostsList/SearchBlogPostsList'
import { GetBlogPostsQuery } from 'schemas'

const SearchBlogPosts = () => {
    const searchValue = useSearchValueCtx()

    const { data: { blogPosts: data } = {}, loading } = useQuery<IBlogPostsQuery>(GetBlogPostsQuery, {
        variables: {
            page: 1,
            pageSize: 4,
            search: searchValue,
        },
        skip: !searchValue,
    })

    if (!data?.items?.length) {
        if (loading) {
            return <SearchListPlaceholder />
        }
        return null
    }
    return (
        <SearchBlogPostsList posts={data.items} />
    )
}

export default SearchBlogPosts

import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const getFamilyStrains: QueryResolvers['getFamilyStrains'] = async (_, { currentPage, pageSize }) => {
    const { data: { getFamilyStrains: __context } = {} } = await (
        api.graphql(
            gql`
                query( $currentPage: Int, $pageSize: Int) {
                    getFamilyStrains(currentPage: $currentPage, pageSize: $pageSize) {
                        total_count
                        page_info {
                            current_page
                            page_size
                            total_pages
                        }
                        items {
                            ...FamilyStrain
                        }
                    }
                }
            `,
        ).query({
            currentPage,
            pageSize,
        })
    )

    return {
        __context,
        __typename: 'FamilyStrains',
    }
}

export default getFamilyStrains

import { TextOnlyButton } from '@pwa-onilab/ui/components/elements/Buttons'
import { useAccountOrderModal } from '@pwa-onilab/ui/components/modules/AccountSubpages'
import { useTranslation } from 'react-i18next'

import styles from './AccountOrderViewButton.module.scss'

interface IAccountOrderViewButton {
    orderNumber: string
}

const AccountOrderViewButton = ({ orderNumber }: IAccountOrderViewButton) => {
    const { t } = useTranslation()
    const { openModal } = useAccountOrderModal()
    const onClick = () => openModal(orderNumber)

    return (
        <TextOnlyButton className={styles.viewButton} isBold onClick={onClick}>
            {t('global.buttons.view')}
        </TextOnlyButton>
    )
}

export default (AccountOrderViewButton)

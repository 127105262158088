import { Breadcrumbs } from '@pwa-onilab/ui/components/modules/Breadcrumbs'
import { classNames } from '@pwa-onilab/ui/helpers'

interface CmsPageBreadcrumbsProps {
    className: string
    currentCmsPageTitle: string
}

const FaqPageBreadcrumbs = (
    {
        className,
        currentCmsPageTitle,
    }: Partial<CmsPageBreadcrumbsProps>,
) => (
    <Breadcrumbs
        className={classNames(className)}
        items={[{ name: currentCmsPageTitle }]}
    />
)

export default FaqPageBreadcrumbs

import { ITitle } from '@pwa-onilab/ui/components/UI/Title'
import { createElement } from 'react'

const Title = (
    {
        type,
        text,
        className,
        mutableRef,
    }: ITitle) => {
    return createElement(
        type,
        {
            className,
            ref: mutableRef,
        },
        text,
    )
}

export default Title

import { Product } from '@pwa-concept/modules/graphql'
import { useWishlist, useWishlistRemoveItem } from '@pwa-concept/modules/wishlist'
import useWishlistAddItem from '@pwa-concept/modules/wishlist/features/add/hooks/useWishlistAddItem'
import { ga4AddToWishlist } from '@pwa-onilab/ga4/operations/product/addToWishlist'
import {
    WISHLIST_ADD_PRODUCT_MUTATION_BASE_OPTIONS, WISHLIST_REMOVE_PRODUCT_MUTATION_BASE_OPTIONS,
} from '@pwa-onilab/ui/components/modules/Product/elements/WishlistButton/WishlistButtonConstants'
import { isProductOnTheWishlist } from '@pwa-onilab/ui/components/modules/Wishlist'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { PropsWithChildren, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import styles from './WishlistButton.module.scss'

interface WishlistButtonProps extends IClassName {
    isProductPage?: boolean
    isCategoryCard?: boolean
    isAbsolutePositionVariant?: boolean
    isCart?: boolean
    sku: Product['sku']
}
const WishlistButton = (
    {
        className,
        children,
        isProductPage,
        isCategoryCard,
        isCart,
        sku,
        isAbsolutePositionVariant,
    }: PropsWithChildren<WishlistButtonProps>,
) => {
    const { t } = useTranslation()

    const [isProductInWishlist, setProductInWishlist] = useState<boolean>(false)
    const [currentWishlistProductId, setCurrentWishlistProductId] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const addProductsToWishlist = useWishlistAddItem(WISHLIST_ADD_PRODUCT_MUTATION_BASE_OPTIONS)
    const removeProductsFromWishlist = useWishlistRemoveItem(WISHLIST_REMOVE_PRODUCT_MUTATION_BASE_OPTIONS)

    // TODO mutate product in cache once on wishlist apollo write seems to be better
    useWishlist({
        onCompleted: ({ wishlist }) => {
            setProductInWishlist(isProductOnTheWishlist(wishlist, setCurrentWishlistProductId, sku))
        },
    })

    const addProductHandler = useCallback(async() => {
        if (!sku) return

        const items = [
            {
                sku,
                quantity: 1,
            },
        ]
        setIsLoading(true)

        await addProductsToWishlist({ items })
            .then(({ data: { wishlist } = {} }) => {
                if (!wishlist?.items?.items) {
                    setProductInWishlist(false)
                    return
                }
                //  FIXME does it really need?
                setProductInWishlist(isProductOnTheWishlist(wishlist, setCurrentWishlistProductId, sku))

                const addedItem = wishlist.items.items.find((wishlistItem) => {
                    return wishlistItem.product.sku === sku
                })
                ga4AddToWishlist({ product: addedItem.product })
            })
            .catch((error) => toast.error(error.message))

        setIsLoading(false)
    }, [sku])

    const removeProductHandler = useCallback(async () => {
        if (!sku) return

        let items = []

        if (currentWishlistProductId) {
            items = [currentWishlistProductId]
        }

        setIsLoading(true)

        await removeProductsFromWishlist({ items })
            .then(({ data: { wishlist } = {} }) => {
                setProductInWishlist(isProductOnTheWishlist(wishlist, setCurrentWishlistProductId, sku))
            })
            .catch((error) => toast.error(error.message))

        setIsLoading(false)
    }, [sku, currentWishlistProductId])

    const classes = classNames(
        styles.button,
        isAbsolutePositionVariant && styles.absoluteVariant,
        isProductPage && styles.productPageVariant,
        isCategoryCard && styles.categoryCardVariant,
        isCart && styles.cartVariant,
        className,
    )

    return (
        <ButtonInstance
            className={classes}
            onClick={isProductInWishlist ? removeProductHandler : addProductHandler}
            aria-label={t('wishlist.button.add')}
        >
            <Icon
                name={isProductInWishlist ? 'heartFill' : 'heart'}
                className={classNames(
                    isProductInWishlist ? styles.heartFillIcon : styles.heartIcon,
                    isLoading && styles.beatAnimation)}
            />
            {children}
        </ButtonInstance>
    )
}
export default WishlistButton

import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type SendDeleteAccountMessageMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SendDeleteAccountMessageMutation = { __typename?: 'Mutation', sendDeleteAccountMessage?: { __typename?: 'SendDeleteAccountMessage', status?: boolean | null, errorMessage?: string | null } | null };


export const SendDeleteAccountMessageDocument = gql`
    mutation SendDeleteAccountMessage {
  sendDeleteAccountMessage @client {
    status
    errorMessage
  }
}
    `;
export type SendDeleteAccountMessageMutationFn = Apollo.MutationFunction<SendDeleteAccountMessageMutation, SendDeleteAccountMessageMutationVariables>;

/**
 * __useSendDeleteAccountMessageMutation__
 *
 * To run a mutation, you first call `useSendDeleteAccountMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDeleteAccountMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDeleteAccountMessageMutation, { data, loading, error }] = useSendDeleteAccountMessageMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendDeleteAccountMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendDeleteAccountMessageMutation, SendDeleteAccountMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SendDeleteAccountMessageMutation, SendDeleteAccountMessageMutationVariables>(SendDeleteAccountMessageDocument, options);
      }
export type SendDeleteAccountMessageMutationHookResult = ReturnType<typeof useSendDeleteAccountMessageMutation>;
export type SendDeleteAccountMessageMutationResult = Apollo.MutationResult<SendDeleteAccountMessageMutation>;
export type SendDeleteAccountMessageMutationOptions = Apollo.BaseMutationOptions<SendDeleteAccountMessageMutation, SendDeleteAccountMessageMutationVariables>;
import { useCatalogQuery } from '@pwa-concept/modules/catalog/graphql/queries/Catalog'
import { Catalog, CatalogQueryInput } from '@pwa-concept/modules/graphql'
import { CATEGORY_PAGE_SIZE } from '@pwa-onilab/ui/components/modules/Category'
import { usePaginationLoadedPages } from '@pwa-onilab/ui/components/modules/Pagination/hooks/usePaginationLoadedPages'
import usePaginationUrlPages from '@pwa-onilab/ui/components/modules/Pagination/hooks/usePaginationUrlPages'
import { tPaginationDispatcher } from '@pwa-onilab/ui/components/modules/Pagination/paginationInterfaces'
import { getPaginationActionDispatcher } from '@pwa-onilab/ui/helpers/pagination/getPaginationActionDispatcher'
import { getUrlSearchParam } from '@pwa-onilab/ui/helpers/urlParams'
import { useMemo, useState } from 'react'

const LOADED_PAGES_SET = new Set<number>()
const useCategoryItems = (
    catalogInput: Omit<CatalogQueryInput, 'limit' | 'page'>,
    skip?: boolean,
) => {
    const [clientPage, setClientPage] = useState<number>(+getUrlSearchParam(undefined, 'page') || 1)
    const [serverPage, setServerPage] = useState<number>(clientPage)

    const [cachedData, setCachedData] = useState<Catalog | null>(null)

    usePaginationLoadedPages(LOADED_PAGES_SET, setClientPage, setServerPage, setCachedData, catalogInput)
    usePaginationUrlPages(clientPage, serverPage)

    const onCategoryCompleted = (values) => {
        LOADED_PAGES_SET.add(values.catalog.pagination.current)

        setCachedData(prevState => {
            if (!prevState) {
                return values.catalog
            }

            return {
                ...prevState,
                items: [
                    ...prevState.items,
                    ...values.catalog.items,
                ],
                pagination: values.catalog.pagination || prevState.pagination,
            }
        })
    }

    const dispatchPaginationAction: tPaginationDispatcher = getPaginationActionDispatcher({
        setClientPage,
        setServerPage,
        setCachedData,
        loadedPagesSet: LOADED_PAGES_SET,
    })

    const { onCategoryCompletedMemo, dispatchPaginationActionMemo } = useMemo(() => {
        return {
            onCategoryCompletedMemo: onCategoryCompleted,
            dispatchPaginationActionMemo: dispatchPaginationAction,
        }
    }, [])

    const {
        data: { catalog: data } = {},
        loading,
    } = useCatalogQuery({
        variables: {
            input: {
                ...catalogInput,
                limit: CATEGORY_PAGE_SIZE,
                page: clientPage,
            },
        },
        skip: skip || LOADED_PAGES_SET.has(clientPage),
        onCompleted: onCategoryCompletedMemo,
        fetchPolicy: 'cache-first',
    })

    return {
        data: cachedData || data,
        dispatchPaginationAction: dispatchPaginationActionMemo,
        loading,

        clientPage,
        serverPage,
    }
}

export default useCategoryItems

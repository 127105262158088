import { AccountNavigation } from '@pwa-onilab/ui/components/modules/Account'
import { useOutlet } from 'react-router-dom'

import styles from '../AccountPage.module.scss'

const AccountPageDesktop = () => {
    const outlet = useOutlet()

    return (
        <div className={styles.mainContentWrapper}>
            <AccountNavigation />
            <div className={styles.accountSubpageWrapper}>
                {outlet}
            </div>
        </div>
    )
}

export default AccountPageDesktop

import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const storeConfigRegistrationPageMeta: QueryResolvers['storeConfigRegistrationPageMeta'] = async () => {
    const { data } = await (
        api.graphql(
            gql`
                query {
                    storeConfig {
                        registration_page_meta_title
                        registration_page_meta_keywords
                        registration_page_meta_description
                        registration_page_meta_robots
                    }
                }
            `,
        ).query(undefined, {
            errorPolicy: 'all',
        })
    )
    if (!data?.storeConfig) {
        return null
    }

    return {
        title: data.storeConfig.registration_page_meta_title || null,
        keywords: data.storeConfig.registration_page_meta_keywords || null,
        description: data.storeConfig.registration_page_meta_description || null,
        robots: data.storeConfig.registration_page_meta_robots || null,
        __typename: 'StoreConfigDefaultPageMeta',
    }
}

export default storeConfigRegistrationPageMeta

import { useCustomerLazyQuery } from "@pwa-concept/modules/customer/graphql/queries/Customer";

const useLazyCustomer = () => {
    const [getCustomer, { loading }] = useCustomerLazyQuery()

    return {
        getCustomer,
        loading
    }
}

export default useLazyCustomer

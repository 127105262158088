import { useCart } from '@pwa-concept/modules/cart'
import { CartMoney } from '@pwa-concept/modules/graphql'

interface IUseCartTotalsOutput {
    data: {
        subtotal: number
        discounts: CartMoney[]
        delivery: number
        total: number

    }
    loading: boolean
}

const useCartTotals = (): IUseCartTotalsOutput => {
    const { data: { totals } = {}, loading } = useCart()

    return {
        data: {
            subtotal: +totals?.sub?.money || 0,
            discounts: totals?.discounts || [],
            delivery: +totals?.shipping?.money || 0,
            total: +totals?.grand?.money || 0,
        },
        loading,
    }
}

export default useCartTotals

import { FamilyStrain } from '@pwa-concept/modules/graphql'
import { ImageLazy } from '@pwa-onilab/ui/components/UI/Image/ImageLazy'
import { Link } from '@pwa-onilab/ui/components/UI/Link'
import Title from '@pwa-onilab/ui/components/UI/Title'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { addSlashToEndUrl } from 'helpers'
import { useTranslation } from 'react-i18next'

import styles from './StrainFamilyCard.module.scss'

interface IStrainFamilyCard extends IClassName {
    strain: FamilyStrain
}

const StrainFamilyCard = ({ strain, className }: IStrainFamilyCard) => {
    const { t } = useTranslation()

    const strainUrl = `${addSlashToEndUrl(window.location.pathname)}${strain.urlKey}/`

    return (
        <div className={classNames(styles.wrapper, className)}>
            <Link to={strainUrl} className={styles.image} aria-label={strain.name}>
                <ImageLazy
                    src={strain.thumbnail || undefined}
                    alt="family strains thumbnail"
                />
            </Link>
            <div className={styles.mainContent}>
                <Title text={strain.name} type="h2" className={styles.title} />
                <p className={styles.shortText}>
                    {strain.shortDescription}
                </p>
                <Link className={styles.link} to={strainUrl}>
                    {t('strains.homepage.buySeeds', { name: strain.name })}
                </Link>
            </div>
        </div>
    )
}

export default StrainFamilyCard

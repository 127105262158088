import { State } from '@pwa-concept/modules/graphql'
import { PageIntroBlockMobile } from 'elements/BlogStatePages/elements'

const StatePageIntroBlockMobile = ({ state }: {state: State}) => {
    return (
        <PageIntroBlockMobile
            backBtnUrlKey={state.location.urlKey}
            title={state.pageTitle}
            author={state.author}
            createdAt={state.publishTime}
            imageSrc={state.image}
        />
    )
}

export default StatePageIntroBlockMobile

import { Helmet } from 'react-helmet'

interface ISchemaHelmet {
    jsonConfig: object
}

const SchemaHelmet = ({ jsonConfig = {} }: ISchemaHelmet) => {
    const configString = JSON.stringify(jsonConfig)

    return (
        <Helmet>
            <script type="application/ld+json">
                {configString}
            </script>
        </Helmet>
    )
}

export default SchemaHelmet

import { Filter, FilterType } from '@pwa-concept/modules/graphql'
import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { IFilterControls } from '@pwa-onilab/ui/components/modules/Filters/components/FilterControls/IFilterControls'

const useCalculateFiltersCount = () => {
    const { isBreakpointUp } = useMediaBreakpoint()

    return (currentValues: IFilterControls['currentValues'], filter: Filter) => {
        const count = currentValues?.filters?.[filter.key]?.length || 0
        if (filter.type === FilterType.Price) {
            return count && isBreakpointUp('lg') ? 1 : 0
        }

        return count
    }
}

export default useCalculateFiltersCount

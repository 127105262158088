import { MediaBreakpointUp, useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import HeaderTop from '@pwa-onilab/ui/components/modules/HeaderTop'
import { CART_ROUTE, CHECKOUT_ROUTE } from '@pwa-onilab/ui/constants'
import { HEADER_ID } from '@pwa-onilab/ui/constants/app'
import { useCategoryPageMenu } from '@pwa-onilab/ui/hooks'
import HeaderBottom from 'components/overrides/modules/HeaderBottom/HeaderBottom'
import { HeaderPromoNotification } from 'modules/Header'
import { useMatch } from 'react-router-dom'

import styles from './Header.module.scss'

// todo apollo state for header visibility
const Header = () => {
    const { data: menuData } = useCategoryPageMenu()

    const { isBreakpointDown } = useMediaBreakpoint()

    const isCheckout = useMatch(CHECKOUT_ROUTE)
    const isMobileCart = useMatch(CART_ROUTE) && isBreakpointDown('sm')

    if (isCheckout || isMobileCart) {
        return null
    }

    return (
        <header
            id={HEADER_ID}
            className={styles.header}
        >
            <HeaderTop />
            <HeaderBottom menuData={menuData} />
            <HeaderPromoNotification />
        </header>
    )
}

export default Header

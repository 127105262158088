import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import { useCartModal } from '@pwa-onilab/ui/hooks'
import { useTranslation } from 'react-i18next'

import styles from './ReorderSuccessToast.module.scss'

// TODO AccountPage.orders.reorder.success should contain itemQty
const ReorderSuccessToast = () => {
    const { t } = useTranslation()
    const { openModal } = useCartModal()

    return (
        <div className={styles.toast}>
            {t('AccountPage.orders.reorder.success')}
            <ButtonInstance onClick={openModal} className={styles.btn}>
                {t('global.buttons.view')}
            </ButtonInstance>
        </div>
    )
}

export default ReorderSuccessToast

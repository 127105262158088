import { ImageLazy } from '@pwa-onilab/ui/components/UI/Image/ImageLazy'

import styles from './PromoCodeImage.module.scss'

interface IPromoCodeImageProps {
    backgroundImage: string
}

const PromoCodeImage = (
    {
        backgroundImage,
    }: IPromoCodeImageProps,
) => {
    if (!backgroundImage) return null

    return (
        <ImageLazy
            src={backgroundImage}
            className={styles.image}
            loaderClassName={styles.imagePlaceholder}
        />
    )
}

export default PromoCodeImage

import { Component } from 'react'

interface SliderErrorBoundaryState {
    hasError: boolean
}

class SliderErrorBoundary extends Component<unknown, SliderErrorBoundaryState> {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    render() {
        const { hasError } = this.state
        const { children } = this.props

        if (hasError) {
            return null
        }

        return children
    }
}

export { SliderErrorBoundary }

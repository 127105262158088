import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import Icon from '@pwa-onilab/ui/components/UI/Icon'

import styles from './MobileMenuListItem.module.scss'

interface IMobileMenuListItemProps {
    nodeId: number
    title: string
}

const MobileMenuListItem = (
    {
        nodeId,
        title,
    }: IMobileMenuListItemProps,
) => (
    <li>
        <Anchor
            to={`${nodeId}`}
            className={styles.link}
        >
            {title}
            <Icon name="chevronBold" className={styles.iconArrow} />
        </Anchor>
    </li>
)

export default MobileMenuListItem

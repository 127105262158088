import { useEffect } from 'react'
import { addScrollableSelector, disablePageScroll, enablePageScroll } from 'scroll-lock'

addScrollableSelector('[data-rsbs-scroll]')

const useScrollLock = (isOpen) => {
    useEffect(() => {
        if (isOpen) {
            disablePageScroll()

            return () => {
                enablePageScroll()
            }
        }

        return () => {
        }
    }, [isOpen])

    return {
        scrollableProps: { 'data-scroll-lock-scrollable': true },
    }
}

export default useScrollLock

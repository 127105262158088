import { useCategoryPageMenu } from '@pwa-onilab/ui/hooks'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

const useMobileMenuSubPageNode = () => {
    const { stockData, loading } = useCategoryPageMenu()
    const params = useParams()

    const node = useMemo(() => {
        if (!stockData?.menu) return null

        const parts = params?.['*']?.split('/') || []
        const findIt = (nodeId, cursorCandidates) => (
            cursorCandidates?.nodes?.reduce?.((result, candidate) => {
                if (result) {
                    return result
                }

                if (String(candidate.nodeId) === String(nodeId)) {
                    return candidate
                }

                if (candidate?.nodes?.length > 0 || candidate?.type === 'wrapper') {
                    return findIt(nodeId, candidate)
                }

                return null
            }, null)
        )

        return parts.reduce((cursor, nodeId) => findIt(nodeId, cursor), stockData?.menu)
    }, [params, stockData?.menu])

    return {
        node,
        loading,
    }
}

export default useMobileMenuSubPageNode

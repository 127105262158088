import { SearchCategoriesProvider, SearchValueProvider } from '@pwa-onilab/ui/components/modules/Search'
import { ISearchProviders } from '@pwa-onilab/ui/components/modules/Search/providers/ISearchProviders'
import { PropsWithChildren } from 'react'

const SearchProviders = (
    {
        children,
        categories,
        searchValue,
    }: PropsWithChildren<ISearchProviders>) => {

    return (
        <SearchValueProvider searchValue={searchValue}>
            <SearchCategoriesProvider categories={categories}>
                {children}
            </SearchCategoriesProvider>
        </SearchValueProvider>
    )
}

export default SearchProviders

import { SALE_LABEL } from 'constants/modules/labelsProduct'

export function checkForSaleLabel(productVariants, labels, SALE_LABEL) {
    let labelsCheck = false
    let hasSaleLabel = false
    function checkLabels(array) {
        for (let item of array) {
            if (item?.product?.price?.discount) {
                if (item?.product?.price?.discount) {
                    return true
                }
            }
        }
        return false
    }

    if (productVariants) {
        labelsCheck = checkLabels(productVariants);

        if (labelsCheck && labels) {
            labels?.forEach(group => {
                if (group.label === "Sale") {
                    hasSaleLabel = true
                }
            });

            if (!hasSaleLabel) {
                labels?.unshift(SALE_LABEL)
            }
        }

        if (labels === null) {
            if (!hasSaleLabel) {
                labels = []
                labels.unshift(SALE_LABEL)
            }
        }
    }
}

import { TableRow } from '@pwa-onilab/ui/components/UI/TableRow'
import { useTranslation } from 'react-i18next'
import { TableCell } from '@pwa-onilab/ui/components/UI/TableCell'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import { AccountCardsQuery } from '../query/AccountCardsQuery'
import styles from 'modules/Account/subpages/AccountCards/AccountCards.module.scss'
import { useState, useEffect } from 'react'
import api from '@pwa-concept/core/api'
import { gql } from 'graphql-tag'
import { toast } from 'react-toastify'
import Modal from '@pwa-onilab/ui/components/elements/Modal'

const AccountCardsTableRow = () => {
    const { t } = useTranslation()
    const { customerSavedCards, loading, error } = AccountCardsQuery()
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [popupContent, setPopupContent] = useState('');
    const [isPopupVisibleDelete, setPopupVisibleDelete] = useState(false);
    const [popupContentDelete, setPopupContentDelete] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(null);


    // Initialize with all rows visible
    const [visibleRows, setVisibleRows] = useState([...customerSavedCards])

    useEffect(() => {
        if (!loading && customerSavedCards) {
            // Data is available, so set the visible rows
            setVisibleRows([...customerSavedCards]);
        }
    }, [loading, customerSavedCards]);


    const findFullName = (cc_type) => {
        const typeData = {
            AE: 'AmericanExpress',
            DI: 'Discover',
            JCB: 'JCB',
            MC: 'Mastercard',
            VI: 'Visa',
            MI: 'Mestro',
            DN: 'Diners Club'
        }
        if (typeData.hasOwnProperty(cc_type)) {
            return typeData[cc_type];
        } else {
            return '';
        }
    };

    const deleteCard = (index, id) => {
        return (async () => {
            try {
                const { data: { deleteCustomerSavedCardById: context = {} } = {} } = await (
                    api.graphql(
                        gql`
                            query {
                                deleteCustomerSavedCardById(card_id: ${id}) {
                                    result
                                    error_message
                                }
                            }
                        `,
                    ).query(undefined, {
                        errorPolicy: 'all',
                    })
                )

                if (context?.result) {
                    const updatedRows = visibleRows.filter((_, i) => i !== index)
                    setVisibleRows(updatedRows)
                }
            } catch (error) {
                toast.error(error.message)
            }
        })()
    };

    if (loading) {
        // You can return a loading indicator here
        return <></>
    }

    if (error) {
        // You can handle the error here
        return <div>Error: {error.message}</div>
    }

    return (
        <>
            {visibleRows.map((item, index) => (
                <TableRow key={index}>
                    <TableCell className={styles.cardsTableCell}>
                        <div className={styles.cardsTableCellLink}
                           onClick={() => {
                               setPopupContent(item);
                               setPopupVisible(true);
                           }}
                           >
                            XXXX-{JSON.parse(item?.additional)?.cc_last4}
                        </div>
                    </TableCell>
                    <TableCell className={styles.cardsTableCell}>
                        {JSON.parse(item?.additional)?.cc_exp_year}
                    </TableCell>
                    <TableCell className={styles.cardsTableCell}>
                        {JSON.parse(item?.additional)?.cc_exp_month}
                    </TableCell>
                    <TableCell className={styles.cardsTableCell}>
                        {item?.last_use}
                    </TableCell>
                    <TableCell className={styles.cardsTableCell}>
                        <Button
                            className={styles.deleteCards}
                            onClick={() => {
                                setSelectedIndex(index);
                                setPopupVisibleDelete(true);
                            }}
                        ></Button>
                    </TableCell>
                </TableRow>
            ))}
            {visibleRows.length ? null : <p className={styles.emptyText}>{t('You don\'t have any saved cards yet. Please make at least one purchase using Authorize.net payment method.')}</p>}
            {isPopupVisible && (
                <Modal
                    isDraggable
                    isOverAll
                    isOpen={isPopupVisible}
                    onClose={setPopupVisible}
                    className={styles.cardsTableCellModal}
                >
                    <div className={styles.cardsTableCellContent}>
                        <p><b>{t('Created')}Created:</b> <i>{popupContent?.created_at}</i></p>
                        <p><b>{t('Last 4 numbers:')}</b> <i>XXXX-{setPopupVisible}{JSON.parse(popupContent?.additional)?.cc_last4}</i></p>
                        <p><b>{t('Exp years:')}</b> <i>{JSON.parse(popupContent?.additional)?.cc_exp_month}</i></p>
                        <p><b>{t('Exp month:')}</b> <i>{JSON.parse(popupContent?.additional)?.cc_exp_year}</i></p>
                        <p><b>{t('Type')}:</b> <i>{findFullName(JSON.parse(popupContent?.additional)?.cc_type)}</i></p>
                        <p><b>{t('Lat Use:')}</b> <i>{popupContent?.last_use}</i></p>
                        <p><b>{t('Expires')}:</b> <i>{popupContent?.expires}</i></p>
                        <p><b>{t('Profile ID')}:</b> <i>{popupContent?.profile_id}</i></p>
                        <p><b>{t('Payment ID')}:</b> <i>{popupContent?.payment_id}</i></p>
                    </div>
                    <div className={styles.cardsTableWrapperDelete}>
                        <Button
                            isPrimary
                            isBold
                            onClick={() => {
                                setPopupVisible(false);
                            }}
                        >
                            {t('Close')}
                        </Button>
                    </div>
                </Modal>
            )}
            {isPopupVisibleDelete && (
                <Modal
                    isDraggable
                    isOverAll
                    isOpen={isPopupVisibleDelete}
                    onClose={setPopupVisibleDelete}
                    className={styles.cardsTableCellModal}
                >
                    <p>
                        {t('Are you sure, you want to delete this card?')}
                    </p>
                    <div className={styles.cardsTableWrapperDelete}>
                        <Button
                            isPrimary
                            isBold
                            onClick={() => {
                                if (selectedIndex !== null) {
                                    deleteCard(selectedIndex, visibleRows[selectedIndex].id);
                                }
                                setPopupVisibleDelete(false);
                            }}
                        >
                            {t('Yes')}
                        </Button>
                        <Button
                            isPrimary
                            isBold
                            onClick={() => {
                                setPopupVisibleDelete(false);
                            }}
                        >
                            {t('No')}
                        </Button>
                    </div>
                </Modal>
            )}
        </>
    )
}

export default AccountCardsTableRow

import {
    useCartSetShippingAddressMutation,
} from '@pwa-concept/modules/cart/graphql/mutations/CartSetShippingAddress'
import { SetShippingAddressOnCartInput } from '@pwa-concept/modules/graphql'
import { logger } from '@pwa-concept/modules/helpers/logger'

const useCartSetShippingAddresses = () => {
    const [setShippingAddress] = useCartSetShippingAddressMutation()

    return async (shippingAddressesInput: SetShippingAddressOnCartInput['shippingAddresses']) => {
        return await setShippingAddress({
            variables: {
                input: {
                    shippingAddresses: shippingAddressesInput,
                },
            },
            onError(e) {
                logger.error(e)
            },
        })
    }
}

export default useCartSetShippingAddresses

import { IButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance/index'
import { PropsWithChildren } from 'react'

const ButtonInstance = (
    {
        type = 'button',
        children,
        buttonRef,
        ...restProps
    }: PropsWithChildren<IButtonInstance>) => {
    return (
        <button
            {...restProps}
            ref={buttonRef}
            type={type}
        >
            {children}
        </button>
    )
}

export default ButtonInstance

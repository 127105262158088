import { PropsWithChildren } from 'react'

const DisablePageBuilderStyles = ({ children }: PropsWithChildren<any>) => {
    return (
        <div className="disablePageBuilder">
            {children}
        </div>
    )
}

export default DisablePageBuilderStyles

import { Resolvers } from '@pwa-concept/modules/graphql'

import LiveSearchResult from './LiveSearchResult'
import Mutation from './Mutation'
import ProductsInLiveSearch from './ProductsInLiveSearch'
import Query from './Query'

const resolvers: Resolvers = {
    Query,
    Mutation,
    LiveSearchResult,
    ProductsInLiveSearch,
}

export default resolvers

import { Input } from '@pwa-onilab/ui/components/UI/Input'
import Label from '@pwa-onilab/ui/components/UI/Label'
import { classNames } from '@pwa-onilab/ui/helpers'
import { ChangeEventHandler, forwardRef, PropsWithChildren } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form'

import styles from './CategoryCheckbox.module.scss'

const VARIANTS = {
    default: null,
    touch: styles.variantTouch,
    list: styles.variantList,
    filter: styles.variantFilter,
    color: styles.variantColor,
    error: styles.variantError,
}

type CategoryCheckboxOnChange = (state: boolean, name: string) => any

interface CategoryCheckboxProps {
    className?: string
    variant?: keyof typeof VARIANTS
    isChecked?: boolean
    onChange?: CategoryCheckboxOnChange | ChangeEventHandler
    name?: string
    value?: string
}

const CategoryCheckbox = forwardRef<any, PropsWithChildren<CategoryCheckboxProps> & Partial<UseFormRegisterReturn>>(
    (
        {
            className,
            children,
            variant = 'default',
            isChecked,
            onChange,
            name,
            value,
            onBlur,
        },
        ref,
    ) => {
        const onChangeHandler = () => {
            (onChange as CategoryCheckboxOnChange)?.(!isChecked, name)
        }

        return (
            <Label
                className={classNames(
                    styles.checkboxWrapper,
                    className,
                    isChecked && styles.isChecked,
                    (variant && VARIANTS?.[variant]) || VARIANTS.default,
                )}
                onClick={!ref ? onChangeHandler : undefined}
            >
                {ref && (
                    <Input
                        name={name}
                        className={styles.input}
                        defaultChecked={isChecked}
                        type="checkbox"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange as ChangeEventHandler}
                        ref={ref}
                    />
                )}
                <span className={styles.checkbox}>
                    <span className={styles.display} />
                    <span className={styles.title}>{children}</span>
                </span>
            </Label>
        )
    },
)

export default CategoryCheckbox

import { classNames } from '@pwa-onilab/ui/helpers'
import { IReactHookFormFields } from '@pwa-onilab/ui/interfaces'
import { InputHTMLAttributes, useCallback, useState } from 'react'

import styles from './Input.module.scss'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement>, Partial<IReactHookFormFields>{
    onChange?: (x) => void
    className?: string
    inputErrorClass?: string
}

// TODO now register function write correct, and setValues work without onChangeValue
//     as field in required, it seems like we always should have register we can erase setValue prop
//     but with setValue we can set setValueOptions, think how replace it
//     for example we can call setValue with options inside onChange prop

// TODO check posibility to set setValueOptions inside register
const Input = (
    {
        onChange,
        setValue,
        setValueOptions,
        type,
        className,
        field,
        register,
        rules,
        errors,
        inputErrorClass,
        ...restProps
    }: InputProps) => {
    const [compositionEnded, setCompositionEnded] = useState(true)

    const onChangeValue = useCallback((e) => {
        if (compositionEnded) {
            setValue?.(field, e.target.value, setValueOptions)
            onChange?.(e)
        }
    }, [onChange, compositionEnded])

    return (
        <>
            <input
                {...restProps}
                className={classNames(styles.input, className)}
                type={type}
                // onChange need for cases without register
                {...register?.(field, rules)}
                onChange={onChangeValue}
                onCompositionStart={() => {
                    setCompositionEnded(false)
                }}
                onCompositionEnd={() => {
                    setCompositionEnded(true)
                }}
            />

            {
                errors?.[field] && (
                    <p className={classNames(styles.error, inputErrorClass)}>
                        {errors[field]?.message}
                    </p>
                )
            }
        </>
    )
}

export default Input

import { makeVar, useReactiveVar } from '@apollo/client'

const isCartModalOpen = makeVar(false)

const useIsCartModalOpen = () => useReactiveVar<boolean>(isCartModalOpen)

const openModal = () => isCartModalOpen(true)
const closeModal = () => isCartModalOpen(false)

const useCartModal = () => {
    return {
        useIsCartModalOpen,
        openModal,
        closeModal,
    }
}

export default useCartModal

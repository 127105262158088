import MAGENTO_DEFAULT_IMAGE from '@pwa-onilab/ui/assets/images/magentoDefaultImage.jpg'
import { IImageProps } from '@pwa-onilab/ui/components/UI/Image/IImageProps'
import { DEFAULT_MAGENTO_IMAGE_ALT } from '@pwa-onilab/ui/constants/app'
import { useState } from 'react'
const Image = (
    {
        className,
        src = MAGENTO_DEFAULT_IMAGE,
        alt = src === MAGENTO_DEFAULT_IMAGE ? DEFAULT_MAGENTO_IMAGE_ALT : '',
        title,
        width,
        height,
        onClick,
        ...imageProps
    }: IImageProps,
) => {
    const [isError, setIsError] = useState<boolean>(false)
    const onError = () => {
        setIsError(true)
    }

    return (
        <img
            {...imageProps}
            src={isError ? MAGENTO_DEFAULT_IMAGE : src}
            className={className}
            alt={isError ? DEFAULT_MAGENTO_IMAGE_ALT : alt}
            width={width}
            height={height}
            title={title}
            onClick={onClick}
            onError={onError}
        />
    )
}

export default Image

import { MediaBreakpointDown } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { CenteredModal } from '@pwa-onilab/ui/components/elements/Modals'
import { useAccountOrderModal, useAccountOrderModalNumber } from '@pwa-onilab/ui/components/modules/AccountSubpages'
import {
    OrderBillingAddress,
    OrderEmail,
    OrderInformationTitle,
    OrderInfoRow, OrderPaymentMethod, OrderShippingAddress,
    OrderShippingMethod, OrderStatusInfo, OrderSummaryItems, OrderTotals, ReorderButton,
} from '@pwa-onilab/ui/components/modules/Order'
import { OrderComment } from 'modules/Order'

import styles from './AccountOrderModal.module.scss'

const AccountOrderModal = () => {
    const { closeModal } = useAccountOrderModal()
    const orderNumber = useAccountOrderModalNumber()

    return (
        <CenteredModal
            isOpen={!!orderNumber}
            onDismiss={closeModal}
            className={styles.modalContent}
            footer={(
                <MediaBreakpointDown name="lg">
                    <ReorderButton orderNumber={orderNumber} />
                </MediaBreakpointDown>
            )}
            footerClassName={styles.reorderButton}
        >
            <OrderStatusInfo orderNumber={orderNumber} />
            <OrderInformationTitle />
            <OrderInfoRow>
                <OrderEmail />
            </OrderInfoRow>
            <OrderInfoRow>
                <OrderShippingMethod orderNumber={orderNumber} className={styles.rowCell} />
                <OrderPaymentMethod orderNumber={orderNumber} className={styles.rowCell} />
            </OrderInfoRow>
            <OrderInfoRow>
                <OrderShippingAddress orderNumber={orderNumber} className={styles.rowCell} />
                <OrderBillingAddress orderNumber={orderNumber} className={styles.rowCell} />
            </OrderInfoRow>
            <OrderInfoRow>
                <OrderComment orderNumber={orderNumber} />
            </OrderInfoRow>
            <OrderSummaryItems orderNumber={orderNumber} />
            <OrderTotals orderNumber={orderNumber} />
        </CenteredModal>
    )
}

export default AccountOrderModal

import { $cart } from '@pwa-concept/core/models'
import { tryThrowCartError } from '@pwa-concept/modules/cart/graphql/stores/magento2/resolvers/helpers'
import { Resolvers } from '@pwa-concept/modules/graphql'

const Cart: Resolvers['Cart'] = {
    // TODO: We should use only uid for id, but deprecated id is required on Actility now
    id: (_, __, { context, errors }) => {
        if (!context?.id) {
            return null
        }
        if (errors) {
            tryThrowCartError(errors)
        }

        if ($cart.id !== context.id) {
            $cart.id = context.id
        }
        return context.id
    },
    totalQuantity: (_, __, { context }) => context?.totalQuantity || null,
    email: (_, __, { context }) => context?.email || null,
    totals: (_, __, { context: __context }) => ({
        __context,
        __typename: 'CartTotals',
    }),

    prices: (_, __, { context: __context }) => ({
        __context,
        __typename: 'CartPrices',
    }),

    items: (_, __, { context }) => {
        if (!context?.items?.length) {
            return null
        }

        return context.items.filter(Boolean).map((__context) => ({
            __context,
            __typename: 'CartItems',
        }))
    },

    shippingAddresses: (_, __, { context }) => {
        if (!context?.shippingAddresses?.length) {
            return null
        }

        return context.shippingAddresses.map((__context) => {
            return {
                __context,
                __typename: 'CartShippingAddresses',
            }
        })
    },

    billingAddress: (_, __, { context }) => {
        return context?.billingAddress ? {
            __context: context.billingAddress,
            __typename: 'CartBillingAddress',
        } : null
    },

    availablePaymentMethods: (_, __, { context }) => {
        if (!context?.availablePayments) {
            return null
        }
        return context.availablePayments.map((__context) => ({
            __context,
            __typename: 'CartAvailablePaymentMethods',
        }))
    },

    selectedPaymentMethod: (_, __, { context: { selectedPayment: __context } }) => {
        if (!__context?.code) {
            return null
        }

        return {
            __context,
            __typename: 'CartSelectedPaymentMethod',
        }
    },
    appliedCoupon: (_, __, { context }) => context?.applied_coupons?.[0]?.code || null,
}

export default Cart

import { useFilters } from '@pwa-concept/modules/filter'
import { FiltersQueryInput } from '@pwa-concept/modules/graphql'

const BESTSELLER_FILTERS_INPUT: FiltersQueryInput = {
    filters: {
        is_bestseller: ['1'],
    },
}

const useBestsellerPageFilters = () => {
    return useFilters(BESTSELLER_FILTERS_INPUT)
}

export default useBestsellerPageFilters

import { $auth } from '@pwa-concept/core/models'
import {
    CustomerOrderQueryVariables,
    useCustomerOrderQuery,
} from '@pwa-concept/modules/customer-orders/graphql/queries/CustomerOrder'
import { CustomerOrder } from '@pwa-concept/modules/graphql'

interface IUseCustomerOrderOutput {
    data: CustomerOrder
    loading: boolean
}

const useCustomerOrder = (id: CustomerOrderQueryVariables['id']): IUseCustomerOrderOutput => {
    const { data, loading } = useCustomerOrderQuery({
        skip: !$auth.getToken() || !id,
        variables: {
            id,
        },
    })

    return {
        data: data?.customerOrder || undefined,
        loading,
    }
}

export default useCustomerOrder

import {
    ADDRESS_FORM_FIELDS,
    AddressFormAddressLineField,
    AddressFormCityField,
    AddressFormCountryField,
    AddressFormPostalCodeField,
    AddressFormRegionField,
} from '@pwa-onilab/ui/components/modules/AddressForm'
import { useTranslation } from 'react-i18next'

import styles from './AddressSection.module.scss'

interface IAddressSection {
    className?: string
}

const AddressSection = ({ className }: IAddressSection) => {
    const { t } = useTranslation()

    return (
        <div className={className}>
            <AddressFormAddressLineField
                name={ADDRESS_FORM_FIELDS.STREET_ONE}
                placeholder={t('addressForm.addressLine1.text')}
                isRequired
            />
            <AddressFormAddressLineField
                name={ADDRESS_FORM_FIELDS.STREET_TWO}
                placeholder={t('addressForm.addressLine2.text')}
            />
            <div className={styles.twoFieldsRow}>
                <div className={styles.twoFieldsCol}>
                    <AddressFormCityField />
                </div>
                <div className={styles.twoFieldsCol}>
                    <AddressFormCountryField />
                </div>
            </div>
            <div className={styles.twoFieldsRow}>
                <div className={styles.twoFieldsCol}>
                    <AddressFormRegionField />
                </div>
                <div className={styles.twoFieldsCol}>
                    <AddressFormPostalCodeField />
                </div>
            </div>
        </div>
    )
}

export default AddressSection

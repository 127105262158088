import Container from '@pwa-onilab/ui/components/UI/Container'
import { GiftSectionItems } from '@pwa-onilab/ui/components/widgets/GiftSection'
import { filteringByObjects } from '@pwa-onilab/ui/helpers'
import { memo } from 'react'

interface IGiftSectionProps {
    data: {
        widget_type: string
        [index: number]: {
            description: string
            desktop_image: string
            link_title: string
            link_url: string
            mobile_image: string
            title: string
            text_color: string
        }
    }
}

const GiftSection = (data: IGiftSectionProps) => {
    const slides = filteringByObjects(data)

    if (!slides?.length) return null

    return (
        <Container>
            <GiftSectionItems slides={slides} />
        </Container>
    )
}

export default memo(GiftSection)

import { CustomerOrdersModule } from '@pwa-concept/modules/customer-orders'

import graphqlClientFragments from './graphql/fragments'
import graphqlSchemas from './graphql/schemas'
import stores from './graphql/stores'

const AppCustomerOrdersModule = CustomerOrdersModule.extend((module, { concat }) => {
    // TODO rm from client fragments also
    const moduleStoreFragments = module?.graphqlStoreFragments?.filter((fragmentDocument) => {
        return !fragmentDocument['ProductData']
    }) || []

    return {
        graphqlSchemas: concat(module?.graphqlSchemas, graphqlSchemas),
        graphqlResolvers: concat(module?.graphqlResolvers, stores?.resolvers),
        graphqlStoreFragments: concat(moduleStoreFragments, stores.fragments),
        graphqlFragments: concat(module?.graphqlFragments, graphqlClientFragments),
    }
})

export default AppCustomerOrdersModule

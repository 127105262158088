import { $auth } from '@pwa-concept/core/models'
import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { ProtectedRoute } from '@pwa-onilab/ui/components/elements/ProtectedRoute'
import AccountPage from '@pwa-onilab/ui/pages/AccountPage/AccountPage'
import { MobileMenuAccountPage } from '@pwa-onilab/ui/pages/MobileMenuPage'

const AccountAuthWrapper = () => {
    const { isBreakpointDown } = useMediaBreakpoint()

    const isToken = $auth.useIsToken()

    if (isBreakpointDown('lg') && !isToken) {
        return <MobileMenuAccountPage />
    }

    return <ProtectedRoute><AccountPage /></ProtectedRoute>
}

export default AccountAuthWrapper

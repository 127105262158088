import { InputSearch } from '@pwa-onilab/ui/components/elements/Inputs/InputSearch'
import { ChangeEventHandler } from 'react'

import styles from './FaqSearchInput.module.scss'

interface IFaqSearchInputProps {
    handleChange?: (value: string) => void | ChangeEventHandler
    searchValue: string
    clearInput?: () => void
    className: string
    isAutoFocus: boolean
}

const FaqSearchInput = (
    {
        handleChange,
        searchValue,
        clearInput,
        className,
        isAutoFocus,
    }: Partial<IFaqSearchInputProps>,
) => (
    <InputSearch
        onChange={handleChange}
        value={searchValue}
        onClear={clearInput}
        isClean
        className={className}
        searchIconClassName={styles.searchIcon}
        isAutoFocus={isAutoFocus}
    />
)

export default FaqSearchInput

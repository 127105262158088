import { useCustomer } from '@pwa-concept/modules/customer'
import { PersonalInfoForm } from '@pwa-onilab/ui/components/modules/Account'
import { AccountDetailsTitle } from '@pwa-onilab/ui/components/modules/Account/subpages/AccountDetails/AccountDetailsTitle'
import DeleteAccountLink from '@pwa-onilab/ui/components/modules/Account/subpages/AccountDetails/DeleteAccountLink'
import NewslettersToggler from '@pwa-onilab/ui/components/modules/Account/subpages/AccountDetails/NewslettersToggler'
import { AccountDetailsPlaceholder } from '@pwa-onilab/ui/components/modules/Account/subpages/AccountDetails/Placeholder'
import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'

import styles from './AccountDetails.module.scss'

const AccountDetails = () => {
    const { data, loading } = useCustomer()

    if (loading || !data) {
        return <AccountDetailsPlaceholder />
    }

    return (
        <div className={styles.wrapper}>
            <AccountDetailsTitle className={styles.title} />
            <PersonalInfoForm />
            <NewslettersToggler isCustomerSubscribed={data?.isSubscribed} />
            <MediaBreakpointUp name="lg">
                <DeleteAccountLink />
            </MediaBreakpointUp>
        </div>
    )
}

export default AccountDetails

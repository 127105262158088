import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { ISearchModalProps, SearchResult } from '@pwa-onilab/ui/components/modules/Search'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Container from '@pwa-onilab/ui/components/UI/Container'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import Logo from '@pwa-onilab/ui/components/UI/Logo'
import { useSearchModal } from '@pwa-onilab/ui/hooks'
import { InputSearch } from 'components/overrides/elements/Inputs/InputSearch/InputSearch'
import MobileSearchInput from 'components/overrides/modules/Search/elements/MobileSearchInput/MobileSearchInput'

import styles from './SearchModal.module.scss'

const SearchModal = (
    {
        searchResult,
        searchValue,
        isLoading,
        onInputChange,
        onClear,
        lastSearches,
        onClearLastSearches,
        categories,
    }: ISearchModalProps,
) => {
    const isDesktop = useMediaBreakpoint().isBreakpointUp('lg')
    const {
        useIsSearchModalOpen,
        closeSearchModal,
    } = useSearchModal()

    const isOpen = useIsSearchModalOpen()

    const header = (
        <MobileSearchInput
            onInputChange={onInputChange}
            onClear={onClear}
            onModalClose={closeSearchModal}
            searchValue={searchValue}
        />
    )

    return (
        <Modal
            isOverAll
            isOpen={isOpen}
            onDismiss={closeSearchModal}
            variant={isDesktop ? 'topFullWidth' : 'fullWidth'}
            header={header}
        >
            <Container className={styles.container}>
                {
                    isDesktop && (
                        <div className={styles.header}>
                            <Logo className={styles.logo} />
                            <InputSearch
                                onChange={onInputChange}
                                onClear={onClear}
                                isClean
                                isAutoFocus
                                variant="modal"
                                value={searchValue}
                                className={styles.input}
                                searchIconClassName={styles.searchIcon}
                            />
                            <ButtonInstance
                                onClick={closeSearchModal}
                                className={styles.close}
                            >
                                <Icon
                                    name="cross"
                                    className={styles.closeIcon}
                                />
                            </ButtonInstance>
                        </div>
                    )
                }
                <SearchResult
                    searchResult={searchResult}
                    searchValue={searchValue}
                    isLoading={isLoading}
                    lastSearches={lastSearches}
                    onClearLastSearches={onClearLastSearches}
                    categories={categories}
                />
            </Container>
        </Modal>
    )
}

export default SearchModal

import { $auth } from '@pwa-concept/core/models'
import { PropsWithChildren } from 'react'
import { Navigate } from 'react-router'

const ProtectedRoute = (
    {
        children,
    }: PropsWithChildren<any>,
) => {
    const isUserLogin = $auth.isToken

    if (!isUserLogin) {
        return <Navigate to="/" replace />
    }
    return children
}

export default ProtectedRoute

import { IFiltersFunctionsApi } from '@pwa-onilab/ui/components/modules/Filters/context'

const turnOnFilter = (onChangeValue: IFiltersFunctionsApi['onChangeValue'], filterId) => {
    onChangeValue((prev) => ({
        ...(prev || {}),
        filters: {
            ...(prev?.filters || {}),
            [filterId]: '1',
        },
    }))
}

export default turnOnFilter

import { useStoreConfigWholeSaleQuery } from 'app/modules/store-config/graphql/queries/StoreConfigWholeSale'

const useStoreConfigWholeSale = () => {
    const { data: { storeConfigWholeSale: data } = {}, loading } = useStoreConfigWholeSaleQuery()

    return {
        data,
        loading,
    }
}

export default useStoreConfigWholeSale

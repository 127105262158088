import { useStoreConfig } from '@pwa-concept/modules/store-config'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { HelpBlock } from 'modules/Help'
import { useTranslation } from 'react-i18next'

import styles from './HelpContactUs.module.scss'

const HelpContactUs = (
    {
        className,
    }: IClassName,
) => {
    const { t } = useTranslation()
    const { data: { emailContactUs } = {}, loading } = useStoreConfig()

    if (!emailContactUs && !loading) {
        return null
    }

    return (
        <Anchor
            target="_self"
            to={`mailto:${emailContactUs}`}
            isForceAbsoluteUrl
            className={classNames(
                loading && styles.disabled,
                className,
            )}
        >
            <HelpBlock
                titleType="h6"
                titleText={t('contactUs.title')}
                text={emailContactUs}
                iconName="mail"
                isLoading={loading}
            />
        </Anchor>
    )
}

export default HelpContactUs

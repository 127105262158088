import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'
import Container from '@pwa-onilab/ui/components/UI/Container'
import Breadcrumbs from 'modules/LocationPage/components/Breadcrumbs/Breadcrumbs'

import styles from './LocationPagePlaceholder.module.scss'

const LocationPagePlaceholder = () => {
    return (
        <Container className={styles.wrapper}>
            <Breadcrumbs />
            <Placeholder className={styles.parsedContentPlaceholder} />
        </Container>
    )
}

export default LocationPagePlaceholder

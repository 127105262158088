import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import Label from '@pwa-onilab/ui/components/UI/Label'
import { InputErrorWrapper } from '@pwa-onilab/ui/components/UI/Wrappers/InputErrorWrapper'
import { MULTIPLE_EMAILS_SEPARATOR, REGEX_EMAIL } from '@pwa-onilab/ui/constants/patterns'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import css from './InputMultipleEmail.module.scss'

export interface InputMultipleEmailProps {
    className?: string
    disabled?: boolean
    label?: string
    onChange?: ((values: string[]) => void)
    currentInputValue?: string[]
}

const InputMultipleEmail = (
    {
        className,
        label,
        currentInputValue,
        onChange,
        disabled,
    }: InputMultipleEmailProps,
) => {
    const { t } = useTranslation()

    const [editableEmail, setEditableEmail] = useState(null)
    // TODO seems like we dont need replace operation
    const id = useMemo(() => (
        `email-input-${Math.random()}`.replace(/[^-0-9a-z]/gim, '')
    ), [])
    const inputRef = useRef<HTMLInputElement>(null)
    const [input, setInput] = useState<string>('')

    const hasInvalidEmail = currentInputValue?.some((email) => !email?.match(REGEX_EMAIL))

    const isActive = (currentInputValue?.length > 0 || input?.length > 0)

    const saveEmails = (values: string[]) => (
        onChange([...new Set(values)].filter((email) => email.trim().length > 0))
    )

    const onBlur = (ev) => {
        setInput(ev?.currentTarget?.value)
    }

    const onRemove = useCallback((email: string) => (e) => {
        e.preventDefault()
        e.stopPropagation()

        saveEmails(currentInputValue?.filter((item) => item !== email))
    }, [])

    const onEdit = useCallback((email: string) => () => {
        setInput('')
        setEditableEmail(email)

        if ('value' in inputRef.current) inputRef.current.value = email
        inputRef.current?.focus?.()
    }, [])

    const onKeyDown = useCallback((ev) => {
        if (ev.key === 'Enter') onBlur(ev)
    }, [])

    const onClickLabel = useCallback(() => inputRef.current?.focus?.(), [])

    useEffect(() => {
        if (input?.length > 0 && input?.includes?.('@')) {
            const currentValue = (currentInputValue || [])
            const emails = (
                input
                    .split(MULTIPLE_EMAILS_SEPARATOR)
                    .map((email) => email.trim())
            )

            if (emails.length > 0) {
                if (editableEmail) {
                    saveEmails(currentValue.map((email) => (email === editableEmail ? emails[0] : email)))
                    setEditableEmail(null)
                } else {
                    saveEmails([...currentValue, ...emails])
                }
            }

            setInput('')
            if ('value' in inputRef.current) inputRef.current.value = ''
        }
    }, [input, currentInputValue])

    return (
        <InputErrorWrapper value={hasInvalidEmail && t('wishlist.shareViaEmail.incorrect')}>
            <div
                className={classNames(
                    css.container,
                    disabled && css.disabled,
                    isActive && css.isValue,
                    className,
                )}
            >
                <Label
                    htmlFor={id}
                    className={css.focus}
                    onClick={onClickLabel}
                />
                {currentInputValue?.map?.((email) => (
                    <div
                        key={email}
                        onClick={onEdit(email)}
                        className={classNames(
                            css.bubble,
                            !email.match(REGEX_EMAIL) && css.bubbleError,
                            editableEmail === email && css.bubbleEdit,
                        )}
                    >
                        <span>{email}</span>
                        <ButtonInstance onClick={onRemove(email)} className={css.bubbleRemove}>
                            <Icon name="close" className={css.bubbleIcon} />
                        </ButtonInstance>
                    </div>
                ))}
                <input
                    id={id}
                    disabled={disabled}
                    className={css.input}
                    type="email"
                    minLength={1}
                    required
                    onKeyDown={onKeyDown}
                    defaultValue={editableEmail || input}
                    onSubmit={onBlur}
                    onBlur={onBlur}
                    ref={inputRef}
                    autoComplete="off"
                />
                {label?.length > 0 && (
                    <div className={css.label}>
                        {label}
                    </div>
                )}
            </div>
        </InputErrorWrapper>
    )
}

export default InputMultipleEmail

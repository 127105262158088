import { VisibleOnScreen } from '@pwa-onilab/ui/components/elements/VisibleOnScreen'
import { IVisibleOnScreen } from '@pwa-onilab/ui/components/elements/VisibleOnScreen/IVisibleOnScreen'
import { getBlogStatePageAnchorHash } from 'elements/BlogStatePages/helplers/getBlogStatePageAnchorHash'
import { RefObject, useEffect } from 'react'

interface IPageVisibleOnScreen extends Omit<IVisibleOnScreen, 'data' | 'refProp'>{
    children: string
    refProp: RefObject<HTMLElement>
    as: string
}

const PAGE_VISIBLE_ON_SCREEN_THRESHOLD = 0.8

const PageVisibleOnScreen = (
    {
        children,
        refProp,
        threshold,
        componentProps,
        as,
        ...visibleOnScreenProps
    }: IPageVisibleOnScreen,
) => {
    const linkHash = getBlogStatePageAnchorHash(children)

    useEffect(() => {
        const locationHash = window.location.hash?.replace('#', '')
        if (locationHash && locationHash === linkHash) {
            refProp.current?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [])

    return (
        <VisibleOnScreen
            as={as}
            threshold={PAGE_VISIBLE_ON_SCREEN_THRESHOLD}
            {...visibleOnScreenProps}
            componentProps={{
                id: linkHash,
                ...componentProps,
            }}
            data={children}
            refProp={refProp}
        >
            {children}
        </VisibleOnScreen>
    )
}

export default PageVisibleOnScreen

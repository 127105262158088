import { Product } from '@pwa-concept/modules/graphql'
import { CategoryProductCartImage } from '@pwa-onilab/ui/components/modules/Product'
import { ProductBadges } from '@pwa-onilab/ui/components/modules/Product/elements'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import CategoryProductCardPrice
    from 'components/overrides/modules/Product/CategoryProductCard/CategoryProductCardPrice/CategoryProductCardPrice'
import WishlistButton from 'components/overrides/modules/Product/elements/WishlistButton/WishlistButton'
import { useCustomProductUrl } from 'hooks'
import { CategoryProductCardAttributes, ProductRatingBlock } from 'modules/Product'
import { useTranslation } from 'react-i18next'
import { SALE_LABEL } from 'constants/modules/labelsProduct'
import { checkForSaleLabel } from 'components/modules/Product/utils/saleLabelUtils'

import styles from './CategoryProductCardContent.module.scss'

interface ICategoryProductCardContentProps {
    product?: Product
}

const CategoryProductCardContent = (
    {
        product,
    }: ICategoryProductCardContentProps,
) => {
    const { t } = useTranslation()
    const {
        sku,
        url,
        name,
        ratingSummary,
        actualPricePerSeed,
        originalPricePerSeed,
        thcText,
        labels,
        textAttributes: {
            sipValueText,
            farvalue,
        } = {},
        thumbnail: { url: imgSrc, description } = {},
    } = product
    const labelsNew = []
    const productCardAttributesData = [
        farvalue,
        thcText,
        sipValueText,
    ]
    const {
        productLinkState,
        relativeUrl,
    } = useCustomProductUrl(url, sku)

    if (labels === null) {
        checkForSaleLabel(product?.variants, labelsNew, SALE_LABEL)
    } else {
        checkForSaleLabel(product?.variants, labels, SALE_LABEL)
    }

    return (
        <>
            <WishlistButton
                className={styles.wishlist}
                isCategoryCard
                sku={sku}
            />
            <div className={styles.badges}>
                <ProductBadges
                    data={labels || labelsNew}
                    isRowDirection
                />
            </div>
            <Anchor
                to={relativeUrl}
                state={productLinkState}
                aria-label={t('productPage.title')}
            >
                <div className={styles.imageWrapper}>
                    <CategoryProductCartImage src={imgSrc} alt={description} />
                    <ProductRatingBlock ratingSummary={ratingSummary} />
                </div>
                {
                    name && (
                        <p className={styles.title}>
                            {name}
                        </p>
                    )
                }
            </Anchor>
            <CategoryProductCardAttributes
                className={styles.attributes}
                data={productCardAttributesData}
            />
            <CategoryProductCardPrice
                actualPricePerSeed={actualPricePerSeed}
                originalPricePerSeed={originalPricePerSeed}
                withFromLabel
            />
        </>
    )
}

export default CategoryProductCardContent

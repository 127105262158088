import { DefaultHelmet, IDefaultHelmet } from '@pwa-onilab/ui/components/elements/DefaultHelmet'
import { SchemaHelmetCollectionPage } from 'elements/SchemaHelmet/SchemaHelmetCollectionPage'
import { SchemaHelmetWebPage } from 'elements/SchemaHelmet/SchemaHelmetWebPage'
import { SchemaHelmetBestsellersCollection } from 'modules/BestsellersPage'

const BestsellerPageHelmet = ({ meta }: { meta: IDefaultHelmet['meta']}) => {
    return (
        <>
            <DefaultHelmet meta={meta} defaultTitle="" />
            <SchemaHelmetWebPage meta={meta} />
            <SchemaHelmetCollectionPage meta={meta} />
            <SchemaHelmetBestsellersCollection title={meta.title} />
        </>
    )
}

export default BestsellerPageHelmet

import { ProductResolvers } from '@pwa-concept/modules/graphql'
import {
    PRODUCT_MODULE_BESTSELLER_LABEL,
    PRODUCT_MODULE_DISCOUNT_LABEL,
    PRODUCT_MODULE_MIX_PACK_LABEL,
} from 'app/modules/product'
import { SYMBOL_SLASH } from 'constants/common'

const Product: ProductResolvers = {
    shortDescription: (_, __, { context }) => {
        return context?.short_description?.html || null
    },

    reviewCount: (_, __, { context }) => context?.review_count || null,

    ratingSummary: (_, __, { context }) => {
        return context?.rating_summary || null
    },

    thcText: (_, __, { context }) => context?.thc_text || null,
    sipValueText: (_, __, { context }) => context?.sipvalue_text || null,
    farvalue: (_, __, { context }) => context?.farvalue_text || null,

    actualPricePerSeed: (_, __, { context }) => context?.actual_price_per_seed || null,
    originalPricePerSeed: (_, __, { context }) => context?.original_price_per_seed || null,

    faqQuestions: (_, __, { context }) => {
        if (!context?.faq_questions?.length) {
            return null
        }

        return context.faq_questions.map((__context) => {
            return {
                answer: __context.answer || null,
                question: __context.question || null,
                __typename: 'Question',
            }
        })
    },

    labels: (_, __, { context }) => {
        if (!context) {
            return null
        }

        const labels = []

        const discount = context.price_range?.minimum_price?.discount?.percent_off || 0

        if (discount) {
            labels.push({
                label: PRODUCT_MODULE_DISCOUNT_LABEL,
                backgroundColor: '#E51F05',
                color: '#FFF',
                __typename: 'ProductLabel',
            })
        }

        if (context.is_bestseller) {
            labels.push({
                label: PRODUCT_MODULE_BESTSELLER_LABEL,
                backgroundColor: '#39B54A',
                color: '#FFF',
                __typename: 'ProductLabel',
            })
        }

        if (context.is_mix_pack) {
            labels.push({
                label: PRODUCT_MODULE_MIX_PACK_LABEL,
                backgroundColor: 'linear-gradient(90deg, #E51F05 0%, #8600E5 100%)',
                color: '#FFF',
                __typename: 'ProductLabel',
            })
        }

        if (labels.length) {
            return labels
        }

        return null
    },

    url: (_, __, { context }) => {
        if (!context) {
            return null
        }

        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return context.url_prefix + SYMBOL_SLASH + context.url_key + SYMBOL_SLASH
    },

    attributeInTable: (_, __, { context }) => {
        if (!context?.attribute_in_table?.length) {
            return null
        }

        return context.attribute_in_table
    },

    metaRobots: (_, __, { context }) => {
        return context?.meta_robots || ''
    },

    textAttributes: (_, __, { context }) => {
        return context?.text_attributes || {}
    },
}

export default Product

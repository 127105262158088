import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { DefaultHelmet, IDefaultHelmet } from '@pwa-onilab/ui/components/elements/DefaultHelmet'
import { useStoreConfigWorldPage } from 'app/modules/store-config'
import { SchemaHelmetCollectionPage } from 'elements/SchemaHelmet/SchemaHelmetCollectionPage'
import { SchemaHelmetWebPage } from 'elements/SchemaHelmet/SchemaHelmetWebPage'
import { useMemo } from 'react'

const WorldPageHelmet = () => {
    const { data: storeConfig = {}, loading: storeConfigLoading } = useStoreConfig()
    const { data: worldPageConfig, loading: configLoading } = useStoreConfigWorldPage()

    const extendedMeta: IDefaultHelmet['meta'] = useMemo(() => {
        if (!worldPageConfig?.meta) {
            return {}
        }

        return {
            ...worldPageConfig.meta,
            ogType: 'article',
            customProperties: [
                {
                    name: 'article:publisher',
                    value: storeConfig.ogArticlePublisher,
                },
            ],
        }
    }, [storeConfig, worldPageConfig])

    return (
        <>
            <DefaultHelmet
                meta={extendedMeta}
                defaultTitle={worldPageConfig?.title}
                isMetaLoading={storeConfigLoading || configLoading}
            />
            <SchemaHelmetWebPage meta={extendedMeta} />
            <SchemaHelmetCollectionPage meta={extendedMeta} />
        </>
    )
}

export default WorldPageHelmet

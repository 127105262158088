import { IAbstractAddress, useFindCountry } from '@pwa-onilab/ui/hooks'
import { useTranslation } from 'react-i18next'

const useAddressLine = ({ address }: {address: IAbstractAddress}) => {
    const {
        street,
        city,
        regionName: region,
        countryId,
        telephone,
        postcode,
    } = address
    const { t } = useTranslation()

    const findCountry = useFindCountry()

    const streetLine = street?.join(' ') || ''
    const cityAndPostcode = [city, postcode].filter((el) => !!el).join(' ')
    const countryName = findCountry(countryId)?.fullNameLocale
    const regionText = region ? t('address.region', { region }) : null

    const addressLine = [streetLine, cityAndPostcode, regionText, countryName, telephone].filter(Boolean).join(', ')

    return addressLine
}

export default useAddressLine

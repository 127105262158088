import { PropsWithChildren } from 'react'

import styles from './AuthorPageRightColumn.module.scss'

const AuthorPageRightColumn = ({ children }: PropsWithChildren<any>) => {
    return (
        <div className={styles.rightColumn}>
            {children}
        </div>
    )
}

export default AuthorPageRightColumn

import NavigationBackButton from '@pwa-onilab/ui/components/elements/Buttons/NavigationBackButton'
import { CartHeaderProps } from '@pwa-onilab/ui/components/modules/Cart/elements/CartHeader/CartHeader'
import { useTranslation } from 'react-i18next'

import styles from './CartMobileDefaultHeader.module.scss'

const CartMobileDefaultHeader = ({ itemsCount }: CartHeaderProps) => {
    const { t } = useTranslation()

    return (
        <div className={styles.wrapper}>
            <NavigationBackButton />
            <div>
                <p className={styles.title}>{t('cart.title')}</p>
                <p className={styles.itemsCount}>{t('cart.items.count', { count: itemsCount })}</p>
            </div>
        </div>
    )
}
export default CartMobileDefaultHeader

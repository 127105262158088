import { useQuery } from '@pwa-concept/core/graphql/hooks'
import { HeaderTopMenuQuery } from '@pwa-onilab/ui/schemas'

const useHeaderTopMenu = () => {
    const { data: { menu = [] } = {}, loading: isMenuLoading } = useQuery(HeaderTopMenuQuery)

    return {
        menuData: menu?.nodes || [],
        isMenuLoading,
    }
}

export default useHeaderTopMenu

import { AddressDescriptionLine } from '@pwa-onilab/ui/components/elements/AddressDescriptionLine'
import { OrderFieldPlaceholder } from '@pwa-onilab/ui/components/modules/Order'
import OrderFieldTitle from '@pwa-onilab/ui/components/modules/Order/elements/OrderFieldTitle/OrderFieldTitle'
import { tAnyAddress } from '@pwa-onilab/ui/hooks'

interface IOrderAddress {
    address: tAnyAddress
    className?: string
    title: string
    loading?: boolean
}

const OrderAddress = ({ address, className, title, loading }: IOrderAddress) => {
    return (
        <div className={className}>
            <OrderFieldTitle text={title} />
            {
                loading
                    ? <OrderFieldPlaceholder />
                    : <AddressDescriptionLine address={address} />
            }
        </div>
    )
}

export default OrderAddress

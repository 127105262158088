import {
    AccountAddressAddressLine,
    AccountAddressButtons,
    AccountAddressCustomerName,
    AccountAddressType,
    IAccountAddress,
} from '@pwa-onilab/ui/components/modules/AccountAddress'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './AccountAddress.module.scss'

const AccountAddress = (
    {
        address,
        className,
        openDeleteAddressModal,
        openUpdateAddressModal,
    }: IAccountAddress) => (
        <li className={classNames(
            styles.wrapper,
            (address.defaultShipping || address.defaultBilling) && styles.defaultAddressWrapper,
            className
        )}
        >
            <AccountAddressType defaultShipping={address.defaultShipping} defaultBilling={address.defaultBilling} />
            <AccountAddressCustomerName
                firstName={address.firstName}
                middleName={address.middleName}
                lastName={address.lastName}
            />
            <AccountAddressAddressLine address={address} />
            <AccountAddressButtons
                id={address.id}
                openDeleteAddressModal={openDeleteAddressModal}
                openUpdateAddressModal={openUpdateAddressModal}
            />
        </li>
)

export default AccountAddress

import { AccountNavigation } from '@pwa-onilab/ui/components/modules/Account'
import { useOutlet } from 'react-router-dom'

const AccountPageMobile = () => {
    const outlet = useOutlet()

    return outlet || <AccountNavigation />
}

export default AccountPageMobile

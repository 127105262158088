import { PricePartProps } from '@pwa-onilab/ui/components/modules/Product/elements/ProductPrice/ProductPrice'
import Price from '@pwa-onilab/ui/components/UI/Price'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './InitialPrice.module.scss'

interface InitialPriceProps extends PricePartProps {
    isPriceWithDiscount: boolean
}

const InitialPrice = (
    {
        value,
        isPriceWithDiscount,
        isSearch,
        isProductPage,
        isSuccesAddToCartModal,
        isCart,
        className,
    }: InitialPriceProps) => {
    const wrapperClasses = [styles.wrapper]

    if (isPriceWithDiscount) wrapperClasses.push(styles.isPriceWithDiscount)
    if (isSearch) wrapperClasses.push(styles.search)
    if (isProductPage) wrapperClasses.push(styles.productPage)
    if (isSuccesAddToCartModal) wrapperClasses.push(styles.succesAddToCartModal)
    if (isCart) wrapperClasses.push(styles.cart)
    if (className) wrapperClasses.push(className)

    return (
        <div className={classNames(...wrapperClasses)}>
            <Price>
                {value}
            </Price>
        </div>
    )
}

export default InitialPrice

import { IInputRadio } from '@pwa-onilab/ui/components/elements/Inputs'
import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import styles from './InputRadio.module.scss'

const InputRadio = (
    {
        children,
        id,
        value,
        register = () => {},
        field = '',
        checked,
        className,
        checkboxClassName,
        inputClassName,
        onChange,
        rules,
        isHideRadioCircle,
        ...restProps
    }: PropsWithChildren<IInputRadio>,
) => (
    <label htmlFor={id} className={classNames(styles.wrapper, className)}>
        <input
            {...restProps}
            type="radio"
            id={id}
            value={value}
            checked={checked}
            onChange={onChange} // for cases without register
            {...register(field, {
                ...rules,
                onChange,
            })}
            className={inputClassName}
        />
        {!isHideRadioCircle && <div className={classNames(styles.checkboxButton, checkboxClassName)} />}
        {children}
    </label>
)

export default InputRadio

import { StoreFragments } from '@pwa-concept/core'

import CustomerOrder from './CustomerOrder.graphql'
import ProductData from './ProductData.graphql'

const fragments: StoreFragments = [
    CustomerOrder,
    ProductData,
]

export default fragments

import { InputSearch } from '@pwa-onilab/ui/components/elements/Inputs/InputSearch'
import { MediaBreakpointDown } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { ModalHeaderBackArrow } from '@pwa-onilab/ui/components/elements/Modal/elements/ModalHeaderBackArrow'
import { MouseEvent } from 'react'

import styles from './MobileSearchInput.module.scss'

interface IMobileSearchInputProps {
    onInputChange?: (event: MouseEvent<HTMLElement>) => void
    onClear?: () => void
    onModalClose?: () => void
    searchValue?: string
}

const MobileSearchInput = (
    {
        onInputChange = () => {},
        onClear,
        onModalClose = () => {},
        searchValue = '',
    }: IMobileSearchInputProps,
) => (
    <MediaBreakpointDown name="lg">
        <ModalHeaderBackArrow onDismiss={onModalClose}>
            <InputSearch
                onChange={onInputChange}
                onClear={onClear}
                isClean
                isAutoFocus
                variant="modal"
                value={searchValue}
                searchIconClassName={styles.searchIcon}
            />
        </ModalHeaderBackArrow>
    </MediaBreakpointDown>
)

export default MobileSearchInput

import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './OrderFieldPlaceholder.module.scss'

interface IOrderFieldPlaceholder {
    className?: string
}

const OrderFieldPlaceholder = ({ className }: IOrderFieldPlaceholder) => {
    return <div className={classNames(styles.placeholder, className)} />
}

export default OrderFieldPlaceholder

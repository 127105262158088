import { Resolvers } from '@pwa-concept/modules/graphql'

const CustomerOrderProduct: Resolvers['CustomerOrderProduct'] = {
    id: (_, __, { context }) => context?.id,
    url: (_, __, { context }) => context?.product_url_key,
    name: (_, __, { context }) => context?.product_name,
    discounts: (_, __, { context }) => context?.discounts ? context?.discounts?.map?.((__context) => ({
        __context,
        __typename: 'CustomerOrderDiscount',
    })) : null,
    status: (_, __, { context }) => context?.status,
    options: (_, __, { context }) => context?.selected_options ? context?.selected_options?.map?.((__context) => ({
        __context,
        __typename: 'CustomerOrderProductOption',
    })) : null,
    sku: (_, __, { context }) => context?.product_sku,
    price: (_, __, { context }) => {
        return context?.product_sale_price || null
    },
    quantityOrdered: (_, __, { context }) => context?.quantity_ordered,

    productData: (_, __, { context }) => {
        if (!context?.productData) {
            return null
        }
        return {
            brand: context.productData.brand || null,
            image: context.productData.image || null,
            prices: context.productData.prices ? {
                __context: context.productData.prices,
                __typename: 'CustomerOrderProductPrice',
            } : null,
            __typename: 'CustomerOrderProductOption',
        }
    },
}

export default CustomerOrderProduct

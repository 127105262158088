import { useRequestResetOtp } from '@pwa-concept/modules/one-time-password'
import useVerifyResetOTP from '@pwa-concept/modules/one-time-password/features/reset-otp/hooks/useVerifyAuthOTP'
import {
    VerifyOtpResetMutationVariables,
} from '@pwa-concept/modules/one-time-password/graphql/mutations/VerifyOTPReset'
import { OTP_DEFAULT_ATTEMPT_VALUE } from '@pwa-onilab/ui/constants'
import { IOneTimePassword, useCustomerPersonalInfo } from '@pwa-onilab/ui/hooks'
import { useTimer } from '@pwa-onilab/ui/hooks/oneTimePassword'
import { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

interface IUseChangePasswordOTP extends IOneTimePassword {
    customerEmail: string
    isRequestOpenOtpLoading: boolean
}

// TODO add global state, for cases when we change page
const useChangePasswordOTP = (): IUseChangePasswordOTP => {
    const [attemptsLeft, setAttemptsLeft] = useState<number>(OTP_DEFAULT_ATTEMPT_VALUE)
    const attemptsLeftRef = useRef<number>(null)

    const [isResendCode, setIsResendCode] = useState<boolean>(false)
    const [isRequestOpenOtpLoading, setIsRequestOpenOtpLoading] = useState<boolean>(false)

    const { email: customerEmail } = useCustomerPersonalInfo()
    const { startTimer, stopTimer, timerValue, timerValueRef } = useTimer()

    const requestOneTimePasswordResetMutation = useRequestResetOtp()
    const verifyResetOtp = useVerifyResetOTP()

    const requestOneTimePasswordReset = useCallback(async (isForceResend: boolean) => {
        if (timerValueRef.current || (!isForceResend && attemptsLeftRef.current !== null)) {
            return null
        }
        setIsRequestOpenOtpLoading(true)

        const successRequestHandler = (response) => {
            setAttemptsLeft(OTP_DEFAULT_ATTEMPT_VALUE)
            attemptsLeftRef.current = OTP_DEFAULT_ATTEMPT_VALUE

            setIsRequestOpenOtpLoading(false)
            if (isForceResend) {
                startTimer()
                setIsResendCode(true)
            }
            return response
        }

        const requestErrorHandler = (error) => {
            setIsRequestOpenOtpLoading(false)
            toast.error(error.message)
        }

        return await requestOneTimePasswordResetMutation(customerEmail)
            .then(successRequestHandler)
            .catch(requestErrorHandler)
    }, [])

    const handleOpenOneTimePassword = useCallback(() => {
        requestOneTimePasswordReset(false)
    }, [])

    const resendCode = useCallback(async () => {
        if (timerValueRef.current) {
            return
        }
        requestOneTimePasswordReset(true)
    }, [])

    const checkOneTimePassword = useCallback(async ({ code }) => {
        const verificationSuccessHandler = ({ data }) => {
            const { attemptsLeft: newAttemptsLeft, valid } = data
            if (valid) {
                setAttemptsLeft(OTP_DEFAULT_ATTEMPT_VALUE)
                attemptsLeftRef.current = null

                stopTimer()
                setIsResendCode(false)

                return data
            }

            setAttemptsLeft(newAttemptsLeft)
            attemptsLeftRef.current = newAttemptsLeft

            if (!newAttemptsLeft && !timerValueRef.current) {
                resendCode()
            }

            return data
        }

        const verificationErrorHandler = (error) => {
            toast.error(error.message)
        }

        const input: VerifyOtpResetMutationVariables = {
            email: customerEmail,
            code,
        }

        return await verifyResetOtp(input)
            .then(verificationSuccessHandler)
            .catch(verificationErrorHandler)
    }, [customerEmail])

    useEffect(() => {
        setIsResendCode(false)
    }, [customerEmail])

    return {
        handleOpenOneTimePassword,
        checkOneTimePassword,
        resendCode,
        timerValue,
        attemptsLeft,
        isResendCode,
        customerEmail,
        isRequestOpenOtpLoading,
    }
}

export default useChangePasswordOTP

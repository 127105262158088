import { MutationResolvers } from '@pwa-concept/modules/graphql'

import requestOTPAuth from './requestOTPAuth'
import requestOTPReset from './requestOTPReset'
import verifyOTPAuth from './verifyOTPAuth'
import verifyOTPReset from './verifyOTPReset'

const Mutation: MutationResolvers = {
    requestOTPAuth,
    requestOTPReset,
    verifyOTPAuth,
    verifyOTPReset,
}

export default Mutation

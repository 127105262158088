import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { Skeleton } from '@pwa-onilab/ui/components/elements/Skeleton'
import Container from '@pwa-onilab/ui/components/UI/Container'

import styles from './FaqPage.module.scss'

const FaqPageSkeleton = () => (
    <Container>
        <MediaBreakpointUp name="lg">
            <Skeleton className={styles.breadcrumbsPlaceholder} />
        </MediaBreakpointUp>
        <div className={styles.containerPlaceholder}>
            <div className={styles.wrapperPlaceholder}>
                <MediaBreakpointUp name="lg">
                    <Skeleton className={styles.sidebarPlaceholder} />
                    <Skeleton className={styles.contentPlaceholder} />
                </MediaBreakpointUp>
            </div>
            <Skeleton className={styles.contactUsPlaceholder} />
        </div>
    </Container>
)

export default FaqPageSkeleton

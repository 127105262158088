import { useCms } from '@pwa-concept/modules/cms'
import { MediaBreakpointDown, MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Modal from '@pwa-onilab/ui/components/elements/Modal'
import CmsPageHelmet from '@pwa-onilab/ui/components/modules/CmsPage/elements/CmsPageHelmet'
import { FaqPageBreadcrumbs } from '@pwa-onilab/ui/components/modules/FaqPage'
import { Help } from '@pwa-onilab/ui/components/modules/Help'
import Container from '@pwa-onilab/ui/components/UI/Container'
import Parser from '@pwa-onilab/ui/helpers'
import Error404Page from '@pwa-onilab/ui/pages/Error404Page'
import { FaqPageSkeleton } from '@pwa-onilab/ui/pages/FaqPage'
import { useParams } from 'react-router-dom'

import styles from './FaqPage.module.scss'

const FaqPage = () => {
    const { id = null } = useParams()

    const {
        data,
        loading,
    } = useCms({ id })

    if (!data && loading) {
        return (
            <FaqPageSkeleton />
        )
    }

    if (!loading && !data) {
        return (
            <Error404Page />
        )
    }

    const content = (
        <Container className={styles.container}>
            {
                data?.content && (
                    <Parser content={data?.content} />
                )
            }
            <Help className={styles.help} />
        </Container>
    )

    return (
        <>
            <CmsPageHelmet cms={data} />
            <MediaBreakpointUp name="lg">
                <Container>
                    <FaqPageBreadcrumbs
                        className={styles.breadcrumbs}
                        currentCmsPageTitle={data?.title}
                    />
                </Container>
                {content}
            </MediaBreakpointUp>
            <MediaBreakpointDown name="lg">
                <Modal
                    isOpen
                    mainClassName={styles.main}
                    variant="wishlist"
                >
                    {content}
                </Modal>
            </MediaBreakpointDown>
        </>
    )
}

export default FaqPage

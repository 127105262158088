import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { Section } from '@pwa-onilab/ui/components/UI/Section'
import { Banner } from '@pwa-onilab/ui/components/widgets/Banners'
import { HOME_ROUTE } from '@pwa-onilab/ui/constants'
import { classNames } from '@pwa-onilab/ui/helpers'
import {
    BANNERS_TYPE_TEXT_ABOVE_IMAGE,
    BANNERS_TYPE_TEXT_ON_LEFT_SIDE,
    BannerTextAboveImage,
} from 'components/widgets/Banners'
import { DisablePageBuilderStyles } from 'elements/DisablePageBuilderStyles'
import { useMatch } from 'react-router-dom'

import styles from './Banners.module.scss'

interface IBannersProps {
    appearance_type: typeof BANNERS_TYPE_TEXT_ABOVE_IMAGE | typeof BANNERS_TYPE_TEXT_ON_LEFT_SIDE
    description?: string
    mobile_description?: string
    image_url: string
    mobile_image_url?: string
    title?: string
    url?: string
    url_text?: string
}

const Banners = (
    {
        appearance_type: appearanceType,
        description: desktopDescription,
        mobile_description: mobileDescription,
        image_url: desktopImageUrl,
        mobile_image_url: mobileImageUrl,
        title,
        url,
        url_text: urlText,
    }: IBannersProps,
) => {
    const isMobile = useMediaBreakpoint().isBreakpointDown('xs')
    const isHomePage = useMatch(HOME_ROUTE)

    const imageUrl = isMobile ? mobileImageUrl : desktopImageUrl

    if (!imageUrl || !appearanceType) {
        return null
    }

    const description = isMobile ? mobileDescription : desktopDescription

    const containerClassName = classNames(
        !isHomePage && styles.disableContainerPadding,
    )

    return (
        <Section className={styles.section}>
            <DisablePageBuilderStyles>
                <Container className={containerClassName}>
                    {
                        appearanceType === BANNERS_TYPE_TEXT_ON_LEFT_SIDE && (
                            <Banner
                                description={description}
                                imageUrl={imageUrl}
                                title={title}
                                url={url}
                                urlText={urlText}
                            />
                        )
                    }
                    {
                        appearanceType === BANNERS_TYPE_TEXT_ABOVE_IMAGE && (
                            <BannerTextAboveImage
                                imageUrl={imageUrl}
                                description={description}
                            />
                        )
                    }
                </Container>
            </DisablePageBuilderStyles>
        </Section>
    )
}

export default Banners

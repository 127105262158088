import { useCustomer } from '@pwa-concept/modules/customer'
import { classNames } from '@pwa-onilab/ui/helpers'
import { Trans } from 'react-i18next'

import styles from './OrderEmail.module.scss'

interface IOrderEmail {
    defaultEmail?: string
}

// TODO get email from customer order response after adding into backend
const OrderEmail = ({ defaultEmail }: IOrderEmail) => {
    const { data: { email } = {}, loading } = useCustomer()

    const isShowLoader = !defaultEmail && loading
    return (
        <div className={styles.titleWrapper}>
            <Trans
                i18nKey="global.accountWithColonText"
                values={{ text: email || defaultEmail }}
                components={{ tag: <span className={classNames(styles.email, isShowLoader && styles.emailLoader)} /> }}
            />
        </div>
    )
}

export default OrderEmail

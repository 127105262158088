import { useCart } from '@pwa-concept/modules/cart'

const useCartPaymentMethods = () => {
    const { data, loading } = useCart()

    return {
        data: {
            availablePaymentMethods: data?.availablePaymentMethods || undefined,
            selectedPaymentMethod: data?.selectedPaymentMethod || undefined,
        },
        loading,
    }
}

export default useCartPaymentMethods

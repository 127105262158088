import { useCart } from '@pwa-concept/modules/cart'

const useCartTotalsInfo = () => {
    const { data, loading } = useCart()

    return {
        data: {
            totalQuantity: data?.totalQuantity || 0,
            totalPrice: data?.totals?.grand?.money || 0,
            subtotal: data?.totals?.sub?.money || 0,
        },
        loading,
    }
}

export default useCartTotalsInfo

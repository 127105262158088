import { turnOffFilter, turnOnFilter } from 'modules/Filters'
import { useCallback } from 'react'

const useBooleanFilter = (isActive, filterId, onChangeValue) => (
    useCallback(() => {
        if (isActive) {
            turnOffFilter(onChangeValue, filterId)
        } else {
            turnOnFilter(onChangeValue, filterId)
        }
    }, [isActive])
)

export default useBooleanFilter

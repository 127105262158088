import { useQuery } from '@apollo/client'
import { Product } from '@pwa-concept/modules/@types/modules/graphql'
import { mapProductReviews } from '@pwa-onilab/ui/mappers'
import { GetProductReviewsQuery } from '@pwa-onilab/ui/schemas'

// FIXME replace on apollo level
const useProductReviewsQuery = (sku: Product['sku']) => {
    const { data: { products } = {}, loading } = useQuery(GetProductReviewsQuery, {
        variables: {
            productSKU: sku,
            currentPage: 1,
            pageSize: 5,
        },
        fetchPolicy: 'cache-and-network',
    })

    return {
        data: mapProductReviews(products),
        loading,
    }
}

export default useProductReviewsQuery

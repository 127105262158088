import { useMemo } from 'react'
import { RegisterOptions } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const useCityFieldRules = (): RegisterOptions => {
    const { t } = useTranslation()

    return useMemo((): RegisterOptions => {
        return {
            required: t('global.errors.required'),
        }
    }, [])
}

export default useCityFieldRules

import api from '@pwa-concept/core/api'
import { MutationResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const verifyOTPAuth: MutationResolvers['verifyOTPAuth'] = async (_, { email, code }) => {
    const { data: { verifyOneTimePasswordAuth: __context } } = await (
        api.graphql(
            gql`
                mutation($email: String!, $code: String!) {
                    verifyOneTimePasswordAuth (email: $email, code: $code){
                        ... OneTimePasswordVerificationOutput
                    }
                }
            `,
        ).mutation({ email, code })
    )
    return {
        __context,
        __typename: 'VerifyOTPRequestOutput',
    }
}

export default verifyOTPAuth

export enum DaysAgoMessages {
    today= 'productPage.review.created.today',
    yesterday= 'productPage.review.created.yesteday',
    thisWeek='productPage.review.created.thisWeek',
    moreThanWeek='productPage.review.created.moreThanWeek',
    moreThanMonth= 'productPage.review.created.moreThanMonth',
    moreThanYear= 'productPage.review.created.moreThanYear',
}

export const calculateDaysAgoMessage = (createdDaysAgo: number) => {
    if (createdDaysAgo === 0) {
        return DaysAgoMessages.today
    }
    if (createdDaysAgo === 1) {
        return DaysAgoMessages.yesterday
    }
    if (createdDaysAgo < 8) {
        return DaysAgoMessages.thisWeek
    }
    if (createdDaysAgo < 29) {
        return DaysAgoMessages.moreThanWeek
    }
    if (createdDaysAgo < 365) {
        return DaysAgoMessages.moreThanMonth
    }

    return DaysAgoMessages.moreThanYear
}

import { MediaBreakpointDown, MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import {
    AccountOrderModal,
    AccountOrdersDesktop,
    AccountOrdersMobile,
    IAccountOrderHistoryWithItems,
} from '@pwa-onilab/ui/components/modules/AccountSubpages'

const AccountOrderHistoryWithItems = ({ orders }: IAccountOrderHistoryWithItems) => {
    return (
        <>
            <MediaBreakpointUp name="lg">
                <AccountOrdersDesktop orders={orders} />
            </MediaBreakpointUp>
            <MediaBreakpointDown name="lg">
                <AccountOrdersMobile orders={orders} />
            </MediaBreakpointDown>
            <AccountOrderModal />
        </>
    )
}

export default AccountOrderHistoryWithItems

import { classNames } from '@pwa-onilab/ui/helpers'
import { memo } from 'react'
import { Trans } from 'react-i18next'

import styles from './WishlistCountItems.module.scss'

interface WishlistCountItemsProps {
    className: string
    count: number
}

const WishlistCountItems = memo((
    {
        className,
        count,
    }: Partial<WishlistCountItemsProps>,
) => {
    if (!count) return null

    return (
        <div className={classNames(styles.wrapper, className)}>
            <Trans
                i18nKey="global.items.count"
                values={{ count }}
                components={{ bold: <b /> }}
            />
        </div>
    )
})

export default WishlistCountItems

import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const AccountPageHelmet = () => {
    const { t } = useTranslation()
    const { data: storeConfig } = useStoreConfig()
    const webSiteTitleName = storeConfig?.defaultTitle
    const webSiteTitle = `${webSiteTitleName} - ${t('AccountPage.title')}`

    return (
        <Helmet>
            <title>
                {webSiteTitle}
            </title>
            <meta name="title" content={t('AccountPage.title')} />
            <meta name="description" content={t('AccountPage.meta.description')} />
            <meta name="keywords" content={t('AccountPage.meta.keywords')} />
        </Helmet>
    )
}

export default AccountPageHelmet

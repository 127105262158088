import TextOnlyButton from '@pwa-onilab/ui/components/elements/Buttons/TextOnlyButton'
import { classNames } from '@pwa-onilab/ui/helpers'
import { ReactElement } from 'react'

import styles from './FormTitle.module.scss'

interface FormTitleProps {
    className?: string
    loading?: boolean
    onClick?: () => void
    title?: string
    subtitle: string | ReactElement
    buttonText?: string
    titleClassName?: string
    subtitleClassName?: string
    buttonClassName?: string

}

const FormTitle = (
    {
        loading,
        onClick,
        title,
        subtitle,
        buttonText,
        titleClassName,
        subtitleClassName,
        buttonClassName,
    }: FormTitleProps,
) => (
    <>
        {
            title && (
                <div className={classNames(styles.title, titleClassName)}>
                    {title}
                </div>
            )
        }
        <div className={classNames(styles.subtitle, subtitleClassName)}>
            {subtitle}
            {
                buttonText && (
                    <TextOnlyButton
                        isBold
                        className={classNames(styles.textButton, buttonClassName)}
                        onClick={onClick}
                        disabled={loading}
                    >
                        {buttonText}
                    </TextOnlyButton>
                )
            }
        </div>
    </>
)

export default FormTitle

import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { CategoryProductCardAttribute } from 'modules/Product'

import styles from './CategoryProductCardAttributes.module.scss'

interface ICategoryProductCardAttributesProps extends IClassName {
    data: string[]
}
const CategoryProductCardAttributes = (
    {
        data,
        className,
    }: ICategoryProductCardAttributesProps,
) => {
    if (!data?.length) {
        return null
    }

    return (
        <div className={classNames(styles.wrapper, className)}>
            {
                data.map((value) => {
                    if (!value) {
                        return null
                    }

                    return (
                        <CategoryProductCardAttribute key={value}>
                            {value}
                        </CategoryProductCardAttribute>
                    )
                })
            }
        </div>
    )
}

export default CategoryProductCardAttributes

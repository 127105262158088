import { Table } from '@pwa-onilab/ui/components/UI/Table'
import { PropsWithChildren } from 'react'

import styles from './AccountOrdersTable.module.scss'

const AccountOrdersTable = ({ children }: PropsWithChildren<any>) => {
    return (
        <Table className={styles.table}>
            {children}
        </Table>
    )
}

export default AccountOrdersTable

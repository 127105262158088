type tErrorCallback = (error: Error | string, ...optionalParams: any[]) => void

type tLogsCallback = (message: string, ...optionalParams: any[]) => void

// TODO warn, debug
export class Logger {
    error: (error: Error | string, ...optionalParams: any[]) => void
    log: (message: string, ...optionalParams: any[]) => void

    #errorsCallback: tErrorCallback
    setErrorsCallback: (callback: tErrorCallback) => void

    #logsCallback: tLogsCallback
    setLogsCallback: (callback: tLogsCallback) => void

    useDefaultHandlers: () => void

    constructor() {
        this.error = (error, ...optionalParams) => {
            this.#errorsCallback?.(error, ...optionalParams)
        }

        this.setErrorsCallback = (callback) => {
            this.#errorsCallback = callback
        }

        this.log = (message, ...optionalParams) => {
            this.#logsCallback?.(message, ...optionalParams)
        }

        this.setLogsCallback = (callback) => {
            this.#logsCallback = callback
        }

        this.useDefaultHandlers = () => {
            this.#logsCallback = (message, _) => {
                if (process.env.NODE_ENV !== 'production') {
                    console.log(message)
                }
            }

            this.#errorsCallback = (error, _) => {
                if (process.env.NODE_ENV !== 'production') {
                    const message = typeof error === 'string' ? error : error.message
                    console.error(message)
                }
            }
        }
    }
}

export const logger = new Logger()

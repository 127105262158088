interface SourceProps {
    media: string
    srcSet: string
}

const Source = (
    {
        media,
        srcSet,
    }: Partial<SourceProps>,
) => (
    <source
        srcSet={srcSet}
        media={media}
    />
)

export default Source

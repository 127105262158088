import { HOME_ROUTE } from '@pwa-onilab/ui/constants/navigation'

export const MOBILE_MENU_BAR_DATA = [
    {
        name: 'Home',
        icon: 'home',
        link: HOME_ROUTE,
    },
    {
        name: 'Catalog',
        icon: 'shopLogo',
        link: '/menu/shop',
    },
    {
        name: 'Cart',
        icon: 'bag',
        link: '/cart',
    },
    {
        name: 'Wishlist',
        icon: 'wishlist',
        link: '/wishlist',
    },
    {
        name: 'Account',
        icon: 'account',
        link: '/account',
    },
]

import { Resolvers } from '@pwa-concept/modules/graphql'

const WishlistItem: Resolvers['WishlistItem'] = {
    id: (_, __, { context }) => context?.id || null,
    addedAt: (_, __, { context }) => context?.added_at || null,
    description: (_, __, { context }) => context?.description || null,
    product: (_, __, { context }) => ({
        __context: context?.product,
        __typename: 'Product',
    }),
}

export default WishlistItem

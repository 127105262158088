import { useStoreConfig } from '@pwa-concept/modules/store-config'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { HelpBlock } from 'modules/Help'
import { useTranslation } from 'react-i18next'

import styles from './HelpTelephone.module.scss'

const HelpTelephone = (
    {
        className,
    }: IClassName,
) => {
    const { t } = useTranslation()
    const { data: { telephoneContactUs } = {}, loading } = useStoreConfig()

    if (!telephoneContactUs && !loading) {
        return null
    }

    return (
        <Anchor
            target="_self"
            to={`tel:${telephoneContactUs}`}
            className={classNames(
                styles.anchor,
                loading && styles.disabled,
                className,
            )}
            isForceAbsoluteUrl
        >
            <HelpBlock
                titleType="h6"
                titleText={t('help.callUs')}
                text={telephoneContactUs}
                iconName="contactUsChat"
                isLoading={loading}
            />
        </Anchor>
    )
}

export default HelpTelephone

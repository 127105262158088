import { Filter } from '@pwa-concept/modules/graphql'
import { IParsedFiltersFromURL, useFiltersFunctionsApi } from '@pwa-onilab/ui/components/modules/Filters'
import { filterObjectToSearchString } from '@pwa-onilab/ui/components/modules/Filters/helpers'
import useFiltersTags from '@pwa-onilab/ui/components/modules/Filters/hooks/tags/useFiltersTags'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// TODO split on contexts:
//  currentValues    ===== categoryItems
//  filters (values from hook prop)

const useFiltersFunctions = (filters: Filter[], initial) => {
    const batchUpdateRef = useRef(null)
    const [isBatchUpdate, setBatchUpdate] = useState<boolean>(false)

    const [currentValues, setCurrentValues] = useState<IParsedFiltersFromURL>(initial)
    const currentValuesRef = useRef<IParsedFiltersFromURL>(initial)
    const filtersRef = useRef<Filter[]>(filters)

    const location = useLocation()
    const navigate = useNavigate()

    const filtersApi = useFiltersFunctionsApi({
        setCurrentValues,
        setBatchUpdate,
        currentValuesRef,
        filtersRef,
    })
    useEffect(() => {
        setCurrentValues(initial)
        currentValuesRef.current = initial
    }, [initial])

    useEffect(() => {
        filtersRef.current = filters
    }, [filters])

    useEffect(() => {
        if (isBatchUpdate) {
            if (batchUpdateRef.current) {
                clearTimeout(batchUpdateRef.current)
            }

            batchUpdateRef.current = setTimeout(() => {
                const newUrl = `${location.pathname}?${filterObjectToSearchString(currentValues)}`
                if (newUrl === location.pathname + location.search) {
                    window.scrollTo(0, 0)
                } else {
                    navigate(newUrl)
                }
                setBatchUpdate(false)
            }, 600)
        }
    }, [currentValues, isBatchUpdate])

    const tags = useFiltersTags(filters, currentValues)

    return {
        currentValues,
        tags,
        filtersApi,
    }
}

export default useFiltersFunctions

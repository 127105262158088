import { MediaBreakpointDown } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { FaqAccordionItemContent, FaqAccordionItemTrigger } from '@pwa-onilab/ui/components/widgets/FaqWidget'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useCallback, useState } from 'react'

import styles from './FaqAccordionItem.module.scss'

interface IFaqAccordionItemProps {
    className: string
    title: string
    content: string
    benchmark: string
}

const FaqAccordionItem = (
    {
        className,
        title,
        content,
        benchmark,
    }: Partial<IFaqAccordionItemProps>,
) => {
    const [isActive, setActive] = useState(false)

    const onToggleHandler = useCallback(() => {
        setActive(state => !state)
    }, [])

    const accordionItemContent = (
        <FaqAccordionItemContent
            content={content}
            benchmark={benchmark}
            isActive={isActive}
        />
    )

    return (
        <div className={classNames(styles.wrapper, className)}>
            <FaqAccordionItemTrigger
                isActive={isActive}
                onToggle={onToggleHandler}
                title={title}
                benchmark={benchmark}
            />
            {accordionItemContent}
            <MediaBreakpointDown name="lg">
                <Modal
                    isOpen={isActive}
                    onDismiss={onToggleHandler}
                    isDraggable
                    isOverAll
                >
                    {accordionItemContent}
                </Modal>
            </MediaBreakpointDown>
        </div>

    )
}

export default FaqAccordionItem

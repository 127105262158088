import { Section } from '@pwa-onilab/ui/components/UI/Section'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { PropsWithChildren } from 'react'

import styles from './CategoryFiltersSection.module.scss'

const CategoryFiltersSection = ({ children, className }: PropsWithChildren<IClassName>) => {
    return (
        <Section className={classNames(styles.filterSection, className)}>
            {children}
        </Section>
    )
}

export default CategoryFiltersSection

import { makeVar } from "@apollo/client";

export enum DRIP_INIT_CODES {
    INITIAL,
    LOADING,
    LOADED,
    FAILED,
}

export const dripInitCode = makeVar<DRIP_INIT_CODES>(DRIP_INIT_CODES.INITIAL)

import { MediaBreakpointDown, MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { ModalHeaderBackArrowWithSearch } from '@pwa-onilab/ui/components/elements/Modal/elements'
import Container from '@pwa-onilab/ui/components/UI/Container'
import {
    FaqAccordion,
    FaqSearchEmpty,
    FaqSearchInput,
    FaqSearchModal,
    FaqWidgetSidebar,
    useFaq,
} from '@pwa-onilab/ui/components/widgets/FaqWidget'
import { goBack } from '@pwa-onilab/ui/helpers'
import { DisablePageBuilderStyles } from 'elements/DisablePageBuilderStyles'
import { useTranslation } from 'react-i18next'

import styles from './FaqWidget.module.scss'

interface IFaqWidgetProps {
    items: Array<{
        items: Array<{
            title: string
            description: string
        }>
        title: string
    }>
}

const FaqWidget = (
    {
        items,
    }: IFaqWidgetProps,
) => {
    const { t } = useTranslation()

    const {
        searchValue,
        handleChange,
        isModalOpen,
        isSearchModalOpen,
        onCloseSearchModalHandler,
        openSearchModal,
        activeTab,
        setActiveTabHandler,
        onCloseModalHandler,
        clearInput,
    } = useFaq(items)

    const goBackHandler = goBack()

    const headerSearchInput = (
        <FaqSearchInput
            searchValue={searchValue}
            handleChange={handleChange}
            clearInput={clearInput}
            className={styles.input}
            isAutoFocus
        />
    )

    const content = (
        <>
            <MediaBreakpointUp name="lg">
                {headerSearchInput}
            </MediaBreakpointUp>
            {
                !activeTab?.items?.length ? (
                    <FaqSearchEmpty />
                ) : (
                    <FaqAccordion
                        data={activeTab?.items}
                        benchmark={activeTab?.benchmark}
                    />
                )
            }
        </>
    )

    return (
        <DisablePageBuilderStyles>
            <MediaBreakpointDown name="lg">
                <Container isRevert>
                    <ModalHeaderBackArrowWithSearch
                        onDismiss={goBackHandler}
                        onFocus={openSearchModal}
                        className={styles.header}
                    >
                        {t('faqPage.title')}
                    </ModalHeaderBackArrowWithSearch>
                </Container>
            </MediaBreakpointDown>
            <div className={styles.wrapper}>
                <FaqWidgetSidebar
                    items={items}
                    activeTitle={activeTab?.title}
                    setActiveTabHandler={setActiveTabHandler}
                />
                <MediaBreakpointUp name="lg">
                    <div className={styles.content}>
                        {content}
                    </div>
                </MediaBreakpointUp>
                <MediaBreakpointDown name="lg">
                    <Modal
                        isOpen={isModalOpen}
                        onDismiss={onCloseModalHandler}
                        variant="footerMenu"
                        header={(
                            <ModalHeaderBackArrowWithSearch
                                onDismiss={onCloseModalHandler}
                                onFocus={openSearchModal}
                            >
                                {activeTab?.title}
                            </ModalHeaderBackArrowWithSearch>
                        )}
                    >
                        {content}
                    </Modal>
                    <FaqSearchModal
                        isOpen={isSearchModalOpen}
                        onClose={onCloseSearchModalHandler}
                        header={headerSearchInput}
                    >
                        {content}
                    </FaqSearchModal>
                </MediaBreakpointDown>
            </div>
        </DisablePageBuilderStyles>
    )
}

export default FaqWidget

import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './SearchPlaceholder.module.scss'

interface ISearchPlaceholderProps {
    className?: string
}

const SearchPlaceholder = (
    {
        className,
    }: ISearchPlaceholderProps,
) => (
    <div className={classNames(className, styles.wrapper)}>
        <div className={styles.title} />
        <div className={styles.list}>
            <div className={styles.item} />
            <div className={styles.item} />
            <div className={styles.item} />
        </div>
        <div className={styles.title} />
        <div className={styles.cards}>
            <div className={styles.card} />
            <div className={styles.card} />
            <div className={styles.card} />
            <div className={styles.card} />
        </div>
        <div className={styles.seeAll} />
    </div>
)

export default SearchPlaceholder

import { DefaultHelmet } from '@pwa-onilab/ui/components/elements/DefaultHelmet'
import { useStoreConfigRegistrationPageMeta } from 'app/modules/store-config'
import { SchemaHelmetWebPage } from 'elements/SchemaHelmet/SchemaHelmetWebPage'

const RegistrationHomePageHelmet = () => {
    const { data: registrationPageMeta } = useStoreConfigRegistrationPageMeta()
    if (!registrationPageMeta) {
        return null
    }

    return (
        <>
            <DefaultHelmet
                meta={registrationPageMeta}
                defaultTitle=""
            />
            <SchemaHelmetWebPage meta={registrationPageMeta} />
        </>
    )
}

export default RegistrationHomePageHelmet

import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { useMemo } from 'react'
import { RegisterOptions } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const useAddressLineRules = (isRequired: boolean): RegisterOptions => {
    const { t } = useTranslation()
    const { data: { maxAddressLineLength } = {} } = useStoreConfig()

    return useMemo((): RegisterOptions => {
        return {
            required: isRequired ? t('global.errors.name') : false,
            maxLength: {
                value: maxAddressLineLength,
                message: t('global.errors.maxLength'),
            },
        }
    }, [])
}

export default useAddressLineRules

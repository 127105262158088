import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'
import Container from '@pwa-onilab/ui/components/UI/Container'
import StatePageBreadcrumbsPlaceholder from 'modules/StatePage/components/StatePageBreadcrumbs/StatePageBreadcrumbsPlaceholder'

import styles from './StatePagePlaceholder.module.scss'

const StatePagePlaceholder = () => {
    return (
        <Container>
            <StatePageBreadcrumbsPlaceholder />
            <div className={styles.columns}>
                <Placeholder className={styles.firstColumn} />
                <Placeholder className={styles.secondColumn} />
            </div>
        </Container>
    )
}

export default StatePagePlaceholder

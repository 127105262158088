import './global.scss'
import './initi18n'

import { useCountries } from '@pwa-concept/modules/countries'
import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { initDrip } from '@pwa-onilab/drip/initDrip'
import { initGA4 } from '@pwa-onilab/ga4/initGA4'
import { initGTM } from '@pwa-onilab/gtm/initGTM'
import DefaultError from '@pwa-onilab/ui/components/elements/ErrorBoundary/DefaultError/DefaultError'
import FirstLevelError from '@pwa-onilab/ui/components/elements/ErrorBoundary/FirstLevelError/FirstLevelError'
import { Footer } from '@pwa-onilab/ui/components/modules/Footer'
import { GlobalLoader } from '@pwa-onilab/ui/components/modules/GlobalLoader'
import MobileMenuBar from '@pwa-onilab/ui/components/modules/MobileMenuBar'
import { useInitCartData, useProductReviewData } from '@pwa-onilab/ui/hooks'
import * as Sentry from '@sentry/react'
import Header from 'components/overrides/modules/Header/Header'
import { SchemaHelmetMobilePhoneStore } from 'elements/SchemaHelmet/SchemaHelmetMobilePhoneStore'
import { SchemaHelmetOrganization } from 'elements/SchemaHelmet/SchemaHelmetOrganization'
import { SchemaHelmetWebSite } from 'elements/SchemaHelmet/SchemaHelmetWebSite'
import { useRegisterServiceWorker } from 'hooks/useRegisterServiceWorker'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

export const App = () => {
    useInitCartData()
    const { data: storeConfig } = useStoreConfig()
    useProductReviewData()
    useCountries()

    useRegisterServiceWorker()

    useEffect(() => {
        if (!storeConfig?.analytic) {
            return
        }
        if (storeConfig.analytic.gtmID) {
            initGTM(storeConfig.analytic.gtmID)
        }

        initDrip(storeConfig.analytic.dripAccountID)
        initGA4(storeConfig.analytic.ga4ID)
    }, [storeConfig])

    return (
        <Sentry.ErrorBoundary fallback={<FirstLevelError />}>
            <SchemaHelmetWebSite />
            <SchemaHelmetOrganization />
            <SchemaHelmetMobilePhoneStore />
            <Header />
            <Sentry.ErrorBoundary fallback={({ resetError }) => <DefaultError resetError={resetError} />}>
                <Outlet />
            </Sentry.ErrorBoundary>
            <Footer />
            <MobileMenuBar />
            <GlobalLoader />
        </Sentry.ErrorBoundary>
    )
}

import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';
import { ProductImageFragmentDoc } from './ProductImage';
import { ProductPriceFragmentDoc } from './ProductPrice';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

export type ProductVariantFragment = { __typename?: 'ProductVariant', options?: Array<{ __typename?: 'ProductVariantOption', key?: string | null, value?: string | null } | null> | null, product?: { __typename?: 'Product', id?: string | null, sku?: string | null, configuredSiblingSku?: string | null, name?: string | null, url?: string | null, description?: string | null, shortDescription?: string | null, stock?: { __typename?: 'ProductStock', name?: string | null, type?: Types.ProductStockType | null } | null, images?: Array<{ __typename?: 'ProductImage', id?: string | null, description?: string | null, url?: string | null } | null> | null, thumbnail?: { __typename?: 'ProductImage', id?: string | null, description?: string | null, url?: string | null } | null, price?: { __typename?: 'ProductPrice', discount?: number | null, isRange?: boolean | null, badges?: Array<{ __typename?: 'ProductPriceBadge', name?: string | null } | null> | null, current?: { __typename?: 'Money', value?: number | null, text?: string | null, currency?: string | null } | null, initial?: { __typename?: 'Money', value?: number | null, text?: string | null, currency?: string | null } | null } | null } | null };

export const ProductVariantFragmentDoc = gql`
    fragment ProductVariant on ProductVariant {
  options {
    key
    value
  }
  product {
    id
    sku
    configuredSiblingSku
    name
    url
    description @include(if: $isFullProduct)
    shortDescription @include(if: $isFullProduct)
    stock {
      name
      type
    }
    images @include(if: $isFullProduct) {
      ...ProductImage
    }
    thumbnail {
      ...ProductImage
    }
    price {
      ...ProductPrice
    }
  }
}
    ${ProductImageFragmentDoc}
${ProductPriceFragmentDoc}`;
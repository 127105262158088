import { useAuthLogin } from '@pwa-concept/modules/auth'

interface IUseTryGetAuthTokenResponse {
    token?: string
    errorMessage?: string
}

const useGetAuthToken = () => {
    const tryLoginCustomer = useAuthLogin()

    return async (email: string, password: string): Promise<IUseTryGetAuthTokenResponse> => {
        try {
            const { data: { token } } = await tryLoginCustomer(email, password, true)
            return {
                token,
                errorMessage: null,
            }
        } catch (error) {
            return {
                token: null,
                errorMessage: error.message,
            }
        }
    }
}
export default useGetAuthToken

import { ProductModule } from '@pwa-concept/modules/product'

import graphqlFragments from './graphql/fragments'
import graphqlSchemas from './graphql/schemas'
import stores from './graphql/stores'

// TODO rm unused fields from fragments
const AppProductModule = ProductModule.extend((module, { concat }) => ({
    graphqlSchemas: concat(module?.graphqlSchemas, graphqlSchemas),
    graphqlResolvers: concat(module?.graphqlResolvers, stores?.resolvers),
    graphqlStoreFragments: concat(module?.graphqlStoreFragments, stores.fragments),
    graphqlFragments: concat(module?.graphqlFragments, graphqlFragments),
    // graphqlStoreFragments: stores?.fragments,
    // graphqlStorePossibleTypes: stores?.possibleTypes,

}))

export default AppProductModule

import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { IIconProps } from '@pwa-onilab/ui/components/UI/Icon/Icon'
import { ITitle } from '@pwa-onilab/ui/components/UI/Title'
import { ILoading } from '@pwa-onilab/ui/interfaces'
import { HelpText, HelpTitle } from 'modules/Help'
import { IHelpText } from 'modules/Help/elements/HelpText/IHelpText'

import styles from './HelpBlock.module.scss'

interface IHelpBlock extends ILoading {
    titleType: ITitle['type']
    titleText: ITitle['text']
    text: IHelpText['text']
    iconName: IIconProps['name']
}

const HelpBlock = (
    {
        titleType,
        titleText,
        text,
        iconName,
        isLoading,
    }: IHelpBlock) => {
    return (
        <div className={styles.wrapper}>
            <Icon name={iconName} className={styles.icon} />
            <div className={styles.textWrapper}>
                <HelpTitle type={titleType} text={titleText} />
                <HelpText text={text} isLoading={isLoading} />
            </div>
        </div>
    )
}

export default HelpBlock

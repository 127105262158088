import PasswordFormField from '@pwa-onilab/ui/components/elements/FormFields/PasswordFormField/PasswordFormField'
import { PERSONAL_INFORMATION_INPUT_SET_VALUE_OPTIONS } from '@pwa-onilab/ui/components/modules/Account'
import { useFormContext } from 'react-hook-form'

const PersonalInfoPasswordField = () => {
    const { register, setValue, formState: { errors } } = useFormContext()

    return (
        <PasswordFormField
            register={register}
            setValue={setValue}
            setValueOptions={PERSONAL_INFORMATION_INPUT_SET_VALUE_OPTIONS}
            errors={errors}
        />
    )
}
export default PersonalInfoPasswordField

import { BlogAuthor } from '@pwa-concept/modules/graphql'
import { Image } from '@pwa-onilab/ui/components/UI/Image'
import {
    AuthorPageLeftColumn, AuthorPageRightColumn,
    AuthorPageRowTitle,
    AuthorPageRowWrapper,
    AuthorPageSocialLinks,
} from 'modules/AuthorPage'

import styles from './AuthorPageDescription.module.scss'

const AuthorPageDescription = ({ author }: { author: BlogAuthor }) => {
    return (
        <AuthorPageRowWrapper>
            <AuthorPageLeftColumn>
                <Image
                    src={author.imageUrl}
                    alt={`${author.name} avatar`}
                    className={styles.image}
                />
            </AuthorPageLeftColumn>
            <AuthorPageRightColumn>
                <AuthorPageRowTitle type="h1" text={author.name} className={styles.title} />
                {
                    author.description && (
                        <p className={styles.description}>
                            {author.description}
                        </p>
                    )
                }
                <AuthorPageSocialLinks socialUrls={author.socialUrls} />
            </AuthorPageRightColumn>
        </AuthorPageRowWrapper>
    )
}

export default AuthorPageDescription

import ParserBounded from '@pwa-onilab/ui/components/elements/ParserBounded/ParserBounded'
import { PageAuthor, PageMainImage, PageTitle } from 'elements/BlogStatePages/elements'
import { StrainFamilyAllFamilies } from 'modules/StrainFamily'
import { IStrainFamilyContent } from 'modules/StrainFamily/components/StrainFamilyContent/IStrainFamilyContent'

import styles from './StrainFamilyContent.module.scss'

const StrainFamilyContent = ({ strain }: IStrainFamilyContent) => {
    return (
        <div>
            <PageTitle text={strain.name} />
            <PageAuthor author={strain.author} createdAt={strain.publishTime} />
            <div className={`${styles.mainContentWrapper}`}>
                {strain.image && (
                    <PageMainImage
                        image={strain.image}
                        className={styles.image}
                        loaderClassName={styles.image}
                    />
                )}
            </div>
            {
                strain.description && (
                    <ParserBounded content={strain.description} />
                )
            }
            <StrainFamilyAllFamilies strain={strain} />
        </div>
    )
}

export default StrainFamilyContent

import { Breadcrumbs } from '@pwa-onilab/ui/components/modules/Breadcrumbs'
import { ILoading } from '@pwa-onilab/ui/interfaces'
import { BreadcrumbsPageTypeEnum, useBreadCrumbs } from 'app/modules/breadcrumb'

interface IStrainFamilyBreadcrumbs extends ILoading {
    urlKey?: string
}

const StrainFamilyBreadcrumbs = (
    {
        isLoading: isStrainLoading,
        urlKey,
    }: IStrainFamilyBreadcrumbs,
) => {
    const { data: breadcrumbs, loading: breadcrumbsLoading } = useBreadCrumbs({
        pageType: BreadcrumbsPageTypeEnum.FAMILY_STRAIN,
        urlKey,
    })

    return <Breadcrumbs items={breadcrumbs} loading={breadcrumbsLoading || isStrainLoading} />
}

export default StrainFamilyBreadcrumbs

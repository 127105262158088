import { BreadcrumbUrlPossibleType } from '@pwa-concept/modules/graphql'
import { CART_ROUTE, HOME_ROUTE, WISHLIST_ROUTE } from '@pwa-onilab/ui/constants/navigation'
import { checkIsAbsoluteUrl } from '@pwa-onilab/ui/helpers/urlParams'

// TODO rewrite; erase extra routes
const router = {
    withFullUrl: (current = '') => `${window.location.origin}${current}`,
    getHomeUrl: () => HOME_ROUTE,
    getHomePath: () => HOME_ROUTE,
    getCategoryUrl: (category) => {
        if (!category) {
            return null
        }

        if (category?.urlKey?.length > 0) {
            return `/${category.urlKey}`
        }

        return `/category/${category?.id || category}`
    },
    getCategoryPath: () => '/category/:id',
    getCartUrl: () => CART_ROUTE,
    getCartPath: () => CART_ROUTE,
    getProductUrl: (product) => {
        // FIXME
        if (!product) {
            return null
        }

        return `/product/${product?.id || product}`
    },
    getProductPath: () => '/product/:id',
    getSearchUrl: (search: string = null, filters: string = null) => {
        if (checkIsAbsoluteUrl(search)) {
            return search
        }

        let searchParams = search ? `q=${encodeURIComponent(search)}` : ''
        if (filters) {
            searchParams += '&' + filters
        }

        return `/search?${searchParams}`
    },
    getSearchPath: () => '/search',
    getBlogUrl: (post) => (post ? `/blog/${post?.identifier || post}` : null),
    getBlogPath: () => '/blog/:id',
    getCmsPageUrl: (page) => {
        if (!page) {
            return null
        }

        if (page?.urlKey || page?.identifier) {
            return page?.urlKey || page?.identifier
        }

        return `/info/${page}`
    },
    getCmsPagePath: () => '/info/:id',
    getMenuNodeUrl: (node) => {
        if (!node?.content) {
            return null
        }

        if (node.content.urlPath?.length > 0) {
            return {
                to: `/${node.content.urlPath}`,
            }
        }

        switch (node.content.type) {
            case 'cms_page_link':
                return {
                    to: node.content.urlKey,
                }
            case 'cms_page':
                return {
                    to: `/${node.content.urlKey || node.content.urlPath}`,
                }
            case 'product':
                return {
                    to: `/product/${node.content.id}`,
                }
            case 'category':
                return {
                    to: `/category/${node.content.id}`,
                }
            case 'custom_url':
                return {
                    to: node.content.url,
                    isExternal: node.content.isExternal,
                    isTargetBlank: node.content.target,
                }
            default:
                return null
        }
    },
    getMyAccountUrl: () => '/account',
    getMyAccountPath: () => '/account',
    getMyInformationUrl: () => '/account/information',
    getMyInformationVatNumberUrl: () => '/account/information#vatNumber',
    getMyInformationVatNumberHash: () => '#vatNumber',
    getMyInformationPath: () => '/account/information',
    getWishlistUrl: () => WISHLIST_ROUTE,
    getWishlistPath: () => WISHLIST_ROUTE,
    getMyOrdersUrl: () => '/account/orders',
    getMyOrdersPath: () => '/account/orders',
    getMyAddressesUrl: () => '/account/addresses',
    getMyAddressesPath: () => '/account/addresses',
    getMySavedCardsUrl: () => '/account/saved-cards',
    getMySavedCardsPath: () => '/account/saved-cards',
    getMyReviewsUrl: () => '/account/reviews',
    getMyReviewsPath: () => '/account/reviews',
    getMyMessagesUrl: () => '/account/messages',
    getMyMessagesPath: () => '/account/messages',
    getMyQuotationsUrl: () => '/account/quotations',
    getMyQuotationsPath: () => '/account/quotations',
    getMySubscriptionHistoryUrl: () => '/account/subscription-history',
    getMySubscriptionHistoryPath: () => '/account/subscription-history',
    getEntityUrl: (entity) => {
        switch (entity?.__typename) {
            case 'Vendor':
            case 'VendorInfo':
                return `/microsite/${entity?.id || entity?.entity_id}`
            case 'OrderItem':
                return entity?.product_url_key?.length ? `/${entity?.product_url_key}` : router.getProductUrl(entity?.product)
            case 'SimpleProduct':
            case 'ConfigurableProduct':
            case 'VirtualProduct':
            case 'Product':
            case 'BundleProduct':
                return router.getProductUrl(entity)
            case 'SimpleCartItem':
            case 'ConfigurableCartItem':
            case 'VirtualCartItem':
            case 'CartItem':
            case 'BundleCartItem':
                return router.getProductUrl(entity?.product)
            case 'CategoryTree':
                return router.getCategoryUrl(entity)
            case 'Category':
                return router.getCategoryUrl(entity)
            case 'CmsPage':
            case 'Cms':
                return router.getCmsPageUrl(entity)
            case 'Node':
            case 'MenuNode':
                return router.getMenuNodeUrl(entity)

            case 'BreadcrumbUrl': {
                if (entity?.to) return entity.to

                switch (entity?.type) {
                    case BreadcrumbUrlPossibleType.Category:
                        return router.getCategoryUrl(entity)
                    default:
                        return null
                }
            }
            default:
                return null
        }
    },
}

const useRouter = () => router

export { useRouter }

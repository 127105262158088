import Price from '@pwa-onilab/ui/components/UI/Price'
import { classNames } from '@pwa-onilab/ui/helpers'
import { roundNumberToHundredth } from 'helpers'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

import styles from './ProductPricePerSeed.module.scss'
import api from '@pwa-concept/core/api'
import { gql } from 'graphql-tag'
import { toast } from 'react-toastify'

interface IProductPricePerSeedProps {
    actualPricePerSeed: number
    originalPricePerSeed?: number
    className?: string
    withFromLabel?: boolean
}

const ProductPricePerSeed = (
    {
        actualPricePerSeed,
        originalPricePerSeed,
        className,
        withFromLabel,
    }: IProductPricePerSeedProps,
) => {
    const { t } = useTranslation()

    const [exchangeRate, setExchangeRate] = useState(null) // State to store the exchange rate
    const [loading, setLoading] = useState(true) // State to track loading state

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: { currency: context = {} } = {} } = await api.graphql(
                    gql`
                        query {
                            currency {
                                exchange_rates {
                                    currency_to
                                    rate
                                }
                            }
                        }
                    `,
                ).query(undefined, {
                    errorPolicy: 'all',
                });
                setExchangeRate(context.exchange_rates[0].rate)
            } catch (error) {
                console.error('Error fetching data:', error)
                toast.error('Error fetching data')
            } finally {
                setLoading(false)
            }
        };
        if (Cookies.get('current_store') && Cookies.get('current_store') === 'ca') {
            fetchData()
        } else {
            setLoading(false)
            setExchangeRate(1)
        }
    }, []);

    if (loading) {
        return <div>Loading...</div>
    }

    return (
        <div className={classNames(styles.wrapper, className)}>
            <div className={classNames(styles.priceWrapper, originalPricePerSeed && styles.priceWrapperWithDiscount)}>
                {
                    withFromLabel && (
                        <div className={styles.fromLabel}>
                            {t('as low as')}
                        </div>
                    )
                }
                {
                    originalPricePerSeed && (
                        <Price className={styles.originalPrice}>
                            {roundNumberToHundredth(originalPricePerSeed, exchangeRate)}
                        </Price>
                    )
                }
                <div className={styles.actualPricePerSeed}>
                    {
                        actualPricePerSeed && (
                            <Price className={styles.actualPrice}>
                                {roundNumberToHundredth(actualPricePerSeed, exchangeRate)}
                            </Price>
                        )
                    }
                    <div className={styles.seedLabel}>
                        /{t('global.seed')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductPricePerSeed

export const splitArraysOnColumns = <T extends unknown>(array: T[], columnsCount): T[][] => {
    const result: T[][] = [
        [],
    ]

    let currentColumn = 0

    const maxItemsInColumn = Math.ceil(array.length / columnsCount)

    for (let i = 0; i < array.length; i++) {
        if (i !== 0 && (i % maxItemsInColumn === 0)) {
            currentColumn += 1
            result[currentColumn] = [array[i]]
            continue
        }

        result[currentColumn].push(array[i])
    }

    return result
}

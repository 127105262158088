import api from '@pwa-concept/core/api'
import { MutationResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const shareWishlist: MutationResolvers['shareWishlist'] = async (_, input) => {
    const {
        id,
        emails,
        message,
    } = input

    if (!id) {
        throw new Error('Cannot perform operation')
    }

    const { data: { shareWishlistOnilab = {} } = {} } = (
        await api.graphql(
            gql`
                mutation(
                    $wishlistUid: String!
                    $emails: [String]!
                    $message: String
                ) {
                    shareWishlistOnilab(
                        wishlistUid: $wishlistUid
                        emails: $emails
                        message: $message
                    ) {
                        status
                    }
                }
            `,
        ).mutation({
            wishlistUid: id,
            emails,
            message,
        })
    )

    if (!shareWishlistOnilab) {
        return null
    }

    return {
        status: shareWishlistOnilab.status || null,
        __typename: 'WishlistShareOutput',
    }
}

export default shareWishlist

import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { createElement, MouseEventHandler, PropsWithChildren } from 'react'

import styles from './MobileMenuAnchor.module.scss'

interface IMobileMenuAnchorProps {
    className?: string
    isArrow?: boolean
    as?: Parameters<typeof createElement>[0]
    asProps?: object
    onClick?: MouseEventHandler
    isBorderBottom?: boolean
    isParent?: boolean
}

const MobileMenuAnchor = (
    {
        children,
        className,
        isArrow,
        as = 'div',
        asProps,
        onClick,
        isBorderBottom,
        isParent,
    }: PropsWithChildren<IMobileMenuAnchorProps>,
) => (
    createElement<Pick<IMobileMenuAnchorProps, 'className' | 'onClick'>>(
        as,
        {
            onClick,
            ...asProps,
            className: classNames(
                styles.anchor, className,
                isBorderBottom && styles.borderBottom,
                isParent && styles.parentNode,
            ),
        },
        <>
            {children}
            {isArrow && <Icon name="chevron" className={styles.iconArrow} />}
        </>,
    )
)

export default MobileMenuAnchor

import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { IAnchor } from '@pwa-onilab/ui/components/UI/Anchor/IAnchor'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'

import styles from './NavigationMobileButton.module.scss'

interface INavigationMobileButtonProps extends IClassName, IAnchor {
    title: string
    onClick: () => void
    withArrow: boolean
}

const NavigationMobileButton = (
    {
        title,
        onClick,
        className,
        to,
        withArrow,
        isForceAbsoluteUrl,
    }: Partial<INavigationMobileButtonProps>,
) => (
    <Anchor
        className={classNames(styles.button, withArrow && styles.linkWithArrow, className)}
        onClick={onClick}
        to={to}
        isForceAbsoluteUrl={isForceAbsoluteUrl}
    >
        {title}
        {
            withArrow && (
                <Icon
                    name="chevron"
                    className={styles.icon}
                />
            )
        }
    </Anchor>
)

export default NavigationMobileButton

import { CheckboxForm, ICheckboxForm } from '@pwa-onilab/ui/components/UI/Checkbox'
import { FORM_RECEIVE_NEWS_CHECKBOX } from '@pwa-onilab/ui/constants/formFields'
import { useTranslation } from 'react-i18next'

const ReceiveNewsCheckbox = ({ register, errors, disabled }: ICheckboxForm) => {
    const { t } = useTranslation()

    return (
        <CheckboxForm
            register={register}
            id={FORM_RECEIVE_NEWS_CHECKBOX}
            errors={errors}
            disabled={disabled}
        >
            {t('signUpForm.receiveNews')}
        </CheckboxForm>
    )
}

export default ReceiveNewsCheckbox

import { PaginationLoadMoreSkeleton } from '@pwa-onilab/ui/components/modules/Pagination'
import { CategoryProductCardSkeleton } from '@pwa-onilab/ui/components/modules/Product'

import css from './CategoryItems.module.scss'

const CategoryItemsSkeleton = () => (
    <div id="items" className={css.wrapper}>
        <div className={css.list}>
            <CategoryProductCardSkeleton className={css.card} skeletonCount={20} />
        </div>
        <PaginationLoadMoreSkeleton />
    </div>
)

export { CategoryItemsSkeleton }

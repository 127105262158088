import { useCartRecommendedProducts } from '@pwa-concept/modules/cart'
import { CartRecommendedSliderPlaceholder } from '@pwa-onilab/ui/components/modules/Cart'
import { CartRecommendedTitle } from '@pwa-onilab/ui/components/modules/Cart/elements/CartRecommended'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import CartRecommendedSlider from 'components/overrides/modules/Cart/elements/CartRecommended/CartRecommendedSlider/CartRecommendedSlider'
import { memo } from 'react'

import styles from './CartRecommended.module.scss'

const CartRecommended = (
    {
        className,
    }: IClassName,
) => {
    const { data: items, loading } = useCartRecommendedProducts()

    if (!items?.length) {
        if (loading) {
            return <CartRecommendedSliderPlaceholder />
        }
        return null
    }

    return (
        <div className={classNames(styles.wrapper, className)}>
            <CartRecommendedTitle className={styles.title} />
            <CartRecommendedSlider items={items} />
        </div>
    )
}
export default memo(CartRecommended)

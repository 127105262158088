import Cart from '@pwa-onilab/ui/components/modules/Cart'
import { useCartModal } from '@pwa-onilab/ui/hooks'
import { useEffect } from 'react'

const CartMobileNavigation = () => {
    const { closeModal } = useCartModal()

    useEffect(() => {
        closeModal()
    }, [])

    return <Cart />
}

export default CartMobileNavigation

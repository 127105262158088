import { CartModule } from '@pwa-concept/modules/cart'
import { CurrencyModule } from '@pwa-concept/modules/currency'
import { AppProductModule } from 'app/modules/product'

import graphqlFragments from './graphql/fragments'
import graphqlSchemas from './graphql/schemas'
import stores from './graphql/stores'

const AppCartModule = CartModule.extend((module, { concat }) => ({
    modules: [
        AppProductModule,
        CurrencyModule,
    ],
    graphqlFragments: concat(module?.graphqlFragments, graphqlFragments),
    graphqlSchemas: concat(module?.graphqlSchemas, graphqlSchemas),
    graphqlResolvers: concat(module?.graphqlResolvers, stores?.resolvers),
    graphqlStoreFragments: concat(module?.graphqlStoreFragments, stores?.fragments),
    graphqlStorePossibleTypes: concat(module?.graphqlStorePossibleTypes, stores?.possibleTypes),
}))

export default AppCartModule

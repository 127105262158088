import { IFilterSortByInstance } from '@pwa-onilab/ui/components/modules/Filters/components/FilterSortBy/IFilterSortBy'
import { Tab, Tabs } from '@pwa-onilab/ui/components/UI/Tabs'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useTranslation } from 'react-i18next'

const FilterSortByDesktop = (
    {
        sortId,
        onChangeValue,
        className,
        items,
    }: IFilterSortByInstance) => {
    const { t } = useTranslation()
    const onOptionClick = (option) => () => onChangeValue(option)

    return (
        <Tabs
            title={t('global.sortBy')}
            variant="small"
            className={classNames(className)}
        >
            {items?.map((option) => {
                const isActive = option.id === sortId
                return (
                    <Tab
                        isActive={isActive}
                        onClick={onOptionClick(option)}
                        key={option.id}
                    >
                        {option.name}
                    </Tab>
                )
            })}
        </Tabs>
    )
}

export default FilterSortByDesktop

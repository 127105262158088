import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { SYMBOL_SLASH } from 'constants/common'
import { addSlashToEndUrl } from 'helpers'

const useGetLocationPageUrl = () => {
    const { data: { worldPageUrlKey } = {} } = useStoreConfig()

    return (urlKey: string) => {
        const url = [worldPageUrlKey, urlKey].filter(Boolean).join(SYMBOL_SLASH)
        return SYMBOL_SLASH + addSlashToEndUrl(url)
    }
}

export default useGetLocationPageUrl

import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import {
    productsDecoratorCategoryId,
    productsDecoratorMultipleFilters,
} from '@pwa-concept/modules/helpers/productsModules'
import { gql } from 'graphql-tag'

const filters: QueryResolvers['filters'] = async (_, { input }) => {
    const { categoryId, search, filters } = input
    const mappedFilters = filters ? Object.entries(filters)?.map(([key, value]) => ({ key, value })) : undefined

    const { data: { products = {} } = {} } = await (
        api.graphql(
            gql`
                query Products(
                    $search: String = ""
                    $filter: ProductAttributeFilterInput
                ) {
                    products(
                        filter: $filter
                        search: $search
                    ) {
                        aggregations (
                            filter: {
                                category: {
                                    includeDirectChildrenOnly: true
                                }
                            }
                        ) {
                            ... Aggregation
                        }
                    }
                }
            `,
        ).variableIf(
            !!mappedFilters?.length, (prev) => productsDecoratorMultipleFilters(prev, mappedFilters),
        ).variableIf(
            !!categoryId, (prev) => productsDecoratorCategoryId(prev, categoryId),
        ).query({
            search,
        })
    )

    return [...products?.aggregations || []]
        ?.sort?.((a, b) => +a?.position > +b?.position ? 1 : -1)
        ?.map?.((__context) => ({
            __context,
            __typename: 'Filter',
        }))
}

export default filters

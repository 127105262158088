import {
    CartReorderItemsMutationVariables,
    useCartReorderItemsMutation,
} from '@pwa-concept/modules/cart/graphql/mutations/CartReorderItems'

const useCartReorderItems = () => {
    const [reorderItems] = useCartReorderItemsMutation()

    return async (orderNumber: CartReorderItemsMutationVariables['orderNumber']) => {
        const { data: { cartReorderItems: { cart, userInputErrors } = {} } = {} } = await reorderItems({
            variables: {
                orderNumber,
            },
            ignoreResults: true,
        })

        return {
            data: {
                cart: cart || undefined,
                errors: userInputErrors || [],
            },
        }
    }
}

export default useCartReorderItems

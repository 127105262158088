import { useStoreConfigWorldPageQuery } from 'app/modules/store-config/graphql/queries/StoreConfigWorldPage'

const useStoreConfigWorldPage = () => {
    const { data, loading } = useStoreConfigWorldPageQuery()

    return {
        data: data?.storeConfigWorldPage,
        loading,
    }
}

export default useStoreConfigWorldPage

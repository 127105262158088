import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck


type ResolverTypeWrapperSignatureContext<T = any, T2 = any> = {
    __context?: T
    __errors?: T2
};
type ResolverContext<T = any, T2 = any> = {
    context?: T
    errors?: T2
};

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type ResolverFn<TResult, TParent, TContext, TArgs> = (parent: TParent , args: TArgs, context: TContext, info: GraphQLResolveInfo) => Promise<TResult> | TResult
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
}

export interface AccessToken {
  __typename?: 'AccessToken';
  token?: Maybe<Scalars['String']>;
}

export interface AdditionalField {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Any']>;
}

export interface AvailableShippingMethod {
  __typename?: 'AvailableShippingMethod';
  available?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  carrierTitle?: Maybe<Scalars['String']>;
  methodCode?: Maybe<Scalars['String']>;
  methodTitle?: Maybe<Scalars['String']>;
  price?: Maybe<CartMoneyOnly>;
  priceExcludingTax?: Maybe<CartMoneyOnly>;
  priceIncludingTax?: Maybe<CartMoneyOnly>;
}

export interface BillingAddressInput {
  address?: InputMaybe<CartAddressInput>;
  customerAddressId?: InputMaybe<Scalars['Int']>;
  sameAsShipping?: InputMaybe<Scalars['Boolean']>;
  useForShipping?: InputMaybe<Scalars['Boolean']>;
}

export interface Breadcrumb {
  __typename?: 'Breadcrumb';
  id?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<BreadcrumbUrl>;
}

export interface BreadcrumbUrl {
  __typename?: 'BreadcrumbUrl';
  id?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}

export enum BreadcrumbUrlPossibleType {
  Category = 'Category'
}

export interface Cart {
  __typename?: 'Cart';
  appliedCoupon?: Maybe<Scalars['String']>;
  availablePaymentMethods?: Maybe<Array<Maybe<CartAvailablePaymentMethods>>>;
  billingAddress?: Maybe<CartBillingAddress>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  items?: Maybe<Array<Maybe<CartItems>>>;
  prices?: Maybe<CartPrices>;
  selectedPaymentMethod?: Maybe<CartSelectedPaymentMethod>;
  shippingAddresses?: Maybe<Array<Maybe<CartShippingAddresses>>>;
  totalQuantity?: Maybe<Scalars['Int']>;
  totals?: Maybe<CartTotals>;
}

export interface CartAddressCountry {
  __typename?: 'CartAddressCountry';
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
}

export interface CartAddressInput {
  additionalFields?: InputMaybe<Array<InputMaybe<AdditionalField>>>;
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['Int']>;
  street?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  telephone?: InputMaybe<Scalars['String']>;
}

export interface CartAddressRegion {
  __typename?: 'CartAddressRegion';
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['Int']>;
}

export interface CartAmount {
  __typename?: 'CartAmount';
  amount?: Maybe<CartMoney>;
  label?: Maybe<Scalars['String']>;
}

export interface CartAssignToCustomerInput {
  destinationCartId?: InputMaybe<Scalars['String']>;
  sourceCartId?: InputMaybe<Scalars['ID']>;
}

export interface CartAvailablePaymentMethods {
  __typename?: 'CartAvailablePaymentMethods';
  code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}

export interface CartBillingAddress {
  __typename?: 'CartBillingAddress';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<CartAddressCountry>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  region?: Maybe<CartAddressRegion>;
  street?: Maybe<Array<Maybe<Scalars['String']>>>;
  telephone?: Maybe<Scalars['String']>;
}

export interface CartCustomizableOptionsValue {
  __typename?: 'CartCustomizableOptionsValue';
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface CartItem {
  quantity?: InputMaybe<Scalars['Int']>;
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  sku: Scalars['String'];
}

export interface CartItemCustomizableOptions {
  __typename?: 'CartItemCustomizableOptions';
  changedLabel?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<CartItemCustomizableOptionsType>;
  values?: Maybe<Array<Maybe<CartCustomizableOptionsValue>>>;
}

export enum CartItemCustomizableOptionsType {
  Area = 'Area',
  Checkbox = 'Checkbox',
  Date = 'Date',
  DropDown = 'DropDown',
  Field = 'Field',
  File = 'File',
  Multiple = 'Multiple',
  Radio = 'Radio'
}

export interface CartItemPrices {
  __typename?: 'CartItemPrices';
  discounts?: Maybe<Array<Maybe<CartAmount>>>;
  price?: Maybe<CartMoneyOnly>;
  rowTotal?: Maybe<CartMoneyOnly>;
  rowTotalIncludingTax?: Maybe<CartMoneyOnly>;
  totalItemDiscount?: Maybe<CartMoneyOnly>;
}

export interface CartItemSelectedConfigurableOptions {
  __typename?: 'CartItemSelectedConfigurableOptions';
  optionLabel: Scalars['String'];
  optionUid: Scalars['ID'];
  optionValueUid: Scalars['ID'];
  valueLabel: Scalars['String'];
}

export enum CartItemTypes {
  BundleCartItem = 'BundleCartItem',
  ConfigurableCartItem = 'ConfigurableCartItem',
  DownloadableCartItem = 'DownloadableCartItem',
  SimpleCartItem = 'SimpleCartItem',
  VirtualCartItem = 'VirtualCartItem'
}

export interface CartItems {
  __typename?: 'CartItems';
  configurableOptions?: Maybe<Array<Maybe<CartItemSelectedConfigurableOptions>>>;
  configuredVariant?: Maybe<Product>;
  customizableOptions?: Maybe<Array<Maybe<CartItemCustomizableOptions>>>;
  id?: Maybe<Scalars['ID']>;
  prices?: Maybe<CartItemPrices>;
  product?: Maybe<Product>;
  quantity?: Maybe<Scalars['Int']>;
  type?: Maybe<CartItemTypes>;
}

export interface CartMoney {
  __typename?: 'CartMoney';
  label?: Maybe<Scalars['String']>;
  money?: Maybe<Scalars['String']>;
}

export interface CartMoneyOnly {
  __typename?: 'CartMoneyOnly';
  money?: Maybe<Scalars['String']>;
}

export interface CartPrices {
  __typename?: 'CartPrices';
  appliedTaxes?: Maybe<Array<Maybe<CartAmount>>>;
  discounts?: Maybe<Array<Maybe<CartAmount>>>;
  grandTotal?: Maybe<CartMoney>;
  subtotalExcludingTax?: Maybe<CartMoney>;
  subtotalIncludingTax?: Maybe<CartMoney>;
  subtotalWithDiscountExcludingTax?: Maybe<CartMoney>;
}

export interface CartRemoveItemInput {
  cartId?: InputMaybe<Scalars['ID']>;
  cartItemUid?: InputMaybe<Scalars['String']>;
}

export interface CartReorderItemsOutput {
  __typename?: 'CartReorderItemsOutput';
  cart?: Maybe<Cart>;
  userInputErrors?: Maybe<Array<Maybe<ReorderError>>>;
}

export interface CartSelectedPaymentMethod {
  __typename?: 'CartSelectedPaymentMethod';
  code?: Maybe<Scalars['String']>;
  purchaseOrderNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}

export interface CartSelectedShippingMethod {
  __typename?: 'CartSelectedShippingMethod';
  carrierCode?: Maybe<Scalars['String']>;
  carrierTitle?: Maybe<Scalars['String']>;
  methodCode?: Maybe<Scalars['String']>;
  methodTitle?: Maybe<Scalars['String']>;
  price?: Maybe<CartMoneyOnly>;
}

export interface CartSetGuestEmailInput {
  cartId: Scalars['String'];
  email: Scalars['String'];
}

export interface CartShippingAddresses {
  __typename?: 'CartShippingAddresses';
  availableShippingMethods?: Maybe<Array<Maybe<AvailableShippingMethod>>>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<CartAddressCountry>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  region?: Maybe<CartAddressRegion>;
  selectedShippingMethod?: Maybe<CartSelectedShippingMethod>;
  street?: Maybe<Array<Maybe<Scalars['String']>>>;
  telephone?: Maybe<Scalars['String']>;
}

export interface CartTotals {
  __typename?: 'CartTotals';
  discounts?: Maybe<Array<Maybe<CartMoney>>>;
  grand?: Maybe<CartMoneyOnly>;
  shipping?: Maybe<CartMoney>;
  shippings?: Maybe<Array<Maybe<CartMoney>>>;
  sub?: Maybe<CartMoneyOnly>;
  subTax?: Maybe<CartMoneyOnly>;
  tax?: Maybe<CartMoneyOnly>;
  taxes?: Maybe<Array<Maybe<CartMoney>>>;
}

export interface CartUpdateItem {
  cartItemId: Scalars['ID'];
  cartItemUid?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
}

export interface CartUpdateItemInput {
  cartId: Scalars['String'];
  cartItems: Array<CartUpdateItem>;
}

export interface Catalog {
  __typename?: 'Catalog';
  items?: Maybe<Array<Maybe<Product>>>;
  pagination?: Maybe<Pagination>;
}

export interface CatalogQueryFilterInput {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

export interface CatalogQueryInput {
  categoryId?: InputMaybe<Scalars['ID']>;
  filters?: InputMaybe<Array<InputMaybe<CatalogQueryFilterInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<CatalogQuerySortInput>>>;
}

export interface CatalogQuerySortInput {
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
}

export enum CatalogQuerySortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export interface CategoriesQueryInput {
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parent?: InputMaybe<Scalars['String']>;
}

export interface Category {
  __typename?: 'Category';
  breadcrumbs?: Maybe<Array<Maybe<Breadcrumb>>>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
}

export interface CategoryQueryInput {
  id?: InputMaybe<Scalars['ID']>;
}

export interface CheckoutOrderDetails {
  __typename?: 'CheckoutOrderDetails';
  order_number?: Maybe<Scalars['String']>;
}

export interface Cms {
  __typename?: 'Cms';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  layout?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}

export interface CmsBlock {
  __typename?: 'CmsBlock';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}

export interface CmsBlocksOutput {
  __typename?: 'CmsBlocksOutput';
  items?: Maybe<Array<Maybe<CmsBlock>>>;
}

export interface CmsRoute {
  __typename?: 'CmsRoute';
  id?: Maybe<Scalars['ID']>;
  redirectCode?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<CmsRouteType>;
  url?: Maybe<Scalars['String']>;
}

export enum CmsRouteType {
  Category = 'CATEGORY',
  CmsPage = 'CMS_PAGE',
  Product = 'PRODUCT'
}

export interface Country {
  __typename?: 'Country';
  availableRegions?: Maybe<Array<Maybe<Region>>>;
  fullNameEnglish?: Maybe<Scalars['String']>;
  fullNameLocale?: Maybe<Scalars['String']>;
  /** The unique ID for a `Country` object. */
  id?: Maybe<Scalars['ID']>;
  threeLetterAbbreviation?: Maybe<Scalars['String']>;
  twoLetterAbbreviation?: Maybe<Scalars['String']>;
}

export interface CreateMeshPaymentUrlOutput {
  __typename?: 'CreateMeshPaymentUrlOutput';
  redirect_url?: Maybe<Scalars['String']>;
}

export interface CreatePaypalExpressTokenOutput {
  __typename?: 'CreatePaypalExpressTokenOutput';
  paypalUrls?: Maybe<PaypalExpressUrlList>;
  token?: Maybe<Scalars['String']>;
}

export interface Customer {
  __typename?: 'Customer';
  addresses?: Maybe<Array<Maybe<CustomerAddress>>>;
  dateOfBirth?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  isSubscribed?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
}

export interface CustomerAddress {
  __typename?: 'CustomerAddress';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  defaultBilling?: Maybe<Scalars['Boolean']>;
  defaultShipping?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<CustomerAddressRegion>;
  street?: Maybe<Array<Maybe<Scalars['String']>>>;
  telephone?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
}

export interface CustomerAddressInput {
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  defaultBilling?: InputMaybe<Scalars['Boolean']>;
  defaultShipping?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<CustomerAddressRegionInput>;
  street?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  telephone?: InputMaybe<Scalars['String']>;
  vatId?: InputMaybe<Scalars['String']>;
}

export interface CustomerAddressRegion {
  __typename?: 'CustomerAddressRegion';
  region?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['Int']>;
}

export interface CustomerAddressRegionInput {
  region?: InputMaybe<Scalars['String']>;
  regionCode?: InputMaybe<Scalars['String']>;
  regionId?: InputMaybe<Scalars['Int']>;
}

export interface CustomerOrder {
  __typename?: 'CustomerOrder';
  billingAddress?: Maybe<CustomerOrderAddress>;
  date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<CustomerOrderPaymentMethod>;
  products?: Maybe<Array<Maybe<CustomerOrderProduct>>>;
  shippingAddress?: Maybe<CustomerOrderAddress>;
  shippingAmount?: Maybe<Scalars['Float']>;
  shippingMethod?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  totals?: Maybe<CustomerOrderTotals>;
}

export interface CustomerOrderAddress {
  __typename?: 'CustomerOrderAddress';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['ID']>;
  street?: Maybe<Array<Maybe<Scalars['String']>>>;
  telephone?: Maybe<Scalars['String']>;
}

export interface CustomerOrderDiscount {
  __typename?: 'CustomerOrderDiscount';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface CustomerOrderPaymentMethod {
  __typename?: 'CustomerOrderPaymentMethod';
  additionalData?: Maybe<KeyValue>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
}

export interface CustomerOrderProduct {
  __typename?: 'CustomerOrderProduct';
  discounts?: Maybe<Array<Maybe<CustomerOrderDiscount>>>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<CustomerOrderProductOption>>>;
  price?: Maybe<Money>;
  productData?: Maybe<CustomerOrderProductData>;
  quantityOrdered?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
}

export interface CustomerOrderProductData {
  __typename?: 'CustomerOrderProductData';
  brand?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  prices?: Maybe<CustomerOrderProductPrice>;
}

export interface CustomerOrderProductOption {
  __typename?: 'CustomerOrderProductOption';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface CustomerOrderProductPrice {
  __typename?: 'CustomerOrderProductPrice';
  finalPrice?: Maybe<Money>;
  originalPrice?: Maybe<Money>;
  totalRow?: Maybe<Money>;
}

export interface CustomerOrderTotals {
  __typename?: 'CustomerOrderTotals';
  discounts?: Maybe<Array<Maybe<CustomerOrderDiscount>>>;
  subtotal?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Array<Maybe<CustomerOrderTotalsTax>>>;
  total?: Maybe<Scalars['Int']>;
  totalShipping?: Maybe<Scalars['Float']>;
}

export interface CustomerOrderTotalsTax {
  __typename?: 'CustomerOrderTotalsTax';
  rate?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
}

export interface CustomerOrdersInput {
  number?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
}

export interface CustomerOrdersOutput {
  __typename?: 'CustomerOrdersOutput';
  items?: Maybe<Array<Maybe<CustomerOrder>>>;
  pagination?: Maybe<Pagination>;
}

export interface CustomerOrdersPageInfo {
  __typename?: 'CustomerOrdersPageInfo';
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
}

export interface CustomerUpdateInput {
  dateOfBirth?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['Int']>;
  isSubscribed?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
}

export interface DeleteCustomerAccountOutput {
  __typename?: 'DeleteCustomerAccountOutput';
  errorMessage?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

export interface Filter {
  __typename?: 'Filter';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  items?: Maybe<Array<Maybe<FilterItem>>>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<FilterType>;
}

export interface FilterItem {
  __typename?: 'FilterItem';
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  swatchData?: Maybe<FilterSwatchData>;
  value?: Maybe<Scalars['String']>;
}

export interface FilterSwatchData {
  __typename?: 'FilterSwatchData';
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export enum FilterType {
  Checkbox = 'Checkbox',
  Price = 'Price',
  Boolean = 'boolean'
}

export interface FiltersQueryInput {
  categoryId?: InputMaybe<Scalars['ID']>;
  filters?: InputMaybe<Array<InputMaybe<ProductAttributeFilterInput>>>;
  search?: InputMaybe<Scalars['String']>;
}

export interface IProductOption {
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  kind?: Maybe<ProductOptionKind>;
  name?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  values?: Maybe<Array<Maybe<ProductOptionValue>>>;
}

export interface KeyValue {
  __typename?: 'KeyValue';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface LiveSearchCategory {
  __typename?: 'LiveSearchCategory';
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productCount?: Maybe<Scalars['Int']>;
}

export interface LiveSearchResult {
  __typename?: 'LiveSearchResult';
  categories?: Maybe<LiveSearchCategory>;
  products?: Maybe<ProductsInLiveSearch>;
  suggestions?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface Money {
  __typename?: 'Money';
  currency?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
}

export interface Mutation {
  __typename?: 'Mutation';
  addProductsToWishlist?: Maybe<WishlistWithErrors>;
  cartAddItem?: Maybe<Cart>;
  cartApplyCouponCode?: Maybe<Cart>;
  cartAssignToCustomer?: Maybe<Cart>;
  cartGenerateId?: Maybe<Scalars['String']>;
  cartRemoveCouponCode?: Maybe<Cart>;
  cartRemoveItem?: Maybe<Cart>;
  cartReorderItems?: Maybe<CartReorderItemsOutput>;
  cartSetBillingAddress?: Maybe<Cart>;
  cartSetGuestEmail?: Maybe<Cart>;
  cartSetPaymentMethod?: Maybe<Cart>;
  cartSetShippingAddress?: Maybe<Cart>;
  cartSetShippingMethods?: Maybe<Cart>;
  cartUpdateItem?: Maybe<Cart>;
  changePassword?: Maybe<Customer>;
  createCustomerAddress?: Maybe<CustomerAddress>;
  createMeshPaymentUrl?: Maybe<CreateMeshPaymentUrlOutput>;
  createPaypalExpressToken?: Maybe<CreatePaypalExpressTokenOutput>;
  deleteCustomerAccount?: Maybe<DeleteCustomerAccountOutput>;
  deleteCustomerAddress?: Maybe<Scalars['Boolean']>;
  login?: Maybe<AccessToken>;
  mergeWishlists?: Maybe<WishlistWithErrors>;
  placeOrder?: Maybe<PlaceOrderOutput>;
  register?: Maybe<AccessToken>;
  removeProductsFromWishlist?: Maybe<WishlistWithErrors>;
  requestOTPAuth?: Maybe<RequestOtpOutput>;
  requestOTPReset?: Maybe<RequestOtpOutput>;
  reset?: Maybe<ResetToken>;
  resetRequest?: Maybe<ResetRequestOutput>;
  sagePayDeleteToken?: Maybe<SagePayDeleteTokenOutput>;
  sendDeleteAccountMessage?: Maybe<SendDeleteAccountMessage>;
  shareWishlist?: Maybe<WishlistShareOutput>;
  updateCustomer?: Maybe<Customer>;
  updateCustomerAddress?: Maybe<CustomerAddress>;
  updateEmail?: Maybe<Customer>;
  verifyOTPAuth?: Maybe<VerifyOtpRequestOutput>;
  verifyOTPReset?: Maybe<VerifyOtpRequestOutput>;
}


export interface MutationAddProductsToWishlistArgs {
  id: Scalars['ID'];
  items: Array<WishlistItemInput>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}


export interface MutationCartAddItemArgs {
  cartId: Scalars['String'];
  cartItems: Array<CartItem>;
}


export interface MutationCartApplyCouponCodeArgs {
  code: Scalars['String'];
}


export interface MutationCartAssignToCustomerArgs {
  input?: InputMaybe<CartAssignToCustomerInput>;
}


export interface MutationCartRemoveItemArgs {
  input?: InputMaybe<CartRemoveItemInput>;
}


export interface MutationCartReorderItemsArgs {
  orderNumber?: InputMaybe<Scalars['String']>;
}


export interface MutationCartSetBillingAddressArgs {
  input?: InputMaybe<SetBillingAddressOnCartInput>;
}


export interface MutationCartSetGuestEmailArgs {
  input?: InputMaybe<CartSetGuestEmailInput>;
}


export interface MutationCartSetPaymentMethodArgs {
  input?: InputMaybe<SetPaymentMethodOnCartInput>;
}


export interface MutationCartSetShippingAddressArgs {
  input?: InputMaybe<SetShippingAddressOnCartInput>;
}


export interface MutationCartSetShippingMethodsArgs {
  input?: InputMaybe<SetShippingMethodsOnCartInput>;
}


export interface MutationCartUpdateItemArgs {
  input?: InputMaybe<CartUpdateItemInput>;
}


export interface MutationChangePasswordArgs {
  currentPassword?: InputMaybe<Scalars['String']>;
  newPassword?: InputMaybe<Scalars['String']>;
}


export interface MutationCreateCustomerAddressArgs {
  input?: InputMaybe<CustomerAddressInput>;
}


export interface MutationCreateMeshPaymentUrlArgs {
  order_number: Scalars['String'];
}


export interface MutationCreatePaypalExpressTokenArgs {
  input: PaypalExpressTokenInput;
}


export interface MutationDeleteCustomerAccountArgs {
  deleteCode?: InputMaybe<Scalars['String']>;
}


export interface MutationDeleteCustomerAddressArgs {
  id?: InputMaybe<Scalars['Int']>;
}


export interface MutationLoginArgs {
  email?: InputMaybe<Scalars['String']>;
  isShouldIgnoreToken?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
}


export interface MutationMergeWishlistsArgs {
  id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}


export interface MutationPlaceOrderArgs {
  input?: InputMaybe<PlaceOrderInput>;
}


export interface MutationRegisterArgs {
  input?: InputMaybe<RegisterInput>;
}


export interface MutationRemoveProductsFromWishlistArgs {
  id: Scalars['ID'];
  items: Array<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}


export interface MutationRequestOtpAuthArgs {
  email?: InputMaybe<Scalars['String']>;
}


export interface MutationRequestOtpResetArgs {
  email?: InputMaybe<Scalars['String']>;
}


export interface MutationResetArgs {
  code?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
}


export interface MutationResetRequestArgs {
  email?: InputMaybe<Scalars['String']>;
}


export interface MutationSagePayDeleteTokenArgs {
  tokenId?: InputMaybe<Scalars['String']>;
}


export interface MutationShareWishlistArgs {
  emails: Array<InputMaybe<Scalars['String']>>;
  id: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
}


export interface MutationUpdateCustomerArgs {
  input?: InputMaybe<CustomerUpdateInput>;
}


export interface MutationUpdateCustomerAddressArgs {
  id?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<UpdateCustomerAddressInput>;
}


export interface MutationUpdateEmailArgs {
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
}


export interface MutationVerifyOtpAuthArgs {
  code?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
}


export interface MutationVerifyOtpResetArgs {
  code?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
}

export interface NextUrlData {
  __typename?: 'NextUrlData';
  data?: Maybe<NextUrlInfo>;
  status?: Maybe<Scalars['Int']>;
}

export interface NextUrlInfo {
  __typename?: 'NextUrlInfo';
  VPSTxId?: Maybe<Scalars['String']>;
  nextURL?: Maybe<Scalars['String']>;
  securityKey?: Maybe<Scalars['String']>;
  vendorName?: Maybe<Scalars['String']>;
}

export interface Pagination {
  __typename?: 'Pagination';
  count?: Maybe<Scalars['Int']>;
  current?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
}

export interface PaymentMethodInput {
  code: Scalars['String'];
  extraInfo?: InputMaybe<Scalars['Any']>;
}

export interface PaypalExpressTokenInput {
  code: Scalars['String'];
  expressButton?: InputMaybe<Scalars['Boolean']>;
  urls?: InputMaybe<PaypalExpressUrlsInput>;
  usePaypalCredit?: InputMaybe<Scalars['Boolean']>;
}

export interface PaypalExpressUrlList {
  __typename?: 'PaypalExpressUrlList';
  edit?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
}

export interface PaypalExpressUrlsInput {
  cancelUrl: Scalars['String'];
  pendingUrl?: InputMaybe<Scalars['String']>;
  returnUrl: Scalars['String'];
  successUrl?: InputMaybe<Scalars['String']>;
}

export interface PlaceOrderInput {
  cart_id?: InputMaybe<Scalars['String']>;
  order_comment?: InputMaybe<Scalars['String']>;
}

export interface PlaceOrderOutput {
  __typename?: 'PlaceOrderOutput';
  order?: Maybe<CheckoutOrderDetails>;
}

export interface Product {
  __typename?: 'Product';
  breadcrumbs?: Maybe<Array<Maybe<Breadcrumb>>>;
  configuredSiblingSku?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Maybe<ProductImage>>>;
  labels?: Maybe<Array<Maybe<ProductLabel>>>;
  metaDescription?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<ProductOptionUnion>>>;
  parentSku?: Maybe<Scalars['String']>;
  price?: Maybe<ProductPrice>;
  shortDescription?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  stock?: Maybe<ProductStock>;
  thumbnail?: Maybe<ProductImage>;
  type?: Maybe<ProductTypes>;
  url?: Maybe<Scalars['String']>;
  variants?: Maybe<Array<Maybe<ProductVariant>>>;
  vat?: Maybe<ProductVat>;
}

export interface ProductAttributeFilterInput {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

export enum ProductConfigurableOptionType {
  Color = 'Color',
  Image = 'Image',
  Text = 'Text'
}

export interface ProductImage {
  __typename?: 'ProductImage';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
}

export interface ProductLabel {
  __typename?: 'ProductLabel';
  backgroundColor?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
}

export interface ProductLabels {
  __typename?: 'ProductLabels';
  addToCart?: Maybe<Scalars['String']>;
}

export interface ProductOption extends IProductOption {
  __typename?: 'ProductOption';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  kind?: Maybe<ProductOptionKind>;
  name?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ProductOptionType>;
  values?: Maybe<Array<Maybe<ProductOptionValue>>>;
}

export interface ProductOptionConfigurable extends IProductOption {
  __typename?: 'ProductOptionConfigurable';
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  kind?: Maybe<ProductOptionKind>;
  name?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ProductConfigurableOptionType>;
  values?: Maybe<Array<Maybe<ProductOptionValue>>>;
}

export enum ProductOptionKind {
  Configurable = 'Configurable',
  Customizable = 'Customizable'
}

export enum ProductOptionType {
  Area = 'Area',
  Checkbox = 'Checkbox',
  Date = 'Date',
  DropDown = 'DropDown',
  Field = 'Field',
  File = 'File',
  Multiple = 'Multiple',
  Radio = 'Radio'
}

export type ProductOptionUnion = ProductOption | ProductOptionConfigurable;

export interface ProductOptionValue {
  __typename?: 'ProductOptionValue';
  colorValue?: Maybe<Scalars['String']>;
  isInStock?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Money>;
  url?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface ProductPrice {
  __typename?: 'ProductPrice';
  /**  @deprecated  badges is deprecated field. try use [ProductLabel] of product instead  */
  badges?: Maybe<Array<Maybe<ProductPriceBadge>>>;
  current?: Maybe<Money>;
  discount?: Maybe<Scalars['Float']>;
  initial?: Maybe<Money>;
  isRange?: Maybe<Scalars['Boolean']>;
}

export interface ProductPriceBadge {
  __typename?: 'ProductPriceBadge';
  name?: Maybe<Scalars['String']>;
}

export interface ProductQueryInput {
  external?: InputMaybe<Array<InputMaybe<ProductQueryInputExternal>>>;
  id?: InputMaybe<Scalars['ID']>;
  sku?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
}

export interface ProductQueryInputExternal {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Any']>;
}

export enum ProductRelationType {
  CrossSell = 'CROSS_SELL',
  Related = 'RELATED',
  Similar = 'SIMILAR',
  UpSell = 'UP_SELL'
}

export interface ProductRelationsQueryInput {
  external?: InputMaybe<Array<InputMaybe<ProductQueryInputExternal>>>;
  id?: InputMaybe<Scalars['ID']>;
  sku?: InputMaybe<Scalars['String']>;
  type: ProductRelationType;
  url?: InputMaybe<Scalars['String']>;
}

export interface ProductStock {
  __typename?: 'ProductStock';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ProductStockType>;
}

export enum ProductStockType {
  InStock = 'IN_STOCK',
  OutOfStock = 'OUT_OF_STOCK'
}

export enum ProductTypes {
  BundleProduct = 'BundleProduct',
  ConfigurableProduct = 'ConfigurableProduct',
  DownloadableProduct = 'DownloadableProduct',
  GroupedProduct = 'GroupedProduct',
  SimpleProduct = 'SimpleProduct',
  VirtualProduct = 'VirtualProduct'
}

export interface ProductVariant {
  __typename?: 'ProductVariant';
  options?: Maybe<Array<Maybe<ProductVariantOption>>>;
  product?: Maybe<Product>;
}

export interface ProductVariantOption {
  __typename?: 'ProductVariantOption';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface ProductVat {
  __typename?: 'ProductVat';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ProductVatType>;
}

export enum ProductVatType {
  Excluded = 'EXCLUDED',
  Included = 'INCLUDED'
}

export interface ProductsInLiveSearch {
  __typename?: 'ProductsInLiveSearch';
  items?: Maybe<Array<Maybe<Product>>>;
  pageInfo?: Maybe<SearchResultPageInfo>;
}

export interface ProductsQueryInput {
  external?: InputMaybe<Array<InputMaybe<ProductQueryInputExternal>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  sku?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

export interface ProductsQueryOutput {
  __typename?: 'ProductsQueryOutput';
  items?: Maybe<Array<Maybe<Product>>>;
  pagination?: Maybe<Pagination>;
}

export interface Query {
  __typename?: 'Query';
  Countries?: Maybe<Array<Maybe<Country>>>;
  cart?: Maybe<Cart>;
  cartRecommendedProducts?: Maybe<Array<Maybe<Product>>>;
  catalog?: Maybe<Catalog>;
  categories?: Maybe<Array<Maybe<Category>>>;
  category?: Maybe<Category>;
  checkIsEmailAvailable?: Maybe<CheckIsEmailAvailableOutput>;
  cms?: Maybe<Cms>;
  cmsBlocks?: Maybe<CmsBlocksOutput>;
  cmsRoute?: Maybe<CmsRoute>;
  customer?: Maybe<Customer>;
  customerAddresses?: Maybe<Array<Maybe<CustomerAddress>>>;
  customerOrder?: Maybe<CustomerOrder>;
  customerOrderLast?: Maybe<CustomerOrder>;
  customerOrders?: Maybe<CustomerOrdersOutput>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  getStripePaymentIntentSecret?: Maybe<StripePaymentIntentSecretOutput>;
  liveSearch?: Maybe<LiveSearchResult>;
  paymentMethodSuccessMessages?: Maybe<CmsBlock>;
  product?: Maybe<Product>;
  productRelations?: Maybe<Array<Maybe<Product>>>;
  products?: Maybe<ProductsQueryOutput>;
  sagePayGetNextUrl?: Maybe<SagePayGetNextUrlOutput>;
  sagePayGetTokens?: Maybe<SagePayGetTokensOutput>;
  sharedWishlist?: Maybe<Wishlist>;
  sorts?: Maybe<SortsQueryOutput>;
  storeConfig?: Maybe<StoreConfig>;
  wishlist?: Maybe<Wishlist>;
}


export interface QueryCartArgs {
  cartId?: InputMaybe<Scalars['String']>;
  isFull?: InputMaybe<Scalars['Boolean']>;
}


export interface QueryCartRecommendedProductsArgs {
  cartId?: InputMaybe<Scalars['ID']>;
}


export interface QueryCatalogArgs {
  input?: InputMaybe<CatalogQueryInput>;
}


export interface QueryCategoriesArgs {
  input?: InputMaybe<CategoriesQueryInput>;
}


export interface QueryCategoryArgs {
  input?: InputMaybe<CategoryQueryInput>;
}


export interface QueryCheckIsEmailAvailableArgs {
  email?: InputMaybe<Scalars['String']>;
}


export interface QueryCmsArgs {
  id?: InputMaybe<Scalars['ID']>;
}


export interface QueryCmsBlocksArgs {
  identifiers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}


export interface QueryCmsRouteArgs {
  url?: InputMaybe<Scalars['String']>;
}


export interface QueryCustomerOrderArgs {
  id?: InputMaybe<Scalars['ID']>;
}


export interface QueryCustomerOrdersArgs {
  input?: InputMaybe<CustomerOrdersInput>;
}


export interface QueryFiltersArgs {
  input?: InputMaybe<FiltersQueryInput>;
}


export interface QueryGetStripePaymentIntentSecretArgs {
  cartId: Scalars['String'];
}


export interface QueryLiveSearchArgs {
  pageSize?: InputMaybe<Scalars['Int']>;
  search: Scalars['String'];
}


export interface QueryPaymentMethodSuccessMessagesArgs {
  payment_code?: InputMaybe<Scalars['String']>;
}


export interface QueryProductArgs {
  input?: InputMaybe<ProductQueryInput>;
}


export interface QueryProductRelationsArgs {
  input: ProductRelationsQueryInput;
}


export interface QueryProductsArgs {
  input?: InputMaybe<ProductsQueryInput>;
}


export interface QuerySagePayGetNextUrlArgs {
  input?: InputMaybe<SagePayGetNextUrlInput>;
}


export interface QuerySharedWishlistArgs {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sharingCode: Scalars['String'];
}


export interface QueryWishlistArgs {
  id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}

export interface Region {
  __typename?: 'Region';
  code?: Maybe<Scalars['String']>;
  /** The unique ID for a `Region` object. */
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
}

export interface RegisterInput {
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  middlename?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
}

export interface ReorderError {
  __typename?: 'ReorderError';
  code: ReorderErrorCodes;
  message: Scalars['String'];
  path: Array<Maybe<Scalars['String']>>;
}

export enum ReorderErrorCodes {
  InsufficientStock = 'INSUFFICIENT_STOCK',
  NotSalable = 'NOT_SALABLE',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  ReorderNotAvailable = 'REORDER_NOT_AVAILABLE',
  Undefined = 'UNDEFINED'
}

export interface RequestOtpOutput {
  __typename?: 'RequestOTPOutput';
  email?: Maybe<Scalars['String']>;
}

export interface ResetRequestOutput {
  __typename?: 'ResetRequestOutput';
  email?: Maybe<Scalars['String']>;
}

export interface ResetToken {
  __typename?: 'ResetToken';
  attempts?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
}

export interface SagePayDeleteTokenOutput {
  __typename?: 'SagePayDeleteTokenOutput';
  tokenRemovalStatus?: Maybe<Scalars['Boolean']>;
}

export interface SagePayGetNextUrlInput {
  saveToken?: InputMaybe<Scalars['Boolean']>;
  tokenId?: InputMaybe<Scalars['String']>;
}

export interface SagePayGetNextUrlOutput {
  __typename?: 'SagePayGetNextUrlOutput';
  data?: Maybe<NextUrlData>;
  errors?: Maybe<Scalars['String']>;
}

export interface SagePayGetTokensOutput {
  __typename?: 'SagePayGetTokensOutput';
  tokens?: Maybe<Array<Maybe<SagePayToken>>>;
  tokensEnabled?: Maybe<Scalars['String']>;
}

export interface SagePayToken {
  __typename?: 'SagePayToken';
  ccExpMonth?: Maybe<Scalars['String']>;
  ccExpYear?: Maybe<Scalars['String']>;
  ccLast4?: Maybe<Scalars['String']>;
  ccType?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
}

export interface SearchResultPageInfo {
  __typename?: 'SearchResultPageInfo';
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
}

export interface SendDeleteAccountMessage {
  __typename?: 'SendDeleteAccountMessage';
  errorMessage?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
}

export interface SetBillingAddressOnCartInput {
  billingAddress?: InputMaybe<BillingAddressInput>;
}

export interface SetPaymentMethodOnCartInput {
  paymentMethod?: InputMaybe<PaymentMethodInput>;
}

export interface SetShippingAddressOnCartInput {
  shippingAddresses?: InputMaybe<Array<InputMaybe<ShippingAddressesInput>>>;
}

export interface SetShippingMethodsOnCartInput {
  shippingMethods?: InputMaybe<Array<InputMaybe<ShippingMethodsInput>>>;
}

export interface ShippingAddressesInput {
  address?: InputMaybe<CartAddressInput>;
  customerAddressId?: InputMaybe<Scalars['Int']>;
  customerNotes?: InputMaybe<Scalars['String']>;
  pickupLocationCode?: InputMaybe<Scalars['String']>;
}

export interface ShippingMethodsInput {
  carrierCode?: InputMaybe<Scalars['String']>;
  methodCode?: InputMaybe<Scalars['String']>;
  shippingAdditionalFields?: InputMaybe<Array<InputMaybe<AdditionalField>>>;
}

export interface Sort {
  __typename?: 'Sort';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

export interface SortsQueryOutput {
  __typename?: 'SortsQueryOutput';
  default?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<Sort>>>;
}

export interface StoreConfig {
  __typename?: 'StoreConfig';
  baseCurrencyCode?: Maybe<Scalars['String']>;
  defaultTitle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
  logoHeight?: Maybe<Scalars['Int']>;
  logoWidth?: Maybe<Scalars['Int']>;
  maxAddressLineLength?: Maybe<Scalars['Int']>;
  maxNameLength?: Maybe<Scalars['Int']>;
  passwordMinLength?: Maybe<Scalars['Int']>;
  passwordMinStrength?: Maybe<Scalars['Int']>;
  productReturns?: Maybe<Scalars['String']>;
  productReviewsEnabled?: Maybe<Scalars['Boolean']>;
  termsOfDelivery?: Maybe<Scalars['String']>;
}

export interface StripePaymentIntentSecretOutput {
  __typename?: 'StripePaymentIntentSecretOutput';
  secret?: Maybe<Scalars['String']>;
}

export interface UpdateCustomerAddressInput {
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  defaultBilling?: InputMaybe<Scalars['Boolean']>;
  defaultShipping?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<CustomerAddressRegionInput>;
  street?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  telephone?: InputMaybe<Scalars['String']>;
  vatId?: InputMaybe<Scalars['String']>;
}

export interface VerifyOtpRequestOutput {
  __typename?: 'VerifyOTPRequestOutput';
  attemptsLeft?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
}

export interface Wishlist {
  __typename?: 'Wishlist';
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  items?: Maybe<WishlistItemsOutput>;
  sharingCode?: Maybe<Scalars['String']>;
}


export interface WishlistItemsArgs {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}

export interface WishlistItem {
  __typename?: 'WishlistItem';
  addedAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  product?: Maybe<Product>;
}

export interface WishlistItemInput {
  quantity: Scalars['Float'];
  sku: Scalars['String'];
}

export interface WishlistItemsOutput {
  __typename?: 'WishlistItemsOutput';
  items?: Maybe<Array<Maybe<WishlistItem>>>;
  pagination?: Maybe<Pagination>;
}

export interface WishlistShareOutput {
  __typename?: 'WishlistShareOutput';
  status?: Maybe<Scalars['String']>;
}

export interface WishlistUserInputError {
  __typename?: 'WishlistUserInputError';
  code: WishlistUserInputErrorType;
  message?: Maybe<Scalars['String']>;
}

export enum WishlistUserInputErrorType {
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  Undefined = 'UNDEFINED'
}

export interface WishlistWithErrors {
  __typename?: 'WishlistWithErrors';
  userErrors?: Maybe<Array<Maybe<WishlistUserInputError>>>;
  wishlist?: Maybe<Wishlist>;
}

export interface CheckIsEmailAvailableOutput {
  __typename?: 'checkIsEmailAvailableOutput';
  isEmailAvailable?: Maybe<Scalars['Boolean']>;
}



export type ResolverTypeWrapper<T> = Promise<ResolverTypeWrapperSignatureContext & T> | (ResolverTypeWrapperSignatureContext & T);


export interface ResolverWithResolve<TResult, TParent, TContext, TArgs> {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export interface ResolversTypes {
  AccessToken: ResolverTypeWrapper<AccessToken>;
  AdditionalField: AdditionalField;
  Any: ResolverTypeWrapper<Scalars['Any']>;
  AvailableShippingMethod: ResolverTypeWrapper<AvailableShippingMethod>;
  BillingAddressInput: BillingAddressInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Breadcrumb: ResolverTypeWrapper<Breadcrumb>;
  BreadcrumbUrl: ResolverTypeWrapper<BreadcrumbUrl>;
  BreadcrumbUrlPossibleType: BreadcrumbUrlPossibleType;
  Cart: ResolverTypeWrapper<Cart>;
  CartAddressCountry: ResolverTypeWrapper<CartAddressCountry>;
  CartAddressInput: CartAddressInput;
  CartAddressRegion: ResolverTypeWrapper<CartAddressRegion>;
  CartAmount: ResolverTypeWrapper<CartAmount>;
  CartAssignToCustomerInput: CartAssignToCustomerInput;
  CartAvailablePaymentMethods: ResolverTypeWrapper<CartAvailablePaymentMethods>;
  CartBillingAddress: ResolverTypeWrapper<CartBillingAddress>;
  CartCustomizableOptionsValue: ResolverTypeWrapper<CartCustomizableOptionsValue>;
  CartItem: CartItem;
  CartItemCustomizableOptions: ResolverTypeWrapper<CartItemCustomizableOptions>;
  CartItemCustomizableOptionsType: CartItemCustomizableOptionsType;
  CartItemPrices: ResolverTypeWrapper<CartItemPrices>;
  CartItemSelectedConfigurableOptions: ResolverTypeWrapper<CartItemSelectedConfigurableOptions>;
  CartItemTypes: CartItemTypes;
  CartItems: ResolverTypeWrapper<CartItems>;
  CartMoney: ResolverTypeWrapper<CartMoney>;
  CartMoneyOnly: ResolverTypeWrapper<CartMoneyOnly>;
  CartPrices: ResolverTypeWrapper<CartPrices>;
  CartRemoveItemInput: CartRemoveItemInput;
  CartReorderItemsOutput: ResolverTypeWrapper<CartReorderItemsOutput>;
  CartSelectedPaymentMethod: ResolverTypeWrapper<CartSelectedPaymentMethod>;
  CartSelectedShippingMethod: ResolverTypeWrapper<CartSelectedShippingMethod>;
  CartSetGuestEmailInput: CartSetGuestEmailInput;
  CartShippingAddresses: ResolverTypeWrapper<CartShippingAddresses>;
  CartTotals: ResolverTypeWrapper<CartTotals>;
  CartUpdateItem: CartUpdateItem;
  CartUpdateItemInput: CartUpdateItemInput;
  Catalog: ResolverTypeWrapper<Catalog>;
  CatalogQueryFilterInput: CatalogQueryFilterInput;
  CatalogQueryInput: CatalogQueryInput;
  CatalogQuerySortInput: CatalogQuerySortInput;
  CatalogQuerySortOrder: CatalogQuerySortOrder;
  CategoriesQueryInput: CategoriesQueryInput;
  Category: ResolverTypeWrapper<Category>;
  CategoryQueryInput: CategoryQueryInput;
  CheckoutOrderDetails: ResolverTypeWrapper<CheckoutOrderDetails>;
  Cms: ResolverTypeWrapper<Cms>;
  CmsBlock: ResolverTypeWrapper<CmsBlock>;
  CmsBlocksOutput: ResolverTypeWrapper<CmsBlocksOutput>;
  CmsRoute: ResolverTypeWrapper<CmsRoute>;
  CmsRouteType: CmsRouteType;
  Country: ResolverTypeWrapper<Country>;
  CreateMeshPaymentUrlOutput: ResolverTypeWrapper<CreateMeshPaymentUrlOutput>;
  CreatePaypalExpressTokenOutput: ResolverTypeWrapper<CreatePaypalExpressTokenOutput>;
  Customer: ResolverTypeWrapper<Customer>;
  CustomerAddress: ResolverTypeWrapper<CustomerAddress>;
  CustomerAddressInput: CustomerAddressInput;
  CustomerAddressRegion: ResolverTypeWrapper<CustomerAddressRegion>;
  CustomerAddressRegionInput: CustomerAddressRegionInput;
  CustomerOrder: ResolverTypeWrapper<CustomerOrder>;
  CustomerOrderAddress: ResolverTypeWrapper<CustomerOrderAddress>;
  CustomerOrderDiscount: ResolverTypeWrapper<CustomerOrderDiscount>;
  CustomerOrderPaymentMethod: ResolverTypeWrapper<CustomerOrderPaymentMethod>;
  CustomerOrderProduct: ResolverTypeWrapper<CustomerOrderProduct>;
  CustomerOrderProductData: ResolverTypeWrapper<CustomerOrderProductData>;
  CustomerOrderProductOption: ResolverTypeWrapper<CustomerOrderProductOption>;
  CustomerOrderProductPrice: ResolverTypeWrapper<CustomerOrderProductPrice>;
  CustomerOrderTotals: ResolverTypeWrapper<CustomerOrderTotals>;
  CustomerOrderTotalsTax: ResolverTypeWrapper<CustomerOrderTotalsTax>;
  CustomerOrdersInput: CustomerOrdersInput;
  CustomerOrdersOutput: ResolverTypeWrapper<CustomerOrdersOutput>;
  CustomerOrdersPageInfo: ResolverTypeWrapper<CustomerOrdersPageInfo>;
  CustomerUpdateInput: CustomerUpdateInput;
  DeleteCustomerAccountOutput: ResolverTypeWrapper<DeleteCustomerAccountOutput>;
  Filter: ResolverTypeWrapper<Filter>;
  FilterItem: ResolverTypeWrapper<FilterItem>;
  FilterSwatchData: ResolverTypeWrapper<FilterSwatchData>;
  FilterType: FilterType;
  FiltersQueryInput: FiltersQueryInput;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  IProductOption: ResolversTypes['ProductOption'] | ResolversTypes['ProductOptionConfigurable'];
  Int: ResolverTypeWrapper<Scalars['Int']>;
  KeyValue: ResolverTypeWrapper<KeyValue>;
  LiveSearchCategory: ResolverTypeWrapper<LiveSearchCategory>;
  LiveSearchResult: ResolverTypeWrapper<LiveSearchResult>;
  Money: ResolverTypeWrapper<Money>;
  Mutation: ResolverTypeWrapper<{}>;
  NextUrlData: ResolverTypeWrapper<NextUrlData>;
  NextUrlInfo: ResolverTypeWrapper<NextUrlInfo>;
  Pagination: ResolverTypeWrapper<Pagination>;
  PaymentMethodInput: PaymentMethodInput;
  PaypalExpressTokenInput: PaypalExpressTokenInput;
  PaypalExpressUrlList: ResolverTypeWrapper<PaypalExpressUrlList>;
  PaypalExpressUrlsInput: PaypalExpressUrlsInput;
  PlaceOrderInput: PlaceOrderInput;
  PlaceOrderOutput: ResolverTypeWrapper<PlaceOrderOutput>;
  Product: ResolverTypeWrapper<Omit<Product, 'options'> & { options?: Maybe<Array<Maybe<ResolversTypes['ProductOptionUnion']>>> }>;
  ProductAttributeFilterInput: ProductAttributeFilterInput;
  ProductConfigurableOptionType: ProductConfigurableOptionType;
  ProductImage: ResolverTypeWrapper<ProductImage>;
  ProductLabel: ResolverTypeWrapper<ProductLabel>;
  ProductLabels: ResolverTypeWrapper<ProductLabels>;
  ProductOption: ResolverTypeWrapper<ProductOption>;
  ProductOptionConfigurable: ResolverTypeWrapper<ProductOptionConfigurable>;
  ProductOptionKind: ProductOptionKind;
  ProductOptionType: ProductOptionType;
  ProductOptionUnion: ResolversTypes['ProductOption'] | ResolversTypes['ProductOptionConfigurable'];
  ProductOptionValue: ResolverTypeWrapper<ProductOptionValue>;
  ProductPrice: ResolverTypeWrapper<ProductPrice>;
  ProductPriceBadge: ResolverTypeWrapper<ProductPriceBadge>;
  ProductQueryInput: ProductQueryInput;
  ProductQueryInputExternal: ProductQueryInputExternal;
  ProductRelationType: ProductRelationType;
  ProductRelationsQueryInput: ProductRelationsQueryInput;
  ProductStock: ResolverTypeWrapper<ProductStock>;
  ProductStockType: ProductStockType;
  ProductTypes: ProductTypes;
  ProductVariant: ResolverTypeWrapper<ProductVariant>;
  ProductVariantOption: ResolverTypeWrapper<ProductVariantOption>;
  ProductVat: ResolverTypeWrapper<ProductVat>;
  ProductVatType: ProductVatType;
  ProductsInLiveSearch: ResolverTypeWrapper<ProductsInLiveSearch>;
  ProductsQueryInput: ProductsQueryInput;
  ProductsQueryOutput: ResolverTypeWrapper<ProductsQueryOutput>;
  Query: ResolverTypeWrapper<{}>;
  Region: ResolverTypeWrapper<Region>;
  RegisterInput: RegisterInput;
  ReorderError: ResolverTypeWrapper<ReorderError>;
  ReorderErrorCodes: ReorderErrorCodes;
  RequestOTPOutput: ResolverTypeWrapper<RequestOtpOutput>;
  ResetRequestOutput: ResolverTypeWrapper<ResetRequestOutput>;
  ResetToken: ResolverTypeWrapper<ResetToken>;
  SagePayDeleteTokenOutput: ResolverTypeWrapper<SagePayDeleteTokenOutput>;
  SagePayGetNextUrlInput: SagePayGetNextUrlInput;
  SagePayGetNextUrlOutput: ResolverTypeWrapper<SagePayGetNextUrlOutput>;
  SagePayGetTokensOutput: ResolverTypeWrapper<SagePayGetTokensOutput>;
  SagePayToken: ResolverTypeWrapper<SagePayToken>;
  SearchResultPageInfo: ResolverTypeWrapper<SearchResultPageInfo>;
  SendDeleteAccountMessage: ResolverTypeWrapper<SendDeleteAccountMessage>;
  SetBillingAddressOnCartInput: SetBillingAddressOnCartInput;
  SetPaymentMethodOnCartInput: SetPaymentMethodOnCartInput;
  SetShippingAddressOnCartInput: SetShippingAddressOnCartInput;
  SetShippingMethodsOnCartInput: SetShippingMethodsOnCartInput;
  ShippingAddressesInput: ShippingAddressesInput;
  ShippingMethodsInput: ShippingMethodsInput;
  Sort: ResolverTypeWrapper<Sort>;
  SortsQueryOutput: ResolverTypeWrapper<SortsQueryOutput>;
  StoreConfig: ResolverTypeWrapper<StoreConfig>;
  String: ResolverTypeWrapper<Scalars['String']>;
  StripePaymentIntentSecretOutput: ResolverTypeWrapper<StripePaymentIntentSecretOutput>;
  UpdateCustomerAddressInput: UpdateCustomerAddressInput;
  VerifyOTPRequestOutput: ResolverTypeWrapper<VerifyOtpRequestOutput>;
  Wishlist: ResolverTypeWrapper<Wishlist>;
  WishlistItem: ResolverTypeWrapper<WishlistItem>;
  WishlistItemInput: WishlistItemInput;
  WishlistItemsOutput: ResolverTypeWrapper<WishlistItemsOutput>;
  WishlistShareOutput: ResolverTypeWrapper<WishlistShareOutput>;
  WishlistUserInputError: ResolverTypeWrapper<WishlistUserInputError>;
  WishlistUserInputErrorType: WishlistUserInputErrorType;
  WishlistWithErrors: ResolverTypeWrapper<WishlistWithErrors>;
  checkIsEmailAvailableOutput: ResolverTypeWrapper<CheckIsEmailAvailableOutput>;
};

/** Mapping between all available schema types and the resolvers parents */
export interface ResolversParentTypes {
  AccessToken: AccessToken;
  AdditionalField: AdditionalField;
  Any: Scalars['Any'];
  AvailableShippingMethod: AvailableShippingMethod;
  BillingAddressInput: BillingAddressInput;
  Boolean: Scalars['Boolean'];
  Breadcrumb: Breadcrumb;
  BreadcrumbUrl: BreadcrumbUrl;
  Cart: Cart;
  CartAddressCountry: CartAddressCountry;
  CartAddressInput: CartAddressInput;
  CartAddressRegion: CartAddressRegion;
  CartAmount: CartAmount;
  CartAssignToCustomerInput: CartAssignToCustomerInput;
  CartAvailablePaymentMethods: CartAvailablePaymentMethods;
  CartBillingAddress: CartBillingAddress;
  CartCustomizableOptionsValue: CartCustomizableOptionsValue;
  CartItem: CartItem;
  CartItemCustomizableOptions: CartItemCustomizableOptions;
  CartItemPrices: CartItemPrices;
  CartItemSelectedConfigurableOptions: CartItemSelectedConfigurableOptions;
  CartItems: CartItems;
  CartMoney: CartMoney;
  CartMoneyOnly: CartMoneyOnly;
  CartPrices: CartPrices;
  CartRemoveItemInput: CartRemoveItemInput;
  CartReorderItemsOutput: CartReorderItemsOutput;
  CartSelectedPaymentMethod: CartSelectedPaymentMethod;
  CartSelectedShippingMethod: CartSelectedShippingMethod;
  CartSetGuestEmailInput: CartSetGuestEmailInput;
  CartShippingAddresses: CartShippingAddresses;
  CartTotals: CartTotals;
  CartUpdateItem: CartUpdateItem;
  CartUpdateItemInput: CartUpdateItemInput;
  Catalog: Catalog;
  CatalogQueryFilterInput: CatalogQueryFilterInput;
  CatalogQueryInput: CatalogQueryInput;
  CatalogQuerySortInput: CatalogQuerySortInput;
  CategoriesQueryInput: CategoriesQueryInput;
  Category: Category;
  CategoryQueryInput: CategoryQueryInput;
  CheckoutOrderDetails: CheckoutOrderDetails;
  Cms: Cms;
  CmsBlock: CmsBlock;
  CmsBlocksOutput: CmsBlocksOutput;
  CmsRoute: CmsRoute;
  Country: Country;
  CreateMeshPaymentUrlOutput: CreateMeshPaymentUrlOutput;
  CreatePaypalExpressTokenOutput: CreatePaypalExpressTokenOutput;
  Customer: Customer;
  CustomerAddress: CustomerAddress;
  CustomerAddressInput: CustomerAddressInput;
  CustomerAddressRegion: CustomerAddressRegion;
  CustomerAddressRegionInput: CustomerAddressRegionInput;
  CustomerOrder: CustomerOrder;
  CustomerOrderAddress: CustomerOrderAddress;
  CustomerOrderDiscount: CustomerOrderDiscount;
  CustomerOrderPaymentMethod: CustomerOrderPaymentMethod;
  CustomerOrderProduct: CustomerOrderProduct;
  CustomerOrderProductData: CustomerOrderProductData;
  CustomerOrderProductOption: CustomerOrderProductOption;
  CustomerOrderProductPrice: CustomerOrderProductPrice;
  CustomerOrderTotals: CustomerOrderTotals;
  CustomerOrderTotalsTax: CustomerOrderTotalsTax;
  CustomerOrdersInput: CustomerOrdersInput;
  CustomerOrdersOutput: CustomerOrdersOutput;
  CustomerOrdersPageInfo: CustomerOrdersPageInfo;
  CustomerUpdateInput: CustomerUpdateInput;
  DeleteCustomerAccountOutput: DeleteCustomerAccountOutput;
  Filter: Filter;
  FilterItem: FilterItem;
  FilterSwatchData: FilterSwatchData;
  FiltersQueryInput: FiltersQueryInput;
  Float: Scalars['Float'];
  ID: Scalars['ID'];
  IProductOption: ResolversParentTypes['ProductOption'] | ResolversParentTypes['ProductOptionConfigurable'];
  Int: Scalars['Int'];
  KeyValue: KeyValue;
  LiveSearchCategory: LiveSearchCategory;
  LiveSearchResult: LiveSearchResult;
  Money: Money;
  Mutation: {};
  NextUrlData: NextUrlData;
  NextUrlInfo: NextUrlInfo;
  Pagination: Pagination;
  PaymentMethodInput: PaymentMethodInput;
  PaypalExpressTokenInput: PaypalExpressTokenInput;
  PaypalExpressUrlList: PaypalExpressUrlList;
  PaypalExpressUrlsInput: PaypalExpressUrlsInput;
  PlaceOrderInput: PlaceOrderInput;
  PlaceOrderOutput: PlaceOrderOutput;
  Product: Omit<Product, 'options'> & { options?: Maybe<Array<Maybe<ResolversParentTypes['ProductOptionUnion']>>> };
  ProductAttributeFilterInput: ProductAttributeFilterInput;
  ProductImage: ProductImage;
  ProductLabel: ProductLabel;
  ProductLabels: ProductLabels;
  ProductOption: ProductOption;
  ProductOptionConfigurable: ProductOptionConfigurable;
  ProductOptionUnion: ResolversParentTypes['ProductOption'] | ResolversParentTypes['ProductOptionConfigurable'];
  ProductOptionValue: ProductOptionValue;
  ProductPrice: ProductPrice;
  ProductPriceBadge: ProductPriceBadge;
  ProductQueryInput: ProductQueryInput;
  ProductQueryInputExternal: ProductQueryInputExternal;
  ProductRelationsQueryInput: ProductRelationsQueryInput;
  ProductStock: ProductStock;
  ProductVariant: ProductVariant;
  ProductVariantOption: ProductVariantOption;
  ProductVat: ProductVat;
  ProductsInLiveSearch: ProductsInLiveSearch;
  ProductsQueryInput: ProductsQueryInput;
  ProductsQueryOutput: ProductsQueryOutput;
  Query: {};
  Region: Region;
  RegisterInput: RegisterInput;
  ReorderError: ReorderError;
  RequestOTPOutput: RequestOtpOutput;
  ResetRequestOutput: ResetRequestOutput;
  ResetToken: ResetToken;
  SagePayDeleteTokenOutput: SagePayDeleteTokenOutput;
  SagePayGetNextUrlInput: SagePayGetNextUrlInput;
  SagePayGetNextUrlOutput: SagePayGetNextUrlOutput;
  SagePayGetTokensOutput: SagePayGetTokensOutput;
  SagePayToken: SagePayToken;
  SearchResultPageInfo: SearchResultPageInfo;
  SendDeleteAccountMessage: SendDeleteAccountMessage;
  SetBillingAddressOnCartInput: SetBillingAddressOnCartInput;
  SetPaymentMethodOnCartInput: SetPaymentMethodOnCartInput;
  SetShippingAddressOnCartInput: SetShippingAddressOnCartInput;
  SetShippingMethodsOnCartInput: SetShippingMethodsOnCartInput;
  ShippingAddressesInput: ShippingAddressesInput;
  ShippingMethodsInput: ShippingMethodsInput;
  Sort: Sort;
  SortsQueryOutput: SortsQueryOutput;
  StoreConfig: StoreConfig;
  String: Scalars['String'];
  StripePaymentIntentSecretOutput: StripePaymentIntentSecretOutput;
  UpdateCustomerAddressInput: UpdateCustomerAddressInput;
  VerifyOTPRequestOutput: VerifyOtpRequestOutput;
  Wishlist: Wishlist;
  WishlistItem: WishlistItem;
  WishlistItemInput: WishlistItemInput;
  WishlistItemsOutput: WishlistItemsOutput;
  WishlistShareOutput: WishlistShareOutput;
  WishlistUserInputError: WishlistUserInputError;
  WishlistWithErrors: WishlistWithErrors;
  checkIsEmailAvailableOutput: CheckIsEmailAvailableOutput;
};

export interface ClientDirectiveArgs { };

export type ClientDirectiveResolver<Result, Parent, ContextType = ResolverContext, Args = ClientDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export interface AccessTokenResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['AccessToken'] = ResolversParentTypes['AccessToken']> {
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface AnyScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Any'], any> {
  name: 'Any';
}

export interface AvailableShippingMethodResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['AvailableShippingMethod'] = ResolversParentTypes['AvailableShippingMethod']> {
  available?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  carrierCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  carrierTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  methodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  methodTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['CartMoneyOnly']>, ParentType, ContextType>;
  priceExcludingTax?: Resolver<Maybe<ResolversTypes['CartMoneyOnly']>, ParentType, ContextType>;
  priceIncludingTax?: Resolver<Maybe<ResolversTypes['CartMoneyOnly']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface BreadcrumbResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['Breadcrumb'] = ResolversParentTypes['Breadcrumb']> {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['BreadcrumbUrl']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface BreadcrumbUrlResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['BreadcrumbUrl'] = ResolversParentTypes['BreadcrumbUrl']> {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['Cart'] = ResolversParentTypes['Cart']> {
  appliedCoupon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  availablePaymentMethods?: Resolver<Maybe<Array<Maybe<ResolversTypes['CartAvailablePaymentMethods']>>>, ParentType, ContextType>;
  billingAddress?: Resolver<Maybe<ResolversTypes['CartBillingAddress']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['CartItems']>>>, ParentType, ContextType>;
  prices?: Resolver<Maybe<ResolversTypes['CartPrices']>, ParentType, ContextType>;
  selectedPaymentMethod?: Resolver<Maybe<ResolversTypes['CartSelectedPaymentMethod']>, ParentType, ContextType>;
  shippingAddresses?: Resolver<Maybe<Array<Maybe<ResolversTypes['CartShippingAddresses']>>>, ParentType, ContextType>;
  totalQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totals?: Resolver<Maybe<ResolversTypes['CartTotals']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartAddressCountryResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartAddressCountry'] = ResolversParentTypes['CartAddressCountry']> {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartAddressRegionResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartAddressRegion'] = ResolversParentTypes['CartAddressRegion']> {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  regionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartAmountResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartAmount'] = ResolversParentTypes['CartAmount']> {
  amount?: Resolver<Maybe<ResolversTypes['CartMoney']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartAvailablePaymentMethodsResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartAvailablePaymentMethods'] = ResolversParentTypes['CartAvailablePaymentMethods']> {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartBillingAddressResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartBillingAddress'] = ResolversParentTypes['CartBillingAddress']> {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['CartAddressCountry']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['CartAddressRegion']>, ParentType, ContextType>;
  street?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  telephone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartCustomizableOptionsValueResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartCustomizableOptionsValue'] = ResolversParentTypes['CartCustomizableOptionsValue']> {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartItemCustomizableOptionsResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartItemCustomizableOptions'] = ResolversParentTypes['CartItemCustomizableOptions']> {
  changedLabel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  isRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['CartItemCustomizableOptionsType']>, ParentType, ContextType>;
  values?: Resolver<Maybe<Array<Maybe<ResolversTypes['CartCustomizableOptionsValue']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartItemPricesResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartItemPrices'] = ResolversParentTypes['CartItemPrices']> {
  discounts?: Resolver<Maybe<Array<Maybe<ResolversTypes['CartAmount']>>>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['CartMoneyOnly']>, ParentType, ContextType>;
  rowTotal?: Resolver<Maybe<ResolversTypes['CartMoneyOnly']>, ParentType, ContextType>;
  rowTotalIncludingTax?: Resolver<Maybe<ResolversTypes['CartMoneyOnly']>, ParentType, ContextType>;
  totalItemDiscount?: Resolver<Maybe<ResolversTypes['CartMoneyOnly']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartItemSelectedConfigurableOptionsResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartItemSelectedConfigurableOptions'] = ResolversParentTypes['CartItemSelectedConfigurableOptions']> {
  optionLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  optionUid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  optionValueUid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  valueLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartItemsResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartItems'] = ResolversParentTypes['CartItems']> {
  configurableOptions?: Resolver<Maybe<Array<Maybe<ResolversTypes['CartItemSelectedConfigurableOptions']>>>, ParentType, ContextType>;
  configuredVariant?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType>;
  customizableOptions?: Resolver<Maybe<Array<Maybe<ResolversTypes['CartItemCustomizableOptions']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  prices?: Resolver<Maybe<ResolversTypes['CartItemPrices']>, ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['CartItemTypes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartMoneyResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartMoney'] = ResolversParentTypes['CartMoney']> {
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartMoneyOnlyResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartMoneyOnly'] = ResolversParentTypes['CartMoneyOnly']> {
  money?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartPricesResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartPrices'] = ResolversParentTypes['CartPrices']> {
  appliedTaxes?: Resolver<Maybe<Array<Maybe<ResolversTypes['CartAmount']>>>, ParentType, ContextType>;
  discounts?: Resolver<Maybe<Array<Maybe<ResolversTypes['CartAmount']>>>, ParentType, ContextType>;
  grandTotal?: Resolver<Maybe<ResolversTypes['CartMoney']>, ParentType, ContextType>;
  subtotalExcludingTax?: Resolver<Maybe<ResolversTypes['CartMoney']>, ParentType, ContextType>;
  subtotalIncludingTax?: Resolver<Maybe<ResolversTypes['CartMoney']>, ParentType, ContextType>;
  subtotalWithDiscountExcludingTax?: Resolver<Maybe<ResolversTypes['CartMoney']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartReorderItemsOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartReorderItemsOutput'] = ResolversParentTypes['CartReorderItemsOutput']> {
  cart?: Resolver<Maybe<ResolversTypes['Cart']>, ParentType, ContextType>;
  userInputErrors?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReorderError']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartSelectedPaymentMethodResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartSelectedPaymentMethod'] = ResolversParentTypes['CartSelectedPaymentMethod']> {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  purchaseOrderNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartSelectedShippingMethodResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartSelectedShippingMethod'] = ResolversParentTypes['CartSelectedShippingMethod']> {
  carrierCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  carrierTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  methodCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  methodTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['CartMoneyOnly']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartShippingAddressesResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartShippingAddresses'] = ResolversParentTypes['CartShippingAddresses']> {
  availableShippingMethods?: Resolver<Maybe<Array<Maybe<ResolversTypes['AvailableShippingMethod']>>>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['CartAddressCountry']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['CartAddressRegion']>, ParentType, ContextType>;
  selectedShippingMethod?: Resolver<Maybe<ResolversTypes['CartSelectedShippingMethod']>, ParentType, ContextType>;
  street?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  telephone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CartTotalsResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CartTotals'] = ResolversParentTypes['CartTotals']> {
  discounts?: Resolver<Maybe<Array<Maybe<ResolversTypes['CartMoney']>>>, ParentType, ContextType>;
  grand?: Resolver<Maybe<ResolversTypes['CartMoneyOnly']>, ParentType, ContextType>;
  shipping?: Resolver<Maybe<ResolversTypes['CartMoney']>, ParentType, ContextType>;
  shippings?: Resolver<Maybe<Array<Maybe<ResolversTypes['CartMoney']>>>, ParentType, ContextType>;
  sub?: Resolver<Maybe<ResolversTypes['CartMoneyOnly']>, ParentType, ContextType>;
  subTax?: Resolver<Maybe<ResolversTypes['CartMoneyOnly']>, ParentType, ContextType>;
  tax?: Resolver<Maybe<ResolversTypes['CartMoneyOnly']>, ParentType, ContextType>;
  taxes?: Resolver<Maybe<Array<Maybe<ResolversTypes['CartMoney']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CatalogResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['Catalog'] = ResolversParentTypes['Catalog']> {
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['Product']>>>, ParentType, ContextType>;
  pagination?: Resolver<Maybe<ResolversTypes['Pagination']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CategoryResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category']> {
  breadcrumbs?: Resolver<Maybe<Array<Maybe<ResolversTypes['Breadcrumb']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  metaDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metaKeywords?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metaTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CheckoutOrderDetailsResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CheckoutOrderDetails'] = ResolversParentTypes['CheckoutOrderDetails']> {
  order_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CmsResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['Cms'] = ResolversParentTypes['Cms']> {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  layout?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metaDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metaKeywords?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metaTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CmsBlockResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CmsBlock'] = ResolversParentTypes['CmsBlock']> {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  identifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CmsBlocksOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CmsBlocksOutput'] = ResolversParentTypes['CmsBlocksOutput']> {
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['CmsBlock']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CmsRouteResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CmsRoute'] = ResolversParentTypes['CmsRoute']> {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  redirectCode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['CmsRouteType']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CountryResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['Country'] = ResolversParentTypes['Country']> {
  availableRegions?: Resolver<Maybe<Array<Maybe<ResolversTypes['Region']>>>, ParentType, ContextType>;
  fullNameEnglish?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullNameLocale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  threeLetterAbbreviation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twoLetterAbbreviation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CreateMeshPaymentUrlOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CreateMeshPaymentUrlOutput'] = ResolversParentTypes['CreateMeshPaymentUrlOutput']> {
  redirect_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CreatePaypalExpressTokenOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CreatePaypalExpressTokenOutput'] = ResolversParentTypes['CreatePaypalExpressTokenOutput']> {
  paypalUrls?: Resolver<Maybe<ResolversTypes['PaypalExpressUrlList']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CustomerResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['Customer'] = ResolversParentTypes['Customer']> {
  addresses?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerAddress']>>>, ParentType, ContextType>;
  dateOfBirth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  isSubscribed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  middleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CustomerAddressResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CustomerAddress'] = ResolversParentTypes['CustomerAddress']> {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultBilling?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  defaultShipping?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  middleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['CustomerAddressRegion']>, ParentType, ContextType>;
  street?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  telephone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vatId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CustomerAddressRegionResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CustomerAddressRegion'] = ResolversParentTypes['CustomerAddressRegion']> {
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  regionCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  regionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CustomerOrderResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CustomerOrder'] = ResolversParentTypes['CustomerOrder']> {
  billingAddress?: Resolver<Maybe<ResolversTypes['CustomerOrderAddress']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentMethod?: Resolver<Maybe<ResolversTypes['CustomerOrderPaymentMethod']>, ParentType, ContextType>;
  products?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerOrderProduct']>>>, ParentType, ContextType>;
  shippingAddress?: Resolver<Maybe<ResolversTypes['CustomerOrderAddress']>, ParentType, ContextType>;
  shippingAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shippingMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totals?: Resolver<Maybe<ResolversTypes['CustomerOrderTotals']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CustomerOrderAddressResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CustomerOrderAddress'] = ResolversParentTypes['CustomerOrderAddress']> {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryCode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  middleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  regionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  street?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  telephone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CustomerOrderDiscountResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CustomerOrderDiscount'] = ResolversParentTypes['CustomerOrderDiscount']> {
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CustomerOrderPaymentMethodResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CustomerOrderPaymentMethod'] = ResolversParentTypes['CustomerOrderPaymentMethod']> {
  additionalData?: Resolver<Maybe<ResolversTypes['KeyValue']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CustomerOrderProductResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CustomerOrderProduct'] = ResolversParentTypes['CustomerOrderProduct']> {
  discounts?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerOrderDiscount']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerOrderProductOption']>>>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  productData?: Resolver<Maybe<ResolversTypes['CustomerOrderProductData']>, ParentType, ContextType>;
  quantityOrdered?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CustomerOrderProductDataResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CustomerOrderProductData'] = ResolversParentTypes['CustomerOrderProductData']> {
  brand?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prices?: Resolver<Maybe<ResolversTypes['CustomerOrderProductPrice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CustomerOrderProductOptionResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CustomerOrderProductOption'] = ResolversParentTypes['CustomerOrderProductOption']> {
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CustomerOrderProductPriceResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CustomerOrderProductPrice'] = ResolversParentTypes['CustomerOrderProductPrice']> {
  finalPrice?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  originalPrice?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  totalRow?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CustomerOrderTotalsResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CustomerOrderTotals'] = ResolversParentTypes['CustomerOrderTotals']> {
  discounts?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerOrderDiscount']>>>, ParentType, ContextType>;
  subtotal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taxes?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerOrderTotalsTax']>>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalShipping?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CustomerOrderTotalsTaxResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CustomerOrderTotalsTax'] = ResolversParentTypes['CustomerOrderTotalsTax']> {
  rate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CustomerOrdersOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CustomerOrdersOutput'] = ResolversParentTypes['CustomerOrdersOutput']> {
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerOrder']>>>, ParentType, ContextType>;
  pagination?: Resolver<Maybe<ResolversTypes['Pagination']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CustomerOrdersPageInfoResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['CustomerOrdersPageInfo'] = ResolversParentTypes['CustomerOrdersPageInfo']> {
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DeleteCustomerAccountOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['DeleteCustomerAccountOutput'] = ResolversParentTypes['DeleteCustomerAccountOutput']> {
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface FilterResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['Filter'] = ResolversParentTypes['Filter']> {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['FilterItem']>>>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['FilterType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface FilterItemResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['FilterItem'] = ResolversParentTypes['FilterItem']> {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  swatchData?: Resolver<Maybe<ResolversTypes['FilterSwatchData']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface FilterSwatchDataResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['FilterSwatchData'] = ResolversParentTypes['FilterSwatchData']> {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface IProductOptionResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['IProductOption'] = ResolversParentTypes['IProductOption']> {
  __resolveType: TypeResolveFn<'ProductOption' | 'ProductOptionConfigurable', ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kind?: Resolver<Maybe<ResolversTypes['ProductOptionKind']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  required?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  values?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProductOptionValue']>>>, ParentType, ContextType>;
};

export interface KeyValueResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['KeyValue'] = ResolversParentTypes['KeyValue']> {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface LiveSearchCategoryResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['LiveSearchCategory'] = ResolversParentTypes['LiveSearchCategory']> {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface LiveSearchResultResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['LiveSearchResult'] = ResolversParentTypes['LiveSearchResult']> {
  categories?: Resolver<Maybe<ResolversTypes['LiveSearchCategory']>, ParentType, ContextType>;
  products?: Resolver<Maybe<ResolversTypes['ProductsInLiveSearch']>, ParentType, ContextType>;
  suggestions?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface MoneyResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['Money'] = ResolversParentTypes['Money']> {
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface MutationResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> {
  addProductsToWishlist?: Resolver<Maybe<ResolversTypes['WishlistWithErrors']>, ParentType, ContextType, RequireFields<MutationAddProductsToWishlistArgs, 'id' | 'items'>>;
  cartAddItem?: Resolver<Maybe<ResolversTypes['Cart']>, ParentType, ContextType, RequireFields<MutationCartAddItemArgs, 'cartId' | 'cartItems'>>;
  cartApplyCouponCode?: Resolver<Maybe<ResolversTypes['Cart']>, ParentType, ContextType, RequireFields<MutationCartApplyCouponCodeArgs, 'code'>>;
  cartAssignToCustomer?: Resolver<Maybe<ResolversTypes['Cart']>, ParentType, ContextType, Partial<MutationCartAssignToCustomerArgs>>;
  cartGenerateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cartRemoveCouponCode?: Resolver<Maybe<ResolversTypes['Cart']>, ParentType, ContextType>;
  cartRemoveItem?: Resolver<Maybe<ResolversTypes['Cart']>, ParentType, ContextType, Partial<MutationCartRemoveItemArgs>>;
  cartReorderItems?: Resolver<Maybe<ResolversTypes['CartReorderItemsOutput']>, ParentType, ContextType, Partial<MutationCartReorderItemsArgs>>;
  cartSetBillingAddress?: Resolver<Maybe<ResolversTypes['Cart']>, ParentType, ContextType, Partial<MutationCartSetBillingAddressArgs>>;
  cartSetGuestEmail?: Resolver<Maybe<ResolversTypes['Cart']>, ParentType, ContextType, Partial<MutationCartSetGuestEmailArgs>>;
  cartSetPaymentMethod?: Resolver<Maybe<ResolversTypes['Cart']>, ParentType, ContextType, Partial<MutationCartSetPaymentMethodArgs>>;
  cartSetShippingAddress?: Resolver<Maybe<ResolversTypes['Cart']>, ParentType, ContextType, Partial<MutationCartSetShippingAddressArgs>>;
  cartSetShippingMethods?: Resolver<Maybe<ResolversTypes['Cart']>, ParentType, ContextType, Partial<MutationCartSetShippingMethodsArgs>>;
  cartUpdateItem?: Resolver<Maybe<ResolversTypes['Cart']>, ParentType, ContextType, Partial<MutationCartUpdateItemArgs>>;
  changePassword?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType, Partial<MutationChangePasswordArgs>>;
  createCustomerAddress?: Resolver<Maybe<ResolversTypes['CustomerAddress']>, ParentType, ContextType, Partial<MutationCreateCustomerAddressArgs>>;
  createMeshPaymentUrl?: Resolver<Maybe<ResolversTypes['CreateMeshPaymentUrlOutput']>, ParentType, ContextType, RequireFields<MutationCreateMeshPaymentUrlArgs, 'order_number'>>;
  createPaypalExpressToken?: Resolver<Maybe<ResolversTypes['CreatePaypalExpressTokenOutput']>, ParentType, ContextType, RequireFields<MutationCreatePaypalExpressTokenArgs, 'input'>>;
  deleteCustomerAccount?: Resolver<Maybe<ResolversTypes['DeleteCustomerAccountOutput']>, ParentType, ContextType, Partial<MutationDeleteCustomerAccountArgs>>;
  deleteCustomerAddress?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationDeleteCustomerAddressArgs>>;
  login?: Resolver<Maybe<ResolversTypes['AccessToken']>, ParentType, ContextType, Partial<MutationLoginArgs>>;
  mergeWishlists?: Resolver<Maybe<ResolversTypes['WishlistWithErrors']>, ParentType, ContextType, RequireFields<MutationMergeWishlistsArgs, 'id'>>;
  placeOrder?: Resolver<Maybe<ResolversTypes['PlaceOrderOutput']>, ParentType, ContextType, Partial<MutationPlaceOrderArgs>>;
  register?: Resolver<Maybe<ResolversTypes['AccessToken']>, ParentType, ContextType, Partial<MutationRegisterArgs>>;
  removeProductsFromWishlist?: Resolver<Maybe<ResolversTypes['WishlistWithErrors']>, ParentType, ContextType, RequireFields<MutationRemoveProductsFromWishlistArgs, 'id' | 'items'>>;
  requestOTPAuth?: Resolver<Maybe<ResolversTypes['RequestOTPOutput']>, ParentType, ContextType, Partial<MutationRequestOtpAuthArgs>>;
  requestOTPReset?: Resolver<Maybe<ResolversTypes['RequestOTPOutput']>, ParentType, ContextType, Partial<MutationRequestOtpResetArgs>>;
  reset?: Resolver<Maybe<ResolversTypes['ResetToken']>, ParentType, ContextType, Partial<MutationResetArgs>>;
  resetRequest?: Resolver<Maybe<ResolversTypes['ResetRequestOutput']>, ParentType, ContextType, Partial<MutationResetRequestArgs>>;
  sagePayDeleteToken?: Resolver<Maybe<ResolversTypes['SagePayDeleteTokenOutput']>, ParentType, ContextType, Partial<MutationSagePayDeleteTokenArgs>>;
  sendDeleteAccountMessage?: Resolver<Maybe<ResolversTypes['SendDeleteAccountMessage']>, ParentType, ContextType>;
  shareWishlist?: Resolver<Maybe<ResolversTypes['WishlistShareOutput']>, ParentType, ContextType, RequireFields<MutationShareWishlistArgs, 'emails' | 'id'>>;
  updateCustomer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType, Partial<MutationUpdateCustomerArgs>>;
  updateCustomerAddress?: Resolver<Maybe<ResolversTypes['CustomerAddress']>, ParentType, ContextType, Partial<MutationUpdateCustomerAddressArgs>>;
  updateEmail?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType, Partial<MutationUpdateEmailArgs>>;
  verifyOTPAuth?: Resolver<Maybe<ResolversTypes['VerifyOTPRequestOutput']>, ParentType, ContextType, Partial<MutationVerifyOtpAuthArgs>>;
  verifyOTPReset?: Resolver<Maybe<ResolversTypes['VerifyOTPRequestOutput']>, ParentType, ContextType, Partial<MutationVerifyOtpResetArgs>>;
};

export interface NextUrlDataResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['NextUrlData'] = ResolversParentTypes['NextUrlData']> {
  data?: Resolver<Maybe<ResolversTypes['NextUrlInfo']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface NextUrlInfoResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['NextUrlInfo'] = ResolversParentTypes['NextUrlInfo']> {
  VPSTxId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nextURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  securityKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PaginationResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['Pagination'] = ResolversParentTypes['Pagination']> {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  current?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PaypalExpressUrlListResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['PaypalExpressUrlList'] = ResolversParentTypes['PaypalExpressUrlList']> {
  edit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PlaceOrderOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['PlaceOrderOutput'] = ResolversParentTypes['PlaceOrderOutput']> {
  order?: Resolver<Maybe<ResolversTypes['CheckoutOrderDetails']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ProductResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product']> {
  breadcrumbs?: Resolver<Maybe<Array<Maybe<ResolversTypes['Breadcrumb']>>>, ParentType, ContextType>;
  configuredSiblingSku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  images?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProductImage']>>>, ParentType, ContextType>;
  labels?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProductLabel']>>>, ParentType, ContextType>;
  metaDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metaKeywords?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metaTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProductOptionUnion']>>>, ParentType, ContextType>;
  parentSku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['ProductPrice']>, ParentType, ContextType>;
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stock?: Resolver<Maybe<ResolversTypes['ProductStock']>, ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['ProductImage']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ProductTypes']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  variants?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProductVariant']>>>, ParentType, ContextType>;
  vat?: Resolver<Maybe<ResolversTypes['ProductVat']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ProductImageResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ProductImage'] = ResolversParentTypes['ProductImage']> {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ProductLabelResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ProductLabel'] = ResolversParentTypes['ProductLabel']> {
  backgroundColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ProductLabelsResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ProductLabels'] = ResolversParentTypes['ProductLabels']> {
  addToCart?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ProductOptionResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ProductOption'] = ResolversParentTypes['ProductOption']> {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kind?: Resolver<Maybe<ResolversTypes['ProductOptionKind']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  required?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ProductOptionType']>, ParentType, ContextType>;
  values?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProductOptionValue']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ProductOptionConfigurableResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ProductOptionConfigurable'] = ResolversParentTypes['ProductOptionConfigurable']> {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kind?: Resolver<Maybe<ResolversTypes['ProductOptionKind']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  required?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ProductConfigurableOptionType']>, ParentType, ContextType>;
  values?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProductOptionValue']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ProductOptionUnionResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ProductOptionUnion'] = ResolversParentTypes['ProductOptionUnion']> {
  __resolveType: TypeResolveFn<'ProductOption' | 'ProductOptionConfigurable', ParentType, ContextType>;
};

export interface ProductOptionValueResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ProductOptionValue'] = ResolversParentTypes['ProductOptionValue']> {
  colorValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isInStock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ProductPriceResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ProductPrice'] = ResolversParentTypes['ProductPrice']> {
  badges?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProductPriceBadge']>>>, ParentType, ContextType>;
  current?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  discount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  initial?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  isRange?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ProductPriceBadgeResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ProductPriceBadge'] = ResolversParentTypes['ProductPriceBadge']> {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ProductStockResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ProductStock'] = ResolversParentTypes['ProductStock']> {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ProductStockType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ProductVariantResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ProductVariant'] = ResolversParentTypes['ProductVariant']> {
  options?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProductVariantOption']>>>, ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ProductVariantOptionResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ProductVariantOption'] = ResolversParentTypes['ProductVariantOption']> {
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ProductVatResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ProductVat'] = ResolversParentTypes['ProductVat']> {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ProductVatType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ProductsInLiveSearchResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ProductsInLiveSearch'] = ResolversParentTypes['ProductsInLiveSearch']> {
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['Product']>>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['SearchResultPageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ProductsQueryOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ProductsQueryOutput'] = ResolversParentTypes['ProductsQueryOutput']> {
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['Product']>>>, ParentType, ContextType>;
  pagination?: Resolver<Maybe<ResolversTypes['Pagination']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface QueryResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> {
  Countries?: Resolver<Maybe<Array<Maybe<ResolversTypes['Country']>>>, ParentType, ContextType>;
  cart?: Resolver<Maybe<ResolversTypes['Cart']>, ParentType, ContextType, Partial<QueryCartArgs>>;
  cartRecommendedProducts?: Resolver<Maybe<Array<Maybe<ResolversTypes['Product']>>>, ParentType, ContextType, Partial<QueryCartRecommendedProductsArgs>>;
  catalog?: Resolver<Maybe<ResolversTypes['Catalog']>, ParentType, ContextType, Partial<QueryCatalogArgs>>;
  categories?: Resolver<Maybe<Array<Maybe<ResolversTypes['Category']>>>, ParentType, ContextType, Partial<QueryCategoriesArgs>>;
  category?: Resolver<Maybe<ResolversTypes['Category']>, ParentType, ContextType, Partial<QueryCategoryArgs>>;
  checkIsEmailAvailable?: Resolver<Maybe<ResolversTypes['checkIsEmailAvailableOutput']>, ParentType, ContextType, Partial<QueryCheckIsEmailAvailableArgs>>;
  cms?: Resolver<Maybe<ResolversTypes['Cms']>, ParentType, ContextType, Partial<QueryCmsArgs>>;
  cmsBlocks?: Resolver<Maybe<ResolversTypes['CmsBlocksOutput']>, ParentType, ContextType, Partial<QueryCmsBlocksArgs>>;
  cmsRoute?: Resolver<Maybe<ResolversTypes['CmsRoute']>, ParentType, ContextType, Partial<QueryCmsRouteArgs>>;
  customer?: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>;
  customerAddresses?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomerAddress']>>>, ParentType, ContextType>;
  customerOrder?: Resolver<Maybe<ResolversTypes['CustomerOrder']>, ParentType, ContextType, Partial<QueryCustomerOrderArgs>>;
  customerOrderLast?: Resolver<Maybe<ResolversTypes['CustomerOrder']>, ParentType, ContextType>;
  customerOrders?: Resolver<Maybe<ResolversTypes['CustomerOrdersOutput']>, ParentType, ContextType, Partial<QueryCustomerOrdersArgs>>;
  filters?: Resolver<Maybe<Array<Maybe<ResolversTypes['Filter']>>>, ParentType, ContextType, Partial<QueryFiltersArgs>>;
  getStripePaymentIntentSecret?: Resolver<Maybe<ResolversTypes['StripePaymentIntentSecretOutput']>, ParentType, ContextType, RequireFields<QueryGetStripePaymentIntentSecretArgs, 'cartId'>>;
  liveSearch?: Resolver<Maybe<ResolversTypes['LiveSearchResult']>, ParentType, ContextType, RequireFields<QueryLiveSearchArgs, 'search'>>;
  paymentMethodSuccessMessages?: Resolver<Maybe<ResolversTypes['CmsBlock']>, ParentType, ContextType, Partial<QueryPaymentMethodSuccessMessagesArgs>>;
  product?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType, Partial<QueryProductArgs>>;
  productRelations?: Resolver<Maybe<Array<Maybe<ResolversTypes['Product']>>>, ParentType, ContextType, RequireFields<QueryProductRelationsArgs, 'input'>>;
  products?: Resolver<Maybe<ResolversTypes['ProductsQueryOutput']>, ParentType, ContextType, Partial<QueryProductsArgs>>;
  sagePayGetNextUrl?: Resolver<Maybe<ResolversTypes['SagePayGetNextUrlOutput']>, ParentType, ContextType, Partial<QuerySagePayGetNextUrlArgs>>;
  sagePayGetTokens?: Resolver<Maybe<ResolversTypes['SagePayGetTokensOutput']>, ParentType, ContextType>;
  sharedWishlist?: Resolver<Maybe<ResolversTypes['Wishlist']>, ParentType, ContextType, RequireFields<QuerySharedWishlistArgs, 'sharingCode'>>;
  sorts?: Resolver<Maybe<ResolversTypes['SortsQueryOutput']>, ParentType, ContextType>;
  storeConfig?: Resolver<Maybe<ResolversTypes['StoreConfig']>, ParentType, ContextType>;
  wishlist?: Resolver<Maybe<ResolversTypes['Wishlist']>, ParentType, ContextType, RequireFields<QueryWishlistArgs, 'id'>>;
};

export interface RegionResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['Region'] = ResolversParentTypes['Region']> {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ReorderErrorResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ReorderError'] = ResolversParentTypes['ReorderError']> {
  code?: Resolver<ResolversTypes['ReorderErrorCodes'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface RequestOtpOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['RequestOTPOutput'] = ResolversParentTypes['RequestOTPOutput']> {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ResetRequestOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ResetRequestOutput'] = ResolversParentTypes['ResetRequestOutput']> {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ResetTokenResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['ResetToken'] = ResolversParentTypes['ResetToken']> {
  attempts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface SagePayDeleteTokenOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['SagePayDeleteTokenOutput'] = ResolversParentTypes['SagePayDeleteTokenOutput']> {
  tokenRemovalStatus?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface SagePayGetNextUrlOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['SagePayGetNextUrlOutput'] = ResolversParentTypes['SagePayGetNextUrlOutput']> {
  data?: Resolver<Maybe<ResolversTypes['NextUrlData']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface SagePayGetTokensOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['SagePayGetTokensOutput'] = ResolversParentTypes['SagePayGetTokensOutput']> {
  tokens?: Resolver<Maybe<Array<Maybe<ResolversTypes['SagePayToken']>>>, ParentType, ContextType>;
  tokensEnabled?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface SagePayTokenResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['SagePayToken'] = ResolversParentTypes['SagePayToken']> {
  ccExpMonth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ccExpYear?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ccLast4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ccType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface SearchResultPageInfoResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['SearchResultPageInfo'] = ResolversParentTypes['SearchResultPageInfo']> {
  currentPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pageSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface SendDeleteAccountMessageResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['SendDeleteAccountMessage'] = ResolversParentTypes['SendDeleteAccountMessage']> {
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface SortResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['Sort'] = ResolversParentTypes['Sort']> {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface SortsQueryOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['SortsQueryOutput'] = ResolversParentTypes['SortsQueryOutput']> {
  default?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['Sort']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface StoreConfigResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['StoreConfig'] = ResolversParentTypes['StoreConfig']> {
  baseCurrencyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logoHeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  logoWidth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxAddressLineLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxNameLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  passwordMinLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  passwordMinStrength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  productReturns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productReviewsEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  termsOfDelivery?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface StripePaymentIntentSecretOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['StripePaymentIntentSecretOutput'] = ResolversParentTypes['StripePaymentIntentSecretOutput']> {
  secret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface VerifyOtpRequestOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['VerifyOTPRequestOutput'] = ResolversParentTypes['VerifyOTPRequestOutput']> {
  attemptsLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  valid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface WishlistResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['Wishlist'] = ResolversParentTypes['Wishlist']> {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  items?: Resolver<Maybe<ResolversTypes['WishlistItemsOutput']>, ParentType, ContextType, Partial<WishlistItemsArgs>>;
  sharingCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface WishlistItemResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['WishlistItem'] = ResolversParentTypes['WishlistItem']> {
  addedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface WishlistItemsOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['WishlistItemsOutput'] = ResolversParentTypes['WishlistItemsOutput']> {
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['WishlistItem']>>>, ParentType, ContextType>;
  pagination?: Resolver<Maybe<ResolversTypes['Pagination']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface WishlistShareOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['WishlistShareOutput'] = ResolversParentTypes['WishlistShareOutput']> {
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface WishlistUserInputErrorResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['WishlistUserInputError'] = ResolversParentTypes['WishlistUserInputError']> {
  code?: Resolver<ResolversTypes['WishlistUserInputErrorType'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface WishlistWithErrorsResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['WishlistWithErrors'] = ResolversParentTypes['WishlistWithErrors']> {
  userErrors?: Resolver<Maybe<Array<Maybe<ResolversTypes['WishlistUserInputError']>>>, ParentType, ContextType>;
  wishlist?: Resolver<Maybe<ResolversTypes['Wishlist']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CheckIsEmailAvailableOutputResolvers<ContextType = ResolverContext, ParentType extends ResolversParentTypes['checkIsEmailAvailableOutput'] = ResolversParentTypes['checkIsEmailAvailableOutput']> {
  isEmailAvailable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface Resolvers<ContextType = ResolverContext> {
  AccessToken?: AccessTokenResolvers<ContextType>;
  Any?: GraphQLScalarType;
  AvailableShippingMethod?: AvailableShippingMethodResolvers<ContextType>;
  Breadcrumb?: BreadcrumbResolvers<ContextType>;
  BreadcrumbUrl?: BreadcrumbUrlResolvers<ContextType>;
  Cart?: CartResolvers<ContextType>;
  CartAddressCountry?: CartAddressCountryResolvers<ContextType>;
  CartAddressRegion?: CartAddressRegionResolvers<ContextType>;
  CartAmount?: CartAmountResolvers<ContextType>;
  CartAvailablePaymentMethods?: CartAvailablePaymentMethodsResolvers<ContextType>;
  CartBillingAddress?: CartBillingAddressResolvers<ContextType>;
  CartCustomizableOptionsValue?: CartCustomizableOptionsValueResolvers<ContextType>;
  CartItemCustomizableOptions?: CartItemCustomizableOptionsResolvers<ContextType>;
  CartItemPrices?: CartItemPricesResolvers<ContextType>;
  CartItemSelectedConfigurableOptions?: CartItemSelectedConfigurableOptionsResolvers<ContextType>;
  CartItems?: CartItemsResolvers<ContextType>;
  CartMoney?: CartMoneyResolvers<ContextType>;
  CartMoneyOnly?: CartMoneyOnlyResolvers<ContextType>;
  CartPrices?: CartPricesResolvers<ContextType>;
  CartReorderItemsOutput?: CartReorderItemsOutputResolvers<ContextType>;
  CartSelectedPaymentMethod?: CartSelectedPaymentMethodResolvers<ContextType>;
  CartSelectedShippingMethod?: CartSelectedShippingMethodResolvers<ContextType>;
  CartShippingAddresses?: CartShippingAddressesResolvers<ContextType>;
  CartTotals?: CartTotalsResolvers<ContextType>;
  Catalog?: CatalogResolvers<ContextType>;
  Category?: CategoryResolvers<ContextType>;
  CheckoutOrderDetails?: CheckoutOrderDetailsResolvers<ContextType>;
  Cms?: CmsResolvers<ContextType>;
  CmsBlock?: CmsBlockResolvers<ContextType>;
  CmsBlocksOutput?: CmsBlocksOutputResolvers<ContextType>;
  CmsRoute?: CmsRouteResolvers<ContextType>;
  Country?: CountryResolvers<ContextType>;
  CreateMeshPaymentUrlOutput?: CreateMeshPaymentUrlOutputResolvers<ContextType>;
  CreatePaypalExpressTokenOutput?: CreatePaypalExpressTokenOutputResolvers<ContextType>;
  Customer?: CustomerResolvers<ContextType>;
  CustomerAddress?: CustomerAddressResolvers<ContextType>;
  CustomerAddressRegion?: CustomerAddressRegionResolvers<ContextType>;
  CustomerOrder?: CustomerOrderResolvers<ContextType>;
  CustomerOrderAddress?: CustomerOrderAddressResolvers<ContextType>;
  CustomerOrderDiscount?: CustomerOrderDiscountResolvers<ContextType>;
  CustomerOrderPaymentMethod?: CustomerOrderPaymentMethodResolvers<ContextType>;
  CustomerOrderProduct?: CustomerOrderProductResolvers<ContextType>;
  CustomerOrderProductData?: CustomerOrderProductDataResolvers<ContextType>;
  CustomerOrderProductOption?: CustomerOrderProductOptionResolvers<ContextType>;
  CustomerOrderProductPrice?: CustomerOrderProductPriceResolvers<ContextType>;
  CustomerOrderTotals?: CustomerOrderTotalsResolvers<ContextType>;
  CustomerOrderTotalsTax?: CustomerOrderTotalsTaxResolvers<ContextType>;
  CustomerOrdersOutput?: CustomerOrdersOutputResolvers<ContextType>;
  CustomerOrdersPageInfo?: CustomerOrdersPageInfoResolvers<ContextType>;
  DeleteCustomerAccountOutput?: DeleteCustomerAccountOutputResolvers<ContextType>;
  Filter?: FilterResolvers<ContextType>;
  FilterItem?: FilterItemResolvers<ContextType>;
  FilterSwatchData?: FilterSwatchDataResolvers<ContextType>;
  IProductOption?: IProductOptionResolvers<ContextType>;
  KeyValue?: KeyValueResolvers<ContextType>;
  LiveSearchCategory?: LiveSearchCategoryResolvers<ContextType>;
  LiveSearchResult?: LiveSearchResultResolvers<ContextType>;
  Money?: MoneyResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NextUrlData?: NextUrlDataResolvers<ContextType>;
  NextUrlInfo?: NextUrlInfoResolvers<ContextType>;
  Pagination?: PaginationResolvers<ContextType>;
  PaypalExpressUrlList?: PaypalExpressUrlListResolvers<ContextType>;
  PlaceOrderOutput?: PlaceOrderOutputResolvers<ContextType>;
  Product?: ProductResolvers<ContextType>;
  ProductImage?: ProductImageResolvers<ContextType>;
  ProductLabel?: ProductLabelResolvers<ContextType>;
  ProductLabels?: ProductLabelsResolvers<ContextType>;
  ProductOption?: ProductOptionResolvers<ContextType>;
  ProductOptionConfigurable?: ProductOptionConfigurableResolvers<ContextType>;
  ProductOptionUnion?: ProductOptionUnionResolvers<ContextType>;
  ProductOptionValue?: ProductOptionValueResolvers<ContextType>;
  ProductPrice?: ProductPriceResolvers<ContextType>;
  ProductPriceBadge?: ProductPriceBadgeResolvers<ContextType>;
  ProductStock?: ProductStockResolvers<ContextType>;
  ProductVariant?: ProductVariantResolvers<ContextType>;
  ProductVariantOption?: ProductVariantOptionResolvers<ContextType>;
  ProductVat?: ProductVatResolvers<ContextType>;
  ProductsInLiveSearch?: ProductsInLiveSearchResolvers<ContextType>;
  ProductsQueryOutput?: ProductsQueryOutputResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Region?: RegionResolvers<ContextType>;
  ReorderError?: ReorderErrorResolvers<ContextType>;
  RequestOTPOutput?: RequestOtpOutputResolvers<ContextType>;
  ResetRequestOutput?: ResetRequestOutputResolvers<ContextType>;
  ResetToken?: ResetTokenResolvers<ContextType>;
  SagePayDeleteTokenOutput?: SagePayDeleteTokenOutputResolvers<ContextType>;
  SagePayGetNextUrlOutput?: SagePayGetNextUrlOutputResolvers<ContextType>;
  SagePayGetTokensOutput?: SagePayGetTokensOutputResolvers<ContextType>;
  SagePayToken?: SagePayTokenResolvers<ContextType>;
  SearchResultPageInfo?: SearchResultPageInfoResolvers<ContextType>;
  SendDeleteAccountMessage?: SendDeleteAccountMessageResolvers<ContextType>;
  Sort?: SortResolvers<ContextType>;
  SortsQueryOutput?: SortsQueryOutputResolvers<ContextType>;
  StoreConfig?: StoreConfigResolvers<ContextType>;
  StripePaymentIntentSecretOutput?: StripePaymentIntentSecretOutputResolvers<ContextType>;
  VerifyOTPRequestOutput?: VerifyOtpRequestOutputResolvers<ContextType>;
  Wishlist?: WishlistResolvers<ContextType>;
  WishlistItem?: WishlistItemResolvers<ContextType>;
  WishlistItemsOutput?: WishlistItemsOutputResolvers<ContextType>;
  WishlistShareOutput?: WishlistShareOutputResolvers<ContextType>;
  WishlistUserInputError?: WishlistUserInputErrorResolvers<ContextType>;
  WishlistWithErrors?: WishlistWithErrorsResolvers<ContextType>;
  checkIsEmailAvailableOutput?: CheckIsEmailAvailableOutputResolvers<ContextType>;
};

export interface DirectiveResolvers<ContextType = ResolverContext> {
  client?: ClientDirectiveResolver<any, any, ContextType>;
};

import { CategoryFiltersSection } from '@pwa-onilab/ui/components/elements/Category'
import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'
import {
    CategoryLayoutSkeleton, CategoryNavSkeleton,
} from '@pwa-onilab/ui/components/modules/Category'
import { FiltersSkeleton } from '@pwa-onilab/ui/components/modules/Filters'
import Container from '@pwa-onilab/ui/components/UI/Container'

import styles from './CategoryPage.module.scss'

const CategoryPageSkeleton = () => (
    <CategoryLayoutSkeleton>
        <CategoryNavSkeleton />
        <CategoryFiltersSection>
            <Container>
                <FiltersSkeleton />
            </Container>
        </CategoryFiltersSection>
        <Container>
            <MediaBreakpointUp name="lg">
                <Placeholder className={styles.bottomDescriptionPlaceholder} />
            </MediaBreakpointUp>
            <Placeholder className={styles.bottomFaqPlaceholder} />
        </Container>
    </CategoryLayoutSkeleton>
)

export { CategoryPageSkeleton }

import { Resolvers } from '@pwa-concept/modules/graphql'

import AvailableShippingMethod from './AvailableShippingMethod'
import CartSelectedShippingMethod from './CartSelectedShippingMethod'
import Mutation from './Mutation'

const resolvers: Resolvers = {
    Mutation,
    AvailableShippingMethod,
    CartSelectedShippingMethod,
}

export default resolvers

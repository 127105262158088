import { useCustomerAddresses, useCustomerAddressUpdate } from '@pwa-concept/modules/customer-addresses'
import { CustomerAddress } from '@pwa-concept/modules/graphql'
import { ModalProps } from '@pwa-onilab/ui/components/elements/Modal/Modal'
import { ADDRESS_FORM_FIELDS_TYPE } from '@pwa-onilab/ui/components/modules/AddressForm'
import { AddressModal } from '@pwa-onilab/ui/components/modules/AddressModal'
import { extractAddressFromFormValues, extractFormValuesFromAddress } from '@pwa-onilab/ui/mappers'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const AddressModalUpdate = (
    {
        isOpen,
        onDismiss,
        id,
    }: Pick<ModalProps & CustomerAddress, 'isOpen' | 'onDismiss' | 'id'>) => {
    const { t } = useTranslation()
    const { addresses } = useCustomerAddresses()
    const updateAddress = useCustomerAddressUpdate()

    const formDefaultValues = useMemo((): ADDRESS_FORM_FIELDS_TYPE => {
        const address = addresses?.find(({ id: addressId }) => addressId === id)
        return extractFormValuesFromAddress(address)
    }, [id, addresses])

    const submitHandler = useCallback(async(formValues) => {
        await updateAddress(id, extractAddressFromFormValues(formValues))
            .then(() => toast.success(t('addressForm.update.success')))
            .catch((error) => toast.error(error.message))
            .finally(onDismiss)
    }, [id])

    return (
        <AddressModal
            isOpen={isOpen}
            onDismiss={onDismiss}
            onSubmit={submitHandler}
            title={t('AccountPage.addresses.edit')}
            defaultValues={formDefaultValues}
        />
    )
}

export default AddressModalUpdate

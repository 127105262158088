import { Resolvers } from '@pwa-concept/modules/graphql'

const WishlistWithErrors: Resolvers['WishlistWithErrors'] = {
    wishlist: (_, __, { context }) => !!context?.onilab_wishlist ? ({
        __context: context?.onilab_wishlist,
        __typename: 'Wishlist'
    }) : null,
    userErrors: (_, __, { context }) => !!context?.user_errors ? (
        context?.user_errors?.map?.((__context) => ({
            __context,
            __typename: 'WishlistUserInputError',
        }))
    ) : [],
}

export default WishlistWithErrors

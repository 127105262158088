import { gql } from '@apollo/client'
import api from '@pwa-concept/core/api'
import { $cart } from '@pwa-concept/core/models'
import { MutationResolvers } from '@pwa-concept/modules/graphql'
import { extractRequestAdditionalFields } from '@pwa-concept/modules/helpers'

const cartSetShippingAddress: MutationResolvers['cartSetShippingAddress'] = async (_, { input }) => {
    const { shippingAddresses } = input

    const { data: { setShippingAddressesOnCart: { cart: __context = null } = {} } = {} } = await (
        api.graphql(gql`
            mutation(
                $input: SetShippingAddressesOnCartInput
            ) {
                setShippingAddressesOnCart(input: $input) {
                    cart {
                        ... Cart
                    }
                }
            }
        `).mutation({
            input: {
                cart_id: $cart.id,
                shipping_addresses: shippingAddresses?.map((
                    {
                        address,
                        customerAddressId,
                        customerNotes,
                        pickupLocationCode,
                    },
                ) => {
                    return {
                        address: customerAddressId ? null : {
                            city: address?.city,
                            company: address?.company,
                            country_code: address?.countryCode,
                            firstname: address?.firstName,
                            lastname: address?.lastName,
                            postcode: address?.postCode,
                            region: address?.region,
                            region_id: address?.regionId,
                            // save_in_address_book: true by default
                            street: address?.street,
                            telephone: address?.telephone,
                            ...extractRequestAdditionalFields(address?.additionalFields),
                        },
                        customer_address_id: customerAddressId,
                        customer_notes: customerNotes,
                        pickup_location_code: pickupLocationCode,
                    }
                }),
            },
        })
    )

    if (!__context) return null

    return {
        __context,
        __typename: 'Cart',
    }
}

export default cartSetShippingAddress

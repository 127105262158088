export enum GA4_OPERATIONS {
    addToCart= 'add_to_cart',
    removeFromCart = 'remove_from_cart',
    viewCart= 'view_cart',
    addShippingInfo= 'add_shipping_info',
    beginCheckout= 'begin_checkout',
    addToWishlist='add_to_wishlist',
    selectItem = 'select_item',
    viewItem= 'view_item',
}

import { ProductVariant } from '@pwa-concept/modules/graphql'

const mergeConfigurableVariants = (variants: ProductVariant[], parentSku) => {
    return variants?.map((variant) => ({
        ...variant,
        product: {
            ...variant.product,
            parentSku,
        },
    })) || null
}

export const smartMerge = (target, source) => {
    const softMerge = { ...target, ...source }

    const smartMergeResult = Object.keys(source).reduce((result, field) => {
        const value = softMerge?.[field]

        if (
            value === null ||
            value === undefined ||
            (typeof value === 'string' && !value?.length) ||
            (Array.isArray(value) && !value?.length) ||
            (typeof value === 'object' && !Object.keys(value)?.length)
        ) {
            result[field] = target?.[field]
        }
        return result
    }, softMerge)

    smartMergeResult.configuredSiblingSku = target.sku
    smartMergeResult.parentSku = target.sku
    smartMergeResult.variants = mergeConfigurableVariants(smartMergeResult.variants, target.sku)

    return smartMergeResult
}

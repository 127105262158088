import {
    Product,
    ProductConfigurableOptionType, ProductOptionConfigurable,
    ProductOptionUnion, ProductStockType,
    ProductVariant,
    ProductVariantOption,
} from '@pwa-concept/modules/graphql'
import { logger } from '@pwa-concept/modules/helpers/logger'

export const mapOptionsForProduct = (product: Product) => {
    if (!product) {
        return null
    }

    const currentProductSku = product.sku
    const productVariants = product.variants
    const currentVariant = productVariants?.find(variant => variant?.product?.sku === currentProductSku) ||
        productVariants?.[0]
    return mapOptionsForCurrentVariant(product, currentVariant)
}

export const mapOptionsForCurrentVariant = (product: Product, currentVariant) => {
    if (!currentVariant) {
        return null
    }

    const allOptions = product.options

    return currentVariant?.options?.map((variantOption: ProductVariantOption) => {
        const allSameTypeOptions = allOptions?.find((option) => option?.key === variantOption?.key)

        const fullVariantOptions = allSameTypeOptions?.values?.find((sameTypeOption) => {
            return sameTypeOption?.value === variantOption?.value
        })

        return {
            key: variantOption.key,
            ...fullVariantOptions,
        }
    })
}

export const mapDefaultOptionsForForm = (product: Product, currentVariant) => {
    if (!product) {
        return null
    }

    const options = mapOptionsForCurrentVariant(product, currentVariant)
    const defaultOptionsArray = {}
    options?.forEach((option) => {
        defaultOptionsArray[option.key] = option.value || ''
    })

    return defaultOptionsArray
}

export const mapCurrentProductRelatedVariants = (product: Product) => {
    if (!product) {
        return null
    }

    const defaultOptions = mapOptionsForProduct(product)

    return product.variants?.filter((variant: ProductVariant) => { //
        let countOfDifferentOptions = 0

        variant.options.forEach((variantOption) => {
            const defaultOptionsIncludeElement = defaultOptions.find((option) => option?.value === variantOption?.value)
            if (!defaultOptionsIncludeElement) {
                countOfDifferentOptions += 1
            }
        })

        return countOfDifferentOptions < 2
    })
}

/**
 $doc$
 * @deprecated seems to be unused now, will be rm latter if it true
 */
const mapConfigurableOptionsWithRelatedProducts = (
    option: ProductOptionConfigurable,
    selectedOptions: ProductVariantOption[],
    relatedVariants: ProductVariant[],
) => {
    const newOptionValues = option?.values?.map((optionValue) => {
        const allVariantsWithSameOption = relatedVariants?.filter((variant: ProductVariant) => {
            return variant?.options?.find((variantOption) => {
                return variantOption.value === optionValue.value
            })
        })

        if (!allVariantsWithSameOption) {
            return null
        }
        if (allVariantsWithSameOption.length === 1) {
            const foundVariant = allVariantsWithSameOption[0]
            return {
                ...optionValue,
                url: foundVariant.product.url,
                price: foundVariant.product.price,
                sku: foundVariant.product.sku,
                variant: foundVariant,
            }
        }

        const fullMatchingOptionsVariant = allVariantsWithSameOption.find((variant) => {
            return variant?.options.every((option) => {
                return selectedOptions.find((selectedOption) => option.value === selectedOption.value)
            })
        })

        if (!fullMatchingOptionsVariant) {
            logger.log('cant find option variant')
        }

        return {
            ...optionValue,
            url: fullMatchingOptionsVariant?.product?.url,
            price: fullMatchingOptionsVariant?.product?.price,
            sku: fullMatchingOptionsVariant?.product?.sku,
            variant: fullMatchingOptionsVariant,
        }
    })

    return {
        ...option,
        values: newOptionValues,
    }
}

// TODO add return interface
export const mapOptionsWithRelatedProducts = (
    options: ProductOptionUnion[],
    selectedOptions: ProductVariantOption[],
    relatedVariants: ProductVariant[],
) => {
    return options?.map((originalOption, originalIndex) => {
        // ignore logic for non configurable options
        if (!(originalOption?.type in ProductConfigurableOptionType)) {
            return originalOption
        }

        const mappedOption = { ...originalOption }
        mappedOption.values = mappedOption.values?.map((originalValue) => {
            const newOptionValue = { ...originalValue }
            // disable value
            newOptionValue.isInStock = false
            // check if value selected
            const isSelectedValue = selectedOptions?.some((selectedOption) => {
                return selectedOption.key === mappedOption.key && selectedOption.value === newOptionValue.value
            })

            // process variants
            const fullMatchVariants = []
            const candidateVariants = []
            relatedVariants?.forEach((variant) => {
                const fullMatches = []
                let isMatchedVariant = false
                let isCandidate = false
                options?.forEach((option) => {
                    // ignore non configurable options
                    if (!(option?.type in ProductConfigurableOptionType)) {
                        return
                    }
                    const selectedOption = selectedOptions?.find((selectedOption) => {
                        return option.key === selectedOption.key
                    })

                    variant?.options?.forEach((variantOption) => {
                        if (option.key === mappedOption.key) {
                            if (variantOption.key === mappedOption.key) {
                                // check if the same value
                                if (variantOption.value === newOptionValue.value) {
                                    // set if variant has current value
                                    isMatchedVariant = true
                                    fullMatches.push(true)
                                }
                            }
                        } else {
                            if (variantOption.key === option.key) {
                                if (variantOption?.value === selectedOption?.value) {
                                    fullMatches.push(true)
                                }
                                if (variant?.product?.stock?.type === ProductStockType.InStock) {
                                    isCandidate = true
                                }
                            }
                        }
                    })
                })
                if (isMatchedVariant) {
                    if (fullMatches.length === variant.options.length) {
                        fullMatchVariants.push(variant)
                    }
                    if (isCandidate) {
                        candidateVariants.push(variant)
                    }
                }
            })

            const foundVariants = candidateVariants.filter((variant) => {
                let isFullMatched = true
                for (let i = 0; i < originalIndex; ++i) {
                    const option = options[i]
                    // ignore non configurable options
                    if (!(option?.type in ProductConfigurableOptionType)) {
                        continue
                    }
                    let isFound = false
                    const selectedOption = selectedOptions?.find((selectedOption) => option.key === selectedOption.key)
                    variant.options.forEach((variantOption) => {
                        if (variantOption.key === option.key) {
                            if (variantOption.value === selectedOption.value) {
                                isFound = true
                            }
                        }
                    })
                    if (!isFound) {
                        isFullMatched = false
                    }
                }

                return isFullMatched
            })

            if (isSelectedValue) {
                const variant = fullMatchVariants[0]
                newOptionValue.isInStock = variant.product.stock.type === ProductStockType.InStock || foundVariants.length > 0
                newOptionValue.url = variant?.product?.url
                newOptionValue.price = variant?.product?.price
                newOptionValue.sku = variant?.product?.sku
            } else {
                // logic when option non selected
                // need to find variant which matches all selections (best)
                // OR
                // we need to find variant which matches all previous selection PLUS current value
                const variant = fullMatchVariants[0] ? fullMatchVariants[0] : foundVariants[0]
                if (variant) {
                    const optionInStock = variant.product.stock.type === ProductStockType.InStock ||
                        foundVariants.length > 0

                    newOptionValue.isInStock = optionInStock
                    newOptionValue.url = variant.product?.url
                    newOptionValue.price = variant.product?.price
                    newOptionValue.sku = variant.product?.sku
                }
            }
            return newOptionValue
        })
        return mappedOption
    })
}

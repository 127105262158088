import TextOnlyButton from '@pwa-onilab/ui/components/elements/Buttons/TextOnlyButton'
import FormTitle from '@pwa-onilab/ui/components/modules/Auth/elements/FormTitle'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import Loader from '@pwa-onilab/ui/components/UI/Loader'
import { OTP_DEFAULT_ATTEMPT_VALUE } from '@pwa-onilab/ui/constants'
import { VERIFY_ONE_TIME_PASSWORD_LENGTH } from '@pwa-onilab/ui/constants/customer'
import { handleKeyDown, onChangeCodeInput, onPasteCodeInput } from '@pwa-onilab/ui/helpers/OneTimePassword'
import { IOneTimePassword } from '@pwa-onilab/ui/hooks'
import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import styles from './OneTimePassword.module.scss'

interface OneTimePasswordInterface extends IOneTimePassword {
    onChangeEmail: () => void
    onValidCode: (token?: string) => void
    email: string
}

const OneTimePassword = (
    {
        onChangeEmail,
        onValidCode,
        email,
        checkOneTimePassword,
        resendCode,
        timerValue,
        attemptsLeft,
        isResendCode,
    }: OneTimePasswordInterface) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { t } = useTranslation()
    const { register, setValue, getValues } = useForm()

    const arrayOfCells = useMemo(() => {
        return new Array(VERIFY_ONE_TIME_PASSWORD_LENGTH).fill(0)
    }, [])

    const clearValues = useCallback(() => {
        const formValues = getValues()
        Object.keys(formValues).forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(formValues, key)) {
                setValue(key, '')
            }
        })
    }, [])

    const checkForm = useCallback(({ nextTarget }) => {
        const values = Object.values(getValues())
        const code: string = values.reduce((acc: string, el: string) => {
            acc += el
            return acc
        }, '')

        if (code.length === VERIFY_ONE_TIME_PASSWORD_LENGTH) {
            nextTarget?.blur()
            setIsLoading(true)
            checkOneTimePassword({ code, email }).then((data) => {
                setIsLoading(false)
                clearValues()
                const { valid, token } = data
                if (valid) {
                    onValidCode?.(token)
                }
            })
        }
    }, [checkOneTimePassword, email])

    const changeCodeHandler = useCallback((event) => {
        onChangeCodeInput(event, setValue, checkForm)
    }, [checkForm])

    const pasteCodeHandler = useCallback((event) => {
        onPasteCodeInput(event, checkForm)
    }, [checkForm])

    return (
        <>
            <FormTitle
                subtitle={(
                    <Trans
                        i18nKey="oneTimePassword.description"
                        values={{ email }}
                        components={{ bold: <b /> }}
                    />
                )}
                subtitleClassName={styles.formSubtitle}
                buttonText={t('oneTimePassword.changeQuestion')}
                onClick={onChangeEmail}
            />
            <form className={styles.otpForm}>
                {arrayOfCells.map((item, index) => {
                    return (
                        <input
                            key={index}
                            type="number"
                            min="0"
                            max="9"
                            maxLength={1}
                            onPaste={pasteCodeHandler}
                            {...register(`code${index}`)}
                            onChange={changeCodeHandler}
                            onKeyDown={handleKeyDown}
                            pattern="\^d$"
                            disabled={!attemptsLeft}
                            className={styles.input}
                        />
                    )
                })}
            </form>

            {attemptsLeft < OTP_DEFAULT_ATTEMPT_VALUE && attemptsLeft > 0 && (
                <div className={styles.incorrectPassword}>
                    <Trans
                        i18nKey={attemptsLeft > 1
                            ? 'oneTimePassword.incorrectCode.text'
                            : 'oneTimePassword.incorrectCodeNotPlural.text'}
                        values={{ attemptsLeft }}
                        components={{ bold: <b /> }}
                    />
                </div>
            )}
            {attemptsLeft === 0 && isResendCode && (
                <div className={styles.incorrectPassword}>
                    {t(timerValue ? 'oneTimePassword.incorrectCode.tryLatter' : 'oneTimePassword.incorrectCode.tryResend')}
                </div>
            )}

            {attemptsLeft === OTP_DEFAULT_ATTEMPT_VALUE && isResendCode && (
                <div className={styles.resendOtps}>
                    {t('oneTimePassword.newCodeSent.text')}
                </div>
            )}

            <div className={styles.resendWrapper}>
                <div className={styles.resend}>
                    <span className={styles.resendTitle}>{t('oneTimePassword.resend.description')}</span>
                    <TextOnlyButton onClick={resendCode} disabled={!!timerValue} className={styles.resendCodeButton}>
                        {t('oneTimePassword.resend.button')}
                        <Icon
                            name="chevron"
                            className={styles.arrowIcon}
                        />
                    </TextOnlyButton>
                </div>
                <div className={styles.timer}>{timerValue}</div>
            </div>
            <Loader visible={isLoading} isAbsolute />
        </>
    )
}

export default OneTimePassword

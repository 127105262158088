import { InputField } from '@pwa-onilab/ui/components/elements/Fields'
import { PERSONAL_INFORMATION_INPUT_SET_VALUE_OPTIONS } from '@pwa-onilab/ui/components/modules/Account'
import { REGEX_NAME } from '@pwa-onilab/ui/constants/patterns'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const PersonalInfoFullNameField = () => {
    const { t } = useTranslation()
    const { register, setValue, formState: { errors } } = useFormContext()
    return (
        <InputField
            field="fullName"
            placeholder={t('global.placeholder.name')}
            register={register}
            setValue={setValue}
            setValueOptions={PERSONAL_INFORMATION_INPUT_SET_VALUE_OPTIONS}
            errors={errors}
            rules={{
                required: t('global.errors.required'),
                pattern: {
                    value: REGEX_NAME,
                    message: t('global.errors.name'),
                },
            }}
        />
    )
}

export default PersonalInfoFullNameField

import { useCartSetShippingMethodsMutation } from '@pwa-concept/modules/cart/graphql/mutations/CartSetShippingMethods'
import { ShippingMethodsInput } from '@pwa-concept/modules/graphql'

const useCartSetShippingMethod = () => {
    const [setShippingMethod] = useCartSetShippingMethodsMutation()

    return async ({ carrierCode, methodCode, shippingAdditionalFields }: ShippingMethodsInput) => {
        return await setShippingMethod({
            variables: {
                input: {
                    shippingMethods: [
                        {
                            carrierCode,
                            methodCode,
                            shippingAdditionalFields,
                        },
                    ],
                },
            },
            ignoreResults: true,
        })
    }
}

export default useCartSetShippingMethod

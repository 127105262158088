import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { IAccountSubpageEmptyButton } from '@pwa-onilab/ui/components/modules/AccountSubpages'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import { StickyBar } from '@pwa-onilab/ui/components/UI/StickyBar'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './AccountSubpageEmptyButton.module.scss'

const AccountSubpageEmptyButton = ({ title, isPrimary, isBlackTransparent, onClick, className, stickyBarClassName }: IAccountSubpageEmptyButton) => {
    const { isBreakpointUp } = useMediaBreakpoint()

    if (isBreakpointUp('lg')) {
        return (
            <Button
                isFullWidth
                isPrimary={isPrimary}
                isBlackTransparent={isBlackTransparent}
                isBold
                className={classNames(styles.button, className)}
                onClick={onClick}
            >
                {title}
            </Button>
        )
    }

    return (
        <StickyBar className={classNames(styles.stickyBar, stickyBarClassName)}>
            <Button
                isFullWidth
                isPrimary={isPrimary}
                isBlackTransparent={isBlackTransparent}
                isBold
                className={className}
                onClick={onClick}
            >
                {title}
            </Button>
        </StickyBar>
    )
}

export default AccountSubpageEmptyButton

import Icons, { tIconName } from '@pwa-onilab/ui/assets/icons/Icons'
import { CSSProperties, ForwardedRef, forwardRef, ReactElement } from 'react'

export interface IIconProps{
    isImage?: boolean
    name?: tIconName | string
    iconSource?: ReactElement
    src?: string
    alt?: string
    style?: CSSProperties | undefined
    className?: string
}

const Icon = forwardRef((
    {
        isImage,
        name,
        iconSource,
        src,
        alt,
        style,
        className,
        ...props
    }: IIconProps, ref: ForwardedRef<any>) => {
    return !isImage
        ? (
            <span {...props} ref={ref} className={className} style={style}>
                {Icons[name] || iconSource}
            </span>
            )
        : <img {...props} ref={ref} src={src} alt={alt || 'icon'} className={className} style={style} />
})

export default Icon

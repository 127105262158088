import { Product } from '@pwa-concept/modules/graphql'
import { ga4SelectItem } from '@pwa-onilab/ga4/operations/product/selectItem'
import { IGa4EcommerceSelectItem } from '@pwa-onilab/gtm/operations/product/selectItem'
import { ProductBadges } from '@pwa-onilab/ui/components/modules/Product/elements'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { ImageLazy } from '@pwa-onilab/ui/components/UI/Image/ImageLazy'
import { classNames } from '@pwa-onilab/ui/helpers'
import ProductPrice from 'components/overrides/modules/Product/elements/ProductPrice/ProductPrice'
import WishlistButton from 'components/overrides/modules/Product/elements/WishlistButton/WishlistButton'
import { useCustomProductUrl } from 'hooks'
import { CategoryProductCardAttributes, ProductRatingBlock } from 'modules/Product'
import { SALE_LABEL } from 'constants/modules/labelsProduct'
import { checkForSaleLabel } from 'components/modules/Product/utils/saleLabelUtils'

import styles from './SimpleProductCard.module.scss'

// based on src/app/modules/product/graphql/stores/magento2/fragments/ProductInterface.graphql
interface MagentoProductInterface {
    actual_price_per_seed?: number
    original_price_per_seed?: number
    thc_text?: string
    text_attributes?: {
        farvalue?: string
        sipvalue_text?: string
    }
    image?: {
        url?: string
        label?: string
    }
    rating_summary?: number
}

export interface ISimpleProductCard extends Pick<IGa4EcommerceSelectItem, 'itemsListName'> {
    product: Product & MagentoProductInterface
    currentViewVariant?: keyof typeof SIMPLE_PRODUCT_CART_VARIANTS
}

export const SIMPLE_PRODUCT_CART_VARIANTS = {
    default: styles.variantDefault,
    grid: styles.variantGrid,
    column: styles.variantColumn,
}

const SimpleProductCard = (
    {
        product,
        product: {
            url_key: possibleUrlKey, // crutch for parser on homepage
            url: possibleUrl,
            sku,
            name,
            actual_price_per_seed: parsedActualPricePerSeed,
            actualPricePerSeed,
            original_price_per_seed: parsedOriginalPricePerSeed,
            originalPricePerSeed,
            thc_text: parsedThcText,
            thcText: themeThcText,
            image: {
                url: imageSrc,
                label: imageAlt,
            } = {},
            thumbnail: {
                url: thumbSrc,
                description: thumbAlt,
            } = {},
            labels = [],
            textAttributes: {
                farvalue: themeFarvalue,
                sipValueText: themeSipValueText,
            } = {},
            text_attributes: {
                farvalue: originalFarvalue,
                sipvalue_text: parsedSipValueText,
            } = {},
        },
        itemsListName,
        currentViewVariant = 'default',
    }: ISimpleProductCard,
) => {
    const imgSrc = thumbSrc || imageSrc
    const imgAlt = thumbAlt || imageAlt
    const url = possibleUrl || possibleUrlKey

    const pricePerSeedActual = actualPricePerSeed || parsedActualPricePerSeed
    const pricePerSeedOriginal = originalPricePerSeed || parsedOriginalPricePerSeed

    const thcText = themeThcText || parsedThcText
    const sipValueText = themeSipValueText || parsedSipValueText
    const farvalue = themeFarvalue || originalFarvalue
    const labelsNew = []

    const ratingValue = product.ratingSummary || product.rating_summary

    const productCardAttributesData: string[] = [
        farvalue,
        thcText,
        sipValueText,
    ]

    const {
        productLinkState,
        relativeUrl,
    } = useCustomProductUrl(url, sku)

    const sendAnalytics = () => {
        ga4SelectItem({
            itemsListName,
            product,
        })
    }

    if (labels === null) {
        checkForSaleLabel(product?.variants, labelsNew, SALE_LABEL)
    } else {
        checkForSaleLabel(product?.variants, labels, SALE_LABEL)
    }

    return (
        <div className={classNames(styles.wrapper, SIMPLE_PRODUCT_CART_VARIANTS[currentViewVariant])}>
            <div className={styles.badges}>
                <ProductBadges
                    isRowDirection
                    data={labels || labelsNew}
                />
            </div>
            <WishlistButton
                isCategoryCard
                sku={sku}
                className={styles.wishlist}
            />
            <Anchor
                to={relativeUrl}
                state={productLinkState}
                className={styles.imageWrapper}
                onClick={sendAnalytics}
            >
                <ImageLazy src={imgSrc} alt={imgAlt} className={styles.image} />
                <ProductRatingBlock ratingSummary={ratingValue} />
            </Anchor>
            <div className={styles.infoWrapper}>
                <Anchor
                    to={relativeUrl}
                    state={productLinkState}
                    onClick={sendAnalytics}
                >
                    <p className={styles.name}>{name}</p>
                </Anchor>
                <CategoryProductCardAttributes
                    className={styles.attributes}
                    data={productCardAttributesData}
                />
                <ProductPrice
                    actualPricePerSeed={pricePerSeedActual}
                    originalPricePerSeed={pricePerSeedOriginal}
                    isProductPricePerSeed
                    withFromLabel
                    className={styles.price}
                />
            </div>
        </div>
    )
}

export default SimpleProductCard

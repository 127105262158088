import { useQuery } from '@apollo/client'
import { States } from '@pwa-concept/modules/graphql'
import { GetStatesQuery } from 'app/modules/states/graphql/queries'
import { useEffect, useState } from 'react'

interface IUseGetStatesVariables {
    locationId?: String
    stateId?: string
    currentPage: number
    pageSize: Number
}

type tUseGetStates = (variables: IUseGetStatesVariables) => {
    data: States
    loading: boolean
    fetchMore: any
}

// TODO rewrite it, or try read cached page on fetchMore
const useGetStates: tUseGetStates = (variables: IUseGetStatesVariables) => {
    const [cache, setCache] = useState<States | null>(null)
    const { data, loading, fetchMore } = useQuery(GetStatesQuery, {
        variables,
        skip: !variables || !(variables.stateId || variables.locationId),
        notifyOnNetworkStatusChange: true,
        onCompleted: ({ getStates }) => {
            setCache(prevState => {
                if (prevState || !getStates) {
                    return prevState
                }

                return getStates
            })
        },
    })

    useEffect(() => {
        setCache(null)
    }, [variables.stateId, variables.locationId, variables.currentPage])

    return {
        data: cache || data?.getStates,
        loading,
        fetchMore: async ({ page }) => {
            const inputOptions = {
                variables: {
                    ...variables,
                    currentPage: page,
                },
            }

            const { data: { getStates: data } = {} } = await fetchMore(inputOptions)
            if (data) {
                setCache(prevCache => {
                    return {
                        __typename: data.__typename || prevCache?.__typename,
                        items: [
                            ...prevCache?.items,
                            ...data.items,
                        ],
                        pagination: data.pagination || prevCache?.pagination,
                    }
                })
            }
        },
    }
}

export default useGetStates

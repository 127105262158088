import { useCategoryNumResultsValue } from '@pwa-onilab/ui/components/modules/Category'
import { SchemaHelmet } from 'elements/SchemaHelmet'
import { getPriceMinMaxFromFilter, getSchemaHelmetProductCollection } from 'elements/SchemaHelmet/SchemaHelmetHelpers'
import { useBestsellerPageFilters } from 'modules/BestsellersPage/hooks'

const SchemaHelmetBestsellersCollection = ({ title }: { title: string }) => {
    const numResultsCount = useCategoryNumResultsValue()
    const { data: filters, loading: filtersLoading } = useBestsellerPageFilters()

    if (filtersLoading || !filters) {
        return null
    }

    const [priceMin, priceMax] = getPriceMinMaxFromFilter(filters)
    const collectionConfig = getSchemaHelmetProductCollection(title, priceMin, priceMax, numResultsCount)

    return <SchemaHelmet jsonConfig={collectionConfig} />
}

export default SchemaHelmetBestsellersCollection

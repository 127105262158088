import { BREAKPOINT_MOBILE_DELIMETER, TIME_DELAY_ON_BREAKPOINT_CHECK } from '@pwa-onilab/ui/constants/breakpoints'
import { CHECKOUT_ROUTE, ROUTES_WITH_DISABLED_FOOTER } from '@pwa-onilab/ui/constants/navigation'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useMatch } from 'react-router-dom'

const useCheckFooterVisibility = () => {
    const [isHideFooter, setHideFooter] = useState(false)
    const isCheckout = useMatch(CHECKOUT_ROUTE)

    const resizeTimeoutRef = useRef(null)

    const location = useLocation()

    useEffect(() => {
        const checkFooterVisibility = () => {
            const shouldHideFooter = ROUTES_WITH_DISABLED_FOOTER.some(el => location.pathname.indexOf(el) !== -1)

            setHideFooter(() => {
                if (window.innerWidth >= BREAKPOINT_MOBILE_DELIMETER) {
                    return false
                }
                return shouldHideFooter
            })
        }

        checkFooterVisibility()

        const resizeHandler = () => {
            clearTimeout(resizeTimeoutRef.current)

            resizeTimeoutRef.current = setTimeout(checkFooterVisibility, TIME_DELAY_ON_BREAKPOINT_CHECK)
        }

        window.addEventListener('resize', resizeHandler)
        return () => window.removeEventListener('resize', resizeHandler)
    }, [location.pathname])

    return !!isCheckout || isHideFooter
}

export default useCheckFooterVisibility

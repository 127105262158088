import {
    ICartProductProps,
    ICartStateApi,
} from '@pwa-onilab/ui/components/modules/Cart/elements/CartProducts/CartDefaultProduct/CartDefaultProduct'
import QuantityInput from '@pwa-onilab/ui/components/modules/Cart/elements/CartProducts/elements/QuantityInput'
import ProductPrice from '@pwa-onilab/ui/components/modules/Product/elements/ProductPrice'
import { useCartProductCounter } from '@pwa-onilab/ui/hooks'

import css from './CartQuantityPriceBlock.module.scss'

const CartQuantityPriceBlock = (
    {
        setIsLoading,
        isLoading,
        cartItem,
    }: Pick<ICartProductProps & ICartStateApi, 'setIsLoading' | 'isLoading' | 'cartItem'>,
) => {
    const {
        incrementItemQuantity,
        decrementItemQuantity,
        handleQuantityInputChange,
        cartProductMnemoQuantity,
    } = useCartProductCounter({
        setIsLoading,
        cartItem,
    })

    return (
        <div className={css.wrapper}>
            <QuantityInput
                quantity={cartProductMnemoQuantity}
                onIncrease={incrementItemQuantity}
                onDecrease={decrementItemQuantity}
                onInputChange={handleQuantityInputChange}
                isLoading={isLoading}
            />
            <ProductPrice
                price={cartItem.configuredVariant?.price || cartItem.product.price}
                productQuantity={cartItem.quantity}
                isCart
            />
        </div>
    )
}

export default CartQuantityPriceBlock

import { IConfiguredFilterModule } from '@pwa-concept/modules/filter/module'
import { logger } from '@pwa-concept/modules/helpers/logger'

export const FILTER_MODULE_CONFIGURATION: IConfiguredFilterModule = {
    filters: {
        cbd: 'equal',
        climate_zone: 'equal',
        effects: 'equal',
        flowering_time_weeks: 'equal',
        for_beginners: 'equal',
        growing_environment: 'equal',
        high_yield: 'equal',
        is_bestseller: 'equal',
        is_mix_pack: 'equal',
        plant_height: 'equal',
        seeds_type: 'equal',
        terpenes: 'equal',
        thc: 'equal',
        wholesale: 'equal',
        actual_price_per_seed: 'range',
    },
    filterMappers: {
        // haven't custom type field yet
        type: (key, currentValue) => {
            switch (key) {
                default: {
                    logger.error('find unhandled filter ' + key)
                    return {}
                }
            }
        },
    },
}

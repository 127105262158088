import { Resolvers } from '@pwa-concept/modules/graphql'

import Query from './Query'
import StoreConfig from './StoreConfig'

const resolvers: Resolvers = {
    Query,
    StoreConfig,
}

export default resolvers

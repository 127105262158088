import { useRouteFallback } from '@pwa-concept/core'
import { useCms } from '@pwa-concept/modules/cms'
import Parser from '@pwa-onilab/ui/helpers'
import CmsPageHelmet from 'components/overrides/modules/CmsPage/elements/CmsPageHelmet/CmsPageHelmet'
import RegistrationHomePageHelmet
    from 'modules/RegistrationHomePage/components/RegistrationHomePageHelmet/RegistrationHomePageHelmet'
import { REGISTRATION_ROUTE } from 'overrides/@pwa-onilab/ui/constants/navigation/navigation'
import { useMemo } from 'react'
import { Outlet, useMatch } from 'react-router-dom'

import css from './HomePage.module.scss'

const HomePage = () => {
    const fallback = useRouteFallback()
    const { data, loading } = useCms({ id: 'home' })

    const registrationRoute = useMatch(REGISTRATION_ROUTE)

    const widgets = useMemo(() => {
        if (loading) {
            return fallback
        }
        if (!data) return null

        if (data?.widgets?.length > 0) {
            // eslint-disable-next-line consistent-return
            return data.widgets.map((
                {
                    id,
                    content,
                }) => <Parser key={id} content={content} />)
        }

        if (data.content) {
            // eslint-disable-next-line consistent-return
            return <Parser content={data.content} />
        }
        return null
    }, [data, loading])

    return (
        <main className={css.root}>
            {
                registrationRoute
                    ? <RegistrationHomePageHelmet />
                    : <CmsPageHelmet cms={data} />
            }
            {widgets}
            <Outlet />
        </main>
    )
}

export default HomePage

import { filterFaqTabs, STANDART_FAQ_DEBOUNCE_TIME } from '@pwa-onilab/ui/components/widgets/FaqWidget'
import { debounce } from 'debounce'
import { useCallback, useState } from 'react'

const useFaqSearchInput = (activeTab, tabs, setCurrentTab, rollbackToTab, isTabChoosen, isTabFromSearch) => {
    const [searchValue, setSearchValue] = useState('')
    const [tabBeforeSearch, setTabBeforeSearch] = useState(null)
    const [debounceTime, setDebounceTime] = useState(STANDART_FAQ_DEBOUNCE_TIME)

    const filterTabs = useCallback(debounce((newVal) => {
        if (!newVal.length) {
            if (tabBeforeSearch) {
                setCurrentTab(tabBeforeSearch, false)

                setTabBeforeSearch(null)
            }

            if (!isTabChoosen && isTabFromSearch) {
                rollbackToTab()
            }

            return
        }

        if (!tabBeforeSearch) {
            setTabBeforeSearch(activeTab)
        }

        const benchmark = newVal.toLowerCase()

        const currentTab = filterFaqTabs(tabs, benchmark)

        setCurrentTab(currentTab)
    }, debounceTime), [tabs, tabBeforeSearch, activeTab, isTabChoosen, isTabFromSearch, debounceTime])

    const handleChange = useCallback((newVal) => {
        setSearchValue(newVal)

        if (newVal !== '') {
            setDebounceTime(STANDART_FAQ_DEBOUNCE_TIME)
            filterTabs(newVal)
        } else {
            setDebounceTime(0)
            filterTabs(newVal)
        }
    }, [filterTabs])

    const clearInput = useCallback(() => {
        setSearchValue('')
        setDebounceTime(0)
        filterTabs('')
    }, [filterTabs])

    return {
        searchValue,
        handleChange,
        clearInput,
    }
}

export default useFaqSearchInput

import { FamilyStrainResolvers } from '@pwa-concept/modules/graphql'

const FamilyStrain: FamilyStrainResolvers = {
    id: (_, __, { context }) => context?.id || null,

    urlKey: (_, __, { context }) => {
        return context?.url_key || null
    },

    name: (_, __, { context }) => context?.name || null,

    shortDescription: (_, __, { context }) => context?.short_description || null,

    description: (_, __, { context }) => context?.description || null,

    thumbnail: (_, __, { context }) => context?.thumbnail || null,

    image: (_, __, { context }) => context?.image || null,

    author: (_, __, { context }) => {
        if (!context?.author) {
            return null
        }
        return {
            __context: context.author,
            __typename: 'BlogAuthor',
        }
    },
    createdAt: (_, __, { context }) => context?.created_at || null,
    publishTime: (_, __, { context }) => context?.publish_time || null,

    meta: (_, __, { context }) => {
        return {
            title: context?.meta_title || context?.name || '',
            keywords: context?.meta_keywords || '',
            description: context?.meta_description || '',
            robots: context?.meta_robots || '',
            __typename: 'FamilyStrainMeta',
        }
    },
}

export default FamilyStrain

import { useCart } from '@pwa-concept/modules/cart'
import CartOutOfStockItemsError from '@pwa-onilab/ui/components/elements/Toasts/CartOutOfStockItemsError'
import { CHECKOUT_ROUTE } from '@pwa-onilab/ui/constants/navigation'
import { checkIsSomeCartItemsOutOfStock, throwErrorToastComponent } from '@pwa-onilab/ui/helpers'
import { useCartModal } from '@pwa-onilab/ui/hooks/modals'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const useNavigateToCheckout = () => {
    const { refetch: refetchCart } = useCart()
    const [isShowToCheckoutError, setIsShowToCheckoutError] = useState(false)
    const { closeModal: closeCartModal } = useCartModal()

    const navigate = useNavigate()

    const tryNavigateToCheckout = useCallback(async() => {
        const { data } = await refetchCart()
        const items = data?.cart?.items

        if (!items) {
            return
        }
        const isCartContainsOutOfStock = checkIsSomeCartItemsOutOfStock(items)

        if (isCartContainsOutOfStock) {
            setIsShowToCheckoutError(true)
            throwErrorToastComponent(CartOutOfStockItemsError)
            return
        }
        navigate(CHECKOUT_ROUTE)
        closeCartModal()
    }, [])

    return {
        tryNavigateToCheckout,
        isShowToCheckoutError,
    }
}

export default useNavigateToCheckout

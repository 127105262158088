import { Product } from '@pwa-concept/modules/graphql'
import { ga4SelectItem } from '@pwa-onilab/ga4/operations/product/selectItem'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { useCustomProductUrl } from 'hooks'
import { useTranslation } from 'react-i18next'

import styles from './CategoryProductCardViewAnchor.module.scss'

interface ICategoryProductCardViewProps extends IClassName {
    product: Product
}

const CategoryProductCardViewAnchor = (
    {
        className,
        product,
    }: Partial<ICategoryProductCardViewProps>,
) => {
    const { t } = useTranslation()
    const { relativeUrl, productLinkState } = useCustomProductUrl(product.url, product.sku)

    const sendAnalytics = () => {
        ga4SelectItem({
            itemsListName: 'Category Page',
            product,
        })
    }

    return (
        <Anchor
            to={relativeUrl}
            state={productLinkState}
            className={classNames(styles.button, className)}
            onClick={sendAnalytics}
        >
            {t('categoryPage.button.viewProduct')}
        </Anchor>
    )
}

export default CategoryProductCardViewAnchor

import { $wishlist } from '@pwa-concept/core/models'
import { IApolloMutationsBaseOptions } from '@pwa-concept/modules/common'
import {
    useAddProductsToWishlistMutation,
} from '@pwa-concept/modules/wishlist/graphql/mutations/AddProductsToWishlist'

interface IWishlistAddData {
    items: Array<{
        sku: string
        quantity: number
    }>
    page?: number
    limit?: number
}

const useWishlistAddItem = (baseOptions?: IApolloMutationsBaseOptions) => {
    const [addProductsToWishlistMutation] = useAddProductsToWishlistMutation(baseOptions)

    return async ({ items, page = 1, limit = 20 }: IWishlistAddData) => {
        const { data: { addProductsToWishlist } = {} } = await addProductsToWishlistMutation({
            variables: {
                id: $wishlist.id,
                items,
                page,
                limit,
            },
            ignoreResults: true,
        })

        return {
            data: addProductsToWishlist,
        }
    }
}

export default useWishlistAddItem

import { MediaBreakpointDown, MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import CartDeleteButton from '@pwa-onilab/ui/components/modules/Cart/elements/CartDeleteButton'
import { CartQuantityPriceBlock } from '@pwa-onilab/ui/components/modules/Cart/elements/CartQuantityPriceBlock'
import { ProductBadges } from '@pwa-onilab/ui/components/modules/Product/elements'
import ProductConfiguredOption from '@pwa-onilab/ui/components/modules/Product/elements/ProductConfiguredOption'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { classNames } from '@pwa-onilab/ui/helpers'
import {
    ICartProductProps, ICartStateApi,
} from 'components/overrides/modules/Cart/elements/CartProducts/CartDefaultProduct/CartDefaultProduct'
import ProductCardImage from 'components/overrides/modules/Product/elements/ProductCardImage/ProductCardImage'
import WishlistButton from 'components/overrides/modules/Product/elements/WishlistButton/WishlistButton'
import { useCustomProductUrl } from 'hooks'
import {
    mapCartProductData,
} from 'overrides/@pwa-onilab/ui/mappers/cart/mapCartProductData'
import { useTranslation } from 'react-i18next'

import styles from './CartDefaultProductInStock.module.scss'

const CartDefaultProductInStock = (
    {
        className,
        isLoading,
        index,
        cartItem,
        cartItem: {
            product,
            configurableOptions,
            configuredVariant,
            id,
            quantity,
        },
        setIsLoading,
        openDeleteModal,
        onLinkClick,
    }: Omit<ICartProductProps & ICartStateApi, 'openSimilarProductsModal' | 'cartItemApi' | 'openSimilarProductsModal'>,
) => {
    const {
        imageUrl,
        imageAlt,
        name,
        sku,
        labels,
    } = mapCartProductData(product, configuredVariant)
    const { t } = useTranslation()

    const cartQuantityPriceBlock = (
        <CartQuantityPriceBlock
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            cartItem={cartItem}
        />
    )

    const onClickOpenDeleteModal = openDeleteModal({
        indexInArray: index,
        cartItem,
    })

    const {
        productLinkState,
        relativeUrl,
    } = useCustomProductUrl(product.url, sku)

    return (
        <div className={classNames(styles.wrapper, className)}>
            <div className={styles.mainContentWrapper}>
                <ProductCardImage
                    imageSrc={imageUrl}
                    imageAlt={imageAlt}
                    url={product.url}
                    sku={sku}
                    className={styles.image}
                    onClick={onLinkClick}
                />
                <div className={styles.infoWrapper}>
                    <ProductBadges
                        data={labels}
                        isRowDirection
                        className={styles.badges}
                    />
                    <Anchor
                        to={relativeUrl}
                        state={productLinkState}
                    >
                        <p className={styles.name}>{name}</p>
                    </Anchor>
                    {
                        configurableOptions?.map(({ optionLabel, valueLabel, optionValueUid }) => (
                            <ProductConfiguredOption
                                key={optionValueUid}
                                optionLabel={optionLabel}
                                valueLabel={valueLabel}
                            />
                        ))
                    }
                    <MediaBreakpointUp name="lg">
                        {cartQuantityPriceBlock}
                    </MediaBreakpointUp>
                </div>
            </div>
            <MediaBreakpointDown name="lg">
                {cartQuantityPriceBlock}
            </MediaBreakpointDown>

            <div className={classNames(styles.buttonsWrapper)}>
                <WishlistButton
                    isCart
                    sku={product.sku}
                    className={styles.wishlist}
                >
                    <span className={styles.wishlistDescription}>{t('cart.wishlist.save')}</span>
                </WishlistButton>

                <CartDeleteButton onClick={onClickOpenDeleteModal} />
            </div>
        </div>
    )
}

export default CartDefaultProductInStock

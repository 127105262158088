import { makeVar } from '@apollo/client'
import converterCurrencyCodeToSign, {
    tCurrencyCode,
} from '@pwa-concept/modules/currency/helpers/converterCurrencyCodeToSign'

const currencySign = makeVar<string>('')
const currencyCode = makeVar<tCurrencyCode>('EUR')

export const $currencySign = {
    get sign () {
        return currencySign()
    },

    set sign (newVal: string | null) {
        currencySign(newVal)
    },

    get code() {
        return currencyCode()
    },

    set code(newVal: tCurrencyCode) {
        currencyCode(newVal)
        this.sign = converterCurrencyCodeToSign(newVal || 'EUR')
    },
}

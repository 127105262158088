import { Skeleton } from '@pwa-onilab/ui/components/elements/Skeleton'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './BrandsSectionCard.module.scss'

interface INewArrivalSlideSkeletonProps {
    className?: string
}

const BrandsSectionCardSkeleton = ({ className }: INewArrivalSlideSkeletonProps) => (
    <div className={styles.wrapperSkeleton}>
        <Skeleton className={classNames(className, styles.skeleton)} />
    </div>
)

export { BrandsSectionCardSkeleton }

import { useMediaBreakpoint } from '@pwa-onilab/ui//components/elements/MediaBreakpoint'
import {
    AccountHeader,
    AccountMobileHeader,
    AccountPageHelmet,
} from '@pwa-onilab/ui/components/modules/Account'
import useAccountPageTryLogOut from '@pwa-onilab/ui/components/modules/Account/hooks'
import { AccountOrderModalProvider } from '@pwa-onilab/ui/components/modules/AccountSubpages'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { AccountPageDesktop } from '@pwa-onilab/ui/pages/AccountPage/AccountPageDesktop'
import { AccountPageMobile } from '@pwa-onilab/ui/pages/AccountPage/AccountPageMobile'

import styles from './AccountPage.module.scss'

const AccountPage = () => {
    useAccountPageTryLogOut()

    const AccountPageContent = useMediaBreakpoint().isBreakpointDown('lg')
        ? AccountPageMobile
        : AccountPageDesktop

    return (
        <Container className={styles.container}>
            <div className={styles.wrapper}>
                <AccountPageHelmet />
                <AccountMobileHeader />
                <AccountHeader />
                <AccountOrderModalProvider>
                    <AccountPageContent />
                </AccountOrderModalProvider>
            </div>
        </Container>
    )
}

export default AccountPage

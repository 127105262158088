import { findSubstringsWithOneInclude } from '@pwa-onilab/ui/components/widgets/FaqWidget'
import Parser, { classNames } from '@pwa-onilab/ui/helpers'
import { useMemo } from 'react'

interface ISelectedTextInactiveProps {
    text: string
    benchmark: string
    wrapperClassName: string
    activeClassName: string
}

const SelectedTextInactive = (
    {
        text,
        benchmark,
        wrapperClassName,
        activeClassName,
    }: ISelectedTextInactiveProps,
) => {
    const splitedText = useMemo(() => {
        if (!(text && benchmark)) {
            return null
        }
        return findSubstringsWithOneInclude(text, benchmark)
    }, [text, benchmark])

    return splitedText && (
        <Parser content={`<div class=${classNames(wrapperClassName)}>
                    ${splitedText.reduce((acc, subStr, idx) => {
                        const isActiveSubstring = subStr.toLowerCase() === benchmark.toLowerCase()

                        return `${acc}<span class="${classNames(isActiveSubstring && activeClassName)}" key=${idx}>${subStr}</span>`
                    }, '')}
                </div>`}
        />
    )
}

export default SelectedTextInactive

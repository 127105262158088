import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { HOME_ROUTE } from '@pwa-onilab/ui/constants'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import styles from './WishlistEmpty.module.scss'

const WishlistEmpty = (
    {
        className,
    }: IClassName,
) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const goToHomePage = () => {
        navigate(HOME_ROUTE)
    }

    return (
        <div className={classNames(styles.wrapper, className)}>
            <Icon
                name="wishlist"
                className={styles.icon}
            />
            <div className={styles.title}>
                {t('wishlist.empty')}
            </div>
            <div className={styles.buttonWrapper}>
                <Button
                    isPrimaryTransparent
                    isFullWidth
                    isBold
                    onClick={goToHomePage}
                    className={styles.button}
                >
                    {t('global.buttons.continueShopping')}
                </Button>
            </div>
        </div>
    )
}

export default WishlistEmpty

import { MediaBreakpointDown, MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { CartDesktopNavigation, CartMobileNavigation } from '@pwa-onilab/ui/pages/CartPage'

const CartPage = () => {
    return (
        <>
            <MediaBreakpointUp name="lg">
                <CartDesktopNavigation />
            </MediaBreakpointUp>

            <MediaBreakpointDown name="lg">
                <CartMobileNavigation />
            </MediaBreakpointDown>
        </>
    )
}

export default CartPage

import { CustomerAddress } from '@pwa-concept/modules/graphql'
import { ADDRESS_FORM_FIELDS_TYPE } from '@pwa-onilab/ui/components/modules/AddressForm/constants/addressFormConstants'
import { extractCustomerFullName } from '@pwa-onilab/ui/mappers/addressMappers/extractCustomerFullName'

export const extractFormValuesFromAddress = (address: CustomerAddress): ADDRESS_FORM_FIELDS_TYPE => {
    if (!address) {
        return null
    }
    const {
        city,
        company,
        countryCode,
        defaultBilling,
        defaultShipping,
        firstName,
        middleName,
        lastName,
        postcode,
        street,
        telephone,
        region,
        vatId,
    } = address
    const fullName = extractCustomerFullName(firstName, middleName, lastName)

    const result: ADDRESS_FORM_FIELDS_TYPE = {
        city,
        company,
        countryCode,
        defaultBilling,
        defaultShipping,
        fullName,
        postcode,
        street1: street?.[0],
        street2: street?.[1],
        telephone,
        region: region.region,
        regionId: region.regionId,
        vatId,
    }

    return result
}

import { gql } from '@apollo/client'
import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'

let lastSearchedValue = ''

const liveSearch: QueryResolvers['liveSearch'] = async (
    _,
    { search, pageSize },
) => {
    lastSearchedValue = search

    const response = await api.graphql(gql`
        query(
            $search: String!
            $pageSize: Int,
            $isFullProduct: Boolean = false,
        ) {
            liveSearchResults(search: $search, pageSize: $pageSize) {
                categories {
                    id
                    link
                    name
                    product_count
                }
                products (
                    search: $search,
                    pageSize: $pageSize
                ) {
                    page_info {
                        current_page
                        page_size
                        total_pages
                    }
                    items {
                        ... ProductInterface
                    }
                }
            }
        }
    `).query({
        search,
        pageSize,
    })

    if (search !== lastSearchedValue) {
        return null
    }

    if (!response?.data?.liveSearchResults) {
        return null
    }

    return {
        __context: response.data.liveSearchResults,
        __typename: 'LiveSearchResult',
    }
}

export default liveSearch

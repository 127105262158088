import {
    CategoryItems,
    useCategoryNumResultsValue,
    useSetCategoryNumResults,
} from '@pwa-onilab/ui/components/modules/Category'
import { PageSortPanel } from 'elements/PageSortPanel'
import { useBestsellerPageFilterSortValues } from 'modules/BestsellersPage/hooks'

import styles from './BestsellerPageItems.module.scss'

const BestsellerPageItems = () => {
    const numResults = useCategoryNumResultsValue()
    const setNumResults = useSetCategoryNumResults()

    const { currentValues, onChangeValue } = useBestsellerPageFilterSortValues()

    return (
        <div className={styles.wrapper}>
            <PageSortPanel
                numResults={numResults}
                onChangeValue={onChangeValue}
                currentValues={currentValues}
            />
            <CategoryItems
                currentValues={currentValues}
                setNumResults={setNumResults}
            />
        </div>
    )
}

export default BestsellerPageItems

import { MediaBreakpointDown, MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { AUTH_TABS } from '@pwa-onilab/ui/components/modules/Auth/elements/AuthContent/enums/authTabs'
import { IAuthContent } from '@pwa-onilab/ui/components/modules/Auth/elements/AuthContent/IAuthContent'
import LoginForm from '@pwa-onilab/ui/components/modules/Auth/elements/LoginForm/LoginForm'
import AuthOneTimePasswordModal from '@pwa-onilab/ui/components/modules/Auth/elements/OneTimePassword/Modal'
import { SignUpForm } from '@pwa-onilab/ui/components/modules/Auth/elements/SignUpForm'
import OneTimePassword from '@pwa-onilab/ui/components/modules/OneTimePassword'
import { REGISTRATION_ROUTE } from 'overrides/@pwa-onilab/ui/constants/navigation/navigation'
import { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

const AuthContent = (
    {
        closeModal,
        handleOpenOneTimePassword,
        checkOneTimePassword,
        resendCode,
        timerValue,
        attemptsLeft,
        isResendCode,
    }: IAuthContent) => {
    const location = useLocation()

    const [currentTab, setCurrentTab] = useState<AUTH_TABS>(
        location.pathname === REGISTRATION_ROUTE
            ? AUTH_TABS.signUp
            : AUTH_TABS.login,
    )
    const [email, setEmail] = useState('')

    const handleTabChange = useCallback((tab: AUTH_TABS) => () => {
        setCurrentTab(tab)
    }, [])

    const openOneTimePasswordForm = useCallback(async({ email }) => {
        setEmail(email)

        const successOtpHandler = handleTabChange(AUTH_TABS.recovery)
        await handleOpenOneTimePassword(email, successOtpHandler)
    }, [])

    const LoginFormMemoized = useMemo(() => {
        return (
            <LoginForm
                handleClickOnSignUp={handleTabChange(AUTH_TABS.signUp)}
                afterSignInHandler={closeModal}
                openOneTimePasswordModal={openOneTimePasswordForm}
            />
        )
    }, [openOneTimePasswordForm])

    const OneTimePasswordFormMemoized = (
        <OneTimePassword
            email={email}
            onValidCode={closeModal}
            onChangeEmail={handleTabChange(AUTH_TABS.login)}
            checkOneTimePassword={checkOneTimePassword}
            resendCode={resendCode}
            timerValue={timerValue}
            attemptsLeft={attemptsLeft}
            isResendCode={isResendCode}
        />
    )

    return (
        <div>
            {currentTab === AUTH_TABS.signUp && (
                <SignUpForm
                    handleClickOnLogIn={handleTabChange(AUTH_TABS.login)}
                    handleSignUp={closeModal}
                />
            )}

            <MediaBreakpointUp name="md">
                {currentTab === AUTH_TABS.login && LoginFormMemoized}
                {currentTab === AUTH_TABS.recovery && OneTimePasswordFormMemoized}
            </MediaBreakpointUp>
            <MediaBreakpointDown name="md">
                {
                    (currentTab === AUTH_TABS.recovery || currentTab === AUTH_TABS.login) &&
                    LoginFormMemoized
                }
                <AuthOneTimePasswordModal
                    isOtpChoosen={currentTab === AUTH_TABS.recovery}
                    handleModalClosing={handleTabChange(AUTH_TABS.login)}
                >
                    {OneTimePasswordFormMemoized}
                </AuthOneTimePasswordModal>
            </MediaBreakpointDown>
        </div>
    )
}

export default AuthContent

import { Image } from '@pwa-onilab/ui/components/UI/Image'
import { Source } from '@pwa-onilab/ui/components/UI/Picture'
import { classNames } from '@pwa-onilab/ui/helpers'
import { MouseEventHandler } from 'react'

interface PictureProps {
    className: string
    media: string
    src: string
    srcSet: string
    alt: string
    title: string
    width: string
    height: string
    onClick: MouseEventHandler
}

const Picture = (
    {
        className,
        media,
        src,
        srcSet,
        alt,
        title,
        width,
        height,
        onClick,
    }: Partial<PictureProps>,
) => (
    <picture>
        <Source srcSet={srcSet} media={media} />
        <Image
            src={src}
            alt={alt}
            title={title}
            width={width}
            height={height}
            onClick={onClick}
            className={classNames(className)}
        />
    </picture>
)

export default Picture

import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { ImageLazy } from '@pwa-onilab/ui/components/UI/Image/ImageLazy'
import Link from '@pwa-onilab/ui/components/UI/Link/Link'
import Title from '@pwa-onilab/ui/components/UI/Title'
import { FormattedDate } from 'elements/FormattedDate'
import { addSlashToEndUrl } from 'helpers'
import { useTranslation } from 'react-i18next'

import styles from './BlogCard.module.scss'

const BlogCard = ({ post }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.container}>
            <Link
                className={styles.imageWrapper}
                to={addSlashToEndUrl(post.identifier)}
                aria-label={t('anchors.description.blogPage')}
            >
                <ImageLazy
                    src={post.thumbnail}
                    alt={post.featuredImgAlt}
                    className={styles.image}
                />
            </Link>
            <div className={styles.content}>
                <FormattedDate
                    date={post.publishTime}
                    className={styles.publishDate}
                />
                <Title className={styles.title} type="h3" text={post.title} />
                <div className={styles.description}>{post.shortContent}</div>
                <Link
                    className={styles.readMore}
                    to={addSlashToEndUrl(post.identifier)}
                >
                    {t('blogCard.readMore')}
                    <Icon name="chevron" className={styles.icon} />
                </Link>
            </div>
        </div>
    )
}

export default BlogCard

import { IMenuObjData } from '@pwa-onilab/ui/components/modules/Menu'
import {
    MobileMenuAnchor,
} from '@pwa-onilab/ui/components/modules/MobileMenuPage'
import { Link } from '@pwa-onilab/ui/components/UI/Link'

interface IMobileMenuTopNodeProps {
    isClosedList?: boolean
    name: string
    url?: string
    nodeId?: number
    items?: IMenuObjData[]
}

const MobileMenuTopNode = (
    {
        isClosedList,
        name,
        url,
        nodeId,
    }: IMobileMenuTopNodeProps,
) => {
    if (isClosedList) {
        return (
            <MobileMenuAnchor
                isArrow
                isBorderBottom
                asProps={{
                    to: `${nodeId}`,
                }}
                as={Link}
            >
                {name}
            </MobileMenuAnchor>
        )
    }

    return (
        <MobileMenuAnchor
            isBorderBottom
            asProps={{
                to: `/${url}`,
            }}
            as={Link}
        >
            {name}
        </MobileMenuAnchor>
    )
}

export default MobileMenuTopNode

import { InputField } from '@pwa-onilab/ui/components/elements/Fields'
import {
    ADDRESS_FORM_FIELDS,
    ADDRESS_FORM_INPUT_SET_VALUE_OPTIONS,
    IAddressFormField,
    useCityFieldRules,
} from '@pwa-onilab/ui/components/modules/AddressForm'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const AddressFormCityField = ({ className }: IAddressFormField) => {
    const { t } = useTranslation()
    const { register, setValue, formState: { errors } } = useFormContext()
    const rules = useCityFieldRules()

    return (
        <InputField
            className={className}
            placeholder={t('addressForm.city.text')}
            field={ADDRESS_FORM_FIELDS.CITY}
            register={register}
            setValue={setValue}
            setValueOptions={ADDRESS_FORM_INPUT_SET_VALUE_OPTIONS}
            rules={rules}
            errors={errors}
        />
    )
}

export default AddressFormCityField

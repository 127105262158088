import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type CmsBlocksQueryVariables = Types.Exact<{
  identifiers?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
}>;


export type CmsBlocksQuery = { __typename?: 'Query', cmsBlocks?: { __typename?: 'CmsBlocksOutput', items?: Array<{ __typename?: 'CmsBlock', content?: string | null, title?: string | null, identifier?: string | null } | null> | null } | null };


export const CmsBlocksDocument = gql`
    query CmsBlocks($identifiers: [String]) {
  cmsBlocks(identifiers: $identifiers) @client {
    items {
      content
      title
      identifier
    }
  }
}
    `;

/**
 * __useCmsBlocksQuery__
 *
 * To run a query within a React component, call `useCmsBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmsBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmsBlocksQuery({
 *   variables: {
 *      identifiers: // value for 'identifiers'
 *   },
 * });
 */
export function useCmsBlocksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CmsBlocksQuery, CmsBlocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CmsBlocksQuery, CmsBlocksQueryVariables>(CmsBlocksDocument, options);
      }
export function useCmsBlocksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CmsBlocksQuery, CmsBlocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CmsBlocksQuery, CmsBlocksQueryVariables>(CmsBlocksDocument, options);
        }
export type CmsBlocksQueryHookResult = ReturnType<typeof useCmsBlocksQuery>;
export type CmsBlocksLazyQueryHookResult = ReturnType<typeof useCmsBlocksLazyQuery>;
export type CmsBlocksQueryResult = Apollo.QueryResult<CmsBlocksQuery, CmsBlocksQueryVariables>;
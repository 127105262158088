import * as Sentry from '@sentry/react'
import Parser, { IParser } from 'overrides/@pwa-onilab/ui/helpers/parser'

const ParserBounded = (parserProps: IParser) => {
    return (
        <Sentry.ErrorBoundary fallback={null}>
            <Parser {...parserProps} />
        </Sentry.ErrorBoundary>
    )
}

export default ParserBounded

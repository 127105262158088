export enum APP_CMS_CUSTOM_ROUTE_TYPES {
    BLOG_POST= 'BLOG_POST',
    BLOG_CATEGORY= 'BLOG_CATEGORY',
    BESTSELLERS='BESTSELLERS',
    BLOG_AUTHOR= 'BLOG_AUTHOR',
    HOMEPAGE_BLOG='HOMEPAGE_BLOG',
    FAMILY_STRAIN='FAMILY_STRAIN',
    HOMEPAGE_FAMILY_STRAIN='HOMEPAGE_FAMILY_STRAIN',
    LOCATION='LOCATION',
    LOCATION_STATE='LOCATION_STATE',
    WHOLESALE='WHOLESALE',
    CATEGORIES_AS_STRAINS='CATEGORIES_AS_STRAINS',
    WORLD='WORLD',
}

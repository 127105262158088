import { HeaderTopMenuPlaceholder } from '@pwa-onilab/ui/components/modules/HeaderTop'
import { MenuNodeLink } from '@pwa-onilab/ui/components/modules/Menu'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useHeaderTopMenu } from '@pwa-onilab/ui/hooks'

import styles from './HeaderTopMenu.module.scss'

interface IHeaderTopMenuProps {
    className: string
}

const HeaderTopMenu = (
    {
        className,
    }: Partial<IHeaderTopMenuProps>,
) => {
    const { menuData, isMenuLoading } = useHeaderTopMenu()

    if (isMenuLoading) {
        return (
            <HeaderTopMenuPlaceholder className={styles.placeholder} />
        )
    }

    if (!menuData.length) return null

    return (
        <div className={classNames(styles.wrapper, className)}>
            {
                menuData.map((item) => (
                    <MenuNodeLink
                        node={item}
                        key={item?.nodeId}
                        className={styles.item}
                    >
                        {item?.title}
                    </MenuNodeLink>
                ))
            }
        </div>
    )
}

export default HeaderTopMenu

import styles from './AuthorPageCategoriesList.module.scss'

const AuthorPageCategoriesListPlaceholder = () => {
    return (
        <ul className={styles.list}>
            <li className={styles.listItemPlaceholder} />
            <li className={styles.listItemPlaceholder} />
            <li className={styles.listItemPlaceholder} />
            <li className={styles.listItemPlaceholder} />
            <li className={styles.listItemPlaceholder} />
            <li className={styles.listItemPlaceholder} />
        </ul>
    )
}

export default AuthorPageCategoriesListPlaceholder

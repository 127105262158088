import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { classNames } from '@pwa-onilab/ui/helpers'
import { MENU_NODE_WRAPPER_TYPE } from 'modules/Menu'

import styles from './DropdownMenuAnchor.module.scss'

interface IDropdownMenuAnchorProps {
    className?: string
    url: string
    title: string
    isBold?: boolean
    onClick?: () => void
    type: string
}

const DropdownMenuAnchor = (
    {
        className,
        url,
        title,
        isBold,
        onClick,
        type,
    }: IDropdownMenuAnchorProps,
) => {
    if (type === MENU_NODE_WRAPPER_TYPE && title) {
        return (
            <div className={styles.wrapperTitle}>
                {title}
            </div>
        )
    }

    if (!url || !title) {
        return null
    }

    return (
        <Anchor
            className={classNames(styles.anchor, isBold && styles.bold, className)}
            to={url}
            onClick={onClick}
        >
            {title}
        </Anchor>
    )
}

export default DropdownMenuAnchor

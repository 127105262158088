import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './Account.module.scss'

interface IAccountButtonProps {
    onClick: () => void
    className?: string
}

const AccountButton = (
    {
        onClick,
        className,
    }: IAccountButtonProps,
) => {
    const { t } = useTranslation()

    return (
        <ButtonInstance
            className={classNames(styles.button, className)}
            onClick={onClick}
            aria-label={t('global.buttons.goToAccount')}
        >
            <Icon
                name="account"
                className={styles.icon}
            />
        </ButtonInstance>
    )
}

export default memo(AccountButton)

import { isNumber } from 'helpers'

const roundNumberToHundredth = (num, rate) => {
    if (!num  || !isNumber(num)) {
        return null
    }

    if (!rate) {
        rate = 1
    }

    return Math.round(100 * +num * rate) / 100
}

export default roundNumberToHundredth


import { IApolloMutationsBaseOptions } from '@pwa-concept/modules/common'
import { WishlistNotificationAdd, WishlistNotificationRemove } from '@pwa-onilab/ui/components/modules/Wishlist'
import { throwSuccessToastComponent, toastifyError } from '@pwa-onilab/ui/helpers/toast'

export const WISHLIST_ADD_PRODUCT_MUTATION_BASE_OPTIONS: IApolloMutationsBaseOptions = {
    onError: toastifyError,
    onCompleted: () => throwSuccessToastComponent(WishlistNotificationAdd),
}

export const WISHLIST_REMOVE_PRODUCT_MUTATION_BASE_OPTIONS: IApolloMutationsBaseOptions = {
    onError: toastifyError,
    onCompleted: () => throwSuccessToastComponent(WishlistNotificationRemove),
}

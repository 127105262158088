import { module } from '@pwa-concept/core'
import { createCatalogId } from '@pwa-concept/modules/catalog/helpers'
import { PaginationModule } from '@pwa-concept/modules/pagination'
import { ProductModule } from '@pwa-concept/modules/product'

import graphqlSchemas from './graphql/schemas'
import stores from './graphql/stores'

const CatalogModule = module(() => ({
    modules: [
        ProductModule,
        PaginationModule,
    ],
    graphqlSchemas,
    graphqlResolvers: stores?.resolvers,
    graphqlStoreFragments: stores?.fragments,
    graphqlStorePossibleTypes: stores?.possibleTypes,
    graphqlPolicies: {
        Query: {
            fields: {
                catalog: {
                    read(existing, props) {
                        const { args: { input } = { input: {} }, toReference, canRead } = props

                        const catalogId = createCatalogId(input)
                        const keyField = catalogId

                        const isEqualExistingKeyField = keyField === existing?.__ref

                        if (isEqualExistingKeyField) {
                            return existing
                        }
                        const reference = toReference({
                            id: catalogId,
                            __typename: 'Catalog',
                        })

                        if (canRead(reference)) {
                            return reference
                        }

                        return undefined
                    },
                },
            },
        },

        Catalog: {
            keyFields: (catalog) => {
                const { input, id } = catalog
                return id || createCatalogId(input)
            },
        },
    },
}))

export default CatalogModule

import Title, { ITitle } from '@pwa-onilab/ui/components/UI/Title'

import styles from './HelpTitle.module.scss'

const HelpTitle = ({ text, type }: ITitle) => {
    return (
        <Title
            type={type}
            text={text}
            className={styles.title}
        />
    )
}

export default HelpTitle

export const calculateProductPrice = (price, quantity = 1) => {
    let current = price?.current.value || 0
    let initial = price?.initial.value || 0
    const perItem = price?.current.value || 0

    if (quantity > 1) {
        current *= quantity
        initial *= quantity
    }
    return {
        current: current.toFixed(2),
        initial: initial.toFixed(2),
        perItem,
    }
}

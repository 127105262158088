import Container from '@pwa-onilab/ui/components/UI/Container'
import { Section } from '@pwa-onilab/ui/components/UI/Section'
import { DescriptionWidgetItem } from 'components/widgets/DescriptionWidget'
import { DisablePageBuilderStyles } from 'elements/DisablePageBuilderStyles'

import styles from './DescriptionWidget.module.scss'

interface IDescriptionWidgetProps {
    items: Array<{
        title: string
        description: string
    }>
}
const DescriptionWidget = (
    {
        items,
    }: IDescriptionWidgetProps,
) => {
    if (!items?.length) {
        return null
    }

    return (
        <Section className={styles.section}>
            <DisablePageBuilderStyles>
                <Container>
                    <div className={styles.wrapper}>
                        <div className={styles.content}>
                            {
                                items.map(({ title, description }) => (
                                    <DescriptionWidgetItem
                                        key={title}
                                        title={title}
                                        description={description}
                                        className={styles.item}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </Container>
            </DisablePageBuilderStyles>
        </Section>
    )
}

export default DescriptionWidget

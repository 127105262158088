
    var doc = {"kind":"Document","definitions":[{"kind":"ObjectTypeExtension","name":{"kind":"Name","value":"Query"},"interfaces":[],"directives":[],"fields":[{"kind":"FieldDefinition","name":{"kind":"Name","value":"getBreadCrumbs"},"arguments":[{"kind":"InputValueDefinition","name":{"kind":"Name","value":"pageType"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"BreadcrumbsPageTypeEnum"}},"directives":[]},{"kind":"InputValueDefinition","name":{"kind":"Name","value":"urlKey"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}},"directives":[]}],"type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Breadcrumb"}}},"directives":[]}]},{"kind":"EnumTypeDefinition","name":{"kind":"Name","value":"BreadcrumbsPageTypeEnum"},"directives":[],"values":[{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"BLOG_POST"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"BLOG_AUTHOR"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"HOMEPAGE_BLOG"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"PRODUCT"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"BESTSELLERS"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"CATEGORIES_AS_STRAINS"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"FAMILY_STRAIN"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"HOMEPAGE_FAMILY_STRAIN"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"LOCATION"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"LOCATION_STATE"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"WHOLESALE"},"directives":[]}]}],"loc":{"start":0,"end":359}};
    doc.loc.source = {"body":"extend type Query {\n    # Put you queries...\n    getBreadCrumbs(pageType: BreadcrumbsPageTypeEnum , urlKey: String): [Breadcrumb]\n}\n\nenum BreadcrumbsPageTypeEnum {\n    BLOG_POST\n    BLOG_AUTHOR\n    HOMEPAGE_BLOG\n    PRODUCT\n    BESTSELLERS\n    CATEGORIES_AS_STRAINS\n    FAMILY_STRAIN\n    HOMEPAGE_FAMILY_STRAIN\n    LOCATION\n    LOCATION_STATE\n    WHOLESALE\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    

import Icon from '@pwa-onilab/ui/components/UI/Icon'

import styles from './ContactInfo.module.scss'
import { memo } from 'react'

interface IContactInfoProps {
    schedule: string
    telephone: string
    mail: string
}

const ContactInfo = (
    {
        schedule = 'Mon-Sat: 9am - 6pm',
        telephone = '+353 56 7721033',
        mail = 'info@bliss.ie',
    }: Partial<IContactInfoProps>,
) => (
    <div className={styles.menu}>
        {schedule && <p>{schedule}</p>}
        {
            telephone && (
                <a href={`tel:${telephone}`}>
                    <div className={styles.iconWrapper}>
                        <Icon name="phone" />
                    </div>
                    <span>
                        {telephone}
                    </span>
                </a>
            )
        }
        {
            mail && (
                <a href={`mailto:${mail}`}>
                    <div className={styles.iconWrapper}>
                        <Icon name="mail" />
                    </div>
                    <span>{mail}</span>
                </a>
            )
        }
    </div>
)

export default memo(ContactInfo)

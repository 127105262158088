import { DefaultModalContent, DefaultModalContentProps } from '@pwa-onilab/ui/components/elements/Modal/elements'
import Overlay from '@pwa-onilab/ui/components/UI/Overlay'
import { Portal } from '@pwa-onilab/ui/components/UI/Portal'
import { PortalProps } from '@pwa-onilab/ui/components/UI/Portal/Portal'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { FC, PropsWithChildren } from 'react'

export interface DefaultModalProps extends DefaultModalContentProps, PortalProps {
    animation?: FC<{ isOpen?: boolean }>
    additionalStyle?: object
    expendAdditionalStyleOnOverlay?: boolean
    overlayClassName?: IClassName['className']
}

const DefaultModal = (
    {
        variant,
        isOpen,
        animation: Animation,
        expendAdditionalStyleOnOverlay = true,
        additionalStyle,
        overlayClassName,
        root,
        ...props
    }: PropsWithChildren<DefaultModalProps>,
) => {
    const jsxOverlay = (
        isOpen && (
            <Overlay
                onClick={props?.onDismiss}
                additionalStyle={expendAdditionalStyleOnOverlay ? additionalStyle : null}
                className={overlayClassName}
            />
        )
    )

    const jsxContent = (
        isOpen ? (
            <Portal root={root}>
                <DefaultModalContent
                    isOpen={isOpen}
                    variant={variant}
                    {...props}
                />
            </Portal>
        ) : (
            <></>
        )
    )

    if (Animation) {
        return (
            <>
                {jsxOverlay}
                <Animation isOpen={isOpen}>
                    {jsxContent}
                </Animation>
            </>
        )
    }

    return (
        <>
            {jsxOverlay}
            {jsxContent}
        </>
    )
}

export { DefaultModal }

import { CmsRouteType } from '@pwa-concept/modules/graphql'

export const getLinkOnProductState = (url, sku) => {
    return {
        cms: {
            sku,
            url,
            type: CmsRouteType.Product,
        },
    }
}

export const getNavigateOnProductState = (url, sku) => {
    return {
        state: {
            cms: {
                sku,
                url,
                type: CmsRouteType.Product,
            },
        },
    }
}

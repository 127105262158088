import { createElement, FunctionComponent } from 'react'
import { toast } from 'react-toastify'

type tToastComponentProps = (
    component: FunctionComponent
) => void

export const throwSuccessToastComponent: tToastComponentProps = (component) => {
    toast.success(createElement(component))
}

export const throwErrorToastComponent: tToastComponentProps = (component) => {
    toast.error(createElement(component))
}

import {
    productAttributesFilters,
    productAttributesFiltersMappers,
} from '@pwa-concept/modules/filter/models/productAttributesFilters'
import { CatalogQueryFilterInput } from '@pwa-concept/modules/graphql'
import { logger } from '@pwa-concept/modules/helpers/logger'

export const productsDecoratorMultipleFilters = (prev: any, filters: CatalogQueryFilterInput[]) => {
    return {
        ...prev,
        filter: {
            ...(prev?.filter || {}),
            ...(filters || [])?.reduce?.((result, { key, value }) => {
                if (!value?.length || !Array.isArray(value)) {
                    return result
                }

                const filterType = productAttributesFilters[key]
                if (filterType) {
                    const filterMapper = productAttributesFiltersMappers[filterType]
                    if (filterMapper) {
                        result[key] = filterMapper(key, value)
                        return result
                    }
                }

                logger.error('find undefined filter')
                result[key] = {
                    in: value,
                }

                return result
            }, {}),
        },
    }
}

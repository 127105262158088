import { CatalogQuerySortInput } from '@pwa-concept/modules/graphql'

const productsDecoratorSort = (prev: any, sort: CatalogQuerySortInput[]) => {
    return {
        ...prev,
        sort: sort?.reduce?.((result, { name, order }) => {
            result[name] = order

            return result
        }, {}),
    }
}

export default productsDecoratorSort

import { IPaginationList, PaginationList } from '@pwa-onilab/ui/components/modules/Pagination'
import Icon from '@pwa-onilab/ui/components/UI/Icon'

import styles from './AccountOrdersPagination.module.scss'

const AccountOrdersPagination = (
    {
        pagination,
        onClick,
    }: Pick<IPaginationList, 'pagination' | 'onClick'>,
) => {
    return (
        <PaginationList
            pagination={pagination}
            onClick={onClick}
            wrapperClassName={styles.wrapper}
            cellClassName={styles.item}
            activeClassName={styles.isActive}
            previousClassName={styles.arrow}
            nextClassName={styles.arrow}
            previousLabel={<Icon name="doubleLeftArrow" className={styles.icon} />}
            nextLabel={<Icon name="nextArrow" className={styles.icon} />}
        />
    )
}

export default AccountOrdersPagination

import { useContextPattern } from '@pwa-onilab/ui/hooks'
import { createContext, PropsWithChildren, useMemo, useState } from 'react'

interface IModalMethodContext {
    openModal: (orderNumber: string) => void
    closeModal: () => void
}

const ModalMethodContext = createContext<IModalMethodContext>(
    { openModal: () => null, closeModal: () => null },
)
ModalMethodContext.displayName = 'AccountOrderModalMethodContext'

const ModalValueContext = createContext<string>('')
ModalValueContext.displayName = 'AccountOrderModalValueContext'

const AccountOrderModalProvider = ({ children }: PropsWithChildren<any>) => {
    const [orderNumber, setOrderNumber] = useState<string>('')

    const modalMethods = useMemo(() => {
        return {
            openModal: (orderNumber: string) => setOrderNumber(orderNumber),
            closeModal: () => setOrderNumber(''),
        }
    }, [])

    return (
        <ModalMethodContext.Provider value={modalMethods}>
            <ModalValueContext.Provider value={orderNumber}>
                {children}
            </ModalValueContext.Provider>
        </ModalMethodContext.Provider>
    )
}

const useAccountOrderModalNumber = () => {
    return useContextPattern(ModalValueContext)
}

const useAccountOrderModal = (): IModalMethodContext => {
    return useContextPattern(ModalMethodContext)
}

export { AccountOrderModalProvider, useAccountOrderModal, useAccountOrderModalNumber }

export const getCustomerNames = (fullNameString: string, isJapaneseName?: boolean) => {
    if (!fullNameString) {
        return null
    }

    const [firstname, ...names] = fullNameString.trim().split(' ').filter((name) => name.length)
    let lastname = null
    let middlename = null

    if (names?.length) {
        lastname = names[names.length - 1]
        if (names.length > 1) {
            middlename = names.slice(0, -1).join(' ')
        }
    }

    return {
        firstname: isJapaneseName ? lastname || firstname : firstname,
        lastname: isJapaneseName ? firstname : lastname,
        middlename,
    }
}

import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { MediaBreakpointDown } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'

import styles from './AccountNavigationLink.module.scss'

interface IAccountNavigationLink {
    url: string
    title: string
}

const AccountNavigationLink = ({ url, title }: IAccountNavigationLink) => {
    return (
        <li>
            <Anchor
                to={url}
                className={styles.link}
                activeClassName={styles.activeLink}
                fullMatch
            >
                {title}

                <MediaBreakpointDown name="lg">
                    <Icon name="chevron" className={styles.icon} />
                </MediaBreakpointDown>
            </Anchor>
        </li>
    )
}

export default AccountNavigationLink

import api from '@pwa-concept/core/api'
import { $auth } from '@pwa-concept/core/models'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const GUEST_CART_QUERY = gql`
    query(
        $cartId: String!
        $isFullProduct: Boolean = false
    ) {
        cart(cart_id: $cartId) {
            recommend_products {
                ... ProductInterface
            }
        }
    }
`

const CUSTOMER_CART_QUERY = gql`
    query(
        $isFullProduct: Boolean = false
    ) {
        customerCart {
            recommend_products {
                ... ProductInterface
            }
        }
    }
`

const resolveQuery = (cartId) => {
    const isCustomerCart = $auth.isToken
    if (isCustomerCart) {
        return {
            query: CUSTOMER_CART_QUERY,
            variables: {},
            makeContextFromData: (data) => data?.customerCart?.recommend_products,
        }
    }

    if (cartId?.length) {
        return {
            query: GUEST_CART_QUERY,
            variables: { cartId },
            makeContextFromData: (data) => data?.cart?.recommend_products,
        }
    }

    return {}
}

const cartRecommendedProducts: QueryResolvers['cartRecommendedProducts'] = async (_, { cartId }) => {
    const { query, variables, makeContextFromData } = resolveQuery(cartId)

    const { data } = await (
        api.graphql(query).query(variables)
    )

    const ctx = makeContextFromData(data)

    if (!ctx) return null

    return ctx?.map?.((__context) => ({
        __context,
        __typename: 'Product',
    })) || []
}

export default cartRecommendedProducts

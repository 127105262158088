import { formatOrderDate } from '@pwa-onilab/ui/components/modules/AccountSubpages/AccountOrderHistory/helpers'
import { OrderBlockRecord } from '@pwa-onilab/ui/components/modules/Order'
import Price from '@pwa-onilab/ui/components/UI/Price'
import { useTranslation } from 'react-i18next'

import styles from './AccountOrderBlockMain.module.scss'

interface IAccountOrderBlockMain {
    date: string
    paymentName: string
    status: string
    total: number
}

const AccountOrderBlockMain = (
    {
        date,
        paymentName,
        status,
        total,
    }: IAccountOrderBlockMain) => {
    const { t } = useTranslation()
    const formattedDate = formatOrderDate(date).date

    return (
        <div className={styles.wrapper}>
            <OrderBlockRecord title={t('AccountPage.orders.createdWithColon')}>
                {formattedDate}
            </OrderBlockRecord>
            <OrderBlockRecord title={t('AccountPage.orders.paymentWithColon')}>
                {paymentName}
            </OrderBlockRecord>
            <OrderBlockRecord title={t('AccountPage.orders.statusWithColon')}>
                {status}
            </OrderBlockRecord>
            <OrderBlockRecord title={t('global.totalWithColon')}>
                <Price isReplaceSpace className={styles.total}>
                    {total}
                </Price>
            </OrderBlockRecord>
        </div>
    )
}

export default AccountOrderBlockMain

import { State } from '@pwa-concept/modules/graphql'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import Title from '@pwa-onilab/ui/components/UI/Title'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { useGetStatePageUrl } from 'hooks'
import { useTranslation } from 'react-i18next'

import styles from './StateCard.module.scss'

interface IStateCard extends IClassName {
    state: State
}

const StateCard = ({ state, className }: IStateCard) => {
    const { t } = useTranslation()
    const url = useGetStatePageUrl()(state.urlKey, state.location?.urlKey)

    return (
        <div className={classNames(styles.wrapper, className)}>
            <Title
                type="h3"
                text={t('locationsPage.states.title', { stateName: state.name })}
                className={styles.title}
            />
            <p className={styles.description}>
                {state.shortDescription}
            </p>
            <Anchor to={url} className={styles.anchor}>
                {t('locationsPage.states.buyCannabis', { stateName: state.name })}
                <Icon name="chevron" className={styles.icon} />
            </Anchor>
        </div>
    )
}

export default StateCard

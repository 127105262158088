import { useCustomerOrder } from '@pwa-concept/modules/customer-orders'
import { CartShippingAddresses, CustomerOrderAddress } from '@pwa-concept/modules/graphql'
import { IOrderComponent } from '@pwa-onilab/ui/components/modules/Order'
import OrderAddress from '@pwa-onilab/ui/components/modules/Order/elements/OrderAddress/OrderAddress'
import { useTranslation } from 'react-i18next'

interface IOrderShippingAddress extends IOrderComponent{
    defaultShippingAddress?: CustomerOrderAddress | CartShippingAddresses
}

const OrderShippingAddress = ({ orderNumber, className, defaultShippingAddress }: IOrderShippingAddress) => {
    const { data: { shippingAddress } = {}, loading } = useCustomerOrder(orderNumber)
    const { t } = useTranslation()

    return (
        <OrderAddress
            address={shippingAddress || defaultShippingAddress}
            title={t('checkoutPage.address.title.shipping')}
            className={className}
            loading={!defaultShippingAddress && loading}
        />
    )
}

export default OrderShippingAddress

import Slider, { SliderNavigation, SliderPagination } from '@pwa-onilab/ui/components/UI/Slider'
import { LocalStoresItem } from '@pwa-onilab/ui/components/widgets/LocalStoresSection'
import { useMemo } from 'react'
import { SwiperSlide } from 'swiper/react'

import styles from './LocalStoresSlider.module.scss'

interface ILocalStoresSliderProps {
    items: Array<{
        address: string
        address_link: string
        desktop_image: string
        mobile_image: string
        phone: string
        title: string
    }>
}

const LocalStoresSlider = (
    {
        items,
    }: ILocalStoresSliderProps,
) => {
    const slides = useMemo(() => (
        items.map(({
            address,
            address_link: addressLink,
            desktop_image: desktopImage,
            mobile_image: mobileImage,
            phone,
            title,
        }) => (
            <SwiperSlide key={title + phone}>
                <LocalStoresItem
                    image={desktopImage}
                    imageMobile={mobileImage}
                    title={title}
                    address={address}
                    telephone={phone}
                    addressLink={addressLink}
                />
            </SwiperSlide>
        ))
    ), [items])

    return (
        <Slider
            breakpoints={{
                575: {},
                360: {
                    pagination: false,
                    navigation: false,
                    slidesPerView: 2.2,
                    spaceBetween: 8,
                },
            }}
        >
            {slides}
            <SliderNavigation
                variant="close"
                className={styles.navigationPosition}
            />
            <SliderPagination
                type="fraction"
                className={styles.paginationPosition}
            />
        </Slider>
    )
}

export default LocalStoresSlider

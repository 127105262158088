import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { PageAuthorPlaceholder, PageListPlaceholder, PageMainImagePlaceholder } from 'elements/BlogStatePages/elements'
import { BlogPostBreadcrumbs } from 'modules/BlogPost'

import styles from './BlogPostPagePlaceholder.module.scss'

const BlogPostPagePlaceholder = () => {
    const isDesktop = useMediaBreakpoint().isBreakpointUp('lg')

    return (
        <Container>
            {
                isDesktop && <BlogPostBreadcrumbs />
            }
            <div className={styles.wrapper}>
                <PageListPlaceholder />
                <div>
                    {
                       isDesktop
                           ? (
                               <>
                                   <PageAuthorPlaceholder />
                                   <PageMainImagePlaceholder />
                               </>
                               )
                           : (
                               <>
                                   <PageMainImagePlaceholder />
                                   <PageAuthorPlaceholder />
                               </>
                               )
                   }
                    <Placeholder className={styles.contentPlaceholder} />
                </div>
            </div>
        </Container>
    )
}

export default BlogPostPagePlaceholder

import { Product } from '@pwa-concept/modules/graphql'
import { useProducts } from '@pwa-concept/modules/product'
import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { Section } from '@pwa-onilab/ui/components/UI/Section'
import { PRODUCT_LIST_TYPES, ProductListContent } from 'components/widgets/ProductList'
import { DisablePageBuilderStyles } from 'elements/DisablePageBuilderStyles'

import styles from './ProductList.module.scss'

interface IProductListProps {
    desktop_type: PRODUCT_LIST_TYPES
    mobile_type: PRODUCT_LIST_TYPES
    products: Product[]
    title: string
}

const ProductList = (
    {
        desktop_type: desktopType,
        mobile_type: mobileType,
        products, // products with minimal data(id, sku)
        title,
    }: IProductListProps,
) => {
    const skip = !products?.length
    const skuArray = products?.map((product) => product.sku)
    const { data, loading } = useProducts({ sku: skuArray }, { skip })

    const contentType: PRODUCT_LIST_TYPES = useMediaBreakpoint().isBreakpointUp('lg') ? desktopType : mobileType

    if (skip) {
        return null
    }
    if (!loading && !data?.items?.length) {
        return null
    }

    return (
        <Section className={styles.section}>
            <DisablePageBuilderStyles>
                <Container>
                    {
                        title && (
                            <h2 className={styles.title}>
                                {title}
                            </h2>
                        )
                    }
                    <ProductListContent
                        type={contentType}
                        products={loading ? products : data?.items}
                        isLoading={loading}
                    />
                </Container>
            </DisablePageBuilderStyles>
        </Section>
    )
}

export default ProductList

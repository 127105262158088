
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import styles from './HeaderStoreSwitcher.module.scss'
import { useState } from 'react'
import React from 'react'
import Logo from '@pwa-onilab/ui/components/UI/Logo'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import Modal from '@pwa-onilab/ui/components/elements/Modal'
const HeaderStoreSwitcher = () => {
    const { t } = useTranslation()
    const state = Cookies.get('current_store') ? false : true
    const [modalIsOpen, setModalIsOpen] = useState(state)
    let currentCountry = Cookies.get('current_store') ? Cookies.get('current_store') : "us"
    currentCountry = currentCountry === 'ca' ? 'Canada' : 'United States'
    const usaAccept = () => {
        Cookies.set('current_store', 'ca', { path: '/' })
        setModalIsOpen(false)
        currentCountry = 'Canada'
        window.location.reload()
    }
    const caAccept = () => {
        Cookies.set('current_store', 'us', { path: '/' })
        setModalIsOpen(false)
        currentCountry = 'United States'
        window.location.reload()
    }
    const onValueChange = (event) => {
        setModalIsOpen(true)
    };

    return (
        <div className={styles.selectCountryText}>
            <p className={styles.selectCountry} onClick={onValueChange}>
                {currentCountry}
            </p>
            <Modal
                className={styles.modalStore}
                isDraggable
                isOverAll
                titleClassName={styles.modalTitle}
                isOpen={modalIsOpen}
            >
                <div className={styles.logoPopup}>
                    <Logo></Logo>
                </div>
                <div>
                    <p className={styles.boldText}>{t('Please select your country.')}</p>
                </div>
                <div className={styles.buttonsWrapper}>
                    <Button
                        isPrimary
                        isBold
                        onClick={caAccept}
                    >
                        {t('USA')}
                    </Button>
                    <Button
                        isPrimary
                        isBold
                        onClick={usaAccept}
                    >
                        {t('Canada')}
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

export default HeaderStoreSwitcher

import { StoreConfig } from '@pwa-concept/modules/graphql'
import { useStoreConfigQuery } from '@pwa-concept/modules/store-config/graphql/queries/StoreConfig'

interface IUseStoreConfig {
    data: StoreConfig
    loading: boolean
}

const useStoreConfig = (): IUseStoreConfig => {
    const { data, loading } = useStoreConfigQuery()

    return {
        data: data?.storeConfig || undefined,
        loading,
    }
}

export default useStoreConfig

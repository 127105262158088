import { WidgetsRenderer } from '@pwa-onilab/ui/components/widgets/WidgetsRenderer'
import { ParserImage } from 'components/widgets/ParserImage'
import parser, { attributesToProps, DOMNode, domToReact, HTMLReactParserOptions } from 'html-react-parser'
import { NavHashLink as Link } from 'react-router-hash-link'

export interface IParser {
    content: string
    onClickToLink?: () => void
    customReplace?: (domNode: DOMNode) => JSX.Element
    customReplaceConditionFunc?: (domNode: DOMNode) => boolean
}

const Parser = (
    {
        content,
        onClickToLink = () => {},
        customReplace,
        customReplaceConditionFunc,
    }: IParser) => {
    const processLink = ({ attribs, children }) => {
        const { href, ...attrs } = attribs

        if (href) {
            // eslint-disable-next-line prefer-regex-literals
            const isAbsoluteUrl = (value) => new RegExp(
                '^(?:[a-z]+:)?//', 'i',
            ).test(value)

            // eslint-disable-next-line prefer-regex-literals
            const isSpecialLink = (value) => new RegExp(
                '^(sms|tel|mailto):', 'i',
            ).test(value)

            if (!isAbsoluteUrl(href) && !isSpecialLink(href)) {
                return (
                    <Link {...attributesToProps({ ...attrs, to: href })} onClick={onClickToLink}>
                        {domToReact(children, parserOptions)}
                    </Link>
                )
            }
            return (
                <a {...attributesToProps({ ...attribs })} onClick={onClickToLink} aria-hidden>
                    {domToReact(children, parserOptions)}
                </a>
            )
        }
    }

    const processWidget = (
        {
            children: [{ data: widgetJson }],
            attribs: {
                'data-widget-type': widgetType,
            },
        },
    ) => {
        try {
            const widgetContent = JSON.parse(widgetJson)
            return (
                <WidgetsRenderer
                    widgetContent={widgetContent}
                    widgetType={widgetType}
                />
            )
        } catch (e) {
            return (<></>)
        }
    }

    const processScript = (data) => {
        const script = document.createElement('script')
        script.src = data.src
        script.type = 'text/javascript'

        document.head.appendChild(script)
    }

    const parserOptions: HTMLReactParserOptions = {
        replace: (domNode) => {
            if (typeof customReplace === 'function') {
                const isShouldUseReplace = typeof customReplaceConditionFunc === 'function'
                    ? customReplaceConditionFunc(domNode)
                    : true

                if (isShouldUseReplace) {
                    return customReplace(domNode)
                }
            }

            const { name, attribs, children } = domNode

            if (name === 'a') {
                return processLink(domNode)
            }

            // it is not valid to put a div inside a P tag,
            // so anytime when we have only one children inside a P tag
            // and this children is widget script, so replace whole P tag
            // with widget
            // the widget could be wrapped inside a P tag because somebody
            // has pressed Enter key in wysiwyg and
            // it automatically wrapped widget inside a P tag
            if ((name === 'p') &&
                (children) &&
                (children.length === 1) &&
                (children[0].type === 'script') &&
                (children[0].attribs.type === 'text/widget')
            ) {
                return processWidget(children[0])
            }

            if (name === 'img' && domNode.attribs.src) {
                return <ParserImage domNode={domNode} />
            }

            // but widget can be placed not only inside P, for example if
            // the content is edited without wysiwyg
            if ((name === 'script') &&
                (attribs.type === 'text/widget') &&
                (children) &&
                (children.length) &&
                (children[0].type === 'text')
            ) {
                return processWidget(domNode)
            }

            if ((name === 'script') &&
                (attribs.type === 'text/javascript')
            ) {
                return processScript(attribs)
            }
        },
    }

    return parser(content || '', parserOptions)
}

export default Parser

import { Cart } from "@pwa-concept/modules/graphql";
import { $currencySign } from "@pwa-concept/core/models";
import {
    extractGa4EcommerceProduct,
    IGa4EcommerceProductOutput
} from "../product/extractGa4EcommerceProduct";
import { tCurrencyCode } from "@pwa-concept/modules/currency/helpers";

export interface IGa4ExtractCart {
    cart: Cart,
}

export interface IGa4ExtractCartOutput {
    price: Cart['totals']['grand']['money']
    currency: tCurrencyCode,
    items: IGa4EcommerceProductOutput[],
}

type tGa4ExtractEcommerceCart = (props: IGa4ExtractCart) => IGa4ExtractCartOutput

export const extractGa4EcommerceCartObject: tGa4ExtractEcommerceCart = ({ cart }) => {
    const totalValue = cart.totals.grand.money
    const currency = $currencySign.code

    return {
        price: totalValue,
        currency,

        items: cart.items?.map((cartItem) => {
            return extractGa4EcommerceProduct({
                product: cartItem.product,
                configuredVariant: cartItem.configuredVariant,
                quantity: cartItem.quantity,

            })
        }) || []
    }
}

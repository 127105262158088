import {
    WidgetHeaderTitle,
    WidgetHeaderViewAll,
} from '@pwa-onilab/ui/components/widgets/WidgetHeader'
import { classNames } from '@pwa-onilab/ui/helpers/classNames'
import { memo } from 'react'

import styles from './WidgetHeader.module.scss'

export interface IWidgetHeaderProps {
    title: string
    to: string
    linkText: string
    classNameWrapper: string
}

const WidgetHeader = (
    {
        title,
        to,
        linkText,
        classNameWrapper,
    }: Partial<IWidgetHeaderProps>,
) => {
    if (!to && title) {
        return (
            <WidgetHeaderTitle
                className={classNames(styles.title, classNameWrapper)}
                title={title}
            />
        )
    }

    return (
        <div className={classNames(styles.widgetHeaderTop, classNameWrapper)}>
            <WidgetHeaderTitle title={title} />
            <WidgetHeaderViewAll
                to={to}
                linkText={linkText}
            />
        </div>
    )
}

export default memo(WidgetHeader)

import { IUseFiltersFunctionsApi } from '@pwa-onilab/ui/components/modules/Filters'
import { ONLY_IN_STOCK } from '@pwa-onilab/ui/components/modules/Filters/constants/FilterKeys'
import { IFiltersFunctionsApi } from '@pwa-onilab/ui/components/modules/Filters/context'
import { FILTER_NODE_ACTUAL_PRICE_PER_SEED } from 'modules/Filters'
import { useMemo } from 'react'

export const useFiltersFunctionsApi = (
    {
        setCurrentValues,
        setBatchUpdate,
        currentValuesRef,
        filtersRef,
    }: IUseFiltersFunctionsApi) => {
    return useMemo((): IFiltersFunctionsApi => {
        const onChangeValue = (callback) => {
            setCurrentValues(prev => {
                const newValues = callback(prev) || {}
                delete newValues.page

                currentValuesRef.current = newValues
                return newValues
            })
            setBatchUpdate(true)
        }

        const onRemoveTag = (item) => {
            const filter = filtersRef.current.find(({ key }) => key === item.key)
            const isOutOfStockFilterItem = item.key === ONLY_IN_STOCK
            const isPriceTag = item.key === FILTER_NODE_ACTUAL_PRICE_PER_SEED

            const isNeedDeleteFilterField = isOutOfStockFilterItem || isPriceTag
            onChangeValue((prev) => {
                const newFilters = { ...prev?.filters }
                const filterKey = filter?.key || item.key

                if (isNeedDeleteFilterField) {
                    delete newFilters[filterKey]
                } else {
                    newFilters[filterKey] = newFilters[filterKey]?.filter?.((value) => value !== item.value)
                }

                return {
                    ...(prev || {}),
                    filters: newFilters,
                }
            })
        }

        const onToggleOption = (filter, item = null) => {
            if (!filter?.key?.length) {
                return
            }
            onChangeValue((prev) => {
                const prevValue = prev?.filters?.[filter.key] || []
                const isExist = prevValue?.includes?.(item?.value)
                const newValue = isExist
                    ? prevValue.filter((option) => option !== item?.value)
                    : [...prevValue, item?.value]

                return {
                    ...prev,
                    filters: {
                        ...prev?.filters,
                        [filter.key]: newValue,
                    },
                }
            })
        }

        const isOptionChecked = (filter, item) => {
            const values = currentValuesRef.current
            return values?.filters?.[filter?.key]?.includes(item?.value)
        }

        return {
            isOptionChecked,
            onChangeValue,
            onRemoveTag,
            onToggleOption,
        }
    }, [])
}

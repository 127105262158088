import { Product } from '@pwa-concept/modules/graphql'
import {
    StrainsForSaleProductsEmpty,
    StrainsForSaleProductsGroup,
    useProductsAlphabeticallyByGroup,
} from 'modules/StrainsForSale'

import styles from './StrainsForSaleProducts.module.scss'

interface IStrainsForSaleProductsProps {
    products: Product[]
}

const StrainsForSaleProducts = (
    {
        products,
    }: IStrainsForSaleProductsProps,
) => {
    const currentProducts = useProductsAlphabeticallyByGroup(products)

    if (!currentProducts?.length) {
        return (
            <StrainsForSaleProductsEmpty className={styles.emptyResult} />
        )
    }

    const sortedGroups = [...currentProducts].sort((a, b) => {
        const isALetter = isNaN(a.title)
        const isBLetter = isNaN(b.title)

        if (isALetter && !isBLetter) {
            return -1
        } else if (!isALetter && isBLetter) {
            return 1
        } else {
            return a.title.localeCompare(b.title)
        }
    })

    const scrollToTop = (event) => {
        event.preventDefault()
        const listingElement = document.getElementById(event.target.getAttribute('data-scroll'))
        if (listingElement) {
            listingElement.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const extractedLetters = sortedGroups.filter(group => group.products.length > 0).map(group => group.title.toUpperCase())

    return (
        <div className={styles.wrapper}>
            <ul id="listing" className={styles.listingWrapper}>
                {extractedLetters.map((letter, index) => (
                    isNaN(letter) ? (
                        <li key={letter} className={styles.letterListingTitle}>
                            <span onClick={scrollToTop} data-scroll={letter.toLowerCase()}>
                              {letter}
                            </span>
                        </li>
                    ) : null
                ))}
                {/[0-9]/.test(extractedLetters.join('')) && (
                    <li className={styles.letterListingTitle}>
                        <span
                            onClick={scrollToTop}
                            data-scroll={/[0-9]/.exec(extractedLetters.join(''))[0].toLowerCase()}
                        >
                            0-9
                        </span>
                    </li>
                )}
            </ul>
            {
                sortedGroups.map(({ title, products }) => (
                    <StrainsForSaleProductsGroup
                        key={title}
                        title={title}
                        products={products}
                        className={styles.list}
                    />
                ))
            }
        </div>
    )
}

export default StrainsForSaleProducts

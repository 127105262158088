import { AuthWrapper } from '@pwa-onilab/ui/components/modules/Auth/elements'
import { AccountButton } from '@pwa-onilab/ui/components/modules/HeaderBottom/UserPanel/Account/AccountButton'
import { useAuthModal } from '@pwa-onilab/ui/hooks'
import { IClassName } from '@pwa-onilab/ui/interfaces'

const Account = (
    {
        className,
    }: IClassName,
) => {
    const { openModal } = useAuthModal()

    return (
        <>
            <AccountButton
                className={className}
                onClick={openModal}
            />
            <AuthWrapper />
        </>

    )
}

export default Account

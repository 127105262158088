import { Skeleton } from '@pwa-onilab/ui/components/elements/Skeleton'

import styles from './BlogNavigation.module.scss'

const BlogNavigationSkeleton = () => {
    return (
        <div className={styles.wrapperSkeleton}>
            <Skeleton className={styles.skeleton} />
        </div>
    )
}

export { BlogNavigationSkeleton }

import Title from '@pwa-onilab/ui/components/UI/Title'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './StrainsForSaleProductsTitle.module.scss'

interface IStrainsForSaleProductsTitleProps {
    title: string
    className?: string
}

const StrainsForSaleProductsTitle = (
    {
        title,
        className,
    }: IStrainsForSaleProductsTitleProps,
) => {
    if (!title) {
        return null
    }

    return (
        <div className={classNames(styles.titleWrapper, className)}>
            <Title
                type="h2"
                text={`${title}`}
                className={styles.title}
            />
        </div>
    )
}

export default StrainsForSaleProductsTitle

import CartBadge from '@pwa-onilab/ui/components/elements/CartBadge'
import { CartHeaderProps } from '@pwa-onilab/ui/components/modules/Cart/elements/CartHeader/CartHeader'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { useCartModal } from '@pwa-onilab/ui/hooks/modals'
import { useTranslation } from 'react-i18next'

import styles from './CartDefaultHeader.module.scss'

const CartDefaultHeader = (
    {
        itemsCount,
    }: CartHeaderProps,
) => {
    const { t } = useTranslation()
    const { closeModal } = useCartModal()

    return (
        <div className={styles.wrapper}>
            <ButtonInstance
                className={styles.closeButton}
                onClick={closeModal}
            >
                <Icon name="cross" className={styles.icon} />
            </ButtonInstance>
            <div className={styles.title}>
                {t('global.items')}
            </div>
            <CartBadge isUseResize quantity={itemsCount} className={styles.badge} />
        </div>
    )
}

export default CartDefaultHeader

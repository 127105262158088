import { IAnchor } from '@pwa-onilab/ui/components/UI/Anchor/IAnchor'
import { Link } from '@pwa-onilab/ui/components/UI/Link'
import { PropsWithChildren } from 'react'

const Anchor = (
    {
        to = '/',
        target = '_blank',
        rel = 'noreferrer',
        children,
        className,
        activeClassName,
        onClick,
        scroll,
        fullMatch,
        state,
        isForceAbsoluteUrl,
        ...otherLinkProps
    }: PropsWithChildren<IAnchor>,
) => {
    const isAbsoluteUrl = to?.includes('https://')

    if (isAbsoluteUrl || isForceAbsoluteUrl) {
        return (
            <a
                href={to}
                className={className}
                target={target}
                rel={rel}
                {...otherLinkProps}
            >
                {children}
            </a>
        )
    }

    return (
        <Link
            to={to}
            className={className}
            activeClassName={activeClassName}
            onClick={onClick}
            scroll={scroll}
            fullMatch={fullMatch}
            state={state}
            {...otherLinkProps}
        >
            {children}
        </Link>
    )
}

export default Anchor

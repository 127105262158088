import * as Types from '../../../../graphql';

import { gql } from '@apollo/client';
import { BreadCrumbClientFragmentFragmentDoc } from '../../../../../../.pwa/packages/modules/breadcrumb/graphql/fragments/BreadCrumbClientFragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type GetBreadCrumbsQueryVariables = Types.Exact<{
  pageType?: Types.InputMaybe<Types.BreadcrumbsPageTypeEnum>;
  urlKey?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetBreadCrumbsQuery = { __typename?: 'Query', getBreadCrumbs?: Array<{ __typename?: 'Breadcrumb', id?: string | null, level?: number | null, name?: string | null, url?: { __typename?: 'BreadcrumbUrl', type?: string | null, to?: string | null, id?: string | null } | null } | null> | null };


export const GetBreadCrumbsDocument = gql`
    query GetBreadCrumbs($pageType: BreadcrumbsPageTypeEnum, $urlKey: String) {
  getBreadCrumbs(pageType: $pageType, urlKey: $urlKey) @client {
    ...BreadCrumbClientFragment
  }
}
    ${BreadCrumbClientFragmentFragmentDoc}`;

/**
 * __useGetBreadCrumbsQuery__
 *
 * To run a query within a React component, call `useGetBreadCrumbsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBreadCrumbsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBreadCrumbsQuery({
 *   variables: {
 *      pageType: // value for 'pageType'
 *      urlKey: // value for 'urlKey'
 *   },
 * });
 */
export function useGetBreadCrumbsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBreadCrumbsQuery, GetBreadCrumbsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetBreadCrumbsQuery, GetBreadCrumbsQueryVariables>(GetBreadCrumbsDocument, options);
      }
export function useGetBreadCrumbsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBreadCrumbsQuery, GetBreadCrumbsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetBreadCrumbsQuery, GetBreadCrumbsQueryVariables>(GetBreadCrumbsDocument, options);
        }
export type GetBreadCrumbsQueryHookResult = ReturnType<typeof useGetBreadCrumbsQuery>;
export type GetBreadCrumbsLazyQueryHookResult = ReturnType<typeof useGetBreadCrumbsLazyQuery>;
export type GetBreadCrumbsQueryResult = Apollo.QueryResult<GetBreadCrumbsQuery, GetBreadCrumbsQueryVariables>;
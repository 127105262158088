import { ISearchCategory } from '@pwa-onilab/ui/components/modules/Search/hooks/ISearchCategory'
import { ISearchProviders } from '@pwa-onilab/ui/components/modules/Search/providers/ISearchProviders'
import { useContextPattern } from '@pwa-onilab/ui/hooks'
import { createContext, PropsWithChildren } from 'react'

const SearchCategoriesCtx = createContext<ISearchCategory[]>(null)
const useSearchCategories = (): ISearchCategory[] => useContextPattern(SearchCategoriesCtx)

const SearchCategoriesProvider = ({ children, categories }: PropsWithChildren<Pick<ISearchProviders, 'categories'>>) => {
    return (
        <SearchCategoriesCtx.Provider value={categories}>
            {children}
        </SearchCategoriesCtx.Provider>
    )
}

export { SearchCategoriesProvider, useSearchCategories }

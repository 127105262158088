import Container from '@pwa-onilab/ui/components/UI/Container'
import { Section } from '@pwa-onilab/ui/components/UI/Section'
import { PromoCodeContent, PromoCodeForm, PromoCodeImage } from '@pwa-onilab/ui/components/widgets/PromoCode'
import { DisablePageBuilderStyles } from 'elements/DisablePageBuilderStyles'

import styles from './PromoCode.module.scss'

interface IPromoCodeProps {
    className?: string
    background_image: string
    button_text: string
    email_placeholder: string
    short_description: string
    title: string
}

const PromoCode = (
    {
        background_image: backgroundImage,
        button_text: buttonText,
        email_placeholder: emailPlaceholder,
        short_description: shortDescription,
        title,
    }: IPromoCodeProps,
) => (
    <Section className={styles.section}>
        <DisablePageBuilderStyles>
            <Container>
                <div className={styles.wrapper}>
                    <div className={styles.leftPart}>
                        <PromoCodeContent
                            title={title}
                            shortDescription={shortDescription}
                        />
                        <PromoCodeForm
                            emailPlaceholder={emailPlaceholder}
                            buttonText={buttonText}
                        />
                    </div>
                    <PromoCodeImage backgroundImage={backgroundImage} />
                </div>
            </Container>
        </DisablePageBuilderStyles>
    </Section>
)

export default PromoCode

import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const Countries: QueryResolvers['Countries'] = async (_, __) => {
    const { data: { countries } = {} } = await (
        api.graphql(
            gql`
                query {
                    countries {
                        ...Country
                    }
                }
            `,
        ).query()
    )

    return countries?.map((__context) => ({ __context, __typename: 'Country' }))
}

export default Countries

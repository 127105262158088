import { Skeleton } from '@pwa-onilab/ui/components/elements/Skeleton'

import styles from './CmsPageContent.module.scss'

const CmsPageContentSkeleton = () => (
    <div className={styles.rootSkeleton}>
        <Skeleton className={styles.skeleton} />
    </div>
)

export { CmsPageContentSkeleton }

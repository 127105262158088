import { AccountAddressDeleteConfirmation } from '@pwa-onilab/ui/components/modules/AccountAddress'
import {
    AccountAddAddressButton,
    AccountAddressesAdditional,
    AccountAddressesDefault,
    IAccountAddressContent,
} from '@pwa-onilab/ui/components/modules/AccountSubpages'
import { AddressModalUpdate } from '@pwa-onilab/ui/components/modules/AddressModals'
import { useModalWithStoredId } from '@pwa-onilab/ui/hooks'

import styles from './AccountAddresses.module.scss'

const AccountAddresses = ({ openCreateNewAddressModal }: IAccountAddressContent) => {
    const {
        id: deletedAddressId,
        isOpen: isDeleteAddressModalOpen,
        openModal: openDeleteAddressModal,
        closeModal: closeDeleteAddressModal,
    } = useModalWithStoredId<number>()

    const {
        id: updatedAddressId,
        isOpen: isUpdatedAddressModalOpen,
        openModal: openUpdateAddressModal,
        closeModal: closeUpdateAddressModal,
    } = useModalWithStoredId<number>()

    const accountAddressesProps = {
        openUpdateAddressModal,
        openDeleteAddressModal,
    }

    return (
        <div className={styles.wrapper}>
            <AccountAddressesDefault {...accountAddressesProps} />
            <AccountAddressesAdditional {...accountAddressesProps} />
            <AccountAddAddressButton onClick={openCreateNewAddressModal} className={styles.addAddressButton} />
            <AccountAddressDeleteConfirmation
                id={deletedAddressId}
                isOpen={isDeleteAddressModalOpen}
                onDismiss={closeDeleteAddressModal}
            />
            <AddressModalUpdate
                id={updatedAddressId}
                isOpen={isUpdatedAddressModalOpen}
                onDismiss={closeUpdateAddressModal}
            />
        </div>
    )
}

export default AccountAddresses

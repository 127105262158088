export const getRgbFromHex = (hex: string) => {
    const fullHexString = hex.length === 3
        ? [hex[0], hex[0], hex[1], hex[1], hex[2], hex[2]].join('')
        : hex

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(fullHexString)

    if (result) {
        const r = parseInt(result[1], 16)
        const g = parseInt(result[2], 16)
        const b = parseInt(result[3], 16)

        return [r, g, b]
    }

    return null
}

import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import css from './Tabs.module.scss'

const VARIANTS = {
    small: css.small,
}

interface TabsProps {
    className?: string
    title?: string
    variant?: keyof typeof VARIANTS
}

const Tabs = (
    {
        children,
        className,
        title,
        variant,
    }: PropsWithChildren<TabsProps>,
) => (
    <div
        className={classNames(
            css.tabs,
            variant && VARIANTS?.[variant],
            className,
        )}
    >
        {title && (
            <p className={css.title}>{title}</p>
        )}
        {children}
    </div>
)

export default Tabs

import { useAuthModal } from '@pwa-onilab/ui/hooks'
import { useEffect } from 'react'

const RegistrationHomePage = () => {
    const authModal = useAuthModal()

    useEffect(() => {
        authModal.openModal()
    }, [])

    return null
}

export default RegistrationHomePage

import { formSaveBeforeHideModal, formSaveCallbackType } from '@pwa-onilab/ui/models'
import { useEffect } from 'react'

interface IUseSaveBeforeHideModal {
    callback: formSaveCallbackType
    isFormWasChanged: boolean
}

const useSaveBeforeHideModal = ({ isFormWasChanged, callback }: IUseSaveBeforeHideModal) => {
    useEffect(() => {
        if (isFormWasChanged) {
            formSaveBeforeHideModal.setCallback(callback)
        } else {
            formSaveBeforeHideModal.setCallback(null)
        }
    }, [isFormWasChanged])
}

export default useSaveBeforeHideModal

import { StoreConfigNotFoundPageUrl } from '@pwa-concept/modules/graphql'
import { IErrorPage } from '@pwa-onilab/ui/components/modules/ErrorPage/ErrorPage'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { addSlashToEndUrl } from 'helpers'

import styles from './ErrorPageNavigationList.module.scss'

interface IErrorPageNavigationList extends Pick<IErrorPage, 'onLinksClick'>{
    nodes: StoreConfigNotFoundPageUrl[]
}

const ErrorPageNavigationList = ({ nodes, onLinksClick }: IErrorPageNavigationList) => {
    return (
        <ul className={styles.list}>
            {nodes.map(({ url, text }) => (
                <li key={url} className={styles.listItem}>
                    <Anchor to={`/${addSlashToEndUrl(url)}`} className={styles.link} onClick={onLinksClick}>
                        <span className={styles.title}>
                            {text}
                        </span>
                        <Icon name="chevron" className={styles.arrow} />
                    </Anchor>
                </li>
            ))}
        </ul>
    )
}

export default ErrorPageNavigationList

import { useCmsRoute } from '@pwa-concept/modules/cms'
import { Breadcrumbs } from '@pwa-onilab/ui/components/modules/Breadcrumbs'
import { BreadcrumbsPageTypeEnum, useBreadCrumbs } from 'app/modules/breadcrumb'

const BlogBreadcrumbs = () => {
    const { data: cmsRoute } = useCmsRoute()

    const { data: crumbs, loading } = useBreadCrumbs({
        pageType: BreadcrumbsPageTypeEnum.HOMEPAGE_BLOG,
        urlKey: cmsRoute.url,
    })

    return (
        <Breadcrumbs items={crumbs} loading={loading} />
    )
}

export default BlogBreadcrumbs

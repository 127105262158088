import { IFilterControls } from '@pwa-onilab/ui/components/modules/Filters/components/FilterControls/IFilterControls'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useTranslation } from 'react-i18next'

import css from './FilterResultCount.module.scss'

const FilterResultCount = (
    {
        numResults,
        className,
    }: Pick<IFilterControls, 'numResults' | 'className'>,
) => {
    const { t } = useTranslation()

    return (
        <div className={classNames(css.result, className)}>
            {numResults || 0}
            {' '}
            {t('global.results')}
        </div>
    )
}

export default FilterResultCount

import { Icon as IconInterface } from '@pwa-concept/modules/graphql'
import { useStoreConfig } from '@pwa-concept/modules/store-config'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { Image } from '@pwa-onilab/ui/components/UI/Image'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { memo } from 'react'

import styles from './SocialMediaIcons.module.scss'

const SocialMediaIcons = ({ className }: IClassName) => {
    const { data: { socialAndPaymentConfig: { socialIcons } = {} } = {} } = useStoreConfig()
    if (!socialIcons?.length) {
        return null
    }

    return (
        <ul className={classNames(styles.list, className)}>
            {
                socialIcons.map(({ alt, link, src }: IconInterface) => (
                    <li key={link}>
                        <Anchor to={link} className={styles.link}>
                            <Image
                                className={styles.icon}
                                src={src}
                                alt={alt}
                            />
                        </Anchor>
                    </li>
                ))
            }
        </ul>
    )
}

export default memo(SocialMediaIcons)

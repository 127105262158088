import { QueryResolvers } from '@pwa-concept/modules/graphql'

import cms from './cms'
import cmsBlocks from './cmsBlocks'
import cmsRoute from './cmsRoute'

const Query: QueryResolvers = {
    cms,
    cmsBlocks,
    cmsRoute,
}

export default Query

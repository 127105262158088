
    var doc = {"kind":"Document","definitions":[{"kind":"ObjectTypeExtension","name":{"kind":"Name","value":"Mutation"},"interfaces":[],"directives":[],"fields":[{"kind":"FieldDefinition","name":{"kind":"Name","value":"cartAssignToCustomer"},"arguments":[{"kind":"InputValueDefinition","name":{"kind":"Name","value":"input"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"CartAssignToCustomerInput"}},"directives":[]}],"type":{"kind":"NamedType","name":{"kind":"Name","value":"Cart"}},"directives":[]}]},{"kind":"InputObjectTypeDefinition","name":{"kind":"Name","value":"CartAssignToCustomerInput"},"directives":[],"fields":[{"kind":"InputValueDefinition","name":{"kind":"Name","value":"sourceCartId"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}},"directives":[]},{"kind":"InputValueDefinition","name":{"kind":"Name","value":"destinationCartId"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}},"directives":[]}]}],"loc":{"start":0,"end":178}};
    doc.loc.source = {"body":"extend type Mutation {\n    cartAssignToCustomer(input: CartAssignToCustomerInput): Cart\n}\n\ninput CartAssignToCustomerInput {\n    sourceCartId: ID\n    destinationCartId: String\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    

import { useTranslation } from 'react-i18next'

import styles from './FaqQuestionsTitle.module.scss'

const FaqQuestionsTitle = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.title}>
            {t('productPage.additionalInfo.faqQuestionsTitle')}
        </div>
    )
}

export default FaqQuestionsTitle

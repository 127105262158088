import { useCart } from '@pwa-concept/modules/cart'
import { ga4ViewCart } from '@pwa-onilab/ga4/operations/cart/viewCart'
import CartEmpty from '@pwa-onilab/ui/components/modules/Cart/elements/CartEmpty'
import CartHeader from '@pwa-onilab/ui/components/modules/Cart/elements/CartHeader'
import CartWithItems from '@pwa-onilab/ui/components/modules/Cart/elements/CartWithItems'
import Loader from '@pwa-onilab/ui/components/UI/Loader'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useCartItemUndoOperation } from '@pwa-onilab/ui/hooks'
import CartRecommended from 'components/overrides/modules/Cart/elements/CartRecommended/CartRecommended'
import { useEffect, useRef, useState } from 'react'

import styles from './Cart.module.scss'

const Cart = () => {
    const isViewSend = useRef<boolean>(false)
    const [isCartLoading, setIsCartLoading] = useState<boolean>(false)

    const { data, loading } = useCart()

    const { lastDeletedItem, setLastDeletedItem, handleUndoOperation } = useCartItemUndoOperation(setIsCartLoading)

    const isCartWithItems = (!loading && !!data?.items?.length) || lastDeletedItem
    const totalQuantity = (!loading && data?.totalQuantity) || 0

    useEffect(() => {
        if (!loading && !isViewSend.current && !!data) {
            isViewSend.current = true
            ga4ViewCart({ cart: data })
        }
    }, [data, loading])

    return (
        <div className={styles.recommendedCartWrapper}>
            <div className={classNames(styles.wrapper, !isCartWithItems && styles.wrapperNoItems)}>
                <CartHeader itemsCount={totalQuantity} />
                {
                    isCartWithItems
                        ? <CartWithItems
                                items={data.items}
                                setIsCartLoading={setIsCartLoading}
                                isLoading={isCartLoading}
                                lastDeletedItem={lastDeletedItem}
                                setLastDeletedItem={setLastDeletedItem}
                                handleUndoOperation={handleUndoOperation}
                          />
                        : <CartEmpty />
                }

                <Loader wrapperClass={styles.loaderWrapper} visible={loading || isCartLoading} />

            </div>
            <CartRecommended />
        </div>
    )
}

export default Cart

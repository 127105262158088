import ProductPrice, { PriceProps } from 'components/overrides/modules/Product/elements/ProductPrice/ProductPrice'

import styles from './CategoryProductCardPrice.module.scss'

interface ICategoryProductCardPriceProps extends Pick<PriceProps, 'withFromLabel'>{
    actualPricePerSeed: number
    originalPricePerSeed?: number | null
}

const CategoryProductCardPrice = (
    {
        actualPricePerSeed,
        originalPricePerSeed,
        withFromLabel,
    }: ICategoryProductCardPriceProps,
) => (
    <ProductPrice
        actualPricePerSeed={actualPricePerSeed}
        originalPricePerSeed={originalPricePerSeed}
        className={styles.price}
        isProductPricePerSeed
        withFromLabel={withFromLabel}
    />
)

export default CategoryProductCardPrice

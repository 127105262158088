import { $cart } from '@pwa-concept/core/models'
import { useCartSetGuestEmailMutation } from '@pwa-concept/modules/cart/graphql/mutations/CartSetGuestEmail'
import { CartSetGuestEmailInput } from '@pwa-concept/modules/graphql'

const useCartSetGuestEmail = () => {
    const [setGuestEmailOnCart] = useCartSetGuestEmailMutation()
    const [cartId] = $cart.useId()

    return async (email: CartSetGuestEmailInput['email']) => {
        return await setGuestEmailOnCart({
            variables: {
                input: {
                    cartId,
                    email,
                },
            },
        })
    }
}

export default useCartSetGuestEmail

import { ReactComponent as VisaPayment } from 'assets/icons/payments/visaPayment.svg'
import { ReactComponent as MasterCardPayment } from 'assets/icons/payments/masterCardPayment.svg'
import { ReactComponent as AmexPayment } from 'assets/icons/payments/amexPayment.svg'
import { ReactComponent as ZCashPayment } from 'assets/icons/payments/zCashPayment.svg'
import { ReactComponent as ZellePayment } from 'assets/icons/payments/zellePayment.svg'
import { ReactComponent as VenmoPayment } from 'assets/icons/payments/venmoPayment.svg'
import { ReactComponent as BTCPayment } from 'assets/icons/payments/btcPayment.svg'

const CustomPaymentsIcons = {
    visaPayment: <VisaPayment />,
    masterCardPayment: <MasterCardPayment />,
    amexPayment: <AmexPayment />,
    zCashPayment: <ZCashPayment />,
    zellePayment: <ZellePayment />,
    venmoPayment: <VenmoPayment />,
    btcPayment: <BTCPayment />,
}

export default CustomPaymentsIcons

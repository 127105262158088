import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

const DEFAULT_TRACES_SAMPLE_RATE = 1.0
const DEFAULT_NORMALIZE_DEPTH = 3
const DEFAULT_MAX_BREADCRUMBS = 100

export const initSentry = () => {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        dist: process.env.SENTRY_DIST,
        release: process.env.SENTRY_RELEASE,
        environment: (process.env.SENTRY_ENV || '').toLowerCase(),
        tracesSampleRate: Number(process.env.SENTRY_TRACES_SAMPLE_RATE || DEFAULT_TRACES_SAMPLE_RATE),
        normalizeDepth: Number(process.env.SENTRY_NORMALIZE_DEPTH || DEFAULT_NORMALIZE_DEPTH),
        maxBreadcrumbs: Number(process.env.SENTRY_MAX_BREADCRUMBS || DEFAULT_MAX_BREADCRUMBS),

        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
        ],
        beforeBreadcrumb: (breadcrumb) => {
            if (breadcrumb?.category !== 'console' || breadcrumb?.level !== 'log') {
                return breadcrumb
            }
            return null
        },
        /** @see https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry */
        // TODO check for denyUrls & ignoreErrors.
        ignoreErrors: [
            'ResizeObserver loop limit exceeded',
        ],
        //  examle of them exist in commit b1ae9f319dfb0b3ee44dccc246d7540665501df2
    })
}

import { InputField } from '@pwa-onilab/ui/components/elements/Fields'
import { REGEX_EMAIL } from '@pwa-onilab/ui/constants/patterns'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useUnSubscribeEmailFromNewsletter } from '@pwa-onilab/ui/hooks'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import { useTranslation } from 'react-i18next'

import styles from './SubscriptionForm.module.scss'

const SubscriptionForm = ({ className }: IClassName) => {
    const { t } = useTranslation()

    const {
        register,
        errors,
        handleSubmit,
        onSubmitSubscribeEmailForm,
        subscribeEmailToNewsletterLoading,
        disabled,
    } = useUnSubscribeEmailFromNewsletter()

    return (
        <form className={classNames(styles.form, className)} onSubmit={handleSubmit(onSubmitSubscribeEmailForm)}>
            <InputField
                placeholder={t('notify.outOfStock.enterEmail')}
                field="email"
                register={register}
                errors={errors}
                rules={{
                    required: t('global.errors.required'),
                    pattern: {
                        value: REGEX_EMAIL,
                        message: t('global.errors.email'),
                    },
                }}
                inputBoxClass={styles.inputBox}
                className={styles.input}
            />
            <Button
                isPrimary
                isBold
                type="submit"
                className={styles.button}
                disabled={disabled || subscribeEmailToNewsletterLoading}
            >
                {t('global.subscribe')}
            </Button>
        </form>
    )
}

export default SubscriptionForm

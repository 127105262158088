import { IOrderComponent, useReorderButton } from '@pwa-onilab/ui/components/modules/Order'
import { classNames } from '@pwa-onilab/ui/helpers'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import { useTranslation } from 'react-i18next'

import styles from './ReorderButton.module.scss'

const ReorderButton = ({ className, orderNumber }: IOrderComponent) => {
    const { t } = useTranslation()

    const { onClick, isButtonDisabled } = useReorderButton(orderNumber)

    return (
        <Button
            onClick={onClick}
            className={classNames(styles.button, className)}
            isBold
            isFullWidth
            isBlack
            disabled={isButtonDisabled}
        >
            {t('AccountPage.orders.reorder')}
        </Button>
    )
}

export default ReorderButton

import { SimpleButton } from '@pwa-onilab/ui/components/elements/Buttons'
import { Link } from '@pwa-onilab/ui/components/UI/Link'
import { classNames } from '@pwa-onilab/ui/helpers'
import { MouseEventHandler, PropsWithChildren } from 'react'

import css from '../../Tabs.module.scss'

interface TabProps {
    isActive?: boolean
    onClick?: MouseEventHandler
    className?: string
    to?: string
}

const Tab = (
    {
        isActive,
        children,
        to,
        onClick,
        className,
    }: PropsWithChildren<TabProps>,
) => {
    if (to) {
        return (
            <Link
                to={to}
                className={classNames(css.tab, className, isActive && css.active)}
                activeClassName={css.active}
                onClick={onClick}
                fullMatch
            >
                {children}
            </Link>
        )
    }

    return (
        <SimpleButton
            onClick={onClick}
            className={classNames(css.tab, className, isActive && css.active)}
        >
            {children}
        </SimpleButton>
    )
}

export default Tab

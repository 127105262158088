import MobileMenuNode, {
    IMobileMenuNodeData,
} from 'components/overrides/modules/MobileMenuPage/MobileMenuNodes/MobileMenuNode/MobileMenuNode'

interface IMobileMenuNodesProps {
    nodes?: IMobileMenuNodeData[]
    parent?: IMobileMenuNodeData
}

const MobileMenuNodes = ({ nodes, parent }: IMobileMenuNodesProps) => {
    if (!nodes?.length) return null

    return (
        <>
            {nodes.map((node) => (
                <MobileMenuNode
                    parent={parent}
                    key={node?.nodeId}
                    node={node}
                />
            ))}
        </>
    )
}

export default MobileMenuNodes

import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { FilterActiveOptions, FilterControls } from '@pwa-onilab/ui/components/modules/Filters'
import { IFilterControls } from '@pwa-onilab/ui/components/modules/Filters/components/FilterControls/IFilterControls'

import css from './FiltersBlock.module.scss'

const FiltersBlock = (
    {
        tags,
        filters,
        onChangeValue,
        currentValues,
        onToggleOption,
        isOptionChecked,
        numResults,
    }: Omit<IFilterControls, 'className'>) => {
    return (
        <div className={css.wrapper}>
            <FilterControls
                className={css.controls}
                tags={tags}
                filters={filters}
                onChangeValue={onChangeValue}
                currentValues={currentValues}
                onToggleOption={onToggleOption}
                isOptionChecked={isOptionChecked}
                numResults={numResults}
            />
            <MediaBreakpointUp name="lg">
                <FilterActiveOptions tags={tags} />
            </MediaBreakpointUp>
        </div>
    )
}

export default FiltersBlock

import { useEffect } from 'react'

const listenerCallbacks = new WeakMap()

const handleIntersections = (entries) => {
    entries.forEach((entry) => {
        if (!listenerCallbacks.has(entry.target)) {
            return
        }

        const callback = listenerCallbacks.get(entry.target)

        if (!entry.isIntersecting) {
            return
        }

        LazyImageObserver.unobserve(entry.target)
        listenerCallbacks.delete(entry.target)
        callback()
    })
}

const LazyImageObserver = new IntersectionObserver(handleIntersections, {
    threshold: [0.1],
})

const useImageLazyObserver = (ref, callback) => {
    useEffect(() => {
        const element = ref.current

        listenerCallbacks.set(element, callback)
        LazyImageObserver.observe(element)

        return () => {
            listenerCallbacks.delete(element)
            LazyImageObserver.unobserve(element)
        }
    }, [callback])
}

export default useImageLazyObserver

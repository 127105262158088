import { Pagination } from '@pwa-concept/modules/graphql'
import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import PaginationList from '@pwa-onilab/ui/components/modules/Pagination/elements/PaginationList/PaginationList'
import { usePaginationFloatVisible } from '@pwa-onilab/ui/components/modules/Pagination/hooks'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { useCallback, useState } from 'react'

import css from './PaginationFloat.module.scss'

interface PaginationFloatProps extends IClassName {
    data?: Pagination
    onLoadPage?: (page: number) => void
}

// TODO open/close animation
const PaginationFloat = (
    {
        className,
        data,
        onLoadPage,
    }: PaginationFloatProps,
) => {
    if (!(data?.total > 1)) {
        return null
    }

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isPaginationVisible, setIsPaginationVisible] = useState<boolean>(true)

    const toggleOpen = useCallback(() => setIsOpen(prevState => !prevState), [])

    const isDesktop = useMediaBreakpoint().isBreakpointUp('lg')

    usePaginationFloatVisible(setIsPaginationVisible, setIsOpen, isDesktop)

    const { current: currentPage, total: maxPage } = data

    return (
        <div className={classNames(css.wrapper, !isPaginationVisible && css.hidden, className)}>
            <PaginationList
                pagination={data}
                onClick={onLoadPage}
                wrapperClassName={classNames(
                    css.list,
                    (isOpen || isDesktop) && css.isOpenList,
                )}
                activeClassName={css.isActive}
                cellClassName={css.item}
                previousClassName={css.arrow}
                nextClassName={css.arrow}
                previousLabel={<Icon name="doubleLeftArrow" className={css.icon} />}
                nextLabel={<Icon name="nextArrow" className={css.icon} />}
                isShowPreviousCell={isDesktop}
                isShowNextCell={isDesktop}
                isShowAllPages={!isDesktop}
            />
            {
                !isDesktop && (
                    <ButtonInstance onClick={toggleOpen} className={css.label}>
                        {`${currentPage}/${maxPage}`}
                    </ButtonInstance>
                )
            }
        </div>
    )
}

export default PaginationFloat

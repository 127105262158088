import { useCartRemoveItemMutation } from '@pwa-concept/modules/cart/graphql/mutations/CartRemoveItem'
import { IApolloMutationsBaseOptions } from '@pwa-concept/modules/common'

const useCartRemoveItem = (baseOptions?: IApolloMutationsBaseOptions) => {
    const [removeCartItem] = useCartRemoveItemMutation({ ...baseOptions })

    return async ({ id, uid }: { id: string, uid: string }) => {
        return await removeCartItem({
            variables: {
                input: {
                    cartId: id,
                    cartItemUid: uid,
                },
            },
            ignoreResults: true,
        })
    }
}

export default useCartRemoveItem

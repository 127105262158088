type tFilterTypeString = 'match' | 'equal' | 'range' | string

export interface IFilterObject {
    [key: string]: tFilterTypeString
}

export interface IFilterMapperDecoratorObject {
    [key: tFilterTypeString]: (
        key: tFilterTypeString, // can be needed inside custom mappers
        currentValue: string[]
    ) => object
}
export const productAttributesFilters: IFilterObject = {
    category_id: 'equal',
    category_uid: 'equal',
    description: 'match',
    fashion_color: 'equal',
    fashion_material: 'equal',
    fashion_size: 'equal',
    fashion_style: 'equal',
    format: 'equal',
    has_video: 'equal',
    name: 'match',
    price: 'range',
    short_description: 'match',
    sku: 'equal',
    url_key: 'equal',
}

export const productAttributesFiltersMappers: IFilterMapperDecoratorObject = {
    match: (key, currentValue) => {
        return {
            match: currentValue[0],
        }
    },
    equal: (key, currentValue) => {
        return {
            in: currentValue,
        }
    },
    range: (key, currentValue) => {
        return {
            from: currentValue[0] || 0,
            ...(currentValue.length > 1 ? { to: currentValue[1] } : {}),
        }
    },
}

import { Resolvers } from '@pwa-concept/modules/graphql'

import AvailableShippingMethod from './AvailableShippingMethod'
import Cart from './Cart'
import CartAddressCountry from './CartAddressCountry'
import CartAddressRegion from './CartAddressRegion'
import CartAvailablePaymentMethods from './CartAvailablePaymentMethods'
import CartBillingAddress from './CartBillingAddress'
import CartCustomizableOptionsValue from './CartCustomizableOptionsValue'
import CartItemCustomizableOptions from './CartItemCustomizableOptions'
import CartItemPrices from './CartItemPrices'
import CartItems from './CartItems'
import CartMoney from './CartMoney'
import CartMoneyOnly from './CartMoneyOnly'
import CartReorderItemsOutput from './CartReorderItemsOutput'
import CartSelectedPaymentMethod from './CartSelectedPaymentMethod'
import CartSelectedShippingMethod from './CartSelectedShippingMethod'
import CartShippingAddresses from './CartShippingAddresses'
import CartTotals from './CartTotals'
import Mutation from './Mutation'
import Query from './Query'

const resolvers: Resolvers = {
    Query,
    Mutation,
    CartReorderItemsOutput,
    Cart,
    CartAddressCountry,
    CartCustomizableOptionsValue,
    CartItemCustomizableOptions,
    CartItemPrices,
    CartItems,
    CartMoney,
    CartMoneyOnly,
    CartSelectedShippingMethod,
    CartShippingAddresses,
    CartAddressRegion,
    AvailableShippingMethod,
    CartTotals,
    CartSelectedPaymentMethod,
    CartBillingAddress,
    CartAvailablePaymentMethods,
}

export default resolvers

import * as Types from '../../../../graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type StoreConfigBestsellersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type StoreConfigBestsellersQuery = { __typename?: 'Query', storeConfigBestsellers?: { __typename?: 'StoreConfigBestsellers', descriptionBlock?: string | null, bottomDescriptionBlock?: string | null, breadcrumbLabel?: string | null, urlKey?: string | null, meta?: { __typename?: 'StoreConfigDefaultPageMeta', title?: string | null, keywords?: string | null, description?: string | null, robots?: string | null } | null } | null };


export const StoreConfigBestsellersDocument = gql`
    query StoreConfigBestsellers {
  storeConfigBestsellers @client {
    descriptionBlock
    bottomDescriptionBlock
    breadcrumbLabel
    urlKey
    meta {
      title
      keywords
      description
      robots
    }
  }
}
    `;

/**
 * __useStoreConfigBestsellersQuery__
 *
 * To run a query within a React component, call `useStoreConfigBestsellersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreConfigBestsellersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreConfigBestsellersQuery({
 *   variables: {
 *   },
 * });
 */
export function useStoreConfigBestsellersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StoreConfigBestsellersQuery, StoreConfigBestsellersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<StoreConfigBestsellersQuery, StoreConfigBestsellersQueryVariables>(StoreConfigBestsellersDocument, options);
      }
export function useStoreConfigBestsellersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StoreConfigBestsellersQuery, StoreConfigBestsellersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<StoreConfigBestsellersQuery, StoreConfigBestsellersQueryVariables>(StoreConfigBestsellersDocument, options);
        }
export type StoreConfigBestsellersQueryHookResult = ReturnType<typeof useStoreConfigBestsellersQuery>;
export type StoreConfigBestsellersLazyQueryHookResult = ReturnType<typeof useStoreConfigBestsellersLazyQuery>;
export type StoreConfigBestsellersQueryResult = Apollo.QueryResult<StoreConfigBestsellersQuery, StoreConfigBestsellersQueryVariables>;
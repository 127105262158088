import ProductLabel from '@pwa-onilab/ui/components/modules/Product/elements/ProductBadges/elements/ProductLabel/ProductLabel'
import { IProductBadges } from '@pwa-onilab/ui/components/modules/Product/elements/ProductBadges/IProductBadge'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './ProductBadges.module.scss'

const ProductBadges = (
    {
        data,
        isRowDirection,
        className,
    }: IProductBadges,
) => {
    if (!data?.length) {
        return null
    }

    const classes = classNames(
        styles.wrapper,
        isRowDirection && styles.rowDirection,
        className,
    )

    return (
        <div className={classes}>
            {data.map(({ label, backgroundColor, color }) => {
                return <ProductLabel key={label} label={label} backgroundColor={backgroundColor} color={color} />
            })}
        </div>
    )
}

export default ProductBadges

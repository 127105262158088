import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import CMSPageDesktop from '@pwa-onilab/ui/pages/CMSPage/CMSPageDesktop'
import CMSPageMobile from '@pwa-onilab/ui/pages/CMSPage/CMSPageMobile/CMSPageMobile'
import { memo } from 'react'

const CMSPage = () => {
    const isMobile = useMediaBreakpoint().isBreakpointDown('lg')
    if (isMobile) {
        return <CMSPageMobile />
    }

    return <CMSPageDesktop />
}

export default memo(CMSPage)

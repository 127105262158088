import Container from '@pwa-onilab/ui/components/UI/Container'
import { Section } from '@pwa-onilab/ui/components/UI/Section'
import { IWidgetHeaderProps, WidgetHeader } from '@pwa-onilab/ui/components/widgets/WidgetHeader'
import BeautyBlogSlider from 'components/overrides/widgets/BeautyBlogSection/BeautyBlogSlider/BeautyBlogSlider'
import { DisablePageBuilderStyles } from 'elements/DisablePageBuilderStyles'
import { memo } from 'react'

import styles from './BeautyBlogSection.module.scss'

interface IBestsellersSectionProps extends IWidgetHeaderProps {
    short_description: string
    items: Array<{
        featured_img: string
        publish_time: string
        short_content: string
        title: string
        url_path: string
    }>
}

const BeautyBlogSection = (
    {
        short_description: shortDescription,
        title,
        items,
    }: IBestsellersSectionProps,
) => {
    if (!items?.length) return null

    return (
        <Section className={styles.wrapper}>
            <DisablePageBuilderStyles>
                <Container>
                    <WidgetHeader
                        title={title}
                        subtitle={shortDescription}
                    />
                    <BeautyBlogSlider items={items} />
                </Container>
            </DisablePageBuilderStyles>
        </Section>
    )
}

export default memo(BeautyBlogSection)

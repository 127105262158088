import useDeleteCustomerAccount from '@pwa-concept/modules/customer/features/delete/hooks/useDeleteCustomerAccount'
import { useLogOut } from '@pwa-onilab/ui/hooks'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

const useAccountDeleteForm = () => {
    const formValues = useForm()

    const deleteCustomerAccount = useDeleteCustomerAccount()
    const logOut = useLogOut()

    const onSubmit = useCallback(async ({ pin }) => {
        try {
            const response = await deleteCustomerAccount(pin)
            if (!response) {
                return
            }

            if (response.errorMessage) {
                toast.error(response.errorMessage)
            }

            if (response.status) {
                await logOut()
            }
        } catch (error) {
            toast.error(error.message)
        } finally {
            formValues.reset()
        }
    }, [])

    return {
        formValues,
        onSubmit,
    }
}

export default useAccountDeleteForm

import { Resolvers } from '@pwa-concept/modules/graphql'

import CustomerAddress from './CustomerAddress'
import CustomerAddressRegion from './CustomerAddressRegion'
import Mutation from './Mutation'
import Query from './Query'

const resolvers: Resolvers = {
    Query,
    Mutation,
    CustomerAddress,
    CustomerAddressRegion,
}

export default resolvers

import { Resolvers } from '@pwa-concept/modules/graphql'

import CustomerOrder from './CustomerOrder'
import CustomerOrderProduct from './CustomerOrderProduct'
import Mutation from './Mutation'
import Query from './Query'

const resolvers: Resolvers = {
    Query,
    Mutation,
    CustomerOrder,
    CustomerOrderProduct,
}

export default resolvers

import { DeletedItemInterface } from '@pwa-onilab/ui/hooks/Cart/useCartItemUndoOperation'

export const getLastDeletedItemPosition = (
    lastDeletedItem: DeletedItemInterface,
    currentIdx: number,
    currentId: string,
    itemsLength: number,
) => {
    let isItemInTheEnd = false
    let isItemInTheMiddle = false

    if (lastDeletedItem) {
        const deletedItemIndex = lastDeletedItem.indexInArray

        isItemInTheEnd = currentIdx === itemsLength - 1 && deletedItemIndex > currentIdx
        isItemInTheMiddle = !isItemInTheEnd && deletedItemIndex === currentIdx && currentId !== lastDeletedItem.id
    }

    return {
        isItemInTheMiddle,
        isItemInTheEnd,
    }
}

import { useCart } from '@pwa-concept/modules/cart'
import CartBadge from '@pwa-onilab/ui/components/elements/CartBadge'
import CartModal from '@pwa-onilab/ui/components/modules/CartModal/CartModal'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { useCartModal } from '@pwa-onilab/ui/hooks/modals'

import styles from './Cart.module.scss'

const Cart = () => {
    const { data } = useCart()

    const { openModal } = useCartModal()

    const totalQuantity = data?.totalQuantity || 0

    return (
        <>
            <ButtonInstance
                className={styles.button}
                onClick={openModal}
            >
                <Icon name="bag" className={styles.icon} />
                <CartBadge quantity={totalQuantity} isUseResize />
            </ButtonInstance>
            <CartModal />
        </>
    )
}

export default Cart

import {
    GetBreadCrumbsQueryVariables,
    useGetBreadCrumbsQuery,
} from 'app/modules/breadcrumb/graphql/queries/GetBreadCrumbs'

const useBreadCrumbs = ({ pageType, urlKey }: GetBreadCrumbsQueryVariables) => {
    const { data: { getBreadCrumbs } = {}, loading } = useGetBreadCrumbsQuery({
        variables: {
            pageType,
            urlKey,
        },
        skip: !urlKey || !pageType,
    })

    return {
        data: getBreadCrumbs,
        loading,
    }
}

export default useBreadCrumbs

import { ReactComponent as ApplePayCartIcon } from './apllePay.svg'
import { ReactComponent as GooglePayCartIcon } from './googlePay.svg'
import { ReactComponent as MasterCardCartIcon } from './mastercard.svg'
import { ReactComponent as PaypalCartIcon } from './paypal.svg'
import { ReactComponent as VisaCartIcon } from './visa.svg'

const IconCartPayments = {
    visaCart: <VisaCartIcon />,
    mastercardCart: <MasterCardCartIcon />,
    paypalCart: <PaypalCartIcon />,
    applepayCart: <ApplePayCartIcon />,
    googlepayCart: <GooglePayCartIcon />,
}

export default IconCartPayments

import { QueryResolvers } from '@pwa-concept/modules/graphql'

import getState from './getState'
import getStates from './getStates'

const Query: QueryResolvers = {
    getStates,
    getState,
}

export default Query

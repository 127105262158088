import { CartAddressInput } from '@pwa-concept/modules/graphql'
import { ADDRESS_FORM_FIELDS_TYPE } from '@pwa-onilab/ui/components/modules/AddressForm'
import { getCustomerNames } from '@pwa-onilab/ui/helpers'

export const extractCartAddressInputFromForm = (formValues: ADDRESS_FORM_FIELDS_TYPE): CartAddressInput => {
    if (!formValues) {
        return null
    }

    // middlename not provided in CartAddressInput
    const { firstname, lastname } = getCustomerNames(formValues.fullName) || {}

    return {
        city: formValues.city || null,
        company: formValues.company || null,
        countryCode: formValues.countryCode || null,
        postCode: formValues.postcode || null,
        region: formValues.region || null,
        regionId: formValues.regionId || null,
        street: [formValues.street1, formValues.street2],
        telephone: formValues.telephone,
        firstName: firstname,
        lastName: lastname,
    }
}

import { MutationResolvers } from '@pwa-concept/modules/graphql'

import shareWishlist from './wishlist-share'
import addProductsToWishlist from './wishlist-add-product'
import removeProductsFromWishlist from './wishlist-remove-product'
import mergeWishlists from './wishlist-merge'

const Mutation: MutationResolvers = {
    shareWishlist,
    addProductsToWishlist,
    removeProductsFromWishlist,
    mergeWishlists,
}

export default Mutation

import * as Types from '../../../../graphql';

import { gql } from '@apollo/client';
import { BlogAuthorFragmentFragmentDoc } from '../fragments/ClientBlogAuthorFragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type GetBlogAuthorQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  urlKey?: Types.InputMaybe<Types.Scalars['String']>;
  isFull?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GetBlogAuthorQuery = { __typename?: 'Query', getBlogAuthor?: { __typename?: 'BlogAuthor', id?: number | null, urlKey?: string | null, imageUrl?: string | null, thumbnailImageUrl?: string | null, name?: string | null, description?: string | null, socialUrls?: { __typename?: 'SocialUrls', facebook?: string | null, instagram?: string | null, linkedin?: string | null, pinterest?: string | null, twitter?: string | null, youtube?: string | null } | null, meta?: { __typename?: 'BlogAuthorMeta', title?: string | null, keywords?: string | null, description?: string | null, robots?: string | null } | null, categories?: Array<{ __typename?: 'BlogCategory', id?: string | null, title?: string | null } | null> | null } | null };


export const GetBlogAuthorDocument = gql`
    query GetBlogAuthor($id: Int, $urlKey: String, $isFull: Boolean = true) {
  getBlogAuthor(id: $id, urlKey: $urlKey, isFull: $isFull) @client {
    ...BlogAuthorFragment
  }
}
    ${BlogAuthorFragmentFragmentDoc}`;

/**
 * __useGetBlogAuthorQuery__
 *
 * To run a query within a React component, call `useGetBlogAuthorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogAuthorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogAuthorQuery({
 *   variables: {
 *      id: // value for 'id'
 *      urlKey: // value for 'urlKey'
 *      isFull: // value for 'isFull'
 *   },
 * });
 */
export function useGetBlogAuthorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBlogAuthorQuery, GetBlogAuthorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetBlogAuthorQuery, GetBlogAuthorQueryVariables>(GetBlogAuthorDocument, options);
      }
export function useGetBlogAuthorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBlogAuthorQuery, GetBlogAuthorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetBlogAuthorQuery, GetBlogAuthorQueryVariables>(GetBlogAuthorDocument, options);
        }
export type GetBlogAuthorQueryHookResult = ReturnType<typeof useGetBlogAuthorQuery>;
export type GetBlogAuthorLazyQueryHookResult = ReturnType<typeof useGetBlogAuthorLazyQuery>;
export type GetBlogAuthorQueryResult = Apollo.QueryResult<GetBlogAuthorQuery, GetBlogAuthorQueryVariables>;
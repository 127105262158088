import { Product } from '@pwa-concept/modules/graphql'
import { CarouselProductsSlider, SliderNavigation, SliderScrollbar } from '@pwa-onilab/ui/components/UI/Slider'
import { useResizeCallback } from '@pwa-onilab/ui/hooks'
import { ISimpleProductCard } from 'components/overrides/modules/Product/SimpleProductCard/SimpleProductCard'
import SimpleProductCardWrapper from 'components/overrides/modules/Product/SimpleProductCard/SimpleProductCardWrapper'
import { RefObject, useMemo, useRef, useState } from 'react'
import { SwiperSlide } from 'swiper/react'

import styles from './CarouselSectionSlider.module.scss'

interface ICarouselSectionSliderProps extends Pick<ISimpleProductCard, 'itemsListName'>{
    items: Product[]
}

const updateSliderParamsOnResize = (slider, containerRef: RefObject<HTMLDivElement>) => {
    // as breakpointsBase prop for swiper doesn't work with react yet, we have to use such crutch
    if (!slider) {
        return undefined
    }
    const containerWidth = containerRef.current.getBoundingClientRect().width

    if (containerWidth > 991) {
        slider.params.slidesPerView = 5
        slider.params.spaceBetween = 24
        slider.params.slidesPerGroup = 5
    } else if (containerWidth > 576) {
        slider.params.slidesPerView = 4
        slider.params.spaceBetween = 12
        slider.params.slidesPerGroup = 4
    } else {
        slider.params.slidesPerView = 2.2
        slider.params.spaceBetween = 12
        slider.params.slidesPerGroup = 1
    }
    slider.update()
}

const CarouselSectionSlider = ({ items, itemsListName }: ICarouselSectionSliderProps) => {
    const [slider, setSlider] = useState(null)

    const sliderContainerRef = useRef<HTMLDivElement>(null)

    const slides = useMemo(() => (
        items.map((product) => (
            <SwiperSlide key={product.sku}>
                <SimpleProductCardWrapper product={product} itemsListName={itemsListName} />
            </SwiperSlide>
        ))
    ), [items])

    useResizeCallback(() => {
        updateSliderParamsOnResize(slider, sliderContainerRef)
    })

    return (
        <div className={styles.wrapper} ref={sliderContainerRef}>
            <CarouselProductsSlider
                className={styles.carouselSlider}
                spaceBetween={12}
                setCustomThumbsSwiper={setSlider}
            >
                {slides}
                <SliderNavigation
                    variant="default"
                    nextClassName={styles.nextArrow}
                    prevClassName={styles.prevArrow}
                />
                <SliderScrollbar className={styles.scrollBar} />
            </CarouselProductsSlider>
        </div>
    )
}

export default CarouselSectionSlider

import { FamilyStrainsResolvers } from '@pwa-concept/modules/graphql'

const FamilyStrains: FamilyStrainsResolvers = {
    items: (_, __, { context }) => {
        if (!context?.items?.length) {
            return null
        }

        return context.items.map((item) => {
            return {
                __context: item,
                __typename: 'FamilyStrain',
            }
        })
    },
    pagination: (_, __, { context }) => {
        return {
            count: context.total_count || null,
            current: context.page_info?.current_page || null,
            limit: context.page_info?.page_size || null,
            total: context.page_info?.total_pages || null,
            __typename: 'Pagination',
        }
    },
}

export default FamilyStrains

import { BackButton } from '@pwa-onilab/ui/components/elements/Buttons'
import { MediaBreakpointDown } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { AccountDotsGoToDeletePage } from '@pwa-onilab/ui/components/modules/Account'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { ACCOUNT_ROUTE } from '@pwa-onilab/ui/constants/navigation'
import { classNames } from '@pwa-onilab/ui/helpers'
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch, useNavigate } from 'react-router-dom'

import styles from './AccountMobileHeader.module.scss'

const AccountMobileHeader = () => {
    const { t } = useTranslation()
    const isAccountSubpageMatch = useMatch('/account/:subpage')
    const navigate = useNavigate()

    const goToAccount = useCallback(() => {
        navigate(ACCOUNT_ROUTE)
    }, [])

    return (
        <MediaBreakpointDown name="lg">
            <Container className={styles.wrapper} isRevert>
                <BackButton
                    onClick={goToAccount}
                    className={classNames(styles.backButton, !isAccountSubpageMatch && styles.hide)}
                />
                <div className={styles.title}>{t('global.account')}</div>
                <AccountDotsGoToDeletePage />
            </Container>
        </MediaBreakpointDown>
    )
}

export default memo(AccountMobileHeader)

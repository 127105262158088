import { useCms } from '@pwa-concept/modules/cms'
import ParserBounded from '@pwa-onilab/ui/components/elements/ParserBounded/ParserBounded'
import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'
import CmsPageHelmet from '@pwa-onilab/ui/components/modules/CmsPage/elements/CmsPageHelmet'
import { Error404 } from '@pwa-onilab/ui/components/modules/Error404'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { CmsPageContent } from '@pwa-onilab/ui/components/widgets/CmsPage'
import { useParams } from 'react-router-dom'

import styles from './CMSPageMobile.module.scss'

const CMSPageMobile = () => {
    const { id = null } = useParams()
    const {
        data,
        loading,
    } = useCms({ id })

    if (loading) {
        return <Placeholder className={styles.placeholder} />
    }

    if (!data) {
        return <Error404 />
    }

    return (
        <Container>
            <CmsPageHelmet cms={data} />
            <CmsPageContent>
                <h1>{data.title}</h1>
                {/* TODO title from data.title? */}
                <ParserBounded content={data.content || ''} />
            </CmsPageContent>
        </Container>
    )
}

export default CMSPageMobile

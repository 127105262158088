import {
    useLiveSearchQueryLazyQuery,
} from '@pwa-concept/modules/live-search/graphql/queries/LiveSearchQuery'

interface IUseLiveSearchQueryProps {
    onCompleted?: (values) => void
}

const useLiveSearchQuery = ({ onCompleted }: IUseLiveSearchQueryProps) => {
    const [search, { loading }] = useLiveSearchQueryLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted,
    })

    return {
        search,
        loading,
    }
}

export default useLiveSearchQuery

import { IAccountAddress } from '@pwa-onilab/ui/components/modules/AccountAddress'
import {
    AccountAddressesList, AccountAddressesTitle,
    useAccountAdditionalAddresses,
} from '@pwa-onilab/ui/components/modules/AccountSubpages'
import { useTranslation } from 'react-i18next'
import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'

import styles from 'modules/AccountAddress/AccountAddressesAdditional/AccountAddressesAdditional.module.scss'

const AccountAddressesAdditional = (
    {
        openUpdateAddressModal,
        openDeleteAddressModal,
    }: Pick<IAccountAddress, 'openDeleteAddressModal' | 'openUpdateAddressModal'>,
) => {
    const { t } = useTranslation()
    const addressList = useAccountAdditionalAddresses()

    if (!addressList) {
        return null
    }

    return (
        <div className={styles.wrapper}>
            <MediaBreakpointUp name="lg">
                <AccountAddressesTitle title={t('addresses.additionalAddresses')} />
            </MediaBreakpointUp>
            <AccountAddressesList
                addressList={addressList}
                openDeleteAddressModal={openDeleteAddressModal}
                openUpdateAddressModal={openUpdateAddressModal}
            />
        </div>

    )
}

export default AccountAddressesAdditional

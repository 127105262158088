import { $wishlist } from '@pwa-concept/core/models'
import { IApolloMutationsBaseOptions } from '@pwa-concept/modules/common'
import { useShareWishlistMutation } from '@pwa-concept/modules/wishlist/graphql/mutations/ShareWishlist'

interface IWishlistShareData {
    emails: string[]
    message?: string
}

const useWishlistShare = (baseOptions?: IApolloMutationsBaseOptions) => {
    const [shareWishlistMutation] = useShareWishlistMutation({ ...baseOptions })

    return async ({ emails, message = '' }: IWishlistShareData) => {
        return await shareWishlistMutation({
            variables: {
                id: $wishlist.id,
                emails,
                message,
            },
            ignoreResults: true,
        })
    }
}

export default useWishlistShare

export const productsDecoratorCategoryId = (prev: any, categoryId: string) => {
    return {
        ...prev,
        filter: {
            ...(prev?.filter || {}),
            category_id: {
                ...prev?.filter?.category_id,
                eq: categoryId,
            },
        },
    }
}

import { CustomerOrder } from '@pwa-concept/modules/graphql'
import { useAccountOrderModal } from '@pwa-onilab/ui/components/modules/AccountSubpages'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { useTranslation } from 'react-i18next'

import styles from './AccountOrderBlockHeader.module.scss'

const AccountOrderBlockHeader = ({ number }: Pick<CustomerOrder, 'number'>) => {
    const { t } = useTranslation()
    const { openModal } = useAccountOrderModal()
    const onClick = () => openModal(number)

    return (
        <div className={styles.wrapper}>
            <span className={styles.order}>
                {t('global.order.number', { orderNumber: number })}
            </span>
            <ButtonInstance onClick={onClick}>
                <Icon name="chevronBold" className={styles.icon} />
            </ButtonInstance>
        </div>
    )
}

export default AccountOrderBlockHeader

import { State } from '@pwa-concept/modules/graphql'
import MAGENTO_DEFAULT_IMAGE from '@pwa-onilab/ui/assets/images/magentoDefaultImage.jpg'
import { IImageLazyProps } from '@pwa-onilab/ui/components/UI/Image'
import { ImageLazy } from '@pwa-onilab/ui/components/UI/Image/ImageLazy'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './PageMainImage.module.scss'

const PageMainImage = (
    {
        image,
        className,
        loaderClassName,
    }: Pick<State & IImageLazyProps, 'image' | 'className' | 'loaderClassName'>) => {
    return (
        <ImageLazy
            src={image || MAGENTO_DEFAULT_IMAGE}
            alt="state page main image"
            className={classNames(
                styles.image,
                className,
            )}
            loaderClassName={classNames(styles.placeholder, loaderClassName)}
        />
    )
}
export default PageMainImage

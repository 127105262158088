import { FamilyStrain } from '@pwa-concept/modules/graphql'
import { StrainFamilyCard } from 'modules/StrainFamilies'

import styles from './StrainFamiliesList.module.scss'

interface IStrainFamiliesList {
    items: FamilyStrain[]
}

const StrainFamiliesList = ({ items }: IStrainFamiliesList) => {
    if (!items) {
        return null
    }

    return (
        <ul className={styles.list}>
            {items.map((item) => {
                return (
                    <li key={item.id} className={styles.item}>
                        <StrainFamilyCard strain={item} />
                    </li>
                )
            })}
        </ul>
    )
}

export default StrainFamiliesList

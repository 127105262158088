import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { PasswordField } from '@pwa-onilab/ui/components/elements/Fields'
import { IFormField } from '@pwa-onilab/ui/components/elements/FormFields'
import { FORM_PASSWORD_FIELD } from '@pwa-onilab/ui/constants/formFields'
import { useTranslation } from 'react-i18next'

const PasswordFormField = (
    {
        additionalRules,
        ...formFields
    }: IFormField) => {
    const { t } = useTranslation()
    const { data: { passwordMinLength } = {} } = useStoreConfig()

    return (
        <PasswordField
            {...formFields}
            placeholder={t('global.password.placeholder')}
            field={FORM_PASSWORD_FIELD}
            rules={{
                required: t('global.errors.required'),
                minLength: {
                    value: passwordMinLength,
                    message: t('password.error.length', { minLength: passwordMinLength }),
                },
                ...additionalRules,
            }}
        />
    )
}

export default PasswordFormField

import ISelect from '@pwa-onilab/ui/components/UI/Select/ISelect'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useCallback, useMemo } from 'react'

import styles from './Select.module.scss'

const Select = (
    {
        options,
        name,
        className,
        onValueChange,
        defaultValue,
        isHideDefaultValue,
        register,
        rules = {},
    }: ISelect,
) => {
    const methods = useMemo(() => register?.(name, rules) || null, [])

    const onChange = useCallback((event) => {
        methods?.onChange(event)
        onValueChange?.(event)
    }, [onValueChange])

    const optionsList = useMemo(() => options?.map(({ value, label }) => (
        <option key={value + label} value={value}>
            {label}
        </option>
    )), [options])

    return (
        <select
            {...methods}
            id={name}
            name={name}
            className={classNames(styles.select, className)}
            onChange={onChange}
            defaultValue={defaultValue}
        >
            {
                isHideDefaultValue && (
                    <option disabled selected value />
                )
            }
            {optionsList}
        </select>
    )
}

export default Select

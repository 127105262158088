import {
    extractGa4EcommerceProduct,
    IGa4EcommerceProduct
} from "@pwa-onilab/ga4/helpers/product/extractGa4EcommerceProduct";
import ReactGA4 from "react-ga4";
import { GA4_OPERATIONS } from "../../constants/ga4Operations";

export interface IGa4EcommerceSelectItem extends IGa4EcommerceProduct {
    itemsListName?: string
    itemsListId?: string
}

export const ga4SelectItem = (
    {
        itemsListId,
        itemsListName,
        ...ecommerceProductProps
    }: IGa4EcommerceSelectItem) => {

    const ecommerceData = {
        item_list_id: itemsListId,
        item_list_name: itemsListName,
        items: extractGa4EcommerceProduct(ecommerceProductProps),
    }

    ReactGA4.gtag('event', GA4_OPERATIONS.selectItem, ecommerceData)
}

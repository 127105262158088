import { PlaceholderPaginationListItems } from '@pwa-onilab/ui/components/elements/PlaceholderPaginationListItems'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import Title from '@pwa-onilab/ui/components/UI/Title'
import { useGetStates } from 'app/modules/states'
import PaginationLoadMore from 'components/overrides/modules/Pagination/elements/PaginationLoadMore/PaginationLoadMore'
import { DisablePageBuilderStyles } from 'elements/DisablePageBuilderStyles'
import { StateCard, StateCardPlaceholder } from 'modules/LocationPage'
import { useCallback, useState } from 'react'

import styles from './LocationStatesWidget.module.scss'

interface ILocationStatesWidget {
    id: number
    name: string
    url_key: string
    url_path: string
}

const LOCATION_WIDGET_PAGE_SIZE = 10

const LocationStatesWidget = (
    {
        id,
        name,
        url_path: urlPath,
    }: ILocationStatesWidget) => {
    const [isReachLastPage, setIsReachLastPage] = useState<boolean>(false)

    const { data, loading: statesLoading, fetchMore } = useGetStates({
        locationId: id.toString(10),
        currentPage: 1,
        pageSize: LOCATION_WIDGET_PAGE_SIZE,
    })

    const totalItemsCount = data?.pagination?.count || 0
    const totalPagesCount = data?.pagination?.total || 0

    const onLoadPage = useCallback((page: number) => {
        if (page === totalPagesCount) {
            setIsReachLastPage(true)
        }
        fetchMore({ page })
    }, [totalPagesCount])

    const isInitialDataLoad = statesLoading && !data
    const isNextPageLoad = statesLoading && data

    return (
        <DisablePageBuilderStyles>
            {
                name && (
                    urlPath
                        ? (
                            <Anchor to={urlPath}>
                                <Title type="h2" text={name} className={styles.title} />
                            </Anchor>
                            )
                        : <Title type="h2" text={name} className={styles.title} />
                )
            }
            <ul className={styles.wrapper}>
                {
                    isInitialDataLoad && (
                        <PlaceholderPaginationListItems
                            maxPlaceholdersOnPage={LOCATION_WIDGET_PAGE_SIZE}
                            alternativePlaceholderComponent={StateCardPlaceholder}
                            itemClassName={styles.cardSize}
                        />
                    )
                }
                {
                    data?.items.map((state) => {
                        return (
                            <li key={state.id} className={styles.cardSize}>
                                <StateCard state={state} />
                            </li>
                        )
                    })
                }
                {
                    isNextPageLoad && (
                        <PlaceholderPaginationListItems
                            maxPossibleItems={totalItemsCount
                                ? totalItemsCount - data.items.length
                                : LOCATION_WIDGET_PAGE_SIZE}
                            maxPlaceholdersOnPage={LOCATION_WIDGET_PAGE_SIZE}
                            alternativePlaceholderComponent={StateCardPlaceholder}
                            itemClassName={styles.cardSize}
                        />
                    )
                }
            </ul>
            {
                !isReachLastPage && (
                    <PaginationLoadMore
                        data={data?.pagination}
                        onLoadPage={onLoadPage}
                    />
                )
            }
        </DisablePageBuilderStyles>
    )
}

export default LocationStatesWidget

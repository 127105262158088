import useCartAddItem from '@pwa-concept/modules/cart/features/add/hooks/useCartAddItem'
import { CartItem, Product, ProductVariant } from '@pwa-concept/modules/graphql'
import { ga4AddToCart } from '@pwa-onilab/ga4/operations/cart/addToCart'
import { AddedProduct } from '@pwa-onilab/ui/components/modules/SuccessAddToCartModal/SuccessAddToCartModal'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

export interface IEnteredOption {
    [key: string]: string
}

export interface IAddToCardHandlerParams {
    product: Product
    variant?: ProductVariant
    quantity?: number
    enteredOptions?: IEnteredOption
}

const getSelectedPropertiesFromCustomization = (
    currentProduct: Product,
    addedProduct: AddedProduct,
    enteredOptions: IEnteredOption,
    accumulator: string[],
) => {
    currentProduct.options?.forEach((option) => {
        if (!option.name) {
            return
        }

        const currentEnteredOption = enteredOptions[option.name]
        if (currentEnteredOption) {
            const currentAttribute = option.values.find((attribute) => {
                return attribute.value === currentEnteredOption
            })
            if (!currentAttribute) {
                return
            }

            addedProduct.selectedProperties.push({
                property: option.name,
                value: currentAttribute.name,
            })
            accumulator.push(currentEnteredOption)
        }
    })
}

const getSelectedPropertiesFromConfigurable = (
    variant: ProductVariant,
    product: Product,
    addedProduct: AddedProduct,
    accumulator: string[],
) => {
    variant.options.forEach((option) => {
        const currentOptionTypeInfo = product.options.find((fullOption) => fullOption.key === option.key)
        const currentOptionInfo = currentOptionTypeInfo.values.find((optionValue) => {
            return optionValue.value === option.value
        })

        addedProduct.selectedProperties.push({
            property: currentOptionTypeInfo.key,
            value: currentOptionInfo.name,
        })
        accumulator.push(option.value)
    })
}

// selected properties needed in success add to cart modal
const useAddToCart = () => {
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [addedProduct, setAddedProduct] = useState(null)

    const addItemToCart = useCartAddItem()

    const addToCardHandler = useCallback(async (
        {
            product,
            variant,
            quantity,
            enteredOptions,
        }: IAddToCardHandlerParams) => {
        const cartItems: [CartItem] = [{
            quantity: quantity || 1,
            sku: product.parentSku || product.sku,
        }]

        const addedProduct: AddedProduct = {
            ...product,
            ...variant?.product,
            ...cartItems[0],
            quantity: cartItems[0].quantity,
            selectedProperties: (enteredOptions || variant?.options) ? [] : null,
        }

        const currentProduct = variant?.product || product
        if (enteredOptions) {
            cartItems[0].selected_options = []
            getSelectedPropertiesFromCustomization(
                currentProduct,
                addedProduct,
                enteredOptions,
                cartItems[0].selected_options,
            )
        }

        if (variant?.options) {
            cartItems[0].selected_options = cartItems[0].selected_options || []
            getSelectedPropertiesFromConfigurable(variant, product, addedProduct, cartItems[0].selected_options)
        }

        setAddedProduct(addedProduct)

        try {
            await addItemToCart({ cartItems })
            ga4AddToCart({
                product,
                currentVariant: variant,
                quantity,
            })
            setIsSuccessModalOpen(true)
        } catch (e) {
            toast.error(e.message)
        }
    }, [])

    const closeSuccessModal = useCallback(() => {
        setIsSuccessModalOpen(false)
    }, [])

    return {
        addToCardHandler,
        isSuccessModalOpen,
        closeSuccessModal,
        addedProduct,
    }
}

export default useAddToCart

import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const getStates: QueryResolvers['getStates'] = async (_, { filter, pageSize, currentPage }) => {
    const { data: { getStates: __context } = { } } = await (
        api.graphql(
            gql`
            query($filter: LocationStatesFilterInput, $currentPage: Int, $pageSize: Int) {
                getStates(filter: $filter, currentPage: $currentPage, pageSize: $pageSize) {
                    items {
                        ... State
                    }
                    page_info {
                        current_page
                        page_size
                        total_pages
                    }
                    total_count
                }
            }
        `,
        ).variableIf(filter?.stateId, (prev) => {
            return {
                ...prev,
                filter: {
                    ...prev.filter,
                    state_id: {
                        eq: filter.stateId,
                    },
                },
            }
        }).variableIf(filter?.locationId, (prev) => {
            return {
                ...prev,
                filter: {
                    ...prev.filter,
                    location_id: {
                        eq: filter.locationId,
                    },
                },
            }
        }).query({
            currentPage,
            pageSize,
        })
    )

    if (!__context?.items) {
        return null
    }

    return {
        items: __context.items.map((item) => {
            return {
                __context: item,
                __typename: 'State',
            }
        }),
        pagination: {
            count: __context.total_count || null,
            current: __context.page_info?.current_page || null,
            limit: __context.page_info?.page_size || null,
            total: __context.page_info?.total_pages || null,
            __typename: 'Pagination',
        },
        __typename: 'States',
    }
}

export default getStates

import Container from '@pwa-onilab/ui/components/UI/Container'
import { ContainerProp } from '@pwa-onilab/ui/components/UI/Container/Container'
import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import styles from './StrainFamilyPageContainer.module.scss'

const StrainFamilyPageContainer = ({ children, className, ...props }: PropsWithChildren<ContainerProp>) => {
    return (
        <Container {...props} className={classNames(styles.container, className)}>
            {children}
        </Container>
    )
}

export default StrainFamilyPageContainer

import { BlogAuthor } from '@pwa-concept/modules/graphql'
import { useStoreConfig } from '@pwa-concept/modules/store-config'
import {
    AuthorPageCategoriesList,
    AuthorPageCategoriesListPlaceholder,
    AuthorPageLeftColumn,
    AuthorPagePostsRowTitle,
} from 'modules/AuthorPage'
import { useTranslation } from 'react-i18next'

const AuthorPageCategories = ({ author, loading: authorLoading }: {author?: BlogAuthor, loading: boolean}) => {
    const { t } = useTranslation()
    const { loading: storeConfigLoading } = useStoreConfig()

    const loading = authorLoading || storeConfigLoading

    return (
        <AuthorPageLeftColumn>
            <AuthorPagePostsRowTitle
                type="h5"
                text={t('AuthorPage.posts.categories.title')}
            />
            {
                loading
                    ? <AuthorPageCategoriesListPlaceholder />
                    : <AuthorPageCategoriesList categories={author.categories} />
            }
        </AuthorPageLeftColumn>
    )
}

export default AuthorPageCategories

import { MediaBreakpointDown, MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { IFilterTag } from '@pwa-onilab/ui/components/modules/Filters'
import { FilterActiveOptions } from '@pwa-onilab/ui/components/modules/Filters/components/FilterActiveOptions'
import { FilterClearAll } from '@pwa-onilab/ui/components/modules/Filters/elements/FilterClearAll'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import { Counter } from '@pwa-onilab/ui/components/UI/Counter'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { useTranslation } from 'react-i18next'

import styles from './FilterModalHeader.module.scss'

interface FilterModalHeaderProps {
    onClose: () => void
    tags: IFilterTag[]
}
const FilterModalHeader = (
    {
        onClose,
        tags,
    }: FilterModalHeaderProps,
) => {
    const { t } = useTranslation()

    return (
        <>
            <div className={styles.header}>
                <MediaBreakpointUp name="lg">
                    <ButtonInstance
                        onClick={onClose}
                        className={styles.button}
                    >
                        <Icon
                            name="close"
                            className={styles.closeIcon}
                        />
                    </ButtonInstance>
                </MediaBreakpointUp>
                <div className={styles.title}>
                    {t('global.filter')}

                    {
                        tags?.length > 0 && (
                            <Counter className={styles.counter}>
                                {tags?.length}
                            </Counter>
                        )
                    }
                </div>
                {
                    tags?.length > 0 && (
                        <FilterClearAll className={styles.clearAll} />
                    )
                }
            </div>
            <MediaBreakpointDown name="lg">
                <FilterActiveOptions
                    tags={tags}
                    withoutClearAll
                    className={styles.activeOptions}
                />
            </MediaBreakpointDown>
        </>
    )
}

export default FilterModalHeader

import { useTranslation } from 'react-i18next'

import styles from './SubscriptionContent.module.scss'

const SubscriptionContent = () => {
    const { t } = useTranslation()

    return (
        <>
            <div className={styles.title}>
                {t('global.subscription.joinOurNewsletter')}
            </div>
            <div className={styles.description}>
                {t('global.subscription.offer')}
            </div>
        </>
    )
}

export default SubscriptionContent

import { IProductLabel } from '@pwa-onilab/ui/components/modules/Product/elements/ProductBadges/IProductBadge'

const ProductLabel = ({ label, color, backgroundColor: background }: IProductLabel) => {
    return (
        <div style={{ color, background, fontWeight: 700 }}>
            {label}
        </div>
    )
}

export default ProductLabel

import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useTranslation } from 'react-i18next'

import styles from './CartDeleteButton.module.scss'

interface DeleteButtonProps {
    onClick: () => void
    isOutOfStock?: boolean
}

const CartDeleteButton = ({ onClick, isOutOfStock }: DeleteButtonProps,
) => {
    const { t } = useTranslation()

    return (
        <ButtonInstance
            className={classNames(styles.wrapper, isOutOfStock && styles.outOfStock)}
            onClick={onClick}
        >
            <Icon name="trash" className={styles.icon} />
            <span className={styles.description}>{t('cart.buttons.remove')}</span>
        </ButtonInstance>
    )
}

export default CartDeleteButton

import { SimpleButton } from '@pwa-onilab/ui/components/elements/Buttons'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './ChangePasswordButton.module.scss'

interface IChangePasswordButton {
    onClick: () => void
    isShowPasswordFields: boolean
}

const ChangePasswordButton = ({ onClick, isShowPasswordFields }: IChangePasswordButton) => {
    const { t } = useTranslation()

    if (isShowPasswordFields) {
        return (
            <h3 className={styles.title}>
                {t('AccountPage.buttons.changePassword')}
            </h3>
        )
    }

    return (
        <SimpleButton onClick={onClick} className={styles.button}>
            {t('AccountPage.buttons.changePassword')}
        </SimpleButton>
    )
}

export default memo(ChangePasswordButton)

import { findAllSubstrings } from '@pwa-onilab/ui/components/widgets/FaqWidget'
import { REGEX_HTML_TAG } from '@pwa-onilab/ui/constants/patterns'
import Parser, { classNames } from '@pwa-onilab/ui/helpers'
import { useMemo } from 'react'

interface ISelectedTextActiveProps {
    text: string
    benchmark: string
    wrapperClassName: string
    activeClassName: string
}

const SelectedTextActive = (
    {
        text,
        benchmark,
        wrapperClassName,
        activeClassName,
    }: Partial<ISelectedTextActiveProps>,
) => {
    const subStrings = useMemo(() => {
        if (!!benchmark && !!text) {
            return findAllSubstrings(text, benchmark)
        }

        return null
    }, [benchmark, text])

    return subStrings && (
        <Parser content={
			`<div class="${classNames(wrapperClassName)}">
				${subStrings.reduce((acc, subStr) => {
                        const isActiveSubstring = subStr.toLowerCase() === benchmark.toLowerCase()
                        const isHtmlTag = subStr.match(REGEX_HTML_TAG)

                        if (isActiveSubstring && !isHtmlTag) {
                            return `${acc} <span class="${classNames(isActiveSubstring && activeClassName)}">${subStr}</span>`
                        }

                        return acc + subStr
                    }, '')
		        }
			</div>`
		}
        />
    )
}

export default SelectedTextActive

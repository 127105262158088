import api from '@pwa-concept/core/api'
import { gql } from 'graphql-tag'

const createCustomerAddressMutation = async (inputFields) => await api.graphql(
    gql`
        mutation($input: CustomerAddressInput!) {
            createCustomerAddress(input: $input) {
                ... CustomerAddress
            }
        }
    `,
).mutation({
    input: inputFields,
})

export default createCustomerAddressMutation

import { gql } from '@apollo/client'
import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'

let lastSearchedValue = ''
const promisesArray = []

// it realized like this, because mapping in out inner format + store it in cache is very expensive operation.
// wait when last user query was completed, then parse it
const liveSearch: QueryResolvers['liveSearch'] = async (prop1, { search, pageSize }, prop3) => {
    lastSearchedValue = search
    promisesArray.push(
        api.graphql(gql`
            query(
                $search: String!
                $pageSize: Int,
                $isFullProduct: Boolean = false,
            ) {
                liveSearchResults(search: $search, pageSize: $pageSize) {
                    categories {
                        id
                        link
                        name
                        product_count
                    }
                    suggestions
                    products (
                        search: $search,
                        pageSize: $pageSize
                    ) {
                        page_info {
                            current_page
                            page_size
                            total_pages
                        }
                        items {
                            ... ProductInterface
                        }
                    }
                }
            }
        `).query({
            search,
            pageSize,
        }),
    )

    let lastResponse = null
    await Promise.allSettled(promisesArray)
        .then((result) => {
            if (search === lastSearchedValue) {
                lastResponse = result[result.length - 1]
            }
        })

    promisesArray.length = 0
    if (!lastResponse) {
        return null
    }

    return {
        __context: lastResponse.value?.data?.liveSearchResults,
        __typename: 'LiveSearchResult',
    }
}

export default liveSearch

import { HelpTitle } from '@pwa-onilab/ui/components/modules/Help'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import HelpContactUs from 'components/overrides/modules/Help/HelpContactUs/HelpContactUs'
import HelpTelephone from 'components/overrides/modules/Help/HelpTelephone/HelpTelephone'

import styles from './Help.module.scss'

const Help = (
    {
        className,
    }: IClassName,
) => {
    return (
        <div className={classNames(styles.wrapper, className)}>
            <HelpTitle className={styles.title} />
            <HelpTelephone className={styles.telephone} />
            <HelpContactUs />
        </div>
    )
}

export default Help

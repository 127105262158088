import { IAddressFormTitle } from '@pwa-onilab/ui/components/modules/AddressForm/elements/sections/AddressFormTitle/index'

import styles from './AddressFormTitle.module.scss'

const AddressFormTitle = ({ title }: IAddressFormTitle) => {
    return (
        <h2 className={styles.wrapper}>
            {title}
        </h2>
    )
}

export default AddressFormTitle

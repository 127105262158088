import { module } from '@pwa-concept/core'
import {
    IFilterMapperDecoratorObject,
    IFilterObject,
    productAttributesFilters, productAttributesFiltersMappers,
} from '@pwa-concept/modules/filter/models/productAttributesFilters'

import graphqlSchemas from './graphql/schemas'
import stores from './graphql/stores'

export interface IConfiguredFilterModule {
    filters?: IFilterObject
    filterMappers?: IFilterMapperDecoratorObject
}

const FilterModule = module(() => {
    return ({
        graphqlSchemas,
        graphqlResolvers: stores?.resolvers,
        graphqlStoreFragments: stores?.fragments,
        graphqlStorePossibleTypes: stores?.possibleTypes,
        graphqlPolicies: {
            Filter: {
                keyFields: false,
            },
            FilterItem: {
                keyFields: false,
            },
        },
    })
})

const ConfiguredFilterModule = ({ filters, filterMappers }: IConfiguredFilterModule) => {
    for (const [key, value] of Object.entries(filters || {})) {
        productAttributesFilters[key] = value
    }

    for (const [key, value] of Object.entries(filterMappers || {})) {
        productAttributesFiltersMappers[key] = value
    }

    return FilterModule
}

export { ConfiguredFilterModule, FilterModule }

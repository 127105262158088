import api from '@pwa-concept/core/api'
import { MutationResolvers } from '@pwa-concept/modules/graphql'
import { GraphQLError } from 'graphql'
import { gql } from 'graphql-tag'

const removeProductsFromWishlist: MutationResolvers['removeProductsFromWishlist'] = async (_, input) => {
    const {
        id,
        items,
        page,
        limit,
    } = input

    if (!id) {
        throw new Error('Cannot perform operation')
    }

    const { data: { removeProductsFromWishlistOnilab = {} } = {} } = (
        await api.graphql(
            gql`
                mutation(
                    $wishlistUid: String!
                    $wishlistItemsIds: [ID!]!
                    $pageSize: Int = 20
                    $currentPage: Int = 1
                    $isFullProduct: Boolean = false
                ) {
                    removeProductsFromWishlistOnilab(
                        wishlistUid: $wishlistUid
                        wishlistItemsIds: $wishlistItemsIds
                    ) {
                        onilab_wishlist {
                            uid
                            items(
                                currentPage: $currentPage
                                pageSize: $pageSize
                            ) {
                                items {
                                    added_at
                                    description
                                    id
                                    product {
                                        ... ProductInterface
                                    }
                                }

                                page_info {
                                    current_page
                                    page_size
                                    total_pages
                                }
                            }
                            items_count
                            sharing_code
                        }
                        user_errors {
                            code
                            message
                        }
                    }
                }
            `,
        ).mutation({
            wishlistUid: id,
            wishlistItemsIds: items,
            pageSize: limit,
            currentPage: page,
        })
    )

    if (removeProductsFromWishlistOnilab?.user_errors?.length) {
        throw new GraphQLError(removeProductsFromWishlistOnilab.user_errors[0].message)
    }

    if (!removeProductsFromWishlistOnilab) {
        return null
    }

    return {
        __context: removeProductsFromWishlistOnilab,
        __typename: 'WishlistWithErrors',
    }
}

export default removeProductsFromWishlist

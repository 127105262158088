import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type ShareWishlistMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  emails: Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>;
  message?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ShareWishlistMutation = { __typename?: 'Mutation', shareWishlist?: { __typename?: 'WishlistShareOutput', status?: string | null } | null };


export const ShareWishlistDocument = gql`
    mutation ShareWishlist($id: ID!, $emails: [String]!, $message: String) {
  shareWishlist(id: $id, emails: $emails, message: $message) @client {
    status
  }
}
    `;
export type ShareWishlistMutationFn = Apollo.MutationFunction<ShareWishlistMutation, ShareWishlistMutationVariables>;

/**
 * __useShareWishlistMutation__
 *
 * To run a mutation, you first call `useShareWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareWishlistMutation, { data, loading, error }] = useShareWishlistMutation({
 *   variables: {
 *      id: // value for 'id'
 *      emails: // value for 'emails'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useShareWishlistMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ShareWishlistMutation, ShareWishlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ShareWishlistMutation, ShareWishlistMutationVariables>(ShareWishlistDocument, options);
      }
export type ShareWishlistMutationHookResult = ReturnType<typeof useShareWishlistMutation>;
export type ShareWishlistMutationResult = Apollo.MutationResult<ShareWishlistMutation>;
export type ShareWishlistMutationOptions = Apollo.BaseMutationOptions<ShareWishlistMutation, ShareWishlistMutationVariables>;
import { SimpleButton } from '@pwa-onilab/ui/components/elements/Buttons'
import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { Counter } from '@pwa-onilab/ui/components/UI/Counter'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

import css from './FilterModalTrigger.module.scss'

interface FilterModalTriggerProps {
    className?: string
    onClick?: MouseEventHandler
    tagsCount: number
}

const FilterModalTrigger = (
    {
        className,
        onClick,
        tagsCount,
    }: FilterModalTriggerProps,
) => {
    const { t } = useTranslation()

    return (
        <SimpleButton
            onClick={onClick}
            className={classNames(css.button, className)}
        >
            <Icon
                name="filterList"
                className={css.icon}
            />
            <div className={css.title}>
                {t('global.filter')}
            </div>

            <MediaBreakpointUp name="lg">
                {
                    tagsCount > 0 && (
                        <Counter className={css.count}>
                            {tagsCount}
                        </Counter>
                    )
                }
            </MediaBreakpointUp>
        </SimpleButton>
    )
}

export default FilterModalTrigger

import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { useSliderContext } from '@pwa-onilab/ui/components/UI/Slider/providers'
import { classNames } from '@pwa-onilab/ui/helpers'
import { memo, useEffect, useRef } from 'react'
import { NavigationOptions } from 'swiper/types/modules/navigation'

import styles from './SliderNavigation.module.scss'

const VARIANTS = {
    default: undefined,
    filled: styles.filled,
    vertical: styles.vertical,
}

interface SliderNavigationProps {
    className?: string
    prevClassName?: string
    nextClassName?: string
    variant?: keyof typeof VARIANTS
}

const SliderNavigation = memo((
    {
        className,
        prevClassName,
        nextClassName,
        variant = 'default',
    }: SliderNavigationProps,
) => {
    const nextRef = useRef(null)
    const prevRef = useRef(null)
    const { slider } = useSliderContext()

    useEffect(() => {
        if (nextRef.current && prevRef.current && slider && (slider?.params?.navigation as NavigationOptions)?.nextEl !== nextRef.current) {
            try {
                const navigation = (slider.params.navigation as NavigationOptions)
                navigation.nextEl = nextRef.current
                navigation.prevEl = prevRef.current

                slider.navigation.init()
                slider.navigation.update()
            } catch (e) {
                //
            }
        }

        return () => {
            if (slider) {
                try {
                    slider?.navigation.destroy?.()
                } catch (e) {
                    //
                }
            }
        }
    }, [slider])

    return (
        <div
            className={classNames(
                className,
                (variant && VARIANTS?.[variant]) || VARIANTS.default,
            )}
        >
            <div
                ref={prevRef}
                className={classNames(
                    'swiper-button-prev',
                    styles.button,
                    prevClassName,
                )}
            >
                <Icon name="nextArrow" className={styles.icon} />
            </div>

            <div
                ref={nextRef}
                className={classNames(
                    'swiper-button-next',
                    styles.button,
                    nextClassName,
                )}
            >
                <Icon name="nextArrow" className={styles.icon} />
            </div>
        </div>
    )
})

export { SliderNavigation }

import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import css from './Skeleton.module.scss'

export interface SkeletonProps {
    className?: String
    inline?: boolean
    width?: string
    height?: string
    borderRadius?: string
}

const Skeleton = (
    {
        className,
        inline = false,
        children,
        ...style
    }: PropsWithChildren<SkeletonProps>,
) => (
    <div className={classNames(css.skeleton, className, inline && css.inline)} style={style}>
        {children}
    </div>
)

export default Skeleton

import { CategoryItemsSkeleton } from '@pwa-onilab/ui/components/modules/Category'
import { FilterControlsSkeleton } from '@pwa-onilab/ui/components/modules/Filters/components/FilterControls'

import css from './Filters.module.scss'

const FiltersSkeleton = () => (
    <>
        <div className={css.wrapper}>
            <FilterControlsSkeleton />
        </div>
        <CategoryItemsSkeleton />
    </>
)

export { FiltersSkeleton }

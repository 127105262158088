import { StoreFragments } from '@pwa-concept/core'

import State from './StateClient.graphql'
import States from './StatesClient.graphql'

const fragments: StoreFragments = [
    State,
    States,
]

export default fragments

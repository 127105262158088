import { Skeleton } from '@pwa-onilab/ui/components/elements/Skeleton'
import { classNames } from '@pwa-onilab/ui/helpers'

import css from './CmsPageBreadcrumbs.module.scss'

interface ICmsPageBreadcrumbsSkeletonProps {
    className: string
}

const CmsPageBreadcrumbsSkeleton = (
    {
        className,
    }: Partial<ICmsPageBreadcrumbsSkeletonProps>,
) => (
    <Skeleton className={classNames(css.skeleton, className)} />
)

export { CmsPageBreadcrumbsSkeleton }

import { useContextPattern } from '@pwa-onilab/ui/hooks'
import { createContext, PropsWithChildren, useState } from 'react'

const NumResultsValueCtx = createContext(null)
const NumResultsApiCtx = createContext(null)

const useCategoryNumResultsValue = () => useContextPattern(NumResultsValueCtx)
const useSetCategoryNumResults = () => useContextPattern(NumResultsApiCtx)

const CategoryNumResultsContext = ({ children }: PropsWithChildren<any>) => {
    const [numResults, setNumResults] = useState<number>(0)

    return (
        <NumResultsApiCtx.Provider value={setNumResults}>
            <NumResultsValueCtx.Provider value={numResults}>
                {children}
            </NumResultsValueCtx.Provider>
        </NumResultsApiCtx.Provider>
    )
}

export { CategoryNumResultsContext, useSetCategoryNumResults, useCategoryNumResultsValue }

import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import { classNames } from '@pwa-onilab/ui/helpers'
import { MouseEventHandler } from 'react'

import styles from './MenuAnchor.module.scss'

interface IMenuAnchorProps {
    className?: string
    title: string
    to?: string
    active: boolean
    onMouseEnter: MouseEventHandler
    onClick?: () => void
}

const MenuAnchor = (
    {
        className,
        title,
        to,
        active,
        onMouseEnter,
        onClick,
    }: IMenuAnchorProps,
) => {
    if (to) {
        return (
            <Anchor
                className={classNames(styles.button, active && styles.active, className)}
                to={to}
                onMouseEnter={onMouseEnter}
                onClick={onClick}
            >
                {title}
            </Anchor>
        )
    }

    return (
        <ButtonInstance
            className={classNames(styles.button, active && styles.active, className)}
            onMouseEnter={onMouseEnter}
            onClick={onClick}
        >
            {title}
        </ButtonInstance>
    )
}

export default MenuAnchor

import { useSorts } from '@pwa-concept/modules/sort'
import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import {
    FilterSortByDesktop,
    FilterSortByMobile,
} from '@pwa-onilab/ui/components/modules/Filters'
import { IFilterSortByData } from 'components/overrides/modules/Filters/components/FilterSortBy/IFilterSortBy'
import { runDefaultSort } from 'modules/Filters/helpers/sortByFunctions'
import { useCallback } from 'react'

const FilterSortBy = (
    {
        className,
        onChangeValue,
        sortId,
        data: customData = null,
        isSearch,
    }: IFilterSortByData,
) => {
    const { data: sortsData } = useSorts({ skip: !!customData })
    const data = customData || sortsData

    const { isBreakpointUp } = useMediaBreakpoint()

    const defaultSortId = data?.default

    let currentId = sortId
    if (!currentId && !isSearch) {
        currentId = defaultSortId
    }

    const onChangeSort = useCallback((item) => {
        onChangeValue(runDefaultSort(item, defaultSortId, isSearch))
    }, [defaultSortId, isSearch])

    if (isBreakpointUp('lg')) {
        if (!data?.items) {
            return null
        }

        return (
            <FilterSortByDesktop
                className={className}
                onChangeValue={onChangeSort}
                sortId={currentId}
                items={data.items}
            />
        )
    }

    return (
        <FilterSortByMobile
            className={className}
            onChangeValue={onChangeSort}
            items={data?.items}
            sortId={currentId}
        />
    )
}

export default FilterSortBy

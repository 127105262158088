import Container from '@pwa-onilab/ui/components/UI/Container'
import { GiftSectionItem } from '@pwa-onilab/ui/components/widgets/GiftSection'

import styles from './GiftSectionItems.module.scss'

interface IGiftSectionItemsProps {
    slides: Array<{
        description: string
        desktop_image: string
        link_title: string
        link_url: string
        mobile_image: string
        title: string
        text_color: string
    }>
}

const GiftSectionItems = ({ slides }: IGiftSectionItemsProps) => (
    <Container isRevertGift className={styles.wrapper}>
        {
            slides.map(({
                description,
                desktop_image: desktopImage,
                link_title: linkTitle,
                link_url: linkUrl,
                mobile_image: mobileImage,
                title,
                text_color: textColor,
            }) => (
                <GiftSectionItem
                    image={desktopImage}
                    imageMobile={mobileImage}
                    title={title}
                    subtitle={description}
                    link={linkUrl}
                    linkText={linkTitle}
                    textColor={textColor}
                    key={linkUrl + desktopImage}
                />
            ))
        }
    </Container>
)

export default GiftSectionItems

import { FaqAccordionItem } from '@pwa-onilab/ui/components/widgets/FaqWidget/FaqAccordion'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './FaqAccordion.module.scss'

interface IFaqAccordionProps {
    className: string
    data: {}
    benchmark: string
}

const FaqAccordion = (
    {
        className,
        data,
        benchmark,
    }: Partial<IFaqAccordionProps>,
) => {
    if (!data?.length) return null

    return (
        <div className={classNames(styles.wrapper, className)}>
            {
                data.map(({ title, description }) => (
                    <FaqAccordionItem
                        key={title}
                        className={styles.item}
                        title={title}
                        content={description}
                        benchmark={benchmark}
                    />
                ))
             }
        </div>
    )
}

export default FaqAccordion

import { LiveSearchResult } from '@pwa-concept/modules/graphql'
import { SearchList, SearchProducts } from '@pwa-onilab/ui/components/modules/Search'
import { ISearchCategory } from '@pwa-onilab/ui/components/modules/Search/hooks/ISearchCategory'
import { classNames } from '@pwa-onilab/ui/helpers'
import SearchSeeAll from 'components/overrides/modules/Search/elements/SearchSeeAll/SearchSeeAll'
import { SearchBlogPosts } from 'modules/Search'
import { useTranslation } from 'react-i18next'

import styles from './SearchResultFound.module.scss'

interface ISearchResultFoundProps {
    searchResult?: LiveSearchResult
    searchValue: string
    popularSearchItems: string[]
    categories: ISearchCategory[]
}

const SearchResultFound = (
    {
        searchResult,
        searchValue,
        popularSearchItems,
        categories,
    }: ISearchResultFoundProps,
) => {
    const { t } = useTranslation()

    return (
        <div className={styles.wrapper}>
            <div className={classNames(styles.aside, !!categories?.length && styles.asideIndent)}>
                <SearchList
                    items={categories}
                    title={t('search.categories')}
                />
                <SearchBlogPosts />
            </div>
            <div className={styles.content}>
                <SearchList
                    items={popularSearchItems}
                    title={t('search.popular.searches')}
                    className={styles.popularSearches}
                />
                <SearchProducts items={searchResult?.products?.items || []} />
                <SearchSeeAll
                    searchValue={searchValue}
                    isHideButton={!searchResult?.products?.pageInfo?.totalPages}
                />
            </div>
        </div>
    )
}

export default SearchResultFound

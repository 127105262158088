import { IPaginationList, PaginationCell } from '@pwa-onilab/ui/components/modules/Pagination'
import { usePaginationPages } from '@pwa-onilab/ui/components/modules/Pagination/hooks'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useTranslation } from 'react-i18next'

// TODO to fast fix all pagination issues we can use https://www.npmjs.com/package/react-paginate
const PaginationList = (
    {
        pagination,
        onClick,
        wrapperClassName,
        activeClassName,
        cellClassName,
        previousClassName,
        nextClassName,
        breakClassName,
        previousLabel = '<<',
        nextLabel = '>',
        breakLabel = '...',
        isShowPreviousCell = true,
        isShowNextCell = true,
        isShowAllPages,
    }: IPaginationList) => {
    if (!pagination || pagination.total < 2) {
        return null
    }

    const mainCellsCommonProps = {
        className: cellClassName,
        activeClassName,
    }

    const { t } = useTranslation()
    const pages = usePaginationPages(pagination, isShowAllPages)

    const {
        current: currentPage,
        total: maxPage,
    } = pagination

    const onNextPage = () => onClick(currentPage + 1)

    const onFirstPage = () => onClick(1)
    const onMaxPage = () => onClick(maxPage)

    const isIncludeIntermediatePages = !isShowAllPages && !!pages.length
    const isShowFirstTripleDots = isIncludeIntermediatePages && (pages[0] - 1) !== 1
    const isShowSecondTripleDots = isIncludeIntermediatePages && (pages[pages.length - 1] + 1) !== maxPage

    const isFirstPage = currentPage === 1
    const isMaxPage = currentPage === maxPage

    return (
        <div className={wrapperClassName}>
            {
                isShowPreviousCell && (
                    <PaginationCell
                        className={classNames(cellClassName, previousClassName)}
                        disabled={isFirstPage}
                        onClick={onFirstPage}
                        aria-label={t('buttons.navigation.goToFirst')}
                    >
                        {previousLabel}
                    </PaginationCell>
                )
            }
            <PaginationCell {...mainCellsCommonProps} onClick={onFirstPage} isActive={isFirstPage}>
                1
            </PaginationCell>
            {isShowFirstTripleDots && (
                // TODO any action?
                <span className={classNames(cellClassName, breakClassName)}>
                    {breakLabel}
                </span>
            )}
            {pages.map((page) => {
                const isActive = currentPage === page
                const selectPage = () => onClick(page)

                return (
                    <PaginationCell
                        key={page}
                        {...mainCellsCommonProps}
                        isActive={isActive}
                        onClick={selectPage}
                    >
                        {page}
                    </PaginationCell>
                )
            })}
            {isShowSecondTripleDots && (
                // TODO any action?
                <span className={classNames(cellClassName, breakClassName)}>
                    {breakLabel}
                </span>
            )}
            <PaginationCell {...mainCellsCommonProps} isActive={isMaxPage} onClick={onMaxPage}>
                {maxPage}
            </PaginationCell>
            {
                isShowNextCell && (
                    <PaginationCell
                        className={classNames(cellClassName, nextClassName)}
                        disabled={isMaxPage}
                        onClick={onNextPage}
                        aria-label={t('buttons.next')}
                    >
                        {nextLabel}
                    </PaginationCell>
                )
            }
        </div>
    )
}

export default PaginationList

import { useCountries } from '@pwa-concept/modules/countries'
import { ADDRESS_FORM_FIELDS } from '@pwa-onilab/ui/components/modules/AddressForm'
import { useFormContext } from 'react-hook-form'

// TODO if we completely sure that countries data never change, we can add cache object
const useRegionFieldOptions = () => {
    const { watch } = useFormContext()
    const { data: countries } = useCountries()

    const chosenCountryId = watch(ADDRESS_FORM_FIELDS.COUNTRY_CODE, countries?.[0]?.id)

    const chosenCountryRegions = countries?.find((country) => country.id === chosenCountryId)?.availableRegions || []

    return chosenCountryRegions.map(({ id, name }) => ({ value: id, label: name }))
}

export default useRegionFieldOptions

import { Question } from '@pwa-concept/modules/graphql'
import Placeholder from '@pwa-onilab/ui/components/elements/Placeholder/Placeholder'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName, ILoading } from '@pwa-onilab/ui/interfaces'
import { FaqQuestionsAccordion, FaqQuestionsTitle } from 'modules/FaqQuestions'

import styles from './FaqQuestions.module.scss'

interface IFaqQuestionsProps extends ILoading, IClassName {
    faqQuestions: Question[]
}

const FaqQuestions = (
    {
        faqQuestions,
        className,
        isLoading,
    }: IFaqQuestionsProps,
) => {
    if (!faqQuestions?.length) {
        if (isLoading) {
            return <Placeholder className={classNames(styles.placeholder, className)} />
        }
        return null
    }

    return (
        <div className={classNames(styles.wrapper, className)}>
            <FaqQuestionsTitle />
            <FaqQuestionsAccordion faqQuestions={faqQuestions} />
        </div>
    )
}

export default FaqQuestions

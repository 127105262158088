import { SearchListItems, SearchTitle } from '@pwa-onilab/ui/components/modules/Search'
import { ISearchCategory } from '@pwa-onilab/ui/components/modules/Search/hooks/ISearchCategory'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './SearchList.module.scss'

interface ISearchListProps {
    title: string
    items: string[] | ISearchCategory[]
    className: string
}

const SearchList = (
    {
        title,
        items,
        className,
    }: Partial<ISearchListProps>,
) => {
    if (!items?.length) return null

    return (
        <div className={classNames(styles.wrapper, className)}>
            <SearchTitle
                title={title}
                className={styles.title}
            />
            <SearchListItems items={items} />
        </div>
    )
}

export default SearchList

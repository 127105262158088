import { selectProductDefaultVariant } from '@pwa-onilab/ui/components/modules/Product'
import SimpleProductCard, {
    ISimpleProductCard,
} from 'components/overrides/modules/Product/SimpleProductCard/SimpleProductCard'

const SimpleProductCardWrapper = (
    {
        product,
        currentViewVariant,
        itemsListName,
    }: ISimpleProductCard,
) => {
    const variant = selectProductDefaultVariant(product, product.sku)

    const currentProduct = product || variant?.product

    return (
        <SimpleProductCard
            currentViewVariant={currentViewVariant}
            product={currentProduct}
            itemsListName={itemsListName}
        />
    )
}

export default SimpleProductCardWrapper

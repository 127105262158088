import { Product } from '@pwa-concept/modules/graphql'
import { useProducts } from '@pwa-concept/modules/product'
import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { PopularProductsSlideAnchor } from 'components/widgets/PopularProducts'
import { addSlashToEndUrl } from 'helpers'

import styles from './PopularProductsSlide.module.scss'

interface IPopularProductsSlideProps {
    products: Product[]
    category: {
        name: string
        url: string
    }
}

const PopularProductsSlide = (
    {
        products: initialProductsList,
        category,
    }: IPopularProductsSlideProps,
) => {
    const skip = !initialProductsList?.length
    const skuArray = initialProductsList?.map((product) => product.sku)
    const { data, loading } = useProducts({ sku: skuArray }, { skip })

    const products = skip
        ? null
        : loading
            ? initialProductsList
            : data?.items

    const isShowCategoryLink = category?.url && category.name

    if (!isShowCategoryLink && !products?.length) {
        return null
    }

    return (
        <div className={styles.wrapper}>
            {
                isShowCategoryLink && (
                    <Anchor
                        to={addSlashToEndUrl(category.url)}
                        className={styles.title}
                    >
                        {category.name}
                    </Anchor>
                )
            }
            {
                products?.length && (
                    <div className={styles.list}>
                        {
                            products.map((product) => {
                                if (loading) {
                                    return (
                                        <Placeholder
                                            key={product.sku}
                                            className={styles.anchorPlaceholder}
                                        />
                                    )
                                }
                                return (
                                    <PopularProductsSlideAnchor
                                        key={product.sku}
                                        product={product}
                                    />
                                )
                            })
                        }
                    </div>
                )
            }
        </div>
    )
}

export default PopularProductsSlide

import { CLASS_NAME_CLOSE_MENU_AFTER_CLICK_ON_HEADER } from '@pwa-onilab/ui/components/modules/Header'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './MenuButton.module.scss'

interface IMenuButtonProps {
    className?: string
    isActive: boolean
    clickOnMenuItemHandler: (menuName: string) => void
    name: string
    icon: string
}

const MenuButton = (
    {
        className,
        isActive,
        clickOnMenuItemHandler,
        name,
        icon,
    }: IMenuButtonProps,
) => (
    <li className={classNames(isActive && styles.active, className)}>
        <ButtonInstance
            onClick={clickOnMenuItemHandler(name, isActive)}
            className={classNames(styles.button, CLASS_NAME_CLOSE_MENU_AFTER_CLICK_ON_HEADER)}
        >
            {
                !!icon && (
                    <Icon
                        name={icon}
                        className={styles.icon}
                    />
                )
            }
            {name}
        </ButtonInstance>
    </li>
)

export default MenuButton

import { useAuthLogin } from '@pwa-concept/modules/auth'
import { useLazyCustomer } from '@pwa-concept/modules/customer'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

interface ILogInAdditionalOptions {
    isShouldWaitCustomer?: boolean
    isShouldIgnoreToken?: boolean
}

const useLogIn = () => {
    const loginCustomer = useAuthLogin()
    const { getCustomer } = useLazyCustomer()
    const { t } = useTranslation()

    return useCallback(async (
        email: string,
        password: string,
        options: ILogInAdditionalOptions = {
            isShouldWaitCustomer: false,
            isShouldIgnoreToken: false,
        },

    ) => {
        try {
            const { isShouldWaitCustomer, isShouldIgnoreToken } = options

            await loginCustomer(email, password, isShouldIgnoreToken)
            if (isShouldWaitCustomer) {
                await getCustomer() // for get customer earlier than customer go to account
            } else {
                void getCustomer()
            }

            toast.success(t('loginForm.success'))
        } catch (error) {
            toast.error(error.message)
        }
    }, [])
}

export default useLogIn

import { useGetBlogAuthorPostsListQuery } from 'app/modules/blog-author/graphql/queries/GetBlogAuthorPostsList'

const useBlogAuthorPostsList = (id: string) => {
    const { data: { getBlogAuthorPostsList } = { }, loading } = useGetBlogAuthorPostsListQuery({
        variables: {
            id,
        },
        skip: !id,
    })

    return {
        data: getBlogAuthorPostsList || {},
        loading,
    }
}

export default useBlogAuthorPostsList

import { gql } from '@apollo/client'
import api from '@pwa-concept/core/api'
import { $cart } from '@pwa-concept/core/models'
import { MutationResolvers } from '@pwa-concept/modules/graphql'
import { GraphQLError } from 'graphql'

const cartSetPaymentMethod: MutationResolvers['cartSetPaymentMethod'] = async (_, { input }) => {
    const { paymentMethod: { code, extraInfo } = {} } = input

    const { data: { setPaymentMethodOnCart: { cart: __context = null } = {} } = {}, errors } = await (
        api.graphql(gql`
            mutation(
                $input: SetPaymentMethodOnCartInput
            ) {
                setPaymentMethodOnCart(input: $input) {
                    cart {
                        ... Cart
                    }
                }
            }
        `).mutation({
            input: {
                cart_id: $cart.id,
                payment_method: {
                    code,
                    ...extraInfo,
                },
            },
        })
    )
    if (errors) {
        throw new GraphQLError(errors[0].message)
    }

    if (!__context) return null

    return {
        __context,
        __typename: 'Cart',
    }
}

export default cartSetPaymentMethod

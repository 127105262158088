import { useCustomerOrder } from '@pwa-concept/modules/customer-orders'
import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'
import { IOrderComponent } from '@pwa-onilab/ui/components/modules/Order'
import OrderFieldTitle from '@pwa-onilab/ui/components/modules/Order/elements/OrderFieldTitle/OrderFieldTitle'
import { useTranslation } from 'react-i18next'

import styles from './OrderComment.module.scss'
const OrderComment = (
    {
        orderNumber,
    }: Pick<IOrderComponent, 'orderNumber'>) => {
    const { t } = useTranslation()

    const { data: { orderComment } = { }, loading } = useCustomerOrder(orderNumber)

    const comment = orderComment || t('order.comment.noComment')

    return (
        <div className={styles.wrapper}>
            <OrderFieldTitle text={t('checkout.order.comment.title')} />
            {
                loading
                    ? <Placeholder className={styles.placeholder} />
                    : <pre className={!!orderComment && styles.comment}>{comment}</pre>
            }
        </div>
    )
}

export default OrderComment

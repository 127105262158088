import { useMemo, useState } from 'react'

const useModalStates = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const {
        openModal,
        closeModal,
    } = useMemo(() => {
        return {
            openModal: () => setIsModalOpen(true),
            closeModal: () => setIsModalOpen(false),
        }
    }, [])

    return {
        isModalOpen,
        openModal,
        closeModal,
    }
}

export default useModalStates

import { $auth } from '@pwa-concept/core/models'
import { useVerifyAuthOTP } from '@pwa-concept/modules/one-time-password'
import useRequestAuthOTP from '@pwa-concept/modules/one-time-password/features/auth-otp/hooks/useRequestAuthOTP'
import { useWishlistMerge } from '@pwa-concept/modules/wishlist'
import { OTP_DEFAULT_ATTEMPT_VALUE } from '@pwa-onilab/ui/constants'
import { useCustomerAssign } from '@pwa-onilab/ui/hooks/Cart'
import { useTimer } from '@pwa-onilab/ui/hooks/oneTimePassword'
import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

// TODO add global state, for cases when we change page
// TODO handle case when user after getting timer set new Email and then back to previous
const useAuthOneTimePassword = () => {
    const { startTimer, stopTimer, timerValue, timerValueRef } = useTimer()
    const [attemptsLeft, setAttemptsLeft] = useState<number>(OTP_DEFAULT_ATTEMPT_VALUE)
    const attemptsLeftRef = useRef<number>(null)

    const emailValueRef = useRef<string>('')

    const [isResendCode, setIsResendCode] = useState(false)

    const customerAssign = useCustomerAssign()
    const mergeWishlist = useWishlistMerge()

    const { t } = useTranslation()

    const requestOneTimePasswordAuthMutation = useRequestAuthOTP()
    const verifyAuthOTP = useVerifyAuthOTP()

    const requestOneTimePasswordAuth = useCallback(async (email, isForceResend = false) => {
        const emailValue = emailValueRef.current

        if (!!emailValue.length && emailValue !== email) {
            stopTimer()
            setIsResendCode(false)
        } else if (timerValueRef.current || (!isForceResend && attemptsLeftRef.current !== null)) {
            return null
        }
        setAttemptsLeft(OTP_DEFAULT_ATTEMPT_VALUE)
        attemptsLeftRef.current = OTP_DEFAULT_ATTEMPT_VALUE

        return await requestOneTimePasswordAuthMutation(email)
    }, [])

    const handleOpenOneTimePassword = useCallback(async (email, callback = null) => {
        try {
            await requestOneTimePasswordAuth(email, false)
            callback?.()
            emailValueRef.current = email
        } catch (e) {
            toast.error(e.message)
        }
    }, [])

    const resendCode = useCallback(() => {
        if (timerValueRef.current) {
            return
        }
        startTimer()
        setIsResendCode(true)

        requestOneTimePasswordAuth(emailValueRef.current, true)
            .catch((error) => toast.error(error.message))
    }, [])

    const verifyOneTimePasswordAuthMutation = useCallback(async (email: string, code: string) => {
        try {
            const { data = {} } = await verifyAuthOTP({ email, code })
            const { attemptsLeft: newAttemptsLeft, token, valid } = data
            if (valid) {
                $auth.setToken(token)
                try {
                    // TODO create hook with all merges logic?
                    await customerAssign()
                    await mergeWishlist()
                } catch (error) {
                    toast.error(error.message)
                }
                toast.success(t('loginForm.success'))
            } else {
                setAttemptsLeft(newAttemptsLeft)
                attemptsLeftRef.current = newAttemptsLeft

                if (!newAttemptsLeft && !timerValueRef.current) {
                    resendCode()
                }
            }
            return data
        } catch (error) {
            toast.error(error.message)
        }
    }, [])

    const checkOneTimePassword = useCallback(async ({ code, email }) => {
        return await verifyOneTimePasswordAuthMutation(email, code)
    }, [])

    return {
        handleOpenOneTimePassword,
        checkOneTimePassword,
        resendCode,
        timerValue,
        attemptsLeft,
        isResendCode,
    }
}

export default useAuthOneTimePassword

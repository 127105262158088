import { SimpleProductCardSkeleton } from '@pwa-onilab/ui/components/modules/Product'
import SimpleProductCardWrapper from 'components/overrides/modules/Product/SimpleProductCard/SimpleProductCardWrapper'
import { IProductListContent } from 'components/widgets/ProductList/components/IProductListContent'

import styles from './ProductListColumn.module.scss'

const ProductListColumn = (
    {
        products,
        isLoading,
    }: Pick<IProductListContent, 'products' | 'isLoading'>,
) => (
    <div className={styles.list}>
        {
            products.map((product) => (
                <div className={styles.item} key={product.sku}>
                    {
                        isLoading
                            ? <SimpleProductCardSkeleton className={styles.placeholder} />
                            : <SimpleProductCardWrapper
                                    currentViewVariant="column"
                                    product={product}
                                    itemsListName="ProductListColumn widget"
                              />
                    }
                </div>
            ))
        }
    </div>
)

export default ProductListColumn

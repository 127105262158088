import { module } from '@pwa-concept/core'
import { PaginationModule } from '@pwa-concept/modules/pagination'
import { BlogAuthorModule } from 'app/modules/blog-author'

import graphqlFragments from './graphql/fragments'
import graphqlSchemas from './graphql/schemas'
import stores from './graphql/stores'

const StatesModule = module(() => ({
    modules: [
        PaginationModule,
        BlogAuthorModule,
    ],
    graphqlSchemas,
    graphqlResolvers: stores?.resolvers,
    graphqlStoreFragments: stores?.fragments,
    graphqlStorePossibleTypes: stores?.possibleTypes,
    graphqlFragments,

}))

export default StatesModule

import { makeVar } from '@apollo/client'

const DEFAULT_QUOTE = {
    shippingAddress: null,
    billingAddress: null,
    shippingMethod: null,
    paymentMethod: null,
}

const quoteModel = makeVar(DEFAULT_QUOTE)

export const $quote = {
    get getQuote() {
        return quoteModel()
    },
    setBillingAddress(address) {
        quoteModel({
            ...quoteModel(),
            billingAddress: address,
        })
    },
    setShippingAddress(address) {
        quoteModel({
            ...quoteModel(),
            shippingAddress: address,
        })
    },
    setShippingMethod(carrierCode, methodCode) {
        quoteModel({
            ...quoteModel(),
            shippingMethod: {
                carrierCode,
                methodCode,
            },
        })
    },
    setPaymentMethod(code) {
        quoteModel({
            ...quoteModel(),
            paymentMethod: {
                code,
            },
        })
    },
    reset () {
        quoteModel(DEFAULT_QUOTE)
    },
}

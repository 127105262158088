import { useCustomerAddresses } from '@pwa-concept/modules/customer-addresses'
import { useMemo } from 'react'

const useAccountDefaultAddresses = () => {
    const { addresses } = useCustomerAddresses()

    return useMemo(() => {
        const defaultAddresses = addresses?.filter(({ defaultBilling, defaultShipping }) => defaultBilling || defaultShipping) || []
        const isAdditionalAddressesExist = addresses?.length !== defaultAddresses.length
        const isShowMessageAboutEmptyAddresses = !defaultAddresses.length && isAdditionalAddressesExist

        return {
            addressList: defaultAddresses,
            isShowMessageAboutEmptyAddresses,
        }
    }, [addresses])
}

export default useAccountDefaultAddresses

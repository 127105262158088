import { Cms } from '@pwa-concept/modules/graphql'
import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { DefaultHelmet, IDefaultHelmet } from '@pwa-onilab/ui/components/elements/DefaultHelmet'
import { SchemaHelmetWebPage } from 'elements/SchemaHelmet/SchemaHelmetWebPage'
const CmsPageHelmet = ({ cms }: {cms: Cms}) => {
    const { data: storeConfig } = useStoreConfig()

    const webSiteTitleName = storeConfig?.defaultTitle

    const meta: IDefaultHelmet['meta'] = {
        title: cms?.metaTitle || '',
        keywords: cms?.metaKeywords || '',
        description: cms?.metaDescription || '',
        robots: cms?.metaRobots || '',
        ogType: 'article',
    }

    if (storeConfig?.ogArticlePublisher) {
        meta.customProperties = [
            {
                name: 'article:publisher',
                value: storeConfig.ogArticlePublisher,
            },
        ]
    }

    return (
        <>
            <DefaultHelmet
                meta={meta}
                defaultTitle={webSiteTitleName}
            />
            <SchemaHelmetWebPage meta={meta} />
        </>
    )
}

export default CmsPageHelmet

import * as Types from '../../../../graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type SetOrderCommentOnCartMutationVariables = Types.Exact<{
  comment?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SetOrderCommentOnCartMutation = { __typename?: 'Mutation', setOrderCommentOnCart?: boolean | null };


export const SetOrderCommentOnCartDocument = gql`
    mutation setOrderCommentOnCart($comment: String) {
  setOrderCommentOnCart(comment: $comment) @client
}
    `;
export type SetOrderCommentOnCartMutationFn = Apollo.MutationFunction<SetOrderCommentOnCartMutation, SetOrderCommentOnCartMutationVariables>;

/**
 * __useSetOrderCommentOnCartMutation__
 *
 * To run a mutation, you first call `useSetOrderCommentOnCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrderCommentOnCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrderCommentOnCartMutation, { data, loading, error }] = useSetOrderCommentOnCartMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useSetOrderCommentOnCartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetOrderCommentOnCartMutation, SetOrderCommentOnCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SetOrderCommentOnCartMutation, SetOrderCommentOnCartMutationVariables>(SetOrderCommentOnCartDocument, options);
      }
export type SetOrderCommentOnCartMutationHookResult = ReturnType<typeof useSetOrderCommentOnCartMutation>;
export type SetOrderCommentOnCartMutationResult = Apollo.MutationResult<SetOrderCommentOnCartMutation>;
export type SetOrderCommentOnCartMutationOptions = Apollo.BaseMutationOptions<SetOrderCommentOnCartMutation, SetOrderCommentOnCartMutationVariables>;
import { CenteredModal } from '@pwa-onilab/ui/components/elements/Modals'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import Title from '@pwa-onilab/ui/components/UI/Title'
import { useModalStates } from '@pwa-onilab/ui/hooks'
import { StatePageList } from 'elements/BlogStatePages/elements'
import { IStatePageNavList } from 'modules/StatePage/components'
import { useTranslation } from 'react-i18next'

import styles from './StatePageMobileTable.module.scss'

const StatePageMobileTable = (
    {
        headerRefs,
        headers,
        activeHeader,
    }: IStatePageNavList) => {
    const { isModalOpen, openModal, closeModal } = useModalStates()
    const { t } = useTranslation()

    return (
        <>
            <ButtonInstance className={styles.triggerTable} onClick={openModal}>
                <Icon name="chevron" className={styles.icon} />
                {t('global.tableOfContent')}
            </ButtonInstance>
            <CenteredModal
                isOpen={isModalOpen}
                onDismiss={closeModal}
                blocking={false}
                header={<Title type="h3" text={t('global.tableOfContent')} className={styles.modalTitle} />}
            >
                <StatePageList
                    headers={headers}
                    headerRefs={headerRefs}
                    activeHeader={activeHeader}
                    onClick={closeModal}
                />
            </CenteredModal>
        </>
    )
}

export default StatePageMobileTable

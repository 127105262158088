import { useWishlistShare } from '@pwa-concept/modules/wishlist'
import { InputMultipleEmail } from '@pwa-onilab/ui/components/elements/Inputs/InputMultipleEmail'
import { WishlistNotificationShare } from '@pwa-onilab/ui/components/modules/Wishlist'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import { Textarea } from '@pwa-onilab/ui/components/UI/Textarea'
import { MAX_LENGTH_TEXTAREA } from '@pwa-onilab/ui/constants/components/MaxLengthTextarea'
import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import styles from './WishlistShareViaEmailForm.module.scss'

const WishlistShareViaEmailForm = memo(() => {
    const shareWishlist = useWishlistShare()

    const { t } = useTranslation()

    const [emails, setEmails] = useState([])
    const [text, setText] = useState('')
    const [symbolsCount, setSymbolsCount] = useState(MAX_LENGTH_TEXTAREA)

    const onChangeInputHandler = useCallback((e) => {
        if (e?.target?.value?.length > MAX_LENGTH_TEXTAREA) return

        setSymbolsCount(MAX_LENGTH_TEXTAREA - e?.target?.value?.length)

        setText(e?.target?.value)
    }, [])

    const buttonDisabled = !emails.length || symbolsCount === MAX_LENGTH_TEXTAREA || !text.length

    const onSubmit = useCallback(async () => {
        if (!emails?.length) return null

        try {
            await shareWishlist({ emails, message: text })
            toast.success(WishlistNotificationShare)

            setEmails([])
            setText('')
        } catch (error) {
            toast.error(error.message)
        }
    }, [emails, text])

    return (
        <>
            <div className={styles.textareaWrapper}>
                <InputMultipleEmail
                    currentInputValue={emails}
                    label={t('global.placeholder.emailAddress')}
                    onChange={setEmails}
                />
                <Textarea
                    value={text}
                    placeholder={t('global.message')}
                    maxLength={MAX_LENGTH_TEXTAREA}
                    onChange={onChangeInputHandler}
                    className={styles.textarea}
                />
                <div className={styles.textareaNotification}>
                    {t('wishlist.textarea.charactersRemaining', { numValue: symbolsCount })}
                </div>
            </div>
            <Button
                isPrimary
                isBold
                isFullWidth
                disabled={buttonDisabled}
                onClick={onSubmit}
            >
                {t('wishlist.shareWishlist')}
            </Button>
        </>
    )
})

export default WishlistShareViaEmailForm

import { createContext, PropsWithChildren, useContext } from 'react'

const MenuNodeLinkContext = createContext({})
const useMenuNodeLinkContext = () => useContext(MenuNodeLinkContext)

interface MenuNodeLinkProviderProps {
    value?: object
}

const MenuNodeLinkProvider = (
    {
        value,
        children,
    }: PropsWithChildren<MenuNodeLinkProviderProps>,
) => (
    <MenuNodeLinkContext.Provider value={value}>
        {children}
    </MenuNodeLinkContext.Provider>
)

export { MenuNodeLinkProvider, useMenuNodeLinkContext }

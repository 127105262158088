import { formSaveBeforeHideModal } from '@pwa-onilab/ui/models'

export const linkOnClickHandler = (event, scroll, callback) => {
    const onSaveRejectHandler = () => {
        event?.target.click()
    }

    const isSaveFormModalOpen = formSaveBeforeHideModal.tryOpenModal(onSaveRejectHandler)
    if (isSaveFormModalOpen) {
        event.preventDefault()
        return
    }

    if (scroll) {
        window.scrollTo({ top: 0 })
    }

    callback?.(event)
}

import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { useSearchModal } from '@pwa-onilab/ui/hooks'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { useTranslation } from 'react-i18next'

import styles from './SearchTriggerMobile.module.scss'

const SearchTriggerMobile = (
    {
        className,
    }: IClassName,
) => {
    const { openSearchModal } = useSearchModal()
    const { t } = useTranslation()

    return (
        <ButtonInstance
            onClick={openSearchModal}
            className={className}
            aria-label={t('global.search.open')}
        >
            <Icon
                name="magnifyingGlass"
                className={styles.icon}
            />
        </ButtonInstance>
    )
}

export default SearchTriggerMobile

import { IModalContentYesNoButtons } from '@pwa-onilab/ui/components/elements/ModalContentElements'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import { useTranslation } from 'react-i18next'

import styles from './YesNoButtons.module.scss'

const YesNoButtons = ({ onNoButtonClick, onYesButtonClick }: IModalContentYesNoButtons) => {
    const { t } = useTranslation()
    return (
        <div className={styles.buttonsWrapper}>
            <Button
                isBlackTransparent
                onClick={onYesButtonClick}
                className={styles.button}
            >
                {t('global.yes')}
            </Button>
            <Button
                isBlack
                onClick={onNoButtonClick}
                className={styles.button}
            >
                {t('global.no')}
            </Button>
        </div>
    )
}

export default YesNoButtons

import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const storeConfigWorldPage: QueryResolvers['storeConfigWorldPage'] = async (_, inputObj) => {
    const { data: { storeConfig } = {} } = await (
        api.graphql(
            gql`
                query {
                    storeConfig {
                        world_page_title
                        world_meta_title
                        world_meta_keywords
                        world_meta_description
                        world_meta_robots
                    }
                }
            `,
        ).query(undefined, {
            errorPolicy: 'all',
        })
    )

    if (!storeConfig) {
        return null
    }

    return {
        title: storeConfig.world_page_title || null,
        meta: {
            title: storeConfig.world_meta_title || '',
            keywords: storeConfig.world_meta_keywords || '',
            description: storeConfig.world_meta_description || '',
            robots: storeConfig.world_meta_robots || '',
            __typename: 'StoreConfigDefaultPageMeta',
        },
        __typename: 'StoreConfigWorldPage',
    }
}

export default storeConfigWorldPage

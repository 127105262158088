import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useScrollLock } from '@pwa-onilab/ui/hooks'
import { PropsWithChildren, ReactElement, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import styles from './DefaultModalContent.module.scss'

const VARIANTS = {
    default: styles.default,
    absolute: styles.variantAbsolute,
    productImages: styles.variantProductImagesVariant,
    centered: styles.variantCentered,
    // TODO use className instead of wishlist variant
    wishlist: styles.variantWishlist,
    footerMenu: styles.variantFooterMenu,
    topFullWidth: styles.variantTopFullWidth,
    fullWidth: styles.variantFullWidth,
}

export interface DefaultModalContentProps {
    isOpen?: boolean
    isUseJumpOnTop?: boolean
    isUseBorderRadius?: boolean
    isUseDefaultPadding?: boolean
    isMobileScrollableMain?: boolean
    isShowCloseButton?: boolean

    onDismiss?: (e?: any) => void
    wrapperClassName?: string
    titleClassName?: string
    headerClassName?: string
    footerClassName?: string
    mainClassName?: string
    className?: string
    closeButtonClass?: string
    variant?: keyof typeof VARIANTS
    header?: ReactElement | string
    footer?: ReactElement | string
    title?: string
}

const DefaultModalContent = (
    {
        isOpen,
        isUseJumpOnTop,
        isUseBorderRadius,
        isUseDefaultPadding,
        isMobileScrollableMain,
        isShowCloseButton,
        isOverAll,

        onDismiss,
        wrapperClassName,
        className,
        titleClassName,
        headerClassName,
        footerClassName,
        mainClassName,
        closeButtonClass,
        children,
        variant = 'default',
        header,
        footer,
        title,
    }: PropsWithChildren<DefaultModalContentProps>,
) => {
    const { t } = useTranslation()
    const { pathname } = useLocation()

    const prevLocation = useRef<string>(pathname)
    const wrapperRef = useRef<HTMLDivElement>()
    const scrollValue = useRef<number>(0)

    const { scrollableProps } = useScrollLock(isOpen)

    const onDismisHandler = useCallback(() => {
        onDismiss?.()
    }, [onDismiss])

    // Close modal by Change route
    useEffect(() => {
        if (prevLocation.current !== pathname) {
            onDismisHandler()
        }
    }, [onDismisHandler, pathname])

    // Close modal by Escape keyboard
    useEffect(() => {
        const onKeyup = (e) => {
            if (['escape', 'esc'].includes(e.key?.toLowerCase?.())) {
                onDismisHandler()
            }
        }

        window.addEventListener('keyup', onKeyup)

        return () => {
            window.removeEventListener('keyup', onKeyup)
        }
    }, [onDismisHandler])

    if (!isOpen) {
        // FIXME now it unnesessory check
        return null
    }

    const onMouseDownHandler = (ev) => {
        if (ev?.target?.contains?.(wrapperRef.current)) {
            onDismisHandler()
            if (isUseJumpOnTop) {
                window.scrollTo(0, scrollValue.current)
            }
        }
    }

    return (
        <>
            <div
                onMouseDown={onMouseDownHandler}
                ref={wrapperRef}
                className={classNames(
                    styles.wrapper,
                    wrapperClassName,
                    isMobileScrollableMain && styles.scrollableMainOnMobile,
                    variant && VARIANTS?.[variant],
                    isOverAll && styles.overAll,
                )}
            >
                <div className={classNames(
                    styles.body,
                    className,
                    isUseBorderRadius && styles.borderRadius,
                    isUseDefaultPadding && styles.defaultPadding,
                )}
                >
                    {
                        isShowCloseButton && (
                            <button
                                onMouseDown={onDismiss}
                                type="submit"
                                className={classNames(styles.close, closeButtonClass)}
                                aria-label={t('global.close')}
                            >
                                <Icon name="cross" />
                            </button>
                        )
                    }
                    {header && (
                        <header className={classNames(styles.header, headerClassName)}>
                            {header}
                        </header>
                    )}

                    <main {...scrollableProps} className={classNames(styles.main, mainClassName)}>
                        {title && (
                            <div className={classNames(styles.title, titleClassName)}>{title}</div>
                        )}
                        {children}
                    </main>

                    {footer && (
                        <footer className={classNames(styles.footer, footerClassName)}>
                            {footer}
                        </footer>
                    )}
                </div>
            </div>
        </>
    )
}

export { DefaultModalContent }

import { CartItemTypes, Resolvers } from '@pwa-concept/modules/graphql'

const CartItems: Resolvers['CartItems'] = {
    id: (_, __, { context }) => {
        return context?.uid || null
    },
    quantity: (_, __, { context }) => context?.quantity || null,
    product: (_, __, { context }) => {
        return {
            __context: context?.product,
            __typename: 'Product',
        }
    },
    configuredVariant: (_, __, { context }) => {
        if (context?.__typename !== CartItemTypes.ConfigurableCartItem) {
            return null
        }
        return {
            __context: context.configuredVariant,
            __typename: 'Product',
        }
    },
    prices: (_, __, { context: __context }) => ({
        __context,
        __typename: 'CartItemPrices',
    }),
    customizableOptions: (_, __, { context }) => {
        const data = (context?.customizableOptionsRequired || context?.customizableOptionsNotRequired)

        return (data?.length > 0 ? data.map((__context) => ({
            __context,
            __typename: 'CartItemCustomizableOptions',
        })) : null)
    },
    configurableOptions: (_, __, { context }) => {
        if (context?.__typename !== CartItemTypes.ConfigurableCartItem) {
            return null
        }
        return context?.configurableOptions?.map((configurableOption) => {
            return {
                optionLabel: configurableOption?.option_label,
                valueLabel: configurableOption?.value_label,
                optionValueUid: configurableOption?.configurable_product_option_value_uid,
                optionUid: configurableOption?.configurable_product_option_uid,
                __typename: 'CartItemSelectedConfigurableOptions',
            }
        }) || null
    },
}

export default CartItems

import { gql } from '@apollo/client'
import api from '@pwa-concept/core/api'
import { $cart } from '@pwa-concept/core/models'
import { MutationResolvers } from '@pwa-concept/modules/graphql'
import { extractRequestAdditionalFields } from '@pwa-concept/modules/helpers'

const cartSetBillingAddress: MutationResolvers['cartSetBillingAddress'] = async (_, { input }) => {
    const { billingAddress: { address, customerAddressId, useForShipping, sameAsShipping } } = input

    const { data: { setBillingAddressOnCart: { cart: __context = null } = {} } = {} } = await (
        api.graphql(gql`
            mutation(
                $input: SetBillingAddressOnCartInput
            ) {
                setBillingAddressOnCart(input: $input) {
                    cart {
                        ... Cart
                    }
                }
            }
        `).mutation({
            input: {
                cart_id: $cart.id,
                billing_address: {
                    address: customerAddressId ? null : {
                        city: address?.city,
                        company: address?.company,
                        country_code: address?.countryCode,
                        firstname: address?.firstName,
                        lastname: address?.lastName,
                        postcode: address?.postCode,
                        region: address?.region,
                        region_id: address?.regionId,
                        // save_in_address_book: true by default
                        street: address?.street,
                        telephone: address?.telephone,
                        ...extractRequestAdditionalFields(address?.additionalFields),
                    },
                    customer_address_id: customerAddressId,
                    same_as_shipping: sameAsShipping,
                    use_for_shipping: useForShipping,
                },
            },
        })
    )

    if (!__context) return null

    return {
        __context,
        __typename: 'Cart',
    }
}

export default cartSetBillingAddress

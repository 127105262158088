import { $currencySign } from '@pwa-concept/core/models'
import { tCurrencyCode } from '@pwa-concept/modules/currency/helpers'
import { StoreConfigResolvers } from '@pwa-concept/modules/graphql'
import {
    MAX_ADDRESS_LINE_LENGTH,
    MAX_NANE_LENGTH,
    PASSWORD_MIN_LENGTH,
} from '@pwa-concept/modules/store-config/constants'
import Cookies from 'js-cookie'

const StoreConfig: StoreConfigResolvers = {
    id: (_, __, { context }) => context?.id || null,
    maxAddressLineLength: (_, __, { context }) => MAX_ADDRESS_LINE_LENGTH,
    maxNameLength: (_, __, { context }) => MAX_NANE_LENGTH,

    passwordMinLength: (_, __, { context }) => +context?.minimum_password_length || PASSWORD_MIN_LENGTH,
    passwordMinStrength: (_, __, { context }) => +context?.required_character_classes_number || 1,

    productReviewsEnabled: (_, __, { context }) => !!(context?.product_reviews_enabled === '1'),
    baseCurrencyCode: (_, __, { context }) => {
        if (Cookies.get('current_store') && Cookies.get('current_store') == 'ca') {
            context.base_currency_code = 'CAD'
        }
        const currentCode: tCurrencyCode = context?.base_currency_code || 'USD'

        $currencySign.code = currentCode

        return currentCode
    },

    termsOfDelivery: (_, __, { context }) => '',
    productReturns: (_, __, { context }) => '',

    logoHeight: (_, __, { context }) => context?.logo_height || null,
    logoWidth: (_, __, { context }) => context?.logo_width || null,

    defaultTitle: (_, __, { context }) => context?.default_title || null,

    locale: (_, __, { context }) => context?.locale || null,
}

export default StoreConfig

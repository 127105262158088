import { Filter, FilterType } from '@pwa-concept/modules/graphql'
import {
    getPriceTag,
    IFilterTag,
    IParsedFiltersFromURL,
    ONLY_IN_STOCK,
    ONLY_IN_STOCK_TAG,
} from '@pwa-onilab/ui/components/modules/Filters'
import { FILTER_NODE_ACTUAL_PRICE_PER_SEED } from 'modules/Filters'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// TODO field name seems to be useless
const useFiltersTags = (filters: Filter[], currentValues: IParsedFiltersFromURL) => {
    const { t } = useTranslation()

    const [tags, setTags] = useState<IFilterTag[]>([])

    useEffect(() => {
        if (!currentValues?.filters) {
            return
        }

        const array = Object.entries<string[]>(currentValues?.filters || {})

        setTags(
            array.reduce<IFilterTag[]>((acc, [key, values]) => {
                if (!values) {
                    return acc
                }

                switch (key) {
                    case ONLY_IN_STOCK: {
                        acc.push(ONLY_IN_STOCK_TAG)
                        return acc
                    }
                    case FILTER_NODE_ACTUAL_PRICE_PER_SEED: {
                        const filter = filters?.find(({ key: innerKey }) => innerKey === key)
                        const priceFilter = getPriceTag(filter, values)
                        if (priceFilter) {
                            acc.push(priceFilter)
                        }
                        return acc
                    }
                    default: {
                        const filter = filters?.find(({ key: innerKey }) => innerKey === key)

                        if (filter?.type === FilterType.Boolean) {
                            acc.push({
                                key: filter.key,
                                value: filter.name,
                                name: t('global.yes'),
                            })

                            return acc
                        }

                        values.forEach((value) => {
                            const item = filter?.items.find(({ value: innerValue }) => innerValue === value)
                            acc.push({
                                key,
                                name: item?.name || value,
                                value,
                            })
                        })
                        return acc
                    }
                }
            }, []),
        )
    }, [currentValues, filters])

    return tags
}

export default useFiltersTags

import { StoreConfig } from '@pwa-concept/modules/graphql'
import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { SchemaHelmet } from 'elements/SchemaHelmet'
import { SCHEMA_HELMET_CONTEXT_VALUE } from 'elements/SchemaHelmet/SchemaHelmetConstants'

const getWebSiteSchema = (storeConfig: StoreConfig) => {
    const url = `${window.location.origin}/`
    const website = storeConfig.structuredData?.website || {}

    return {
        '@context': SCHEMA_HELMET_CONTEXT_VALUE,
        '@type': 'WebSite',
        '@id': `${url}#website`,
        url,
        name: website.name,
        description: website.description,
        publisher: {
            '@id': `${url}#organization`,
        },
        potentialAction: [
            {
                '@type': 'SearchAction',
                target: {
                    '@type': 'EntryPoint',
                    urlTemplate: `${url}search?q={search_term_string}`,
                },
                'query-input': 'required name=search_term_string',
            },
        ],
        inLanguage: storeConfig.locale,
    }
}

const SchemaHelmetWebSite = () => {
    const { data: storeConfig } = useStoreConfig()

    if (!storeConfig) {
        return null
    }

    return (
        <SchemaHelmet jsonConfig={getWebSiteSchema(storeConfig)} />
    )
}

export default SchemaHelmetWebSite

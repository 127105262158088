import { HeaderTopMenu } from '@pwa-onilab/ui/components/modules/HeaderTop'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { HEADER_TOP_ID } from '@pwa-onilab/ui/constants/app'
import { HeaderQuickAccess, HeaderTopInfo, HeaderStoreSwitcher } from 'modules/HeaderTop'

import styles from './HeaderTop.module.scss'
import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'

const HeaderTop = () => {
    return (
        <div id={HEADER_TOP_ID} className={styles.header}>
            <Container className={styles.container}>
                <MediaBreakpointUp name="lg">
                    <HeaderTopInfo className={styles.info} />
                    <HeaderQuickAccess className={styles.linkArrow} />
                </MediaBreakpointUp>
                <HeaderStoreSwitcher />
                <MediaBreakpointUp name="lg">
                    <HeaderTopMenu className={styles.menu} />
                </MediaBreakpointUp>
            </Container>
        </div>
    )
}

export default HeaderTop

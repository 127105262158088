import { InputField } from '@pwa-onilab/ui/components/elements/Fields'
import {
    ADDRESS_FORM_FIELDS,
    ADDRESS_FORM_INPUT_SET_VALUE_OPTIONS,
    usePostalCodeFieldRules,
} from '@pwa-onilab/ui/components/modules/AddressForm'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const AddressFormPostalCodeField = () => {
    const { t } = useTranslation()
    const { register, setValue, formState: { errors } } = useFormContext()
    const rules = usePostalCodeFieldRules()

    return (
        <InputField
            placeholder={t('addressForm.postalCode.text')}
            field={ADDRESS_FORM_FIELDS.POST_CODE}
            register={register}
            setValue={setValue}
            setValueOptions={ADDRESS_FORM_INPUT_SET_VALUE_OPTIONS}
            rules={rules}
            errors={errors}
        />
    )
}

export default AddressFormPostalCodeField

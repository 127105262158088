import { ProductPrice as IProductPrice } from '@pwa-concept/modules/graphql'
import InitialPrice
    from '@pwa-onilab/ui/components/modules/Product/elements/ProductPrice/elements/InitialPrice/InitialPrice'
import PricePerItem from '@pwa-onilab/ui/components/modules/Product/elements/ProductPrice/elements/PricePerItem'
import PriceWithDiscount
    from '@pwa-onilab/ui/components/modules/Product/elements/ProductPrice/elements/PriceWithDiscount'
import { classNames } from '@pwa-onilab/ui/helpers'
import { calculateProductPrice } from '@pwa-onilab/ui/helpers/calculateProductPrice'
import { ProductPricePerSeed } from 'components/UI/Price'
import { useMemo } from 'react'

import styles from './ProductPrice.module.scss'

export interface PriceProps {
    price?: IProductPrice
    productQuantity?: number
    isSearch?: boolean
    isProductPage?: boolean
    isSuccesAddToCartModal?: boolean
    isCart?: boolean
    isSimilarProductModal?: boolean
    className?: string
    initialPriceClassName?: string
    priceWithDiscountClassName?: string
    pricePerItemClassName?: string
    isRowReverse?: boolean
    actualPricePerSeed?: number
    originalPricePerSeed?: number
    isProductPricePerSeed?: boolean
    withFromLabel?: boolean
}

export interface PricePartProps extends PriceProps {
    value: number
}

// TODO for cart items add component which works with CartItemPrices (it include already calculated price)
const ProductPrice = (
    {
        price,
        productQuantity = 1,
        isSearch,
        isProductPage,
        isSuccesAddToCartModal,
        isCart,
        isRowReverse,
        className,
        initialPriceClassName,
        priceWithDiscountClassName,
        pricePerItemClassName,
        isProductPricePerSeed,
        actualPricePerSeed,
        originalPricePerSeed,
        withFromLabel,
    }: PriceProps,
) => {
    if (isProductPricePerSeed) {
        return (
            <ProductPricePerSeed
                className={className}
                actualPricePerSeed={actualPricePerSeed}
                originalPricePerSeed={originalPricePerSeed}
                withFromLabel={withFromLabel}
            />
        )
    }

    const isPriceWithDiscount = !!(price?.current.value < price?.initial.value)
    const isShowPerItemLabel = !isSuccesAddToCartModal && productQuantity > 1

    const wrapperClasses = [
        styles.priceWrapper,
        isSearch && styles.searchWrapper,
        isProductPage && styles.productPageWrapper,
        isSuccesAddToCartModal && styles.successModal,
        isCart && styles.cart,
        isRowReverse && styles.wrapperRowReverse,
        className,
    ]

    const priceValues = useMemo(() => {
        return calculateProductPrice(price, productQuantity)
    }, [price, productQuantity])

    return (
        <div className={classNames(...wrapperClasses)}>
            {
                    isPriceWithDiscount && (
                        <PriceWithDiscount
                            value={priceValues.current}
                            productQuantity={productQuantity}
                            isSearch={isSearch}
                            isProductPage={isProductPage}
                            isSuccesAddToCartModal={isSuccesAddToCartModal}
                            isCart={isCart}
                            className={priceWithDiscountClassName}
                        />
                    )
                }
            <InitialPrice
                value={priceValues.initial}
                productQuantity={productQuantity}
                isPriceWithDiscount={isPriceWithDiscount}
                isSearch={isSearch}
                isProductPage={isProductPage}
                isSuccesAddToCartModal={isSuccesAddToCartModal}
                isCart={isCart}
                className={initialPriceClassName}
            />
            {isShowPerItemLabel && <PricePerItem value={priceValues.perItem} isCart={isCart} className={pricePerItemClassName} />}
        </div>
    )
}

export default ProductPrice

import { useCartShippingMethods } from '@pwa-concept/modules/cart'
import { useMemo } from 'react'

const useCartAvailableOnlyShippingMethods = () => {
    const {
        data: {
            availableShippingMethods: potentialAvailableShippingMethods,
        },
        loading,
    } = useCartShippingMethods()

    const availableShippingMethods = useMemo(() => {
        return potentialAvailableShippingMethods?.filter((method) => method.available) || null
    }, [potentialAvailableShippingMethods])

    return {
        data: availableShippingMethods,
        loading,
    }
}

export default useCartAvailableOnlyShippingMethods

import { Skeleton } from '@pwa-onilab/ui/components/elements/Skeleton'
import { classNames } from '@pwa-onilab/ui/helpers'

import css from './CategoryProductCard.module.scss'

interface ICategoryProductCardSkeletonProps {
    className?: string
    skeletonCount?: number
}

const CategoryProductCardSkeleton = (
    {
        className,
        skeletonCount,
    }: ICategoryProductCardSkeletonProps,
) => (
    <>
        {!!skeletonCount && (
            Array
                .from({ length: skeletonCount })
                .map((_, idx) => (
                    <Skeleton key={idx} className={classNames(className, css.skeleton)} />
                ))
        )}
    </>
)

export { CategoryProductCardSkeleton }

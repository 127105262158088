import { Product } from '@pwa-concept/modules/graphql'
import { CartRecommendedProduct } from '@pwa-onilab/ui/components/modules/Cart/elements/CartProducts/CartRecommendedProduct'
import styles from '@pwa-onilab/ui/components/modules/Cart/elements/CartRecommended/CartRecommendedSlider/CartRecommendedSlider.module.scss'
import Slider, { SliderNavigation } from '@pwa-onilab/ui/components/UI/Slider'
import { classNames } from '@pwa-onilab/ui/helpers'
import { SwiperSlide } from 'swiper/react'

interface ICartRecommendedSliderProps {
    className?: string
    items: Product[]
}
const CartRecommendedSlider = (
    {
        className,
        items,
    }: ICartRecommendedSliderProps,
) => (
    <Slider
        className={classNames(styles.wrapper, className)}
        mousewheel
        breakpoints={{
            360: {
                direction: 'horizontal',
                slidesPerView: 2.2,
                spaceBetween: 12,
                mousewheel: false,
            },
            992: {
                spaceBetween: 8,
                slidesPerView: 'auto',
                direction: 'vertical',
            },
        }}
    >
        {items.map((data) => (
            <SwiperSlide key={data.sku}>
                <CartRecommendedProduct product={data} />
            </SwiperSlide>
        ))}
        <SliderNavigation
            variant="vertical"
            prevClassName={styles.navigationBtn}
            nextClassName={styles.navigationBtn}
        />
    </Slider>
)

export default CartRecommendedSlider

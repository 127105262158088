import { IVisibleOnScreen } from '@pwa-onilab/ui/components/elements/VisibleOnScreen/IVisibleOnScreen'
import { createElement, PropsWithChildren, useEffect, useRef } from 'react'

const VisibleOnScreen = (
    {
        onScreen = null,
        children = null,
        className = null,
        as = 'div',
        componentProps,
        disabled = false,
        threshold = null,
        data = null,
        refProp,
    }: Partial<PropsWithChildren<IVisibleOnScreen>>,
) => {
    // FIXME useImperativeHandle + forward ref
    const ref = refProp || useRef()

    useEffect(() => {
        const element = ref.current

        if (!disabled) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(({ isIntersecting }) => {
                    if (isIntersecting) {
                        void onScreen?.(data)
                    }
                })
            }, {
                root: null,
                rootMargin: '0px',
                ...(threshold ? { threshold } : {}),
            })

            if (element) {
                observer.observe(element)
            }

            return () => {
                if (element) {
                    observer.unobserve(element)
                }
            }
        }

        return () => null
    }, [data, onScreen, ref, disabled, threshold])

    if (disabled) return null

    return (
        createElement(
            as,
            {
                ref,
                ...componentProps,
                className: className || componentProps?.className,
            },
            children,
        )
    )
}

export default VisibleOnScreen

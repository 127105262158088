import { tAnyAddress, useAddressDescription } from '@pwa-onilab/ui/hooks'

import styles from './AddressDescriptionLine.module.scss'

interface IAddressDescriptionLine {
    address: tAnyAddress
}

const AddressDescriptionLine = ({ address }: IAddressDescriptionLine) => {
    const addressDescription = useAddressDescription(address)
    if (!addressDescription) {
        return null
    }

    return (
        <p className={styles.wrapper}>
            <b>{addressDescription.nameLine}</b>
            &nbsp;{addressDescription.addressLine}
        </p>
    )
}

export default AddressDescriptionLine

import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { Image } from '@pwa-onilab/ui/components/UI/Image'
import { classNames } from '@pwa-onilab/ui/helpers'
import { addSlashToEndUrl } from 'helpers'

import styles from './BenefitsCard.module.scss'

interface IBenefitsCardProps {
    link: string
    iconImage: string
    title: string
    shortDescription: string
    className: string
}

const BenefitsCard = (
    {
        link,
        iconImage,
        title,
        shortDescription,
        className,
    }: Partial<IBenefitsCardProps>,
) => {
    if (!link) return null

    return (
        <Anchor
            to={addSlashToEndUrl(link)}
            className={classNames(styles.card, className)}
        >
            {
                iconImage && (
                    <div className={styles.imageWrapper}>
                        <Image
                            src={iconImage}
                            className={styles.image}
                        />
                    </div>
                )
            }
            <div className={styles.content}>
                {
                    title && (
                        <p className={styles.title}>
                            {title}
                        </p>
                    )
                }
                <MediaBreakpointUp name="lg">
                    {
                        shortDescription && (
                            <p className={styles.subtitle}>
                                {shortDescription}
                            </p>
                        )
                    }
                </MediaBreakpointUp>
            </div>
        </Anchor>
    )
}

export default BenefitsCard

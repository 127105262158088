import { Button } from '@pwa-onilab/ui/components/UI/Button'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { useCartModal } from '@pwa-onilab/ui/hooks/modals'
import { useTranslation } from 'react-i18next'

import styles from './CartEmpty.module.scss'

const CartEmpty = () => {
    const { t } = useTranslation()
    const { closeModal } = useCartModal()

    return (
        <div className={styles.wrapper}>
            <Icon name="bag" className={styles.icon} />
            <span className={styles.description}>
                {t('cart.empty')}
            </span>
            <div className={styles.buttonWrapper}>
                <Button
                    isPrimaryTransparent
                    isBold
                    onClick={closeModal}
                    className={styles.continueButton}
                >
                    {t('global.buttons.continueShopping')}
                </Button>
            </div>
        </div>
    )
}

export default CartEmpty

import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './CloseButton.module.scss'

interface ICloseButton {
    className?: string
    onClick: () => void
}

const CloseButton = (
    {
        className,
        onClick,
    }: ICloseButton,
) => {
    return (
        <ButtonInstance className={classNames(styles.wrapper, className)} onClick={onClick}>
            <Icon name="cross" />
        </ButtonInstance>
    )
}

export default CloseButton

import { AccountSubpageEmpty } from '@pwa-onilab/ui/components/modules/AccountSubpages'
import { HOME_ROUTE } from '@pwa-onilab/ui/constants/navigation'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const AccountOrderHistoryEmpty = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const goToCart = () => navigate(HOME_ROUTE)

    return (
        <AccountSubpageEmpty
            iconName="noOrders"
            text={t('AccountPage.orders.empty.title')}
            buttonText={t('global.buttons.continueShopping')}
            onClick={goToCart}
        />
    )
}

export default AccountOrderHistoryEmpty

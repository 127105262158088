import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { classNames } from '@pwa-onilab/ui/helpers'
import { addSlashToEndUrl } from 'helpers'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './WidgetHeaderViewAll.module.scss'

export interface IWidgetHeaderViewAllProps {
    to: string
    linkText: string
    className: string
}

const WidgetHeaderViewAll = (
    {
        to,
        linkText,
        className,
    }: Partial<IWidgetHeaderViewAllProps>,
) => {
    const { t } = useTranslation()

    if (!to) return null

    return (
        <Anchor
            to={addSlashToEndUrl(to)}
            className={classNames(styles.viewAllButton, className)}
        >
            {linkText || t('global.viewAll')}
        </Anchor>
    )
}

export default memo(WidgetHeaderViewAll)

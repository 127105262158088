import Modal from '@pwa-onilab/ui/components/elements/Modal'
import AuthContent from '@pwa-onilab/ui/components/modules/Auth/elements/AuthContent'
import { IOneTimePassword, useAuthModal } from '@pwa-onilab/ui/hooks'
import { PropsWithChildren } from 'react'

import styles from './AuthModal.module.scss'

const AuthModal = (
    {
        handleOpenOneTimePassword,
        checkOneTimePassword,
        resendCode,
        timerValue,
        attemptsLeft,
        isResendCode,
    }: PropsWithChildren<IOneTimePassword>) => {
    const { isModalOpen, closeModal } = useAuthModal()

    return (
        <Modal
            className={styles.authModal}
            closeButtonClass={styles.closeButton}
            isOpen={isModalOpen}
            isUseBorderRadius
            isShowCloseButton
            onDismiss={closeModal}
        >
            <AuthContent
                closeModal={closeModal}
                handleOpenOneTimePassword={handleOpenOneTimePassword}
                checkOneTimePassword={checkOneTimePassword}
                resendCode={resendCode}
                timerValue={timerValue}
                attemptsLeft={attemptsLeft}
                isResendCode={isResendCode}
            />
        </Modal>
    )
}

export default AuthModal

import { State } from '@pwa-concept/modules/graphql'
import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { PageBackButton } from 'elements/BlogStatePages/elements'
import { usePageParsedContent } from 'elements/BlogStatePages/hooks'
import { ParserContentWrapper } from 'elements/ParserContentWrapper'
import { StatePageSideBar } from 'modules/StatePage'
import {
    StatePageIntroBlockDesktop,
    StatePageIntroBlockMobile,
    StatePageMobileTable,
} from 'modules/StatePage/components'
import { useState } from 'react'

import styles from './StatePageContent.module.scss'

interface IStatePageDescription {
    state: State
}

const StatePageContent = (
    {
        state,
    }: IStatePageDescription,
) => {
    const [currentVisibleOnScreen, setCurrentVisibleOnScreen] = useState<string | null>(null)

    const isDesktop = useMediaBreakpoint().isBreakpointUp('lg')

    const { content, headers, refs } = usePageParsedContent(
        {
            content: state.description,
            setCurrentVisibleOnScreen,
        })

    return (
        <div className={styles.wrapper}>
            {
                isDesktop && (
                    <div>
                        <PageBackButton urlKey={state.location?.urlKey || ''} />
                        <StatePageSideBar
                            activeHeader={currentVisibleOnScreen}
                            headers={headers}
                            headerRefs={refs}
                            className={styles.sidebar}
                        />
                    </div>
                )
            }
            <ParserContentWrapper>
                {
                    isDesktop
                        ? <StatePageIntroBlockDesktop state={state} />
                        : <StatePageIntroBlockMobile state={state} />
                }
                {content}
            </ParserContentWrapper>
            {
                !isDesktop && (
                    <StatePageMobileTable
                        headers={headers}
                        headerRefs={refs}
                        activeHeader={currentVisibleOnScreen}
                    />
                )
            }
        </div>
    )
}

export default StatePageContent

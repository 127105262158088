import { Resolvers } from '@pwa-concept/modules/graphql'

import FamilyStrain from './FamilyStrain'
import FamilyStrains from './FamilyStrains'
import Mutation from './Mutation'
import Query from './Query'

const resolvers: Resolvers = {
    Query,
    Mutation,
    FamilyStrains,
    FamilyStrain,
}

export default resolvers

import { Skeleton } from '@pwa-onilab/ui/components/elements/Skeleton'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './BlogCard.module.scss'

export interface IBlogCardSkeletonProps {
    className?: string
    skeletonCount?: number
}

const BlogCardSkeleton = (
    {
        className,
        skeletonCount = 1,
    }: IBlogCardSkeletonProps,
) => {
    return (
        <>
            {
                Array
                    .from({ length: skeletonCount })
                    .map((_, idx) => (
                        <Skeleton key={idx} className={classNames(className, styles.skeleton)} />
                    ))
            }
        </>
    )
}

export { BlogCardSkeleton }

import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

export type CartBillingAddressFragment = { __typename?: 'CartBillingAddress', city?: string | null, company?: string | null, firstName?: string | null, lastName?: string | null, postCode?: string | null, street?: Array<string | null> | null, telephone?: string | null, country?: { __typename?: 'CartAddressCountry', code?: string | null, label?: string | null } | null, region?: { __typename?: 'CartAddressRegion', code?: string | null, label?: string | null, regionId?: number | null } | null };

export const CartBillingAddressFragmentDoc = gql`
    fragment CartBillingAddress on CartBillingAddress {
  city
  company
  country {
    code
    label
  }
  firstName
  lastName
  postCode
  region {
    code
    label
    regionId
  }
  street
  telephone
}
    `;
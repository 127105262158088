import { FilterType } from '@pwa-concept/modules/graphql'
import { CATEGORY_KEY } from '@pwa-onilab/ui/components/modules/Filters'
import { IFilterControls } from '@pwa-onilab/ui/components/modules/Filters/components/FilterControls/IFilterControls'
import FilterGroup from 'components/overrides/modules/Filters/elements/FilterGroup/FilterGroup'
import FilterOptions from 'components/overrides/modules/Filters/elements/FilterOptions/FilterOptions'
import { FILTER_NODE_PRICE } from 'modules/Filters'

import styles from './FilterModalContent.module.scss'

const FilterModalContent = (
    {
        filters,
        currentValues,
        onChangeValue,
        numResults,
        onToggleOption,
        isOptionChecked,
    }: Pick<IFilterControls,
    'filters' | 'currentValues' | 'onChangeValue' | 'numResults' | 'onToggleOption' | 'isOptionChecked'>,
) => {
    return (
        <>
            {
                filters?.map((group, index) => {
                    if (!group || group.key === CATEGORY_KEY || group.key === FILTER_NODE_PRICE) {
                        return null
                    }

                    return (
                        <FilterGroup
                            key={group.id || group.key || index}
                            title={group.name}
                            className={styles.option}
                            description={group.description}
                            hideHeaderTitle={group?.type === FilterType.Boolean}
                        >
                            <FilterOptions
                                numResults={numResults}
                                data={group}
                                title={group?.name}
                                onChangeValue={onChangeValue}
                                currentValues={currentValues}
                                onToggleOption={onToggleOption}
                                isOptionChecked={isOptionChecked}
                            />
                        </FilterGroup>
                    )
                })
            }
        </>
    )
}

export default FilterModalContent

import styles from './PromoCodeContent.module.scss'

interface IPromoCodeContentProps {
    title: string
    shortDescription: string
}

const PromoCodeContent = (
    {
        title,
        shortDescription,
    }: IPromoCodeContentProps,
) => (
    <>
        {
            title && (
                <div className={styles.title}>
                    {title}
                </div>
            )
        }
        {
            shortDescription && (
                <div className={styles.description}>
                    {shortDescription}
                </div>
            )
        }
    </>
)

export default PromoCodeContent

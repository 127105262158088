import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { PropsWithChildren } from 'react'

import styles from './TotalsRow.module.scss'

const TotalsRow = ({ children, className }: PropsWithChildren<IClassName>) => {
    return (
        <div className={classNames(styles.row, className)}>
            {children}
        </div>
    )
}

export default TotalsRow

import { useApolloClient } from '@apollo/client'
import { WISHLIST_KEYFIELD } from '@pwa-concept/modules/wishlist/constants'

const useWishlistDeleteFromCache = () => {
    const { cache } = useApolloClient()

    return () => {
        cache.evict({ id: WISHLIST_KEYFIELD })
    }
}

export default useWishlistDeleteFromCache

import { ADDRESS_FORM_CONFIGURATION } from '@pwa-onilab/ui/components/modules/AddressForm'
import { useSaveBeforeHideModal } from '@pwa-onilab/ui/hooks'
import { useCallback, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'

import IAddressForm from '../IAddressForm'

type tUseAddressForm = (defaultValues: IAddressForm['defaultValues'], onSubmit: IAddressForm['onSubmit']) => {
    submitHandler: (formValues) => Promise<void>
    methods: UseFormReturn<object, any>
    isSubmitting: boolean
}

const useAddressForm: tUseAddressForm = (defaultValues, onSubmit) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const methods = useForm({
        ...ADDRESS_FORM_CONFIGURATION,
        defaultValues,
    })

    const { formState: { isDirty }, trigger } = methods

    const submitHandler = useCallback(async (formValues) => {
        if (!isDirty) {
            return
        }
        setIsSubmitting(true)
        await onSubmit(formValues)
        setIsSubmitting(false)
    }, [isDirty])

    useSaveBeforeHideModal({
        isFormWasChanged: isDirty && !isSubmitting,
        callback: useCallback(async () => {
            const isFormValid = await trigger()

            if (isFormValid) {
                try {
                    await submitHandler(methods.getValues())
                    return true
                } catch (e) {
                    return false
                }
            }
            return false
        }, []),
    })

    return {
        submitHandler,
        methods,
        isSubmitting,
    }
}

export default useAddressForm

import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './MenuLink.module.scss'

interface IMenuLinkProps {
    className?: string
    name: string
    to: string
}

const MenuLink = (
    {
        className,
        name,
        to,
    }: IMenuLinkProps,
) => (
    <li className={classNames(styles.link, className)}>
        <Anchor to={to}>
            {name}
        </Anchor>
    </li>
)

export default MenuLink

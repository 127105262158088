import { MediaBreakpoint, MediaBreakpointProps } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { PropsWithChildren } from 'react'

interface MediaBreakpointUpProps extends Pick<MediaBreakpointProps, 'name'>{

}

const MediaBreakpointUp = (props: PropsWithChildren<MediaBreakpointUpProps>) => (
    <MediaBreakpoint {...props} direction="up" />
)

export default MediaBreakpointUp

import { ButtonInstance, IButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import styles from './SimpleButton.module.scss'

// TODO SimpleButton seems to be useless right now, replace with ButtonInstance
/**
 $doc$
 * @deprecated since 15 12 2022; use ButtonInstance instead
 */
const SimpleButton = (
    {
        children,
        className,
        onClick,
        disabled = false,
        type = 'button',
    }: Partial<PropsWithChildren<IButtonInstance>>,
) => (
    <ButtonInstance
        className={classNames(styles.button, className)}
        onClick={onClick}
        disabled={disabled}
        type={type}
    >
        {children}
    </ButtonInstance>
)

export default SimpleButton

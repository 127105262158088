import { CategoryCheckbox } from '@pwa-onilab/ui/components/elements/CategoryCheckbox'
import {
    COLOR_KEY,
    FilterOptionsCheckboxColor,
    IFilterOptionsModalOptions,
} from '@pwa-onilab/ui/components/modules/Filters'
import { useFilterApiContext } from '@pwa-onilab/ui/components/modules/Filters/context'

import styles from './FilterOptionsModalOptions.module.scss'

const FilterOptionsModalOptions = ({ options, filter, letter }: IFilterOptionsModalOptions) => {
    const { onToggleOption, isOptionChecked } = useFilterApiContext()

    return (
        <>
            {options.map((option, idx) => {
                const categoryCheckboxChange = () => onToggleOption(filter, option)
                const isChecked = isOptionChecked(filter, option)

                switch (option.key) {
                    case COLOR_KEY: {
                        return (
                            <FilterOptionsCheckboxColor
                                key={option.id}
                                item={option}
                                onChange={categoryCheckboxChange}
                                isChecked={isChecked}
                                className={styles.option}
                            />
                        )
                    }

                    default: {
                        return (
                            <CategoryCheckbox
                                key={option.id}
                                isChecked={isChecked}
                                name={option.value}
                                onChange={categoryCheckboxChange}
                                variant="filter"
                                className={styles.option}
                            >
                                {
                                    (letter && !idx) && (
                                        <p className={styles.letter}>
                                            {letter}
                                        </p>
                                    )
                                }
                                {option.name} ({option.count})
                            </CategoryCheckbox>
                        )
                    }
                }
            })}
        </>
    )
}

export default FilterOptionsModalOptions

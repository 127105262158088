import { InputField } from '@pwa-onilab/ui/components/elements/Fields'
import { IFormField } from '@pwa-onilab/ui/components/elements/FormFields'
import { FORM_EMAIL_FIELD } from '@pwa-onilab/ui/constants/formFields'
import { REGEX_EMAIL } from '@pwa-onilab/ui/constants/patterns'
import { useTranslation } from 'react-i18next'

const EmailFormField = (
    {
        additionalRules,
        ...formFields
    }: IFormField) => {
    const { t } = useTranslation()

    return (
        <InputField
            {...formFields}
            type="text"
            placeholder={t('global.email')}
            field={FORM_EMAIL_FIELD}
            rules={{
                required: t('global.errors.required'),
                pattern: {
                    value: REGEX_EMAIL,
                    message: t('global.errors.email'),
                },
                ...additionalRules,
            }}
        />
    )
}

export default EmailFormField

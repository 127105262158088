import { Resolvers } from '@pwa-concept/modules/graphql'
import Query from './Query'
import Mutation from './Mutation'
import Wishlist from './Wishlist'
import WishlistItem from './WishlistItem'
import WishlistItemsOutput from './WishlistItemsOutput'
import WishlistUserInputError from './WishlistUserInputError'
import WishlistWithErrors from './WishlistWithErrors'

const resolvers: Resolvers = {
    Query,
    Mutation,
    Wishlist,
    WishlistItem,
    WishlistItemsOutput,
    WishlistUserInputError,
    WishlistWithErrors,
}

export default resolvers

import { Resolvers } from '@pwa-concept/modules/graphql'

const CustomerOrderAddress: Resolvers['CustomerOrderAddress'] = {
    firstName: (_, __, { context }) => context?.firstname || null,
    middleName: (_, __, { context }) => context?.middlename || null,
    lastName: (_, __, { context }) => context?.lastname || null,
    telephone: (_, __, { context }) => context?.telephone || null,
    city: (_, __, { context }) => context?.city || null,
    company: (_, __, { context }) => context?.company || null,
    street: (_, __, { context }) => context?.street || [],
    countryCode: (_, __, { context }) => context?.country_code || null,
    postcode: (_, __, { context }) => context?.postcode || null,
    region: (_, __, { context }) => context?.region || null,
    regionId: (_, __, { context }) => context?.region_id || null,
}

export default CustomerOrderAddress

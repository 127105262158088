import { Resolvers } from '@pwa-concept/modules/graphql'

import CustomerOrder from './CustomerOrder'
import CustomerOrderAddress from './CustomerOrderAddress'
import CustomerOrderDiscount from './CustomerOrderDiscount'
import CustomerOrderPaymentMethod from './CustomerOrderPaymentMethod'
import CustomerOrderProduct from './CustomerOrderProduct'
import CustomerOrderProductOption from './CustomerOrderProductOption'
import CustomerOrderProductPrice from './CustomerOrderProductPrice' // resolver for custom order query
import CustomerOrderTotals from './CustomerOrderTotals'
import CustomerOrderTotalsTax from './CustomerOrderTotalsTax'
import Query from './Query'

const resolvers: Resolvers = {
    Query,
    CustomerOrder,
    CustomerOrderAddress,
    CustomerOrderDiscount,
    CustomerOrderProduct,
    CustomerOrderProductOption,
    CustomerOrderProductPrice,
    CustomerOrderTotals,
    CustomerOrderTotalsTax,
    CustomerOrderPaymentMethod,
}

export default resolvers

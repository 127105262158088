import Cms from './Cms.graphql'
import CmsBlocks from './CmsBlocks.graphql'
import CmsRoute from './CmsRoute.graphql'
import PaymentMethodSuccessMessages from './PaymentMethodSuccessMessages.graphql'

export default [
    Cms,
    CmsRoute,
    CmsBlocks,
    PaymentMethodSuccessMessages,
]

import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { Link } from '@pwa-onilab/ui/components/UI/Link'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { addSlashToEndUrl } from 'helpers'
import { useTranslation } from 'react-i18next'

import styles from './PageBackButton.module.scss'

export interface IStatePageBackButton extends IClassName {
    urlKey: string
}

const PageBackButton = ({ urlKey, className }: IStatePageBackButton) => {
    const { t } = useTranslation()

    const isDesktop = useMediaBreakpoint().isBreakpointUp('lg')

    return (
        <Link
            to={`/${addSlashToEndUrl(urlKey)}`}
            className={classNames(styles.link, className)}
            aria-label={t('global.back')}
        >
            <Icon
                name="chevron"
                className={styles.icon}
            />
            {isDesktop && t('global.back')}
        </Link>
    )
}

export default PageBackButton

import { gql } from '@apollo/client'
import api from '@pwa-concept/core/api'
import { $cart } from '@pwa-concept/core/models'
import { CartApplyCouponCodeMutationVariables } from '@pwa-concept/modules/cart/graphql/mutations/CartApplyCouponCode'
import { MutationResolvers } from '@pwa-concept/modules/graphql'

const cartApplyCouponCode: MutationResolvers['cartApplyCouponCode'] = async (
    _,
    { code }: CartApplyCouponCodeMutationVariables,
) => {
    const cartId = $cart.id

    const { data: { applyCouponToCart: { cart: __context = null } = {} } = {}, errors: __errors } = await (
        api.graphql(gql`
            mutation(
                $input: ApplyCouponToCartInput
            ) {
                applyCouponToCart(input: $input) {
                    cart {
                        ... Cart
                    }
                }
            }
        `).mutation({
            input: {
                cart_id: cartId,
                coupon_code: code,
            },
        })
    )

    return {
        __context,
        __errors,
        __typename: 'Cart',
    }
}

export default cartApplyCouponCode

import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { DEFAULT_HELMET_ROBOTS_META } from '@pwa-onilab/ui/components/elements/DefaultHelmet/DefaultHelmetConstants'
import { IDefaultHelmet } from '@pwa-onilab/ui/components/elements/DefaultHelmet/IDefaultHelmet'
import { getWebSiteMetaTitle } from '@pwa-onilab/ui/helpers'
import { Helmet } from 'react-helmet'

const DefaultHelmet = (
    {
        meta,
        isMetaLoading,
        defaultTitle,
    }: IDefaultHelmet) => {
    const { data: storeConfig, loading: storeConfigLoading } = useStoreConfig()

    if (isMetaLoading || storeConfigLoading) {
        return null
    }

    const metaTitle = meta?.title || ''
    const metaDescription = meta?.description || ''
    const metaKeywords = meta?.keywords || ''

    const webSiteTitleName = storeConfig?.defaultTitle
    const webSiteTitle = getWebSiteMetaTitle(webSiteTitleName, metaTitle, defaultTitle)

    const isAddImageMeta = !!meta?.ogImage?.src

    const robotsMeta = meta?.robots || DEFAULT_HELMET_ROBOTS_META

    return (
        <Helmet>
            <title>
                {webSiteTitle}
            </title>

            <meta name="title" content={webSiteTitle} />
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={metaKeywords} />

            <meta name="twitter:card" content="summary_large_image" />

            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:url" content={window.location.href} />

            <meta property="og:site_name" content={webSiteTitleName || ''} />
            <meta property="og:locale" content={storeConfig?.locale || ''} />

            {
                meta?.ogType && <meta property="og:type" content={meta.ogType} />
            }
            {/* react-helmet dont support fragments, so check twice */}
            {isAddImageMeta && <meta property="og:image" content={meta.ogImage.src} />}
            {isAddImageMeta && <meta property="og:image:alt" content={meta.ogImage.alt} />}

            {
                meta?.customProperties?.map(({ name, value }) => {
                    return <meta property={name} content={value} key={name} />
                })
            }

            <meta name="robots" content={robotsMeta} />
        </Helmet>
    )
}

export default DefaultHelmet

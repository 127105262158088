import {
    SocialMediaIconsList,
    SocialMediaList,
    VARIANT_ICONS_WITH_TITLE,
    VARIANT_ICONS_WITHOUT_TITLE,
    VARIANT_LIST,
} from '@pwa-onilab/ui/components/modules/Footer'
import { memo } from 'react'

interface ISocialMediaProps {
    className: string
    variant: string
}

const SocialMedia = (
    {
        className,
        variant = 'list',
    }: Partial<ISocialMediaProps>,
) => {
    switch (variant) {
        case VARIANT_ICONS_WITH_TITLE:
        case VARIANT_ICONS_WITHOUT_TITLE:
            return (
                <SocialMediaIconsList
                    variant={variant}
                    className={className}
                />
            )
        case VARIANT_LIST:
            return (
                <SocialMediaList className={className} />
            )
        default:
            return null
    }
}

export default memo(SocialMedia)

import { IFreeShippingStatusBar } from 'modules/Cart'

import styles from './FreeShippingStatusBarProgress.module.scss'

const FreeShippingStatusBarProgress = (
    {
        subtotal,
        freeShippingMinimumAmount,
    }: IFreeShippingStatusBar,
) => {
    const currentFillRate = (subtotal * 100) / freeShippingMinimumAmount
    const currentPercentFillValue = currentFillRate > 100 ? 100 : currentFillRate

    return (
        <div className={styles.bar}>
            <div
                style={{ width: `${currentPercentFillValue}%` }}
                className={styles.fillBar}
            />
            <div
                style={{ left: `calc(${currentPercentFillValue}% - 4px)` }}
                className={styles.marker}
            />
        </div>
    )
}

export default FreeShippingStatusBarProgress

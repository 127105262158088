import { useIsHideAccountHeader } from '@pwa-onilab/ui/components/modules/Account'
import { AccountMessageBlock } from '@pwa-onilab/ui/components/modules/Account/AccountHeader/AccountMessageBlock'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './AccountHeader.module.scss'
import AccountInfoBlocks from './AccountInfoBlocks'

interface AccountHelloBannerProps {
    className?: string
}

const AccountHeader = ({ className }: AccountHelloBannerProps) => {
    const isHideHeader = useIsHideAccountHeader()

    return (
        <div className={classNames(styles.wrapper, isHideHeader && styles.hideHeader, className)}>
            <AccountMessageBlock />
            <AccountInfoBlocks />
        </div>
    )
}

export default AccountHeader

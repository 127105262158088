import { useGetStrainFamilyQuery } from 'app/modules/strain-family/graphql/queries/GetStrainFamily'

const useGetStrainFamily = (urlKey: string) => {
    const { data: { getFamilyStrain } = {}, loading } = useGetStrainFamilyQuery({
        variables: {
            urlKey,
        },
        skip: !urlKey,
        // returnPartialData: true, // dont work :( TODO: check module read function
    })

    return {
        data: getFamilyStrain || undefined,
        loading,
    }
}

export default useGetStrainFamily

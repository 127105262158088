import Title, { ITitle } from '@pwa-onilab/ui/components/UI/Title'

import styles from './PageTitle.module.scss'

const PageTitle = ({ text }: Pick<ITitle, 'text'>) => {
    return (
        <Title type="h1" text={text} className={styles.title} />
    )
}

export default PageTitle

import { StoreConfig } from '@pwa-concept/modules/graphql'
import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { LOGO_CONFIGURATION } from 'constants/LogoConfiguration'
import { SchemaHelmet } from 'elements/SchemaHelmet'
import { SCHEMA_HELMET_CONTEXT_VALUE } from 'elements/SchemaHelmet/SchemaHelmetConstants'

export const structuredDataOrganization = (config: StoreConfig) => {
    if (!config || !Object.keys(config).length) {
        return {}
    }
    const url = `${window.location.origin}/`
    const organizationConfig = config.structuredData.organization

    const logoId = `${url}#/schema/logo/image/`

    const data = {
        '@context': SCHEMA_HELMET_CONTEXT_VALUE,
        '@type': 'Organization',

        '@id': `${url}#organization`,
        url,

        name: organizationConfig.name,
        alternateName: organizationConfig.alternativeName,
        description: organizationConfig.description,

        brand: organizationConfig.brand,
        email: organizationConfig.email,
        telephone: organizationConfig.telephone,

        logo: {
            '@type': 'ImageObject',
            '@id': logoId,
            url: LOGO_CONFIGURATION.desktopSrc,
            contentUrl: LOGO_CONFIGURATION.desktopSrc,
            caption: config.defaultTitle || '',
            inLanguage: config.locale || '',
            width: LOGO_CONFIGURATION.desktopWidth,
            height: LOGO_CONFIGURATION.desktopHeight,
        },
        image: {
            '@id': logoId,
        },

        address: {
            '@type': 'PostalAddress',
            streetAddress: organizationConfig.addressStreet,
            addressLocality: organizationConfig.addressLocality,
            postalCode: organizationConfig.addressPostcode,
            addressCountry: organizationConfig.addressCountry,
        },
        sameAs: [config.ogArticlePublisher],
    }
    return data
}

const SchemaHelmetOrganization = () => {
    const { data: storeConfig } = useStoreConfig()

    if (!storeConfig) {
        return null
    }

    const jsonConfig = structuredDataOrganization(storeConfig)

    return <SchemaHelmet jsonConfig={jsonConfig} />
}

export default SchemaHelmetOrganization

import { Breadcrumb, BreadcrumbUrlPossibleType, Resolvers } from '@pwa-concept/modules/graphql'

const id = (context) => String(context.id)

const Category: Resolvers['Category'] = {
    id: (_, __, { context }) => `${id(context)}`,
    name: (_, __, { context }) => context?.name,
    description: (_, __, { context }) => context?.description,
    level: (_, __, { context }) => context?.level ? Number(context?.level) : 0,
    image: (_, __, { context }) => context?.image || null,
    breadcrumbs: (category, __, { context }) => ([
        ...(context?.breadcrumbs || [])?.map?.((breadcrumb, index): Breadcrumb => ({
            id: `${id(context)}_${index}`,
            name: breadcrumb?.category_name,
            level: +breadcrumb?.category_level || null,
            url: {
                id: String(breadcrumb?.category_id),
                type: BreadcrumbUrlPossibleType.Category,
                to: breadcrumb?.category_url_path?.length > 0 ? `/${breadcrumb.category_url_path}` : null,
                __typename: 'BreadcrumbUrl',
            },
            __typename: 'Breadcrumb',
        })),
        {
            id: `${id(context)}_${(+context?.breadcrumbs?.length || 0)}`,
            level: 0,
            name: context?.name,
            url: null,
            __typename: 'Breadcrumb',
        },
    ]),
    url: (_, __, { context }) => {
        if (context?.url_path?.length) return context?.url_path
        if (context?.url_key?.length) return [context?.url_key, context?.url_suffix].filter(Boolean).join('')

        return null
    },
    metaDescription: (_, __, { context }) => {
        return context?.meta_description || ''
    },
    metaKeywords: (_, __, { context }) => {
        return context?.meta_keywords || ''
    },
    metaTitle: (_, __, { context }) => {
        return context?.meta_title || ''
    },
}

export default Category

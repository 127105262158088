import { IImageLazyProps } from '@pwa-onilab/ui/components/UI/Image/IImageProps'
import Image from '@pwa-onilab/ui/components/UI/Image/Image'
import useImageLazyObserver from '@pwa-onilab/ui/components/UI/Image/ImageLazy/useImageLazyObserver'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useCallback, useRef, useState } from 'react'

import styles from './ImageLazy.module.scss'

const ImageLazy = (
    {
        isEager = false,
        className,
        loaderClassName,
        ...imageProps
    }: IImageLazyProps) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null)
    const [isVisible, setIsVisible] = useState<boolean>(isEager)
    const [isLoad, setIsLoad] = useState<boolean>(isEager)

    const onScreen = useCallback(() => setIsVisible(true), [])
    const onLoad = () => setIsLoad(true)

    useImageLazyObserver(wrapperRef, onScreen)

    return (
        <div
            ref={wrapperRef} className={classNames(
                !isLoad && styles.wrapperLoading,
                !isLoad && loaderClassName,
            )}
        >
            {
                isVisible && (
                    <Image
                        {...imageProps}
                        className={classNames(className, !isLoad && styles.imgLoading)}
                        onLoad={onLoad}
                    />
                )
            }
        </div>
    )
}

export default ImageLazy

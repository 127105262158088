import {
    AccountFormSubmit,
    AccountOTP,
    PersonalInfoFullNameField,
    usePersonalInfoForm,
} from '@pwa-onilab/ui/components/modules/Account'
import { FormProvider } from 'react-hook-form'

import { ChangePasswordButton, PasswordChangeFields } from './components'
import PersonalInfoEmailField from './fields/PersonalInfoEmailField/PersonalInfoEmailField'
import PersonalInfoPasswordField from './fields/PersonalInfoPasswordField/PersonalInfoPasswordField'

const PersonalInfoForm = () => {
    const {
        submitHandler,
        formChangeHandler,
        isShowPasswordConfirmation,
        openPasswordFields,
        openOtpModal,
        methods,

        isCustomerUpdating,
        isShowPasswordFields,

        isOtpModalOpen,
        handleChangeEmailClickOnOtp,
        closeOtpModal,
    } = usePersonalInfoForm()
    const { formState: { isDirty, isSubmitting } } = methods

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submitHandler)} onChange={formChangeHandler}>
                <PersonalInfoFullNameField />
                <PersonalInfoEmailField />
                {
                    isShowPasswordConfirmation && <PersonalInfoPasswordField />
                }
                <ChangePasswordButton onClick={openPasswordFields} isShowPasswordFields={isShowPasswordFields} />
                {
                    isShowPasswordFields && (
                        <PasswordChangeFields
                            openOtpResetModal={openOtpModal}
                            errors={methods.formState.errors}
                        />
                    )
                }
                <AccountFormSubmit
                    isDisabled={!isDirty || isCustomerUpdating || isSubmitting}
                    isLoading={isCustomerUpdating || isSubmitting}
                />
            </form>
            <AccountOTP
                isOtpModalOpen={isOtpModalOpen}
                closeOtpModal={closeOtpModal}
                handleChangeEmail={handleChangeEmailClickOnOtp}
            />
        </FormProvider>
    )
}

export default PersonalInfoForm

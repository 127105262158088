import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { checkPasswordIsStrong, getPasswordStrengthErrorMessage } from '@pwa-onilab/ui/helpers'
import { useMemo } from 'react'
import { RegisterOptions } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const useAccountOtpAddPasswordRules = (): RegisterOptions => {
    const { t } = useTranslation()
    const { data: { passwordMinLength, passwordMinStrength } = {} } = useStoreConfig()

    return useMemo((): RegisterOptions => {
        return {
            validate: {
                passwordStrength: (value) => {
                    const isPassStrong = checkPasswordIsStrong(value, passwordMinStrength)
                    if (isPassStrong) {
                        return true
                    }
                    return t(getPasswordStrengthErrorMessage(passwordMinStrength))
                },
            },
            minLength: {
                value: passwordMinLength,
                message: t('password.error.length', { minLength: passwordMinLength }),
            },
        }
    }, [])
}

export default useAccountOtpAddPasswordRules

import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import Cookies from 'js-cookie'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './AgePopUp.module.scss'
import Logo from '@pwa-onilab/ui/components/UI/Logo/Logo'

const AgePopUp = () => {
    const { t } = useTranslation()
    const state = Cookies.get('weeseeds') ? false : true
    const [modalIsOpen, setModalIsOpen] = useState(state)
    const ageAccept = () => {
        setModalIsOpen(false)
        Cookies.set('weeseeds', true, { path: '/' })
    }
    const closeModal = () => {
        setModalIsOpen(true)
    }
    const ageDecline = useCallback(() => {
        window.history.back()
    }, [])

    return (
        <>
            <Modal
                className={styles.modalAge}
                isDraggable
                isOverAll
                titleClassName={styles.modalTitle}
                isOpen={modalIsOpen}
                onClose={closeModal}
            >
                <div className={styles.logoPopup}>
                    <Logo></Logo>
                </div>
                <div>
                    <p className={styles.boldText}>{t('This website is intended for adults only (18+)')}</p>
                    <p>{t('Are you over 18 years of age?')}</p>
                </div>
                <div className={styles.buttonsWrapper}>
                    <Button
                        isPrimary
                        isBold
                        onClick={ageAccept}
                    >
                        {t('Yes')}
                    </Button>
                    <Button
                        isBold
                        onClick={ageDecline}
                        className={styles.ageDecline}
                    >
                        {t('No')}
                    </Button>
                </div>
            </Modal>
        </>
    )
}
export default AgePopUp

import { ModalHeaderBackArrowWithSearch } from '@pwa-onilab/ui/components/elements/Modal/elements'
import {
    MobileMenuContainer,
    MobileMenuModal,
    MobileMenuPlaceholder,
    MobileMenuTopNode,
} from '@pwa-onilab/ui/components/modules/MobileMenuPage'
import { goBack } from '@pwa-onilab/ui/helpers'
import { useCategoryPageMenu, useSearchModal } from '@pwa-onilab/ui/hooks'
import { useTranslation } from 'react-i18next'

const ShopPageModalHeader = () => {
    const { t } = useTranslation()

    const goBackHandler = goBack()
    const { openSearchModal } = useSearchModal()

    return (
        <ModalHeaderBackArrowWithSearch
            onDismiss={goBackHandler}
            onFocus={openSearchModal}
        >
            {t('global.catalog')}
        </ModalHeaderBackArrowWithSearch>
    )
}

const MobileMenuShopPage = () => {
    const { data, loading } = useCategoryPageMenu()

    if (loading) {
        return (
            <MobileMenuPlaceholder />
        )
    }

    return (
        <MobileMenuModal header={<ShopPageModalHeader />}>
            <MobileMenuContainer>
                {
                    data?.map(({ name, nodeId, url }) => (
                        <MobileMenuTopNode
                            key={name}
                            name={name}
                            nodeId={nodeId}
                            url={url}
                            isClosedList
                        />
                    ))
                }
            </MobileMenuContainer>
        </MobileMenuModal>
    )
}

export default MobileMenuShopPage

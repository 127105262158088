import { ButtonInstance, IButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import styles from './TextOnlyButton.module.scss'

interface ITextOnlyButton extends IButtonInstance{
    children?: string
    isBold?: boolean
    isUnderline?: boolean
}

const TextOnlyButton = (
    {
        children,
        className,
        isBold,
        isUnderline,
        ...ButtonInstanceRestProps
    }: PropsWithChildren<ITextOnlyButton>,
) => (
    <ButtonInstance
        {...ButtonInstanceRestProps}
        className={classNames(
            styles.wrapper,
            className,
            isBold && styles.bold,
            isUnderline && styles.underline,
        )}
    >
        {children}
    </ButtonInstance>
)

export default TextOnlyButton

import Label from '@pwa-onilab/ui/components/UI/Label'
import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import styles from './Field.module.scss'

interface FieldProps {
    id?: string
    label: string
    inputBoxClass?: string
    labelClass?: string
    required?: boolean
    shouldShowFieldPlaceholder?: boolean
    isLongLabel?: boolean
}

const Field = (
    {
        children,
        id,
        label,
        required,
        inputBoxClass,
        labelClass,
        shouldShowFieldPlaceholder,
        isLongLabel,
    }: PropsWithChildren<FieldProps>) => {
    const requiredSymbol = required ? (<>*</>) : null

    return (
        <div
            className={classNames(styles.inputBox, inputBoxClass, shouldShowFieldPlaceholder && styles.showInputPlaceholder, isLongLabel && styles.bigTextPlaceholder)}
        >
            {children}
            <Label className={classNames(styles.label, labelClass)} htmlFor={id}>
                {label}
                {requiredSymbol}
            </Label>
        </div>
    )
}

export default Field

import { LiveSearchCategory } from '@pwa-concept/modules/graphql'
import { useLiveSearchQuery } from '@pwa-concept/modules/live-search'
import {
    CATEGORIES_LIST_SIZE,
    LAST_SEARCHES_SIZE,
    SEARCH_DEBOUNCE_TIME,
    SEARCH_PAGE_SIZE,
} from '@pwa-onilab/ui/components/modules/Search'
import { ISearchCategory } from '@pwa-onilab/ui/components/modules/Search/hooks/ISearchCategory'
import { useLocalStorage } from '@pwa-onilab/ui/hooks'
import { MouseEvent, useMemo, useRef, useState } from 'react'

const getCategoriesFromLiveSearchResult = (categories: LiveSearchCategory[]) => {
    if (!categories?.length) {
        return null
    }
    return categories.slice(0, CATEGORIES_LIST_SIZE).map((category, index) => ({
        key: category.id || index,
        to: category.link,
        name: category.name,
    }))
}

const useLiveSearch = () => {
    const [searchValue, setSearchValue] = useState('')
    const [searchResult, setSearchResult] = useState(null)
    const [lastSearches, setLastSearches] = useLocalStorage('lastSearches', null)
    const [categories, setCategories] = useState<ISearchCategory[] | null>(null)
    const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)

    const debounceRef = useRef(null)

    const { search: startSearch } = useLiveSearchQuery({
        onCompleted: ({ liveSearch } = {}) => {
            setIsSearchLoading(false)
            if (liveSearch) {
                setSearchResult(liveSearch)
                setCategories(getCategoriesFromLiveSearchResult(liveSearch?.categories))
                if (searchValue) {
                    setLastSearches((prevLastSearches) => {
                        if (prevLastSearches?.length) {
                            return [...prevLastSearches.slice(-LAST_SEARCHES_SIZE), searchValue]
                        }
                        return [searchValue]
                    })
                }
            }
        },
    })

    const {
        search,
        clearSearchField,
        handleInputChange,
        onClearLastSearches,
    } = useMemo(() => {
        const handleSearch = (searchString = '', pageSize = SEARCH_PAGE_SIZE) => {
            setSearchValue(searchString)

            if (debounceRef.current) {
                clearTimeout(debounceRef.current)
            }

            if (!searchString) {
                setIsSearchLoading(false)
                return
            }

            setIsSearchLoading(true)
            debounceRef.current = setTimeout(() => {
                void startSearch({
                    variables: {
                        search: searchString,
                        pageSize,
                    },
                })
            }, SEARCH_DEBOUNCE_TIME)
        }

        const clearSearchField = () => {
            setSearchValue('')
            setSearchResult(null)
            setCategories(null)
            setIsSearchLoading(false)
        }

        const handleInputChange = (event: MouseEvent<HTMLElement>) => {
            void search(event)// TODO recheck it latter
        }

        const onClearLastSearches = () => setLastSearches(null)

        return {
            search: handleSearch,
            clearSearchField,
            handleInputChange,
            onClearLastSearches,
        }
    }, [])

    return {
        searchValue,
        searchResult,
        searchLoading: isSearchLoading,
        clearSearchField,
        lastSearches: lastSearches || [],
        onClearLastSearches,
        handleInputChange,
        categories,
    }
}

export default useLiveSearch

import IconsAccount from '@pwa-onilab/ui/assets/icons/account'
import IconsCheckout from '@pwa-onilab/ui/assets/icons/checkout'
import IconsLogo from '@pwa-onilab/ui/assets/icons/logo'
import CustomIcons from 'assets/icons/CustomIcons'
import CustomPaymentsIcons from 'assets/icons/payments/CustomPaymentsIcons'

import { ReactComponent as Account } from './account.svg'
import { ReactComponent as Bag } from './bag.svg'
import { ReactComponent as Butterfly } from './butterfly.svg'
import { ReactComponent as Chevron } from './chevron.svg'
import { ReactComponent as ChevronBold } from './chevronBold.svg'
import { ReactComponent as BagMobile } from './common/bagMobile.svg'
import { ReactComponent as BottomArrow } from './common/bottomArrow.svg'
import { ReactComponent as BurgerMenu } from './common/burger.svg'
import { ReactComponent as Close } from './common/close.svg'
import { ReactComponent as Comment } from './common/comment.svg'
import { ReactComponent as Cross } from './common/cross.svg'
import { ReactComponent as Heart } from './common/heart.svg'
import { ReactComponent as HeartFill } from './common/heartFill.svg'
import { ReactComponent as HomeIcon } from './common/home.svg'
import { ReactComponent as LeftArrow } from './common/leftArrow.svg'
import { ReactComponent as Logout } from './common/logout.svg'
import { ReactComponent as Minus } from './common/minus.svg'
import { ReactComponent as Mobile } from './common/mobile.svg'
import { ReactComponent as ModifiedBasket } from './common/modifiedBasket.svg'
import { ReactComponent as PdfDoc } from './common/pdfDoc.svg'
import { ReactComponent as Plus } from './common/plus.svg'
import { ReactComponent as ReviewStar } from './common/reviewStar.svg'
import { ReactComponent as ReviewHalfStar } from './common/reviewStarHalf.svg'
import { ReactComponent as Share } from './common/share.svg'
import { ReactComponent as ShopLogo } from './common/shopLogo.svg'
import { ReactComponent as ShowPasswordIcon } from './common/showPasswordIcon.svg'
import { ReactComponent as Tick } from './common/tick.svg'
import { ReactComponent as Trash } from './common/trash.svg'
import { ReactComponent as Wishlist } from './common/wishlist.svg'
import { ReactComponent as CreditCart } from './creditCard.svg'
import { ReactComponent as DoubleLeftArrow } from './doubleLeftArrow.svg'
import { ReactComponent as FilterList } from './filterList.svg'
import { ReactComponent as Check } from './forStyles/check.svg'
import { ReactComponent as Gift } from './gift.svg'
import { ReactComponent as Leaf } from './leaf.svg'
import { ReactComponent as MagnifyingGlass } from './magnifyingGlass.svg'
import { ReactComponent as MagnifyingGlassBlack } from './magnifyingGlassBlack.svg'
import { ReactComponent as Mail } from './mail.svg'
import { ReactComponent as Mark } from './mark.svg'
import { ReactComponent as MarkWhite } from './markWhite.svg'
import { ReactComponent as NextArrow } from './nextArrow.svg'
import { ReactComponent as Package } from './package.svg'
import { ReactComponent as Percent } from './percent.svg'
import { ReactComponent as Phone } from './phone.svg'
import { ReactComponent as Question } from './question.svg'
import { ReactComponent as Facebook } from './socials/Facebook.svg'
import { ReactComponent as FacebookGrayscale } from './socials/grayscale/facebook.svg'
import { ReactComponent as InstagramGrayscale } from './socials/grayscale/instagram.svg'
import { ReactComponent as PinterestGrayscale } from './socials/grayscale/pinterest.svg'
import { ReactComponent as YoutubeGrayscale } from './socials/grayscale/youtube.svg'
import { ReactComponent as Instagram } from './socials/Instagram.svg'
import { ReactComponent as Twitter } from './socials/Twitter.svg'
import { ReactComponent as TopBottomArrows } from './topBottomArrows.svg'

const Icons = {
    mark: <Mark />,
    markWhite: <MarkWhite />,
    question: <Question />,
    magnifyingGlass: <MagnifyingGlass />,
    magnifyingGlassBlack: <MagnifyingGlassBlack />,
    mobile: <Mobile />,
    comment: <Comment />,
    burgerMenu: <BurgerMenu />,

    wishlist: <Wishlist />,
    trash: <Trash />,

    account: <Account />,
    bag: <Bag />,
    bagMobile: <BagMobile />,
    modifiedBasket: <ModifiedBasket />,
    percent: <Percent />,
    leaf: <Leaf />,
    chevron: <Chevron />,
    package: <Package />,
    gift: <Gift />,
    butterfly: <Butterfly />,
    creditCard: <CreditCart />,
    chevronBold: <ChevronBold />,
    phone: <Phone />,
    mail: <Mail />,
    facebook: <Facebook />,
    facebookGrayscale: <FacebookGrayscale />,
    twitter: <Twitter />,
    instagram: <Instagram />,
    instagramGrayscale: <InstagramGrayscale />,
    youtubeGrayscale: <YoutubeGrayscale />,
    pinterestGrayscale: <PinterestGrayscale />,
    pdfDoc: <PdfDoc />,

    heart: <Heart />,
    heartFill: <HeartFill />,
    cross: <Cross />,
    reviewStar: <ReviewStar />,
    reviewHalfStar: <ReviewHalfStar />,
    filterList: <FilterList />,
    doubleLeftArrow: <DoubleLeftArrow />,
    check: <Check />,
    topBottomArrows: <TopBottomArrows />,
    nextArrow: <NextArrow />,
    bottomArrow: <BottomArrow />,
    leftArrow: <LeftArrow />,
    close: <Close />,
    share: <Share />,
    home: <HomeIcon />,

    shopLogo: <ShopLogo />,

    plus: <Plus />,
    minus: <Minus />,

    tick: <Tick />,
    logout: <Logout />,
    showPasswordIcon: <ShowPasswordIcon />,

    ...IconsAccount,
    ...IconsCheckout,
    ...IconsLogo,
    ...CustomPaymentsIcons,
    ...CustomIcons,
}

export type tIconName = keyof typeof Icons

export default Icons

import { Resolvers } from '@pwa-concept/modules/graphql'

const Wishlist: Resolvers['Wishlist'] = {
    id: (_, __, { context }) => context?.uid || null,
    count: (_, __, { context }) => context?.items_count || null,
    sharingCode: (_, __, { context }) => context?.sharing_code || null,
    items: (_, __, { context }) => !!context?.items ? ({
        __context: context?.items,
        __typename: 'WishlistItemsOutput',
    }) : null,
}

export default Wishlist

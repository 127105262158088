import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { IDefaultHelmet } from '@pwa-onilab/ui/components/elements/DefaultHelmet'
import {
    useStoreConfigFamiliesHomePageMetaQuery,
} from 'app/modules/store-config/graphql/queries/storeConfigFamiliesHomePageMeta'

const useStoreConfigFamiliesHomePageMeta = () => {
    const { data: { storeConfigFamiliesHomePageMeta } = {}, loading } = useStoreConfigFamiliesHomePageMetaQuery()
    const { data: storeConfig } = useStoreConfig()
    const meta: IDefaultHelmet['meta'] = {
        ...storeConfigFamiliesHomePageMeta,
        ogType: 'article',
    }

    if (storeConfig?.ogArticlePublisher) {
        meta.customProperties = [
            {
                name: 'article:publisher',
                value: storeConfig.ogArticlePublisher,
            },
        ]
    }

    return {
        data: meta,
        loading,
    }
}

export default useStoreConfigFamiliesHomePageMeta

import Slider from '@pwa-onilab/ui/components/UI/Slider'
import { SliderProps } from '@pwa-onilab/ui/components/UI/Slider/Slider'
import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import styles from './CarouselProductsSlider.module.scss'

const CarouselProductsSlider = ({ children, className, ...props }: PropsWithChildren<SliderProps>) => {
    return (
        <Slider className={classNames(styles.carouselSlider, className)} {...props}>
            {children}
        </Slider>
    )
}

export default CarouselProductsSlider

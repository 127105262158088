import { Resolvers } from '@pwa-concept/modules/graphql'

const BlogAuthor: Resolvers['BlogAuthor'] = {
    id: (_, __, { context }) => context.id,
    urlKey: (_, __, { context }) => context.url_key,
    imageUrl: (_, __, { context }) => context.featured_image,
    thumbnailImageUrl: (_, __, { context }) => context.thumbnail_featured_image,

    name: (_, __, { context }) => context.name,
    description: (_, __, { context }) => context.description,
    socialUrls: (_, __, { context }) => ({
        facebook: context.facebook_link,
        instagram: context.instagram_link,
        linkedin: context.linkedin_link,
        pinterest: context.pinterest_link,
        twitter: context.twitter_link,
        youtube: context.youtube_link,
    }),

    meta: (_, __, { context }) => ({
        title: context.meta_title,
        keywords: context.meta_keywords,
        description: context.meta_description,
        robots: context.meta_robots,
    }),
    categories: (_, __, { context }) => context.categories,
}

export default BlogAuthor

import api from '@pwa-concept/core/api'
import {
    extractFieldsFromCustomerAddressInput,
} from '@pwa-concept/modules/customer-addresses/graphql/stores/magento2/resolvers/Mutation/helpers'
import { MutationResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const createCustomerAddressMutation = async (inputFields) => await api.graphql(
    gql`
        mutation($input: CustomerAddressInput!) {
            createCustomerAddress(input: $input) {
                ... CustomerAddress
            }
        }
    `,
).mutation({
    input: inputFields,
})

// TODO fix input interface
const createCustomerAddress: MutationResolvers['createCustomerAddress'] = async (_, input) => {
    const inputFields = extractFieldsFromCustomerAddressInput(input?.input)

    const { data: { createCustomerAddress: __context = null } = {} } = await createCustomerAddressMutation(inputFields)

    if (!__context) return null

    return {
        __context,
        __typename: 'CustomerAddress',
    }
}

export default createCustomerAddress

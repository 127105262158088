import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import styles from './TableCell.module.scss'

interface ITableCell {
    className?: string
}

const TableCell = ({ children, className }: PropsWithChildren<ITableCell>) => {
    return (
        <div className={classNames(styles.td, className)}>
            {children}
        </div>
    )
}

export default TableCell

import { PropsWithChildren } from 'react'

import css from './InputErrorWrapper.module.scss'

interface InputErrorWrapperProps {
    className?: string
    value?: string | {
        message?: string
    }
}

const InputErrorWrapper = (
    {
        children,
        className,
        value,
    }: PropsWithChildren<InputErrorWrapperProps>,
) => {
    const error = (typeof value === 'string' ? value : value?.message) || null

    return (
        <div className={className}>
            {children}
            {!!error && <p className={css.error}>{error}</p>}
        </div>
    )
}

export default InputErrorWrapper

import { ReactComponent as Account } from '../account.svg'
import { ReactComponent as Package } from '../package.svg'
import { ReactComponent as Percent } from '../percent.svg'
import { ReactComponent as CheckoutArrow } from './checkoutArrow.svg'
import { ReactComponent as CheckoutEdit } from './checkoutEdit.svg'
import { ReactComponent as CreditCard } from './credirCard3.svg'
import { ReactComponent as Home } from './home.svg'
import { ReactComponent as SecureCheckoutIcon } from './lock.svg'

const IconsCheckout = {
    checkoutEmail: <Account />,
    checkoutAddress: <Home />,
    checkoutShipping: <Package />,
    checkoutPayment: <CreditCard />,
    checkoutDiscount: <Percent />,
    secureCheckout: <SecureCheckoutIcon />,
    checkoutArrow: <CheckoutArrow />,
    checkoutEdit: <CheckoutEdit />,
}

export default IconsCheckout

import { ReactComponent as Box } from 'assets/icons/box.svg'
import { ReactComponent as CloudMessage } from 'assets/icons/cloudMessage.svg'
import { ReactComponent as ContactUsChat } from 'assets/icons/contactUs/ContactUsChat.svg'
import { ReactComponent as CreditWithLock } from 'assets/icons/creditWithLock.svg'
import { ReactComponent as DeliveryFast } from 'assets/icons/deliveryFast.svg'
import { ReactComponent as DeliveryTrack } from 'assets/icons/deliveryTrack.svg'
import { ReactComponent as Discount } from 'assets/icons/discount.svg'
import { ReactComponent as Dots } from 'assets/icons/dots.svg'
import { ReactComponent as Handshake } from 'assets/icons/handshake.svg'
import { ReactComponent as Informer } from 'assets/icons/informer.svg'
import { ReactComponent as Linkedin } from 'assets/icons/linkedin.svg'
import { ReactComponent as Loan } from 'assets/icons/loan.svg'
import { ReactComponent as Location } from 'assets/icons/location.svg'
import { ReactComponent as Lock } from 'assets/icons/lock.svg'
import { ReactComponent as Lotus } from 'assets/icons/lotus.svg'
import { ReactComponent as MobileWithChat } from 'assets/icons/mobileWithChat.svg'
import { ReactComponent as PaymentMethod } from 'assets/icons/paymentMethod.svg'
import { ReactComponent as Pencil } from 'assets/icons/pencil.svg'
import { ReactComponent as Savings } from 'assets/icons/savings.svg'
import { ReactComponent as Steps } from 'assets/icons/steps.svg'
import { ReactComponent as TextFieldBasket } from 'assets/icons/textFieldBasket.svg'
import { ReactComponent as TrackDeliveryWithSearch } from 'assets/icons/trackDeliveryWithSearch.svg'
import { ReactComponent as Weed } from 'assets/icons/weed.svg'

const CustomIcons = {
    box: <Box />,
    cloudMessage: <CloudMessage />,
    creditWithLock: <CreditWithLock />,
    contactUsChat: <ContactUsChat />,
    deliveryTrack: <DeliveryTrack />,
    discount: <Discount />,
    dots: <Dots />,
    handshake: <Handshake />,
    loan: <Loan />,
    lock: <Lock />,
    lotus: <Lotus />,
    mobileWithChat: <MobileWithChat />,
    savings: <Savings />,
    steps: <Steps />,
    textFieldBasket: <TextFieldBasket />,
    trackDeliveryWithSearch: <TrackDeliveryWithSearch />,
    weed: <Weed />,
    informer: <Informer />,
    paymentMethod: <PaymentMethod />,
    pencil: <Pencil />,
    location: <Location />,
    deliveryFast: <DeliveryFast />,
    linkedin: <Linkedin />,
}

export default CustomIcons

import { CategoryCheckbox } from '@pwa-onilab/ui/components/elements/CategoryCheckbox'
import {
    COLOR_KEY,
    FILTER_MAX_OPTIONS_WITHOUT_MODALS,
    FilterOptionsCheckboxColor,
    FilterOptionsModalButton,
    ONE_COLUMN_CATEGORY_KEYS,
    THREE_COLUMNS_CATEGORY_KEYS,
    TWO_COLUMNS_CATEGORY_KEYS,
} from '@pwa-onilab/ui/components/modules/Filters'
import { IFilterOptions } from '@pwa-onilab/ui/components/modules/Filters/elements/FilterOptions/IFilterOptions'
import { classNames } from '@pwa-onilab/ui/helpers'

import css from './FilterOptionsCheckbox.module.scss'

const FilterOptionsCheckbox = (
    {
        data,
        onToggleOption,
        isOptionChecked,
        numResults,
    }: Pick<IFilterOptions, 'numResults' | 'data' | 'onToggleOption' | 'isOptionChecked'>,
) => {
    if (!data) {
        return null
    }

    const showOptionModal = data?.items?.length >= FILTER_MAX_OPTIONS_WITHOUT_MODALS

    return (
        <>
            <div className={classNames(
                css.checkboxList,
                ONE_COLUMN_CATEGORY_KEYS[data?.key] && css.oneColumn,
                TWO_COLUMNS_CATEGORY_KEYS[data?.key] && css.twoColumns,
                THREE_COLUMNS_CATEGORY_KEYS[data?.key] && css.threeColumns,
            )}
            >
                {data?.items?.slice(0, 10).map((item) => {
                    if (!item) {
                        return null
                    }
                    const categoryCheckboxChange = () => {
                        onToggleOption(data, item)
                    }

                    const isChecked = isOptionChecked(data, item)

                    if (item.key === COLOR_KEY) {
                        return (
                            <FilterOptionsCheckboxColor
                                key={item.id}
                                isChecked={isChecked}
                                item={item}
                                onChange={categoryCheckboxChange}
                                className={css.checkbox}
                            />
                        )
                    }

                    return (
                        <CategoryCheckbox
                            isChecked={isChecked}
                            name={item.value}
                            onChange={categoryCheckboxChange}
                            key={item.id}
                            variant="filter"
                            className={css.checkbox}
                        >
                            {item.name} ({item.count})
                        </CategoryCheckbox>
                    )
                })}
            </div>
            {showOptionModal && <FilterOptionsModalButton data={data} numResults={numResults} />}
        </>
    )
}

export default FilterOptionsCheckbox

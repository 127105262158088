export const initGTM = (code: string ) => {
    window.dataLayer = [{
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
    }];

    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${code}`;
    document.body.appendChild(script);
    window.dataLayer.push({
        event: 'pageview',
    });
}

import { classNames } from '@pwa-onilab/ui/helpers/classNames'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { PropsWithChildren } from 'react'

import styles from './Container.module.scss'

export interface ContainerProp extends IClassName {
    isRevert?: boolean
    isRevertGift?: boolean
    isMobileFullWide?: boolean
}

const Container = (
    {
        children,
        className = '',
        isRevert = false,
        isRevertGift = false,
        isMobileFullWide = false,
    }: PropsWithChildren<ContainerProp>) => {
    return (
        <div className={
            classNames(
                isRevert
                    ? styles.revert
                    : styles.container,
                isRevertGift && styles.revertGift,
                isMobileFullWide && styles.mobileFullWide,
                className,
            )
}
        >
            {children}
        </div>
    )
}

export default Container

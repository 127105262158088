import { Product } from '@pwa-concept/modules/graphql'
import { ga4SelectItem } from '@pwa-onilab/ga4/operations/product/selectItem'
import { ProductBadges } from '@pwa-onilab/ui/components/modules/Product/elements'
import ProductPrice from '@pwa-onilab/ui/components/modules/Product/elements/ProductPrice'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import CartRecommendedProductName
    from 'components/overrides/modules/Cart/elements/CartProducts/CartRecommendedProduct/CartRecommendedProductName/CartRecommendedProductName'
import ProductCardImage from 'components/overrides/modules/Product/elements/ProductCardImage/ProductCardImage'
import WishlistButton from 'components/overrides/modules/Product/elements/WishlistButton/WishlistButton'

import styles from './CartRecommendedProduct.module.scss'

interface CartRecommendedProductProps extends IClassName {
    product: Product
}

const CartRecommendedProduct = (
    {
        className,
        product,
    }: CartRecommendedProductProps,
) => {
    if (!product) return null

    const {
        sku,
        name,
        actualPricePerSeed,
        originalPricePerSeed,
        url: productUrl,
        thumbnail: {
            description: imageDescription,
            url: imageSrc,
        } = {},
        labels,
    } = product

    const sendAnalytics = () => {
        ga4SelectItem({
            itemsListName: 'Cart Recommended products',
            product,
        })
    }

    return (
        <div className={classNames(styles.wrapper, className)}>
            <WishlistButton
                className={styles.wishlist}
                isCategoryCard
                sku={sku}
            />
            <ProductBadges data={labels} className={styles.badges} />
            <ProductCardImage
                className={styles.imageWrapper}
                imageClassName={styles.image}
                sku={sku}
                url={productUrl}
                imageSrc={imageSrc}
                imageAlt={imageDescription}
                onClick={sendAnalytics}
            />
            <CartRecommendedProductName
                className={styles.name}
                name={name}
                sku={sku}
                url={productUrl}
                onClick={sendAnalytics}
            />
            <ProductPrice
                actualPricePerSeed={actualPricePerSeed}
                originalPricePerSeed={originalPricePerSeed}
                isProductPricePerSeed
                withFromLabel
            />
        </div>
    )
}

export default CartRecommendedProduct

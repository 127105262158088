import { gql } from '@apollo/client'
import api from '@pwa-concept/core/api'
import { $cart } from '@pwa-concept/core/models'
import { MutationResolvers } from '@pwa-concept/modules/graphql'

const cartSetShippingMethods: MutationResolvers['cartSetShippingMethods'] = async (_, { input }) => {
    const { shippingMethods } = input

    const { data: { setShippingMethodsOnCart: { cart: __context = null } = {} } = {} } = await (
        api.graphql(gql`
            mutation(
                $input: SetShippingMethodsOnCartInput
            ) {
                setShippingMethodsOnCart(input: $input) {
                    cart {
                        ... Cart
                    }
                }
            }
        `).mutation({
            input: {
                cart_id: $cart.id,
                // by default magento get only one shipping, but it can be switched
                shipping_methods: shippingMethods.map((method) => {
                    const shippingMethod = {
                        carrier_code: method.carrierCode,
                        method_code: method.methodCode,
                    }
                    method.shippingAdditionalFields?.forEach(({ key, value }) => {
                        shippingMethod[key] = value
                    })

                    return shippingMethod
                }),
            },
        })
    )

    if (!__context) return null

    return {
        __context,
        __typename: 'Cart',
    }
}

export default cartSetShippingMethods

import { BreadCrumbsPlaceholder } from '@pwa-onilab/ui/components/modules/Breadcrumbs/BreadCrumbsPlaceholder'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { AuthorPageDescriptionPlaceholder, AuthorPagePostsInfo } from 'modules/AuthorPage'

const AuthorPagePlaceholder = () => {
    return (
        <Container>
            <BreadCrumbsPlaceholder />
            <AuthorPageDescriptionPlaceholder />
            <AuthorPagePostsInfo loading />
        </Container>
    )
}

export default AuthorPagePlaceholder

import { IErrorPage } from '@pwa-onilab/ui/components/modules/ErrorPage/ErrorPage'
import { Image } from '@pwa-onilab/ui/components/UI/Image'
import Title from '@pwa-onilab/ui/components/UI/Title'
import previewImage from 'assets/images/404/plant.png'
import { useTranslation } from 'react-i18next'

import styles from './ErrorPageInfo.module.scss'

const ErrorPageInfo = ({ errorText }: IErrorPage) => {
    const { t } = useTranslation()

    return (
        <>
            <Title
                className={styles.title}
                type="h2"
                text={t('global.errors.oops')}
            />
            <Title
                className={styles.subtitle}
                type="h3"
                text={errorText}
            />
            <Image src={previewImage} className={styles.previewImage} />
        </>
    )
}

export default ErrorPageInfo

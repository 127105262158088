import { Resolvers } from '@pwa-concept/modules/graphql'

import Query from './Query'
import State from './State'

const resolvers: Resolvers = {
    Query,
    State,
}

export default resolvers

import { $wishlist } from '@pwa-concept/core/models'
import { IApolloMutationsBaseOptions } from '@pwa-concept/modules/common'
import { useMergeWishlistMutation } from '@pwa-concept/modules/wishlist/graphql/mutations/MergeWishlist'

interface IWishlistMergeData {
    page?: number
    limit?: number
}

const useWishlistMerge = (baseOptions?: IApolloMutationsBaseOptions) => {
    const [mergeWishlistMutation] = useMergeWishlistMutation(baseOptions)

    return async ({ page = 1, limit = 20 }: IWishlistMergeData = {}) => {
        const { data: { mergeWishlists } = {} } = await mergeWishlistMutation({
            variables: {
                id: $wishlist.id,
                page,
                limit,
            },
            ignoreResults: true,
        })

        return {
            data: mergeWishlists,
        }
    }
}

export default useWishlistMerge

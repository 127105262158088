export const getValuesForInputRangeMaxChanges = (event, currentMin) => {
    const value = Number(event.target.value)
    return [
        currentMin,
        Math.max(
            currentMin,
            value,
        ),
    ]
}

import api from '@pwa-concept/core/api'
import { $wishlist } from '@pwa-concept/core/models'
import { MutationResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const mergeWishlists: MutationResolvers['mergeWishlists'] = async (_, input) => {
    const {
        id,
        page,
        limit,
    } = input

    if (!id) {
        throw new Error('Cannot perform operation')
    }

    const { data: { mergeWishlistsOnilab = {} } = {} } = (
        await api.graphql(
            gql`
                mutation(
                    $sourceWishlistUid: String!
                    $pageSize: Int = 20
                    $currentPage: Int = 1
                    $isFullProduct: Boolean = true
                ) {
                    mergeWishlistsOnilab(
                        sourceWishlistUid: $sourceWishlistUid
                    ) {
                        onilab_wishlist {
                            uid
                            items(
                                currentPage: $currentPage
                                pageSize: $pageSize
                            ) {
                                items {
                                    added_at
                                    description
                                    id
                                    product {
                                        ... ProductInterface
                                    }
                                }

                                page_info {
                                    current_page
                                    page_size
                                    total_pages
                                }
                            }
                            items_count
                            sharing_code
                        }
                    }
                }
            `,
        ).mutation({
            sourceWishlistUid: id,
            pageSize: limit,
            currentPage: page,
        })
    )

    if (!mergeWishlistsOnilab) {
        return null
    }

    if (mergeWishlistsOnilab?.onilab_wishlist?.uid) {
        $wishlist.id = mergeWishlistsOnilab.onilab_wishlist.uid
    }

    return {
        __context: mergeWishlistsOnilab,
        __typename: 'WishlistWithErrors',
    }
}

export default mergeWishlists

import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { ENTER_KEY_VALUE } from '@pwa-onilab/ui/constants'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useRouter, useSearchModal } from '@pwa-onilab/ui/hooks'
import { ChangeEventHandler, forwardRef, ReactElement, useEffect, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import styles from './InputSearch.module.scss'

const VARIANTS = {
    default: null,
    modal: styles.modal,
}

type InputSearchOnChangeFn = (value: string) => void

interface InputSearchProps {
    name?: string
    className?: string
    searchIconClassName?: string
    id?: string
    value?: string
    onChange?: InputSearchOnChangeFn | ChangeEventHandler
    onSubmit?: () => void
    onFocus?: () => void
    onBlur?: () => void
    isClean?: boolean
    onClear?: () => void
    label?: string
    isAutoFocus?: boolean
    side?: ReactElement
    variant?: keyof typeof VARIANTS
}

const InputSearch = forwardRef<any, InputSearchProps>(
    (
        {
            name,
            className,
            searchIconClassName,
            id,
            value,
            onChange,
            onSubmit,
            onFocus,
            onBlur,
            isClean,
            isAutoFocus,
            label = 'Search',
            side,
            variant = 'default',
            onClear,
        },
        ref,
    ) => {
        const { t } = useTranslation()

        const { getSearchUrl } = useRouter()
        const { closeSearchModal } = useSearchModal()
        const navigate = useNavigate()

        const inputRef = useRef(null)
        useImperativeHandle(ref, () => inputRef.current)

        useEffect(() => {
            if (isAutoFocus) {
                inputRef.current?.focus?.()
            }
        }, [isAutoFocus, inputRef])

        const onChangeHandler = (ev) => {
            onChange?.(ref ? ev : ev?.target?.value)
        }

        const handleKeyDown = (e) => {
            if (e.key === ENTER_KEY_VALUE && !!value) {
                navigate(getSearchUrl(value))
                closeSearchModal()
            }
        }

        return (
            <div
                className={classNames(
                    className,
                    styles.wrapper,
                    variant && VARIANTS?.[variant],
                )}
                id={id}
                onClick={onSubmit}
                role="button"
                aria-label={t('global.search.open')}
            >
                <Icon
                    name="magnifyingGlass"
                    className={classNames(styles.searchIcon, searchIconClassName)}
                />
                <div className={styles.inputContainer}>
                    <input
                        {...(value !== undefined ? { value } : {})}
                        {...(onChange !== undefined ? { onChange: onChangeHandler } : {})}
                        placeholder={label}
                        required
                        name={name}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        ref={inputRef}
                        className={styles.input}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                {isClean && !!value?.length && (
                    <ButtonInstance
                        className={styles.clean}
                        type="submit"
                        onClick={onClear}
                    >
                        <Icon name="cross" className={styles.cleanIcon} />
                    </ButtonInstance>
                )}
            </div>
        )
    },
)

export { InputSearch }

import { Catalog } from '@pwa-concept/modules/graphql'
import { CATEGORY_PAGE_SIZE } from '@pwa-onilab/ui/components/modules/Category'
import { paginationLoadedPagesSet } from '@pwa-onilab/ui/components/modules/Pagination/paginationInterfaces'
import { Dispatch, SetStateAction } from 'react'

type tPaginationCellClick = (
    setCachedData: Dispatch<SetStateAction<Catalog>>,
    loadedPagesSet: paginationLoadedPagesSet,
    params: {
        page: number
    }
) => void

export const paginationCellClick: tPaginationCellClick = (setCachedData, loadedPagesSet, params) => {
    const { page } = params

    if (!loadedPagesSet.has(page)) {
        loadedPagesSet.clear()
        setCachedData(null)
        return
    }

    const firstPageIndex = loadedPagesSet.values().next().value

    setCachedData(prevData => {
        if (!prevData?.items?.length) {
            return null
        }
        const pageItemStartIndex = (page - firstPageIndex) * CATEGORY_PAGE_SIZE

        loadedPagesSet.clear()
        loadedPagesSet.add(page)

        const newItems = prevData.items.slice(pageItemStartIndex, pageItemStartIndex + CATEGORY_PAGE_SIZE)

        return {
            ...prevData,
            items: newItems,
            pagination: {
                ...prevData.pagination,
                current: page,
            },
        }
    })
}

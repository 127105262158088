import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'

import styles from './SearchList.module.scss'

const SearchListPlaceholder = () => {
    return (
        <div className={styles.wrapper}>
            <Placeholder className={styles.placeholderRow} />
            <Placeholder className={styles.placeholderRow} />
            <Placeholder className={styles.placeholderRow} />
            <Placeholder className={styles.placeholderRow} />
            <Placeholder className={styles.placeholderRow} />
        </div>
    )
}

export default SearchListPlaceholder

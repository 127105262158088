import { IImageProps } from '@pwa-onilab/ui/components/UI/Image'
import { ImageLazy } from '@pwa-onilab/ui/components/UI/Image/ImageLazy'

import styles from './CategoryProductCartImage.module.scss'

const CategoryProductCartImage = (
    {
        src,
        alt,
    }: IImageProps) => {
    return (
        <ImageLazy
            className={styles.image}
            src={src}
            alt={alt}
        />
    )
}

export default CategoryProductCartImage

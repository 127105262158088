import { Resolvers } from '@pwa-concept/modules/graphql'

const CartReorderItemsOutput: Resolvers['CartReorderItemsOutput'] = {
    cart: (_, __, { context, errors: __errors = null }) => ({
        __context: context?.cart || null,
        __errors,
        __typename: 'Cart',
    }),
    userInputErrors: (_, __, { context }) => context?.userInputErrors || [],
}

export default CartReorderItemsOutput

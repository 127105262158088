import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren, ReactElement } from 'react'

import styles from './MobileMenuModal.module.scss'

interface MobileMenuModalProps {
    header?: ReactElement
    className?: string
    root?: HTMLElement
}

const MobileMenuModal = ({ children, className, header, root }: PropsWithChildren<MobileMenuModalProps>) => {
    return (
        <Modal
            isOpen
            header={header}
            isUseBorderRadius={false}
            className={classNames(styles.modalWrapper, className)}
            isMobileScrollableMain
            root={root}
        >
            {children}
        </Modal>
    )
}

export default MobileMenuModal

import { DRIP_INIT_CODES, dripInitCode } from "./stateMachine/dripInitStateMachine";

const insertDripScript = (dripID: string) => {
    const dripScript = document.createElement('script');
    dripScript.type = 'text/javascript';
    dripScript.async = true;
    dripScript.src = `https://tag.getdrip.com/${dripID}.js`;
    dripInitCode(DRIP_INIT_CODES.LOADING)

    dripScript.onload = () => {
        dripInitCode(DRIP_INIT_CODES.LOADED)
    }
    dripScript.onerror = () => {
        dripInitCode(DRIP_INIT_CODES.FAILED)
        for (let i = 0; i < window._dcq.length; i += 1) {
            delete window._dcq[i]
        }
        window._dcq.length = 0

        for (let member in window._dcs) {
            delete window._dcs[member]
        }
    }

    const script = document.getElementsByTagName('script')[0];
    script.parentNode.insertBefore(dripScript, script);
}

export function initDrip(dripID: string) {
    if (!dripID || dripInitCode()) {
        return null
    }

    window._dcq = window._dcq || [];

    window._dcs = window._dcs || {};
    window._dcs.account = dripID

    insertDripScript(dripID)
}

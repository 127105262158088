import { memo } from 'react'

import { MOBILE_LIST_OF_POLICY_LINKS_DATA, MobileListOfPolicyLink } from '@pwa-onilab/ui/components/modules/Footer'
import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'

import styles from './MobileListOfPolicyLinks.module.scss'

const MobileListOfPolicyLinks = () => {
    const { isBreakpointUp } = useMediaBreakpoint()

    if (isBreakpointUp('xs')) return null

    return (
        <div className={styles.wrapper}>
            {MOBILE_LIST_OF_POLICY_LINKS_DATA.map(({ title, link }) => (
                <MobileListOfPolicyLink
                    key={link}
                    link={link}
                    title={title}
                    className={styles.link}
                />
            ))}
        </div>
    )
}

export default memo(MobileListOfPolicyLinks)

import { BreadCrumbsPlaceholder } from '@pwa-onilab/ui/components/modules/Breadcrumbs/BreadCrumbsPlaceholder'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { LocationCardPlaceholder } from 'modules/WorldPage'

import styles from './WorldPage.module.scss'

const WorldPagePlaceholder = () => {
    const arr = new Array(18).fill(1)
    return (
        <Container className={styles.wrapper}>
            <BreadCrumbsPlaceholder />
            <ul className={styles.list}>
                {arr.map((item, idx) => {
                    return (
                        <li key={idx}>
                            <LocationCardPlaceholder />
                        </li>
                    )
                })}
            </ul>
        </Container>
    )
}

export default WorldPagePlaceholder

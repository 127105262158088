import { StoreConfigNotFoundPageUrl } from '@pwa-concept/modules/graphql'
import {
    ErrorPageNavListPlaceholder,
} from '@pwa-onilab/ui/components/modules/ErrorPage'
import { IErrorPage } from '@pwa-onilab/ui/components/modules/ErrorPage/ErrorPage'
import ErrorPageNavigationList
    from 'components/overrides/modules/ErrorPage/components/ErrorPageNavigationList/ErrorPageNavigationList'

import styles from './ErrorPageNavigationButtons.module.scss'

interface IErrorPageNavigationButtons extends Pick<IErrorPage, 'onLinksClick'>{
    isLoading: boolean
    nodes: StoreConfigNotFoundPageUrl[]
}
const ErrorPageNavigationButtons = ({ isLoading, nodes, onLinksClick }: IErrorPageNavigationButtons) => {
    if (isLoading) {
        return (
            <div className={styles.wrapper}>
                <ErrorPageNavListPlaceholder />
                <ErrorPageNavListPlaceholder />
            </div>
        )
    }
    if (!nodes?.length) {
        return null
    }

    const half = nodes.length ? Math.ceil(nodes.length / 2) : 0

    const firstHalf = Array.from({ length: half }, (_, index) => nodes[index])
    const secondHalf = Array.from({ length: nodes.length - half }, (_, index) => nodes[half + index])

    return (
        <div className={styles.wrapper}>
            <ErrorPageNavigationList nodes={firstHalf} onLinksClick={onLinksClick} />
            <ErrorPageNavigationList nodes={secondHalf} onLinksClick={onLinksClick} />
        </div>
    )
}

export default ErrorPageNavigationButtons

import { LeftPositionModal } from '@pwa-onilab/ui/components/elements/Modals'
import {
    FilterModalContent,
    FilterModalFooter,
    FilterModalHeader,
} from '@pwa-onilab/ui/components/modules/Filters'
import { IFilterControls } from '@pwa-onilab/ui/components/modules/Filters/components/FilterControls/IFilterControls'
import { classNames } from '@pwa-onilab/ui/helpers'

import css from './FilterModal.module.scss'

interface FilterModalProps extends IFilterControls {
    isOpen: boolean
    onClose: () => void
}

const FilterModal = (
    {
        className,
        isOpen,
        onClose,
        tags,
        filters,
        onChangeValue,
        currentValues,
        onToggleOption,
        isOptionChecked,
        numResults,
    }: FilterModalProps,
) => {
    const footer = (
        <FilterModalFooter
            onClose={onClose}
            numResults={numResults}
        />
    )

    const header = (
        <FilterModalHeader
            onClose={onClose}
            tags={tags}
        />
    )

    return (
        <LeftPositionModal
            className={classNames(css.filterModal, className)}
            wrapperClassName={css.contentWrapper}
            mainClassName={css.main}
            headerClassName={css.header}
            footerClassName={css.footer}
            isOpen={isOpen}
            onDismiss={onClose}
            isDraggable
            isOverAll
            header={header}
            footer={footer}
        >
            <FilterModalContent
                filters={filters}
                onChangeValue={onChangeValue}
                currentValues={currentValues}
                onToggleOption={onToggleOption}
                isOptionChecked={isOptionChecked}
                numResults={numResults}
            />
        </LeftPositionModal>
    )
}

export default FilterModal

export const COLOR_KEY = 'color'
export const CATEGORY_KEY = 'category_id'
export const ONLY_IN_STOCK = 'only_in_stock'

export const FILTER_TYPES = {
    CATEGORY_KEY: 'category_id',
    PRICE_KEY: 'price',
    CBD_KEY: 'cbd',
    FLOWERING_TIME_WEEKS_KEY: 'flowering_time_weeks',
    HIGH_YIELD_KEY: 'high_yield',
    PLANT_HEIGHT_KEY: 'plant_height',
    TERPENES_KEY: 'terpenes',
    THC_KEY: 'thc',
    FOR_BEGINNERS_KEY: 'for_beginners',
    SEEDS_TYPE_KEY: 'seeds_type',
    EFFECTS_KEY: 'effects',
    SIP_VALUE_KEY: 'sipvalue',
}

export const ONE_COLUMN_CATEGORY_KEYS = {}

export const TWO_COLUMNS_CATEGORY_KEYS = {}

export const THREE_COLUMNS_CATEGORY_KEYS = {
    technology: 'technology',
    inches: 'inches',
    diameter: 'diameter',
    position: 'position',
    capacity: 'capacity',
    package: 'package',
    size: 'size',
    color: 'color',
}

import { SearchList } from '@pwa-onilab/ui/components/modules/Search'
import { ISearchCategory } from '@pwa-onilab/ui/components/modules/Search/hooks/ISearchCategory'
import { IBlogPost } from 'modules/Blog/interfaces'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface ISearchBlogPostsList {
    posts: IBlogPost[]
}

const SearchBlogPostsList = ({ posts }: ISearchBlogPostsList) => {
    const { t } = useTranslation()

    const searchListItems: ISearchCategory[] = useMemo(() => {
        return posts.map((item, idx) => {
            return {
                key: idx,
                to: item.postUrl,
                name: item.title,
            }
        })
    }, [posts])

    return (
        <SearchList
            title={t('search.posts')}
            items={searchListItems}
        />
    )
}

export default SearchBlogPostsList

import { Image } from '@pwa-onilab/ui/components/UI/Image'
import { LinkArrow } from 'components/UI/LinkArrow'
import Parser, { classNames } from '@pwa-onilab/ui/helpers'
import { addSlashToEndUrl } from 'helpers'

import styles from './Banner.module.scss'

interface IBannerProps {
    description: string
    imageUrl: string
    title: string
    url: string
    urlText: string
    className?: string
}

const Banner = (
    {
        description,
        imageUrl,
        title,
        url,
        urlText,
        className,
    }: IBannerProps,
) => (
    <div className={classNames(styles.wrapper, className)}>
        <div className={styles.content}>
            {
                title && (
                    <div className={styles.title}>
                        {title}
                    </div>
                )
            }
            {
                description && (
                    <div className={styles.description}>
                        <Parser content={description} />
                    </div>
                )
            }
            {
                url && urlText && (
                    <LinkArrow
                        to={addSlashToEndUrl(url)}
                        withArrow
                    >
                        {urlText}
                    </LinkArrow>
                )
            }

        </div>
        <div className={styles.imageWrapper}>
            <Image
                src={imageUrl}
                className={styles.image}
            />
        </div>
    </div>
)

export default Banner

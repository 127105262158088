export const getValuesForInputRangeMinChanges = (event, currentMax) => {
    const value = Number(event.target.value)
    return [
        Math.min(
            currentMax,
            value,
        ),
        currentMax,
    ]
}

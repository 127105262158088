import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { SYMBOL_SLASH } from 'constants/common'
import { addSlashToEndUrl } from 'helpers'

const useGetAuthorUrl = () => {
    const { data: { blogAuthorUrlKey } = {} } = useStoreConfig()

    return (authorUrlKey: string) => {
        return addSlashToEndUrl(SYMBOL_SLASH + [blogAuthorUrlKey, authorUrlKey].filter(Boolean).join(SYMBOL_SLASH))
    }
}

export default useGetAuthorUrl

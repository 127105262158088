import { ButtonInstance, IButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { PropsWithChildren } from 'react'

interface IPaginationCell extends IButtonInstance, IClassName {
    isActive?: boolean
    activeClassName?: string
}

const PaginationCell = (
    {
        children,
        isActive,
        className,
        activeClassName,
        ...buttonProps
    }: PropsWithChildren<IPaginationCell>) => {
    const classes = classNames(
        className,
        isActive && activeClassName,
    )

    return (
        <ButtonInstance {...buttonProps} className={classes}>
            {children}
        </ButtonInstance>
    )
}

export default PaginationCell

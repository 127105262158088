import { WishlistCountItems, WishlistShareViaEmail, WishlistTitle } from '@pwa-onilab/ui/components/modules/Wishlist'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './WishlistTopBar.module.scss'

interface WishlistTopBarProps {
    className: string
    numAllItems: number
}

const WishlistTopBar = (
    {
        className,
        numAllItems,
    }: Partial<WishlistTopBarProps>,
) => (
    <div className={classNames(styles.wrapper, className)}>
        <WishlistTitle />
        <WishlistCountItems
            count={numAllItems}
            className={styles.countItems}
        />
        <WishlistShareViaEmail />
    </div>
)

export default WishlistTopBar

import {
    MediaBreakpointDown,
    MediaBreakpointUp,
    useMediaBreakpoint,
} from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import useModalStates from '@pwa-onilab/ui/hooks/modals/useModalStates'
import { useCallback } from 'react'

import styles from './FilterGroupButtonTooltip.module.scss'

interface IFilterGroupButtonTooltipProps {
    description: string
}

const FilterGroupButtonTooltip = (
    {
        description,
    }: IFilterGroupButtonTooltipProps,
) => {
    const isDesktop = useMediaBreakpoint().isBreakpointUp('lg')

    const {
        isModalOpen: isMobileModalOpen,
        closeModal: closeMobileModal,
        openModal: openMobileModal,
    } = useModalStates()

    if (!description) {
        return null
    }

    const openMobileModalHandle = useCallback(() => {
        if (isDesktop) {
            return null
        }

        openMobileModal()
    }, [])

    return (
        <div className={styles.tooltip}>
            <ButtonInstance
                onClick={openMobileModalHandle}
                className={styles.iconWrapper}
            >
                <Icon
                    name="informer"
                    className={styles.icon}
                />
            </ButtonInstance>
            <MediaBreakpointDown name="lg">
                <Modal
                    isOpen={isMobileModalOpen}
                    onDismiss={closeMobileModal}
                    isDraggable
                    isOverAll
                >
                    <div className={styles.mobileContentWrapper}>
                        {description}
                    </div>
                </Modal>
            </MediaBreakpointDown>
            <MediaBreakpointUp name="lg">
                <div className={styles.contentWrapper}>
                    <div className={styles.content}>
                        {description}
                    </div>
                </div>
            </MediaBreakpointUp>
        </div>
    )
}

export default FilterGroupButtonTooltip

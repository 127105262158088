import { useCartSetPaymentMethodMutation } from '@pwa-concept/modules/cart/graphql/mutations/CartSetPaymentMethod'
import { PaymentMethodInput } from '@pwa-concept/modules/graphql'

const useCartSetPaymentMethod = () => {
    const [setPaymentMethod] = useCartSetPaymentMethodMutation()

    return async (paymentMethod: PaymentMethodInput) => {
        return await setPaymentMethod({
            variables: {
                input: {
                    paymentMethod,
                },
            },
            ignoreResults: true,
        })
    }
}

export default useCartSetPaymentMethod

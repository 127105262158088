// if you will change ADDRESS_FORM_FIELDS don't forget change ADDRESS_FORM_FIELDS_TYPE too

export enum ADDRESS_FORM_FIELDS {
    CITY = 'city',
    COMPANY = 'company', // TODO
    COUNTRY_CODE = 'countryCode',
    USE_AS_DEFAULT_BILLING = 'defaultBilling',
    USE_AS_DEFAULT_SHIPPING = 'defaultShipping',
    FULL_NAME = 'fullName',
    POST_CODE = 'postcode',
    REGION = 'region',
    REGION_ID = 'regionId',
    STREET_ONE = 'street1',
    STREET_TWO = 'street2',
    TELEPHONE = 'telephone',
    VAT_ID = 'vatId',
}

export interface ADDRESS_FORM_FIELDS_TYPE {
    city: string
    company: string
    countryCode: string
    defaultBilling: boolean
    defaultShipping: boolean
    fullName: string
    postcode: string
    region: string
    regionId: number
    street1: string
    street2: string
    telephone: string
    vatId: string
}

import Container from '@pwa-onilab/ui/components/UI/Container'
import Parser, { classNames } from '@pwa-onilab/ui/helpers'
import IMenuObjData from 'components/overrides/modules/Menu/IMenuObjData'
import { DropdownMenuList, MENU_NODE_CMS_BLOCK_TYPE } from 'modules/Menu'
import { Dispatch } from 'react'

import styles from './DropdownMenu.module.scss'

interface IDropdownMenuProps {
    className?: string
    activeCategory: IMenuObjData
    setActiveCategory: Dispatch<IMenuObjData>
}

const DropdownMenu = (
    {
        className,
        activeCategory,
        setActiveCategory,
    }: IDropdownMenuProps,
) => {
    if (!activeCategory?.items?.length) {
        return null
    }

    let cmsBlockContent = null

    const closeDropDownMenu = () => setActiveCategory(null)

    return (
        <div
            className={classNames(styles.dropDown, className)}
            onMouseLeave={closeDropDownMenu}
        >
            <Container className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.listWrapper}>
                        {
                            activeCategory.items.map((
                                {
                                    title,
                                    url,
                                    items,
                                    nodeId,
                                    type,
                                    content,
                                }) => {
                                if (type === MENU_NODE_CMS_BLOCK_TYPE && !!content?.content) {
                                    cmsBlockContent = content.content

                                    return null
                                }

                                return (
                                    <DropdownMenuList
                                        key={nodeId}
                                        items={items}
                                        parentUrl={url}
                                        parentTitle={title}
                                        type={type}
                                    />
                                )
                            })
                        }
                    </div>
                    {
                        !!cmsBlockContent && (
                            <div className={styles.banner}>
                                <Parser content={cmsBlockContent} />
                            </div>
                        )
                    }
                </div>
                {/* TODO */}
                {/* until the link is known, temporarily hidden */}
                {/* <DropdownMenuViewAll */}
                {/*    className={styles.viewAllButton} */}
                {/*    onClick={onCloseMenuHandler} */}
                {/* /> */}
            </Container>
        </div>
    )
}

export default DropdownMenu

import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IHelpText } from 'modules/Help/elements/HelpText/IHelpText'

import styles from './HelpText.module.scss'

const HelpText = ({ text, className, isLoading }: IHelpText) => {
    return (
        <p className={classNames(styles.text, className)}>
            {
                isLoading
                    ? <Placeholder className={styles.placeholderSizes} />
                    : text
            }
        </p>
    )
}

export default HelpText

import { ITitle } from '@pwa-onilab/ui/components/UI/Title'
import { classNames } from '@pwa-onilab/ui/helpers'
import { StrainsForSaleAlphabetList, StrainsForSaleAlphabetTitle } from 'modules/StrainsForSale'

import styles from './StrainsForSaleAlphabet.module.scss'

interface IStrainsForSaleAlphabetProps {
    className?: string
    titleText: ITitle['text']
}

const StrainsForSaleAlphabet = (
    {
        className,
        titleText,
    }: IStrainsForSaleAlphabetProps,
) => (
    <div className={classNames(styles.wrapper, className)}>
        <StrainsForSaleAlphabetTitle text={titleText} />
        {/*<StrainsForSaleAlphabetList />*/}
    </div>
)

export default StrainsForSaleAlphabet

import { useSliderContext } from '@pwa-onilab/ui/components/UI/Slider/providers'
import { classNames } from '@pwa-onilab/ui/helpers'
import { memo, useEffect, useRef } from 'react'
import { PaginationOptions } from 'swiper/types'

import styles from './SliderPagination.module.scss'

const VARIANTS = {
    default: undefined,
}

interface SliderPaginationProps extends Pick<PaginationOptions, 'clickable' | 'dynamicBullets' | 'dynamicMainBullets' | 'hideOnClick'> {
    className?: string
    variant?: keyof typeof VARIANTS
}

const SliderPagination = memo((
    {
        clickable = true,
        dynamicBullets,
        dynamicMainBullets = 1,
        hideOnClick,
        className,
        variant = 'default',
    }: SliderPaginationProps,
) => {
    const wrapperRef = useRef(null)
    const { slider } = useSliderContext()

    useEffect(() => {
        if (wrapperRef.current && slider?.mounted && (slider?.params?.pagination as PaginationOptions)?.el !== wrapperRef.current) {
            try {
                const pagination = slider.params.pagination as PaginationOptions
                pagination.clickable = clickable
                pagination.hideOnClick = hideOnClick
                pagination.el = wrapperRef.current
                pagination.bulletClass = styles.bullet
                pagination.bulletActiveClass = styles.isActive
                pagination.clickableClass = styles.isClickable
                pagination.dynamicBullets = dynamicBullets
                pagination.dynamicMainBullets = dynamicMainBullets
                pagination.hiddenClass = styles.isHidden

                if (!slider.pagination?.el && slider?.initialized) {
                    slider?.pagination?.init?.()
                    slider?.pagination?.render?.()
                    slider?.pagination?.update?.()
                }
            } catch (e) {
                //
            }
        }

        return () => {
            if (slider?.params?.pagination && slider?.initialized) {
                try {
                    slider?.pagination?.destroy?.()
                } catch (e) {
                    //
                }
            }
        }
    }, [clickable, dynamicBullets, dynamicMainBullets, hideOnClick, slider])

    return (
        <div
            ref={wrapperRef}
            className={classNames(
                styles.wrapper,
                className,
                (variant && VARIANTS?.[variant]) || VARIANTS.default,
            )}
        />
    )
})

export { SliderPagination }

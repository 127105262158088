import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import styles from './FilterGroupContent.module.scss'

interface FilterGroupContentProps {
    className?: string
}

const FilterGroupContent = (
    {
        children,
        className,
    }: PropsWithChildren<FilterGroupContentProps>,
) => (
    <div className={classNames(className)}>
        {children}
    </div>
)

export default FilterGroupContent

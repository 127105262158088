export const REGEX_EMAIL = /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/

export const MULTIPLE_EMAILS_SEPARATOR: string | RegExp = /[,\s;]/

export const REGEX_NAME = /[^\s]{1,}\s[^\s]{1,}/igm
export const REGEX_FULL_NAME_VALIDATION = /[^\s]{2,}\s[^\s]{2,}/igm

export const REGEX_NOT_DIGITS = /\D/
export const REGEX_DIGITS = /\d+/
export const REGEX_LETTERS_UPPERCASE = /[A-Z]+/
export const REGEX_LETTERS_LOWERCASE = /[a-z]+/
export const REGEX_NOT_LETTERS_AND_DIGIT = /[\W]+/

export const REGEX_SPACE = /\s/

export const REGEX_NO_PHOTO = /\/\.(svg|jpe?g|png|webp)$/igm

export const REGEX_HTML_TAG = /<(“[^”]*”|'[^’]*’|[^'”>])*>/g
export const REGEX_TELEPHONE_ALLOWED_CHAR = /^(\+?[\d-*#,()\s;]*)$/

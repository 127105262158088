import { CartItems } from '@pwa-concept/modules/graphql'
import { ga4SelectItem } from '@pwa-onilab/ga4/operations/product/selectItem'
import { IAnchor } from '@pwa-onilab/ui/components/UI/Anchor/IAnchor'
import { IN_STOCK } from '@pwa-onilab/ui/constants'
import { IOpenDeleteItemModal } from '@pwa-onilab/ui/hooks/modals/useDeletingItemModal'
import CartDefaultProductInStock
    from 'components/overrides/modules/Cart/elements/CartProducts/CartDefaultProduct/CartDefaultProductInStock/CartDefaultProductInStock'
import CartDefaultProductOutOfStock
    from 'components/overrides/modules/Cart/elements/CartProducts/CartDefaultProduct/CartDefaultProductOutOfStock/CartDefaultProductOutOfStock'
import { memo, useCallback } from 'react'

export interface ICartStateApi {
    openDeleteModal: (_: IOpenDeleteItemModal) => () => void
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export interface ICartProductProps {
    cartItem: CartItems // yeah, in theme now it is plural
    className?: string
    isLoading: boolean
    index?: number
    isShowGoToCheckoutError?: boolean
    onLinkClick: IAnchor['onClick']
}

const CartDefaultProduct = (
    {
        cartItem,
        className,
        openDeleteModal,
        setIsLoading,
        isLoading,
        index,
        isShowGoToCheckoutError,
    }: Omit<ICartProductProps & ICartStateApi, 'onLinkClick'>) => {
    const isInStock = (cartItem.configuredVariant || cartItem.product).stock.type === IN_STOCK

    const onLinkClick = useCallback(() => {
        ga4SelectItem({
            itemsListName: 'Cart Items',
            product: cartItem.product,
            configuredVariant: cartItem.configuredVariant,
        })
    }, [cartItem])

    if (isInStock) {
        return (
            <CartDefaultProductInStock
                className={className}
                cartItem={cartItem}
                openDeleteModal={openDeleteModal}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                index={index}
                onLinkClick={onLinkClick}
            />
        )
    }

    return (
        <CartDefaultProductOutOfStock
            className={className}
            cartItem={cartItem}
            openDeleteModal={openDeleteModal}
            index={index}
            isShowGoToCheckoutError={isShowGoToCheckoutError}
            onLinkClick={onLinkClick}
        />
    )
}

export default memo(CartDefaultProduct)

import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useCallback, useState } from 'react'
import Parser from '@pwa-onilab/ui/helpers'

import styles from './FaqQuestionsAccordionItem.module.scss'

interface IFaqQuestionsAccordionItemProps {
    answer: string
    question: string
    className?: string
}

const FaqQuestionsAccordionItem = (
    {
        answer,
        question,
        className,
    }: IFaqQuestionsAccordionItemProps,
) => {
    const [isActive, setActive] = useState(false)

    const handleToggle = useCallback(() => {
        setActive(state => !state)
    }, [])

    return (
        <div className={classNames(styles.wrapper, className)}>
            <div
                className={styles.title}
                onClick={handleToggle}
            >
                <span>{question}</span>
                <Icon
                    name={isActive ? 'minus' : 'plus'}
                    className={styles.icon}
                />
            </div>
            {
                isActive && (
                    <div className={styles.content}>
                        <Parser content={answer} />
                    </div>
                )
            }
        </div>
    )
}

export default FaqQuestionsAccordionItem

import {
    IGa4EcommerceProduct
} from "@pwa-onilab/ga4/helpers/product/extractGa4EcommerceProduct";
import { extractGa4EcommerceProductObject } from "@pwa-onilab/ga4/helpers/product/extractGa4EcommerceProductObject";
import ReactGA4 from "react-ga4";
import { GA4_OPERATIONS } from "../../constants/ga4Operations";

export const ga4AddToWishlist = (props: IGa4EcommerceProduct) => {
    ReactGA4.gtag('event', GA4_OPERATIONS.addToWishlist, extractGa4EcommerceProductObject(props))
}

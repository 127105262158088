import { useCart } from '@pwa-concept/modules/cart'
import { IUseCartProps } from '@pwa-concept/modules/cart/features/get/hooks/useCart'

const useCartShippingMethods = ({ input = {}, options = {} }: IUseCartProps = {}) => {
    const { data, loading } = useCart(input, options)

    return {
        data: {
            availableShippingMethods: data?.shippingAddresses?.[0].availableShippingMethods || undefined,
            selectedShippingMethod: data?.shippingAddresses?.[0].selectedShippingMethod || undefined,
        },
        loading,
    }
}

export default useCartShippingMethods

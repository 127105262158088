import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { Copyright } from '@pwa-onilab/ui/components/modules/Footer'
import Container from '@pwa-onilab/ui/components/UI/Container'
import PaymentMethods from 'components/overrides/modules/Footer/elements/PaymentMethods/PaymentMethods'
import { memo } from 'react'

import styles from './FooterBottom.module.scss'

const FooterBottom = () => (
    <div className={styles.footerBottom}>
        <Container className={styles.container}>
            <PaymentMethods />
            <MediaBreakpointUp name="lg">
                <Copyright />
            </MediaBreakpointUp>
        </Container>
    </div>
)

export default memo(FooterBottom)

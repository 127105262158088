import { useFiltersQuery } from '@pwa-concept/modules/filter/graphql/queries/Filters'
import { FiltersQueryInput } from '@pwa-concept/modules/graphql'

interface UseFiltersOptions {
    skip?: boolean
}

const useFilters = (input?: FiltersQueryInput, options?: UseFiltersOptions) => {
    const {
        data: { filters: data = [] } = {},
        loading,
        previousData: { filters: prevFilters = [] } = {},
    } = useFiltersQuery({
        variables: {
            input,
        },
        skip: options?.skip,
    })

    return {
        data: loading ? prevFilters : data,
        loading,
    }
}

export default useFilters

import { useMenuNodeLinkContext } from '@pwa-onilab/ui/components/modules/Menu'
import { Link } from '@pwa-onilab/ui/components/UI/Link'
import { useRouter } from '@pwa-onilab/ui/hooks'
import { PropsWithChildren } from 'react'

interface MenuNodeLinkProps {
    node?: object
    className?: string
}

const MenuNodeLink = (
    {
        node,
        className,
        children,
    }: PropsWithChildren<MenuNodeLinkProps>,
) => {
    const context = useMenuNodeLinkContext()

    const { getMenuNodeUrl } = useRouter()
    const url = getMenuNodeUrl(node)

    const onClickHandler = () => {
        context?.onLinkClick?.()
    }

    return (
        url?.isExternal ? (
            <a
                target={url?.isTargetBlank ? '_blank' : null}
                className={className}
                onClick={onClickHandler}
                rel={url?.isTargetBlank ? 'noreferrer noopener' : null}
                href={url?.to}
            >
                {children}
            </a>
        ) : (
            <Link
                target={url?.isTargetBlank ? '_blank' : null}
                rel={url?.isTargetBlank ? 'noreferrer noopener' : null}
                to={url?.to || '/'}
                onClick={onClickHandler}
                className={className}
            >
                {children}
            </Link>
        )
    )
}

export default MenuNodeLink

import { APOLLO_ERROR_TYPES } from '@pwa-concept/modules/constants'
import { PersonalInfoNewPassword, PersonalInfoPasswordField } from '@pwa-onilab/ui/components/modules/Account'
import { useTranslation } from 'react-i18next'

import styles from './PasswordChangeFields.module.scss'

interface IPasswordChangeFields {
    openOtpResetModal: () => void
    errors?: object
}

const PasswordChangeFields = (
    {
        openOtpResetModal,
        errors,
    }: IPasswordChangeFields) => {
    const { t } = useTranslation()

    const isShowRequestOtpModal = !!(errors['password']?.type === APOLLO_ERROR_TYPES.INCORRECT_PASSWORD)

    return (
        <>
            <PersonalInfoPasswordField />
            {
                isShowRequestOtpModal && (
                    <div className={styles.errorPassword}>
                        <span>{t('global.password.forgot')}</span>
                        <span onClick={openOtpResetModal} className={styles.errorPasswordAction} aria-hidden>
                            {t('global.password.requestOTP')}
                        </span>
                    </div>
                )
            }
            <PersonalInfoNewPassword />
        </>
    )
}

export default PasswordChangeFields

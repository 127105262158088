import { toast } from "react-toastify";
import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button } from '@pwa-onilab/ui/components/UI/Button'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { PROMO_CODE_FIELD } from '@pwa-onilab/ui/constants/formFields'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useCoupon } from '@pwa-onilab/ui/hooks'
import useCartCouponCode from '@pwa-onilab/ui/hooks/Cart/useCartCouponCode'

import styles from './CartCouponCode.module.scss'

export interface CouponeCodeProps {
    className?: string
    titleClassName?: string
    setIsCartLoading?: (isLoading: boolean) => void
}

const CartCouponCode = (
    {
        className,
        titleClassName,
        setIsCartLoading,
    }: CouponeCodeProps,
) => {
    const { data: settedCouponCode } = useCartCouponCode()
    const { t } = useTranslation()

    const { handleSubmit, register, formState: { isDirty }, reset, setValue } = useForm({
        defaultValues: {
            [PROMO_CODE_FIELD]: settedCouponCode || '',
        },
    })

    const handleCouponActionError = useCallback((error: Error) => {
        toast.error(error.message)
    }, [])

    const {
        handleApplyCouponToCart,
        handleRemoveCouponFromCart,
        isCouponSetted,
        isLoading,
    } = useCoupon(settedCouponCode, reset, setValue, handleCouponActionError)

    const { onChange } = useMemo(() => {
        return register(PROMO_CODE_FIELD)
    }, [])

    useEffect(() => {
        setIsCartLoading?.(isLoading)
    }, [isLoading])

    const formSubmitHandler = useCallback(async ({ code }) => {
        if (code.length) {
            await handleApplyCouponToCart(code, () => toast.success(t('coupon.applied')))
        }
    }, [])

    const couponInputChangeHandler = useCallback(async (e) => {
        await onChange(e)
        await handleRemoveCouponFromCart(e, true, () => toast.success(t('coupon.removed')))
    }, [])

    const couponClearField = useCallback(async (e) => {
        await handleRemoveCouponFromCart(e, true, () => toast.success(t('coupon.removed')))
    }, [])

    return (
        <div className={classNames(styles.wrapper, className)}>
            <p className={classNames(styles.title, titleClassName)}>{t('promoCode.title')}</p>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <form className={styles.form} onSubmit={handleSubmit(formSubmitHandler)}>
                <div className={classNames(styles.inputWrapper, isCouponSetted && styles.inputWrapperSetted)}>
                    <Icon name="tick" className={styles.tick} />
                    <input
                        className={styles.input}
                        placeholder={t('promoCode.placeholder')}
                        {...register(PROMO_CODE_FIELD)}
                        onChange={couponInputChangeHandler}
                        disabled={isLoading}
                    />
                    <ButtonInstance onClick={couponClearField} className={styles.removeCouponButton}>
                        <Icon
                            name="cross"
                            className={styles.crossIcon}
                        />
                    </ButtonInstance>
                </div>
                <Button
                    isPrimary
                    isBold
                    className={styles.button}
                    type="submit"
                    disabled={!isDirty || isLoading}
                >
                    {
                        isCouponSetted
                            ? t(isLoading ? 'buttons.removing' : 'promoCode.applied')
                            : t(isLoading ? 'global.applying' : 'global.apply')
                    }
                </Button>

            </form>
        </div>
    )
}

export default CartCouponCode

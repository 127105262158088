import {
    AddressFormNameField,
    AddressFormPhoneField,
} from '@pwa-onilab/ui/components/modules/AddressForm'

interface IPersonalInfoSection {
    className?: string
}

const PersonalInfoSection = ({ className }: IPersonalInfoSection) => (
    <div className={className}>
        <AddressFormNameField />
        <AddressFormPhoneField />
    </div>
)

export default PersonalInfoSection

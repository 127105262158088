import { useCartRemoveCouponCodeMutation } from '@pwa-concept/modules/cart/graphql/mutations/CartRemoveCouponCode'

const useCartRemoveCouponCode = () => {
    const [removeCoupon] = useCartRemoveCouponCodeMutation()

    return async () => {
        return await removeCoupon({
            ignoreResults: true,
        })
    }
}

export default useCartRemoveCouponCode

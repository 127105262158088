import { ModalConfirmFormClose } from '@pwa-onilab/ui/components/modules/ModalConfirmFormClose'
import { formSaveBeforeHideModal } from '@pwa-onilab/ui/models'
import { PropsWithChildren, useEffect } from 'react'

const FormCloseConfirmDriver = ({ children }: PropsWithChildren<any>) => {
    useEffect(() => {
        formSaveBeforeHideModal.activateModel()
    }, [])

    return (
        <>
            {children}
            <ModalConfirmFormClose />
        </>
    )
}

export default FormCloseConfirmDriver

import Container from '@pwa-onilab/ui/components/UI/Container'
import { Section } from '@pwa-onilab/ui/components/UI/Section'
import { filteringByObjects } from '@pwa-onilab/ui/helpers'
import { DisablePageBuilderStyles } from 'elements/DisablePageBuilderStyles'
import { FaqQuestionsAccordionItem } from 'modules/FaqQuestions'

import styles from './FaqItems.module.scss'

interface IFaqItemsProps {
    data: {
        index: {
            title: string
            description: string
        }
        widget_type: string
    }
}

const FaqItems = (
    data: IFaqItemsProps,
) => {
    const faqContent = filteringByObjects(data)

    if (!faqContent?.length) {
        return null
    }

    return (
        <Section className={styles.section}>
            <DisablePageBuilderStyles>
                <Container>
                    <div className={styles.wrapper}>
                        {
                            faqContent.map(({ title, description }) => (
                                <FaqQuestionsAccordionItem
                                    key={title}
                                    answer={description}
                                    question={title}
                                    className={styles.item}
                                />
                            ))
                        }
                    </div>
                </Container>
            </DisablePageBuilderStyles>
        </Section>
    )
}

export default FaqItems

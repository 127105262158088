import { makeVar, useReactiveVar } from '@apollo/client'

const isSearchModalOpen = makeVar(false)

const useIsSearchModalOpen = () => useReactiveVar<boolean>(isSearchModalOpen)

const openSearchModal = () => isSearchModalOpen(true)
const closeSearchModal = () => isSearchModalOpen(false)

const useSearchModal = () => {
    return {
        useIsSearchModalOpen,
        openSearchModal,
        closeSearchModal,
    }
}

export default useSearchModal

import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const getState: QueryResolvers['getState'] = async (_, { stateId, urlKey }) => {
    const { data: { getState: __context } = {} } = await (
        api.graphql(
            gql`
                query($stateId: Int, $urlKey: String) {
                    getState(state_id: $stateId, url_key: $urlKey) {
                        ... State
                        description
                        products {
                            items {
                                id
                                sku
                            }
                        }
                    }
                }
            `,
        ).query({
            stateId,
            urlKey,
        })
    )

    return {
        __context,
        __typename: 'State',
    }
}

export default getState

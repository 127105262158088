import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';
import { StoreConfigFragmentDoc } from '../../fragments/StoreConfigClientFragment';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type StoreConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type StoreConfigQuery = { __typename?: 'Query', storeConfig?: { __typename?: 'StoreConfig', id?: number | null, productReviewsEnabled?: boolean | null, passwordMinLength?: number | null, passwordMinStrength?: number | null, maxNameLength?: number | null, maxAddressLineLength?: number | null, baseCurrencyCode?: string | null, termsOfDelivery?: string | null, productReturns?: string | null, logoWidth?: number | null, logoHeight?: number | null, defaultTitle?: string | null, locale?: string | null } | null };


export const StoreConfigDocument = gql`
    query storeConfig {
  storeConfig @client {
    ...StoreConfig
  }
}
    ${StoreConfigFragmentDoc}`;

/**
 * __useStoreConfigQuery__
 *
 * To run a query within a React component, call `useStoreConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useStoreConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StoreConfigQuery, StoreConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<StoreConfigQuery, StoreConfigQueryVariables>(StoreConfigDocument, options);
      }
export function useStoreConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StoreConfigQuery, StoreConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<StoreConfigQuery, StoreConfigQueryVariables>(StoreConfigDocument, options);
        }
export type StoreConfigQueryHookResult = ReturnType<typeof useStoreConfigQuery>;
export type StoreConfigLazyQueryHookResult = ReturnType<typeof useStoreConfigLazyQuery>;
export type StoreConfigQueryResult = Apollo.QueryResult<StoreConfigQuery, StoreConfigQueryVariables>;
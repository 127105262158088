import { BlogAuthor } from '@pwa-concept/modules/graphql'
import { useBlogAuthorPostsList } from 'app/modules/blog-author'
import {
    AuthorPagePostsList,
    AuthorPagePostsListPlaceholder,
    AuthorPagePostsRowTitle,
    AuthorPageRightColumn,
} from 'modules/AuthorPage'
import { useTranslation } from 'react-i18next'

const AuthorPagePosts = (
    {
        author,
        loading: authorLoading,
    }: { author?: BlogAuthor, loading?: boolean }) => {
    const { t } = useTranslation()

    const { data: { items } = {}, loading: postsLoading } = useBlogAuthorPostsList(author?.id)

    const loading = authorLoading || postsLoading

    return (
        <AuthorPageRightColumn>
            <AuthorPagePostsRowTitle type="h5" text={t('AuthorPage.posts.posts.title')} />
            {
                loading
                    ? <AuthorPagePostsListPlaceholder />
                    : <AuthorPagePostsList posts={items} />
            }
        </AuthorPageRightColumn>
    )
}

export default AuthorPagePosts

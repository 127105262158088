type tRestoreInputCarriage = (
    event: React.ChangeEvent<HTMLInputElement>,
    currentExtremumValue: number,
) => void

export const restoreFilterPriceInputCarriage: tRestoreInputCarriage = (event, currentExtremumValue) => {
    const selectionStart = event.target.selectionStart
    event.target.value = currentExtremumValue.toFixed(2)
    event.target.setSelectionRange(selectionStart - 1, selectionStart - 1)
    event.preventDefault()
    event.stopPropagation()
}

import { useWishlistMerge } from '@pwa-concept/modules/wishlist'
import { RequestOtpButton } from '@pwa-onilab/ui/components/elements/Buttons'
import { EmailFormField } from '@pwa-onilab/ui/components/elements/FormFields'
import PasswordFormField from '@pwa-onilab/ui/components/elements/FormFields/PasswordFormField/PasswordFormField'
import FormTitle from '@pwa-onilab/ui/components/modules/Auth/elements/FormTitle'
import AuthMobileHeader from '@pwa-onilab/ui/components/modules/Auth/elements/MobileHeader'
import { SubmitButton } from '@pwa-onilab/ui/components/modules/Auth/elements/SubmitButton'
import Loader from '@pwa-onilab/ui/components/UI/Loader'
import { FORM_EMAIL_FIELD, FORM_PASSWORD_FIELD } from '@pwa-onilab/ui/constants'
import { useCustomerAssign, useLogIn } from '@pwa-onilab/ui/hooks'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import styles from './LoginForm.module.scss'

interface LoginFormProps {
    afterSignInHandler?: () => void
    handleClickOnSignUp?: () => void
    openOneTimePasswordModal?: ({ email: string }) => void
}

const LoginForm = (
    {
        afterSignInHandler,
        handleClickOnSignUp,
        openOneTimePasswordModal,
    }: LoginFormProps) => {
    const [isShowLoader, setIsShowLoader] = useState(false)
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors, isSubmitting },
        getValues,
        setError,
        clearErrors,
        trigger,
    } = useForm()
    const { t } = useTranslation()
    const mergeWishlist = useWishlistMerge()

    const customerLogIn = useLogIn()

    const handleCustomerAssign = useCustomerAssign()

    const loginSubmitHandler = useCallback(async ({ email, password }) => {
        try {
            await customerLogIn(email, password)
            afterSignInHandler?.()

            await handleCustomerAssign()
            await mergeWishlist()
        } catch (e) {
            toast.error(e.message)
        }
    }, [])

    const handleOpenOneTimePasswordForm = useCallback(async () => {
        const isEmailFieldCorrect = await trigger(FORM_EMAIL_FIELD)
        if (isEmailFieldCorrect) {
            const email = getValues(FORM_EMAIL_FIELD)

            setIsShowLoader(true)
            await openOneTimePasswordModal({ email })
            setIsShowLoader(false)
            return
        }

        clearErrors(FORM_PASSWORD_FIELD)
        setError(FORM_EMAIL_FIELD, {
            type: 'otp',
            message: t('loginForm.oneTimePassword.email.error'),
        }, { shouldFocus: true })
    }, [openOneTimePasswordModal])

    return (
        <div>
            <AuthMobileHeader>
                {t('loginForm.logIn')}
            </AuthMobileHeader>
            <FormTitle
                title={t('loginForm.title')}
                subtitle={t('loginForm.dontHaveAccount')}
                buttonText={t('loginForm.signUp')}
                onClick={handleClickOnSignUp}
                loading={isSubmitting}
            />
            <form onSubmit={handleSubmit(loginSubmitHandler)}>
                <EmailFormField
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    disabled={isSubmitting}
                    className={styles.input}
                />
                <PasswordFormField
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    disabled={isSubmitting}
                    className={styles.input}
                />
                <RequestOtpButton openOtpModal={handleOpenOneTimePasswordForm} />
                <SubmitButton
                    text={t('loginForm.logIn')}
                    onLoadText={t('loginForm.loggingIn')}
                    loading={isSubmitting}
                />
            </form>
            <Loader visible={isShowLoader} isAbsolute />
        </div>
    )
}

export default LoginForm

import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { Link } from '@pwa-onilab/ui/components/UI/Link'
import { classNames } from '@pwa-onilab/ui/helpers'
import { FC } from 'react'

import styles from './MenuItem.module.scss'

export interface MenuItemProps {
    className?: string
    name: string
    icon: FC<{ className?: string }> | string
    link: string
}

const MenuItem = (
    {
        className = '',
        name,
        icon,
        link,
    }: MenuItemProps,
) => {
    const isHomePage = link === '/'
    const isShop = link === '/menu/shop'

    return (
        <Link
            to={link}
            activeClassName={styles.active}
            className={classNames(styles.item,
                !isHomePage && styles.changeable,
                isShop && styles.shop,
                className)}
        >
            <Icon name={icon} className={classNames(styles.icon, (isHomePage || isShop) && styles.homeIcon)} />
            <span className={styles.title}>
                {name}
            </span>
        </Link>
    )
}
export default MenuItem

import {
    SocialMediaIcons,
    SocialMediaIconsWithTitle,
    VARIANT_ICONS_WITH_TITLE,
    VARIANT_ICONS_WITHOUT_TITLE,
} from '@pwa-onilab/ui/components/modules/Footer'
import { memo } from 'react'

interface ISocialMediaIconsListProps {
    className?: string
    variant: string
}

const SocialMediaIconsList = (
    {
        className,
        variant = VARIANT_ICONS_WITH_TITLE,
    }: ISocialMediaIconsListProps,
) => {
    switch (variant) {
        case VARIANT_ICONS_WITHOUT_TITLE:
            return <SocialMediaIcons className={className} />
        case VARIANT_ICONS_WITH_TITLE:
            return <SocialMediaIconsWithTitle className={className} />
        default:
            return null
    }
}

export default memo(SocialMediaIconsList)

import {
    useStoreConfigRegistrationPageMetaQuery,
} from 'app/modules/store-config/graphql/queries/StoreConfigRegistrationPageMeta'

const useStoreConfigRegistrationPageMeta = () => {
    const { data: { storeConfigRegistrationPageMeta } = {}, loading } = useStoreConfigRegistrationPageMetaQuery()

    return {
        data: storeConfigRegistrationPageMeta,
        loading,
    }
}

export default useStoreConfigRegistrationPageMeta

import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { REGEX_NAME } from '@pwa-onilab/ui/constants/patterns'
import { RegisterOptions } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const useFullNameFieldRules = (): RegisterOptions => {
    const { t } = useTranslation()
    const { data: { maxNameLength } = {} } = useStoreConfig()

    return {
        required: t('global.errors.required'),
        pattern: {
            value: REGEX_NAME,
            message: t('global.errors.name'),
        },
        maxLength: {
            value: maxNameLength,
            message: t('global.errors.maxLength'),
        },
    }
}

export default useFullNameFieldRules

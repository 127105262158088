import { SimpleProductCardSkeleton } from '@pwa-onilab/ui/components/modules/Product'
import SimpleProductCardWrapper from 'components/overrides/modules/Product/SimpleProductCard/SimpleProductCardWrapper'
import { IProductListContent } from 'components/widgets/ProductList/components/IProductListContent'

import styles from './ProductListGrid.module.scss'

const ProductListGrid = (
    {
        products, // if loading include id, sku only
        isLoading,
    }: Pick<IProductListContent, 'products' | 'isLoading'>,
) => (
    <div className={styles.grid}>
        {
            products.map((product) => {
                return (
                    <div className={styles.cell} key={product.sku}>
                        {
                            isLoading
                                ? <SimpleProductCardSkeleton />
                                : <SimpleProductCardWrapper
                                        currentViewVariant="grid"
                                        product={product}
                                        itemsListName="ProductListGrid widget"
                                  />
                        }
                    </div>
                )
            })
        }
    </div>
)

export default ProductListGrid

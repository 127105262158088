import { IClassName } from '@pwa-onilab/ui/interfaces'
import { Dispatch, memo, PropsWithChildren, useState } from 'react'
import SwiperCore, {
    Autoplay,
    Controller,
    Mousewheel,
    Navigation,
    Pagination,
    Scrollbar,
    SwiperOptions,
    Thumbs,
} from 'swiper'

import { SliderContainer, SliderErrorBoundary } from './elements'
import { SliderProvider } from './providers'

SwiperCore.use([Navigation, Pagination, Autoplay, Scrollbar, Controller, Thumbs, Mousewheel])

export interface SliderProps extends SwiperOptions, IClassName {
    setCustomThumbsSwiper?: Dispatch<any>
    onSlideChange?: ({ idx }) => void
}

const Slider = memo((
    {
        setCustomThumbsSwiper,
        children,
        className,
        ...args
    }: PropsWithChildren<SliderProps>,
) => {
    const [slider, setSlider] = useState(null)

    const setSliderInstance = (instance) => {
        setSlider(instance)
        setCustomThumbsSwiper?.(instance)
    }

    return (
        <SliderErrorBoundary>
            <SliderProvider
                slider={slider}
                initial={{
                    slidesPerView: 1,
                    spaceBetween: 0,
                    loop: false,
                    cssMode: false,
                    onSlideChange: () => null,
                    breakpoints: {},
                    autoplay: false,
                    centeredSlides: false,
                    freeMode: false,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0,
                    loopedSlides: 0,
                    autoHeight: false,
                    speed: 160,
                    threshold: 5,
                    direction: 'horizontal',
                    slideToClickedSlide: false,
                    watchSlidesProgress: false,
                    watchOverflow: true,
                    mousewheel: {
                        forceToAxis: true,
                    },
                    ...args,
                }}
            >
                <SliderContainer
                    onInit={setSliderInstance}
                    className={className}
                >
                    {children}
                </SliderContainer>
            </SliderProvider>
        </SliderErrorBoundary>
    )
})

export default Slider

import Title from '@pwa-onilab/ui/components/UI/Title'
import { useTranslation } from 'react-i18next'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './AccountDetailsTitle.module.scss'

interface IAccountDetailsTitle {
    className?: String
}

const AccountDetailsTitle = (
    {
        className,
    }: IAccountDetailsTitle,
) => {
    const { t } = useTranslation()

    return (
        <Title
            type="h2"
            text={t('AccountPage.informationSubpage.title')}
            className={classNames(styles.title, className)}
        />
    )
}

export default AccountDetailsTitle

import { Product } from '@pwa-concept/modules/graphql'
import { getLinkOnProductState } from '@pwa-onilab/ui/helpers'
import { SYMBOL_SLASH } from 'constants/common'

const useCustomProductUrl = (url: Product['url'], sku: Product['sku']) => {
    const productLinkState = getLinkOnProductState(url, sku)
    const relativeUrl = SYMBOL_SLASH + url

    return {
        productLinkState,
        relativeUrl,
    }
}

export default useCustomProductUrl

import { StoreConfig } from '@pwa-concept/modules/graphql'
import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { LOGO_CONFIGURATION } from 'constants/LogoConfiguration'
import { SchemaHelmet } from 'elements/SchemaHelmet'
import {
    SCHEMA_HELMET_CONTEXT_VALUE,
    SCHEMA_HELMET_DEFAULT_SITE_URL,
} from 'elements/SchemaHelmet/SchemaHelmetConstants'

const getStructuredDataMobilePhoneStore = (config: StoreConfig) => {
    if (!config || !Object.keys(config).length) {
        return {}
    }

    const mobilePhoneStore = config.structuredData.mobilePhoneStore

    const logoId = `${SCHEMA_HELMET_DEFAULT_SITE_URL}#/schema/logo/image/`

    const data = {
        '@context': SCHEMA_HELMET_CONTEXT_VALUE,
        '@type': 'MobilePhoneStore',
        url: SCHEMA_HELMET_DEFAULT_SITE_URL,
        '@id': `${SCHEMA_HELMET_DEFAULT_SITE_URL}#mobilePhoneStore`,

        name: mobilePhoneStore.name,
        image: {
            '@type': 'ImageObject',
            '@id': logoId,
            url: LOGO_CONFIGURATION.desktopSrc,
            contentUrl: LOGO_CONFIGURATION.desktopSrc,
            caption: config.defaultTitle || '',
            inLanguage: config.locale || '',
            width: LOGO_CONFIGURATION.desktopWidth,
            height: LOGO_CONFIGURATION.desktopHeight,
        },
        address: mobilePhoneStore.address,
        priceRange: mobilePhoneStore.priceRange,
        telephone: mobilePhoneStore.telephone,
        contactPoint: {
            '@type': 'ContactPoint',
            telephone: mobilePhoneStore.contactPointTelephone,
            contactType: mobilePhoneStore.contactPointType,
        },
        openingHoursSpecification: mobilePhoneStore.workDays?.map((day) => {
            return {
                '@type': 'OpeningHoursSpecification',
                opens: mobilePhoneStore.workHoursStart,
                closes: mobilePhoneStore.workHoursEnd,
                dayOfWeek: `${SCHEMA_HELMET_CONTEXT_VALUE}/${day}`,
            }
        }) || [],
    }
    return data
}

const SchemaHelmetMobilePhoneStore = () => {
    const { data: storeConfig } = useStoreConfig()

    if (!storeConfig) {
        return null
    }

    return <SchemaHelmet jsonConfig={getStructuredDataMobilePhoneStore(storeConfig)} />
}

export default SchemaHelmetMobilePhoneStore

import { classNames } from '@pwa-onilab/ui/helpers'
import { createElement, PropsWithChildren } from 'react'

import css from './Flex.module.scss'

const DIRECTION = {
    row: css.directionRow,
    rowReverse: css.directionRowReverse,
    column: css.directionColumn,
    columnReverse: css.directionColumnReverse,
}

const ALIGN_ITEMS = {
    start: css.alignItemsStart,
    center: css.alignItemsCenter,
    end: css.alignItemsEnd,
    stretch: css.alignItemsStretch,
}

const JUSTIFY_CONTENT = {
    start: css.justifyContentStart,
    center: css.justifyContentCenter,
    end: css.justifyContentEnd,
    spaceBetween: css.justifyContentSpaceBetween,
    stretch: css.justifyContentStretch,
}

const FLEX = {
    auto: css.flexAuto,
}

const WRAP = {
    wrap: css.wrapWrap,
    nowrap: css.wrapNoWrap,
}

export interface IFlex {
    className?: string
    as?: Parameters<typeof createElement>[0]
    asProps?: any
    flex?: keyof typeof FLEX
    direction?: keyof typeof DIRECTION
    alignItems?: keyof typeof ALIGN_ITEMS
    justifyContent?: keyof typeof JUSTIFY_CONTENT
    wrap?: keyof typeof WRAP
}

const Flex = (
    {
        children,
        className,
        as = 'div',
        asProps,
        flex,
        direction,
        alignItems,
        justifyContent,
        wrap,
    }: PropsWithChildren<IFlex>,
) => (
    createElement(
        as,
        {
            ...(asProps || {}),
            className: classNames(
                css.wrapper,
                flex && FLEX?.[flex],
                direction && DIRECTION?.[direction],
                alignItems && ALIGN_ITEMS?.[alignItems],
                justifyContent && JUSTIFY_CONTENT?.[justifyContent],
                wrap && WRAP?.[wrap],
                className,
            ),
        },
        children,
    )
)

export default Flex

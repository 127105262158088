import { Resolvers } from '@pwa-concept/modules/graphql'

import Mutation from './Mutation'
import Query from './Query'

const resolvers: Resolvers = {
    Query,
    Mutation,
}

export default resolvers

import { Question } from '@pwa-concept/modules/graphql'
import { FaqQuestionsAccordionItem } from 'modules/FaqQuestions'

import styles from './FaqQuestionsAccordion.module.scss'

interface IFaqQuestionsAccordionProps {
    faqQuestions: [Question]
}
const FaqQuestionsAccordion = (
    {
        faqQuestions,
    }: IFaqQuestionsAccordionProps,
) => {
    return (
        <div className={styles.wrapper}>
            {
                faqQuestions.map(({ answer, question }) => (
                    <FaqQuestionsAccordionItem
                        key={answer}
                        answer={answer}
                        question={question}
                        className={styles.item}
                    />
                ))
            }
        </div>
    )
}

export default FaqQuestionsAccordion

import { IAccountAddress } from '@pwa-onilab/ui/components/modules/AccountAddress'
import {
    AccountAddressesList,
    AccountAddressesTitle,
    useAccountDefaultAddresses,
} from '@pwa-onilab/ui/components/modules/AccountSubpages'
import { useTranslation } from 'react-i18next'
import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'

import styles from './AccountAddressesDefault.module.scss'

const AccountAddressesDefault = (
    {
        openUpdateAddressModal,
        openDeleteAddressModal,
    }: Pick<IAccountAddress, 'openDeleteAddressModal' | 'openUpdateAddressModal'>) => {
    const { t } = useTranslation()

    const { addressList, isShowMessageAboutEmptyAddresses } = useAccountDefaultAddresses()

    if (!addressList.length && !isShowMessageAboutEmptyAddresses) {
        return null
    }

    return (
        <div className={styles.wrapper}>
            <MediaBreakpointUp name="lg">
                <AccountAddressesTitle title={t('addresses.defaultAddresses')} />
            </MediaBreakpointUp>

            {
                isShowMessageAboutEmptyAddresses
                    ? t('addresses.noDefaultAddresses')
                    : <AccountAddressesList
                            addressList={addressList}
                            openUpdateAddressModal={openUpdateAddressModal}
                            openDeleteAddressModal={openDeleteAddressModal}
                      />
            }

        </div>
    )
}

export default AccountAddressesDefault

import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import {
    productsDecoratorCategoryId,
    productsDecoratorMultipleFilters,
} from '@pwa-concept/modules/helpers/productsModules'
import productsDecoratorSort from '@pwa-concept/modules/helpers/productsModules/productsDecoratorSort'
import { gql } from 'graphql-tag'

const catalog: QueryResolvers['catalog'] = async (_, { input } = {}) => {
    const {
        search,
        filters = [],
        categoryId,
        sort,
        page = 1,
        limit = 20,
    } = input || {}

    const { data: { products = {} } = {} } = (
        await api.graphql(
            gql`
                query(
                    $search: String = ""
                    $limit: Int = 20
                    $filter: ProductAttributeFilterInput
                    $sort: ProductAttributeSortInput
                    $page: Int = 1
                    $isFullProduct: Boolean = false
                ) {
                    products(
                        search: $search
                        filter: $filter
                        sort: $sort
                        pageSize: $limit
                        currentPage: $page
                    ) {
                        ...Products
                    }
                }
            `,
        ).variableIf(
            !!filters?.length, (prev) => productsDecoratorMultipleFilters(prev, filters),
        ).variableIf(
            sort?.length > 0, (prev) => productsDecoratorSort(prev, sort),
        ).variableIf(
            !!categoryId, (prev) => productsDecoratorCategoryId(prev, categoryId),
        ).query({
            search,
            page,
            limit,
        })
    )

    return {
        items: products?.items?.map?.((__context) => ({ __context, __typename: 'Product' })) || [],
        pagination: {
            count: products?.total_count,
            current: products?.page_info?.current_page,
            limit: products?.page_info?.page_size,
            total: products?.page_info?.total_pages,
            __typename: 'Pagination',
        },
        input,
        __typename: 'Catalog',
    }
}

export default catalog

import * as Types from '../../../../graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type GetStrainsForSaleQueryQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.GetStrainsForSaleQueryInput>;
}>;


export type GetStrainsForSaleQueryQuery = { __typename?: 'Query', getStrainsForSaleQuery?: Array<{ __typename?: 'Product', id?: string | null, sku?: string | null, name?: string | null, url?: string | null } | null> | null };


export const GetStrainsForSaleQueryDocument = gql`
    query GetStrainsForSaleQuery($input: GetStrainsForSaleQueryInput) {
  getStrainsForSaleQuery(input: $input) @client {
    id
    sku
    name
    url
  }
}
    `;

/**
 * __useGetStrainsForSaleQueryQuery__
 *
 * To run a query within a React component, call `useGetStrainsForSaleQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStrainsForSaleQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStrainsForSaleQueryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStrainsForSaleQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStrainsForSaleQueryQuery, GetStrainsForSaleQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetStrainsForSaleQueryQuery, GetStrainsForSaleQueryQueryVariables>(GetStrainsForSaleQueryDocument, options);
      }
export function useGetStrainsForSaleQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStrainsForSaleQueryQuery, GetStrainsForSaleQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetStrainsForSaleQueryQuery, GetStrainsForSaleQueryQueryVariables>(GetStrainsForSaleQueryDocument, options);
        }
export type GetStrainsForSaleQueryQueryHookResult = ReturnType<typeof useGetStrainsForSaleQueryQuery>;
export type GetStrainsForSaleQueryLazyQueryHookResult = ReturnType<typeof useGetStrainsForSaleQueryLazyQuery>;
export type GetStrainsForSaleQueryQueryResult = Apollo.QueryResult<GetStrainsForSaleQueryQuery, GetStrainsForSaleQueryQueryVariables>;
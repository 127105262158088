import { InputField } from '@pwa-onilab/ui/components/elements/Fields'
import { IFormField } from '@pwa-onilab/ui/components/elements/FormFields'
import { useFullNameFieldRules } from '@pwa-onilab/ui/components/elements/FormFields/hooks'
import { FORM_FULL_NAME_FIELD } from '@pwa-onilab/ui/constants/formFields'
import { useTranslation } from 'react-i18next'

interface IFullNameFormField extends IFormField{
    field?: string
}

const FullNameFormField = ({
    errors,
    register,
    setValue,
    disabled,
    setValueOptions,
    className,
    field,
    additionalRules,
    placeholder,
}: IFullNameFormField) => {
    const { t } = useTranslation()
    const rules = useFullNameFieldRules()

    return (
        <InputField
            placeholder={placeholder || t('global.placeholder.name')}
            field={field || FORM_FULL_NAME_FIELD}
            register={register}
            setValue={setValue}
            setValueOptions={setValueOptions}
            rules={{
                ...rules,
                ...additionalRules,
            }}
            errors={errors}
            className={className}
            disabled={disabled}
        />
    )
}

export default FullNameFormField

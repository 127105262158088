import { classNames } from '@pwa-onilab/ui/helpers'
import { LinkArrow } from 'components/UI/LinkArrow'
import { useTranslation } from 'react-i18next'

import styles from './DropdownMenuViewAll.module.scss'

interface IDropdownMenuViewAllProps {
    className?: string
    onClick: () => void
}

const DropdownMenuViewAll = (
    {
        className,
        onClick,
    }: IDropdownMenuViewAllProps,
) => {
    const { t } = useTranslation()

    return (
        <div className={classNames(styles.wrapper, className)}>
            <LinkArrow
                withArrow
                onClick={onClick}
            >
                {t('global.viewAllStrains')}
            </LinkArrow>
        </div>
    )
}

export default DropdownMenuViewAll

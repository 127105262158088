import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type VerifyOtpResetMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  code: Types.Scalars['String'];
}>;


export type VerifyOtpResetMutation = { __typename?: 'Mutation', verifyOTPReset?: { __typename?: 'VerifyOTPRequestOutput', attemptsLeft?: number | null, token?: string | null, valid?: boolean | null } | null };


export const VerifyOtpResetDocument = gql`
    mutation verifyOTPReset($email: String!, $code: String!) {
  verifyOTPReset(email: $email, code: $code) @client {
    attemptsLeft
    token
    valid
  }
}
    `;
export type VerifyOtpResetMutationFn = Apollo.MutationFunction<VerifyOtpResetMutation, VerifyOtpResetMutationVariables>;

/**
 * __useVerifyOtpResetMutation__
 *
 * To run a mutation, you first call `useVerifyOtpResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOtpResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOtpResetMutation, { data, loading, error }] = useVerifyOtpResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyOtpResetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyOtpResetMutation, VerifyOtpResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<VerifyOtpResetMutation, VerifyOtpResetMutationVariables>(VerifyOtpResetDocument, options);
      }
export type VerifyOtpResetMutationHookResult = ReturnType<typeof useVerifyOtpResetMutation>;
export type VerifyOtpResetMutationResult = Apollo.MutationResult<VerifyOtpResetMutation>;
export type VerifyOtpResetMutationOptions = Apollo.BaseMutationOptions<VerifyOtpResetMutation, VerifyOtpResetMutationVariables>;
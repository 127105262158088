import { REGEX_TELEPHONE_ALLOWED_CHAR } from '@pwa-onilab/ui/constants/patterns'
import { useMemo } from 'react'
import { RegisterOptions } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// TODO add logic for not required phone stores?
// TODO minLength, maxLength, INVALID_COUNTRY format, validate function?
const usePhoneFieldRules = (): RegisterOptions => {
    const { t } = useTranslation()

    return useMemo((): RegisterOptions => {
        return {
            required: t('global.errors.required'),
            pattern: REGEX_TELEPHONE_ALLOWED_CHAR,
        }
    }, [])
}

export default usePhoneFieldRules

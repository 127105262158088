import {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
} from 'react'

type tUsePaginationFloatVisible = (
    setIsVisible: Dispatch<SetStateAction<boolean>>,
    setIsOpen: Dispatch<SetStateAction<boolean>>,
    isDisabled: boolean,
) => void

const MIN_SHIFT = 5

const usePaginationFloatVisible: tUsePaginationFloatVisible = (
    setIsVisible,
    setIsOpen,
    isDisabled,
) => {
    const scrollYRef = useRef<number>(window.scrollY || document.body.scrollTop)

    useEffect(() => {
        if (isDisabled) {
            return null
        }

        const scrollHandler = () => {
            const newY = window.scrollY || document.body.scrollTop
            const isScrollDown = newY > scrollYRef.current

            if ((isScrollDown && newY < scrollYRef.current + MIN_SHIFT) ||
                (!isScrollDown && newY > scrollYRef.current - MIN_SHIFT)
            ) {
                return
            }

            if (newY < scrollYRef.current) {
                setIsVisible(true)
            } else {
                setIsVisible(false)
                setIsOpen(false)
            }
            scrollYRef.current = newY
        }

        window.addEventListener('scroll', scrollHandler)

        return () => {
            window.removeEventListener('scroll', () => scrollHandler)
        }
    }, [isDisabled])
}

export default usePaginationFloatVisible

import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import styles from './StickyBar.module.scss'

interface StickyBarProps {
    className?: string
}

const StickyBar = (
    {
        children,
        className,
    }: PropsWithChildren<StickyBarProps>) => (
        <div className={classNames(styles.wrapper, className)}>
            {children}
        </div>
)

export default StickyBar

import { $currencySign } from '@pwa-concept/core/models'
import { Filter } from '@pwa-concept/modules/graphql'
import { IPageAuthor } from 'elements/BlogStatePages/elements/PageAuthor/PageAuthor'
import { SCHEMA_HELMET_CONTEXT_VALUE, SCHEMA_HELMET_TYPES } from 'elements/SchemaHelmet/SchemaHelmetConstants'
import { addSlashToEndUrl } from 'helpers'
import { FILTER_NODE_ACTUAL_PRICE_PER_SEED } from 'modules/Filters'

export const getSchemaHelmetAuthorId = (author: IPageAuthor['author']) => {
    if (!author.id) {
        return undefined
    }
    return `https://kindseed.com/#/schema/person/${author.id}`
}

export const getSchemaHelmetProductCollection = (
    categoryName: string,
    lowPrice: number,
    highPrice: number,
    offerCount: number,
) => {
    const url = addSlashToEndUrl(window.location.href)
    return {
        '@context': SCHEMA_HELMET_CONTEXT_VALUE,
        '@type': SCHEMA_HELMET_TYPES.ProductCollection,
        name: categoryName,
        url,
        offers: {
            '@type': SCHEMA_HELMET_TYPES.AggregateOffer,
            lowPrice,
            highPrice,
            priceCurrency: $currencySign.code,
            offerCount,
        },
    }
}

export const getPriceMinMaxFromFilter = (filters: Filter[]) => {
    const priceFilter = filters.find((filterGroup) => {
        return filterGroup.key === FILTER_NODE_ACTUAL_PRICE_PER_SEED
    })

    let priceMin
    let priceMax
    if (priceFilter) {
        const firstItemValue = +priceFilter.items[0]?.value || 0
        priceMin = firstItemValue
        priceMax = firstItemValue

        const maxLength = priceFilter.items?.length || 0
        for (let i = 1; i < maxLength; i += 1) {
            const curValue = +priceFilter.items[i].value

            if (curValue > priceMax) {
                priceMax = curValue
            }
            if (curValue < priceMin) {
                priceMin = curValue
            }
        }
    }

    return [priceMin, priceMax]
}

import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import { FooterMenuQuery } from '@pwa-onilab/ui/schemas'

export interface IFooterMenuOptions {
    skip?: boolean
    fetchPolicy?: WatchQueryFetchPolicy
}

const useFooterMenu = ({ options }: { options?: IFooterMenuOptions} = {}) => {
    const {
        data: {
            menu = null,
        } = {},
        loading,
    } = useQuery(FooterMenuQuery, options)

    return {
        menu,
        loading,
    }
}

export default useFooterMenu

import Slider, { SliderPagination, SliderSlide } from '@pwa-onilab/ui/components/UI/Slider'
import { PromoBannersSlide } from '@pwa-onilab/ui/components/widgets/PromoBanners'
import { useMemo } from 'react'

import styles from './PromoBannersSlider.module.scss'

interface IPromoBannersSliderProps {
    items: Array<{
        desktop_image: string
        link_text: string
        link_url: string
        mobile_image: string
        title: string
        type: string
    }>
}

const PromoBannersSlider = ({ items }: IPromoBannersSliderProps) => {
    const moreThanOneSlides = items?.length > 1

    const slides = useMemo(() => (
        items.map(({
            desktop_image: desktopImage,
            link_text: linkText,
            link_url: linkUrl,
            mobile_image: mobileImage,
            title,
        }) => (
            <SliderSlide key={desktopImage}>
                <PromoBannersSlide
                    title={title}
                    desktopImage={desktopImage}
                    mobileImage={mobileImage}
                    linkText={linkText}
                    linkUrl={linkUrl}
                />
            </SliderSlide>
        ))
    ), [items])

    return (
        <Slider
            slidesPerView={1}
            className={styles.sliderPromo}
        >
            {slides}
            {moreThanOneSlides && <SliderPagination className={styles.pagination} />}
        </Slider>
    )
}

export default PromoBannersSlider

// https://www.w3.org/WAI/GL/wiki/Relative_luminance
export const getLuminance = (r: number, g: number, b: number) => {
    const a = [r, g, b].map((value) => {
        value /= 255
        return value <= 0.03928
            ? value / 12.92
            : Math.pow((value + 0.055) / 1.055, 2.4)
    })
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

export const checkColorRgbIsDark = (rgb: number[]) => {
    if (rgb?.length !== 3) {
        return false
    }
    const formattedRgb = rgb.map((color) => {
        if (color > 255) {
            return 255
        }
        if (color < 0) {
            return 0
        }
        return color
    })

    const colorLuminance = getLuminance(formattedRgb[0], formattedRgb[1], formattedRgb[2])

    return colorLuminance < 0.5
}

import { IProtectedInput } from '@pwa-onilab/ui/components/elements/Inputs/PasswordInput'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { Input } from '@pwa-onilab/ui/components/UI/Input'
import { PASSWORD_INPUT_STATES } from '@pwa-onilab/ui/constants/app'
import { useCallback, useState } from 'react'

import styles from './PasswordInput.module.scss'

const PasswordInput = (
    {
        setValue,
        setValueOptions,
        className,
        field,
        register,
        rules,
        errors,
        placeholder = '',
    }: IProtectedInput) => {
    const [currentType, setCurrentType] = useState<PASSWORD_INPUT_STATES>(PASSWORD_INPUT_STATES.HIDDEN_PASSWORD)

    const toggleInputType = useCallback(() => {
        if (currentType === PASSWORD_INPUT_STATES.HIDDEN_PASSWORD) {
            setCurrentType(PASSWORD_INPUT_STATES.SHOWED_PASSWORD)
        } else {
            setCurrentType(PASSWORD_INPUT_STATES.HIDDEN_PASSWORD)
        }
    }, [currentType])

    return (
        <>
            <Input
                className={className}
                type={currentType}
                field={field}
                register={register}
                rules={rules}
                setValue={setValue}
                setValueOptions={setValueOptions}
                placeholder={placeholder}
                errors={errors}
            />
            <ButtonInstance className={styles.password_show} onClick={toggleInputType}>
                <Icon name="showPasswordIcon" />
            </ButtonInstance>
        </>
    )
}

export default PasswordInput

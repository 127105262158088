import useCartTotals from '@pwa-concept/modules/cart/features/get/hooks/useCartTotals'
import { ICartResult } from '@pwa-onilab/ui/components/modules/Cart/elements/CartResult/CartResult'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import Price from '@pwa-onilab/ui/components/UI/Price'
import { StickyBar } from '@pwa-onilab/ui/components/UI/StickyBar'
import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './CartGoToCheckoutStickyBar.module.scss'
import { useEffect } from 'react'
import api from '@pwa-concept/core/api'
import { gql } from 'graphql-tag'
import { useCart } from '@pwa-concept/modules/cart'

const CartGoToCheckoutStickyBar = ({ tryNavigateToCheckout }: Pick<ICartResult, 'tryNavigateToCheckout'>) => {
    const { data: { subtotal, total } } = useCartTotals()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isWaitToNavigate, setIsWaitToNavigate] = useState(true)
    const { t } = useTranslation()
    const cartData = useCart()
    const [request, requestState] = useState(null)

    const onClick = useCallback(async () => {
        // TODO wrap it in component above amd get is loading prop?
        setIsLoading(true)
        await tryNavigateToCheckout()
        setIsLoading(false)
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsWaitToNavigate(false)
        }, 1200);

        return () => clearTimeout(timer)

    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const { data: { guestValidateMinOrderAmount: context = {} } = {} } = await api.graphql(
                gql`
                    query {
                        guestValidateMinOrderAmount(cartId:${cartData.data.id}) {
                            success
                            error_message
                        }
                    }
                `,
            ).query(undefined, {
                errorPolicy: 'all',
            });

            const response = await context;
            requestState(response);
        };

        fetchData();
    }, [subtotal]);

    return (
        <StickyBar className={styles.wrapper}>
            <div className={styles.total}>
                {t('global.totalWithColon')}
                &nbsp;
                <Price>
                    {total}
                </Price>
            </div>
            {request?.success === '1' ? (
                <Button
                    isPrimary
                    isBold
                    onClick={onClick}
                    disabled={isLoading}
                >
                    {t('cart.button.toCheckout')}
                </Button>
            ) : request?.success === '0' ? (
                <>
                    <Button
                        isPrimary
                        isBold
                        onClick={onClick}
                        disabled={isLoading}
                        className={styles.errorButton}
                    >
                        {t('cart.button.toCheckout')}
                    </Button>
                    <div className={styles.errorMessage}>{request.error_message}</div>
                </>
            ) : (
                <Button
                    isPrimary
                    isBold
                    onClick={onClick}
                    disabled={isLoading  || isWaitToNavigate}
                >
                    {t('cart.button.toCheckout')}
                </Button>
            )}
        </StickyBar>
    )
}

export default memo(CartGoToCheckoutStickyBar)


    var doc = {"kind":"Document","definitions":[{"kind":"ObjectTypeExtension","name":{"kind":"Name","value":"Cms"},"interfaces":[],"directives":[],"fields":[{"kind":"FieldDefinition","name":{"kind":"Name","value":"products"},"arguments":[],"type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Product"}}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"metaRobots"},"arguments":[],"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"displayMode"},"arguments":[],"type":{"kind":"NamedType","name":{"kind":"Name","value":"DisplayMode"}},"directives":[]}]},{"kind":"EnumTypeDefinition","name":{"kind":"Name","value":"DisplayMode"},"directives":[],"values":[{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"full_width"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"one_column"},"directives":[]}]}],"loc":{"start":0,"end":148}};
    doc.loc.source = {"body":"extend type Cms {\n    products: [Product]\n    metaRobots: String\n    displayMode: DisplayMode\n}\n\nenum DisplayMode {\n    full_width\n    one_column\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    

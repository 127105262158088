import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { PRODUCT_KEYFIELD } from '@pwa-concept/modules/product/constants'
import { gql } from 'graphql-tag'

const getStrainsForSaleQuery: QueryResolvers['getStrainsForSaleQuery'] = async (_, { input }) => {
    const {
        search,
        limit = 1000,
        filter,
        sort,
        page = 1,
    } = input || {}

    const { data: { products = {} } = {} } = (
        await api.graphql(
            gql`
                query(
                    $search: String = ""
                    $limit: Int = 1000
                    $filter: ProductAttributeFilterInput
                    $sort: ProductAttributeSortInput
                    $page: Int = 1
                ) {
                    products(
                        search: $search
                        pageSize: $limit
                        filter: $filter
                        sort: $sort
                        currentPage: $page
                    ) {

                        total_count

                        items {
                            id
                            sku
                            name
                            url_key
                            url_prefix
                        }
                    }
                }
            `,
        ).query({
            search,
            limit,
            filter,
            sort,
            page,
        })
    )

    if (!products?.items) return null

    return products.items.map((__context) => {
        return {
            __context,
            __typename: PRODUCT_KEYFIELD,
        }
    }) || []
}

export default getStrainsForSaleQuery

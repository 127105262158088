import { memo, MouseEventHandler, PropsWithChildren } from 'react'
import { SwiperSlide } from 'swiper/react'

interface SliderSlideProps {
    className?: string
    onClick?: MouseEventHandler
}

const SliderSlide = memo(({ children, className, onClick }: PropsWithChildren<SliderSlideProps>) => (
    <SwiperSlide
        onClick={onClick}
        className={className}
    >
        {children}
    </SwiperSlide>
))

SliderSlide.displayName = 'SwiperSlide'

export { SliderSlide }

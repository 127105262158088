import { PropsWithChildren } from 'react'

import styles from './OrderInfoRow.module.scss'

const OrderInfoRow = ({ children }: PropsWithChildren<any>) => {
    return (
        <div className={styles.wrapper}>
            {children}
        </div>
    )
}

export default OrderInfoRow

import { $currencySign } from '@pwa-concept/core/models'
import roundNumberToHundredth from 'helpers/roundNumberToHundredth'
import { useTranslation } from 'react-i18next'
import { IFreeShippingStatusBar } from 'modules/Cart'

import styles from './FreeShippingStatusBarInfo.module.scss'

const FreeShippingStatusBarInfo = (
    {
        subtotal,
        freeShippingMinimumAmount,
    }: IFreeShippingStatusBar,
) => {
    const { t } = useTranslation()

    const differenceBetweenMaxAndCurrent = +freeShippingMinimumAmount - +subtotal

    return (
        <div className={styles.info}>
            <div className={styles.common}>
                {$currencySign.sign}
                {differenceBetweenMaxAndCurrent < 0 ? 0 : roundNumberToHundredth(differenceBetweenMaxAndCurrent)}
                {' '}
                {t('cart.freeShippingStatusBar.leftForFreeShipping')}
            </div>
            <div className={styles.summary}>
                {$currencySign.sign}
                {subtotal > freeShippingMinimumAmount ? freeShippingMinimumAmount : subtotal}
                /
                {freeShippingMinimumAmount}
            </div>
        </div>
    )
}

export default FreeShippingStatusBarInfo

import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const storeConfigBestsellers: QueryResolvers['storeConfigBestsellers'] = async (_, inputObj) => {
    const { data: { storeConfig: context = {} } = {} } = await (
        api.graphql(
            gql`
                query {
                    storeConfig {
                        bestsellers_description_block
                        bestsellers_bottom_description_block
                        bestsellers_breadcrumb_label
                        bestsellers_url_key
                        
                        bestsellers_meta_title
                        bestsellers_meta_keywords
                        bestsellers_meta_description
                        bestsellers_meta_robots
                    }
                }
            `,
        ).query(undefined, {
            errorPolicy: 'all',
        })
    )

    if (!context) {
        return null
    }

    return {
        descriptionBlock: context.bestsellers_description_block,
        bottomDescriptionBlock: context.bestsellers_bottom_description_block,
        breadcrumbLabel: context.bestsellers_breadcrumb_label,
        urlKey: context.bestsellers_url_key,

        meta: {
            title: context.bestsellers_meta_title,
            keywords: context.bestsellers_meta_keywords,
            description: context?.bestsellers_meta_description,
            robots: context?.bestsellers_meta_robots,
            __typename: 'StoreConfigDefaultPageMeta',
        },
        __typename: 'StoreConfigBestsellers',
    }
}

export default storeConfigBestsellers

import { TextOnlyButton } from '@pwa-onilab/ui/components/elements/Buttons'
import { useTranslation } from 'react-i18next'

import styles from './RequestOtpButton.module.scss'

interface IRequestOtpButton {
    openOtpModal: () => void
}

const RequestOtpButton = ({ openOtpModal }: IRequestOtpButton) => {
    const { t } = useTranslation()

    return (
        <div className={styles.forgotPasswordSection}>
            <span>{t('global.password.forgot')}</span>
            <TextOnlyButton className={styles.textButton} onClick={openOtpModal}>
                {t('global.password.requestOTP')}
            </TextOnlyButton>
        </div>
    )
}

export default RequestOtpButton

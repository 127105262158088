import { SortsQueryOutput } from '@pwa-concept/modules/graphql'
import { getDefaultSortDirection } from 'modules/Filters/helpers/sortByFunctions'

export const getCategoryItemsInputSortField = (activeSort) => {
    return Object.entries(activeSort || {}).map(([name, order]) => ({
        name,
        order: order?.[0],
    }))
}

export const getCategoryItemsInputFilterField = (activeFilters) => {
    return Object.entries<string[]>(activeFilters).map(([key, value]) => ({ key, value }))
}

export const getCategoryItemsInputDefaultSortField = (allSorts: SortsQueryOutput) => {
    return [{
        name: allSorts.default,
        order: getDefaultSortDirection(allSorts.default),
    }]
}

import { BreadcrumbResolvers } from '@pwa-concept/modules/graphql'

const Breadcrumb: BreadcrumbResolvers = {
    id: (obj) => {
        return obj.id || null
    },
    name: (obj) => {
        return obj.name || null
    },
    level: (obj) => {
        return obj.level ?? null
    },
    url: (obj) => {
        return obj.url || null
    },
}

export default Breadcrumb

import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';
import { ProductImageFragmentDoc } from './ProductImage';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

export type ProductNonRequiredFieldsFragment = { __typename?: 'Product', description?: string | null, shortDescription?: string | null, metaDescription?: string | null, metaKeywords?: string | null, metaTitle?: string | null, breadcrumbs?: Array<{ __typename?: 'Breadcrumb', id?: string | null, name?: string | null, url?: { __typename?: 'BreadcrumbUrl', id?: string | null, type?: string | null, to?: string | null } | null } | null> | null, images?: Array<{ __typename?: 'ProductImage', id?: string | null, description?: string | null, url?: string | null } | null> | null };

export const ProductNonRequiredFieldsFragmentDoc = gql`
    fragment ProductNonRequiredFields on Product {
  description @include(if: $isFullProduct)
  shortDescription @include(if: $isFullProduct)
  breadcrumbs @include(if: $isFullProduct) {
    id
    name
    url {
      id
      type
      to
    }
  }
  images @include(if: $isFullProduct) {
    ...ProductImage
  }
  metaDescription @include(if: $isFullProduct)
  metaKeywords @include(if: $isFullProduct)
  metaTitle @include(if: $isFullProduct)
}
    ${ProductImageFragmentDoc}`;
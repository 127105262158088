import { Resolvers } from '@pwa-concept/modules/graphql'

const StoreConfigWholeSale: Resolvers['StoreConfigWholeSale'] = {
    descriptionBlock: (_, __, { context }) => {
        return context?.wholesale_description_block || null
    },

    topDescriptionBlock: (_, __, { context }) => {
        return context?.wholesale_top_description_block || null
    },

    meta: (_, __, { context }) => {
        return {
            title: context?.wholesale_homepage_meta_title || '',
            keywords: context?.wholesale_homepage_meta_keywords || '',
            description: context?.wholesale_homepage_meta_description || '',
            robots: context?.wholesale_homepage_meta_robots || '',

            __typename: 'StoreConfigDefaultPageMeta',
        }
    },
}

export default StoreConfigWholeSale

import { SearchProviders, useLiveSearch } from '@pwa-onilab/ui/components/modules/Search'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import SearchModal from 'components/overrides/modules/Search/elements/SearchModal/SearchModal'
import SearchTrigger from 'components/overrides/modules/Search/elements/SearchTrigger/SearchTrigger'

interface ISearchProps extends IClassName {
    inputClassName?: IClassName['className']
}

const Search = (
    {
        className,
        inputClassName,
    }: ISearchProps,
) => {
    const {
        searchResult,
        searchValue,
        clearSearchField,
        searchLoading,
        lastSearches,
        onClearLastSearches,
        handleInputChange,
        categories,
    } = useLiveSearch()

    return (
        <SearchProviders categories={categories} searchValue={searchValue}>
            <SearchTrigger
                searchValue={searchValue}
                onInputChange={handleInputChange}
                onClear={clearSearchField}
                className={className}
                inputClassName={inputClassName}
            />
            <SearchModal
                searchResult={searchResult}
                searchValue={searchValue}
                isLoading={searchLoading}
                lastSearches={lastSearches}
                onClearLastSearches={onClearLastSearches}
                onInputChange={handleInputChange}
                categories={categories}
                onClear={clearSearchField}
            />
        </SearchProviders>
    )
}

export default Search

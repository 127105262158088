import { FilterItem } from '@pwa-concept/modules/graphql'
import { FILTER_TYPES } from 'components/overrides/modules/Filters/constants/FilterKeys'
import { FILTER_NODE_ACTUAL_PRICE_PER_SEED } from 'modules/Filters'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const useFilterOptionName = () => {
    const { t } = useTranslation()

    return useCallback((item: FilterItem) => {
        switch (item.key) {
            case FILTER_TYPES.CATEGORY_KEY: {
                return t('global.category')
            }
            case FILTER_NODE_ACTUAL_PRICE_PER_SEED: {
                return t('global.pricePerSeed')
            }
            case FILTER_TYPES.CBD_KEY: {
                return t('global.cbd')
            }
            case FILTER_TYPES.FLOWERING_TIME_WEEKS_KEY: {
                return t('global.floweringTimeWeeks')
            }
            case FILTER_TYPES.HIGH_YIELD_KEY: {
                return t('global.highYield')
            }
            case FILTER_TYPES.PLANT_HEIGHT_KEY: {
                return t('global.plantHeight')
            }
            case FILTER_TYPES.TERPENES_KEY: {
                return t('global.terpenes')
            }
            case FILTER_TYPES.THC_KEY: {
                return t('global.thc')
            }
            case FILTER_TYPES.FOR_BEGINNERS_KEY: {
                return t('global.forBeginners')
            }
            case FILTER_TYPES.SEEDS_TYPE_KEY: {
                return t('global.seedsType')
            }
            case FILTER_TYPES.EFFECTS_KEY: {
                return t('global.effects')
            }
            case FILTER_TYPES.SIP_VALUE_KEY: {
                return t('global.sipValue')
            }
            default: {
                return item.key
            }
        }
    }, [])
}

export default useFilterOptionName

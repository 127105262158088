import { LiveSearchResult } from '@pwa-concept/modules/graphql'
import {
    SearchPlaceholder,
    SearchResultEmpty,
    SearchResultFound,
    SearchResultNotFound,
    useTrendingRequests,
} from '@pwa-onilab/ui/components/modules/Search'
import { ISearchCategory } from '@pwa-onilab/ui/components/modules/Search/hooks/ISearchCategory'

export interface ISearchResultProps {
    searchResult?: LiveSearchResult
    searchValue?: string
    isLoading?: boolean
    lastSearches: string[]
    onClearLastSearches: () => void
    categories: ISearchCategory[]
}

const SearchResult = (
    {
        searchResult,
        searchValue,
        isLoading,
        lastSearches,
        onClearLastSearches,
        categories,
    }: ISearchResultProps,
) => {
    const { trendingRequests: popularSearchItems } = useTrendingRequests()

    if (isLoading) {
        return (
            <SearchPlaceholder />
        )
    }

    const productsLength = searchResult?.products?.items?.length
    const isFound = !!productsLength
    const isNotFound = !!searchValue && !productsLength

    if (isFound) {
        return (
            <SearchResultFound
                searchResult={searchResult}
                searchValue={searchValue}
                popularSearchItems={popularSearchItems}
                categories={categories}
            />
        )
    }
    if (isNotFound) {
        return (
            <SearchResultNotFound
                searchValue={searchValue}
                popularSearchItems={popularSearchItems}
                categories={categories}
            />
        )
    }

    return (
        <SearchResultEmpty
            popularSearchItems={popularSearchItems}
            lastSearches={lastSearches}
            onClearLastSearches={onClearLastSearches}
            categories={categories}
        />
    )
}

export default SearchResult


    var doc = {"kind":"Document","definitions":[{"kind":"ObjectTypeExtension","name":{"kind":"Name","value":"Query"},"interfaces":[],"directives":[],"fields":[{"kind":"FieldDefinition","name":{"kind":"Name","value":"cmsRoute"},"arguments":[{"kind":"InputValueDefinition","name":{"kind":"Name","value":"url"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}},"directives":[]}],"type":{"kind":"NamedType","name":{"kind":"Name","value":"CmsRoute"}},"directives":[]}]},{"kind":"EnumTypeDefinition","name":{"kind":"Name","value":"CmsRouteType"},"directives":[],"values":[{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"CMS_PAGE"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"PRODUCT"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"CATEGORY"},"directives":[]}]},{"kind":"ObjectTypeDefinition","name":{"kind":"Name","value":"CmsRoute"},"interfaces":[],"directives":[],"fields":[{"kind":"FieldDefinition","name":{"kind":"Name","value":"id"},"arguments":[],"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"type"},"arguments":[],"type":{"kind":"NamedType","name":{"kind":"Name","value":"CmsRouteType"}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"url"},"arguments":[],"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"sku"},"arguments":[],"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"redirectCode"},"arguments":[],"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}},"directives":[]}]}],"loc":{"start":0,"end":226}};
    doc.loc.source = {"body":"extend type Query {\n    cmsRoute(url: String): CmsRoute\n}\n\nenum CmsRouteType {\n    CMS_PAGE\n    PRODUCT\n    CATEGORY\n}\n\ntype CmsRoute {\n    id: ID\n    type: CmsRouteType\n    url: String\n    sku: String\n    redirectCode: Int\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    

import Container from '@pwa-onilab/ui/components/UI/Container'

import styles from './StrainsForSalePagePlaceholder.module.scss'

const StrainsForSalePagePlaceholder = () => (
    <Container>
        <div className={styles.breadcrumbs} />
        {
            Array.from({ length: 4 }).map((_, index) => (
                <div
                    key={index}
                    className={styles.wrapper}
                >
                    <div className={styles.title} />
                    <div className={styles.content} />
                </div>
            ))
        }
    </Container>
)

export default StrainsForSalePagePlaceholder

import { useStockOnlyFilter } from '@pwa-onilab/ui/components/modules/Filters'
import { IFiltersFunctionsApi } from '@pwa-onilab/ui/components/modules/Filters/context'
import Toggler from '@pwa-onilab/ui/components/UI/Toggler'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { useTranslation } from 'react-i18next'

import css from './FilterOptionsStockOnly.module.scss'

interface FilterOptionsStockOnlyProps extends IClassName, Pick<IFiltersFunctionsApi, 'onChangeValue'>{
    isActive: boolean
}

const FilterOptionsStockOnly = (
    {
        className,
        onChangeValue,
        isActive,
    }: FilterOptionsStockOnlyProps,
) => {
    const { t } = useTranslation()

    const { isActiveToggle, onToggleHandler } = useStockOnlyFilter(isActive, onChangeValue)

    return (
        <div className={classNames(className)}>
            <div className={css.groupTitle}>
                {t('filters.showInStock.availability')}
            </div>
            <div className={css.wrapper}>
                <div className={css.title}>
                    {t('filters.showInStock')}
                </div>
                <Toggler
                    handleToggle={onToggleHandler}
                    isActive={isActiveToggle}
                />
            </div>
        </div>
    )
}

export default FilterOptionsStockOnly

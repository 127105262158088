import { Product } from '@pwa-concept/modules/graphql'
import { MediaBreakpointDown, MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import {
    CategoryProductCardWrapper,
} from '@pwa-onilab/ui/components/modules/Product'
import { OUT_OF_STOCK } from '@pwa-onilab/ui/constants/products'
import { classNames } from '@pwa-onilab/ui/helpers'
import CategoryProductCardContent
    from 'components/overrides/modules/Product/CategoryProductCard/CategoryProductCardContent/CategoryProductCardContent'
import { CategoryProductCardViewAnchor } from 'modules/Product'

import styles from './CategoryProductCard.module.scss'

interface CategoryProductCardProps {
    className?: string
    product?: Product
}

const CategoryProductCard = (
    {
        className,
        product,
    }: Partial<CategoryProductCardProps>,
) => (
    <CategoryProductCardWrapper
        className={classNames(className, styles.wrapper)}
        isOutOfStock={product?.stock?.type === OUT_OF_STOCK}
    >
        <CategoryProductCardContent product={product} />
        <MediaBreakpointUp name="lg">
            <div className={styles.additional}>
                <CategoryProductCardViewAnchor product={product} />
            </div>
        </MediaBreakpointUp>
        <MediaBreakpointDown name="lg">
            <CategoryProductCardViewAnchor product={product} />
        </MediaBreakpointDown>
    </CategoryProductCardWrapper>
)

export default CategoryProductCard

import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

const goBack = () => {
    const navigate = useNavigate()

    return useCallback(() => navigate(-1), [])
}

export default goBack

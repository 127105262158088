import { useCmsRoute } from '@pwa-concept/modules/cms'
import { Breadcrumbs } from '@pwa-onilab/ui/components/modules/Breadcrumbs'
import { BreadcrumbsPageTypeEnum, useBreadCrumbs } from 'app/modules/breadcrumb'

const WorldPageBreadcrumbs = () => {
    const { data: cmsRoute } = useCmsRoute()
    const { data: breadcrumbs, loading: breadcrumbsLoading } = useBreadCrumbs({
        pageType: BreadcrumbsPageTypeEnum.WOLRD,
        urlKey: cmsRoute.url,
    })

    return <Breadcrumbs items={breadcrumbs} loading={breadcrumbsLoading} />
}

export default WorldPageBreadcrumbs

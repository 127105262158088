import { filterObjectToSearchString, filterSearchStringToObject } from '@pwa-onilab/ui/components/modules/Filters'
import { useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const initBestsellerPageCurrentValues = (search: string) => {
    const currentValues = filterSearchStringToObject(search)
    currentValues.filters = {
        ...currentValues.filters,
        is_bestseller: ['1'],
    }

    return currentValues
}

const useBestsellerPageFilterSortValues = () => {
    const { search } = useLocation()
    const navigate = useNavigate()

    const [currentValues, setCurrentValues] = useState(() => initBestsellerPageCurrentValues(search))

    const onChangeValue = useCallback((callback) => {
        setCurrentValues((prevValues) => {
            const newValues = callback(prevValues)
            const valuesForUrlParam = { ...newValues, filters: undefined }
            const newUrl = `${location.pathname}?${filterObjectToSearchString(valuesForUrlParam)}`
            navigate(newUrl)

            return newValues
        })
    }, [])

    return {
        currentValues,
        onChangeValue,
    }
}

export default useBestsellerPageFilterSortValues

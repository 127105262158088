import {
    AccountOrdersTable,
    AccountOrdersTableRow,
    AccountOrderTableHead,
    IAccountOrderHistoryWithItems,
} from '@pwa-onilab/ui/components/modules/AccountSubpages'

const AccountOrdersDesktop = ({ orders }: IAccountOrderHistoryWithItems) => {
    if (!orders?.length) {
        return null
    }

    return (
        <AccountOrdersTable>
            <AccountOrderTableHead />
            {
                orders.map((order) => <AccountOrdersTableRow key={order.id} order={order} />)
            }
        </AccountOrdersTable>
    )
}

export default AccountOrdersDesktop

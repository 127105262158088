export enum APOLLO_ERROR_TYPES {
    INCORRECT_PASSWORD = 'incorrectPassword',
    CANNOT_PERFORM_OPERATIONS = 'cannotPerformOperations',
    SOME_PRODUCTS_OUT_OF_STOCK = 'someProductsOutOfStock',
    REORDER_NOT_ADD_PRODUCT = 'reorderNotAddProduct'
}

export interface IApolloError {
    errorType: APOLLO_ERROR_TYPES
    errorMessages: string[]
}

export const APOLLO_ERRORS: IApolloError[] = [
    {
        errorType: APOLLO_ERROR_TYPES.INCORRECT_PASSWORD,
        errorMessages: [
            'Invalid login or password.',
            'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.',
        ],
    },

    {
        errorType: APOLLO_ERROR_TYPES.CANNOT_PERFORM_OPERATIONS,
        errorMessages: [
            'The current user cannot perform operations on cart',
        ],
    },
    {
        errorType: APOLLO_ERROR_TYPES.SOME_PRODUCTS_OUT_OF_STOCK,
        errorMessages: [
            'SOME_PRODUCTS_OUT_OF_STOCK',
            'Some of the products are out of stock.',
        ],
    },
]

export const APOLLO_REORDER_ERRORS: IApolloError[] = [
    {
        errorType: APOLLO_ERROR_TYPES.REORDER_NOT_ADD_PRODUCT,
        errorMessages: [
            'Could not add the product with SKU',
        ],
    },
]

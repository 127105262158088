import Loader from '@pwa-onilab/ui/components/UI/Loader'
import { useGlobalLoader } from '@pwa-onilab/ui/hooks/stateMachines'

const GlobalLoader = () => {
    const { useIsGlobalLoaderOpen } = useGlobalLoader()
    const isOpen = useIsGlobalLoaderOpen()

    return <Loader visible={isOpen} />
}
export default GlobalLoader

import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { Link } from '@pwa-onilab/ui/components/UI/Link'
import { classNames } from '@pwa-onilab/ui/helpers'
import { MouseEventHandler, PropsWithChildren } from 'react'

import styles from './LinkArrow.module.scss'

const VARIANTS = {
    default: styles.default,
    filter: styles.filter,
    white: styles.white,
    withoutArrow: styles.withoutArrow,
}

interface LinkArrowProps {
    isExternal?: boolean
    to?: string
    className?: string
    arrowClassName?: string
    onClick?: MouseEventHandler
    withArrow?: boolean
    additionalStyle?: object
    variant?: keyof typeof VARIANTS
}

const LinkArrow = (
    {
        isExternal,
        to,
        children,
        className,
        arrowClassName,
        onClick,
        withArrow,
        variant = 'default',
        additionalStyle,
    }: PropsWithChildren<LinkArrowProps>,
) => {
    const classes = classNames(
        styles.linkArrow,
        variant && VARIANTS?.[variant],
        className,
    )

    const content = (
        <span style={additionalStyle}>
            {children}
        </span>
    )

    if (!to?.length) {
        return (
            <span
                style={additionalStyle}
                onClick={onClick}
                className={classes}
            >
                {content}
                {
                    withArrow && (
                        <Icon
                            name="nextArrow"
                            style={additionalStyle}
                            className={classNames(styles.arrowIcon, arrowClassName)}
                        />
                    )
                }
            </span>
        )
    }

    return (
        isExternal ? (
            <a
                href={to}
                onClick={onClick}
                style={additionalStyle}
                className={classes}
            >
                {content}
                {
                    withArrow && (
                        <Icon
                            name="nextArrow"
                            style={additionalStyle}
                            className={classNames(styles.arrowIcon, arrowClassName)}
                        />
                    )
                }
            </a>
        ) : (
            <Link
                to={to}
                onClick={onClick}
                className={classes}
                style={additionalStyle}
            >
                {content}
                {
                    withArrow && (
                        <Icon
                            name="nextArrow"
                            style={additionalStyle}
                            className={classNames(styles.arrowIcon, arrowClassName)}
                        />
                    )
                }
            </Link>
        )
    )
}

export default LinkArrow

import { classNames } from '@pwa-onilab/ui/helpers'
import { useTranslation } from 'react-i18next'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import { useFilterApiContext } from '@pwa-onilab/ui/components/modules/Filters/context'

import styles from './FilterClearAll.module.scss'

interface FilterClearAllProps {
    className?: string
}

const FilterClearAll = (
    {
        className,
    }: FilterClearAllProps,
) => {
    const { t } = useTranslation()

    const { onChangeValue } = useFilterApiContext()

    const onClearAllClick = () => {
        return onChangeValue((prev) => ({ ...prev, filters: {} }))
    }

    return (
        <ButtonInstance
            className={classNames(styles.button, className)}
            onClick={onClearAllClick}
        >
            {t('global.clearAll')}
        </ButtonInstance>
    )
}

export default FilterClearAll

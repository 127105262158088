import { useCms } from '@pwa-concept/modules/cms'
import useCmsRoute from '@pwa-concept/modules/cms/features/get/hooks/useCmsRoute'
import ParserBounded from '@pwa-onilab/ui/components/elements/ParserBounded/ParserBounded'
import { CmsPageBreadcrumbs } from '@pwa-onilab/ui/components/modules/CmsPage'
import CmsPageHelmet from '@pwa-onilab/ui/components/modules/CmsPage/elements/CmsPageHelmet'
import { Error404 } from '@pwa-onilab/ui/components/modules/Error404'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { CmsPageContent, CmsPageMenu } from '@pwa-onilab/ui/components/widgets/CmsPage'
import { classNames } from '@pwa-onilab/ui/helpers'
import CmsPageWrapper from 'components/overrides/widgets/CmsPage/CmsPageWrapper/CmsPageWrapper'
import { CMSPageDesktopSkeleton } from 'overrides/@pwa-onilab/ui/pages/CMSPage/CMSPageDesktop/CMSPageDesktop.skeleton'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import styles from './CMSPageDesktop.module.scss'

const CMSPageDesktop = () => {
    const { id = null } = useParams()
    const [isCmsPageWithoutMenu, setIsCmsPageWithoutMenu] = useState<boolean>(false)

    const { data: cmsRoute } = useCmsRoute()

    const {
        data,
        loading,
    } = useCms({ id })

    const isOneColumnPage = cmsRoute?.displayMode !== 'full_width'

    if (loading) {
        return <CMSPageDesktopSkeleton isOneColumnPage={isOneColumnPage} />
    }

    if (!data) {
        return <Error404 />
    }

    const cmsPageContentClassName = isCmsPageWithoutMenu
        ? styles.rightContentWrapperWithoutMenu
        : styles.rightContentWrapper

    return (
        <Container>
            <CmsPageHelmet cms={data} />
            <CmsPageBreadcrumbs
                currentCmsPageTitle={data.title}
                className={styles.breadcrumbs}
            />
            <CmsPageWrapper
                isOneColumnPage={isOneColumnPage}
                className={classNames(
                    styles.contentMargin,
                    isOneColumnPage && styles.contentOneColumn,
                )}
            >
                <CmsPageMenu
                    cmsId={data.id || null}
                    setIsCmsPageWithoutMenu={setIsCmsPageWithoutMenu}
                />
                <CmsPageContent className={cmsPageContentClassName}>
                    <h1>{data.title}</h1>
                    {
                        data.content && (
                            <ParserBounded content={data.content} />
                        )
                    }
                </CmsPageContent>
            </CmsPageWrapper>
        </Container>
    )
}

export default CMSPageDesktop

import { BACKSPACE_KEYCODE } from '@pwa-onilab/ui/constants/keys/keycodes'

type tFormatInputRangeField = (
    event: React.ChangeEvent<HTMLInputElement>,
    lastKeyCode: number,
    min: number,
    max: number
) => void

export const formatInputRangeFieldEvent: tFormatInputRangeField = (
    event,
    lastKeyCode,
    min,
    max,
) => {
    let value = +event.target.value

    if (Number.isNaN(value)) {
        return
    }

    const numMax = +max
    const numMin = +min

    if (value < numMin) {
        value = numMin
    } else if (value > numMax) {
        value = numMax
    }

    const newValue = value.toFixed(2)
    const selectionStart = event.target.selectionStart

    let correction = 0

    const isDeleteOperation = lastKeyCode === BACKSPACE_KEYCODE
    let nextChar

    if (isDeleteOperation) {
        nextChar = newValue[selectionStart - 1]
    } else {
        nextChar = newValue[selectionStart]
    }
    if (nextChar === '.') {
        correction = isDeleteOperation ? -1 : 1
    }

    event.target.value = newValue
    event.target.setSelectionRange(selectionStart + correction, selectionStart + correction)
}

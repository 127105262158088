import { FamilyStrain } from '@pwa-concept/modules/graphql'
import { splitArraysOnColumns } from '@pwa-onilab/ui/helpers'
import {
    IStrainFamilyAllFamiliesColumns,
    STRAIN_FAMILY_ALL_FAMILIES_COLUMN_COUNT,
    StrainFamilyAllFamiliesColumn,
} from 'modules/StrainFamily'

const StrainFamilyAllFamiliesColumnsDesktop = ({ items, familyHomepageUrlKey }: IStrainFamilyAllFamiliesColumns) => {
    const realColumnsCount = items.length > STRAIN_FAMILY_ALL_FAMILIES_COLUMN_COUNT
        ? STRAIN_FAMILY_ALL_FAMILIES_COLUMN_COUNT
        : items.length

    const columns = splitArraysOnColumns<FamilyStrain>(items, realColumnsCount)

    return (
        <>
            {columns.map((column, columnIdx) => {
                return (
                    <StrainFamilyAllFamiliesColumn
                        key={columnIdx}
                        items={column}
                        familyHomepageUrlKey={familyHomepageUrlKey}
                    />
                )
            })}
        </>
    )
}

export default StrainFamilyAllFamiliesColumnsDesktop

import { SelectedTextActive } from '@pwa-onilab/ui/components/modules/SelectedText'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { MouseEventHandler } from 'react'

import styles from './FaqAccordionItemTrigger.module.scss'

interface IFaqAccordionItemTriggerProps {
    className: string
    isActive: boolean
    onToggle: MouseEventHandler
    title: string
    benchmark: string
}

const FaqAccordionItemTrigger = (
    {
        className,
        onToggle,
        isActive,
        title,
        benchmark,
    }: Partial<IFaqAccordionItemTriggerProps>,
) => (
    <ButtonInstance
        className={classNames(styles.trigger, className)}
        onClick={onToggle}
    >
        {
            (title && benchmark) ? (
                <SelectedTextActive
                    text={title}
                    benchmark={benchmark}
                    wrapperClassName={styles.title}
                    activeClassName={styles.active}
                />
            ) : (
                <div className={styles.title}>
                    {title}
                </div>
            )
        }
        <Icon
            name={isActive ? 'minus' : 'plus'}
            className={styles.icon}
        />
    </ButtonInstance>
)

export default FaqAccordionItemTrigger

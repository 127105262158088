import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import {
    FooterBottom,
    FooterMenu,
    SocialMedia,
    Subscription,
    VARIANT_ICONS_WITH_TITLE,
} from '@pwa-onilab/ui/components/modules/Footer'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { classNames } from '@pwa-onilab/ui/helpers'
import { memo } from 'react'
import AgePopUp from 'modules/Footer/CustomerService/AgePopUp/AgePopUp'

import styles from './FooterContent.module.scss'

interface IFooterContentProps {
    isHideFooter: boolean
    className: string
}

const FooterContent = (
    {
        isHideFooter,
        className,
    }: Partial<IFooterContentProps>,
) => {
    const isMobile = useMediaBreakpoint().isBreakpointDown('lg')

    return (
        <footer className={classNames(styles.footer, isHideFooter && styles.footerHidden, className)}>
            <div className={styles.topFooter}>
                <Container className={styles.container}>
                    {isMobile && <Subscription className={styles.subscription} />}
                    <FooterMenu className={styles.navigation} />
                    <div>
                        {!isMobile && <Subscription className={styles.subscription} />}
                        <SocialMedia
                            className={styles.socialMedia}
                            variant={VARIANT_ICONS_WITH_TITLE}
                        />
                    </div>
                </Container>
            </div>
            <FooterBottom />
            <AgePopUp />
        </footer>
    )
}

export default memo(FooterContent)

import { Product } from '@pwa-concept/modules/graphql'

interface CommonCartItemData {
    imageUrl: string
    imageAlt: string
    name: Product['name']
    sku: Product['sku']
    url: Product['url']
    labels: ProductLabel[]
}

export const mapCartProductData = (product, configuredVariant = null): CommonCartItemData => {
    const productForMapping = configuredVariant || product

    const { sku } = product

    const {
        thumbnail: {
            url: imageUrl,
            description: imageAlt,
        } = {},
        name,
        url,
        labels,
    } = productForMapping

    return {
        imageUrl,
        imageAlt,
        name,
        sku,
        url,
        labels,
    }
}

import { PasswordField } from '@pwa-onilab/ui/components/elements/Fields'
import Modal, { ModalProps } from '@pwa-onilab/ui/components/elements/Modal/Modal'
import {
    useAccountOtpAddPasswordRules,
    useAccountOtpAddPasswordSubmit,
} from '@pwa-onilab/ui/components/modules/Account'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import Title from '@pwa-onilab/ui/components/UI/Title'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import styles from './AccountOtpCreatePassword.module.scss'

interface IAccountOtpCreatePassword extends Pick<ModalProps, 'isOpen' | 'onDismiss'> {
    token: string
}

const AccountOtpCreatePassword = ({ isOpen, onDismiss, token }: IAccountOtpCreatePassword) => {
    const { t } = useTranslation()

    const { handleSubmit, setValue, register, formState: { errors }, reset } = useForm({
        defaultValues: {
            newPassword: '',
        },
    })

    const { submitHandler, isLoading } = useAccountOtpAddPasswordSubmit({ onDismiss, reset, token })
    const newPasswordRules = useAccountOtpAddPasswordRules()

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={onDismiss}
            isUseBorderRadius
            isShowCloseButton
            isHideMobileCloseButton
            className={styles.modal}
            isDraggable
            isOverAll
        >
            <Title type="h2" text={t('oneTimePassword.createNewPassword')} className={styles.title} />
            <form onSubmit={handleSubmit(submitHandler)}>
                <PasswordField
                    field="newPassword"
                    placeholder={t('global.placeholder.newPassword')}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                    rules={newPasswordRules}
                />
                <Button type="submit" isFullWidth className={styles.button} disabled={isLoading}>
                    {t('global.buttons.save')}
                </Button>
            </form>
        </Modal>
    )
}

export default AccountOtpCreatePassword

import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const storeConfigCategoryStainsMeta: QueryResolvers['storeConfigCategoryStainsMeta'] = async (_, inputObj) => {
    const { data: { storeConfig = {} } = {} } = await (
        api.graphql(
            gql`
                query {
                    storeConfig {
                        categories_as_strains {
                            meta_description
                            meta_keywords
                            meta_robots
                            meta_title
                            cms_block
                            category {
                                id
                            }
                            page_title
                        }
                    }
                }
            `,
        ).query(undefined, {
            errorPolicy: 'all',
        })
    )

    if (!storeConfig?.categories_as_strains) {
        return null
    }

    return storeConfig.categories_as_strains.map((context) => {
        return {
            meta: {
                title: context.meta_title || '',
                keywords: context.meta_keyword || '',
                description: context.meta_description || '',
                robots: context.meta_robots || '',
                __typename: 'StoreConfigDefaultPageMeta',
            },

            categoryId: String(context.category?.id),
            pageTitle: context.page_title || '',
            cmsBlock: context.cms_block || '',
            __typename: 'CategoriesAsStainsMeta',
        }
    })
}

export default storeConfigCategoryStainsMeta

import * as Types from '../../../../graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type StoreConfigNotFoundPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type StoreConfigNotFoundPageDataQuery = { __typename?: 'Query', storeConfigNotFoundPageData?: { __typename?: 'StoreConfigNotFoundPageData', buttonUrl?: string | null, buttonText?: string | null, urlsListTitle?: string | null, urlsList?: Array<{ __typename?: 'StoreConfigNotFoundPageUrl', url?: string | null, text?: string | null } | null> | null } | null };


export const StoreConfigNotFoundPageDataDocument = gql`
    query StoreConfigNotFoundPageData {
  storeConfigNotFoundPageData @client {
    buttonUrl
    buttonText
    urlsListTitle
    urlsList {
      url
      text
    }
  }
}
    `;

/**
 * __useStoreConfigNotFoundPageDataQuery__
 *
 * To run a query within a React component, call `useStoreConfigNotFoundPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreConfigNotFoundPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreConfigNotFoundPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useStoreConfigNotFoundPageDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StoreConfigNotFoundPageDataQuery, StoreConfigNotFoundPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<StoreConfigNotFoundPageDataQuery, StoreConfigNotFoundPageDataQueryVariables>(StoreConfigNotFoundPageDataDocument, options);
      }
export function useStoreConfigNotFoundPageDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StoreConfigNotFoundPageDataQuery, StoreConfigNotFoundPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<StoreConfigNotFoundPageDataQuery, StoreConfigNotFoundPageDataQueryVariables>(StoreConfigNotFoundPageDataDocument, options);
        }
export type StoreConfigNotFoundPageDataQueryHookResult = ReturnType<typeof useStoreConfigNotFoundPageDataQuery>;
export type StoreConfigNotFoundPageDataLazyQueryHookResult = ReturnType<typeof useStoreConfigNotFoundPageDataLazyQuery>;
export type StoreConfigNotFoundPageDataQueryResult = Apollo.QueryResult<StoreConfigNotFoundPageDataQuery, StoreConfigNotFoundPageDataQueryVariables>;
import { classNames } from '@pwa-onilab/ui/helpers'
import { IStatePageHeaderNavList } from 'elements/BlogStatePages/elements'
import { getBlogStatePageAnchorHash } from 'elements/BlogStatePages/helplers/getBlogStatePageAnchorHash'
import { useCallback } from 'react'
import { HashLink } from 'react-router-hash-link'

import styles from './StatePageList.module.scss'

const StatePageList = (
    {
        headerRefs,
        headers,
        activeHeader,
        className,
        onClick,
    }: IStatePageHeaderNavList,
) => {
    const onLiClick = useCallback((headerIdx: number) => () => {
        onClick?.(headers[headerIdx])
    }, [headerRefs, onClick])

    return (
        <ul className={classNames(className)}>
            {headers.map((header, idx) => {
                const isActive = header === activeHeader
                const linkHash = getBlogStatePageAnchorHash(header)

                return (
                    <li
                        key={header + idx.toString(10)}
                        className={classNames(styles.item, isActive && styles.activeItem)}
                    >
                        <HashLink
                            smooth
                            to={`#${linkHash}`}
                            onClick={onLiClick(idx)}
                            className={styles.link}
                        >
                            {header}
                        </HashLink>
                    </li>
                )
            })}
        </ul>
    )
}

export default StatePageList

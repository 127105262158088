import { useQuery } from '@apollo/client'
import { GetLocationsQuery } from 'schemas'
import { LocationShortFragment } from 'schemas/fragments/interfaces'

interface IUseLocationsQuery {
    data: LocationShortFragment[]
    loading: boolean
}

const useLocationsQuery = (): IUseLocationsQuery => {
    const { data, loading } = useQuery(GetLocationsQuery)

    return {
        data: data?.getLocations,
        loading,
    }
}

export default useLocationsQuery

import { Resolvers } from '@pwa-concept/modules/graphql'

// TODO purchaseOrderNumber seems to be unused in pwa. we can stop getting this info
const CartSelectedPaymentMethod: Resolvers['CartSelectedPaymentMethod'] = {
    code: (_, __, { context }) => context?.code || null,
    title: (_, __, { context }) => context?.title || null,
    purchaseOrderNumber: (_, __, { context }) => context?.purchase_order_number || null,
}

export default CartSelectedPaymentMethod

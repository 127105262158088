import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const storeConfigNotFoundPageData: QueryResolvers['storeConfigNotFoundPageData'] = async (_, inputObj) => {
    const { data: { storeConfig: __context = {} } = {} } = await (
        api.graphql(
            gql`
                query {
                    storeConfig {
                        not_found_page_urls_list_title
                        not_found_page_button_text
                        not_found_page_button_url
                        not_found_page_urls_list {
                            url
                            text
                        }
                    }
                }
            `,
        ).query(undefined, {
            errorPolicy: 'all',
        })
    )

    return {
        buttonText: __context?.not_found_page_button_text || null,
        buttonUrl: __context?.not_found_page_button_url || null,
        urlsListTitle: __context?.not_found_page_urls_list_title || null,
        urlsList: __context?.not_found_page_urls_list || null,
        __typename: 'StoreConfigWholeSale',
    }
}

export default storeConfigNotFoundPageData

import { CustomerOrder } from '@pwa-concept/modules/graphql'
import { AccountOrderViewButton } from '@pwa-onilab/ui/components/modules/AccountSubpages'
import { formatOrderDate } from '@pwa-onilab/ui/components/modules/AccountSubpages/AccountOrderHistory/helpers'
import Price from '@pwa-onilab/ui/components/UI/Price'
import { TableCell } from '@pwa-onilab/ui/components/UI/TableCell'
import { TableRow } from '@pwa-onilab/ui/components/UI/TableRow'
import { useTranslation } from 'react-i18next'

import styles from './AccountOrdersTableRow.module.scss'

interface IAccountOrdersTableRow {
    order: CustomerOrder
}

const AccountOrdersTableRow = (
    {
        order: {
            number,
            date,
            status,
            paymentMethod: {
                name: paymentName,
            },
            totals: {
                total,
            },
        },
    }: IAccountOrdersTableRow) => {
    const { t } = useTranslation()

    return (
        <TableRow>
            <TableCell className={styles.numberCell}>{t('global.orderNumber', { number })}</TableCell>
            <TableCell>{formatOrderDate(date).date}</TableCell>
            <TableCell>{status}</TableCell>
            <TableCell>{paymentName}</TableCell>
            <TableCell>
                <Price isReplaceSpace className={styles.price}>
                    {total}
                </Price>
            </TableCell>
            <TableCell>
                <AccountOrderViewButton orderNumber={number} />
            </TableCell>
        </TableRow>
    )
}

export default AccountOrdersTableRow

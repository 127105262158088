import { useCustomerAddresses } from '@pwa-concept/modules/customer-addresses'
import {
    AccountAddressBookPlaceholder,
    AccountAddresses,
    AccountAddressesEmpty,
} from '@pwa-onilab/ui/components/modules/AccountSubpages'
import AddressModalCreate from '@pwa-onilab/ui/components/modules/AddressModals/AddressModalCreate'
import { useModalStates } from '@pwa-onilab/ui/hooks'

const AccountAddressBook = () => {
    const { loading, isEmptyAddressBook } = useCustomerAddresses()

    const { isModalOpen, openModal, closeModal } = useModalStates()

    if (loading) {
        return <AccountAddressBookPlaceholder />
    }

    return (
        <>
            {
                isEmptyAddressBook
                    ? <AccountAddressesEmpty openCreateNewAddressModal={openModal} />
                    : <AccountAddresses openCreateNewAddressModal={openModal} />
            }
            <AddressModalCreate isOpen={isModalOpen} onDismiss={closeModal} />
        </>
    )
}

export default AccountAddressBook

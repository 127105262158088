import { Resolvers } from '@pwa-concept/modules/graphql'

import Query from './Query'
import Mutation from './Mutation'
import Breadcrumb from './Breadcrumb'

const resolvers: Resolvers = {
    Query,
    Mutation,
    Breadcrumb,
}

export default resolvers

import { CustomerAddress } from '@pwa-concept/modules/graphql'
import { AccountAddress, IAccountAddress } from '@pwa-onilab/ui/components/modules/AccountAddress'

import styles from './AccountAddressesList.module.scss'

interface IAccountAddressesList extends Pick<IAccountAddress, 'openDeleteAddressModal' | 'openUpdateAddressModal'>{
    addressList: CustomerAddress[]
}

const AccountAddressesList = (
    {
        addressList,
        openDeleteAddressModal,
        openUpdateAddressModal,
    }: IAccountAddressesList,
) => {
    if (!addressList?.length) {
        return null
    }

    return (
        <ul className={styles.wrapper}>
            {addressList.map((address) => {
                return (
                    <AccountAddress
                        key={address.id}
                        address={address}
                        openUpdateAddressModal={openUpdateAddressModal}
                        openDeleteAddressModal={openDeleteAddressModal}
                        className={styles.card}
                    />
                )
            })}
        </ul>
    )
}

export default AccountAddressesList

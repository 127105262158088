import { SearchList } from '@pwa-onilab/ui/components/modules/Search'
import { ISearchCategory } from '@pwa-onilab/ui/components/modules/Search/hooks/ISearchCategory'
import { classNames } from '@pwa-onilab/ui/helpers'
import SearchEmptyResult from 'components/overrides/modules/Search/elements/SearchEmptyResult/SearchEmptyResult'
import { SearchBlogPosts } from 'modules/Search'
import { useTranslation } from 'react-i18next'

import styles from './SearchResultNotFound.module.scss'

interface ISearchResultNotFoundProps {
    searchValue: string
    popularSearchItems: string[]
    categories: ISearchCategory[]
}

// TODO override SearchResult, rm searchValue prop
const SearchResultNotFound = (
    {
        searchValue,
        popularSearchItems,
        categories,
    }: ISearchResultNotFoundProps,
) => {
    const { t } = useTranslation()

    return (
        <div className={styles.wrapper}>
            <div className={classNames(styles.aside, !!categories?.length && styles.asideIndent)}>
                <SearchList
                    items={categories}
                    title={t('search.categories')}
                />
            </div>
            <div className={styles.content}>
                <SearchEmptyResult className={styles.message} />
                <SearchList
                    items={popularSearchItems}
                    title={t('search.popular.searches')}
                />
                <SearchBlogPosts />
            </div>
        </div>
    )
}

export default SearchResultNotFound

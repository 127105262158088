import { Skeleton } from '@pwa-onilab/ui/components/elements/Skeleton'

import css from './CategoryNavCard.module.scss'

interface ICategoryNavCardSkeletonProps {
    skeletonCount?: number
}

const CategoryNavCardSkeleton = (
    {
        skeletonCount,
    }: ICategoryNavCardSkeletonProps,
) => (
    <>
        {!!skeletonCount && (
            Array
                .from({ length: skeletonCount })
                .map((_, idx) => (
                    <Skeleton key={idx} className={css.skeleton} />
                ))
        )}
    </>
)

export { CategoryNavCardSkeleton }

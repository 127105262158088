import { AdditionalField } from '@pwa-concept/modules/graphql'

export const extractRequestAdditionalFields = (additionalFields: AdditionalField[]) => {
    const objResult = {}
    additionalFields?.forEach(({ key, value }) => {
        objResult[key] = value
    })

    return objResult
}

import { WishlistCountItems, WishlistShareMobile } from '@pwa-onilab/ui/components/modules/Wishlist'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useTranslation } from 'react-i18next'

import styles from './WishlistMobileModalHeader.module.scss'

interface WishlistMobileModalHeaderProps {
    className: string
    numAllItems: number
}

const WishlistMobileModalHeader = (
    {
        className,
        numAllItems,
    }: Partial<WishlistMobileModalHeaderProps>,
) => {
    const { t } = useTranslation()

    return (
        <div className={classNames(styles.header, className)}>
            <div className={styles.centerBlock}>
                <div className={styles.title}>
                    {t('wishlist.title')}
                </div>
                <WishlistCountItems count={numAllItems} />
            </div>
            <WishlistShareMobile className={styles.share} />
        </div>
    )
}

export default WishlistMobileModalHeader

import { GroupMobileModal } from '@pwa-onilab/ui/components/modules/Footer'
import { CUSTOMER_SERVICE } from 'constants/customFooterLinks'
import { Navigation } from '@pwa-onilab/ui/components/modules/Footer'

import styles from './CustomerServiceModalMenu.module.scss'

interface ICustomerServiceModalMenuProps {
    closeModal: () => void
    isModalOpen: boolean
    items: Array<{
        content: {
            urlPath: string
        }
        nodeId: number
        title: string
    }>
}

const CustomerServiceModalMenu = (
    {
        closeModal,
        isModalOpen,
        items,
    }: ICustomerServiceModalMenuProps,
) => (
    <GroupMobileModal
        title={CUSTOMER_SERVICE}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
    >
        <div className={styles.contentWrapper}>
            <Navigation
                withArrow
                items={items}
            />
        </div>
    </GroupMobileModal>
)

export default CustomerServiceModalMenu

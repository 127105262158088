import { REGEX_EMAIL } from '@pwa-onilab/ui/constants/patterns'
import { useUnSubscribeEmailFromNewsletter } from '@pwa-onilab/ui/hooks/emailSubscription'
import InputField from 'components/overrides/elements/Fields/InputField/InputField'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import { useTranslation } from 'react-i18next'

import styles from './PromoCodeForm.module.scss'

interface IPromoCodeFormProps {
    emailPlaceholder: string
    buttonText: string
}

const PromoCodeForm = (
    {
        emailPlaceholder,
        buttonText,
    }: IPromoCodeFormProps,
) => {
    const { t } = useTranslation()

    const {
        register,
        errors,
        handleSubmit,
        onSubmitSubscribeEmailForm,
        subscribeEmailToNewsletterLoading,
        disabled,
    } = useUnSubscribeEmailFromNewsletter()

    return (
        <form
            className={styles.form}
            onSubmit={handleSubmit(onSubmitSubscribeEmailForm)}
        >
            <InputField
                placeholder={emailPlaceholder || t('notify.outOfStock.enterEmail')}
                field="email"
                register={register}
                errors={errors}
                rules={{
                    required: t('global.errors.required'),
                    pattern: {
                        value: REGEX_EMAIL,
                        message: t('global.errors.email'),
                    },
                }}
                inputBoxClass={styles.inputBox}
                inputErrorClass={styles.inputError}
                className={styles.input}
            />
            <Button
                className={styles.button}
                type="submit"
                disabled={disabled || subscribeEmailToNewsletterLoading}
                isBlack
                isBold
            >
                {buttonText}
            </Button>
        </form>
    )
}

export default PromoCodeForm

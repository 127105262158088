import { Picture } from '@pwa-onilab/ui/components/UI/Picture'
import { PromoBannersMiniCard } from '@pwa-onilab/ui/components/widgets/PromoBanners'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './PromoBannersSlide.module.scss'

interface IPromoBannersSlideProps {
    desktopImage: string
    mobileImage: string
    linkText: string
    linkUrl: string
    title: string
    className: string
}

const PromoBannersSlide = (
    {
        desktopImage,
        mobileImage,
        title,
        linkText,
        linkUrl,
        className,
    }: Partial<IPromoBannersSlideProps>,
) => (
    <div className={classNames(styles.slide, className)}>
        <Picture
            src={desktopImage || mobileImage}
            srcSet={mobileImage || desktopImage}
            media="(max-width: 575.98px)"
            alt={title}
            className={styles.image}
        />
        <PromoBannersMiniCard
            title={title}
            linkText={linkText}
            linkUrl={linkUrl}
        />
    </div>
)

export default PromoBannersSlide

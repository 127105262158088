import {
    AccountSubpageEmpty,
    IAccountAddressContent,
} from '@pwa-onilab/ui/components/modules/AccountSubpages'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'

const AccountAddressesEmpty = ({ openCreateNewAddressModal }: IAccountAddressContent) => {
    const { t } = useTranslation()
    const { isBreakpointUp } = useMediaBreakpoint()

    return (
        <AccountSubpageEmpty
            buttonText={isBreakpointUp('lg') ? t('global.addNew') : t('AccountPage.addresses.addNew')}
            text={t('AccountPage.addresses.empty.title')}
            onClick={openCreateNewAddressModal}
        />
    )
}

export default memo(AccountAddressesEmpty)

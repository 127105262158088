import { useCartTotalsInfo } from '@pwa-concept/modules/cart'
import CartBadge from '@pwa-onilab/ui/components/elements/CartBadge'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import Price from '@pwa-onilab/ui/components/UI/Price'
import { useCartModal } from '@pwa-onilab/ui/hooks'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './CartButton.module.scss'

const CartButton = () => {
    const { t } = useTranslation()
    const { openModal } = useCartModal()

    const { data: { totalQuantity, totalPrice } } = useCartTotalsInfo()

    return (
        <ButtonInstance
            className={styles.button}
            onClick={openModal}
            aria-label={t('cart.title')}
        >
            <div className={styles.cartWrapper}>
                <Icon name="bag" className={styles.cartIcon} />
                <CartBadge
                    quantity={totalQuantity}
                    className={styles.cartBadge}
                    isUseResize
                />
            </div>
            <div className={styles.priceWrapper}>
                <div className={styles.amount}>
                    {t('global.amount')}
                </div>
                <Price
                    className={styles.price}
                    isReplaceSpace
                >
                    {totalPrice}
                </Price>
            </div>
        </ButtonInstance>
    )
}

export default memo(CartButton)

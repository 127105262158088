import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { useRouter, useSearchModal } from '@pwa-onilab/ui/hooks'
import { useTranslation } from 'react-i18next'

import styles from './SearchSeeAll.module.scss'

interface ISearchSeeAllProps {
    isHideButton: boolean
    searchValue: string
}

const SearchSeeAll = (
    {
        isHideButton,
        searchValue,
    }: ISearchSeeAllProps,
) => {
    if (isHideButton) {
        return null
    }

    const { getSearchUrl } = useRouter()
    const { closeSearchModal } = useSearchModal()

    const { t } = useTranslation()

    return (
        <div className={styles.wrapper}>
            <Anchor
                to={getSearchUrl(searchValue)}
                className={styles.link}
                onClick={closeSearchModal}
            >
                {t('search.result.seeAll')}
                <Icon
                    name="chevron"
                    className={styles.icon}
                />
            </Anchor>
        </div>
    )
}

export default SearchSeeAll

import { APOLLO_ERROR_TYPES } from '@pwa-concept/modules/constants'
import { getApolloErrorType } from '@pwa-concept/modules/helpers'
import { GraphQLError } from 'graphql/index'

const ALLOWED_ERRORS_ON_CART_MERGE = 1

const CART_UNIMPORTANT_ERRORS = [
    APOLLO_ERROR_TYPES.SOME_PRODUCTS_OUT_OF_STOCK,
    APOLLO_ERROR_TYPES.CANNOT_PERFORM_OPERATIONS,
]

const checkIsImportantError = (errorType: APOLLO_ERROR_TYPES) => {
    // eslint-disable-next-line @typescript-eslint/prefer-includes
    return CART_UNIMPORTANT_ERRORS.indexOf(errorType) === -1
}

export const tryThrowCartError = (errors: readonly GraphQLError[]) => {
    const isShouldThrowError = !!errors?.length &&
        (errors.length !== ALLOWED_ERRORS_ON_CART_MERGE || checkIsImportantError(getApolloErrorType(errors[0].message)))

    if (isShouldThrowError) {
        throw new Error(errors?.[0]?.message)
    }
}

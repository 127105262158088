import PaymentIcons from '@pwa-onilab/ui/assets/icons/payments'
import { PAYPAL_EXPRESS_PAYMENT_METHOD_CODE } from '@pwa-onilab/ui/constants/payments'

export const getPaymentIconByCode = (code: string) => {
    switch (code) {
        case PAYPAL_EXPRESS_PAYMENT_METHOD_CODE: {
            return PaymentIcons.paypal
        }
        default: {
            return null
        }
    }
}

export const getPaymentIconByCodeForModal = (code: string) => {
    switch (code) {
        case PAYPAL_EXPRESS_PAYMENT_METHOD_CODE: {
            return PaymentIcons.paypalWithText
        }
        default: {
            return null
        }
    }
}

export const getPaymentTitleByCode = (code: string) => {
    switch (code) {
        case PAYPAL_EXPRESS_PAYMENT_METHOD_CODE: {
            return 'Paypal'
        }
        default: {
            return null
        }
    }
}

import { Trans } from 'react-i18next'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { WISHLIST_ROUTE } from '@pwa-onilab/ui/constants/navigation'

import styles from './WishlistNotificationAdd.module.scss'

const WishlistNotificationAdd = () => {
    return (
        <Trans
            i18nKey="wishlist.notification.successAdd"
            components={{
                b: <b />,
                anchor: <Anchor to={WISHLIST_ROUTE} className={styles.link} />,
            }}
        />
    )
}

export default WishlistNotificationAdd

import { useCustomerOrderLast } from '@pwa-concept/modules/customer-orders'
import AccountInfoBlock from '@pwa-onilab/ui/components/modules/Account/AccountHeader/AccountInfoBlock'
import { useTranslation } from 'react-i18next'

const AccountLastOrder = () => {
    const { t } = useTranslation()
    const { data: { number } = {}, loading } = useCustomerOrderLast()

    return (
        <AccountInfoBlock
            title={t('AccountPage.header.orders.title')}
            loading={loading}
            message={number || t('AccountPage.header.orders.noOrders')}
            disabled
        />
    )
}

export default AccountLastOrder

import { ErrorPageInfo } from '@pwa-onilab/ui/components/modules/ErrorPage'
import { useTranslation } from 'react-i18next'

import styles from './FirstLevelError.module.scss'

const FirstLevelError = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.firstLevelWrapper}>
            <ErrorPageInfo errorText={t('global.errors.sorryTryLatter')} />
        </div>
    )
}

export default FirstLevelError

import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type VerifyOtpAuthMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  code: Types.Scalars['String'];
}>;


export type VerifyOtpAuthMutation = { __typename?: 'Mutation', verifyOTPAuth?: { __typename?: 'VerifyOTPRequestOutput', attemptsLeft?: number | null, token?: string | null, valid?: boolean | null } | null };


export const VerifyOtpAuthDocument = gql`
    mutation verifyOTPAuth($email: String!, $code: String!) {
  verifyOTPAuth(email: $email, code: $code) @client {
    attemptsLeft
    token
    valid
  }
}
    `;
export type VerifyOtpAuthMutationFn = Apollo.MutationFunction<VerifyOtpAuthMutation, VerifyOtpAuthMutationVariables>;

/**
 * __useVerifyOtpAuthMutation__
 *
 * To run a mutation, you first call `useVerifyOtpAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOtpAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOtpAuthMutation, { data, loading, error }] = useVerifyOtpAuthMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyOtpAuthMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyOtpAuthMutation, VerifyOtpAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<VerifyOtpAuthMutation, VerifyOtpAuthMutationVariables>(VerifyOtpAuthDocument, options);
      }
export type VerifyOtpAuthMutationHookResult = ReturnType<typeof useVerifyOtpAuthMutation>;
export type VerifyOtpAuthMutationResult = Apollo.MutationResult<VerifyOtpAuthMutation>;
export type VerifyOtpAuthMutationOptions = Apollo.BaseMutationOptions<VerifyOtpAuthMutation, VerifyOtpAuthMutationVariables>;
import { Product } from '@pwa-concept/modules/graphql'
import { classNames } from '@pwa-onilab/ui/helpers'
import { StrainsForSaleProductsList, StrainsForSaleProductsTitle } from 'modules/StrainsForSale'

import styles from './StrainsForSaleProductsGroup.module.scss'
import { useTranslation } from 'react-i18next'

interface IStrainsForSaleProductsGroupProps {
    title: string
    products: Product[]
    className?: string
}

const StrainsForSaleProductsGroup = (
    {
        title,
        products,
        className,
    }: IStrainsForSaleProductsGroupProps,
) => {
    const { t } = useTranslation()
    if (!products?.length) {
        return null
    }

    const scrollToTop = (event) => {
        event.preventDefault()
        const listingElement = document.getElementById(event.target.getAttribute('data-scroll'))
        if (listingElement) {
            listingElement.scrollIntoView({ behavior: 'smooth' })
        }
    }

    return (
        <div
            className={classNames(styles.wrapper, className)}
            id={`${title.toLowerCase()}`}
        >
            <StrainsForSaleProductsTitle title={title}/>
            <StrainsForSaleProductsList products={products}/>
            <div className={styles.backTop}>
                <span data-scroll="listing" onClick={scrollToTop}>
                    {t('Back to top')}
                </span>
            </div>
        </div>
    )
}

export default StrainsForSaleProductsGroup

import { ICartWithItems } from '@pwa-onilab/ui/components/modules/Cart/elements/CartWithItems/ICartWithItems'
import { useDeletingItemModal } from '@pwa-onilab/ui/hooks'
import { useCallback } from 'react'

const useCartWithItems = (
    {
        setLastDeletedItem,
    }: Pick<ICartWithItems, 'setLastDeletedItem' | 'setIsCartLoading'>,
) => {
    const {
        isOpen: isDeleteCartItemModalOpen,
        openModal: openDeleteCartItemModal,
        closeModal: closeDeleteCartItemModal,
        id: deletingItemId,
        possibleDeletedCartItem,
        indexInArray,
    } = useDeletingItemModal()

    const handleItemDeleting = useCallback(() => {
        setLastDeletedItem(possibleDeletedCartItem, indexInArray)
    }, [possibleDeletedCartItem, indexInArray])

    return {
        isDeleteCartItemModalOpen,
        openDeleteCartItemModal,
        closeDeleteCartItemModal,
        deletingItemId,

        handleItemDeleting,
    }
}

export default useCartWithItems

import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { DefaultHelmet, IDefaultHelmet } from '@pwa-onilab/ui/components/elements/DefaultHelmet'
import { SchemaHelmetWebPage } from 'elements/SchemaHelmet/SchemaHelmetWebPage'

const StrainsForSaleHelmet = (
    {
        meta,
        defaultTitle = '',
    }: IDefaultHelmet,
) => {
    const { data: storeConfig } = useStoreConfig()
    const defaultHelmetMeta: IDefaultHelmet['meta'] = {
        ...meta,
        ogType: 'article',
    }
    if (storeConfig.ogArticlePublisher) {
        defaultHelmetMeta.customProperties = [
            {
                name: 'article:publisher',
                value: storeConfig.ogArticlePublisher,
            },
        ]
    }

    return (
        <>
            <DefaultHelmet
                meta={defaultHelmetMeta}
                defaultTitle={defaultTitle}
            />
            <SchemaHelmetWebPage meta={defaultHelmetMeta} />
        </>
    )
}

export default StrainsForSaleHelmet

import { ADDRESS_FORM_FIELDS } from '@pwa-onilab/ui/components/modules/AddressForm'
import { ICheckboxesSection } from '@pwa-onilab/ui/components/modules/AddressForm/elements/sections/CheckboxesSection/CheckboxesSection'
import { ICheckboxForm } from '@pwa-onilab/ui/components/UI/Checkbox'
import CheckboxForm from '@pwa-onilab/ui/components/UI/Checkbox/CheckboxForm'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const UseDefaultShippingCheckbox = (
    {
        labelClassName,
        isCheckout,
        isShippingDefaultChecked,
    }: Pick<ICheckboxForm & ICheckboxesSection, 'labelClassName' | 'isCheckout' | 'isShippingDefaultChecked'>,
) => {
    const { t } = useTranslation()
    const { register } = useFormContext()

    return (
        <CheckboxForm
            register={register}
            id={ADDRESS_FORM_FIELDS.USE_AS_DEFAULT_SHIPPING}
            labelClassName={labelClassName}
            defaultChecked={isShippingDefaultChecked}
        >
            {t(isCheckout ? 'checkoutPage.address.checkbox.asShipping' : 'addressForm.setDefaultShipping.text')}
        </CheckboxForm>
    )
}

export default UseDefaultShippingCheckbox

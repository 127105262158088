import { FilterOptionsModalViewDesktop } from '@pwa-onilab/ui/components/modules/Filters'
import { IFilterOptions } from '@pwa-onilab/ui/components/modules/Filters/elements/FilterOptions/IFilterOptions'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { useModalStates } from '@pwa-onilab/ui/hooks'
import { useTranslation } from 'react-i18next'

import styles from './FilterOptionsModalButtonDesktop.module.scss'

const FilterOptionsModalButtonDesktop = ({ data: filter }: Pick<IFilterOptions, 'data'>) => {
    const { t } = useTranslation()
    const { isModalOpen, openModal, closeModal } = useModalStates()

    return (
        <div className={styles.wrapper} onMouseEnter={openModal} onMouseLeave={closeModal}>
            <div className={styles.container}>
                {t('filters.button.showAllOptions', { count: filter.items.length || 0 })}
                <Icon name="chevron" className={styles.iconArrow} />
            </div>
            {
                isModalOpen && <FilterOptionsModalViewDesktop data={filter} />
            }
        </div>
    )
}

export default FilterOptionsModalButtonDesktop

import { IAccountOneTimePassword } from '@pwa-onilab/ui/components/modules/Account'
import { useChangePasswordOTP, useModalStates } from '@pwa-onilab/ui/hooks'
import { useCallback, useEffect, useState } from 'react'

const useAccountOneTimePassword = ({ isOtpModalOpen, closeOtpModal, handleChangeEmail }: IAccountOneTimePassword) => {
    const [token, setToken] = useState<string>(null)

    const {
        handleOpenOneTimePassword,
        checkOneTimePassword,
        resendCode,
        attemptsLeft,
        timerValue,
        customerEmail,
        isResendCode,
        isRequestOpenOtpLoading,
    } = useChangePasswordOTP()

    const {
        isModalOpen: isNewPasswordModalOpen,
        openModal: openNewPasswordModal,
        closeModal: closeNewPasswordModal,
    } = useModalStates()

    useEffect(() => {
        if (isOtpModalOpen) {
            handleOpenOneTimePassword()
        }
    }, [isOtpModalOpen])

    const onChangeEmailClick = useCallback(() => {
        closeOtpModal()
        handleChangeEmail()
    }, [])

    const onValidCodeHandler = useCallback((newToken) => {
        if (newToken) {
            setToken(newToken)
        }
        closeOtpModal()
        openNewPasswordModal()
    }, [])

    return {
        checkOneTimePassword,
        resendCode,
        attemptsLeft,
        timerValue,
        customerEmail,
        isResendCode,
        isRequestOpenOtpLoading,

        token,

        isNewPasswordModalOpen,
        closeNewPasswordModal,

        onChangeEmailClick,
        onValidCodeHandler,
    }
}

export default useAccountOneTimePassword

import { CoreRouteObject, PROVIDE_ROUTER, useProvide } from '@pwa-concept/core'
import { parseRoutes } from '@pwa-concept/core/router/parseRoutes'
import { ReactNode, useMemo } from 'react'
import { RouteObject } from 'react-router-dom'

const useRouterConfig = (children?: ReactNode) => {
    const provideRoutes = useProvide<CoreRouteObject[]>(PROVIDE_ROUTER, [])

    return useMemo<RouteObject[]>(() => ([{
        path: '/',
        element: children,
        children: parseRoutes([
            ...provideRoutes,
        ]),
    }]), [children, provideRoutes])
}

export default useRouterConfig

import api from '@pwa-concept/core/api'
import { MutationResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const addProductsToWishlist: MutationResolvers['addProductsToWishlist'] = async (_, input) => {
    const {
        id,
        items,
        page,
        limit,
    } = input

    if (!id) {
        throw new Error('Cannot perform operation')
    }

    const { data: { addProductsToWishlistOnilab = {} } = {} } = (
        await api.graphql(
            gql`
                mutation(
                    $wishlistUid: String!
                    $wishlistItems: [WishlistItemInput!]!
                    $pageSize: Int = 20
                    $currentPage: Int = 1
                    $isFullProduct: Boolean = false
                ) {
                    addProductsToWishlistOnilab(
                        wishlistUid: $wishlistUid
                        wishlistItems: $wishlistItems
                    ) {
                        onilab_wishlist {
                            uid
                            items(
                                currentPage: $currentPage
                                pageSize: $pageSize
                            ) {
                                items {
                                    added_at
                                    description
                                    id
                                    product {
                                        ... ProductInterface
                                    }
                                }

                                page_info {
                                    current_page
                                    page_size
                                    total_pages
                                }
                            }
                            items_count
                            sharing_code
                        }
                        user_errors {
                            code
                            message
                        }
                    }
                }
            `,
        ).mutation({
            wishlistUid: id,
            wishlistItems: items,
            pageSize: limit,
            currentPage: page,
        })
    )
    // TODO can get respose and throw error if we whould throw in inside WishlistWithErrors resolver
    if (addProductsToWishlistOnilab?.user_errors?.length) {
        throw new Error(addProductsToWishlistOnilab.user_errors[0].message)
    }

    if (!addProductsToWishlistOnilab) {
        return null
    }

    return {
        __context: addProductsToWishlistOnilab,
        __typename: 'WishlistWithErrors',
    }
}

export default addProductsToWishlist

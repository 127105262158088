import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';
import { ProductVariantFragmentDoc } from './ProductVariant';
import { ProductOptionFragmentDoc } from './ProductOption';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

export type ConfigurableProductCommonFieldsFragment = { __typename?: 'Product', sku?: string | null, variants?: Array<{ __typename?: 'ProductVariant', options?: Array<{ __typename?: 'ProductVariantOption', key?: string | null, value?: string | null } | null> | null, product?: { __typename?: 'Product', id?: string | null, sku?: string | null, configuredSiblingSku?: string | null, name?: string | null, url?: string | null, description?: string | null, shortDescription?: string | null, stock?: { __typename?: 'ProductStock', name?: string | null, type?: Types.ProductStockType | null } | null, images?: Array<{ __typename?: 'ProductImage', id?: string | null, description?: string | null, url?: string | null } | null> | null, thumbnail?: { __typename?: 'ProductImage', id?: string | null, description?: string | null, url?: string | null } | null, price?: { __typename?: 'ProductPrice', discount?: number | null, isRange?: boolean | null, badges?: Array<{ __typename?: 'ProductPriceBadge', name?: string | null } | null> | null, current?: { __typename?: 'Money', value?: number | null, text?: string | null, currency?: string | null } | null, initial?: { __typename?: 'Money', value?: number | null, text?: string | null, currency?: string | null } | null } | null } | null } | null> | null, options?: Array<{ __typename?: 'ProductOption', id?: string | null, name?: string | null, key?: string | null, type?: Types.ProductOptionType | null, required?: boolean | null, kind?: Types.ProductOptionKind | null, values?: Array<{ __typename?: 'ProductOptionValue', name?: string | null, value?: string | null, colorValue?: string | null, isInStock?: boolean | null, url?: string | null, price?: { __typename?: 'Money', value?: number | null, text?: string | null, currency?: string | null } | null } | null> | null } | { __typename?: 'ProductOptionConfigurable' } | null> | null };

export const ConfigurableProductCommonFieldsFragmentDoc = gql`
    fragment ConfigurableProductCommonFields on Product {
  sku
  variants {
    ...ProductVariant
  }
  options {
    ...ProductOption
  }
}
    ${ProductVariantFragmentDoc}
${ProductOptionFragmentDoc}`;
import { Resolvers } from '@pwa-concept/modules/graphql'

import Mutation from './Mutation'
import VerifyOTPRequestOutput from './VerifyOTPRequestOutput'

const resolvers: Resolvers = {
    Mutation,
    VerifyOTPRequestOutput,
}

export default resolvers

import { makeVar, useReactiveVar } from '@apollo/client'

const isGlobalLoaderOpen = makeVar(false)

const openGlobalLoader = () => isGlobalLoaderOpen(true)
const closeGlobalLoader = () => isGlobalLoaderOpen(false)
const useIsGlobalLoaderOpen = () => useReactiveVar(isGlobalLoaderOpen)

const useGlobalLoader = () => {
    return {
        useIsGlobalLoaderOpen,
        openGlobalLoader,
        closeGlobalLoader,
    }
}

export default useGlobalLoader

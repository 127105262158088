import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { PasswordField } from '@pwa-onilab/ui/components/elements/Fields'
import { PERSONAL_INFORMATION_INPUT_SET_VALUE_OPTIONS } from '@pwa-onilab/ui/components/modules/Account'
import { checkPasswordIsStrong, getPasswordStrengthErrorMessage } from '@pwa-onilab/ui/helpers'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import styles from './PersonalInfoNewPassword.module.scss'

const PersonalInfoNewPassword = () => {
    const { t } = useTranslation()
    const { register, setValue, getValues, formState: { errors } } = useFormContext()
    const { data: { passwordMinLength, passwordMinStrength } = {} } = useStoreConfig()

    return (
        <PasswordField
            className={styles.newPasswordField}
            field="newPassword"
            placeholder={t('global.placeholder.newPassword')}
            register={register}
            setValue={setValue}
            setValueOptions={PERSONAL_INFORMATION_INPUT_SET_VALUE_OPTIONS}
            errors={errors}
            rules={{
                validate: {
                    passwordUnique: (newPasswordValue) => {
                        const { password: currentPassword } = getValues()
                        if (currentPassword.trim() !== newPasswordValue.trim()) {
                            return true
                        }

                        return t('password.error.notUnique')
                    },
                    passwordStrength: (value) => {
                        const isPassStrong = checkPasswordIsStrong(value, passwordMinStrength)
                        if (isPassStrong) {
                            return true
                        }
                        return t(getPasswordStrengthErrorMessage(passwordMinStrength))
                    },
                },
                minLength: {
                    value: passwordMinLength,
                    message: t('password.error.length', { minLength: passwordMinLength }),
                },
            }}
        />
    )
}

export default PersonalInfoNewPassword

import { CustomerAddress } from '@pwa-concept/modules/graphql'
import { IAccountAddress } from '@pwa-onilab/ui/components/modules/AccountAddress'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import { useTranslation } from 'react-i18next'
import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Icon from '@pwa-onilab/ui/components/UI/Icon'

import styles from 'modules/AccountAddress/AccountAddressButtonEdit/AccountAddressButtonEdit.module.scss'

interface IAccountAddressButtonEdit extends Pick<IAccountAddress & CustomerAddress, 'id' | 'openUpdateAddressModal'> {
    className?: string
}

const AccountAddressButtonEdit = (
    {
        id,
        openUpdateAddressModal,
        className,
    }: IAccountAddressButtonEdit,
) => {
    const { t } = useTranslation()

    const { isBreakpointUp } = useMediaBreakpoint()

    return (
        <ButtonInstance onClick={openUpdateAddressModal(id)} className={className}>
            {
                isBreakpointUp('lg') ? (
                    <Icon
                        name="pencil"
                        className={styles.icon}
                    />
                ) : (
                    <>
                        {t('address.buttons.edit')}
                    </>
                )
            }
        </ButtonInstance>
    )
}

export default AccountAddressButtonEdit

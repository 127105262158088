import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { AddressForm } from '@pwa-onilab/ui/components/modules/AddressForm'
import { IAddressModal } from '@pwa-onilab/ui/components/modules/AddressModal'
import { formSaveBeforeHideModal } from '@pwa-onilab/ui/models'
import { useCallback } from 'react'

import styles from './AddressModal.module.scss'

const AddressModal = (
    {
        isOpen,
        onDismiss,
        onSubmit,
        title,
        defaultValues,
        isCheckout,
        isBillingDefaultChecked,
        isShippingDefaultChecked,
        isBilling,
    }: IAddressModal) => {
    const onDismissWithSaveConfirmation = useCallback((event) => {
        const handleSaveReject = () => onDismiss(event)
        const isSaveModalOpen = formSaveBeforeHideModal.tryOpenModal(handleSaveReject)
        if (!isSaveModalOpen) {
            onDismiss(event)
        }
    }, [])

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={onDismissWithSaveConfirmation}
            isUseBorderRadius
            isUseDefaultPadding
            isDraggable
            isOverAll
            isShowCloseButton
            isHideMobileCloseButton
            className={styles.wrapper}
        >
            <AddressForm
                onSubmit={onSubmit}
                title={title}
                defaultValues={defaultValues}
                isCheckout={isCheckout}
                isBillingDefaultChecked={isBillingDefaultChecked}
                isShippingDefaultChecked={isShippingDefaultChecked}
                isBilling={isBilling}
            />
        </Modal>
    )
}

export default AddressModal

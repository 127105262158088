import { LiveSearchModule } from '@pwa-concept/modules/live-search'

import graphqlSchemas from './graphql/schemas'
import stores from './graphql/stores'

const AppLiveSearchModule = LiveSearchModule.extend((module, { concat }) => ({
    graphqlSchemas: concat(module?.graphqlSchemas, graphqlSchemas),
    graphqlResolvers: concat(module?.graphqlResolvers, stores?.resolvers),
    graphqlStoreFragments: concat(module?.graphqlStoreFragments, stores.fragments),

}))
export default AppLiveSearchModule

export const PRODUCT_THUMBNAIL_SLIDER_SETTING_DESKTOP = {
    slidesPerView: 'auto',
    direction: 'vertical',
    spaceBetween: 24,
}

export const IN_STOCK = 'IN_STOCK'
export const OUT_OF_STOCK = 'OUT_OF_STOCK'

export const PRODUCT_INFO_FORM_ID = 'productForm'
export const PRODUCT_REVIEWS_BLOCK_ID = 'productReviews'

export const PRODUCT_FORT_TYPE_QUANTITY = 'quantity'

export const REVIEW_COUNT_ON_PRODUCT_PAGE = 5

import { Filter } from '@pwa-concept/modules/graphql'
import { useCategoryNumResultsValue, useSetCategoryNumResults } from '@pwa-onilab/ui/components/modules/Category'
import { CategoryItems } from '@pwa-onilab/ui/components/modules/Category/CategoryItems'
import { FiltersBlock, useFiltersFunctions } from '@pwa-onilab/ui/components/modules/Filters'
import { FilterFunctionsContext } from '@pwa-onilab/ui/components/modules/Filters/context'

import css from './Filters.module.scss'

interface FilterProps {
    filters?: Filter[]
    initial?: ReturnType<any>
    categoryId?: string
}

// TODO renaming into categoryPageContent/ e.t.c.?
const Filters = (
    {
        filters = [],
        initial = {},
        categoryId,
    }: FilterProps,
) => {
    // TODO fix prop drill latter
    const numResults = useCategoryNumResultsValue()
    const setNumResults = useSetCategoryNumResults()

    const {
        currentValues,
        tags,
        filtersApi,
    } = useFiltersFunctions(filters, initial)

    const {
        onChangeValue,
        onToggleOption,
        isOptionChecked,
    } = filtersApi

    return (
        <>
            <FilterFunctionsContext filtersApi={filtersApi}>
                <FiltersBlock
                    tags={tags}
                    filters={filters}
                    onChangeValue={onChangeValue}
                    currentValues={currentValues}
                    onToggleOption={onToggleOption}
                    isOptionChecked={isOptionChecked}
                    numResults={numResults}
                />
            </FilterFunctionsContext>
            {/* TODO why category items placed in Filters? */}
            <CategoryItems
                categoryId={categoryId}
                currentValues={currentValues}
                setNumResults={setNumResults}
                className={css.productList}
            />
        </>
    )
}

export default Filters

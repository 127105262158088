import { useGetStrainsForSaleQueryQuery } from 'app/modules/strains-for-sale/graphql/queries/GetStrainsForSaleQuery'
import { SORT_BY_NAME_DATA } from 'modules/StrainsForSale'

const useStrainsForSale = (categoryId: string) => {
    const { data, loading } = useGetStrainsForSaleQueryQuery({
        variables: {
            input: {
                sort: SORT_BY_NAME_DATA,
                filter: {
                    category_id: {
                        eq: categoryId,
                    },
                },
            },
        },
        skip: !categoryId,
    })

    return {
        data: data?.getStrainsForSaleQuery || undefined,
        loading,
    }
}

export default useStrainsForSale

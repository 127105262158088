import { ModalContentTitle } from '@pwa-onilab/ui/components/elements/ModalContentElements'
import { CenteredModal, IConfirmModal } from '@pwa-onilab/ui/components/elements/Modals'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './ConfirmModal.module.scss'

const ConfirmModal = (
    {
        onConfirm,
        onReject,
        confirmText,
        rejectText,
        title,
        loaderComponent,
        isRevert,
        rejectButtonProps,
        confirmButtonProps,
        ...modalProps
    }: PropsWithChildren<IConfirmModal>) => {
    const { t } = useTranslation()

    const modalTitle = title || t('modals.confirmations.defaultTitle')
    const modalRejectText = rejectText || t('modals.confirmations.rejectDefaultText')
    const modalConfirmText = confirmText || t('modals.confirmations.confirmDefaultText')

    const buttonsClasses = classNames(styles.buttonsWrapper, isRevert && styles.buttonsRevert)

    return (
        <CenteredModal {...modalProps}>
            <ModalContentTitle title={modalTitle} />
            <div className={buttonsClasses}>
                <Button onClick={onReject} isBold isFullWidth {...rejectButtonProps}>
                    {modalRejectText}
                </Button>
                <Button onClick={onConfirm} isBold isFullWidth {...confirmButtonProps}>
                    {modalConfirmText}
                </Button>
            </div>
            {loaderComponent}
        </CenteredModal>
    )
}

export default ConfirmModal

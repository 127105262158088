import { useStoreConfig } from '@pwa-concept/modules/store-config'
import {
    EmailFormField,
    FullNameFormField,
    PasswordFormField,
    ReceiveNewsCheckbox,
    TermsAndConditionsCheckbox,
} from '@pwa-onilab/ui/components/elements/FormFields'
import FormTitle from '@pwa-onilab/ui/components/modules/Auth/elements/FormTitle'
import AuthMobileHeader from '@pwa-onilab/ui/components/modules/Auth/elements/MobileHeader'
import { SubmitButton } from '@pwa-onilab/ui/components/modules/Auth/elements/SubmitButton'
import {
    FORM_EMAIL_FIELD,
    FORM_FULL_NAME_FIELD,
    FORM_PASSWORD_FIELD,
    FORM_RECEIVE_NEWS_CHECKBOX,
} from '@pwa-onilab/ui/constants/formFields'
import { checkPasswordIsStrong, getCustomerNames, getPasswordStrengthErrorMessage } from '@pwa-onilab/ui/helpers'
import { useAccountRegistration } from '@pwa-onilab/ui/hooks'
import { PropsWithChildren, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import styles from './SignUpForm.module.scss'

interface SignUpFormProps {
    handleClickOnLogIn: () => void
    handleSignUp: () => void
    gdprData?: []
}

const SignUpForm = (
    {
        handleClickOnLogIn,
        handleSignUp,
    }: PropsWithChildren<SignUpFormProps>,
) => {
    const { t } = useTranslation()
    const { data: { passwordMinStrength } = {} } = useStoreConfig()
    const registerAccount = useAccountRegistration()
    const { data: { registrationGreetingMessage } = {} } = useStoreConfig()

    const { handleSubmit, register, setValue, formState: { errors, isSubmitting } } = useForm()

    const handleSubmitForm = useCallback(async (formValues) => {
        const name = formValues[FORM_FULL_NAME_FIELD]
        const email = formValues[FORM_EMAIL_FIELD]
        const password = formValues[FORM_PASSWORD_FIELD]
        const receiveNews = formValues[FORM_RECEIVE_NEWS_CHECKBOX]

        try {
            const { firstname, middlename, lastname } = getCustomerNames(name)

            const input = {
                firstname,
                lastname,
                middlename,
                password,
                email,
                is_subscribed: receiveNews,
                // TODO: uncomment when country will appear in schema "CustomerInput"
                // country_id,
            }
            const registrationConfig = {
                additionalHandlers: {
                    handleCustomerRegister: handleSignUp,
                },
                toastsSetting: {
                    showRegisterErrors: true,
                    showRegisterSuccessToast: true,
                },
            }

            await registerAccount(input, registrationConfig)
        } catch (e) {
            toast.error(e.message)
        }
    }, [])

    const fieldsCommonProps = {
        register,
        setValue,
        errors,
        disabled: isSubmitting,
    }

    return (
        <>
            <AuthMobileHeader>
                {t('loginForm.signUp')}
            </AuthMobileHeader>
            <FormTitle
                title={registrationGreetingMessage || t('signUpForm.title.withoutRewards')}
                subtitle={t('signUpForm.haveAccount')}
                buttonText={t('global.logInSmall')}
                onClick={handleClickOnLogIn}
            />
            <form className={styles.signUpForm} onSubmit={handleSubmit(handleSubmitForm)}>
                <FullNameFormField {...fieldsCommonProps} />
                <EmailFormField {...fieldsCommonProps} />
                <PasswordFormField
                    {...fieldsCommonProps}
                    additionalRules={{
                        validate: {
                            passwordStrength: (value) => {
                                const isPassStrong = checkPasswordIsStrong(value, passwordMinStrength)
                                if (isPassStrong) {
                                    return true
                                }
                                return t(getPasswordStrengthErrorMessage(passwordMinStrength))
                            },
                        },
                    }}
                />
                <div className={styles.checkboxes}>
                    <TermsAndConditionsCheckbox {...fieldsCommonProps} />
                    <ReceiveNewsCheckbox {...fieldsCommonProps} />
                </div>

                <SubmitButton
                    text={t('loginForm.signUp')}
                    onLoadText={t('loginForm.signingUp')}
                    loading={isSubmitting}
                />
            </form>
        </>
    )
}

export default SignUpForm

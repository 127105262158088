import { CartMoney } from '@pwa-concept/modules/graphql'
import Intersection from '@pwa-onilab/ui/components/elements/Intersection'
import Price from '@pwa-onilab/ui/components/UI/Price'
import { classNames } from '@pwa-onilab/ui/helpers'
import useCartTotals from 'app/modules/cart/features/get/hooks/useCartTotals'
import { FreeShippingStatusBar } from 'modules/Cart'
import { PaymentMethods } from 'modules/PaymentsMethods'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@pwa-onilab/ui/components/UI/Button'

import styles from './CartResult.module.scss'
import api from '@pwa-concept/core/api'
import { gql } from 'graphql-tag'
import { useCart } from '@pwa-concept/modules/cart'
import { useEffect } from 'react'


export interface ICartResult {
    className?: string
    onTotalsIntersection?: (isVisible: boolean) => void
    setIsShowToCheckoutError?: (isError: boolean) => void
    tryNavigateToCheckout?: () => Promise<void>
}

const CartResult = (
    {
        className,
        onTotalsIntersection,
        tryNavigateToCheckout,
    }: ICartResult) => {
    const [isWaitToNavigate, setIsWaitToNavigate] = useState(true)
    const { t } = useTranslation()
    const cartData = useCart()
    const [request, requestState] = useState(null)

    const { data: { subtotal, discounts, delivery, total } } = useCartTotals()

    const handleTryingNavigateToCheckout = useCallback(async () => {
        setIsWaitToNavigate(true)
        await tryNavigateToCheckout()
    }, [tryNavigateToCheckout])

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsWaitToNavigate(false)
        }, 1200);

        return () => clearTimeout(timer)

    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const { data: { guestValidateMinOrderAmount: context = {} } = {} } = await api.graphql(
                gql`
                    query {
                        guestValidateMinOrderAmount(cartId:${cartData.data.id}) {
                            success
                            error_message
                        }
                    }
                `,
            ).query(undefined, {
                errorPolicy: 'all',
            });

            const response = await context;
            requestState(response);
        };

        fetchData();
    }, [subtotal]);


    return (
        <div className={classNames(styles.wrapper, className)}>
            <FreeShippingStatusBar subtotal={subtotal} />
            <div className={styles.totalParts}>
                <div className={styles.totalPart}>
                    <span>
                        {t('cart.result.subtotal')}
                    </span>
                    <Price>
                        {subtotal}
                    </Price>
                </div>
                {
                    discounts.map((discount: CartMoney) => {
                        return (
                            <div key={discount.label} className={styles.totalPart}>
                                <span>
                                    {discount.label}
                                </span>
                                <Price>
                                    {-discount.money}
                                </Price>
                            </div>
                        )
                    })
                }
                {!!delivery &&
                    <div className={styles.totalPart}>
                        <span>
                            {t('cart.result.delivery')}
                        </span>
                        <Price>
                            {delivery}
                        </Price>
                    </div>}
            </div>
            <div className={styles.total}>
                <span>
                    {t('global.totalWithColon')}
                </span>
                <Price>
                    {total}
                </Price>
            </div>
            <Intersection onIntersection={onTotalsIntersection} dontCheckIntersectionOnIntersectedElDown>
                {request?.success === '1' ? (
                    <Button
                        isFullWidth
                        isPrimary
                        isBold
                        onClick={handleTryingNavigateToCheckout}
                        disabled={isWaitToNavigate}
                    >
                        {t('cart.button.toCheckout')}
                    </Button>
                ) : request?.success === '0' ? (
                    <>
                        <Button
                            isFullWidth
                            isPrimary
                            isBold
                            onClick={handleTryingNavigateToCheckout}
                            disabled={isWaitToNavigate}
                            className={styles.errorButton}
                        >
                            {t('cart.button.toCheckout')}
                        </Button>
                        <div className={styles.errorMessage}>{request.error_message}</div>
                    </>
                ) : (
                    <Button
                        isFullWidth
                        isPrimary
                        isBold
                        onClick={handleTryingNavigateToCheckout}
                        disabled={isWaitToNavigate}
                    >
                        {t('cart.button.toCheckout')}
                    </Button>
                )}
            </Intersection>
            <PaymentMethods className={styles.paymentMethods} />
        </div>
    )
}

export default CartResult

import Anchor from '@pwa-onilab/ui/components/UI/Anchor'

import styles from './PromoBannersMiniCard.module.scss'

interface IPromoBannersMiniCardProps {
    title: string
    linkText: string
    linkUrl: string
}

const PromoBannersMiniCard = (
    {
        title,
        linkText,
        linkUrl,
    }: Partial<IPromoBannersMiniCardProps>,
) => (
    <div className={styles.wrapper}>
        {
            title && (
                <p className={styles.title}>{title}</p>
            )
        }
        {
            linkText && (
                <Anchor
                    className={styles.button}
                    to={linkUrl}
                >
                    {linkText}
                </Anchor>
            )
        }
    </div>
)

export default PromoBannersMiniCard

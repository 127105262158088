import { Skeleton } from '@pwa-onilab/ui/components/elements/Skeleton'
import Container from '@pwa-onilab/ui/components/UI/Container'

import styles from './MenuPlaceholder.module.scss'

const MenuPlaceholder = () => (
    <Container className={styles.list}>
        <Skeleton className={styles.item} />
        <Skeleton className={styles.item} />
        <Skeleton className={styles.item} />
        <Skeleton className={styles.item} />
        <Skeleton className={styles.item} />
        <Skeleton className={styles.item} />
    </Container>
)

export default MenuPlaceholder

import * as Types from '../../../../graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type GetBlogAuthorPostsListQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  urlKey?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetBlogAuthorPostsListQuery = { __typename?: 'Query', getBlogAuthorPostsList?: { __typename?: 'BlogAuthorPostsList', items?: Array<{ __typename?: 'BlogPost', id?: string | null, postUrl?: string | null, title?: string | null } | null> | null } | null };


export const GetBlogAuthorPostsListDocument = gql`
    query GetBlogAuthorPostsList($id: Int, $urlKey: String) {
  getBlogAuthorPostsList(id: $id) @client {
    items {
      id
      postUrl
      title
    }
  }
}
    `;

/**
 * __useGetBlogAuthorPostsListQuery__
 *
 * To run a query within a React component, call `useGetBlogAuthorPostsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogAuthorPostsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogAuthorPostsListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      urlKey: // value for 'urlKey'
 *   },
 * });
 */
export function useGetBlogAuthorPostsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBlogAuthorPostsListQuery, GetBlogAuthorPostsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetBlogAuthorPostsListQuery, GetBlogAuthorPostsListQueryVariables>(GetBlogAuthorPostsListDocument, options);
      }
export function useGetBlogAuthorPostsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBlogAuthorPostsListQuery, GetBlogAuthorPostsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetBlogAuthorPostsListQuery, GetBlogAuthorPostsListQueryVariables>(GetBlogAuthorPostsListDocument, options);
        }
export type GetBlogAuthorPostsListQueryHookResult = ReturnType<typeof useGetBlogAuthorPostsListQuery>;
export type GetBlogAuthorPostsListLazyQueryHookResult = ReturnType<typeof useGetBlogAuthorPostsListLazyQuery>;
export type GetBlogAuthorPostsListQueryResult = Apollo.QueryResult<GetBlogAuthorPostsListQuery, GetBlogAuthorPostsListQueryVariables>;
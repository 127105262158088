import { useSorts } from '@pwa-concept/modules/sort'
import {
    getCategoryItemsInputDefaultSortField,
    getCategoryItemsInputFilterField,
    getCategoryItemsInputSortField,
} from '@pwa-onilab/ui/components/modules/Category'
import { ICategoryItems } from '@pwa-onilab/ui/components/modules/Category/CategoryItems/ICategoryItems'
import { useMemo } from 'react'
import { DEFAULT_SORT_DIRECTION } from '@pwa-onilab/ui/components/modules/Filters'

const useCategoryItemsInput = (
    {
        currentValues,
        categoryId,
    }: Pick<ICategoryItems, 'currentValues' | 'categoryId'>,
) => {
    const { data: sorts, loading: sortsLoading } = useSorts()

    const categoryItemsInput = useMemo(() => {
        const {
            sort: activeSort,
            filters = {},
            q: search,
        } = currentValues
        if (!activeSort && sortsLoading) {
            return {}
        }
        if (categoryId === '28' && !activeSort) {
            sorts.default = 'actual_price_per_seed'
        }
        if (categoryId !== '28' && !activeSort) {
            sorts.default = 'thc_priority'
        }

        let sort
        if (activeSort) {
            sort = getCategoryItemsInputSortField(activeSort)
        } else if (!search) {
            sort = getCategoryItemsInputDefaultSortField(sorts)
        }

        return {
            search,
            categoryId,
            filters: getCategoryItemsInputFilterField(filters),
            sort,
        }
    }, [JSON.stringify(currentValues), categoryId, sorts, sortsLoading])

    return {
        data: categoryItemsInput,
        loading: !currentValues.sort && sortsLoading,
    }
}

export default useCategoryItemsInput

import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { GraphQLError } from 'graphql'
import { gql } from 'graphql-tag'

const checkIsEmailAvailable: QueryResolvers['checkIsEmailAvailable'] = async (_, { email }) => {
    const { data: { isEmailAvailable } = {}, errors } = await (
        api.graphql(
            gql`
            query($email: String!) {
                isEmailAvailable(email: $email) {
                    is_email_available
                    __typename
                }
            }
        `,
        ).query({ email })
    )
    if (errors) {
        throw new GraphQLError(errors[0].message)
    }

    return {
        isEmailAvailable: isEmailAvailable?.is_email_available || false,
        __typename: 'checkIsEmailAvailableOutput',
    }
}
export default checkIsEmailAvailable

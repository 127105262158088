import Container from '@pwa-onilab/ui/components/UI/Container'
import { Section } from '@pwa-onilab/ui/components/UI/Section'
import { WidgetHeader } from '@pwa-onilab/ui/components/widgets/WidgetHeader'
import { classNames } from '@pwa-onilab/ui/helpers'
import { CategoriesSlider } from 'components/overrides/widgets/Categories/CategoriesSlider'
import { DisablePageBuilderStyles } from 'elements/DisablePageBuilderStyles'

import styles from './Categories.module.scss'

interface ICategoriesSliderProps {
    button_link: string
    button_title: string
    items: Array<{
        name: string
        url_key: string
        image: string
    }>
    title: string
}

const Categories = (
    {
        button_link: buttonLink,
        button_title: buttonTitle,
        items,
        title,
    }: ICategoriesSliderProps,
) => {
    if (!items?.length) return null

    return (
        <Section className={classNames(styles.section)}>
            <DisablePageBuilderStyles>
                <Container>
                    <WidgetHeader
                        title={title}
                        to={buttonLink}
                        linkText={buttonTitle}
                    />
                    <CategoriesSlider categories={items} />
                </Container>
            </DisablePageBuilderStyles>
        </Section>
    )
}

export default Categories

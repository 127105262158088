import styles from './AccountCards.module.scss'
import { TableHeader } from '@pwa-onilab/ui/components/UI/TableHeader'
import { TableRow } from '@pwa-onilab/ui/components/UI/TableRow'
import { useTranslation } from 'react-i18next'
import { AccountCardsTableRow } from './AccountCardsTableRow'

const AccountCards = () => {
    const { t } = useTranslation()


    return (
        <div className={styles.tableCards}>
            <TableRow>
                <TableHeader className={styles.cardsTableCell} name={t('Last 4 number')} />
                <TableHeader className={styles.cardsTableCell} name={t('Exp year')} />
                <TableHeader className={styles.cardsTableCell} name={t('Exp month')} />
                <TableHeader className={styles.cardsTableCell} name={t('Last use')} />
                <TableHeader className={styles.tableHeaderDelete} name={t('Delete')} />
            </TableRow>
            <AccountCardsTableRow />
        </div>
    )
}

export default AccountCards

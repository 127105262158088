import { Pagination } from '@pwa-concept/modules/graphql'
import { classNames } from '@pwa-onilab/ui/helpers'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import { useTranslation } from 'react-i18next'

import styles from './PaginationLoadMore.module.scss'

interface PaginationLoadMoreProps {
    className?: string
    data?: Pagination
    onLoadPage?: (page: number) => void
}

const PaginationLoadMore = (
    {
        data,
        onLoadPage,
        className,
    }: PaginationLoadMoreProps,
) => {
    const { t } = useTranslation()

    if (!data) {
        return null
    }

    const {
        count,
        current,
        limit,
        total,
    } = data

    const currentCount = Math.min(current * limit, count)

    if (currentCount === count) {
        return null
    }

    const onBtnClick = () => (
        onLoadPage?.(Math.min(total, ((current || 0) + 1)))
    )

    return (
        <div className={classNames(styles.loadMore, className)}>
            <span className={styles.text}>
                {t('categoryPage.pagination.amountOfTotal', {
                    currentCount,
                    totalCount: count,
                })}
            </span>
            <div className={styles.progress}>
                <div
                    className={styles.value}
                    style={{ width: `${(currentCount / count) * 100}%` }}
                />
            </div>
            <Button
                isBlackTransparent
                isBold
                className={styles.button}
                onClick={onBtnClick}
            >
                {t('global.loadMore')}
            </Button>
        </div>
    )
}

export default PaginationLoadMore

import { FilterModalTrigger, FilterResultCount } from '@pwa-onilab/ui/components/modules/Filters'
import { IFilterControls } from '@pwa-onilab/ui/components/modules/Filters/components/FilterControls/IFilterControls'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useModalStates } from '@pwa-onilab/ui/hooks'
import FilterModal from 'components/overrides/modules/Filters/components/FilterModal/FilterModal'
import FilterSortBy from 'components/overrides/modules/Filters/components/FilterSortBy/FilterSortBy'

import styles from './FilterControls.module.scss'
const FilterControls = (
    {
        className,
        tags,
        filters = [],
        onChangeValue,
        currentValues,
        onToggleOption,
        isOptionChecked,
        numResults,
    }: IFilterControls,
) => {
    const {
        isModalOpen: isOpenFilterModal,
        openModal: openFilterModalHandler,
        closeModal: closeFilterModalHandler,
    } = useModalStates()

    const search = currentValues?.q

    const sortId = Object.keys(currentValues?.sort || {})?.[0]

    return (
        <>
            <div className={classNames(styles.wrapper, className)}>
                <FilterModalTrigger
                    onClick={openFilterModalHandler}
                    tagsCount={tags?.length || 0}
                />
                <FilterResultCount
                    numResults={numResults}
                    className={styles.result}
                />
                <FilterSortBy
                    onChangeValue={onChangeValue}
                    sortId={sortId}
                    isSearch={!!search}
                />
            </div>
            <FilterModal
                isOpen={isOpenFilterModal}
                onClose={closeFilterModalHandler}
                tags={tags}
                filters={filters}
                onChangeValue={onChangeValue}
                currentValues={currentValues}
                onToggleOption={onToggleOption}
                isOptionChecked={isOptionChecked}
                numResults={numResults}
            />
        </>
    )
}

export default FilterControls

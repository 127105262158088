import { ICheckboxForm } from '@pwa-onilab/ui/components/UI/Checkbox/index'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import styles from './Checkbox.module.scss'

const CheckboxForm = (
    {
        children,
        id,
        errors,
        rules = {},
        register,
        disabled,
        labelClassName,
        checkboxClassName,
        wrapperClassName,
        defaultChecked,
        ...restProps
    }: PropsWithChildren<ICheckboxForm>) => {
    return (
        <label
            {...restProps}
            htmlFor={id}
            className={classNames(styles.checkbox, disabled && styles.disabled, wrapperClassName)}
            aria-hidden
        >
            <div className={styles.checkboxInner}>
                <input
                    id={id}
                    type="checkbox"
                    defaultChecked={defaultChecked}
                    {...register(id, rules)}

                />
                <span className={classNames(styles.checkboxDisplay, checkboxClassName)}>
                    <Icon name="check" className={styles.checkIcon} />
                </span>
                {!!children && (
                    <span className={classNames(styles.checkboxValue, labelClassName)}>{children}</span>
                )}
            </div>
            {!!errors?.[id] && <p className={styles.error}>{errors[id].message}</p>}
        </label>
    )
}

export default CheckboxForm

import { $cart } from '@pwa-concept/core/models'
import { useCart, useCartRemoveItem } from '@pwa-concept/modules/cart'
import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { ModalProps } from '@pwa-onilab/ui/components/elements/Modal/Modal'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import styles from './CartConfirmDeleteItemModal.module.scss'

interface ConfirmDeleteItemModalProps extends Pick<ModalProps, 'isOpen' | 'onDismiss'> {
    className?: string
    id?: string
    setIsCartLoading?: (isLoading: boolean) => void
    onDeleteItem?: () => void
}

const CartConfirmDeleteItemModal = (
    {
        isOpen,
        onDismiss,
        onDeleteItem,
        id,
        setIsCartLoading,
    }: ConfirmDeleteItemModalProps,
) => {
    const { t } = useTranslation()
    const { refetch: refetchCart } = useCart()
    const removeItemFromCart = useCartRemoveItem()

    const deleteItemHandler = useCallback(async () => {
        setIsCartLoading(true)
        try {
            await removeItemFromCart({
                id: $cart.id,
                uid: id,
            })
            onDeleteItem()
            setIsCartLoading(false)
        } catch (error) {
            toast.error(error.message)
            await refetchCart()
            setIsCartLoading(false)
        }

        onDismiss()
    }, [id])

    return (
        <Modal
            isOpen={isOpen}
            isOverAll
            isDraggable
            isUseBorderRadius
            isShowCloseButton
            isHideMobileCloseButton
            onDismiss={onDismiss}
            closeButtonClass={styles.closeModalButton}
        >
            <div className={styles.wrapper}>
                <p className={styles.description}>
                    {t('cart.removeItem.confirm.description')}
                </p>
                <div className={styles.buttons}>
                    <Button
                        isBlackTransparent
                        isBold
                        onClick={deleteItemHandler}
                    >
                        {t('cart.removeItem.confirm.confirm')}
                    </Button>
                    <Button
                        isBlack
                        isBold
                        onClick={onDismiss}
                    >
                        {t('cart.removeItem.confirm.reject')}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default CartConfirmDeleteItemModal

import { makeVar, useReactiveVar } from '@apollo/client'
import { $auth } from '@pwa-concept/core/models'
import {
    isBreakpointDownNonReactive,
} from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { ACCOUNT_ROUTE } from '@pwa-onilab/ui/constants/navigation'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

const isAuthModalOpen = makeVar(false)

const closeModal = () => {
    isAuthModalOpen(false)
}

const useAuthModal = () => {
    const isModalOpen = useReactiveVar(isAuthModalOpen)
    const navigate = useNavigate()

    const openModal = useCallback(() => {
        if ($auth.isToken) {
            navigate(ACCOUNT_ROUTE)
            return
        }

        if (isBreakpointDownNonReactive('lg')) {
            navigate(ACCOUNT_ROUTE)
            return
        }
        isAuthModalOpen(true)
    }, [])

    return {
        isModalOpen,
        openModal,
        closeModal,
    }
}

export default useAuthModal

import { $wishlist } from '@pwa-concept/core/models'
import { IApolloMutationsBaseOptions } from '@pwa-concept/modules/common'
import { useRemoveProductsFromWishlistMutation } from '@pwa-concept/modules/wishlist/graphql/mutations/RemoveProductsFromWishlist'

interface IWishlistRemoveItemData {
    items: string[]
    page?: number
    limit?: number
}

const useWishlistRemoveItem = (baseOptions?: IApolloMutationsBaseOptions) => {
    const [removeProductsToWishlistMutation] = useRemoveProductsFromWishlistMutation(baseOptions)

    return async ({ items, page = 1, limit = 20 }: IWishlistRemoveItemData) => {
        const { data: { removeProductsFromWishlist } = {} } = await removeProductsToWishlistMutation({
            variables: {
                id: $wishlist.id,
                items,
                page,
                limit,
            },
            ignoreResults: true,
        })

        return {
            data: removeProductsFromWishlist,
        }
    }
}

export default useWishlistRemoveItem

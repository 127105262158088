import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const getUrlFromSearchParams = (searchParams, pathname: string) => {
    const searchQueryParams = Object.entries(searchParams)

    const search: string = searchQueryParams
        .reduce((acc, param: [string, string], idx) => {
            const subQuery = acc + param[0] + '=' + param[1]
            if (idx !== searchQueryParams.length - 1) {
                return subQuery + '&'
            }
            return subQuery
        }, '')

    if (search) {
        return pathname + '?' + search
    }

    return pathname
}

const getSearchParams = () => {
    if (!window.location.search) {
        return []
    }
    return window.location.search
        .replace('?', '')
        .split('&')
        .reduce((acc, el) => {
            const [key, value] = el.split('=')
            acc[key] = value

            return acc
        }, {})
}

const usePaginationUrlPages = (clientPage: number, serverPage: number) => {
    const location = useLocation()

    useEffect(() => {
        const newPage = clientPage > serverPage ? clientPage : serverPage
        const queryParams = getSearchParams()

        const currentPage = +queryParams['page'] || 1

        if (currentPage === newPage) {
            return
        }

        if (newPage === 1) {
            delete queryParams['page']
        } else {
            queryParams['page'] = newPage.toString(10)
        }

        const newUrl = getUrlFromSearchParams(queryParams, location.pathname)

        window.history.replaceState(window.history.state, '', newUrl)
    }, [clientPage, serverPage])
}

export default usePaginationUrlPages

import { WIDGETS_LIST } from '@pwa-onilab/ui/components/widgets/WidgetsRenderer/constants'
import { createElement } from 'react'

const WidgetsRenderer = (
    {
        widgetType = 'widget_empty',
        widgetContent = {},
    },
) => (widgetContent && WIDGETS_LIST[widgetType]) ? createElement(WIDGETS_LIST[widgetType] || null, widgetContent) : null

export default WidgetsRenderer

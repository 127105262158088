import {
    AccountOrderHistoryEmpty,
    AccountOrderHistoryPlaceholder,
    AccountOrderHistoryWithItems,
    AccountOrdersPagination,
    useAccountOrderHistory,
} from '@pwa-onilab/ui/components/modules/AccountSubpages'

const AccountOrderHistory = () => {
    const {
        loading,
        orders,
        isCustomerWithOrders,
        isShowPagination,
        handlePageClick,
        pagination,
    } = useAccountOrderHistory()

    return (
        <>
            {
                loading
                    ? <AccountOrderHistoryPlaceholder />
                    : isCustomerWithOrders
                        ? <AccountOrderHistoryWithItems orders={orders} />
                        : <AccountOrderHistoryEmpty />
             }
            {isShowPagination && (
                <AccountOrdersPagination
                    pagination={pagination}
                    onClick={handlePageClick}
                />
            )}

        </>
    )
}

export default AccountOrderHistory

import { useCategoryBreadcrumbs } from '@pwa-concept/modules/category'
import { Breadcrumbs } from '@pwa-onilab/ui/components/modules/Breadcrumbs'

interface CategoryBreadcrumbsProps {
    className?: string
    categoryId: Number
}

const CategoryBreadcrumbs = (
    {
        className,
        categoryId,
    }: CategoryBreadcrumbsProps,
) => {
    const { data: breadcrumbs } = useCategoryBreadcrumbs({ id: String(categoryId) })

    return (
        <Breadcrumbs className={className} items={breadcrumbs} />
    )
}

export default CategoryBreadcrumbs

import { CustomerOrderProduct } from '@pwa-concept/modules/graphql'
import { Flex } from '@pwa-onilab/ui/components/elements/Flex'
import { OrderSummaryItemPrice } from '@pwa-onilab/ui/components/modules/Order'
import ProductConfiguredOption from '@pwa-onilab/ui/components/modules/Product/elements/ProductConfiguredOption'
import ProductCardImage from 'components/overrides/modules/Product/elements/ProductCardImage/ProductCardImage'
import { useTranslation } from 'react-i18next'

import styles from './OrderSummaryItem.module.scss'

interface IOrderSummaryItem {
    product: CustomerOrderProduct
}

const OrderSummaryItem = ({ product }: IOrderSummaryItem) => {
    const { t } = useTranslation()
    const { productData: { image, prices } = { }, quantityOrdered } = product

    return (
        <div key={product.id} className={styles.productWrapper}>
            <ProductCardImage
                imageSrc={image}
                imageAlt="product image"
                url={product.url}
                className={styles.image}
                sku={product.sku}
            />
            <div className={styles.productMainContent}>
                <p className={styles.name}>
                    {product.name}
                </p>
                {
                    product.options.map(({ label, value }, idx) => (
                        <ProductConfiguredOption
                            key={idx}
                            optionLabel={label}
                            valueLabel={value}
                            wrapperClassName={styles.configuredOption}
                        />
                    ))
                }
                <Flex justifyContent="spaceBetween" className={styles.quantityPriceWrapper}>
                    <ProductConfiguredOption
                        optionLabel={t('global.qty')}
                        valueLabel={quantityOrdered}
                        wrapperClassName={styles.configuredOption}
                    />
                    <OrderSummaryItemPrice
                        prices={prices}
                        quantity={quantityOrdered}
                    />
                </Flex>
            </div>
        </div>
    )
}

export default OrderSummaryItem

import { MediaBreakpointDown } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './FaqWidgetMenuItem.module.scss'

interface IFaqWidgetMenuItemProps {
    setActiveTabHandler: (newTab: {}) => void
    activeTitle: string
    data: {
        title: string
        items: Array<{
            title: string
            description: string
        }>
    }
}

const FaqWidgetMenuItem = (
    {
        setActiveTabHandler,
        activeTitle,
        data,
    }: IFaqWidgetMenuItemProps,
) => (
    <ButtonInstance
        className={classNames(styles.button, data?.title === activeTitle && styles.active)}
        onClick={() => setActiveTabHandler(data)}
    >
        <span className={styles.text}>
            {data?.title}
        </span>
        <MediaBreakpointDown name="lg">
            <Icon
                name="chevron"
                className={styles.arrow}
            />
        </MediaBreakpointDown>
    </ButtonInstance>
)

export default FaqWidgetMenuItem

import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './Title.module.scss'

interface ITitleProps {
    className?: string
    title: string
}

const Title = (
    {
        className,
        title,
    }: ITitleProps,
) => {
    if (!title) return null

    return (
        <div className={classNames(styles.title, className)}>
            {title}
        </div>
    )
}

export default Title

import { PropsWithChildren } from 'react'

export interface LabelProps {
    onClick?: (x) => void
    className?: string

    [restProps: string]: any
}

const Label = (
    {
        children,
        className,
        onClick,
        ...restProps
    }: PropsWithChildren<LabelProps>,
) => {
    return (
        <label
            {...restProps}
            className={className}
            onClick={onClick}
        >
            {children}
        </label>
    )
}

Label.defaultProps = {
    className: null,
    onClick: null,
}

export default Label

import { useQuery } from '@apollo/client'
import { GetStateQuery } from 'app/modules/states/graphql/queries'

interface IUseGetStateVariables {
    stateId?: number
    urlKey?: string
}

const useGetState = (variables: IUseGetStateVariables) => {
    const { data, loading } = useQuery(GetStateQuery, {
        variables,
        skip: !variables || !(variables.stateId || variables.urlKey),
    })

    return {
        data: data?.getState || undefined,
        loading,
    }
}

export default useGetState

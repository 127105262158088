import { useGetBlogAuthorQuery } from 'app/modules/blog-author/graphql/queries/GetBlogAuthor'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const getAuthorNameFromPathname = (pathname: string) => {
    return pathname.split('/').filter(Boolean).pop()
}

const useGetBlogAuthor = (urlKey?: string, isFull: boolean = true) => {
    const location = useLocation()

    const currentUrlKey = useMemo(() => {
        return urlKey || getAuthorNameFromPathname(location.pathname)
    }, [urlKey, location.pathname])

    const { data: { getBlogAuthor } = {}, loading } = useGetBlogAuthorQuery({
        variables: {
            urlKey: currentUrlKey,
            isFull,
        },
    })

    return {
        data: getBlogAuthor || {},
        loading,
    }
}

export default useGetBlogAuthor

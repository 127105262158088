import { Skeleton } from '@pwa-onilab/ui/components/elements/Skeleton'
import { BlogCardSkeleton } from 'modules/Blog/BlogCard'
import { IBlogCardSkeletonProps } from 'modules/Blog/BlogCard/BlogCard.skeleton'
import { BLOG_PAGE_SIZE } from 'pages/BlogPage/constants/blogConstants'

import styles from './BlogContent.module.scss'

const BlogContentSkeleton = ({ skeletonCount }: Pick<IBlogCardSkeletonProps, 'skeletonCount'>) => {
    return (
        <div className={styles.wrapperSkeleton}>
            <div className={styles.header}>
                <Skeleton className={styles.resultsSkeleton} />
                <Skeleton className={styles.sortSkeleton} />
            </div>
            <div className={styles.cardList}>
                <BlogCardSkeleton skeletonCount={skeletonCount || BLOG_PAGE_SIZE} />
            </div>
        </div>
    )
}

export { BlogContentSkeleton }

import { CMSModule } from '@pwa-concept/modules/cms'
import { AppProductModule } from 'app/modules/product'

import graphqlFragments from './graphql/fragments'
import graphqlSchemas from './graphql/schemas'
import stores from './graphql/stores'

const AppCMSModule = (...args: Parameters<typeof CMSModule.configure>) => CMSModule.extend((module, { concat }) => {
    return {
        modules: [
            AppProductModule,
        ],
        graphqlFragments: concat(module?.graphqlFragments, graphqlFragments),
        graphqlSchemas: concat(module?.graphqlSchemas, graphqlSchemas),
        graphqlResolvers: concat(module?.graphqlResolvers, stores?.resolvers),
        graphqlStoreFragments: concat(module?.graphqlStoreFragments, stores?.fragments),
    }
}).configure(...args)

export default AppCMSModule

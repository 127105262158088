import { IMenuObjData, MENU_ELEM_TYPE_CATEGORY, MenuLink } from '@pwa-onilab/ui/components/modules/Menu'
import { classNames } from '@pwa-onilab/ui/helpers'
import { MenuAnchor } from 'modules/Menu'
import { Dispatch } from 'react'

import styles from './MenuList.module.scss'

interface IMenuListProps {
    className?: string
    menuData: IMenuObjData[]
    activeCategoryName: string
    setActiveCategory: Dispatch<IMenuObjData>
}

const MenuList = (
    {
        className,
        menuData,
        activeCategoryName,
        setActiveCategory,
    }: IMenuListProps,
) => (
    <div className={classNames(styles.list, className)}>
        {
            menuData.map((menuItemData) => {
                const {
                    name,
                    url,
                    type,
                } = menuItemData

                if ((type === MENU_ELEM_TYPE_CATEGORY && !menuItemData?.items) || (type === 'custom_url' && !menuItemData?.items)) {
                    return (
                        <MenuLink
                            key={name}
                            name={name}
                            to={url}
                        />
                    )
                }

                const selectActiveCategory = () => setActiveCategory(menuItemData)

                return (
                    <MenuAnchor
                        key={name}
                        title={name}
                        to={url}
                        active={activeCategoryName === name}
                        onMouseEnter={selectActiveCategory}
                    />
                )
            })
        }
    </div>
)

export default MenuList

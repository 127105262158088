import { Product } from '@pwa-concept/modules/graphql'
import { MediaBreakpointDown } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Slider, { SliderPagination } from '@pwa-onilab/ui/components/UI/Slider'
import { splitArraysOnColumns } from '@pwa-onilab/ui/helpers'
import { PopularProductsSlide } from 'components/widgets/PopularProducts'
import { SwiperSlide } from 'swiper/react'

import styles from './PopularProductsSlider.module.scss'

interface IPopularProductsSliderProps {
    items: Array<{
        products: Product[]
        category: {
            name: string
            url: string
            image: string
        }
    }>
    columnsNumber: number
}

const PopularProductsSlider = (
    { items, columnsNumber }: IPopularProductsSliderProps,
) => {
    const filteredItems = items.filter((column) => {
        const isShouldRenderList = (column.category?.url && column.category?.name) ||
            !!column.products?.length

        return isShouldRenderList
    })

    const columns = splitArraysOnColumns(filteredItems, columnsNumber)

    return (
        <Slider
            className={styles.mainList}
            breakpoints={{
                991: {
                    slidesPerView: Math.min(columns.length, 4.2),
                    spaceBetween: 24,
                    slidesPerGroup: 4,
                },
                576: {
                    slidesPerView: Math.min(columns.length, 3.2),
                    spaceBetween: 16,
                    slidesPerGroup: 3,
                },
                360: {
                    slidesPerView: Math.min(columns.length, 1.3),
                    spaceBetween: 16,
                },
            }}
        >
            {
                columns.map((column, colIdx) => {
                    return (
                        <SwiperSlide key={colIdx} className={styles.columnList} tag="ul">
                            {column.map((el, rowIdx) => {
                                return (
                                    <li key={el.category?.url || rowIdx}>
                                        <PopularProductsSlide products={el.products} category={el.category} />
                                    </li>
                                )
                            })}
                        </SwiperSlide>
                    )
                })
            }
            <MediaBreakpointDown name="lg">
                <SliderPagination className={styles.pagination} />
            </MediaBreakpointDown>
        </Slider>
    )
}

export default PopularProductsSlider

import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './SearchTitle.module.scss'

interface ISearchTitleProps {
    title: string
    className?: string
}

const SearchTitle = (
    {
        title,
        className,
    }: ISearchTitleProps,
) => {
    if (!title) return null

    return (
        <div className={classNames(styles.title, className)}>
            {title}
        </div>
    )
}

export default SearchTitle

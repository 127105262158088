import { QueryResolvers } from '@pwa-concept/modules/graphql'

import sharedWishlist from './sharedWishlist'
import wishlist from './wishlist'

const Query: QueryResolvers = {
    wishlist,
    sharedWishlist,
}

export default Query

import { QueryResolvers } from '@pwa-concept/modules/graphql'

import storeConfigBestsellers from './storeConfigBestsellers'
import storeConfigBlogHomePageMeta from './storeConfigBlogHomePageMeta'
import storeConfigCategoryStainsMeta from './storeConfigCategoryStainsMeta'
import storeConfigFamiliesHomePageMeta from './storeConfigFamiliesHomePageMeta'
import storeConfigNotFoundPageData from './storeConfigNotFoundPageData'
import storeConfigRegistrationPageMeta from './storeConfigRegistrationPageMeta'
import storeConfigSearchPageMeta from './storeConfigSearchPageMeta'
import storeConfigWholeSale from './storeConfigWholeSale'
import storeConfigWorldPage from './storeConfigWorldPage'

const Query: QueryResolvers = {
    storeConfigBestsellers,
    storeConfigBlogHomePageMeta,
    storeConfigCategoryStainsMeta,
    storeConfigFamiliesHomePageMeta,
    storeConfigSearchPageMeta,
    storeConfigWholeSale,
    storeConfigNotFoundPageData,
    storeConfigRegistrationPageMeta,
    storeConfigWorldPage,
}

export default Query

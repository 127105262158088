import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'

import styles from './StrainFamiliesList.module.scss'

const StrainFamiliesListPlaceholder = () => {
    const placeholderItems = new Array(20).fill(0)
    return (
        <ul className={styles.list}>
            {placeholderItems.map((item, idx) => {
                return (
                    <li key={idx} className={styles.item}>
                        <Placeholder className={styles.itemPlaceholder} />
                    </li>
                )
            })}
        </ul>
    )
}

export default StrainFamiliesListPlaceholder

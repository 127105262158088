import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { CUSTOMER_DELETE_ROUTE } from '@pwa-onilab/ui/constants/navigation/navigation'
import { useTranslation } from 'react-i18next'

import styles from './DeleteAccountLink.module.scss'

const DeleteAccountLink = () => {
    const { t } = useTranslation()

    return (
        <Anchor to={CUSTOMER_DELETE_ROUTE} className={styles.wrapper}>
            {t('AccountPage.informationSubpage.deleteAccount')}
        </Anchor>
    )
}

export default DeleteAccountLink

import { useCustomerAddressDelete } from '@pwa-concept/modules/customer-addresses'
import { CustomerAddress } from '@pwa-concept/modules/graphql'
import { ModalProps } from '@pwa-onilab/ui/components/elements/Modal/Modal'
import { Button } from '@pwa-onilab/ui/components/UI/Button'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

interface IYesButton extends Pick<ModalProps & CustomerAddress, 'id' | 'onDismiss' | 'className'>{
    isLoading: boolean
    setIsLoading: (isLoading: boolean) => void
}

const YesButton = ({ id, onDismiss, className, isLoading, setIsLoading }: IYesButton) => {
    const { t } = useTranslation()
    const deleteAddress = useCustomerAddressDelete()

    const onAddressDelete = async () => {
        setIsLoading(true)

        try {
            await deleteAddress(id)
            onDismiss()
            toast.success(t('address.delete.success'))
        } catch (error) {
            toast.error(error.message)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Button
            isBlack
            isBold
            onClick={onAddressDelete}
            className={className}
            disabled={isLoading}
        >
            {t('global.yes')}
        </Button>
    )
}

const comparer = (prev, next) => {
    return prev.id === next.id && prev.isLoading === next.isLoading
}

export default memo(YesButton, comparer)

import { Category, Filter } from '@pwa-concept/modules/graphql'
import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { DefaultHelmet, IDefaultHelmet } from '@pwa-onilab/ui/components/elements/DefaultHelmet'
import SchemaHelmetCategoryPage from 'elements/SchemaHelmet/SchemaHelmetCategoryPage/SchemaHelmetCategoryPage'
import { SchemaHelmetCollectionPage } from 'elements/SchemaHelmet/SchemaHelmetCollectionPage'
import { SchemaHelmetWebPage } from 'elements/SchemaHelmet/SchemaHelmetWebPage'

interface ICategoryPageHelmet {
    category: Category
    filters: Filter[]
}

const CategoryPageHelmet = ({ category, filters }: ICategoryPageHelmet) => {
    const { data: storeConfig } = useStoreConfig()

    const meta: IDefaultHelmet['meta'] = {
        title: category?.metaTitle || '',
        keywords: category?.metaKeywords || '',
        description: category?.metaDescription || '',
        robots: category?.metaRobots || '',
        ogType: 'article',
    }

    if (category?.image) {
        meta.ogImage = {
            src: category.image,
            alt: 'category image',
        }
    }

    return (
        <>
            <DefaultHelmet
                meta={meta}
                defaultTitle={storeConfig?.defaultTitle || ''}
            />
            <SchemaHelmetWebPage meta={meta} />
            <SchemaHelmetCollectionPage meta={meta} />
            <SchemaHelmetCategoryPage category={category} filters={filters} />
        </>
    )
}

export default CategoryPageHelmet

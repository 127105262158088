import { StoreConfig } from '@pwa-concept/modules/graphql'
import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { IDefaultHelmet } from '@pwa-onilab/ui/components/elements/DefaultHelmet'
import { SchemaHelmet } from 'elements/SchemaHelmet'
import {
    SCHEMA_HELMET_CONTEXT_VALUE,
    SCHEMA_HELMET_DEFAULT_SITE_URL,
    SCHEMA_HELMET_TYPES,
} from 'elements/SchemaHelmet/SchemaHelmetConstants'
import { addSlashToEndUrl } from 'helpers'

const getCollectionPageSchema = (config: StoreConfig, meta: IDefaultHelmet['meta'], isSearchPage?: boolean) => {
    const url = addSlashToEndUrl(window.location.href)
    const websiteName = config.structuredData.website.name || config.defaultTitle
    const name = [meta.title, websiteName].filter(Boolean).join(' - ')

    let type
    if (isSearchPage) {
        type = [SCHEMA_HELMET_TYPES.CollectionPage, SCHEMA_HELMET_TYPES.SearchResultsPage]
    } else {
        type = SCHEMA_HELMET_TYPES.CollectionPage
    }

    return {
        '@context': SCHEMA_HELMET_CONTEXT_VALUE,
        '@type': type,
        '@id': url,
        url,
        name,
        isPartOf: {
            '@id': `${SCHEMA_HELMET_DEFAULT_SITE_URL}/#website`,
        },
    }
}

interface ISchemaCollectionPage {
    meta: IDefaultHelmet['meta']
    isSearchPage?: boolean
}

const SchemaHelmetCollectionPage = ({ meta, isSearchPage }: ISchemaCollectionPage) => {
    const { data: storeConfig } = useStoreConfig()
    if (!storeConfig || !meta) {
        return null
    }

    const schemaConfig = getCollectionPageSchema(storeConfig, meta, isSearchPage)

    return <SchemaHelmet jsonConfig={schemaConfig} />
}

export default SchemaHelmetCollectionPage

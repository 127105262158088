import { APOLLO_ERROR_TYPES, APOLLO_ERRORS, IApolloError } from '@pwa-concept/modules/constants/apolloErrors'

type tGetType = (
    errorMessage: string,
    apolloError?: IApolloError[],
) => APOLLO_ERROR_TYPES

export const getApolloErrorType: tGetType = (errorMessage, apolloError = APOLLO_ERRORS) => {
    return apolloError.find((apolloError) => {
        return apolloError.errorMessages.find((apolloErrorText) => errorMessage.includes(apolloErrorText))
    })?.errorType || null
}

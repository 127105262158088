import { useCartAddItem } from '@pwa-concept/modules/cart'
import { CartItem, CartItems } from '@pwa-concept/modules/graphql'
import { ga4RemoveFromCart } from '@pwa-onilab/ga4/operations/cart/removeFromCart'
import { TIME_ON_DELETED_ITEM_UNDO_OPERATION } from '@pwa-onilab/ui/constants/cart'
import { useCallback, useRef, useState } from 'react'
import { toast } from 'react-toastify'

export interface DeletedItemInterface {
    id: string
    sku: string
    quantity: number
    indexInArray: number
    name: string
    selectedOptions: string[]
}

const useCartItemUndoOperation = (setIsLoading: (isLoading: boolean) => void) => {
    const [lastDeletedItem, setLastDeletedItem] = useState<DeletedItemInterface>(null)

    const timerRef = useRef(null)

    const addItemToCart = useCartAddItem()

    const handleSetDeletedItem = useCallback((data: CartItems, indexInArray) => {
        clearTimeout(timerRef.current)

        const {
            id,
            quantity,
            configuredVariant,
            product,
            configurableOptions,
        } = data
        const { sku, name } = configuredVariant || product

        setLastDeletedItem({
            id,
            sku,
            quantity,
            name,
            indexInArray,
            selectedOptions: configurableOptions?.map((option) => option.optionValueUid),
        })

        timerRef.current = setTimeout(() => {
            ga4RemoveFromCart({
                product: data.product,
                configuredVariant: data.configuredVariant,
                quantity: data.quantity,
            })

            setLastDeletedItem(null)
        }, TIME_ON_DELETED_ITEM_UNDO_OPERATION)
    }, [])

    const handleUndoOperation = useCallback(async () => {
        const cartItems: [CartItem] = [{
            quantity: lastDeletedItem.quantity || 1,
            sku: lastDeletedItem.sku,
            selected_options: lastDeletedItem.selectedOptions,
        }]

        try {
            clearTimeout(timerRef.current)
            setIsLoading(true)
            await addItemToCart({ cartItems })
            setIsLoading(false)
            setLastDeletedItem(null)
        } catch (e) {
            setIsLoading(false)
            toast.error(e.message)
        }
    }, [lastDeletedItem])

    return {
        lastDeletedItem,
        setLastDeletedItem: handleSetDeletedItem,
        handleUndoOperation,

    }
}

export default useCartItemUndoOperation

import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'
import { PageAuthorPlaceholder, PageMainImagePlaceholder, PageTitlePlaceholder } from 'elements/BlogStatePages/elements'
import { StrainFamilyAllFamiliesPlaceholder } from 'modules/StrainFamily'

import styles from './StrainFamilyContent.module.scss'

const StrainFamilyContentPlaceholder = () => {
    return (
        <div>
            <PageTitlePlaceholder />
            <PageAuthorPlaceholder />
            <div className={styles.mainContentWrapper}>
                <Placeholder className={styles.descriptionPlaceholderSizes} />
                <PageMainImagePlaceholder className={styles.image} />
            </div>
            <Placeholder className={styles.parsedContentPlaceholder} />
            <StrainFamilyAllFamiliesPlaceholder />
        </div>
    )
}

export default StrainFamilyContentPlaceholder

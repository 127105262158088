import { FilterItem } from '@pwa-concept/modules/graphql'
import { ICategoryCheckbox } from '@pwa-onilab/ui/components/elements/CategoryCheckbox/CategoryCheckbox'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import Label from '@pwa-onilab/ui/components/UI/Label'
import { checkColorRgbIsDark, classNames, getRgbFromHex } from '@pwa-onilab/ui/helpers'
import { useMemo } from 'react'

import styles from './FilterOptionsCheckboxColor.module.scss'

interface IFilterOptionsCheckboxColor extends Pick<ICategoryCheckbox, 'onChange' | 'isChecked' | 'className'> {
    item: FilterItem
}

const FilterOptionsCheckboxColor = ({ item, onChange, isChecked, className }: IFilterOptionsCheckboxColor) => {
    const circleBackground = item.swatchData?.value || 'transparent'
    const labelStyle = useMemo(() => {
        const styles = {
            backgroundColor: circleBackground,
        }
        if (isChecked) {
            const labelBackgroundRgb = getRgbFromHex(circleBackground)
            const isDarkBackground = checkColorRgbIsDark(labelBackgroundRgb)

            styles['color'] = isDarkBackground ? 'white' : 'black'
        }

        return styles
    }, [circleBackground, isChecked])

    return (
        <Label className={classNames(styles.label, isChecked && styles.labelChecked, className)} onClick={onChange}>
            <span className={styles.circle} style={labelStyle}>
                <Icon name="check" className={styles.checkIcon} />
            </span>
            <span className={styles.text}>
                {item.name} ({item.count})
            </span>
        </Label>
    )
}

export default FilterOptionsCheckboxColor

import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type CheckIsEmailAvailableQueryVariables = Types.Exact<{
  email?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CheckIsEmailAvailableQuery = { __typename?: 'Query', checkIsEmailAvailable?: { __typename?: 'checkIsEmailAvailableOutput', isEmailAvailable?: boolean | null } | null };


export const CheckIsEmailAvailableDocument = gql`
    query CheckIsEmailAvailable($email: String) {
  checkIsEmailAvailable(email: $email) @client {
    isEmailAvailable
  }
}
    `;

/**
 * __useCheckIsEmailAvailableQuery__
 *
 * To run a query within a React component, call `useCheckIsEmailAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIsEmailAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIsEmailAvailableQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckIsEmailAvailableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckIsEmailAvailableQuery, CheckIsEmailAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CheckIsEmailAvailableQuery, CheckIsEmailAvailableQueryVariables>(CheckIsEmailAvailableDocument, options);
      }
export function useCheckIsEmailAvailableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckIsEmailAvailableQuery, CheckIsEmailAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CheckIsEmailAvailableQuery, CheckIsEmailAvailableQueryVariables>(CheckIsEmailAvailableDocument, options);
        }
export type CheckIsEmailAvailableQueryHookResult = ReturnType<typeof useCheckIsEmailAvailableQuery>;
export type CheckIsEmailAvailableLazyQueryHookResult = ReturnType<typeof useCheckIsEmailAvailableLazyQuery>;
export type CheckIsEmailAvailableQueryResult = Apollo.QueryResult<CheckIsEmailAvailableQuery, CheckIsEmailAvailableQueryVariables>;
import { Resolvers } from '@pwa-concept/modules/graphql'

import Mutation from './Mutation'
import PromoNotification from './PromoNotification'
import Query from './Query'
import StoreConfig from './StoreConfig'
import StoreConfigStructuredData from './StoreConfigStructuredData'
import StoreConfigWholeSale from './StoreConfigWholeSale'
import CategoryAsStrains from './CategoryAsStrains'

const resolvers: Resolvers = {
    Query,
    Mutation,
    StoreConfig,
    StoreConfigStructuredData,
    StoreConfigWholeSale,
    PromoNotification,
    CategoryAsStrains,
}

export default resolvers

export const createCatalogId = (input) => {
    const { categoryId, limit, page, search, sort, filters } = input

    let id = `Catalog:id:${categoryId}_page:${page}_limit:${limit}`
    if (search) {
        id += `_search:${search}`
    }
    if (filters) {
        id += `_filters:${JSON.stringify(filters)}`
    }
    if (sort) {
        id += `_sort:${JSON.stringify(sort)}`
    }

    return id
}

import { useCartUpdateItemMutation } from '@pwa-concept/modules/cart/graphql/mutations/CartUpdateItem'
import { IApolloMutationsBaseOptions } from '@pwa-concept/modules/common'

export interface IUseCartUpdate {
    cartItems: [IUseCartUpdateItem]
    cartId: string
}

export interface IUseCartUpdateItem {
    cartItemUid: string
    quantity: number
}

const useCartUpdateItem = (baseOptions: IApolloMutationsBaseOptions = {}) => {
    const [updateCartItem] = useCartUpdateItemMutation({ ...baseOptions })

    return async ({ cartId, cartItems }: IUseCartUpdate) => {
        return await updateCartItem({
            variables: {
                input: {
                    cartId,
                    cartItems,
                },
            },
            ignoreResults: true,
        })
    }
}

export default useCartUpdateItem

import { linkOnClickHandler } from '@pwa-onilab/ui/components/UI/Link/helpers'
import { classNames } from '@pwa-onilab/ui/helpers'
import { AnchorHTMLAttributes, createElement } from 'react'
import { Link as RouterLink, NavLink } from 'react-router-dom'

export interface LinkProps extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
    to?: string
    activeClassName?: string
    scroll?: boolean
    fullMatch?: boolean
    state?: any
}

const Link = (
    {
        to = '/',
        activeClassName,
        className,
        children,
        onClick,
        scroll,
        fullMatch,
        state,
        ...otherProps
    }: LinkProps,
) => (
    createElement<any>(
        activeClassName ? NavLink : RouterLink,
        {
            ...otherProps,
            className: activeClassName ? ({ isActive }) => classNames(className, isActive && activeClassName) : className,
            to: to || '/',
            state,
            end: fullMatch,
            onClick: (e) => linkOnClickHandler(e, scroll, onClick),
        },
        children,
    )
)

export default Link

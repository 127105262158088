import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { Skeleton } from '@pwa-onilab/ui/components/elements/Skeleton'

import styles from './CartRecommendedSliderPlaceholder.module.scss'

const CartRecommendedSliderPlaceholder = () => (
    <div className={styles.wrapper}>
        <Skeleton className={styles.title} />
        <div className={styles.list}>
            <Skeleton className={styles.item} />
            <MediaBreakpointUp name="lg">
                <Skeleton className={styles.item} />
                <Skeleton className={styles.item} />
                <Skeleton className={styles.item} />
                <Skeleton className={styles.item} />
                <Skeleton className={styles.item} />
                <Skeleton className={styles.item} />

            </MediaBreakpointUp>
        </div>
    </div>

)

export default CartRecommendedSliderPlaceholder

import { logger } from '@pwa-concept/modules/helpers/logger'
import { useEffect } from 'react'

const registerServiceWorker = () => {
    if (process.env.NODE_ENV === 'development' || !('serviceWorker' in navigator)) {
        return
    }

    navigator.serviceWorker
        .register('./sw.js')
        .then((registration) => {
            console.log('Service worker registered!')
        })
        .catch((error) => {
            logger.log('catch error when register service worker')
            logger.error(error)
        })
}

export const useRegisterServiceWorker = () => {
    useEffect(() => {
        if (document.readyState === 'complete') {
            registerServiceWorker()
            return
        }

        window.addEventListener('load', registerServiceWorker)
        return () => {
            window.removeEventListener('load', registerServiceWorker)
        }
    }, [])
}

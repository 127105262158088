import {
    CartApplyCouponCodeMutationVariables,
    useCartApplyCouponCodeMutation,
} from '@pwa-concept/modules/cart/graphql/mutations/CartApplyCouponCode'

const useCartApplyCouponCode = () => {
    const [applyCode] = useCartApplyCouponCodeMutation()

    return async (code: CartApplyCouponCodeMutationVariables['code']) => {
        return await applyCode({
            variables: {
                code,
            },
            ignoreResults: true,
        })
    }
}

export default useCartApplyCouponCode

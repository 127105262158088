import { NavigateButton } from '@pwa-onilab/ui/components/elements/Buttons/NavigateButton'
import { MediaBreakpointDown, MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import {
    ErrorPageInfo,
} from '@pwa-onilab/ui/components/modules/ErrorPage'
import ErrorPageHelmet from '@pwa-onilab/ui/components/modules/ErrorPage/elements/ErrorPageHelmet'
import Container from '@pwa-onilab/ui/components/UI/Container'
import Title from '@pwa-onilab/ui/components/UI/Title'
import { HOME_ROUTE } from '@pwa-onilab/ui/constants/navigation'
import { classNames } from '@pwa-onilab/ui/helpers'
import useStoreConfigNotFoundPageData from 'app/modules/store-config/features/get/hooks/useStoreConfigNotFoundPageData'
import ErrorPageNavigationButtons
    from 'components/overrides/modules/ErrorPage/components/ErrorPageNavigationButtons/ErrorPageNavigationButtons'
import { useTranslation } from 'react-i18next'

import styles from './Error.module.scss'

export interface IErrorPage {
    errorText: string
    onGoToHomeClick?: () => void
    onLinksClick?: () => void
}

const ErrorPage = ({ errorText, onGoToHomeClick, onLinksClick }: IErrorPage) => {
    const { t } = useTranslation()

    const { data, loading } = useStoreConfigNotFoundPageData()

    const navigationButton = (
        <NavigateButton
            isFullWidth
            route={data?.buttonUrl || HOME_ROUTE}
            className={classNames(styles.navigationButton, loading && styles.placeholder)}
            onClickAdditional={onGoToHomeClick}
        >
            {data?.buttonText || t('global.buttons.goToHomepage')}
        </NavigateButton>
    )

    return (
        <Container className={styles.wrapper}>
            <ErrorPageHelmet />
            <div className={styles.firstPart}>
                <ErrorPageInfo errorText={errorText} />
                <MediaBreakpointDown name="lg">
                    {navigationButton}
                </MediaBreakpointDown>
            </div>
            <div className={styles.secondPart}>
                <Title
                    className={classNames(
                        styles.buttonsBlockTitle,
                        loading && styles.placeholder,
                    )}
                    type="h4"
                    text={data?.urlsListTitle || t('global.errors.whatYouLockingFor')}
                />
                <ErrorPageNavigationButtons
                    isLoading={loading}
                    nodes={data?.urlsList || []}
                    onLinksClick={onLinksClick}
                />
                <MediaBreakpointUp name="lg">
                    {navigationButton}
                </MediaBreakpointUp>
            </div>
        </Container>
    )
}

export default ErrorPage

import { MutationResolvers } from '@pwa-concept/modules/graphql'

import cartAddItem from './cart-add-item'
import cartApplyCouponCode from './cart-apply-coupon-code'
import cartAssignToCustomer from './cart-assign-to-customer'
import cartGenerateId from './cart-generate-id'
import cartRemoveCouponCode from './cart-remove-coupon-code'
import cartRemoveItem from './cart-remove-item'
import cartSetBillingAddress from './cart-set-billing-address'
import cartSetGuestEmail from './cart-set-guest-email'
import cartSetPaymentMethod from './cart-set-payment-method'
import cartSetShippingAddress from './cart-set-shipping-address'
import cartSetShippingMethods from './cart-set-shipping-methods'
import cartUpdateItem from './cart-update-item'
import cartReorderItems from './cartReorderItems'

const Mutation: MutationResolvers = {
    cartGenerateId,
    cartAddItem,
    cartApplyCouponCode,
    cartUpdateItem,
    cartRemoveCouponCode,
    cartRemoveItem,
    cartAssignToCustomer,
    cartSetBillingAddress,
    cartSetPaymentMethod,
    cartSetShippingAddress,
    cartSetShippingMethods,
    cartSetGuestEmail,
    cartReorderItems,
}

export default Mutation

import { classNames } from '@pwa-onilab/ui/helpers'
import { IReactHookFormFields } from '@pwa-onilab/ui/interfaces'
import { ForwardedRef, forwardRef, TextareaHTMLAttributes } from 'react'

import styles from './Textarea.module.scss'

export interface ITextarea extends TextareaHTMLAttributes<HTMLTextAreaElement>, Partial<IReactHookFormFields>{
    onChange: (x) => void
    className?: string
    disableResize?: boolean
}

const Textarea = forwardRef((
    {
        onChange,
        className = '',
        disableResize,
        field,
        register,
        rules,
        errors = {},
        ...restProps
    }: ITextarea, ref: ForwardedRef<HTMLTextAreaElement>) => {
    return (
        <>
            <textarea
                {...restProps}
                ref={ref}
                className={classNames(
                    styles.textarea,
                    disableResize && styles.disableResize,
                    className,
                )}
                onChange={onChange}
                {...register?.(field, {
                    ...rules,
                    onChange,
                })}
            />

            {errors[field] && <p className={styles.error}>{errors[field]?.message}</p>}
        </>
    )
})

export default Textarea

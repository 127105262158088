import { Skeleton } from '@pwa-onilab/ui/components/elements/Skeleton'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './LocalStoresItem.module.scss'

interface ILocalStoresItemSkeletonProps {
    className?: string
}

const LocalStoresItemSkeleton = ({ className }: ILocalStoresItemSkeletonProps) => (
    <div className={styles.wrapperSkeleton}>
        <Skeleton className={classNames(className, styles.skeleton)} />
    </div>
)

export { LocalStoresItemSkeleton }

import { InputRange } from '@pwa-onilab/ui/components/elements/Inputs/InputRange'
import { IFilterOptions } from '@pwa-onilab/ui/components/modules/Filters/elements/FilterOptions/IFilterOptions'
import { useCallback, useMemo, useState } from 'react'

interface FilterOptionsPriceProps extends Pick<IFilterOptions, 'onChangeValue' | 'data'>{
    value?: [minValue: string, maxValue: string]
}

const FilterOptionsPrice = (
    {
        data,
        value,
        onChangeValue,
    }: FilterOptionsPriceProps,
) => {
    const [min, max] = useMemo(() => {
        if (!data?.items?.length) {
            return [0, 0]
        }

        return [data.items[0]?.value || 0, +data.items[data.items.length - 1]?.value + 1 || 0] || [0, 0]
    }, [data.items])

    const [range, setRange] = useState(value || [min, max] || [])

    const onFinalChange = useCallback((newValues) => {
        const isRangeEqualToCurrentFilter = value?.length && newValues.every((el, idx) => el === +value[idx])
        if (isRangeEqualToCurrentFilter) {
            return null
        }

        onChangeValue((prev) => ({
            ...(prev || {}),
            filters: {
                ...(prev?.filters || {}),
                [data.key]: newValues,
            },
        }))
    }, [value])

    return (
        <InputRange
            min={min}
            max={max}
            step={0.1}
            values={range}
            onFinalChange={onFinalChange}
            onChange={setRange}
        />
    )
}

export default FilterOptionsPrice

import { CustomerAddress } from '@pwa-concept/modules/graphql'
import { IAccountAddress } from '@pwa-onilab/ui/components/modules/AccountAddress'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import { useTranslation } from 'react-i18next'
import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Icon from '@pwa-onilab/ui/components/UI/Icon'

import styles from 'modules/AccountAddress/AccountAddressButtonDelete/AccountAddressButtonDelete.module.scss'

interface IAccountAddressButtonDelete extends Pick<IAccountAddress & CustomerAddress, 'id' | 'openDeleteAddressModal'>{
    className?: string
}

const AccountAddressButtonDelete = (
    {
        id,
        openDeleteAddressModal,
        className,
    }: IAccountAddressButtonDelete,
) => {
    const { t } = useTranslation()

    const { isBreakpointUp } = useMediaBreakpoint()

    return (
        <ButtonInstance onClick={openDeleteAddressModal(id)} className={className}>
            {
                isBreakpointUp('lg') ? (
                    <Icon
                        name="trash"
                        className={styles.icon}
                    />
                ) : (
                    <>
                        {t('global.delete')}
                    </>
                )
            }
        </ButtonInstance>
    )
}

export default AccountAddressButtonDelete

import { BlogAuthor } from '@pwa-concept/modules/graphql'
import { DefaultHelmet } from '@pwa-onilab/ui/components/elements/DefaultHelmet'
import { SchemaHelmetPerson } from 'elements/SchemaHelmet/SchemaHelmetPerson'
import { SchemaHelmetWebPage } from 'elements/SchemaHelmet/SchemaHelmetWebPage'

const AuthorPageHelmet = ({ author }: { author: BlogAuthor}) => {
    return (
        <>
            <DefaultHelmet meta={author.meta} defaultTitle="" />
            <SchemaHelmetPerson author={author} />
            <SchemaHelmetWebPage meta={author.meta} />
        </>
    )
}

export default AuthorPageHelmet

import { BlogAuthor } from '@pwa-concept/modules/graphql'
import { AuthorPageCategories, AuthorPagePosts, AuthorPageRowWrapper } from 'modules/AuthorPage'

import styles from './AuthorPagePostsInfo.module.scss'

const AuthorPagePostsInfo = ({ author, loading }: { author?: BlogAuthor, loading?: boolean}) => {
    return (
        <AuthorPageRowWrapper className={styles.infoWrapper}>
            <AuthorPageCategories author={author} loading={loading} />
            <AuthorPagePosts author={author} loading={loading} />
        </AuthorPageRowWrapper>
    )
}

export default AuthorPagePostsInfo

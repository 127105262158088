import { SimpleButton } from '@pwa-onilab/ui/components/elements/Buttons'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useLogOut } from '@pwa-onilab/ui/hooks'
import { useTranslation } from 'react-i18next'

import styles from './AccountLogOutButton.module.scss'

interface IAccountLogOutButton {
    className?: string
}

const AccountLogOutButton = ({ className }: IAccountLogOutButton) => {
    const { t } = useTranslation()

    const logOut = useLogOut()

    return (
        <SimpleButton onClick={logOut} className={classNames(styles.button, className)}>
            <Icon name="logout" className={styles.icon} />
            {t('AccountPage.navigation.logOut')}
        </SimpleButton>
    )
}

export default AccountLogOutButton

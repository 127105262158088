import { CategoryBreadcrumbsSkeleton } from '@pwa-onilab/ui/components/modules/Category'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { Section } from '@pwa-onilab/ui/components/UI/Section'
import { PropsWithChildren } from 'react'

import css from './CategoryLayout.module.scss'

const CategoryLayoutSkeleton = (
    {
        children,
    }: PropsWithChildren<{}>,
) => (
    <>
        <Section className={css.breadcrumbs}>
            <Container>
                <CategoryBreadcrumbsSkeleton />
            </Container>
        </Section>
        <main className={css.main}>
            {children}
        </main>
    </>
)

export { CategoryLayoutSkeleton }

import { $auth, $cart } from '@pwa-concept/core/models'
import { useCartAssignToCustomer } from '@pwa-concept/modules/cart'
import { logger } from '@pwa-concept/modules/helpers/logger'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const useCustomerAssign = () => {
    const assignCart = useCartAssignToCustomer()
    const { t } = useTranslation()

    return async () => {
        if ($auth.isToken) {
            const sourceCartId = $cart.id
            try {
                const { data: { cartAssignToCustomer } = {} } = await assignCart({ sourceCartId })
                return cartAssignToCustomer
            } catch (e) {
                logger.error(e)
                toast.error(t('global.errors.common'))
            }
        }
        return null
    }
}

export default useCustomerAssign

import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

export type CartShippingAddressesFragment = { __typename?: 'CartShippingAddresses', city?: string | null, company?: string | null, firstName?: string | null, lastName?: string | null, postCode?: string | null, street?: Array<string | null> | null, telephone?: string | null, availableShippingMethods?: Array<{ __typename?: 'AvailableShippingMethod', available?: string | null, carrierCode?: string | null, carrierTitle?: string | null, methodCode?: string | null, methodTitle?: string | null, price?: { __typename?: 'CartMoneyOnly', money?: string | null } | null, priceExcludingTax?: { __typename?: 'CartMoneyOnly', money?: string | null } | null, priceIncludingTax?: { __typename?: 'CartMoneyOnly', money?: string | null } | null } | null> | null, country?: { __typename?: 'CartAddressCountry', code?: string | null, label?: string | null } | null, region?: { __typename?: 'CartAddressRegion', code?: string | null, label?: string | null, regionId?: number | null } | null, selectedShippingMethod?: { __typename?: 'CartSelectedShippingMethod', carrierCode?: string | null, carrierTitle?: string | null, methodCode?: string | null, methodTitle?: string | null, price?: { __typename?: 'CartMoneyOnly', money?: string | null } | null } | null };

export const CartShippingAddressesFragmentDoc = gql`
    fragment CartShippingAddresses on CartShippingAddresses {
  availableShippingMethods {
    price {
      money
    }
    available
    carrierCode
    carrierTitle
    methodCode
    methodTitle
    priceExcludingTax {
      money
    }
    priceIncludingTax {
      money
    }
  }
  city
  company
  country {
    code
    label
  }
  firstName
  lastName
  postCode
  region {
    code
    label
    regionId
  }
  selectedShippingMethod {
    price {
      money
    }
    carrierCode
    carrierTitle
    methodCode
    methodTitle
  }
  street
  telephone
}
    `;
import { useCustomerOrder } from '@pwa-concept/modules/customer-orders'
import { IOrderComponent, OrderFieldPlaceholder } from '@pwa-onilab/ui/components/modules/Order'
import { classNames } from '@pwa-onilab/ui/helpers'
import Totals from 'components/overrides/elements/Totals/Totals'

import styles from './OrderTotals.module.scss'

const OrderTotals = ({ orderNumber, className }: IOrderComponent) => {
    const {
        data: {
            shippingMethod,
            totals: {
                totalShipping,
                total,
                subtotal,
                discounts,
            } = {},
        } = {}, loading,
    } = useCustomerOrder(orderNumber)

    if (loading) {
        return <OrderFieldPlaceholder className={styles.placeholder} />
    }

    return (
        <div className={classNames(styles.wrapper, className)}>
            <Totals
                subtotal={subtotal}
                discounts={discounts}
                delivery={totalShipping || 0}
                deliveryName={shippingMethod}
                total={total}
            />
        </div>

    )
}

export default OrderTotals

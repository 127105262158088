import { BlogAuthor, SocialUrls } from '@pwa-concept/modules/graphql'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import Title from '@pwa-onilab/ui/components/UI/Title'
import { useTranslation } from 'react-i18next'

import styles from './AuthorPageSocialLinks.module.scss'

const SOCIAL_NAME_ICONS_MAP: SocialUrls = {
    facebook: 'facebook',
    instagram: 'instagram',
    linkedin: 'linkedin',
    pinterest: 'pinterestGrayscale',
    twitter: 'twitter',
    youtube: 'youtubeGrayscale',
}

const AuthorPageSocialLinks = ({ socialUrls }: { socialUrls: BlogAuthor['socialUrls'] }) => {
    const { t } = useTranslation()

    const socialIcons = Object.entries(socialUrls).filter(([name, url]) => !!url)

    return (
        <div className={styles.wrapper}>
            <Title type="h4" text={t('global.follow')} className={styles.title} />
            <ul className={styles.list}>
                {socialIcons.map(([name, url]) => {
                    const icon = SOCIAL_NAME_ICONS_MAP[name] || 'question'

                    return (
                        <li key={url} className={styles.listItem}>
                            <Anchor to={url} aria-label={name}>
                                <Icon name={icon} />
                            </Anchor>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default AuthorPageSocialLinks

import { MILLISECONDS_IN_DAY } from '@pwa-onilab/ui/constants/app'

export interface IMagento2Review {
    average_rating: number
    created_at: string
    nickname: string
    summary: string
    text: string
}

export interface IReview {
    averageRating: number
    createdDaysAgo: number
    createdAt: string
    nickname: string
    summary: string
    text: string
}

const formatReview = (review: IMagento2Review): IReview => {
    const createdAt = review?.created_at

    let daysCount = null

    if (createdAt) {
        const currentDate = new Date()
        const reviewCreationDate = new Date(createdAt)
        // @ts-expect-error
        const dateDifference = currentDate - reviewCreationDate // milliseconds
        daysCount = Math.floor(dateDifference / MILLISECONDS_IN_DAY)
    }

    return {
        averageRating: review?.average_rating || null,
        nickname: review?.nickname || null,
        summary: review?.summary || null,
        text: review?.text || null,
        createdDaysAgo: daysCount,
        createdAt,
    }
}

export const mapProductReviewsMainData = (products) => {
    const simpleItem = products?.items?.find(({ __typename: typename }) => typename === 'SimpleProduct' || typename === 'ConfigurableProduct')
    return {
        ratingSummary: simpleItem?.rating_summary || null,
        reviewCount: simpleItem?.review_count || 0,
    }
}

export const mapProductReviews = (products) => {
    const simpleItem = products?.items?.find(({ __typename: typename }) => typename === 'SimpleProduct' || typename === 'ConfigurableProduct')
    const reviews = simpleItem?.reviews?.items.map(review => formatReview(review)) || null
    return {
        reviews,
        reviewCount: simpleItem?.review_count || 0,
    }
}

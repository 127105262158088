import { SimpleButton } from '@pwa-onilab/ui/components/elements/Buttons'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import useModalStates from '@pwa-onilab/ui/hooks/modals/useModalStates'
import { WishlistShareViaEmailModal, WishlistShareViaEmailForm } from '@pwa-onilab/ui/components/modules/Wishlist'

import styles from './WishlistShareMobile.module.scss'

interface WishlistShareMobileProps {
    className: string
}

const WishlistShareMobile = (
    {
        className,
    }: Partial<WishlistShareMobileProps>,
) => {
    const {
        isModalOpen,
        openModal,
        closeModal,
    } = useModalStates()

    return (
        <>
            <SimpleButton
                onClick={openModal}
                className={classNames(styles.button, className)}
            >
                <Icon name="share" className={styles.icon} />
            </SimpleButton>

            <WishlistShareViaEmailModal
                isOpen={isModalOpen}
                onCloseModal={closeModal}
            >
                <WishlistShareViaEmailForm />
            </WishlistShareViaEmailModal>
        </>
    )
}

export default WishlistShareMobile

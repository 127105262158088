import { $currencySign } from '@pwa-concept/core/models'
import { getFormattedPrice } from '@pwa-concept/modules/currency/utils'
import { IPriceConfig } from '@pwa-onilab/ui/components/UI/Price/IPriceConfig'
import { PRICE_CONFIG } from '@pwa-onilab/ui/components/UI/Price/PriceConfig'
import { NBSP_CHAR_CODE } from '@pwa-onilab/ui/constants/characters'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { PropsWithChildren } from 'react'
import Cookies from 'js-cookie'
import stylesAttributesText from 'components/overrides/modules/ProductPage/elements/ProductInfo/ProductInfoAttributes/AttributesText/AttributesText.module.scss'

export interface PriceProps extends Partial<IPriceConfig>, IClassName{
}

const addCurrencySign = (
    str: string = '',
    isCurrencyBeforeValue: boolean = false,
    isReplaceSpace: boolean = false,
) => {
    if (isCurrencyBeforeValue) {
        return $currencySign.sign
            .concat(isReplaceSpace ? '' : NBSP_CHAR_CODE)
            .concat(str)
    }
    return str
        .concat(isReplaceSpace ? '' : NBSP_CHAR_CODE)
        .concat($currencySign.sign)
}

// https://gist.github.com/ncreated/9934896  lists "1205.34" float number formatted with all possible iOS locales.
// https://gist.github.com/raushankrjha/d1c7e35cf87e69aa8b4208a8171a8416 list with intl locales
const Price = (
    {
        children,
        className,
        isReplaceSpace = PRICE_CONFIG.isReplaceSpace,
        isCurrencyBeforeValue = PRICE_CONFIG.isCurrencyBeforeValue,
    }: PropsWithChildren<PriceProps>,
) => {
    const moneyValue = +children
    let formattedPrice = getFormattedPrice(moneyValue)
    formattedPrice = addCurrencySign(formattedPrice, isCurrencyBeforeValue, isReplaceSpace)

    if (Cookies.get('current_store') && Cookies.get('current_store') == 'ca') {
        return (
            <div className={className}>
                <div className={stylesAttributesText.priceBox}>
                    <span>{`CA`}</span>
                    <span>{`$${children}`}</span>
                </div>
            </div>
        )
    } else {
        return (
            <div className={className}>
                {formattedPrice}
            </div>
        )
    }
}

export default Price

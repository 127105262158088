import Parser from '@pwa-onilab/ui/helpers'
import { IParser } from '@pwa-onilab/ui/helpers/parser'
import { PageVisibleOnScreen } from 'elements/BlogStatePages/elements/PageVisibleOnScreen'
import { getChildrenTextRecursive } from 'elements/BlogStatePages/helplers/getChildrenTextRecursive'
import { createRef, Dispatch, SetStateAction, useMemo } from 'react'

const TAGS_WHERE_REPLACE_WORK = ['h1', 'h2', 'h3']

interface IPageParsedContent extends Pick<IParser, 'content'> {
    setCurrentVisibleOnScreen: Dispatch<SetStateAction<string>>
}

const usePageParsedContent = ({ content, setCurrentVisibleOnScreen }: IPageParsedContent) => {
    return useMemo(() => {
        const headers = []
        const refs = []

        let length = 0
        let isReplaceEnabled: boolean = false

        const customReplaceConditionFunc = (node) => {
            if (!isReplaceEnabled) {
                isReplaceEnabled = node && node.attribs?.['data-has-anchor'] === '1'
                return false
            }

            return TAGS_WHERE_REPLACE_WORK.includes(node.name)
        }

        const customReplace = (node) => {
            const text = getChildrenTextRecursive(node)
            const ref = createRef<any>()
            refs.push(ref)
            headers.push(text)

            const onScreen = (text) => setCurrentVisibleOnScreen(text)

            return (
                <PageVisibleOnScreen
                    onScreen={onScreen}
                    refProp={ref}
                    componentProps={{
                        'data-index': length++,
                    }}
                    as={node.name}
                >
                    {text}
                </PageVisibleOnScreen>
            )
        }

        const parsedContent = content
            ? Parser({
                content,
                customReplace,
                customReplaceConditionFunc,
            })
            : null

        return {
            content: parsedContent,
            headers,
            refs,
        }
    }, [content])
}

export default usePageParsedContent

import { extractGa4EcommerceProduct, IGa4EcommerceProduct } from "./extractGa4EcommerceProduct";

export const extractGa4EcommerceProductObject = (props: IGa4EcommerceProduct) => {
    const {
        product,
        configuredVariant,
        currentVariant,
        quantity = 1,
    } = props
    const configurableSelectedProductVariant = currentVariant?.product || configuredVariant
    const addedProduct = configurableSelectedProductVariant || product

    const ecommerce = {
        currency: product.price.current.currency,
        value: addedProduct.price.current.value * quantity,
        items: [extractGa4EcommerceProduct(props)]
    }

    return ecommerce
}

import { Product } from '@pwa-concept/modules/graphql'
import { SearchTitle } from '@pwa-onilab/ui/components/modules/Search'
import SearchProductCard from 'components/overrides/modules/Product/SearchProductCard/SearchProductCard'
import { useTranslation } from 'react-i18next'

import styles from './SearchProducts.module.scss'

interface ISearchProductsProps {
    items: Product[]
}

const SearchProducts = (
    {
        items,
    }: ISearchProductsProps,
) => {
    if (!items?.length) return null

    const { t } = useTranslation()

    return (
        <>
            <SearchTitle
                title={t('search.result.title')}
                className={styles.title}
            />
            <div className={styles.products}>
                {items.map((data) => {
                    const currentProduct = data || data?.variants?.[0]?.product

                    return (
                        <SearchProductCard
                            key={currentProduct.id}
                            product={currentProduct}
                            className={styles.card}
                        />
                    )
                })}
            </div>
        </>
    )
}

export default SearchProducts

import { PropsWithChildren } from 'react'
import { ScrollRestoration } from 'react-router-dom'

const NavigationScrollRestorationDriver = ({ children }: PropsWithChildren<any>) => {
    return (
        <>
            {children}
            <ScrollRestoration />
        </>
    )
}

export default NavigationScrollRestorationDriver

import { Resolvers } from '@pwa-concept/modules/graphql'

import AccessToken from './AccessToken'
import Mutation from './Mutation'
import Query from './Query'
import ResetToken from './ResetToken'

const resolvers: Resolvers = {
    Mutation,
    Query,
    AccessToken,
    ResetToken,
}

export default resolvers

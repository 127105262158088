import { SearchList, SearchTitle } from '@pwa-onilab/ui/components/modules/Search'
import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useTranslation } from 'react-i18next'

import styles from './SearchHistory.module.scss'

interface ISearchHistoryProps {
    lastSearches: string[]
    onClearLastSearches: () => void
    className?: string
}

const SearchHistory = (
    {
        lastSearches,
        onClearLastSearches,
        className,
    }: ISearchHistoryProps,
) => {
    if (!lastSearches?.length) return null

    const { t } = useTranslation()

    return (
        <div className={classNames(className)}>
            <div className={styles.header}>
                <SearchTitle title={t('search.history.title')} />
                <ButtonInstance
                    onClick={onClearLastSearches}
                    className={styles.button}
                >
                    {t('search.history.clearAll')}
                </ButtonInstance>
            </div>
            <SearchList items={lastSearches} />
        </div>
    )
}

export default SearchHistory

import { Breadcrumbs } from '@pwa-onilab/ui/components/modules/Breadcrumbs'
import { classNames } from '@pwa-onilab/ui/helpers'
import { memo } from 'react'

interface WishlistBreadcrumbsProps {
    className: string
}

const WishlistBreadcrumbs = memo((
    {
        className,
    }: Partial<WishlistBreadcrumbsProps>,
) => (
    <Breadcrumbs
        className={classNames(className)}
        items={[{ name: 'Wishlist' }]}
    />
))

export default WishlistBreadcrumbs

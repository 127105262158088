import { classNames } from '@pwa-onilab/ui/helpers'
import { FilterGroupButtonTooltip } from 'modules/Filters'

import styles from './FilterGroupButton.module.scss'

interface FilterGroupButtonProps {
    className?: string
    title: string
    description: string
}

const FilterGroupButton = (
    {
        className,
        title,
        description,
    }: FilterGroupButtonProps,
) => (
    <div className={classNames(styles.button, className)}>
        <div className={styles.title}>
            {title}
        </div>
        <FilterGroupButtonTooltip description={description} />
    </div>
)

export default FilterGroupButton

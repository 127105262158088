import { IDefaultHelmet } from '@pwa-onilab/ui/components/elements/DefaultHelmet'
import { IPageAuthor } from 'elements/BlogStatePages/elements/PageAuthor/PageAuthor'
import { SchemaHelmet } from 'elements/SchemaHelmet'
import {
    SCHEMA_HELMET_CONTEXT_VALUE,
    SCHEMA_HELMET_DEFAULT_SITE_URL,
} from 'elements/SchemaHelmet/SchemaHelmetConstants'
import { getSchemaHelmetAuthorId } from 'elements/SchemaHelmet/SchemaHelmetHelpers'
import { addSlashToEndUrl } from 'helpers'

export interface IArticleSchemaConfig {
    title?: String
    datePublished?: String
    imageSrc?: String
    thumbnailSrc?: String
    categories?: string[] | null | undefined
}

interface ISchemaHelmetArticle {
    meta: IDefaultHelmet['meta']
    config: IArticleSchemaConfig
    author?: IPageAuthor['author']
}

const getArticleSchema = (
    meta: ISchemaHelmetArticle['meta'],
    config: ISchemaHelmetArticle['config'],
    author?: IPageAuthor['author'],
) => {
    const url = addSlashToEndUrl(window.location.href)

    const schemaConfig = {
        '@context': SCHEMA_HELMET_CONTEXT_VALUE,
        '@type': 'Article',

        headline: config.title,
        datePublished: config.datePublished,

        mainEntityOfPage: {
            '@id': url,
        },
        publisher: {
            '@id': `${SCHEMA_HELMET_DEFAULT_SITE_URL}#organization`,
        },

        image: config.imageSrc,
        thumbnailUrl: config.thumbnailSrc,

        keywords: meta.keywords,
    }
    if (config.categories?.length) {
        schemaConfig['articleSection'] = config.categories
    }

    if (author?.name) {
        schemaConfig['author'] = {
            name: author.name,
            '@id': getSchemaHelmetAuthorId(author),
        }
    }

    return schemaConfig
}

const SchemaHelmetArticle = ({ meta, config, author }: ISchemaHelmetArticle) => {
    if (!meta || !config) {
        return null
    }

    const schemaConfig = getArticleSchema(meta, config, author)

    return <SchemaHelmet jsonConfig={schemaConfig} />
}

export default SchemaHelmetArticle

import { memo } from 'react'
import { Trans } from 'react-i18next'

const CartOutOfStockItemsError = () => {
    return (
        <Trans
            i18nKey="cart.proceedToCheckout.error.outOfStockItems"
            components={
            {
                b: <b />,
            }
        }
        />
    )
}

export default memo(CartOutOfStockItemsError)

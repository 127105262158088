import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { addSlashToEndUrl } from 'helpers'

import styles from './Breadcrumb.module.scss'

export interface BreadcrumbProps {
    link?: string
    name: string
}

const Breadcrumb = ({ link, name }: BreadcrumbProps) => {
    if (link) {
        return (
            <Anchor
                to={addSlashToEndUrl(link)}
                className={styles.breadcrumb}
            >
                {name}
                &nbsp;/
            </Anchor>
        )
    }

    return (
        <span className={styles.lastBreadcrumb}>
            {name}
        </span>
    )
}

export default Breadcrumb

import { module } from '@pwa-concept/core'
import { CART_KEYFIELD } from '@pwa-concept/modules/cart/constants'
import { ProductModule } from '@pwa-concept/modules/product'

import graphqlSchemas from './graphql/schemas'
import stores from './graphql/stores'

const CartModule = module(() => ({
    modules: [
        ProductModule,
    ],
    graphqlSchemas,
    graphqlResolvers: stores?.resolvers,
    graphqlStoreFragments: stores?.fragments,
    graphqlStorePossibleTypes: stores?.possibleTypes,
    graphqlPolicies: {
        Cart: {
            keyFields: () => CART_KEYFIELD, // we should store only one cart into cache
        },
    },
}))

export default CartModule

import { useCategories } from '@pwa-concept/modules/category'
import { Category } from '@pwa-concept/modules/graphql'
import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import {
    CATEGORY_NAV_LIST_SETTINGS,
    CategoryNavCard,
    CategoryNavListSkeleton,
} from '@pwa-onilab/ui/components/modules/Category'
import Slider, { SliderSlide } from '@pwa-onilab/ui/components/UI/Slider'

import css from './CategoryNavList.module.scss'

interface CategoryNavListProps {
    category: Category
}

const CategoryNavList = (
    {
        category,
    }: CategoryNavListProps,
) => {
    const { data: categories, loading } = useCategories({
        parent: category,
    }, { skip: !category })

    const { isBreakpointDown } = useMediaBreakpoint()

    if (loading) {
        return <CategoryNavListSkeleton />
    }

    if (!categories?.length) return null

    if (isBreakpointDown('lg')) {
        return (
            <div className={css.list}>
                {categories?.map?.((category, index) => (
                    <CategoryNavCard
                        key={category?.id || index}
                        category={category}
                    />
                ))}
            </div>
        )
    }

    return (
        <>
            {
                categories?.length > CATEGORY_NAV_LIST_SETTINGS.maxSingleCards ? (
                    <Slider
                        slidesPerView={CATEGORY_NAV_LIST_SETTINGS.sliderPerViewDesktop}
                        spaceBetween={CATEGORY_NAV_LIST_SETTINGS.spaceBetweenDesktop}
                        breakpoints={{
                            992: {
                                slidesPerView: CATEGORY_NAV_LIST_SETTINGS.sliderPerViewMobile,
                                spaceBetween: CATEGORY_NAV_LIST_SETTINGS.spaceBetweenMobile,
                            },
                        }}
                        className={css.slider}
                    >
                        {categories?.map?.((category, index) => (
                            <SliderSlide key={category?.id || index}>
                                <CategoryNavCard category={category} />
                            </SliderSlide>
                        ))}
                    </Slider>
                ) : (
                    <div className={css.list}>
                        {categories?.map?.((category, index) => (
                            <CategoryNavCard
                                key={category?.id || index}
                                category={category}
                            />
                        ))}
                    </div>
                )
                }
        </>
    )
}

export default CategoryNavList

import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { IAnchor } from '@pwa-onilab/ui/components/UI/Anchor/IAnchor'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'

import styles from './AnchorWithArrow.module.scss'

interface ILinkWithArrow extends IAnchor {
    arrowClassName?: IClassName['className']
    isPlaceArrowBeforeChild?: boolean
}

const AnchorWithArrow = (
    {
        children,
        className,
        arrowClassName,
        isPlaceArrowBeforeChild,
        ...anchorProps
    }: ILinkWithArrow) => {
    const arrow = <Icon name="chevron" className={classNames(styles.chevron, arrowClassName)} />

    return (
        <Anchor {...anchorProps} className={classNames(styles.link, className)}>
            {isPlaceArrowBeforeChild && arrow}
            {children}
            {!isPlaceArrowBeforeChild && arrow}
        </Anchor>
    )
}

export default AnchorWithArrow

import { classNames } from '@pwa-onilab/ui/helpers'
import { createElement, PropsWithChildren } from 'react'

import css from './CategoryProductCardWrapper.module.scss'

export interface ProductCardWrapperProps {
    as?: Parameters<typeof createElement>[0]
    className?: string
    isOutOfStock?: boolean
}

const ProductCardWrapper = (
    {
        as = 'div',
        className,
        isOutOfStock,
        children,
    }: PropsWithChildren<ProductCardWrapperProps>,
) => (
    createElement<{ className?: string }>(
        as,
        {
            className: classNames(
                css.wrapper,
                isOutOfStock && css.outOfStock,
                className,
            ),
        },
        children,
    )
)

export default ProductCardWrapper

import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { ImageLazy } from '@pwa-onilab/ui/components/UI/Image/ImageLazy'
import { LinkArrow } from 'components/UI/LinkArrow'
import { addSlashToEndUrl } from 'helpers'
import { useTranslation } from 'react-i18next'

import styles from './BeautyBlogItem.module.scss'

interface IBeautyBlogItemProps {
    image: string
    title: string
    to: string
    subtitle: string
    publishTime: string
}

const BeautyBlogItem = (
    {
        image,
        title,
        to = '/',
        subtitle,
        publishTime,
    }: IBeautyBlogItemProps) => {
    const { t } = useTranslation()
    const url: string = addSlashToEndUrl(to)

    return (
        <div className={styles.wrapper}>
            {
                image && (
                    <Anchor
                        to={url}
                        className={styles.imageWrapper}
                        aria-label={title || t('anchors.description.blogPage')}
                    >
                        <ImageLazy
                            src={image}
                            alt={title}
                            className={styles.image}
                        />
                    </Anchor>
                )
            }
            <div>
                {
                    publishTime && (
                        <div className={styles.time}>
                            {publishTime}
                        </div>
                    )
                }
                {
                    title && (
                        <div className={styles.title}>
                            {title}
                        </div>
                    )
                }
                {
                    subtitle && (
                        <div className={styles.subtitle}>
                            {subtitle}
                        </div>
                    )
                }
                {
                    to && (
                        <LinkArrow
                            className={styles.link}
                            to={url}
                            withArrow
                        >
                            {title}
                        </LinkArrow>
                    )
                }
            </div>
        </div>
    )
}

export default BeautyBlogItem

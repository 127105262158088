import Flex from '@pwa-onilab/ui/components/elements/Flex/Flex'
import { Skeleton } from '@pwa-onilab/ui/components/elements/Skeleton'

import css from './WidgetHeader.module.scss'

export interface IWidgetHeaderSkeletonProps {
    classNameWrapper?: string
    isTo?: boolean
}

const WidgetHeaderSkeleton = (
    {
        classNameWrapper,
        isTo = false,
    }: IWidgetHeaderSkeletonProps,
) => (
    <div className={classNameWrapper}>
        <Flex direction="column">
            <Flex
                direction="row"
                justifyContent="spaceBetween"
                className={css.widgetHeaderTopSkeleton}
            >
                <Skeleton className={css.titleSkeleton} />
                {isTo && <Skeleton className={css.viewAllButtonSkeleton} />}
            </Flex>
        </Flex>
    </div>
)

export { WidgetHeaderSkeleton }

import { SimpleProductCardSkeleton } from '@pwa-onilab/ui/components/modules/Product'
import Slider, { SliderNavigation, SliderScrollbar } from '@pwa-onilab/ui/components/UI/Slider'
import SimpleProductCardWrapper from 'components/overrides/modules/Product/SimpleProductCard/SimpleProductCardWrapper'
import { IProductListContent } from 'components/widgets/ProductList/components/IProductListContent'
import { SwiperSlide } from 'swiper/react'

import styles from './ProductListSlider.module.scss'

const ProductListSlider = (
    {
        products, // id, sku only if loading
        isLoading,
    }: Pick<IProductListContent, 'products' | 'isLoading'>,
) => {
    return (
        <Slider
            spaceBetween={12}
            breakpoints={{
                991: {
                    slidesPerView: 4,
                    spaceBetween: 24,
                    slidesPerGroup: 4,
                },
                576: {
                    slidesPerView: 3,
                    spaceBetween: 12,
                    slidesPerGroup: 3,
                },
                360: {
                    slidesPerView: 2.2,
                    spaceBetween: 12,
                },
            }}
            className={styles.slider}
        >
            {
                products.map((product) => {
                    return (
                        <SwiperSlide key={product.sku}>
                            {
                                isLoading
                                    ? <SimpleProductCardSkeleton />
                                    : <SimpleProductCardWrapper
                                            currentViewVariant="grid"
                                            product={product}
                                            itemsListName="ProductListSlider widget"
                                      />
                            }
                        </SwiperSlide>
                    )
                })
            }
            <SliderNavigation
                variant="default"
                nextClassName={styles.nextArrow}
                prevClassName={styles.prevArrow}
            />
            <SliderScrollbar className={styles.scrollBar} />
        </Slider>
    )
}

export default ProductListSlider

import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { ComponentProps } from 'react'

interface IPlaceholderPaginationList {
    maxPossibleItems?: number
    maxPlaceholdersOnPage: number
    itemClassName?: IClassName['className']
    alternativePlaceholderComponent?: (props?: ComponentProps<IClassName & any>) => JSX.Element
    placeholderProps?: ComponentProps<IClassName & any>
}

const PlaceholderPaginationListItems = (
    {
        maxPlaceholdersOnPage,
        maxPossibleItems,
        itemClassName,
        alternativePlaceholderComponent,
        placeholderProps,
    }: IPlaceholderPaginationList,
) => {
    const placeholdersCount = Math.min(maxPossibleItems || maxPlaceholdersOnPage, maxPlaceholdersOnPage)
    const PlaceholderComponent = alternativePlaceholderComponent || Placeholder

    return (
        <>
            {
                Array
                    .from({ length: placeholdersCount })
                    .map((_, idx) => (
                        <li key={idx} className={itemClassName}>
                            <PlaceholderComponent {...placeholderProps} />
                        </li>
                    ))
            }
        </>
    )
}

export default PlaceholderPaginationListItems

import {
    AccountLogOutButton,
    AccountNavigationList,
} from '@pwa-onilab/ui/components/modules/Account'
import { memo } from 'react'
import { AccountNavigationTitle } from 'modules/Account'
import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'

import styles from './AccountNavigation.module.scss'

const AccountNavigation = () => {
    return (
        <div className={styles.wrapper}>
            <MediaBreakpointUp name="lg">
                <AccountNavigationTitle className={styles.title} />
            </MediaBreakpointUp>
            <AccountNavigationList />
            <AccountLogOutButton className={styles.logOutButton} />
        </div>
    )
}

export default memo(AccountNavigation)

import { Skeleton } from '@pwa-onilab/ui/components/elements/Skeleton'

import css from './PaginationLoadMore.module.scss'

const PaginationLoadMoreSkeleton = () => (
    <div className={css.loadMore}>
        <Skeleton className={css.skeleton} />
    </div>
)

export default PaginationLoadMoreSkeleton

import Container from '@pwa-onilab/ui/components/UI/Container'
import Error404Page from '@pwa-onilab/ui/pages/Error404Page'
import { LocationCard, useLocationsQuery, WorldPageBreadcrumbs, WorldPageHelmet } from 'modules/WorldPage'
import { WorldPagePlaceholder } from 'pages/WorldPage/index'

import styles from './WorldPage.module.scss'

const WorldPage = () => {
    const { data: locations, loading } = useLocationsQuery()

    if (loading) {
        return <WorldPagePlaceholder />
    }

    if (!locations) {
        return <Error404Page />
    }

    return (
        <Container className={styles.wrapper}>
            <WorldPageHelmet />
            <WorldPageBreadcrumbs />
            <ul className={styles.list}>
                {locations.map((location) => {
                    return (
                        <li key={location.id}>
                            <LocationCard location={location} />
                        </li>
                    )
                })}
            </ul>
        </Container>
    )
}

export default WorldPage

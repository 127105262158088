import {
    useSendDeleteAccountMessageMutation,
} from '@pwa-concept/modules/customer/graphql/mutations/SendDeleteAccountMessage'

const useSendDeleteAccountMassage = () => {
    const [sendDeleteAccRequest] = useSendDeleteAccountMessageMutation()

    return async () => {
        const { data: { sendDeleteAccountMessage } = {} } = await sendDeleteAccRequest({
            ignoreResults: true,
        })

        return sendDeleteAccountMessage
    }
}

export default useSendDeleteAccountMassage

import { Link } from '@pwa-onilab/ui/components/UI/Link'
import { LinkEntity } from '@pwa-onilab/ui/components/UI/LinkEntity'
import { useRouter } from '@pwa-onilab/ui/hooks'
import MobileMenuAnchor from 'components/overrides/modules/MobileMenuPage/MobileMenuAnchor/MobileMenuAnchor'
import { useParams } from 'react-router-dom'

export interface IMobileMenuNodeData {
    type?: 'wrapper' | 'cms_page' | 'custom_url'
    nodeId?: number
    nodes?: IMobileMenuNodeData[]
    title?: string
    content?: any
}

interface IMobileMenuNodeProps {
    node?: IMobileMenuNodeData
    parent?: IMobileMenuNodeData
}

const MobileMenuNode = ({ node, parent }: IMobileMenuNodeProps) => {
    const params = useParams()
    const { getMenuNodeUrl } = useRouter()

    if (!node) {
        return null
    }

    const isParent = parent?.nodeId === node.nodeId

    const name = node.title || node.content?.name || node.content?.title

    if (isParent || !node.nodes?.length) {
        const url = getMenuNodeUrl(node)

        if (url?.isExternal || url?.isTargetBlank) {
            return (
                <MobileMenuAnchor
                    as="a"
                    asProps={{
                        target: url.isTargetBlank ? '_blank' : null,
                        rel: url.isTargetBlank ? 'noreferrer noopener' : null,
                        href: url.to,
                    }}
                >
                    {name}
                </MobileMenuAnchor>
            )
        }

        return (
            <MobileMenuAnchor
                isBorderBottom
                isArrow
                asProps={{
                    data: node,
                }}
                as={LinkEntity}
                isParent={isParent}
            >
                {isParent ? 'All ' : ''}{name}
            </MobileMenuAnchor>
        )
    }

    return (
        <MobileMenuAnchor
            isArrow
            isBorderBottom
            asProps={{
                to: `${[params?.['*'], node?.nodeId].filter(Boolean).join('/')}`,
            }}
            as={Link}
            isParent={isParent}
        >
            {isParent ? 'All ' : ''}{name}
        </MobileMenuAnchor>
    )
}

export default MobileMenuNode

import { useRouteFallback } from '@pwa-concept/core'
import { useCategory } from '@pwa-concept/modules/category'
import { useCmsRoute } from '@pwa-concept/modules/cms'
import { useFilters } from '@pwa-concept/modules/filter'
import { CategoryFiltersSection } from '@pwa-onilab/ui/components/elements/Category'
import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import ParserBounded from '@pwa-onilab/ui/components/elements/ParserBounded/ParserBounded'
import {
    CategoryNav,
    CategoryNumResultsContext,
} from '@pwa-onilab/ui/components/modules/Category'
import { Error404 } from '@pwa-onilab/ui/components/modules/Error404'
import { Filters, filterSearchStringToObject } from '@pwa-onilab/ui/components/modules/Filters'
import Container from '@pwa-onilab/ui/components/UI/Container'
import CategoryLayout from 'components/overrides/modules/Category/CategoryLayout/CategoryLayout'
import CategoryPageHelmet from 'components/overrides/modules/Category/elements/CategoryPageHelmet/CategoryPageHelmet'
import { memo, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import styles from './CategoryPage.module.scss'

const CategoryPage = () => {
    const cms = useCmsRoute()
    const params = useParams()
    const id = cms?.data?.id || params?.id

    const { data: category, loading: categoryLoading } = useCategory({ id })

    const routeFallback = useRouteFallback()

    const { search } = useLocation()
    const initial = useMemo(() => filterSearchStringToObject(search), [search])

    const { data: filters, loading: filtersLoading } = useFilters({
        categoryId: category?.id?.toString?.(),
    }, { skip: !category })

    if (categoryLoading || filtersLoading) {
        return routeFallback
    }

    if (!category) {
        return (
            <Error404 />
        )
    }

    return (
        <CategoryLayout category={category}>
            <CategoryNumResultsContext>
                <CategoryPageHelmet category={category} filters={filters} />
                {
                    !!category.description && (
                        <Container>
                            <ParserBounded content={category.description} />
                        </Container>
                    )
                }
                <CategoryNav category={category} />
                <CategoryFiltersSection>
                    <Container>
                        <Filters
                            initial={initial}
                            filters={filters}
                            categoryId={category.id || null}
                        />
                    </Container>
                </CategoryFiltersSection>
                {
                    !!category.bottomDescription && (
                        <Container className={styles.bottomDescription}>
                            <ParserBounded content={category.bottomDescription} />
                        </Container>
                    )
                }
                {
                    !!category.bottomFaqs && (
                        <ParserBounded content={category.bottomFaqs} />
                    )
                }
            </CategoryNumResultsContext>
        </CategoryLayout>
    )
}

export default memo(CategoryPage)

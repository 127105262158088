import Parser, { classNames } from '@pwa-onilab/ui/helpers'

import styles from './DescriptionWidgetItem.module.scss'

interface IDescriptionWidgetItemProps {
    title: string
    description: string
    className?: string
}
const DescriptionWidgetItem = (
    {
        title,
        description,
        className,
    }: IDescriptionWidgetItemProps,
) => (
    <div className={classNames(styles.wrapper, className)}>
        {
            title && (
                <div className={styles.title}>
                    {title}
                </div>

            )
        }
        {
            description && (
                <div className={styles.description}>
                    <Parser content={description} />
                </div>
            )
        }
    </div>
)

export default DescriptionWidgetItem

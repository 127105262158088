import { SocialMediaData } from '@pwa-onilab/ui//constants/components/SocialMediaData'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { classNames } from '@pwa-onilab/ui/helpers'
import { memo } from 'react'

import styles from './SocialMediaList.module.scss'

interface ISocialMediaListProps {
    className?: string
}

const SocialMediaList = (
    {
        className,
    }: ISocialMediaListProps,
) => (
    <div className={classNames(styles.links, className)}>
        {
            SocialMediaData.map(({ title, iconName, link }) => (
                <Anchor key={title} to={link} className={styles.link}>
                    {title}
                </Anchor>
            ))
        }
    </div>
)

export default memo(SocialMediaList)

import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { ModalContentTitle, ModalContentYesNoButtons } from '@pwa-onilab/ui/components/elements/ModalContentElements'
import { formSaveBeforeHideModal } from '@pwa-onilab/ui/models'
import { useTranslation } from 'react-i18next'

const ModalConfirmFormClose = () => {
    const { t } = useTranslation()
    const { execOnSaveReject, useIsOpen, execCallback } = formSaveBeforeHideModal
    const isOpen = useIsOpen()

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={execOnSaveReject}
            isDraggable
            isOverAll
            isShowCloseButton
            isHideMobileCloseButton
            isUseDefaultPadding
            isUseBorderRadius
        >
            <ModalContentTitle title={t('addressForm.hide.confirmation')} />
            <ModalContentYesNoButtons onNoButtonClick={execOnSaveReject} onYesButtonClick={execCallback} />
        </Modal>
    )
}

export default ModalConfirmFormClose

import { CoreRouteObject } from '@pwa-concept/core'
import { RouteProvider } from '@pwa-concept/core/router/Route'
import { isValidElement, Suspense } from 'react'
import { createBrowserRouter, RouteObject, useRoutes } from 'react-router-dom'

export const parseRoutes = (routes: CoreRouteObject[]): RouteObject[] => (
    routes
        ?.sort((a, b) => (b?.path?.includes?.('*') ? (a?.path?.includes?.('*') ? 1 : -1) : 0))
        ?.map((
            {
                fallback: Fallback,
                element: RouteElement,
                children,
                ...route
            },
        ) => {
            const fallback = ((typeof Fallback === 'function' ? <Fallback /> : Fallback) || '')

            return {
                ...route,
                children: route?.index ? null : parseRoutes(children),
                element: (
                    <Suspense fallback={fallback}>
                        <RouteProvider fallback={fallback}>
                            {(isValidElement(RouteElement) ? RouteElement : <RouteElement />)}
                        </RouteProvider>
                    </Suspense>
                ),
            }
        }) || []
)

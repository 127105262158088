import {
    FooterContent,
    useCheckFooterVisibility,
} from '@pwa-onilab/ui/components/modules/Footer'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { memo } from 'react'

const Footer = ({ className }: IClassName) => {
    const isHideFooter = useCheckFooterVisibility()

    return (
        <FooterContent
            isHideFooter={isHideFooter}
            className={className}
        />
    )
}

export default memo(Footer)

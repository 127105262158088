import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { IAnchor } from '@pwa-onilab/ui/components/UI/Anchor/IAnchor'
import { IImageProps } from '@pwa-onilab/ui/components/UI/Image'
import { ImageLazy } from '@pwa-onilab/ui/components/UI/Image/ImageLazy'
import { useCustomProductUrl } from 'hooks'
import { memo } from 'react'

interface ProductCardImageProps extends Pick<IAnchor, 'onClick'>{
    className?: string
    imageClassName?: string
    url: string
    imageSrc: IImageProps['src']
    imageAlt: IImageProps['alt']
    sku: string
}

const ProductCardImage = (
    {
        className,
        imageClassName,
        url,
        imageSrc,
        imageAlt,
        sku,
        onClick,
    }: ProductCardImageProps,
) => {
    const {
        productLinkState,
        relativeUrl,
    } = useCustomProductUrl(url, sku)

    return (
        <Anchor
            to={relativeUrl}
            state={productLinkState}
            className={className}
            onClick={onClick}
        >
            <ImageLazy
                src={imageSrc}
                className={imageClassName}
                alt={imageAlt}
            />
        </Anchor>
    )
}

export default memo(ProductCardImage)

import CustomToastifyCloseButton from '@pwa-onilab/ui/components/elements/Buttons/CustomToastifyCloseButton'
import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { PropsWithChildren } from 'react'
import { ToastContainer } from 'react-toastify'

import {
    TOASTIFY_CONTAINER_DRAGGABLE_PERCENT_DEFAULT,
    TOASTIFY_CONTAINER_DRAGGABLE_PERCENT_MOBILE,
    TOASTIFY_CONTAINER_MAX_TOAST_ON_SCREEN,
} from './constants'

// https://fkhadra.github.io/react-toastify/introduction
// TODO update mobile layout
const ToastifyDriver = ({ children }: PropsWithChildren<any>) => {
    const isMobile = useMediaBreakpoint().isBreakpointDown('lg')

    return (
        <>
            {children}
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                closeButton={<CustomToastifyCloseButton />}
                icon={false}
                limit={TOASTIFY_CONTAINER_MAX_TOAST_ON_SCREEN}
                draggablePercent={isMobile
                    ? TOASTIFY_CONTAINER_DRAGGABLE_PERCENT_MOBILE
                    : TOASTIFY_CONTAINER_DRAGGABLE_PERCENT_DEFAULT}
            />
        </>
    )
}

export default ToastifyDriver

import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './FormattedDate.module.scss'

export interface DateProps {
    className?: string
    date?: string
}

const FormattedDate = ({ date, className }: Partial<DateProps>) => {
    if (!date) {
        return null
    }

    const formattedDate = new Date(date)
        .toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })

    return (
        <div className={classNames(styles.date, className)}>{formattedDate}</div>
    )
}

export default FormattedDate

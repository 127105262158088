const CURRENCY_CODES_SIGN = {
    AUD: 'AU$',
    EUR: '€',
    CAD: 'CA$',
    DKK: 'DKK',
    JPY: '￥',
    NOK: 'NOK',
    PLN: 'zł',
    SEK: 'SEK',
    CHF: 'CHF',
    GBP: '£',
    USD: '$',
    NZD: 'NZ$',
    SGD: '$',
    KRW: '₩',
}

export type tCurrencyCode = keyof typeof CURRENCY_CODES_SIGN

const converterCurrencyCodeToSign = (currencyCode: tCurrencyCode) => CURRENCY_CODES_SIGN[currencyCode]

export default converterCurrencyCodeToSign

import { classNames } from '@pwa-onilab/ui/helpers'
import { memo } from 'react'

import styles from './WidgetHeaderSubtitle.module.scss'

export interface IWidgetHeaderSubtitleProps {
    subtitle: string
    className: string
}

const WidgetHeaderSubtitle = (
    {
        subtitle,
        className,
    }: Partial<IWidgetHeaderSubtitleProps>,
) => {
    if (!subtitle) return null

    return (
        <p className={classNames(styles.subtitle, className)}>
            {subtitle}
        </p>
    )
}

export default memo(WidgetHeaderSubtitle)

import { useCmsRoute } from '@pwa-concept/modules/cms'
import { Breadcrumbs } from '@pwa-onilab/ui/components/modules/Breadcrumbs'
import { BreadcrumbsPageTypeEnum, useBreadCrumbs } from 'app/modules/breadcrumb'
import { deleteSlashToEndUrl } from 'helpers'

const BlogPostBreadcrumbs = () => {
    const { data: cmsRoute, loading: cmsRouteLoading } = useCmsRoute()
    const urlKey = cmsRoute ? deleteSlashToEndUrl(cmsRoute.url) : null

    const { data, loading } = useBreadCrumbs({
        pageType: BreadcrumbsPageTypeEnum.BLOG_POST,
        urlKey,
    })

    return (
        <Breadcrumbs items={data} loading={loading || cmsRouteLoading} />
    )
}

export default BlogPostBreadcrumbs

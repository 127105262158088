import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { ModalProps } from '@pwa-onilab/ui/components/elements/Modal/Modal'
import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import styles from './CenteredModal.module.scss'

const CenteredModal = (
    {
        children,
        isShowCloseButton = true,
        isHideMobileCloseButton = true,
        isUseDefaultPadding = true,
        isUseBorderRadius = true,
        isDraggable = true,
        isOverAll = true,
        className,
        ...modalProps
    }: PropsWithChildren<ModalProps>) => {
    return (
        <Modal
            isShowCloseButton={isShowCloseButton}
            isHideMobileCloseButton={isHideMobileCloseButton}
            isUseDefaultPadding={isUseDefaultPadding}
            isUseBorderRadius={isUseBorderRadius}
            isDraggable={isDraggable}
            isOverAll={isOverAll}
            className={classNames(styles.modal, className)}
            {...modalProps}
        >
            {children}
        </Modal>
    )
}

export default CenteredModal

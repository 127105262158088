import { Breadcrumbs } from '@pwa-onilab/ui/components/modules/Breadcrumbs'
import { BreadcrumbsPageTypeEnum, useBreadCrumbs } from 'app/modules/breadcrumb'

import styles from './Breadcrumbs.module.scss'

const LocationsBreadcrumbs = ({ urlKey }: { urlKey?: string}) => {
    const { data: crumbs, loading } = useBreadCrumbs({
        pageType: BreadcrumbsPageTypeEnum.LOCATION,
        urlKey,
    })

    return (
        <Breadcrumbs
            items={crumbs}
            className={styles.breadCrumbMargins}
            loading={loading || !urlKey}
        />
    )
}

export default LocationsBreadcrumbs

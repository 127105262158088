import { Link } from '@pwa-onilab/ui/components/UI/Link'
import { addSlashToEndUrl } from 'helpers'
import { IStrainFamilyAllFamiliesColumns } from 'modules/StrainFamily'

import styles from './StrainFamilyAllFamiliesColumn.module.scss'

const StrainFamilyAllFamiliesColumn = ({ items, familyHomepageUrlKey }: IStrainFamilyAllFamiliesColumns) => {
    return (
        <ul className={styles.list}>
            {items.map((item) => {
                const url = addSlashToEndUrl(`/${familyHomepageUrlKey}/${item.urlKey}`)

                return (
                    <li key={item.id}>
                        <Link to={url} className={styles.link}>
                            {item.name}
                        </Link>
                    </li>
                )
            })}
        </ul>
    )
}

export default StrainFamilyAllFamiliesColumn

import { WishlistShareViaEmailButton, WishlistShareViaEmailForm, WishlistShareViaEmailModal } from '@pwa-onilab/ui/components/modules/Wishlist'
import useModalStates from '@pwa-onilab/ui/hooks/modals/useModalStates'
import { useTranslation } from 'react-i18next'

interface WishlistShareViaEmailProps {
    className: string
}

const WishlistShareViaEmail = (
    {
        className,
    }: Partial<WishlistShareViaEmailProps>,
) => {
    const { t } = useTranslation()

    const {
        isModalOpen,
        openModal,
        closeModal,
    } = useModalStates()

    return (
        <>
            <WishlistShareViaEmailButton
                className={className}
                onClick={openModal}
            >
                {t('wishlist.shareViaEmail')}
            </WishlistShareViaEmailButton>
            <WishlistShareViaEmailModal
                isOpen={isModalOpen}
                onCloseModal={closeModal}
            >
                <WishlistShareViaEmailForm />
            </WishlistShareViaEmailModal>
        </>
    )
}

export default WishlistShareViaEmail

import { QueryResolvers } from '@pwa-concept/modules/graphql'

import getBlogAuthor from './getBlogAuthor'
import getBlogAuthorPostsList from './getBlogAuthorPostsList'

const Query: QueryResolvers = {
    getBlogAuthor,
    getBlogAuthorPostsList,
}

export default Query

import { FeatureMutationHookReturnType } from '@pwa-concept/core'
import {
    useVerifyOtpResetMutation,
    VerifyOtpResetMutationVariables,
} from '@pwa-concept/modules/one-time-password/graphql/mutations/VerifyOTPReset'

const useVerifyResetOTP = () => {
    const [requestAuthOTPVerification] = useVerifyOtpResetMutation()

    return async ({ email, code }: VerifyOtpResetMutationVariables): FeatureMutationHookReturnType<typeof useVerifyOtpResetMutation, 'verifyOTPReset'> => {
        const { data, errors } = await requestAuthOTPVerification({
            variables: {
                email,
                code,
            },
            ignoreResults: true,
        })

        return {
            data: data?.verifyOTPReset,
            errors,
        }
    }
}

export default useVerifyResetOTP

import * as Types from '../../../../graphql';

import { gql } from '@apollo/client';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

export type StrainFamilyClientFragmentFragment = { __typename?: 'FamilyStrain', id?: string | null, createdAt?: string | null, publishTime?: string | null, urlKey?: string | null, thumbnail?: string | null, image?: string | null, name?: string | null, shortDescription?: string | null, description?: string | null, author?: { __typename?: 'BlogAuthor', id?: number | null, name?: string | null, urlKey?: string | null, thumbnailImageUrl?: string | null } | null, meta?: { __typename?: 'FamilyStrainMeta', title?: string | null, description?: string | null, keywords?: string | null, robots?: string | null } | null };

export const StrainFamilyClientFragmentFragmentDoc = gql`
    fragment StrainFamilyClientFragment on FamilyStrain {
  id
  author {
    id
    name
    urlKey
    thumbnailImageUrl
  }
  createdAt
  publishTime
  urlKey
  thumbnail
  image
  name
  shortDescription
  description
  meta {
    title
    description
    keywords
    robots
  }
}
    `;
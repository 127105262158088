import { useApolloClient } from '@apollo/client'

const useCustomerDeleteFromCache = () => {
    const { cache } = useApolloClient()

    return () => {
        cache.evict({ id: 'Customer' })
    }
}

export default useCustomerDeleteFromCache

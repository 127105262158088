import { gql } from '@apollo/client'
import api from '@pwa-concept/core/api'
import { MutationResolvers } from '@pwa-concept/modules/graphql'

const cartAssignToCustomer: MutationResolvers['cartAssignToCustomer'] = async (_, { input }) => {
    const { data: { mergeCarts: __context = null } = {}, errors } = await (
        api.graphql(gql`
                mutation(
                    $sourceCartId: String!
                    $destinationCartId: String
                ) {
                    mergeCarts (
                        destination_cart_id: $destinationCartId
                        source_cart_id: $sourceCartId
                    ) {
                        ... Cart
                    }
                }
            `).mutation({
            ...input,
        }, {
            errorPolicy: 'all',
        })
    )

    if (!__context) return null

    return {
        __context,
        __errors: errors,
        __typename: 'Cart',
    }
}

export default cartAssignToCustomer

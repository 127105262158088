import { AnchorWithArrow } from 'elements/AnchorWithArrow'
import { IBlogPost } from 'modules/Blog/interfaces'

import styles from './AuthorPagePostsList.module.scss'

const AuthorPagePostsList = ({ posts }: { posts: IBlogPost[]}) => {
    if (!posts?.length) {
        return null
    }
    return (
        <ul className={styles.list}>
            {posts.map((post) => {
                return (
                    <li key={post.postUrl} className={styles.listItem}>
                        <AnchorWithArrow
                            className={styles.link}
                            to={post.postUrl}
                            isPlaceArrowBeforeChild
                        >
                            {post.title}
                        </AnchorWithArrow>
                    </li>
                )
            })}
        </ul>
    )
}

export default AuthorPagePostsList

import { PropsWithChildren } from 'react'

import styles from './AuthorPageLeftColumn.module.scss'

const AuthorPageLeftColumn = ({ children }: PropsWithChildren<any>) => {
    return (
        <div className={styles.leftColumn}>
            {children}
        </div>
    )
}

export default AuthorPageLeftColumn

// https://github.com/zenorocha/clipboard.js
const createFakeElement = (value) => {
    const isRTL = document.documentElement.getAttribute('dir') === 'rtl'
    const fakeElement = document.createElement('textarea')
    // Prevent zooming on iOS
    fakeElement.style.fontSize = '12pt'
    // Reset box model
    fakeElement.style.border = '0'
    fakeElement.style.padding = '0'
    fakeElement.style.margin = '0'
    // Move element out of screen horizontally
    fakeElement.style.position = 'absolute'
    fakeElement.style[isRTL ? 'right' : 'left'] = '-9999px'
    // Move element to the same position vertically
    const yPosition = window.pageYOffset || document.documentElement.scrollTop
    fakeElement.style.top = `${yPosition}px`

    fakeElement.setAttribute('readonly', '')
    fakeElement.value = value

    return fakeElement
}

interface ICopyText {
    text: string
    callback?: () => void
}

// execCommand is no longer support, but can be useful for old browsers
export const clipboardCopyText = async (
    text: ICopyText['text'] = '',
    callback: ICopyText['callback'] = () => null,
) => {
    if (!!window.isSecureContext && navigator?.clipboard) {
        return await navigator.clipboard
            .writeText(text)
            .then(callback)
            .then(() => true)
            .catch(() => false)
    }

    const fakeTextArea = createFakeElement(text)
    document.body.appendChild(fakeTextArea)

    fakeTextArea.focus()
    fakeTextArea.select()

    try {
        document.execCommand('copy')
        callback()
        return true
    } catch (error) {
        return false
    }
}

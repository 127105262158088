import {
    useStoreConfigCategoryStainsMetaQuery,
} from 'app/modules/store-config/graphql/queries/storeConfigCategoryStainsMeta'

const useStoreConfigCategoryStrainsMeta = ({ skip }: { skip: boolean }) => {
    const { data, loading } = useStoreConfigCategoryStainsMetaQuery({
        skip,
    })

    return {
        data: data?.storeConfigCategoryStainsMeta || undefined,
        loading,
    }
}

export default useStoreConfigCategoryStrainsMeta

import { Resolvers } from '@pwa-concept/modules/graphql'
import { SYMBOL_SLASH } from 'constants/common'

const CustomerOrderProduct: Resolvers['CustomerOrderProduct'] = {
    url: (_, __, { context }) => {
        if (!context) {
            return null
        }
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return context.product_url_prefix + SYMBOL_SLASH + context.product_url_key + SYMBOL_SLASH
    },
}

export default CustomerOrderProduct

import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'

import styles from './OrderSummaryItemPlaceholde.module.scss'

const OrderSummaryItemPlaceholder = () => {
    return (
        <div className={styles.placeholders}>
            <Placeholder className={styles.placeholderSizes} />
            <Placeholder className={styles.placeholderSizes} />
        </div>
    )
}

export default OrderSummaryItemPlaceholder

import { Button } from '@pwa-onilab/ui/components/UI/Button'
import { classNames } from '@pwa-onilab/ui/helpers'
import { Trans } from 'react-i18next'

import styles from './FilterModalFooter.module.scss'

interface FilterModalFooterProps {
    className?: string
    onClose: () => void
    numResults: number
}

const FilterModalFooter = (
    {
        className,
        onClose,
        numResults,
    }: FilterModalFooterProps,
) => (
    <div className={classNames(styles.footer, className)}>
        <Button
            onClick={onClose}
            isBold
            isFullWidth
            isBlack
        >
            <Trans
                i18nKey="filters.showItems"
                values={{ numResults }}
            />
        </Button>
    </div>
)

export default FilterModalFooter

import { State } from '@pwa-concept/modules/graphql'
import { PageAuthor, PageMainImage, PageTitle } from 'elements/BlogStatePages/elements'

const StatePageIntroBlockDesktop = ({ state }: { state: State}) => {
    return (
        <div>
            <PageTitle text={state.pageTitle} />
            <PageAuthor createdAt={state.publishTime} author={state.author} />
            <PageMainImage image={state.image} />
        </div>
    )
}

export default StatePageIntroBlockDesktop

import { StoreConfigBestsellers } from '@pwa-concept/modules/graphql'
import { useStoreConfigBestsellersQuery } from 'app/modules/store-config/graphql/queries/StoreConfigBestsellers'
const DEFAULT_OUTPUT = {}

const useStoreConfigBestsellers = (): { data: StoreConfigBestsellers, loading: boolean} => {
    const { data: { storeConfigBestsellers } = {}, loading } = useStoreConfigBestsellersQuery()

    return {
        data: storeConfigBestsellers || DEFAULT_OUTPUT,
        loading,
    }
}

export default useStoreConfigBestsellers

import { CustomerAddress } from '@pwa-concept/modules/graphql'
import {
    AccountAddressButtonDelete,
    AccountAddressButtonEdit,
    IAccountAddress,
} from '@pwa-onilab/ui/components/modules/AccountAddress'

import styles from './AccountAddressButtons.module.scss'

const AccountAddressButtons = (
    {
        id,
        openDeleteAddressModal,
        openUpdateAddressModal,
    }: Pick<IAccountAddress & CustomerAddress, 'id' | 'openDeleteAddressModal' | 'openUpdateAddressModal'>,
) => (
    <div className={styles.wrapper}>
        <AccountAddressButtonEdit id={id} openUpdateAddressModal={openUpdateAddressModal} className={styles.addressButton} />
        <AccountAddressButtonDelete id={id} openDeleteAddressModal={openDeleteAddressModal} className={styles.addressButton} />
    </div>
)

export default AccountAddressButtons

import { classNames } from '@pwa-onilab/ui/helpers'
import { useTranslation } from 'react-i18next'

import styles from './WishlistTitle.module.scss'

interface IWishlistTitleProps {
    className: string
}

const WishlistTitle = (
    {
        className,
    }: Partial<IWishlistTitleProps>,
) => {
    const { t } = useTranslation()

    return (
        <div className={classNames(styles.title, className)}>
            {t('wishlist.title')}:
        </div>
    )
}

export default WishlistTitle

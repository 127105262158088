import IconCartPayments from '@pwa-onilab/ui/assets/icons/payments/cartPayments'
import IconsPaymentWithText from '@pwa-onilab/ui/assets/icons/payments/paymentsWithText'

import { ReactComponent as ApplePay } from './applepay.svg'
import { ReactComponent as GooglePay } from './googlepay.svg'
import { ReactComponent as MasterCard } from './mastercard.svg'
import { ReactComponent as PayPal } from './paypal.svg'
import { ReactComponent as Visa } from './visa.svg'

const IconsPayment = {
    visa: <Visa />,
    mastercard: <MasterCard />,
    paypal: <PayPal />,
    applepay: <ApplePay />,
    googlepay: <GooglePay />,

    ...IconCartPayments,
    ...IconsPaymentWithText,
}

export default IconsPayment

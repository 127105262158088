import { DefaultHelmet } from '@pwa-onilab/ui/components/elements/DefaultHelmet'
import { useStoreConfigFamiliesHomePageMeta } from 'app/modules/store-config'
import { SchemaHelmetCollectionPage } from 'elements/SchemaHelmet/SchemaHelmetCollectionPage'
import { SchemaHelmetWebPage } from 'elements/SchemaHelmet/SchemaHelmetWebPage'
import { useTranslation } from 'react-i18next'

const StrainFamiliesHelmet = () => {
    const { t } = useTranslation()
    const { data: meta, loading: metaLoading } = useStoreConfigFamiliesHomePageMeta()

    return (
        <>
            <DefaultHelmet
                meta={meta}
                isMetaLoading={metaLoading}
                defaultTitle={t('strains.homepage.meta.title')}
            />
            <SchemaHelmetWebPage meta={meta} />
            <SchemaHelmetCollectionPage meta={meta} />
        </>
    )
}

export default StrainFamiliesHelmet

import api from '@pwa-concept/core/api'
import { MutationResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const sendDeleteAccountMessage: MutationResolvers['sendDeleteAccountMessage'] = async () => {
    const { data: { sendDeleteAccountMassage: __context } = {} } = await (
        api.graphql(
            gql`
                mutation {
                    # mistake on backend
                    sendDeleteAccountMassage {
                        status
                        errorMessage: error_message
                    }
                }
            `,
        ).mutation()
    )

    return __context || null
}

export default sendDeleteAccountMessage

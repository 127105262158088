import * as Types from '../../../../../../src/app/graphql';

import { gql } from '@apollo/client';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

export type CustomerOrderAddressFragment = { __typename?: 'CustomerOrderAddress', firstName?: string | null, middleName?: string | null, lastName?: string | null, telephone?: string | null, city?: string | null, company?: string | null, street?: Array<string | null> | null, countryCode?: number | null, postcode?: string | null, region?: string | null, regionId?: string | null };

export const CustomerOrderAddressFragmentDoc = gql`
    fragment CustomerOrderAddress on CustomerOrderAddress {
  firstName
  middleName
  lastName
  telephone
  city
  company
  street
  countryCode
  postcode
  region
  regionId
}
    `;
import { Resolvers } from '@pwa-concept/modules/graphql'

const Cms: Resolvers['Cms'] = {
    products: (_, __, { context }) => (
        context?.products?.items?.filter?.(Boolean)?.map?.((__context) => ({
            __context,
            __typename: 'Product',
        })) || null
    ),

    metaRobots: (_, __, { context }) => context?.meta_robots || '',
    displayMode: (_, __, { context }) => context?.display_mode || '',
}

export default Cms

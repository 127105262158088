import {
    AccountSubpageEmptyButton,
    IAccountSubpageEmptyButton,
} from '@pwa-onilab/ui/components/modules/AccountSubpages'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

const AccountAddAddressButton = (
    {
        onClick,
        className,
        stickyBarClassName,
    }: Omit<IAccountSubpageEmptyButton, 'title'>,
) => {
    const { t } = useTranslation()

    return (
        <AccountSubpageEmptyButton
            title={t('AccountPage.addresses.addNewWithPlus')}
            onClick={onClick}
            className={className}
            stickyBarClassName={stickyBarClassName}
            isBlackTransparent
        />
    )
}

export default memo(AccountAddAddressButton)

import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './WishlistShareViaEmailModal.module.scss'

interface WishlistShareViaEmailModalProps {
    className?: string
    isOpen: boolean
    onCloseModal: () => void
}

const WishlistShareViaEmailModal = (
    {
        children,
        className,
        isOpen,
        onCloseModal,
    }: PropsWithChildren<WishlistShareViaEmailModalProps>,
) => {
    const { t } = useTranslation()

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={onCloseModal}
            className={classNames(styles.modal, className)}
            wrapperClassName={styles.contentWrapper}
            title={t('wishlist.shareWishlistViaEmail')}
            titleClassName={styles.title}
            isUseBorderRadius
            isShowCloseButton
            isHideMobileCloseButton
            isDraggable
            isOverAll
        >
            {children}
        </Modal>
    )
}

export default WishlistShareViaEmailModal

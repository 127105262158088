import { Resolvers } from '@pwa-concept/modules/graphql'

const CartShippingAddresses: Resolvers['CartShippingAddresses'] = {
    availableShippingMethods: (_, __, { context }) => {
        if (!context?.availableShippingMethods?.length) {
            return null
        }

        return context?.availableShippingMethods.map((__context) => {
            return {
                __context,
                __typename: 'AvailableShippingMethod',
            }
        })
    },
    city: (_, __, { context }) => context?.city || null,
    company: (_, __, { context }) => context?.company || null,
    country: (_, __, { context }) => {
        return {
            __context: context?.country,
            __typename: 'CartAddressCountry',
        }
    },
    firstName: (_, __, { context }) => context.firstname || null,
    lastName: (_, __, { context }) => context?.lastname || null,
    postCode: (_, __, { context }) => context?.postcode || null,
    region: (_, __, { context: __context }) => {
        return {
            __context,
            __typename: 'CartAddressRegion',
        }
    },
    selectedShippingMethod: (_, __, { context }) => {
        if (!context?.selected_shipping_method) {
            return null
        }
        return {
            __context: context.selected_shipping_method,
            __typename: 'CartSelectedShippingMethod',
        }
    },
    street: (_, __, { context }) => context?.street || null,
    telephone: (_, __, { context }) => context?.telephone || null,

}

export default CartShippingAddresses

import { StoreConfigResolvers } from '@pwa-concept/modules/graphql'

const StoreConfig: StoreConfigResolvers = {
    analytic: (_, __, { context }) => {
        if (!context) {
            return null
        }

        return {
            dripAccountID: context.drip_account_id || null,
            gtmID: context.gtm_id || null,
            ga4ID: context.ga4_id || null,
            __typename: 'AnalyticConfig',
        }
    },
    blogAuthorUrlKey: (_, __, { context }) => context?.blog_author_url_key || null,
    blogHomepageUrlKey: (_, __, { context }) => context?.blog_homepage_url_key || null,

    customerService: (_, __, { context }) => context?.cms_customer_service || null,

    cmsPagesUrls: (_, __, { context }) => {
        if (!context) {
            return null
        }

        return {
            privacyPolicy: context.cms_privacy_policy || null,
            termsOfUse: context.cms_terms_of_use || null,
            __typename: 'cmsPagesUrls',
        }
    },

    productPagePayment: (_, __, { context }) => ({
        content: context?.product_page_payment?.content || null,
        title: context?.product_page_payment?.title || null,
        __typename: 'ProductPageBlock',
    }),

    productPageShipping: (_, __, { context }) => ({
        content: context?.product_page_shipping?.content || null,
        title: context?.product_page_shipping?.title || null,
        __typename: 'ProductPageBlock',
    }),

    emailContactUs: (_, __, { context }) => context?.email_contact_us || null,
    telephoneContactUs: (_, __, { context }) => context?.telephone_contact_us || null,

    promoNotification: (_, __, { context: __context }) => {
        if (!__context?.promo_notification) {
            return null
        }

        return {
            __context: __context.promo_notification,
            __typename: 'PromoNotification',
        }
    },

    categoriesAsStrains: (_, __, { context }) => {
        if (!context?.categories_as_strains?.length) {
            return null
        }

        return context.categories_as_strains.map((__context) => ({
            __context,
            __typename: 'CategoryAsStrains',
        }))
    },

    freeShippingMinimumAmount: (_, __, { context: __context }) => __context?.free_shipping_minimum_amount || '',

    familyHomepageUrlKey: (_, __, { context: __context }) => __context?.family_homepage_url_key || '',

    ogArticlePublisher: (_, __, { context: __context }) => __context?.og_article_publisher || '',

    structuredData: (_, __, { context: __context }) => {
        if (!__context?.structured_data) {
            return null
        }

        return {
            __context,
            __typename: 'StoreConfigStructuredData',
        }
    },

    headerGreetingMessage: (_, __, { context: __context }) => {
        return __context?.header_greeting_message || null
    },

    headerQuickAccess: (_, __, { context }) => {
        if (!context) {
            return null
        }

        return {
            url: context.header_quick_access_link_url || null,
            text: context.header_quick_access_link_text || null,
            __typename: 'HeaderQuickAccess',
        }
    },

    socialAndPaymentConfig: (_, __, { context }) => {
        if (!context) {
            return null
        }

        return {
            findUsText: context.find_us_text || null,
            paymentText: context.payment_text || null,
            rightsText: context.rights_text || null,
            socialIcons: context.social_icons || null,
            paymentIcons: context.payment_icons || null,
        }
    },

    registrationGreetingMessage: (_, __, { context }) => {
        return context?.customer_registration_greeting_message || null
    },

    worldPageUrlKey: (_, __, { context }) => context?.world_url_key || null,

}

export default StoreConfig

import { StoreConfigModule } from '@pwa-concept/modules/store-config'

import graphqlFragments from './graphql/fragments'
import graphqlSchemas from './graphql/schemas'
import stores from './graphql/stores'

const AppStoreConfig = StoreConfigModule.extend((module, { concat }) => ({
    graphqlSchemas: concat(module?.graphqlSchemas, graphqlSchemas),
    graphqlResolvers: concat(module?.graphqlResolvers, stores?.resolvers),
    graphqlStoreFragments: concat(module?.graphqlStoreFragments, stores.fragments),
    graphqlFragments: concat(module?.graphqlFragments, graphqlFragments),
}))

export default AppStoreConfig

import { IOrderComponent } from '@pwa-onilab/ui/components/modules/Order'
import Title from '@pwa-onilab/ui/components/UI/Title'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useTranslation } from 'react-i18next'

import styles from './OrderInformationTitle.module.scss'

const OrderInformationTitle = ({ className }: Pick<IOrderComponent, 'className'>) => {
    const { t } = useTranslation()

    return (
        <Title
            type="h3"
            text={t('order.information.title')}
            className={classNames(styles.title, className)}
        />
    )
}

export default OrderInformationTitle

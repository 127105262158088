import { MediaBreakpointDown, MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import AuthContent from '@pwa-onilab/ui/components/modules/Auth/elements/AuthContent'
import AuthModal from '@pwa-onilab/ui/components/modules/Auth/elements/Modal/AuthModal'
import { useAuthOneTimePassword } from '@pwa-onilab/ui/hooks'

const AuthWrapper = () => {
    const {
        handleOpenOneTimePassword,
        checkOneTimePassword,
        resendCode,
        timerValue,
        attemptsLeft,
        isResendCode,
    } = useAuthOneTimePassword()

    return (
        <>
            <MediaBreakpointUp name="lg">
                <AuthModal
                    handleOpenOneTimePassword={handleOpenOneTimePassword}
                    checkOneTimePassword={checkOneTimePassword}
                    resendCode={resendCode}
                    timerValue={timerValue}
                    attemptsLeft={attemptsLeft}
                    isResendCode={isResendCode}
                />
            </MediaBreakpointUp>
            <MediaBreakpointDown name="lg">
                <AuthContent
                    handleOpenOneTimePassword={handleOpenOneTimePassword}
                    checkOneTimePassword={checkOneTimePassword}
                    resendCode={resendCode}
                    timerValue={timerValue}
                    attemptsLeft={attemptsLeft}
                    isResendCode={isResendCode}
                />
            </MediaBreakpointDown>
        </>
    )
}
export default AuthWrapper

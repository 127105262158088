export const CATEGORY_PAGE_SIZE = 20

export const CATEGORY_NAV_LIST_SETTINGS = {
    maxSingleCards: 5,
    sliderPerViewDesktop: 3.25,
    sliderPerViewMobile: 5,
    spaceBetweenMobile: 24,
    spaceBetweenDesktop: 8,

}

export const CATEGORY_ITEMS_THRESHOLD = [0.2, 0.3]

import api from '@pwa-concept/core/api'
import { MutationResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const requestOTPAuth: MutationResolvers['requestOTPAuth'] = async (_, { email }) => {
    const { data: { requestOneTimePasswordAuth: __context } } = await (
        api.graphql(
            gql`
                mutation($email: String!) {
                    requestOneTimePasswordAuth (email: $email){
                        email
                    }
                }
            `,
        ).mutation({ email })
    )

    return {
        __context,
        __typename: 'RequestOTPOutput',
    }
}

export default requestOTPAuth

import { Resolvers } from '@pwa-concept/modules/graphql'

import Country from './Country'
import Mutation from './Mutation'
import Query from './Query'

const resolvers: Resolvers = {
    Query,
    Mutation,
    Country,
}

export default resolvers

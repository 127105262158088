import { QueryResolvers } from '@pwa-concept/modules/graphql'

import getFamilyStrain from './getFamilyStrain'
import getFamilyStrains from './getFamilyStrains'

const Query: QueryResolvers = {
    getFamilyStrain,
    getFamilyStrains,
}

export default Query


    var doc = {"kind":"Document","definitions":[{"kind":"ObjectTypeExtension","name":{"kind":"Name","value":"Query"},"interfaces":[],"directives":[],"fields":[{"kind":"FieldDefinition","name":{"kind":"Name","value":"GetStrainsForSaleQuery"},"arguments":[],"type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Product"}}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"getStrainsForSaleQuery"},"arguments":[{"kind":"InputValueDefinition","name":{"kind":"Name","value":"input"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"GetStrainsForSaleQueryInput"}},"directives":[]}],"type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Product"}}},"directives":[]}]},{"kind":"InputObjectTypeDefinition","name":{"kind":"Name","value":"GetStrainsForSaleQueryInput"},"directives":[],"fields":[{"kind":"InputValueDefinition","name":{"kind":"Name","value":"search"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}},"directives":[]},{"kind":"InputValueDefinition","name":{"kind":"Name","value":"limit"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}},"directives":[]},{"kind":"InputValueDefinition","name":{"kind":"Name","value":"filter"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Any"}},"directives":[]},{"kind":"InputValueDefinition","name":{"kind":"Name","value":"sort"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Any"}},"directives":[]},{"kind":"InputValueDefinition","name":{"kind":"Name","value":"page"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}},"directives":[]}]},{"kind":"ScalarTypeDefinition","name":{"kind":"Name","value":"Any"},"directives":[]}],"loc":{"start":0,"end":345}};
    doc.loc.source = {"body":"extend type Query {\n    # Put you queries...\n    GetStrainsForSaleQuery: [Product]\n    getStrainsForSaleQuery(input: GetStrainsForSaleQueryInput): [Product]\n}\n\ninput GetStrainsForSaleQueryInput {\n    search: String\n    limit: Int\n    filter: Any  #ProductAttributeFilterInput\n    sort: Any #ProductAttributeSortInput\n    page: Int\n}\n\nscalar Any\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    

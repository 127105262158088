export const ACCOUNT_ROUTE = '/account/'
export const ACCOUNT_DETAILS_ROUTE = `${ACCOUNT_ROUTE}details/`
export const ACCOUNT_DELETE_ROUTE = `${ACCOUNT_ROUTE}delete/`
export const CART_ROUTE = '/cart/'
export const CUSTOMER_DELETE_ROUTE = '/account/delete/'
export const HOME_ROUTE = '/'
export const WISHLIST_ROUTE = '/wishlist/'

export const ROUTES_WITH_DISABLED_FOOTER = [
    CART_ROUTE,
    ACCOUNT_ROUTE,
]

export const CHECKOUT_URL = 'checkout/'
export const CHECKOUT_SUCCESS_URL = 'checkout/success/'

export const CHECKOUT_ROUTE = `/${CHECKOUT_URL}`
export const CHECKOUT_SUCCESS_ROUTE = `/${CHECKOUT_SUCCESS_URL}`

export const REGISTRATION_URL = 'registration/'
export const REGISTRATION_ROUTE = `/${REGISTRATION_URL}`

export const CHECKOUT_PAYPAL_EXPRESS_URL = 'checkout/paypal/return/'
export const CHECKOUT_PAYPAL_EXPRESS_ROUTE = `/${CHECKOUT_PAYPAL_EXPRESS_URL}`

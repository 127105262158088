import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { ProtectedRoute } from '@pwa-onilab/ui/components/elements/ProtectedRoute'
import Container from '@pwa-onilab/ui/components/UI/Container'
import AccountDeleteBackButton from 'components/overrides/modules/AccountSubpages/AccountDeletePage/components/AccountDeleteBackButton/AccountDeleteBackButton'
import AccountDeleteForm from 'components/overrides/modules/AccountSubpages/AccountDeletePage/components/AccountDeleteForm/AccountDeleteForm'
import AccountDeletePageHeader from 'components/overrides/modules/AccountSubpages/AccountDeletePage/components/AccountDeletePageHeader/AccountDeletePageHeader'

import styles from './AccountDeletePage.module.scss'

const AccountDeletePage = () => {
    return (
        <ProtectedRoute>
            <Container className={styles.wrapper}>
                <AccountDeletePageHeader />
                <MediaBreakpointUp name="lg">
                    <AccountDeleteBackButton />
                </MediaBreakpointUp>
                <AccountDeleteForm />
            </Container>
        </ProtectedRoute>
    )
}

export default AccountDeletePage

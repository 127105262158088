import api from '@pwa-concept/core/api'
import { $cart } from '@pwa-concept/core/models'
import { MutationResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const setOrderCommentOnCart: MutationResolvers['setOrderCommentOnCart'] = async (_, { comment } = {}) => {
    const { data: { setOrderComment: isCommentSetted } } = await (
        api.graphql(
            gql`
                mutation($cart_id: String!, $comment: String) {
                    setOrderComment(cart_id: $cart_id, comment: $comment)
                }
            `,
        ).mutation({
            cart_id: $cart.id,
            comment,
        })
    )

    return isCommentSetted
}

export default setOrderCommentOnCart

import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import styles from './Table.module.scss'

const Table = ({ children, className }: PropsWithChildren<any>) => {
    return (
        <div className={classNames(styles.table, className)}>
            {children}
        </div>
    )
}

export default Table

import { Skeleton } from '@pwa-onilab/ui/components/elements/Skeleton'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './BeautyBlogItem.module.scss'

interface IBeautyBlogItemSkeletonProps {
    className?: string
}

const BeautyBlogItemSkeleton = ({ className }: IBeautyBlogItemSkeletonProps) => (
    <div className={styles.wrapperSkeleton}>
        <Skeleton className={classNames(className, styles.skeleton)} />
    </div>
)

export { BeautyBlogItemSkeleton }

import { FaqWidgetMenuItem } from '@pwa-onilab/ui/components/widgets/FaqWidget'

interface IFaqWidgetMenuProps {
    items: Array<{
        items: Array<{
            title: string
            description: string
        }>
        title: string
    }>
    activeTitle: string
    setActiveTabHandler: (title: string) => void
}

const FaqWidgetMenu = (
    {
        setActiveTabHandler,
        activeTitle,
        items,
    }: IFaqWidgetMenuProps,
) => {
    if (!items?.length) return null

    return (
        <>
            {
                items.map((data) => (
                    <FaqWidgetMenuItem
                        key={data?.title}
                        data={data}
                        activeTitle={activeTitle}
                        setActiveTabHandler={setActiveTabHandler}
                    />
                ))
            }
        </>
    )
}

export default FaqWidgetMenu

import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'

import styles from './PageMainImagePlaceholder.module.scss'

const PageMainImagePlaceholder = ({ className }: IClassName) => {
    return <Placeholder className={classNames(styles.imagePlaceholder, className)} />
}

export default PageMainImagePlaceholder

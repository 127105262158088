import { StoreConfigNotFoundPageData } from '@pwa-concept/modules/graphql'
import {
    useStoreConfigNotFoundPageDataQuery,
} from 'app/modules/store-config/graphql/queries/StoreConfigNotFoundPageData'

interface IUseStoreConfigNotFoundPageDataOutput {
    data: StoreConfigNotFoundPageData
    loading: boolean
}

const useStoreConfigNotFoundPageData = (): IUseStoreConfigNotFoundPageDataOutput => {
    const { data, loading } = useStoreConfigNotFoundPageDataQuery()
    return {
        data: data?.storeConfigNotFoundPageData || undefined,
        loading,
    }
}

export default useStoreConfigNotFoundPageData

import { useCmsRoute } from '@pwa-concept/modules/cms'
import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { Breadcrumbs } from '@pwa-onilab/ui/components/modules/Breadcrumbs'
import Container from '@pwa-onilab/ui/components/UI/Container'
import Error404Page from '@pwa-onilab/ui/pages/Error404Page'
import { BreadcrumbsPageTypeEnum, useBreadCrumbs } from 'app/modules/breadcrumb'
import { useStoreConfigCategoryStrainsMeta } from 'app/modules/store-config'
import { useStrainsForSale } from 'app/modules/strains-for-sale'
import {
    StrainsForSaleAlphabet,
    StrainsForSaleCategories,
    StrainsForSaleHelmet,
    StrainsForSaleProducts,
    StrainsForSaleCms
} from 'modules/StrainsForSale'
import { StrainsForSalePagePlaceholder } from 'pages/StrainsForSalePage'

import styles from './StrainsForSalePage.module.scss'

const StrainsForSalePage = () => {
    const { data: cmsRoute, loading: cmsRouteLoading } = useCmsRoute()
    const { data: { categoriesAsStrains, defaultTitle } = {} } = useStoreConfig()
    const { data: breadcrumbs, loading: breadCrumbsLoading } = useBreadCrumbs({
        pageType: BreadcrumbsPageTypeEnum.CATEGORIES_AS_STRAINS,
        urlKey: cmsRoute.url,
    })

    const categoryId = cmsRoute?.id

    const { data: strainsForSaleData, loading: strainsForSaleLoading } = useStrainsForSale(categoryId)
    const { data: allCategoriesStrainMetas, loading: metaLoading } = useStoreConfigCategoryStrainsMeta({
        skip: !categoryId,
    })

    if (cmsRouteLoading || strainsForSaleLoading || metaLoading) {
        return (
            <StrainsForSalePagePlaceholder />
        )
    }

    if (!strainsForSaleData) {
        return (
            <Error404Page />
        )
    }

    const currentCategoryMeta = allCategoriesStrainMetas.find((categoryMeta) => {
        return categoryMeta.categoryId === categoryId
    }) || {}

    return (
        <>
            <Container>
                <StrainsForSaleHelmet meta={currentCategoryMeta.meta} defaultTitle={defaultTitle} />
                <Breadcrumbs items={breadcrumbs} loading={breadCrumbsLoading} />
                <StrainsForSaleAlphabet
                    className={styles.alphabet}
                    titleText={currentCategoryMeta.pageTitle}
                />
                <StrainsForSaleProducts products={strainsForSaleData} cms={currentCategoryMeta.pageTitle} />
                <StrainsForSaleCms cms={currentCategoryMeta.cmsBlock} />
            </Container>
            {/*<StrainsForSaleCategories categoriesAsStrains={categoriesAsStrains} />*/}
        </>
    )
}

export default StrainsForSalePage

import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { FreeShippingStatusBarInfo, FreeShippingStatusBarProgress, IFreeShippingStatusBar } from 'modules/Cart'

import styles from './FreeShippingStatusBar.module.scss'

const FreeShippingStatusBar = (
    {
        subtotal,
    }: Pick<IFreeShippingStatusBar, 'subtotal'>,
) => {
    const { data: { freeShippingMinimumAmount } = {} } = useStoreConfig()

    if (!freeShippingMinimumAmount || !subtotal) {
        return null
    }

    return (
        <div className={styles.wrapper}>
            <FreeShippingStatusBarProgress
                subtotal={subtotal}
                freeShippingMinimumAmount={freeShippingMinimumAmount}
            />
            <FreeShippingStatusBarInfo
                subtotal={subtotal}
                freeShippingMinimumAmount={freeShippingMinimumAmount}
            />
        </div>
    )
}

export default FreeShippingStatusBar

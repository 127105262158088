import { classNames } from '@pwa-onilab/ui/helpers'
import { useCustomerPersonalInfo } from '@pwa-onilab/ui/hooks'
import { useTranslation } from 'react-i18next'

import styles from './AccountDeleteFormTitle.module.scss'

const AccountDeleteFormTitle = () => {
    const { email, loading } = useCustomerPersonalInfo()
    const { t } = useTranslation()

    return (
        <h4 className={classNames(styles.title, loading && styles.placeholder)}>
            {!!email && t('AccountPage.delete.form.title', { email })}
        </h4>
    )
}

export default AccountDeleteFormTitle

import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { GraphQLError } from 'graphql/index'
import { gql } from 'graphql-tag'

const sharedWishlist: QueryResolvers['sharedWishlist'] = async (_, input) => {
    const {
        sharingCode,
        currentPage,
        pageSize,
    } = input

    const { data: { onilab_sharing_wishlist = {} } = {}, errors } = (
        await api.graphql(
            gql`
                query(
                    $sharingCode: String!
                    $pageSize: Int = 20
                    $currentPage: Int = 1
                    $isFullProduct: Boolean = false
                ) {
                    onilab_sharing_wishlist(
                        sharingCode: $sharingCode
                    ) {
                        uid
                        items(
                            currentPage: $currentPage
                            pageSize: $pageSize
                        ) {
                            items {
                                added_at
                                description
                                id
                                product {
                                    ... ProductInterface
                                }
                            }

                            page_info {
                                current_page
                                page_size
                                total_pages
                            }
                        }
                        items_count
                        sharing_code
                    }
                }
            `,
        ).query({
            sharingCode,
            currentPage,
            pageSize,
        })
    )

    if (errors) {
        throw new GraphQLError(errors[0].message)
    }

    if (!onilab_sharing_wishlist) {
        return null
    }

    return {
        __context: onilab_sharing_wishlist,
        __typename: 'Wishlist',
    }
}

export default sharedWishlist

import { $auth } from '@pwa-concept/core/models'
import { useCustomer } from '@pwa-concept/modules/customer'
import { useGlobalLoader, useLogOut } from '@pwa-onilab/ui/hooks'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const useAccountPageTryLogOut = () => {
    const { data, loading } = useCustomer()
    const { t } = useTranslation()

    const { openGlobalLoader, closeGlobalLoader } = useGlobalLoader()
    const logOut = useLogOut()
    const isUserLogin = $auth.useIsToken()

    const handleLogout = useCallback(async() => {
        openGlobalLoader()
        await logOut()
        closeGlobalLoader()
    }, [])

    useEffect(() => {
        if (!isUserLogin) {
            void handleLogout()
            return
        }
        const isLoadError = !loading && !data
        if (isLoadError) {
            toast.error(t('global.errors.common'))
            void handleLogout()
        }
    }, [data, loading, isUserLogin])
}

export default useAccountPageTryLogOut

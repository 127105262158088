import { IStrainFamilyAllFamiliesColumns, StrainFamilyAllFamiliesColumn } from 'modules/StrainFamily'

const StrainFamilyAllFamiliesColumnsMobile = (
    {
        items,
        familyHomepageUrlKey,
    }: IStrainFamilyAllFamiliesColumns) => {
    return (
        <StrainFamilyAllFamiliesColumn
            items={items}
            familyHomepageUrlKey={familyHomepageUrlKey}
        />
    )
}

export default StrainFamilyAllFamiliesColumnsMobile

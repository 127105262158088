import CartCouponCode from '@pwa-onilab/ui/components/modules/Cart/elements/CartCouponeCode/CartCouponCode'
import CartGoToCheckoutStickyBar from '@pwa-onilab/ui/components/modules/Cart/elements/CartGoToCheckoutStickyBar'
import { CartItemUndoDeleteMessage } from '@pwa-onilab/ui/components/modules/Cart/elements/CartItemUndoDeleteMessage'
import CartResult from '@pwa-onilab/ui/components/modules/Cart/elements/CartResult'
import { ICartWithItems } from '@pwa-onilab/ui/components/modules/Cart/elements/CartWithItems/ICartWithItems'
import { classNames, getLastDeletedItemPosition } from '@pwa-onilab/ui/helpers'
import { useNavigateToCheckout } from '@pwa-onilab/ui/hooks'
import CartConfirmDeleteItemModal
    from 'components/overrides/modules/Cart/elements/CartConfirmDeleteItemModal/CartConfirmDeleteItemModal'
import CartDefaultProduct
    from 'components/overrides/modules/Cart/elements/CartProducts/CartDefaultProduct/CartDefaultProduct'
import useCartWithItems from 'components/overrides/modules/Cart/hooks/useCartWithItems'
import { useCallback, useState } from 'react'

import styles from './CartWithItems.module.scss'

const CartWithItems = (
    {
        className,
        items,
        setIsCartLoading,
        isLoading,
        lastDeletedItem,
        setLastDeletedItem,
        handleUndoOperation,
    }: ICartWithItems,
) => {
    const { tryNavigateToCheckout, isShowToCheckoutError } = useNavigateToCheckout()
    const [isShowStickyBarButton, setIsShowStickyBarButton] = useState(false)

    const onTotalPriceIntersection = useCallback((isVisible: boolean) => {
        setIsShowStickyBarButton(!isVisible)
    }, [])

    const {
        isDeleteCartItemModalOpen,
        openDeleteCartItemModal,
        closeDeleteCartItemModal,
        deletingItemId,

        handleItemDeleting,
    } = useCartWithItems({ setLastDeletedItem })

    const undoMessageBlock = lastDeletedItem &&
        <CartItemUndoDeleteMessage
            name={lastDeletedItem.name}
            onClick={handleUndoOperation}
        />

    return (
        <>
            <div className={classNames(styles.wrapper, className)}>
                <ul className={classNames(styles.block, styles.productList)}>
                    {!items?.length && undoMessageBlock}
                    {items?.map((cartItem, idx) => {
                        if (!cartItem || !cartItem.id) {
                            return null
                        }
                        const { id } = cartItem

                        const {
                            isItemInTheEnd,
                            isItemInTheMiddle,
                        } = getLastDeletedItemPosition(lastDeletedItem, idx, id, items.length)

                        return (
                            <li key={id}>
                                {isItemInTheMiddle && undoMessageBlock}
                                <CartDefaultProduct
                                    cartItem={cartItem}
                                    index={idx}
                                    className={className}
                                    openDeleteModal={openDeleteCartItemModal}
                                    setIsLoading={setIsCartLoading}
                                    isLoading={isLoading}
                                    isShowGoToCheckoutError={isShowToCheckoutError}
                                />
                                {isItemInTheEnd && undoMessageBlock}
                            </li>
                        )
                    })}
                </ul>

                <CartCouponCode className={styles.block} setIsCartLoading={setIsCartLoading} />

                <CartResult
                    className={styles.block}
                    onTotalsIntersection={onTotalPriceIntersection}
                    tryNavigateToCheckout={tryNavigateToCheckout}
                />
                {
                    isShowStickyBarButton &&
                        <CartGoToCheckoutStickyBar tryNavigateToCheckout={tryNavigateToCheckout} />
                }
            </div>
            <CartConfirmDeleteItemModal
                isOpen={isDeleteCartItemModalOpen}
                onDismiss={closeDeleteCartItemModal}
                setIsCartLoading={setIsCartLoading}
                id={deletingItemId}
                onDeleteItem={handleItemDeleting}
            />
        </>
    )
}

export default CartWithItems

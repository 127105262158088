import { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

export interface PortalProps {
    root?: HTMLElement
}

const Portal = (
    {
        children,
        root,
    }: PropsWithChildren<PortalProps>,
) => (
    createPortal(
        children,
        root || document.body,
    )
)

export default Portal

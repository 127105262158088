import { IErrorHandlerPage } from '@pwa-onilab/ui/components/elements/ErrorBoundary'
import { ErrorPage } from '@pwa-onilab/ui/components/modules/ErrorPage'
import { useTranslation } from 'react-i18next'

const DefaultError = ({ resetError }: IErrorHandlerPage) => {
    const { t } = useTranslation()

    return (
        <ErrorPage
            errorText={t('global.errors.sorry')}
            onGoToHomeClick={resetError}
            onLinksClick={resetError}
        />
    )
}

export default DefaultError

import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { PAYMENT_METHODS_DATA } from '@pwa-onilab/ui/constants'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './PaymentMethods.module.scss'

interface IPaymentMethodsProps {
    className?: string
}

const PaymentMethods = (
    {
        className,
    }: IPaymentMethodsProps,
) => {
    return (
        <div className={classNames(styles.icons, className)}>
            {PAYMENT_METHODS_DATA.map((name) => (
                <div className={styles.iconWrapper} key={name}>
                    <Icon name={name} className={styles.icon} />
                </div>
            ))}
        </div>
    )
}

export default PaymentMethods

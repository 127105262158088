import Flex from '@pwa-onilab/ui/components/elements/Flex/Flex'
import { MediaBreakpointDown, MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { LocalStoresItemSkeleton } from '@pwa-onilab/ui/components/widgets/LocalStoresSection/LocalStoresItem'
import { IWidgetHeaderSkeletonProps, WidgetHeaderSkeleton } from '@pwa-onilab/ui/components/widgets/WidgetHeader'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './LocalStoresSection.module.scss'

const LocalStoresSectionSkeleton = (
    {
        classNameWrapper,
        isSubtitle = false,
        isTo = false,
    }: IWidgetHeaderSkeletonProps,
) => (
    <section className={classNames(styles.wrapper, classNameWrapper)}>
        <Container>
            <WidgetHeaderSkeleton isSubtitle={isSubtitle} isTo={isTo} />
            <Flex
                direction="row" justifyContent="spaceBetween"
                className={classNames(styles.itemsListWrapper)}
            >
                <MediaBreakpointDown name="lg">
                    <LocalStoresItemSkeleton />
                    <LocalStoresItemSkeleton />
                </MediaBreakpointDown>
                <MediaBreakpointUp name="lg">
                    <LocalStoresItemSkeleton />
                </MediaBreakpointUp>
            </Flex>
        </Container>
    </section>
)

export { LocalStoresSectionSkeleton }

import { $auth } from '@pwa-concept/core/models'
import { useCustomerQuery } from '@pwa-concept/modules/customer/graphql/queries/Customer'
import { Customer } from '@pwa-concept/modules/graphql'

interface UseCustomerReturnType {
    data?: Customer
    loading: boolean
}

const useCustomer = (): UseCustomerReturnType => {
    const [authToken] = $auth.useToken()

    const { data, loading } = useCustomerQuery({
        skip: !authToken,
    })

    return {
        data: data?.customer,
        loading,
    }
}

export default useCustomer

// TODO fix this interfaces in schema level
import {
    BillingAddressInput,
    CartAddressInput,
    CartBillingAddress,
    CartShippingAddresses,
    CustomerAddress,
    ShippingAddressesInput,
} from '@pwa-concept/modules/graphql'

// ca = customer address
// shi = shipping addresses input
// CartAi = cart address input

class AdditionalMappers {
    #caToCartAIMapper: (address: CustomerAddress) => object
    setCaToCartAIMapper: (mapper) => void
    getCaToCartAIMapper: () => object

    #cartAiToCaMapper: (address: CartAddress) => object
    setCartAiToCaMapper: (mapper) => void
    getCartAiToCaMapper: () => (address: CartAddress) => object

    constructor() {
        this.setCaToCartAIMapper = (mapper) => {
            this.#caToCartAIMapper = mapper
        }
        this.getCaToCartAIMapper = () => this.#caToCartAIMapper

        this.setCartAiToCaMapper = (mapper) => {
            this.#cartAiToCaMapper = mapper
        }
        this.getCartAiToCaMapper = () => this.#cartAiToCaMapper
    }
}

export const addressConverterAdditionalMappers = new AdditionalMappers()

export const convertCustomerAddressToCartAddressInput = (address: CustomerAddress): CartAddressInput => {
    const additionalMappers = addressConverterAdditionalMappers.getCaToCartAIMapper()

    return {
        city: address.city || null,
        company: address.company || null,
        countryCode: address.countryCode,
        firstName: address.firstName || null,
        lastName: address.lastName || null,
        postCode: address.postcode || null,
        region: address.region?.region || null,
        regionId: address.region?.regionId || null,
        street: address.street || null,
        telephone: address.telephone || null,
        ...additionalMappers, // FIXME check this latter seems like it should be function
    }
}

type CartAddress = CartShippingAddresses | CartBillingAddress

export const convertCartAddressIntoCustomerAddress = (address: CartAddress): CustomerAddress => {
    const additionalMappers = addressConverterAdditionalMappers.getCartAiToCaMapper()

    const result = {
        city: address.city,
        company: address.company,
        countryCode: address.country?.code || null,
        firstName: address.firstName,
        lastName: address.lastName,
        postcode: address.postCode,
        region: {
            region: address.region?.label || null,
            regionId: +address.region?.regionId || null,
            regionCode: address.region?.code || null,
        },
        street: address.street,
        telephone: address.telephone,
        ...additionalMappers?.(address),
    }
    return result
}

export const convertCustomerAddressToBillingAddressInput = (address: CustomerAddress): BillingAddressInput => {
    if (!address) {
        return null
    }

    return {
        address: convertCustomerAddressToCartAddressInput(address),
        customerAddressId: address.id,
    }
}

export const convertCustomerAddressToShippingAddressesInput = (
    address: CustomerAddress,
    customerNotes: string = null,
): ShippingAddressesInput => {
    if (!address) {
        return null
    }

    return {
        address: convertCustomerAddressToCartAddressInput(address),
        customerAddressId: address.id,
        customerNotes,
        // pickupLocationCode,
    }
}

interface IConvertCartAddressToCartToBillingAddressInput {
    address?: CartAddressInput
    id?: number
    sameAsShipping?: boolean
    useForShipping?: boolean

}

export const convertCartAddressToCartToBillingAddressInput = (
    {
        address,
        id,
        sameAsShipping = false,
        useForShipping = false,
    }: IConvertCartAddressToCartToBillingAddressInput): BillingAddressInput => {
    return {
        address,
        customerAddressId: id,
        sameAsShipping,
        useForShipping,
    }
}

import { TableHeader } from '@pwa-onilab/ui/components/UI/TableHeader'
import { TableRow } from '@pwa-onilab/ui/components/UI/TableRow'
import { useTranslation } from 'react-i18next'

const AccountOrderTableHead = () => {
    const { t } = useTranslation()

    return (
        <TableRow>
            <TableHeader name={t('AccountPage.orders.order')} />
            <TableHeader name={t('AccountPage.orders.date')} />
            <TableHeader name={t('AccountPage.orders.status')} />
            <TableHeader name={t('AccountPage.orders.payment')} />
            <TableHeader name={t('AccountPage.orders.total')} />
        </TableRow>
    )
}

export default AccountOrderTableHead

import { classNames } from '@pwa-onilab/ui/helpers'
import { useTranslation } from 'react-i18next'

import styles from './FaqSearchEmpty.module.scss'

interface IFaqSearchEmptyProps {
    className: string
}

const FaqSearchEmpty = (
    {
        className,
    }: Partial<IFaqSearchEmptyProps>,
) => {
    const { t } = useTranslation()

    return (
        <div className={classNames(styles.empty, className)}>
            {t('faqPage.result.empty')}
        </div>
    )
}

export default FaqSearchEmpty

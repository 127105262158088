import { IProtectedInput } from '@pwa-onilab/ui/components/elements/Inputs'
import PasswordInput from '@pwa-onilab/ui/components/elements/Inputs/PasswordInput'
import Field from '@pwa-onilab/ui/components/UI/Field'

const PasswordField = (
    {
        placeholder = '',
        ...inputFields
    }: IProtectedInput) => {
    return (
        <Field label={placeholder}>
            <PasswordInput
                {...inputFields}
                placeholder={placeholder}
            />
        </Field>
    )
}

export default PasswordField

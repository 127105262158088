import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

// TODO for region as InputField add max and min length?
const useRegionFieldRules = () => {
    const { t } = useTranslation()

    return useMemo(() => {
        return {
            required: t('global.errors.required'),
        }
    }, [])
}
export default useRegionFieldRules

import { Resolvers } from '@pwa-concept/modules/graphql'

const State: Resolvers['State'] = {
    author: (_, __, { context }) => {
        if (!context?.author) {
            return null
        }

        return {
            __context: context.author,
            __typename: 'BlogAuthor',
        }
    },
    createdAt: (_, __, { context }) => context?.created_at || null,

    publishTime: (_, __, { context }) => context?.publish_time || null,

    description: (_, __, { context }) => context?.description || null,

    image: (_, __, { context }) => context?.image || null,

    location: (_, __, { context }) => {
        if (!context?.location) {
            return null
        }
        return {
            createdAt: context.location.created_at || null,
            id: context.location.location_id || null,
            name: context.location.name || null,
            pageTitle: context.location.page_title || null,
            updatedAt: context.location.updated_at || null,
            urlKey: context.location.url_key || null,
        }
    },

    name: (_, __, { context }) => context?.name || null,

    pageTitle: (_, __, { context }) => context?.page_title || null,

    shortDescription: (_, __, { context }) => context?.short_description || null,

    id: (_, __, { context }) => {
        return context?.state_id || null
    },

    updatedAt: (_, __, { context }) => context?.updated_at || null,

    urlKey: (_, __, { context }) => {
        return context?.url_key || null
    },
    meta: (_, __, { context }) => {
        return {
            title: context?.meta_title || '',
            keywords: context?.meta_keywords || '',
            description: context?.meta_description || '',
            robots: context?.meta_robots || '',
            __typename: 'StateMeta',
        }
    },
}

export default State

import { IFiltersFunctionsApi } from '@pwa-onilab/ui/components/modules/Filters/context'

const turnOffFilter = (onChangeValue: IFiltersFunctionsApi['onChangeValue'], filterId) => {
    onChangeValue((prev) => {
        const newFilters = { ...(prev?.filters || {}) }

        delete newFilters[filterId]

        return {
            ...(prev || {}),
            filters: newFilters,
        }
    })
}

export default turnOffFilter

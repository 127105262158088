import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import SearchTriggerDesktop
    from 'components/overrides/modules/Search/elements/SearchTrigger/SearchTriggerDesktop/SearchTriggerDesktop'
import SearchTriggerMobile
    from 'components/overrides/modules/Search/elements/SearchTrigger/SearchTriggerMobile/SearchTriggerMobile'

export interface ISearchTriggerCommonProps {
    onClear?: () => void
    searchValue?: string
    onInputChange?: (event) => void
    className?: string
    inputClassName?: string
}

const SearchTrigger = (
    {
        searchValue,
        onInputChange,
        onClear,
        className,
        inputClassName,
    }: ISearchTriggerCommonProps,
) => {
    const isMobile = useMediaBreakpoint().isBreakpointDown('lg')
    if (isMobile) {
        return (
            <SearchTriggerMobile className={className} />
        )
    }

    return (
        <SearchTriggerDesktop
            onClear={onClear}
            searchValue={searchValue}
            onInputChange={onInputChange}
            className={className}
            inputClassName={inputClassName}
        />
    )
}

export default SearchTrigger

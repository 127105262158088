import { gql } from '@apollo/client'
import api from '@pwa-concept/core/api'
import { ProductTypes, QueryResolvers } from '@pwa-concept/modules/graphql'
import { smartMerge } from '@pwa-concept/modules/product/graphql/stores/magento2/resolvers/Query/helpers/productMerge'

const product: QueryResolvers['product'] = async (_, { input }) => {
    const {
        id = null,
        url = null,
        sku = null,
        external = null,
    } = input || {}

    const { data: { products = null } = {} } = (
        await api.graphql(
            gql`
                query($filter: ProductAttributeFilterInput!, $isFullProduct: Boolean = true){
                    products(
                        filter: $filter
                    ) {
                        items {
                            ... ProductInterface
                        }
                    }
                }
            `,
        ).variableIf(external?.length > 0, (prev) => ({
            ...prev,
            filter: {
                ...(prev?.filter || {}),
                ...external.reduce((result, { key, value }) => {
                    result[key] = value

                    return result
                }, {}),
            },
        })).variableIf(!!id, (prev) => ({
            ...prev,
            filter: {
                ...(prev?.filter || {}),
                id: {
                    eq: id,
                },
            },
        })).variableIf(!!sku, (prev) => ({
            ...prev,
            filter: {
                ...(prev?.filter || {}),
                sku: {
                    eq: sku,
                },
            },
        })).variableIf(!!url, (prev) => ({
            ...prev,
            filter: {
                ...(prev?.filter || {}),
                url_key: {
                    eq: url,
                },
            },
        })).query()
    )

    if (!products?.items?.length) return null

    if (products?.items?.length > 1) {
        const defaultValue = { configurable: null, simple: null }

        const { configurable, simple } = products?.items?.reduce((result, item) => {
            if (item?.__typename === ProductTypes.SimpleProduct) {
                result.simple = item
            } else {
                result.configurable = item
            }

            return result
        }, defaultValue) || defaultValue

        return {
            __context: smartMerge(configurable, simple),
            __typename: 'Product',
        }
    }

    if (products.items[0].__typename === ProductTypes.ConfigurableProduct) {
        // case when we open configurable url
        const configurableProduct = products.items[0]
        return {
            __context: configurableProduct,
            __typename: 'Product',
        }
    }

    return {
        __context: products.items[0],
        __typename: 'Product',
    }
}

export default product

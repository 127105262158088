import { State } from '@pwa-concept/modules/graphql'
import { Breadcrumbs } from '@pwa-onilab/ui/components/modules/Breadcrumbs'
import { BreadcrumbsPageTypeEnum, useBreadCrumbs } from 'app/modules/breadcrumb'

const StatePageBreadcrumbs = ({ state }: {state: State}) => {
    const { data: breadcrumbs, loading } = useBreadCrumbs({
        pageType: BreadcrumbsPageTypeEnum.LOCATION_STATE,
        urlKey: state.urlKey,
    })

    return <Breadcrumbs items={breadcrumbs} loading={loading} />
}

export default StatePageBreadcrumbs

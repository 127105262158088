import { BeautyBlogSectionSkeleton } from '@pwa-onilab/ui/components/widgets/BeautyBlogSection'
import { BenefitsSkeleton } from '@pwa-onilab/ui/components/widgets/Benefits/Benefits.skeleton'
import { BrandsSectionSkeleton } from '@pwa-onilab/ui/components/widgets/BrandsSection'
import { CarouselSectionSkeleton } from '@pwa-onilab/ui/components/widgets/CarouselSection'
import { GiftSectionSkeleton } from '@pwa-onilab/ui/components/widgets/GiftSection'
import { LocalStoresSectionSkeleton } from '@pwa-onilab/ui/components/widgets/LocalStoresSection'
import { NewArrivalSkeleton } from '@pwa-onilab/ui/components/widgets/NewArrival'
import { PromoBannersSkeleton } from '@pwa-onilab/ui/components/widgets/PromoBanners'

import css from './HomePage.module.scss'

const HomePageSkeleton = () => (
    <main className={css.rootSkeleton}>
        <PromoBannersSkeleton />
        <BenefitsSkeleton />
        <CarouselSectionSkeleton isTo />
        <NewArrivalSkeleton isTo />
        <CarouselSectionSkeleton isTo />
        <GiftSectionSkeleton />
        <LocalStoresSectionSkeleton />
        <BeautyBlogSectionSkeleton isSubtitle />
        <BrandsSectionSkeleton isTo />
    </main>
)

export { HomePageSkeleton }

import { PropsWithChildren } from 'react'

import styles from './MobileMenuContainer.module.scss'

const MobileMenuContainer = ({ children }: PropsWithChildren<any>) => (
    <div className={styles.container}>
        {children}
    </div>
)

export default MobileMenuContainer

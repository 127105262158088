import CategoriesSlide from 'components/overrides/widgets/Categories/CategoriesSlide/CategoriesSlide'

import styles from './CategoriesSlider.module.scss'

interface CategoriesSliderProps {
    categories: Array<{
        name: string
        image: string
        url_key: string
    }>
}

const CategoriesSlider = (
    {
        categories,
    }: CategoriesSliderProps,
) => (
    <div className={styles.wrapper}>
        {
            categories.slice(0, 6).map(({ image, name, url_key: url }) => (
                <CategoriesSlide
                    key={url}
                    image={image}
                    name={name}
                    url={url}
                    className={styles.card}
                />
            ))
        }
    </div>
)

export default CategoriesSlider

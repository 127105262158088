import Slider, { SliderNavigation, SliderScrollbar } from '@pwa-onilab/ui/components/UI/Slider'
import BeautyBlogItem from 'components/overrides/widgets/BeautyBlogSection/BeautyBlogItem/BeautyBlogItem'
import { useMemo } from 'react'
import { SwiperSlide } from 'swiper/react'

import styles from './BeautyBlogSlider.module.scss'

interface IBeautyBlogSliderProps {
    items: Array<{
        featured_img: string
        publish_time: string
        short_content: string
        title: string
        url_path: string
    }>
}

const BeautyBlogSlider = (
    {
        items,
    }: IBeautyBlogSliderProps,
) => {
    const slides = useMemo(() => (
        items.map(({
            publish_time: publishTime,
            featured_img: image,
            title,
            short_content: shortContent,
            url_path: url,
        }) => (
            <SwiperSlide key={title}>
                <BeautyBlogItem
                    image={image}
                    to={url}
                    title={title}
                    subtitle={shortContent}
                    publishTime={publishTime}
                />
            </SwiperSlide>
        ))
    ), [items])

    return (
        <div className={styles.wrapper}>
            <Slider
                spaceBetween={12}
                breakpoints={{
                    991: {
                        slidesPerView: 4,
                        spaceBetween: 24,
                        slidesPerGroup: 4,
                    },
                    576: {
                        slidesPerView: 3,
                        spaceBetween: 12,
                        slidesPerGroup: 3,
                    },
                    360: {
                        slidesPerView: 2.2,
                        spaceBetween: 12,
                    },
                }}
                className={styles.slider}
            >
                {slides}
                <SliderNavigation
                    variant="default"
                    nextClassName={styles.nextArrow}
                    prevClassName={styles.prevArrow}
                />
                <SliderScrollbar className={styles.scrollBar} />
            </Slider>
        </div>
    )
}

export default BeautyBlogSlider

import { Skeleton } from '@pwa-onilab/ui/components/elements/Skeleton'

import styles from './CmsPageMenu.module.scss'

const CmsPageMenuSkeleton = () => (
    <div className={styles.sidebar}>
        <div className={styles.title}>
            <Skeleton className={styles.titleSkeleton} />
        </div>
        <div className={styles.menu}>
            <Skeleton className={styles.menuSkeleton} />
        </div>
    </div>
)

export { CmsPageMenuSkeleton }

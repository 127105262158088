import { useCheckIsEmailAvailableLazyQuery } from '@pwa-concept/modules/auth/graphql/queries/CheckIsEmailAvailable'

interface IUseCheckIsEmailAvailableResponse {
    isEmailAvailable: boolean
    errorMessage?: string
}

const useCheckIsEmailAvailable = () => {
    const [checkEmailIsAvailable] = useCheckIsEmailAvailableLazyQuery()

    return async (email: string): Promise<IUseCheckIsEmailAvailableResponse> => {
        try {
            const { data: { checkIsEmailAvailable: { isEmailAvailable } } = { } } = await checkEmailIsAvailable({
                variables: { email },
            })

            return {
                isEmailAvailable,
            }
        } catch (error) {
            return {
                isEmailAvailable: false,
                errorMessage: error.message,
            }
        }
    }
}

export default useCheckIsEmailAvailable

import { PRODUCT_LIST_TYPES } from 'components/widgets/ProductList'
import { IProductListContent } from 'components/widgets/ProductList/components/IProductListContent'
import { ProductListColumn, ProductListGrid, ProductListSlider } from 'components/widgets/ProductList/elements'

const ProductListContent = ({ type, products, isLoading }: IProductListContent) => {
    switch (type) {
        case PRODUCT_LIST_TYPES.GRID: {
            return <ProductListGrid products={products} isLoading={isLoading} />
        }
        case PRODUCT_LIST_TYPES.SLIDER: {
            return <ProductListSlider products={products} isLoading={isLoading} />
        }
        default: {
            return <ProductListColumn products={products} isLoading={isLoading} />
        }
    }
}

export default ProductListContent

export const ACCOUNT_NAVIGATION_LIST = [
    {
        url: 'details/',
        i18key: 'AccountPage.navigation.information',
    },
    {
        url: 'addresses/',
        i18key: 'AccountPage.navigation.addresses',
    },
    {
        url: 'orders/',
        i18key: 'AccountPage.navigation.orderHistory',
    },
    {
        url: 'cards/',
        i18key: 'Cards',
    },
]

import Title, { ITitle } from '@pwa-onilab/ui/components/UI/Title'

import styles from './StrainsForSaleAlphabetTitle.module.scss'

const StrainsForSaleAlphabetTitle = (
    {
        text,
    }: Pick<ITitle, 'text'>,
) => {
    if (!text) {
        return null
    }

    return (
        <div className={styles.titleWrapper}>
            <Title
                type="h1"
                text={text}
                className={styles.title}
            />
        </div>
    )
}

export default StrainsForSaleAlphabetTitle

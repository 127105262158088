import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { ModalProps } from '@pwa-onilab/ui/components/elements/Modal/Modal'
import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import styles from './RightModal.module.scss'

const RightPositionModal = (
    {
        children,
        className,
        isDraggable = true,
        isOverAll = true,
        ...modalProps
    }: PropsWithChildren<ModalProps>,
) => {
    return (
        <Modal
            isDraggable={isDraggable}
            isOverAll={isOverAll}
            className={classNames(styles.RightPositionModal, className)}
            {...modalProps}
        >
            {children}
        </Modal>
    )
}

export default RightPositionModal

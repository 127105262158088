import { TIME_DELAY_ON_BREAKPOINT_CHECK } from '@pwa-onilab/ui/constants/breakpoints'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'

const useResizeCallback = (callback: () => void) => {
    const [triggerValue, setTrigger] = useState(false)
    const resizeTimeoutRef = useRef(null)

    useEffect(() => {
        const onResizeTrigger = () => {
            setTrigger(prevState => !prevState)
        }

        const resizeHandler = () => {
            clearTimeout(resizeTimeoutRef.current)
            resizeTimeoutRef.current = setTimeout(onResizeTrigger, TIME_DELAY_ON_BREAKPOINT_CHECK)
        }

        window.addEventListener('resize', resizeHandler)
        return () => window.removeEventListener('resize', resizeHandler)
    }, [])

    useLayoutEffect(() => {
        callback()
    }, [triggerValue, callback])
}

export default useResizeCallback

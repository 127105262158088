import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { ImageLazy } from '@pwa-onilab/ui/components/UI/Image/ImageLazy'
import { classNames } from '@pwa-onilab/ui/helpers'
import { addSlashToEndUrl } from 'helpers'

import styles from './CategoriesSlide.module.scss'

interface CategoriesSlideProps {
    className?: string
    image: string
    name: string
    url: string
}

const CategoriesSlide = (
    {
        className,
        image,
        name,
        url,
    }: CategoriesSlideProps,
) => {
    if (!url) return null

    return (
        <Anchor
            to={addSlashToEndUrl(url)}
            className={classNames(styles.wrapper, className)}
        >
            {
                image && (
                    <div className={styles.imageWrapper}>
                        <ImageLazy
                            src={image}
                            className={styles.image}
                        />
                    </div>
                )
            }
            {
                name && (
                    <div className={styles.title}>
                        {name}
                    </div>
                )
            }
        </Anchor>
    )
}

export default CategoriesSlide

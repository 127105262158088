import { $cart } from '@pwa-concept/core/models'
import { useCartUpdateItem } from '@pwa-concept/modules/cart'
import {
    IUseCartUpdateItem,
} from '@pwa-concept/modules/cart/features/update/hooks/useCartUpdateItem'
import { ga4AddToCart } from '@pwa-onilab/ga4/operations/cart/addToCart'
import { ga4RemoveFromCart } from '@pwa-onilab/ga4/operations/cart/removeFromCart'
import {
    ICartProductProps,
    ICartStateApi,
} from '@pwa-onilab/ui/components/modules/Cart/elements/CartProducts/CartDefaultProduct/CartDefaultProduct'
import { debounce } from 'debounce'
import { useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'

const useCartProductCounter = (
    {
        setIsLoading,
        cartItem,
    }: Pick<ICartProductProps & ICartStateApi, 'setIsLoading' | 'cartItem'>) => {
    const [cartProductMnemoQuantity, setCartProductMnemoQuantity] = useState<number>(cartItem.quantity)

    const prevDebouncedQuantityRef = useRef<number>(cartItem.quantity)
    const mnemoQuantityRef = useRef<number>(cartProductMnemoQuantity)

    const cartItemIdRef = useRef<string>(cartItem.id)
    cartItemIdRef.current = cartItem.id

    const operationQuantityDeltaRef = useRef<number | null>(null)

    const cartUpdateItem = useCartUpdateItem({
        onCompleted() {
            if (!operationQuantityDeltaRef.current) {
                return
            }

            const isIncrease = operationQuantityDeltaRef.current > 0

            const analyticsInput = {
                product: cartItem.product,
                configuredVariant: cartItem.configuredVariant,
                quantity: Math.abs(operationQuantityDeltaRef.current),
            }

            if (isIncrease) {
                ga4AddToCart(analyticsInput)
            } else {
                ga4RemoveFromCart(analyticsInput)
            }
            operationQuantityDeltaRef.current = null
        },
        onError(error) {
            updateMnemoQuantity(cartItem.quantity)
            toast.error(error.message)
            operationQuantityDeltaRef.current = null
        },
    })

    const {
        updateMnemoQuantity,
        incrementItemQuantity,
        decrementItemQuantity,
        handleQuantityInputChange,
    } = useMemo(() => {
        const updateCartItemQuantity = async (cartItemUid, quantity) => {
            const cartItems: [IUseCartUpdateItem] = [{
                cartItemUid,
                quantity,
            }]

            setIsLoading(true)
            await cartUpdateItem({
                cartId: $cart.id,
                cartItems,
            })
            setIsLoading(false)
        }

        const updateMnemoQuantity = (qty: number) => {
            setCartProductMnemoQuantity(qty)
            mnemoQuantityRef.current = qty
        }

        const debouncedUpdateCartItemQuantity = debounce((newQuantity) => {
            if (newQuantity === prevDebouncedQuantityRef.current) {
                return
            }

            operationQuantityDeltaRef.current = newQuantity - prevDebouncedQuantityRef.current

            prevDebouncedQuantityRef.current = newQuantity
            void updateCartItemQuantity(cartItemIdRef.current, newQuantity)
        }, 500)

        const incrementItemQuantity = () => {
            const newQuantity = mnemoQuantityRef.current + 1
            updateMnemoQuantity(newQuantity)
            debouncedUpdateCartItemQuantity(newQuantity)
        }

        const decrementItemQuantity = () => {
            if (mnemoQuantityRef.current < 1) {
                return
            }

            const newQuantity = mnemoQuantityRef.current - 1
            updateMnemoQuantity(newQuantity)

            debouncedUpdateCartItemQuantity(newQuantity)
        }

        const handleQuantityInputChange = (event) => {
            const value = event.target.value.trim()
            if (value === '') {
                updateMnemoQuantity(null)
                return
            }

            const numberValue = +value

            if (numberValue < 1 || !Number.isInteger(numberValue)) {
                event.target.value = mnemoQuantityRef.current
                return
            }
            updateMnemoQuantity(numberValue)
            debouncedUpdateCartItemQuantity(numberValue)
        }

        return {
            updateMnemoQuantity,
            debouncedUpdateCartItemQuantity,
            incrementItemQuantity,
            decrementItemQuantity,
            handleQuantityInputChange,
        }
    }, [])

    return {
        incrementItemQuantity,
        decrementItemQuantity,
        handleQuantityInputChange,
        cartProductMnemoQuantity,
    }
}

export default useCartProductCounter

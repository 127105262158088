import { Placeholder } from '@pwa-onilab/ui/components/elements/Placeholder'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'

import styles from './StateCard.module.scss'

const StateCardPlaceholder = ({ className }: IClassName) => {
    return <Placeholder className={classNames(styles.wrapper, className)} />
}

export default StateCardPlaceholder

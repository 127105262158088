import { InputRangeMax, InputRangeMin, RangeThumb } from '@pwa-onilab/ui/components/elements/Inputs'
import { IInputRange } from '@pwa-onilab/ui/components/elements/Inputs/InputRange/IInputRange'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useCallback, useRef } from 'react'
import { getTrackBackground, Range } from 'react-range'

import css from './InputRange.module.scss'

const TRACK_COLORS = [
    css.rangeBackgroundColor,
    css.rangeFillColor,
    css.rangeBackgroundColor,
]

const InputRange = (
    {
        min,
        max,
        step = 1,
        values,
        onChange,
        onFinalChange,
    }: IInputRange,
) => {
    const currentValues: number[] = values?.map((value) => {
        return Math.min(+max, Math.max(+min, +value))
    }) || [+min, +max]

    if (currentValues.length !== 2 || min === max) {
        return null
    }

    const debounceRef = useRef(null)

    const debouncedOnChange = useCallback((values: number[]) => {
        if (debounceRef.current) {
            clearTimeout(debounceRef.current)
        }

        onChange(values)
    }, [onChange])

    const debouncedFinalChange = useCallback((values: number[]) => {
        if (debounceRef.current) {
            clearTimeout(debounceRef.current)
        }

        debounceRef.current = setTimeout(() => {
            onFinalChange(values)
        }, 1000)
    }, [onFinalChange])

    return (
        <div
            className={classNames(css.container)}
        >
            <div className={css.range}>
                <Range
                    step={step}
                    min={+min}
                    max={+max}
                    onFinalChange={debouncedFinalChange}
                    values={currentValues || []}
                    onChange={debouncedOnChange}
                    renderTrack={({
                        props: {
                            onMouseDown: handleOnMouseDown,
                            onTouchStart: handleOnTouchStart,
                            ...args
                        }, children,
                    }) => (
                        <div
                            onMouseDown={handleOnMouseDown}
                            onTouchStart={handleOnTouchStart}
                            style={args.style}
                            className={css.rangeTrack}
                        >
                            <div
                                ref={args.ref}
                                style={{
                                    height: '3px',
                                    width: '100%',
                                    borderRadius: '4px',
                                    background: getTrackBackground({
                                        values: currentValues,
                                        colors: TRACK_COLORS,
                                        min,
                                        max,
                                    }),
                                    alignSelf: 'center',
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ props, value, isDragged }) => {
                        return (
                            <RangeThumb key={props.key} props={props} value={value} isDragged={isDragged} />
                        )
                    }}
                />
            </div>
            <div className={css.fields}>
                <InputRangeMin
                    currentMin={currentValues[0]}
                    currentMax={currentValues[1]}
                    min={min}
                    max={max}
                    onChange={debouncedOnChange}
                    onFinalChange={onFinalChange}
                />
                <InputRangeMax
                    currentMin={currentValues[0]}
                    currentMax={currentValues[1]}
                    min={min}
                    max={max}
                    onChange={debouncedOnChange}
                    onFinalChange={onFinalChange}
                />
            </div>
        </div>
    )
}

export default InputRange

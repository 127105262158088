import { useMutation } from '@pwa-concept/core/graphql/hooks'
import { ResetPasswordMutation } from '@pwa-onilab/ui/schemas'

// TODO move it into pwa-concept
const useResetPassword = () => {
    const [resetPassword] = useMutation(ResetPasswordMutation, {
        ignoreResults: true,
    })

    return async (
        email: string,
        newPassword: string,
        token: string,
    ) => {
        const input = {
            variables: {
                email,
                newPassword,
                resetPasswordToken: token,
            },
        }
        const { data, errors } = await resetPassword(input)

        return {
            isPasswordReset: data?.resetPassword,
            errors,
        }
    }
}

export default useResetPassword

import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Container from '@pwa-onilab/ui/components/UI/Container'
import {
    BrandsSectionCenterCard,
    BrandsSectionLateralCards,
    useSlideDistribution,
} from '@pwa-onilab/ui/components/widgets/BrandsSection'
import { WidgetHeader } from '@pwa-onilab/ui/components/widgets/WidgetHeader'
import { memo } from 'react'

import styles from './BrandsSection.module.scss'

interface IBrandsSectionProps {
    title: string
    button_link: string
    items: Array<{
        code: string
        image: string
    }>
}

const BrandsSection = (
    {
        button_link: buttonLink,
        items,
        title,
    }: Partial<IBrandsSectionProps>,
) => {
    if (!items?.length) return null

    const {
        mainSlide,
        firstHalf,
        secondHalf,
    } = useSlideDistribution(items)

    return (
        <section className={styles.wrapper}>
            <Container>
                <WidgetHeader title={title} to={buttonLink} />
                <Container isRevert className={styles.content}>
                    <BrandsSectionLateralCards data={firstHalf} />
                    <BrandsSectionCenterCard data={mainSlide} />
                    <MediaBreakpointUp name="sm">
                        <BrandsSectionLateralCards data={secondHalf} />
                    </MediaBreakpointUp>
                </Container>
            </Container>
        </section>
    )
}

export default memo(BrandsSection)

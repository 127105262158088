import { Image } from '@pwa-onilab/ui/components/UI/Image'
import { classNames } from '@pwa-onilab/ui/helpers/classNames'
import { LOGO_CONFIGURATION } from 'constants/LogoConfiguration'
import { Link } from 'react-router-dom'

import styles from './Logo.module.scss'

interface LogoProps {
    className?: string
}

const Logo = ({ className }: LogoProps) => {
    return (
        <Link to="/" className={classNames(styles.logo, className)}>
            <picture>
                <source
                    srcSet={LOGO_CONFIGURATION.desktopSrc}
                    media="(min-width: 993px)"
                />
                <Image
                    src={LOGO_CONFIGURATION.mobileSrc}
                    width="100%"
                    alt="Logo"
                />
            </picture>
        </Link>
    )
}

export default Logo

import { FeatureMutationHookReturnType } from '@pwa-concept/core'
import {
    RequestOtpAuthMutationVariables,
    useRequestOtpAuthMutation,
} from '@pwa-concept/modules/one-time-password/graphql/mutations/RequestOTPAuth'

const useRequestAuthOTP = () => {
    const [requestAuthOTP] = useRequestOtpAuthMutation()

    return async (email: RequestOtpAuthMutationVariables['email']): FeatureMutationHookReturnType<typeof useRequestOtpAuthMutation, 'requestOTPAuth'> => {
        const { data, errors } = await requestAuthOTP({
            variables: {
                email,
            },
            ignoreResults: true,
        })

        return {
            data: data?.requestOTPAuth,
            errors,
        }
    }
}

export default useRequestAuthOTP

import { CustomerAddress } from '@pwa-concept/modules/graphql'
import { useAddressLine } from '@pwa-onilab/ui/hooks'
import { getAbstractAddress } from '@pwa-onilab/ui/mappers'

import styles from './AccountAddressAddressLine.module.scss'

const AccountAddressAddressLine = ({ address }: {address: CustomerAddress}) => {
    const abstractAddress = getAbstractAddress(address)

    const addressLine = useAddressLine({ address: abstractAddress })

    return (
        <div className={styles.wrapper}>
            {addressLine}
        </div>
    )
}

export default AccountAddressAddressLine

import { Wishlist } from '@pwa-concept/modules/graphql'

const isProductOnTheWishlist = (
    wishlist: Wishlist,
    setCurrentWishlistProductId: React.Dispatch<React.SetStateAction<string>>,
    sku,
): boolean => {
    if (!wishlist?.items?.items || !setCurrentWishlistProductId || !sku) return false

    return wishlist.items.items.some(({ id, product }) => {
        if (product?.sku === sku) {
            setCurrentWishlistProductId(id)
            return true
        }

        return false
    })
}

export default isProductOnTheWishlist

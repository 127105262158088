import {
    StrainFamilyBreadcrumbs,
    StrainFamilyContentPlaceholder,
    StrainFamilyPageContainer,
} from 'modules/StrainFamily'

const StrainFamilyPagePlaceholder = () => {
    return (
        <StrainFamilyPageContainer>
            <StrainFamilyBreadcrumbs isLoading />
            <StrainFamilyContentPlaceholder />
        </StrainFamilyPageContainer>
    )
}

export default StrainFamilyPagePlaceholder

import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { ModalHeaderBackArrow } from '@pwa-onilab/ui/components/elements/Modal/elements/ModalHeaderBackArrow'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { PropsWithChildren, ReactElement } from 'react'

import styles from './FaqSearchModal.module.scss'

interface IFaqSearchModalProps {
    className: string
    isOpen: boolean
    onClose: () => void
    header?: ReactElement | string
}

const FaqSearchModal = (
    {
        className,
        isOpen,
        onClose,
        children,
        header,
    }: Partial<PropsWithChildren<IFaqSearchModalProps>>,
) => (
    <Modal
        isOpen={isOpen}
        onDismiss={onClose}
        variant="footerMenu"
        header={(
            <ModalHeaderBackArrow
                onDismiss={onClose}
                className={styles.header}
            >
                {header}
            </ModalHeaderBackArrow>
        )}
        className={className}
    >
        <Container>
            {children}
        </Container>
    </Modal>
)

export default FaqSearchModal

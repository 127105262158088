import { Catalog } from '@pwa-concept/modules/graphql'
import { VisibleOnScreen } from '@pwa-onilab/ui/components/elements/VisibleOnScreen'
import { CATEGORY_ITEMS_THRESHOLD, CATEGORY_PAGE_SIZE } from '@pwa-onilab/ui/components/modules/Category'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import CategoryProductCard from 'components/overrides/modules/Product/CategoryProductCard/CategoryProductCard'
import { PropsWithChildren } from 'react'

import styles from './CategoryItemsList.module.scss'

interface ICategoryItemsList extends IClassName {
    items: Catalog['items']
    onScreen: (page: number) => () => void
    intermediatePagesCount: number
    currentServerPage: number
}

const CategoryItemsList = (
    {
        items,
        className,
        onScreen,
        intermediatePagesCount,
        currentServerPage,
        children,
    }: PropsWithChildren<ICategoryItemsList>) => {
    let idxOfVisibleOnScreen: number = currentServerPage || 0

    return (
        <div className={classNames(styles.list, className)}>
            {
                items.map((product, idx) => {
                    const isCardWithOnScreenEffect = (
                        (idx === 0 || idx % CATEGORY_PAGE_SIZE === 0) &&
                        idxOfVisibleOnScreen <= intermediatePagesCount + currentServerPage
                    )
                    if (isCardWithOnScreenEffect) {
                        idxOfVisibleOnScreen += 1

                        return (
                            <VisibleOnScreen
                                key={product.id}
                                onScreen={onScreen(idxOfVisibleOnScreen - 1)}
                                threshold={CATEGORY_ITEMS_THRESHOLD}
                                className={styles.visibleOnScreen}
                            >
                                <CategoryProductCard
                                    key={product.id}
                                    product={product}
                                    className={styles.card}
                                />
                            </VisibleOnScreen>
                        )
                    }

                    return (
                        <CategoryProductCard
                            key={product.id}
                            product={product}
                            className={styles.card}
                        />
                    )
                })
            }
            {children}
        </div>
    )
}

export default CategoryItemsList

import { classNames } from '@pwa-onilab/ui/helpers'
import { useTranslation } from 'react-i18next'

import styles from './CartRecommendedTitle.module.scss'

interface ICartRecommendedTitleProps {
    className?: string
}

const CartRecommendedTitle = (
    {
        className,
    }: ICartRecommendedTitleProps,
) => {
    const { t } = useTranslation()

    return (
        <h2 className={classNames(styles.title, className)}>
            {t('cart.recommendedItems.title')}
        </h2>
    )
}

export default CartRecommendedTitle

import { IProtectedInput, ProtectedInput } from '@pwa-onilab/ui/components/elements/Inputs'
import Field from '@pwa-onilab/ui/components/UI/Field'

const ProtectedField = (
    {
        setValue,
        className,
        field,
        register,
        rules,
        errors,
        placeholder = '',
        ...inputProps
    }: IProtectedInput) => {
    return (
        <Field label={placeholder}>
            <ProtectedInput
                {...inputProps}
                className={className}
                placeholder={placeholder}
                field={field}
                register={register}
                setValue={setValue}
                errors={errors}
                rules={rules}
            />
        </Field>
    )
}

export default ProtectedField

import { SortsQueryOutput } from '@pwa-concept/modules/graphql'
import { useSortsQuery } from '@pwa-concept/modules/sort/graphql/queries/Sorts'

interface IUseSortsOptions {
    skip?: boolean
}

type tUseSorts = (props?: IUseSortsOptions) => {
    data: SortsQueryOutput
    loading: boolean
}

const useSorts: tUseSorts = ({ skip = false } = {}) => {
    const { data, loading } = useSortsQuery({
        skip,
    })

    return {
        data: data?.sorts || undefined,
        loading,
    }
}

export default useSorts

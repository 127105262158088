
    var doc = {"kind":"Document","definitions":[{"kind":"ObjectTypeExtension","name":{"kind":"Name","value":"Mutation"},"interfaces":[],"directives":[],"fields":[{"kind":"FieldDefinition","name":{"kind":"Name","value":"cartSetPaymentMethod"},"arguments":[{"kind":"InputValueDefinition","name":{"kind":"Name","value":"input"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"SetPaymentMethodOnCartInput"}},"directives":[]}],"type":{"kind":"NamedType","name":{"kind":"Name","value":"Cart"}},"directives":[]}]},{"kind":"InputObjectTypeDefinition","name":{"kind":"Name","value":"SetPaymentMethodOnCartInput"},"directives":[],"fields":[{"kind":"InputValueDefinition","name":{"kind":"Name","value":"paymentMethod"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"PaymentMethodInput"}},"directives":[]}]},{"kind":"InputObjectTypeDefinition","name":{"kind":"Name","value":"PaymentMethodInput"},"directives":[],"fields":[{"kind":"InputValueDefinition","name":{"kind":"Name","value":"code"},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},"directives":[]},{"kind":"InputValueDefinition","name":{"kind":"Name","value":"extraInfo"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Any"}},"directives":[]}]}],"loc":{"start":0,"end":236}};
    doc.loc.source = {"body":"extend type Mutation {\n    cartSetPaymentMethod(input: SetPaymentMethodOnCartInput): Cart\n}\n\ninput SetPaymentMethodOnCartInput {\n    paymentMethod: PaymentMethodInput\n}\n\ninput PaymentMethodInput {\n    code: String!\n    extraInfo: Any\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    

import { classNames } from '@pwa-onilab/ui/helpers'
import { useCustomerPersonalInfo } from '@pwa-onilab/ui/hooks'
import { useTranslation } from 'react-i18next'

import styles from './AccountMessageBlock.module.scss'

const AccountMessageBlock = () => {
    const { email, fullName, loading } = useCustomerPersonalInfo()
    const { t } = useTranslation()

    return (
        <div className={classNames(styles.wrapper, loading && styles.loader)}>
            <p className={styles.greeting}>
                {t('AccountPage.header.greeting', { customerFullName: fullName })}
            </p>
            <p className={styles.email}>
                {email}
            </p>
        </div>
    )
}

export default AccountMessageBlock

import { BackButton } from '@pwa-onilab/ui/components/elements/Buttons'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { ACCOUNT_DETAILS_ROUTE } from '@pwa-onilab/ui/constants/navigation'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import styles from './AccountDeletePageHeader.module.scss'

const AccountDeletePageHeader = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const goToAccount = useCallback(() => {
        navigate(ACCOUNT_DETAILS_ROUTE)
    }, [])

    return (
        <Container className={styles.wrapper} isRevert>
            <BackButton
                onClick={goToAccount}
                className={styles.backButton}
            />
            <div className={styles.title}>{t('AccountPage.informationSubpage.deleteAccount')}</div>
        </Container>
    )
}

export default AccountDeletePageHeader

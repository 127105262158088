import { Filter } from '@pwa-concept/modules/graphql'
import { FILTER_NODE_ACTUAL_PRICE_PER_SEED } from 'modules/Filters'

export const getPriceTag = (priceFilter: Filter, values) => {
    if (!values) {
        return null
    }

    const sortedValues = [...values].sort((a, b) => +a < +b ? -1 : 1)
    const newMinValue = +sortedValues[0]
    const newMaxValue = +sortedValues[1]

    if (priceFilter) {
        const selectedMinValue = +priceFilter.items?.[0].value
        const selectedMaxValue = +priceFilter.items?.[1].value

        const isNewFiltersNotEqualToPrev = newMinValue !== selectedMinValue || newMaxValue !== selectedMaxValue
        if (!isNewFiltersNotEqualToPrev) {
            return null
        }
    }

    const isValuesUnique = newMinValue !== newMaxValue

    if (isValuesUnique) {
        return {
            key: FILTER_NODE_ACTUAL_PRICE_PER_SEED,
            value: sortedValues.join('-'),
            name: sortedValues.join('-'),
        }
    }
    return null
}

import { IFiltersFunctionsApi } from '@pwa-onilab/ui/components/modules/Filters/context'
import Toggler from '@pwa-onilab/ui/components/UI/Toggler'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { useBooleanFilter } from 'modules/Filters'

import styles from './FilterOptionsBoolean.module.scss'

interface IFilterOptionsBooleanProps extends IClassName, Pick<IFiltersFunctionsApi, 'onChangeValue'>{
    title: string
    filterId: string
    isActive: boolean
}

const FilterOptionsBoolean = (
    {
        className,
        onChangeValue,
        isActive,
        title,
        filterId,
    }: IFilterOptionsBooleanProps,
) => {
    if (!title) {
        return null
    }

    const onToggleHandler = useBooleanFilter(isActive, filterId, onChangeValue)

    return (
        <div className={classNames(styles.wrapper, className)}>
            <div className={styles.title}>
                {title}
            </div>
            <Toggler
                handleToggle={onToggleHandler}
                isActive={isActive}
            />
        </div>
    )
}

export default FilterOptionsBoolean

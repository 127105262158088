import { Button } from '@pwa-onilab/ui/components/UI/Button'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './AccountFormSubmit.module.scss'

interface IAccountFormSubmit {
    isLoading: boolean
    isDisabled: boolean
}

const AccountFormSubmit = ({ isDisabled, isLoading }: IAccountFormSubmit) => {
    const { t } = useTranslation()
    return (
        <Button
            isFullWidth
            isBold
            isPrimary
            type="submit"
            className={styles.submitButton}
            disabled={isDisabled}
        >
            {t(isLoading
                ? 'global.buttons.savingChanges'
                : 'global.buttons.saveChanges',
            )}
        </Button>
    )
}

export default memo(AccountFormSubmit)

import { SelectedTextActive, SelectedTextInactive } from '@pwa-onilab/ui/components/modules/SelectedText'
import Parser, { classNames } from '@pwa-onilab/ui/helpers'

import styles from './FaqAccordionItemContent.module.scss'

interface IFaqAccordionItemContentProps {
    className: string
    content: string
    benchmark: string
    isActive: boolean
}

const FaqAccordionItemContent = (
    {
        className,
        content,
        benchmark,
        isActive,
    }: Partial<IFaqAccordionItemContentProps>,
) => {
    if (!content) return null

    if (!isActive) {
        return (
            <SelectedTextInactive
                text={content}
                benchmark={benchmark}
                wrapperClassName={styles.content}
                activeClassName={styles.active}
            />
        )
    }

    if (isActive && benchmark) {
        return (
            <SelectedTextActive
                text={content}
                benchmark={benchmark}
                wrapperClassName={styles.content}
                activeClassName={styles.active}
            />
        )
    }

    return (
        <div className={classNames(styles.content, className)}>
            <Parser content={content} />
        </div>
    )
}

export default FaqAccordionItemContent

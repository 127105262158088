import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import styles from './OrderBlockRecord.module.scss'

interface IAccountOrderBlockRecord {
    title: string
    loading?: boolean
    wrapperClassName?: string
    loaderClassName?: string
}

const OrderBlockRecord = (
    {
        title,
        loading,
        wrapperClassName,
        loaderClassName,
        children,
    }: PropsWithChildren<IAccountOrderBlockRecord>) => {
    return (
        <div className={classNames(styles.wrapper, wrapperClassName)}>
            <span className={styles.title}>{title}</span>
            {
                loading
                    ? <div className={classNames(styles.placeholder, loaderClassName)} />
                    : children
            }
        </div>
    )
}

export default OrderBlockRecord

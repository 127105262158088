import { useApolloClient } from '@apollo/client'
import { ROOT_QUERY_FIELD_KEY } from '@pwa-concept/modules/constants'
import { CUSTOMER_ADDRESSES_FIELD_KEY } from '@pwa-concept/modules/customer-addresses/constants'

// seems to be crutch?
const useCustomerAddressDeleteCache = () => {
    const { cache } = useApolloClient()

    return () => {
        const cachedQueries = cache.data.data[ROOT_QUERY_FIELD_KEY]
        if (!cachedQueries) {
            return
        }
        const customerAddresses = cachedQueries[CUSTOMER_ADDRESSES_FIELD_KEY]
        if (!customerAddresses) {
            return
        }

        customerAddresses.forEach(({ __ref: addressId }) => {
            cache.evict({ id: addressId })
        })
        cache.evict({ id: ROOT_QUERY_FIELD_KEY, fieldName: CUSTOMER_ADDRESSES_FIELD_KEY })
    }
}

export default useCustomerAddressDeleteCache

import { useCustomerUpdate } from '@pwa-concept/modules/customer'
import { CustomerUpdateInput } from '@pwa-concept/modules/graphql'
import Title from '@pwa-onilab/ui/components/UI/Title'
import Toggler from '@pwa-onilab/ui/components/UI/Toggler'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import styles from './NewslettersToggler.module.scss'

interface INewslettersToggler {
    isCustomerSubscribed: boolean
}

const NewslettersToggler = ({ isCustomerSubscribed }: INewslettersToggler) => {
    const [isActive, setIsActive] = useState<boolean>(isCustomerSubscribed)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const { t } = useTranslation()
    const updateCustomer = useCustomerUpdate()

    useEffect(() => {
        setIsActive(isCustomerSubscribed)
    }, [isCustomerSubscribed])

    const toggleNewsletters = useCallback(() => {
        setIsLoading(true)
        setIsActive(isCustomerWasSubscribed => {
            const isSubscribed = !isCustomerWasSubscribed
            const input: CustomerUpdateInput = {
                isSubscribed,
            }

            const successSubscribeHandler = () => {
                if (isSubscribed) {
                    toast.success(t('AccountPage.newsletters.subscribe.success'))
                } else {
                    toast.success(t('AccountPage.newsletters.unsubscribe.success'))
                }
            }

            // TODO optimistic response instead of isActive state?
            updateCustomer(input)
                .then(successSubscribeHandler)
                .catch((error) => toast.error(error.message))
                .finally(() => setIsLoading(false))

            return isSubscribed
        })
    }, [])

    return (
        <div className={styles.wrapper}>
            <Title type="h2" text={t('AccountPage.newsletters.subscribe')} className={styles.title} />
            <Toggler
                handleToggle={toggleNewsletters}
                isActive={isActive}
                id="newslettersToggler"
                disabled={isLoading}
            />
        </div>
    )
}

export default NewslettersToggler

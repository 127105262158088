import { useCart } from '@pwa-concept/modules/cart'

const useCartEmail = () => {
    const { data, loading } = useCart()
    return {
        data: data?.email || null,
        loading,
    }
}

export default useCartEmail

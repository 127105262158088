import { gql } from '@apollo/client'
import api from '@pwa-concept/core/api'
import { MutationResolvers } from '@pwa-concept/modules/graphql'

const cartReorderItems: MutationResolvers['cartReorderItems'] = async (_, { orderNumber }) => {
    const { data: { reorderItems: __context } = {}, errors: __errors } = await (
        api.graphql(gql`
            mutation(
                $orderNumber: String!
            ) {
                reorderItems(orderNumber: $orderNumber) {
                    cart {
                        ... Cart
                    }
                    userInputErrors {
                        code
                        message
                        path
                    }
                }
            }
        `).mutation({
            orderNumber,
        })
    )

    return {
        __context,
        __errors,
        __typename: 'CartReorderItemsOutput',
    }
}

export default cartReorderItems

import { QueryResolvers } from '@pwa-concept/modules/graphql'

import cart from './cart'
import cartRecommendedProducts from './CartRecommendProducts'

const Query: QueryResolvers = {
    cart,
    cartRecommendedProducts,
}

export default Query

import * as Types from '../../../../graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@pwa-concept/core/graphql/hooks';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

const defaultOptions = {} as const;
export type StoreConfigSearchPageMetaQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type StoreConfigSearchPageMetaQuery = { __typename?: 'Query', storeConfigSearchPageMeta?: { __typename?: 'StoreConfigDefaultPageMeta', title?: string | null, keywords?: string | null, description?: string | null, robots?: string | null } | null };


export const StoreConfigSearchPageMetaDocument = gql`
    query StoreConfigSearchPageMeta {
  storeConfigSearchPageMeta @client {
    title
    keywords
    description
    robots
  }
}
    `;

/**
 * __useStoreConfigSearchPageMetaQuery__
 *
 * To run a query within a React component, call `useStoreConfigSearchPageMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreConfigSearchPageMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreConfigSearchPageMetaQuery({
 *   variables: {
 *   },
 * });
 */
export function useStoreConfigSearchPageMetaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StoreConfigSearchPageMetaQuery, StoreConfigSearchPageMetaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<StoreConfigSearchPageMetaQuery, StoreConfigSearchPageMetaQueryVariables>(StoreConfigSearchPageMetaDocument, options);
      }
export function useStoreConfigSearchPageMetaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StoreConfigSearchPageMetaQuery, StoreConfigSearchPageMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<StoreConfigSearchPageMetaQuery, StoreConfigSearchPageMetaQueryVariables>(StoreConfigSearchPageMetaDocument, options);
        }
export type StoreConfigSearchPageMetaQueryHookResult = ReturnType<typeof useStoreConfigSearchPageMetaQuery>;
export type StoreConfigSearchPageMetaLazyQueryHookResult = ReturnType<typeof useStoreConfigSearchPageMetaLazyQuery>;
export type StoreConfigSearchPageMetaQueryResult = Apollo.QueryResult<StoreConfigSearchPageMetaQuery, StoreConfigSearchPageMetaQueryVariables>;
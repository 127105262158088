import Container from '@pwa-onilab/ui/components/UI/Container'

import styles from './Placeholder.module.scss'

const ProductPlaceholder = () => (
    <div className={styles.productPlaceholder}>
        <Container>
            <div className={styles.breadcrumbs} />
            <div className={styles.row}>
                <div className={styles.productGallery} />
                <div className={styles.info} />
            </div>
            <div className={styles.productOverview} />
        </Container>
    </div>
)

export default ProductPlaceholder

import { FeatureMutationHookReturnType } from '@pwa-concept/core'
import {
    useVerifyOtpAuthMutation,
    VerifyOtpAuthMutationVariables,
} from '@pwa-concept/modules/one-time-password/graphql/mutations/VerifyOTPAuth'

const useVerifyAuthOTP = () => {
    const [requestAuthOTPVerification] = useVerifyOtpAuthMutation()

    return async ({ email, code }: VerifyOtpAuthMutationVariables): FeatureMutationHookReturnType<typeof useVerifyOtpAuthMutation, 'verifyOTPAuth'> => {
        const { data, errors } = await requestAuthOTPVerification({
            variables: {
                email,
                code,
            },
            ignoreResults: true,
        })

        return {
            data: data?.verifyOTPAuth,
            errors,
        }
    }
}

export default useVerifyAuthOTP

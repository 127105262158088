import { Resolvers } from '@pwa-concept/modules/graphql'

const Category: Resolvers['Category'] = {
    promoBanner: (_, __, { context }) => context?.promo_banner || null,
    bottomDescription: (_, __, { context }) => context?.bottom_description || null,
    bottomFaqs: (_, __, { context }) => context?.bottom_faqs || null,
    metaRobots: (_, __, { context }) => context?.meta_robots || null,
}

export default Category

import { Breadcrumb as IBreadcrumb } from '@pwa-concept/modules/graphql'
import { Breadcrumb } from '@pwa-onilab/ui/components/modules/Breadcrumbs'
import { BreadCrumbsPlaceholder } from '@pwa-onilab/ui/components/modules/Breadcrumbs/BreadCrumbsPlaceholder'
import { HOME_ROUTE } from '@pwa-onilab/ui/constants/navigation'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { SchemaHelmetBreadcrumbList } from 'elements/SchemaHelmet/SchemaHelmetBreadcrumbList'
import { useTranslation } from 'react-i18next'

import styles from './Breadcrumbs.module.scss'

interface IBreadcrumbs extends IClassName {
    loading?: boolean
    items: IBreadcrumb[]
}

const Breadcrumbs = ({ items, className, loading }: IBreadcrumbs) => {
    const { t } = useTranslation()

    if (loading) {
        return <BreadCrumbsPlaceholder className={className} />
    }

    if (!items?.length) return null

    return (
        <div className={classNames(styles.wrapper, className)}>
            <SchemaHelmetBreadcrumbList breadcrumbs={items} />
            <Breadcrumb link={HOME_ROUTE} name={t('global.home')} />
            {items.map(({ name, url }, idx) => {
                const isLastCrumb = items.length === idx + 1
                return <Breadcrumb key={name} link={isLastCrumb ? null : url?.to} name={name} />
            })}
        </div>
    )
}

export default Breadcrumbs

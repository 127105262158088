import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import Icon from '@pwa-onilab/ui/components/UI/Icon'

import styles from './QuantityInput.module.scss'

interface QuantityInputProps {
    quantity: number
    isLoading?: boolean

    onIncrease: (event) => void
    onDecrease: (event) => void
    onInputChange: (event) => void
}

const QuantityInput = (
    {
        quantity,

        onDecrease,
        onIncrease,
        onInputChange,
        isLoading,
    }: QuantityInputProps,
) => {
    return (
        <div className={styles.wrapper}>
            <ButtonInstance
                onClick={onDecrease}
                disabled={isLoading}
                className={styles.button}
            >
                <Icon
                    name="minus"
                    className={styles.icon}
                />
            </ButtonInstance>
            <input
                type="number"
                className={styles.input}
                onChange={onInputChange}
                value={quantity}
                pattern="\[-d]*"
                readOnly={isLoading}
                min={0}
            />
            <ButtonInstance
                onClick={onIncrease}
                disabled={isLoading}
                className={styles.button}
            >
                <Icon
                    name="plus"
                    className={styles.icon}
                />
            </ButtonInstance>
        </div>
    )
}

export default QuantityInput

import { MediaBreakpointDown, MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { CategoryNavCardSkeleton } from '@pwa-onilab/ui/components/modules/Category'

import css from './CategoryNavList.module.scss'

const CategoryNavListSkeleton = () => (
    <div className={css.list}>
        <MediaBreakpointDown name="lg">
            <CategoryNavCardSkeleton skeletonCount={3} />
        </MediaBreakpointDown>
        <MediaBreakpointUp name="lg">
            <CategoryNavCardSkeleton skeletonCount={5} />
        </MediaBreakpointUp>
    </div>
)

export { CategoryNavListSkeleton }

import { ImageLazy } from '@pwa-onilab/ui/components/UI/Image/ImageLazy'
import Parser from '@pwa-onilab/ui/helpers'

import styles from './BannerTextAboveImage.module.scss'

interface IBannerTextAboveImageProps {
    imageUrl: string
    description?: string
}

const BannerTextAboveImage = (
    {
        imageUrl,
        description,
    }: IBannerTextAboveImageProps,
) => (
    <div className={styles.wrapper}>
        <ImageLazy
            src={imageUrl}
            className={styles.image}
            loaderClassName={styles.imageLoader}
        />
        <div className={styles.content}>
            <div className={styles.headerContent}>
                {
                    description && (
                        <div className={styles.description}>
                            <Parser content={description} />
                        </div>
                    )
                }
            </div>
        </div>
    </div>
)

export default BannerTextAboveImage

import { Resolvers } from '@pwa-concept/modules/graphql'

const AvailableShippingMethod: Resolvers['AvailableShippingMethod'] = {
    methodCode: (_, __, { context }) => context?.method_code || null,
    methodTitle: (_, __, { context }) => context?.method_title || null,
    available: (_, __, { context }) => context?.available || null,
    carrierCode: (_, __, { context }) => context?.carrier_code || null,
    carrierTitle: (_, __, { context }) => context?.carrier_title || null,
    price: (_, __, { context }) => {
        return {
            money: context?.amount?.value ?? null,
            __typename: 'CartMoneyOnly',
        }
    },
    priceExcludingTax: (_, __, { context }) => {
        return {
            money: context?.price_excl_tax?.value ?? null,
            __typename: 'CartMoneyOnly',
        }
    },
    priceIncludingTax: (_, __, { context }) => {
        return {
            money: context?.price_incl_tax?.value ?? null,
            __typename: 'CartMoneyOnly',
        }
    },
}

export default AvailableShippingMethod

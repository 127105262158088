import { useCustomer } from '@pwa-concept/modules/customer'

const useCustomerPersonalInfo = () => {
    const { data: customer, loading } = useCustomer()

    return {
        fullName: customer?.fullName || null,
        email: customer?.email || null,
        loading,
    }
}

export default useCustomerPersonalInfo

import { Resolvers } from '@pwa-concept/modules/graphql'

import Cms from './Cms'
import CmsRoute from './CmsRoute'
import Query from './Query'

const resolvers: Resolvers = {
    Query,
    Cms,
    CmsRoute,
}

export default resolvers

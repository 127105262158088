import { APOLLO_ERROR_TYPES } from '@pwa-concept/modules/constants'
import { useCustomerChangePassword, useCustomerUpdate, useCustomerUpdateEmail } from '@pwa-concept/modules/customer'
import { CustomerUpdateInput } from '@pwa-concept/modules/graphql'
import { getApolloErrorType } from '@pwa-concept/modules/helpers'
import { FORM_PASSWORD_FIELD } from '@pwa-onilab/ui/constants/formFields'
import { getCustomerNames } from '@pwa-onilab/ui/helpers'
import { useCustomerPersonalInfo, useModalStates, useSaveBeforeHideModal } from '@pwa-onilab/ui/hooks'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const usePersonalInfoForm = () => {
    const { t } = useTranslation()

    const [isShowPasswordConfirmation, setIsShowPasswordConfirmation] = useState<boolean>(false)
    const [isShowPasswordFields, setIsShowPasswordFields] = useState<boolean>(false)

    const [isCustomerUpdating, setIsCustomerUpdating] = useState<boolean>(false)

    const { fullName: initialFullName, email: initialEmail } = useCustomerPersonalInfo()

    const updateCustomerFullName = useCustomerUpdate()
    const updateCustomerEmail = useCustomerUpdateEmail()
    const updateCustomerPassword = useCustomerChangePassword()

    const methods = useForm({
        defaultValues: {
            fullName: initialFullName,
            email: initialEmail,
            password: '',
            newPassword: '',
        },
    })
    const {
        formState: { isDirty },
        getValues,
        reset,
        setError,
        setFocus,
        trigger,
    } = methods

    const openPasswordFields = useCallback(() => {
        setIsShowPasswordFields(true)
        setIsShowPasswordConfirmation(false)
    }, [])

    const handleChangeEmailClickOnOtp = useCallback(async () => {
        setIsShowPasswordFields(false)
        setIsShowPasswordConfirmation(false)

        // set focus should be used before reset
        setFocus('email')
        reset({ password: '', newPassword: '' })
    }, [])

    const formChangeHandler = useCallback(() => {
        const { email, password, newPassword } = getValues()

        const emailWasChanged = email !== initialEmail

        const isPasswordSet = !!password?.length && !!newPassword.length

        if (isShowPasswordFields) {
            setIsShowPasswordConfirmation(false)
        } else {
            setIsShowPasswordConfirmation(emailWasChanged && !isPasswordSet)
        }
    }, [isShowPasswordFields, initialEmail])

    const submitHandler = useCallback(async ({ fullName, email, password, newPassword }) => {
        const shouldUpdateFullName = fullName !== initialFullName
        const shouldUpdateEmail = email !== initialEmail
        const shouldUpdatePassword = !!password.length && !!newPassword.length

        const isPasswordOrEmailWasChanged = shouldUpdatePassword || shouldUpdateEmail

        if (shouldUpdateFullName) {
            const { firstname, lastname, middlename } = getCustomerNames(fullName)
            const input: CustomerUpdateInput = {
                firstName: firstname,
                lastName: lastname,
                middleName: middlename,
            }
            setIsCustomerUpdating(true)
            updateCustomerFullName(input)
                .then(() => toast.success(t('AccountPage.informationSubpage.changedName')))
                .catch((error) => toast.error(error.message))
                .finally(() => !isPasswordOrEmailWasChanged && setIsCustomerUpdating(false))
        }

        if (isPasswordOrEmailWasChanged) {
            reset({
                password: '',
                newPassword: '',
            })
        }

        const handlePasswordChangeError = (error) => {
            toast.error(error.message)
            const errorType = getApolloErrorType(error.message)
            if (errorType === APOLLO_ERROR_TYPES.INCORRECT_PASSWORD) {
                setError(FORM_PASSWORD_FIELD, {
                    message: error.message,
                    type: APOLLO_ERROR_TYPES.INCORRECT_PASSWORD,
                })
            }
        }

        const emailChangeSuccessPromiseHandler = () => {
            if (shouldUpdateEmail) {
                toast.success(t('AccountPage.informationSubpage.changedEmail'))
                setIsShowPasswordConfirmation(false)
            }
        }

        const finallyHandler = () => {
            if (isPasswordOrEmailWasChanged) {
                setIsCustomerUpdating(false)
            }
            reset({
                email,
                fullName,
                password: '',
                newPassword: '',
            })
        }

        Promise.resolve()
            .then(async () => shouldUpdateEmail && await updateCustomerEmail(email, password))
            .then(emailChangeSuccessPromiseHandler)
            .catch((error) => toast.error(error.message))

            .then(async () => shouldUpdatePassword && await updateCustomerPassword(newPassword, password))
            .then(() => shouldUpdatePassword && toast.success(t('AccountPage.informationSubpage.changedPassword')))
            .catch((error) => handlePasswordChangeError(error))

            .finally(finallyHandler)
    }, [initialFullName, initialEmail])

    const {
        isModalOpen: isOtpModalOpen,
        openModal: openOtpModal,
        closeModal: closeOtpModal,
    } = useModalStates()

    useSaveBeforeHideModal({
        isFormWasChanged: isDirty && !isCustomerUpdating,
        callback: useCallback(async () => {
            const isFormValid = await trigger()
            if (isFormValid) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                try {
                    await submitHandler(getValues())
                    return true
                } catch (e) {
                    return false
                }
            }
            return false
        }, []),
    })

    return {
        submitHandler,
        formChangeHandler,
        isShowPasswordConfirmation,
        openPasswordFields,
        handleChangeEmailClickOnOtp,

        isOtpModalOpen,
        openOtpModal,
        closeOtpModal,

        isCustomerUpdating,
        isShowPasswordFields,

        methods,

    }
}

export default usePersonalInfoForm

import { BackButton } from '@pwa-onilab/ui/components/elements/Buttons'
import { useTranslation } from 'react-i18next'

import styles from './CartMobileDefaultNoItemHeader.module.scss'

const CartMobileDefaultNoItemHeader = () => {
    const { t } = useTranslation()
    return (
        <div className={styles.wrapper}>
            <BackButton />
            <h2 className={styles.title}>{t('cart.title')}</h2>
        </div>
    )
}

export default CartMobileDefaultNoItemHeader

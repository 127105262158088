import { tAnyAddress, useAddressLine } from '@pwa-onilab/ui/hooks'
import { getAbstractAddress } from '@pwa-onilab/ui/mappers/addressMappers/getAbstractAddress'

const useAddressDescription = (address: tAnyAddress) => {
    if (!address) {
        return null
    }

    const abstractAddress = getAbstractAddress(address)

    const {
        firstName,
        middlename,
        lastName,
    } = abstractAddress
    const addressLine = useAddressLine({ address: abstractAddress })

    return {
        nameLine: [firstName, middlename, lastName].filter((word) => !!word).join(' '),
        addressLine,
    }
}

export default useAddressDescription

import { FilterGroupContent } from '@pwa-onilab/ui/components/modules/Filters'
import { classNames } from '@pwa-onilab/ui/helpers'
import FilterGroupButton from 'components/overrides/modules/Filters/elements/FilterGroup/FilterGroupButton/FilterGroupButton'
import { PropsWithChildren } from 'react'

interface FilterGroupProps {
    className?: string
    title: string
    description: string
    hideHeaderTitle: boolean
}

const FilterGroup = (
    {
        children,
        className,
        title,
        description,
        hideHeaderTitle,
    }: PropsWithChildren<FilterGroupProps>,
) => {
    return (
        <div className={classNames(className)}>
            {
                !hideHeaderTitle && (
                    <FilterGroupButton
                        title={title}
                        description={description}
                    />
                )
            }
            <FilterGroupContent>
                {children}
            </FilterGroupContent>
        </div>
    )
}

export default FilterGroup

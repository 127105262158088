import { IPageAuthor } from 'elements/BlogStatePages/elements/PageAuthor/PageAuthor'
import { SchemaHelmet } from 'elements/SchemaHelmet'
import {
    SCHEMA_HELMET_CONTEXT_VALUE,
    SCHEMA_HELMET_DEFAULT_SITE_URL,
} from 'elements/SchemaHelmet/SchemaHelmetConstants'
import { getSchemaHelmetAuthorId } from 'elements/SchemaHelmet/SchemaHelmetHelpers'

const getSchemaPersonConfig = (author: IPageAuthor['author']) => {
    const name = author.name || undefined
    const imgSrc = author.image || undefined
    return {
        '@context': SCHEMA_HELMET_CONTEXT_VALUE,
        '@type': 'Person',
        '@id': getSchemaHelmetAuthorId(author),
        name,
        image: {
            '@type': 'ImageObject',
            '@id': `${SCHEMA_HELMET_DEFAULT_SITE_URL}#/schema/person/image/`,
            url: imgSrc,
            contentUrl: imgSrc,
            caption: name,
        },
    }
}

const SchemaHelmetPerson = ({ author }: {author: IPageAuthor['author']}) => {
    if (!author) {
        return null
    }
    const schemaConfig = getSchemaPersonConfig(author)
    return <SchemaHelmet jsonConfig={schemaConfig} />
}

export default SchemaHelmetPerson

export const DEFAULT_LOCALE_CODE = 'en'

export const HEADER_ID = 'header'
export const HEADER_TOP_ID = 'headerTop'
export const HEADER_BOTTOM_ID = 'headerBottom'
export const SEARCH_TRIGGER_WRAPPER_ID = 'searchTriggerWrapper'

export const PASSWORD_MIN_LENGTH = 8// TODO remove latter, it will be inside pwa-concept store-config module

export const CUSTOM_ATTRIBUTE_CODE_SKIN_TYPE = 'skin_type'
export const CUSTOM_ATTRIBUTE_CODE_ACTIVE_INGREDIENTS = 'active_ingredients'
export const CUSTOM_ATTRIBUTE_TYPE_CATALOG_PRODUCT = 'catalog_product'

export const MILLISECONDS_IN_SECOND = 1000
export const MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * 60
export const MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * 60
export const MILLISECONDS_IN_DAY = MILLISECONDS_IN_HOUR * 24

export const OTP_DEFAULT_ATTEMPT_VALUE = 5

export enum PASSWORD_INPUT_STATES {
    SHOWED_PASSWORD='text',
    HIDDEN_PASSWORD='password',
}

export const SELECT_ADDRESS_ITEM_FORM_ID = 'selectAddressItemForm'
export const SELECT_SHIPPING_METHOD_FORM_ID = 'selectShippingMethod'
export const SELECT_PAYMENT_METHOD_FORM_ID = 'selectPaymentMethod'
export const DEFAULT_MAGENTO_IMAGE_ALT = 'default magento image'

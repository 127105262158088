import { OneTimePasswordModal } from '@pwa-onilab/ui/components/modules/OneTimePassword'
import useModalStates from '@pwa-onilab/ui/hooks/modals/useModalStates'
import { PropsWithChildren, useCallback, useEffect } from 'react'

interface OneTimePasswordModalProps {
    isOtpChoosen?: boolean
    handleModalClosing?: () => void
}

const AuthOneTimePasswordModal = ({ children, isOtpChoosen, handleModalClosing }: PropsWithChildren<OneTimePasswordModalProps>) => {
    const { isModalOpen, openModal, closeModal } = useModalStates()

    useEffect(() => {
        if (isOtpChoosen) {
            openModal()
        } else {
            closeModal()
        }
    }, [isOtpChoosen])

    const handleCloseModal = useCallback(() => {
        handleModalClosing()
        closeModal()
    }, [])

    return (
        <OneTimePasswordModal isOpen={isModalOpen} onDismiss={handleCloseModal}>
            {children}
        </OneTimePasswordModal>
    )
}

export default AuthOneTimePasswordModal

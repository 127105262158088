import { classNames } from '@pwa-onilab/ui/helpers'
import { useTranslation } from 'react-i18next'

import styles from './StrainsForSaleProductsEmpty.module.scss'

interface IStrainsForSaleProductsEmptyProps {
    className?: string
}

const StrainsForSaleProductsEmpty = (
    {
        className,
    }: IStrainsForSaleProductsEmptyProps,
) => {
    const { t } = useTranslation()

    return (
        <div className={classNames(styles.emptyResult, className)}>
            {t('categoryPage.emptyResult')}
        </div>
    )
}

export default StrainsForSaleProductsEmpty

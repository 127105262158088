import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { IAnchor } from '@pwa-onilab/ui/components/UI/Anchor/IAnchor'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { useCustomProductUrl } from 'hooks'

import styles from './CartRecommendedProductName.module.scss'

interface ICartRecommendedProductNameProps extends IClassName, Pick<IAnchor, 'onClick'>{
    name: string
    url: string
    sku: string
}

const CartRecommendedProductName = (
    {
        className,
        name,
        url,
        sku,
        onClick,
    }: ICartRecommendedProductNameProps,
) => {
    if (!name || !url) return null

    const {
        productLinkState,
        relativeUrl,
    } = useCustomProductUrl(url, sku)

    return (
        <Anchor
            to={relativeUrl}
            state={productLinkState}
            onClick={onClick}
        >
            <p className={classNames(styles.name, className)}>{name}</p>
        </Anchor>
    )
}

export default CartRecommendedProductName

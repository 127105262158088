import { ImageLazy } from '@pwa-onilab/ui/components/UI/Image/ImageLazy'
import { DOMNode } from 'html-react-parser'

import styles from './ParserImage.module.scss'

const ParserImage = ({ domNode }: {domNode: DOMNode}) => {
    return (
        <ImageLazy
            {...domNode.attribs}
            src={domNode.attribs.src}
            alt={domNode.attribs.alt}
            className={domNode.attribs.class}
            loaderClassName={styles.loader}
        />
    )
}

export default ParserImage

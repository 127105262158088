import IFilterFunctions, {
    IFiltersFunctionsApi,
} from '@pwa-onilab/ui/components/modules/Filters/context/FilterFunctionsContext/IFilterFunctions'
import { useContextPattern } from '@pwa-onilab/ui/hooks'
import { createContext, PropsWithChildren } from 'react'

const FilterApiContext = createContext<IFiltersFunctionsApi>({})
const useFilterApiContext = (): IFiltersFunctionsApi => useContextPattern(FilterApiContext)

const FilterFunctionsContext = ({ children, filtersApi }: PropsWithChildren<IFilterFunctions>) => {
    return (
        <FilterApiContext.Provider value={filtersApi}>
            {children}
        </FilterApiContext.Provider>
    )
}

export { FilterFunctionsContext, useFilterApiContext }

import SelectField from '@pwa-onilab/ui/components/elements/Fields/SelectField'
import {
    ADDRESS_FORM_FIELDS,
    ADDRESS_FORM_INPUT_SET_VALUE_OPTIONS,
    useCountryFieldOptions,
} from '@pwa-onilab/ui/components/modules/AddressForm'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// TODO fix register types
// TODO think about rules
const AddressFormCountryField = () => {
    const { t } = useTranslation()
    const { register, setValue, formState: { errors } } = useFormContext()

    const listOfOptions = useCountryFieldOptions()

    return (
        <SelectField
            field={ADDRESS_FORM_FIELDS.COUNTRY_CODE}
            placeholder={t('addressForm.country.text')}
            options={listOfOptions}
            setValue={setValue}
            setValueOptions={ADDRESS_FORM_INPUT_SET_VALUE_OPTIONS}
            register={register}
            defaultValue={listOfOptions?.[0].value}
            errors={errors}
        />
    )
}

export default AddressFormCountryField

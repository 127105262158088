import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const storeConfigBlogHomePageMeta: QueryResolvers['storeConfigBlogHomePageMeta'] = async (_, inputObj) => {
    const { data: { storeConfig: context = {} } = {} } = await (
        api.graphql(
            gql`
                query {
                    storeConfig {
                        blog_homepage_title
                        blog_homepage_meta_keywords
                        blog_homepage_meta_description
                        blog_homepage_meta_robots
                    }
                }
            `,
        ).query(undefined, {
            errorPolicy: 'all',
        })
    )

    return {
        title: context?.blog_homepage_title || '',
        keywords: context?.blog_homepage_meta_keywords || '',
        description: context?.blog_homepage_meta_description || '',
        robots: context?.blog_homepage_meta_robots || '',

        __typename: 'StoreConfigDefaultPageMeta',
    }
}

export default storeConfigBlogHomePageMeta

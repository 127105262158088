import { ApolloError, ApolloQueryResult, ErrorPolicy } from '@apollo/client'
import { $cart } from '@pwa-concept/core/models'
import { CartQuery, CartQueryVariables, useCartQuery } from '@pwa-concept/modules/cart/graphql/queries/Cart'
import { Cart, Exact } from '@pwa-concept/modules/graphql'

interface UseCartOptions {
    errorPolicy: ErrorPolicy
    skip: boolean
}

export interface IUseCartProps {
    input?: Partial<Pick<CartQueryVariables, 'isFull'>>
    options?: Partial<UseCartOptions>
}

type tUseCart = (
    input?: IUseCartProps['input'],
    options?: IUseCartProps['options']
) => {
    data: Cart
    loading: boolean
    refetch: (variables?: Partial<Exact<{cartId?: string, isFull?: boolean}>>) => Promise<ApolloQueryResult<CartQuery>>
    error: ApolloError

}

const useCart: tUseCart = (input = {}, options = {}) => {
    const [cartId] = $cart.useId()

    const { data: { cart = {} } = {}, loading, refetch, error } = useCartQuery({
        ...options,
        variables: {
            ...input,
            cartId,
        },
    })

    return {
        data: cart || undefined,
        loading,
        refetch,
        error,
    }
}

export default useCart

import { ORDERS_PAGE_SIZE } from '@pwa-onilab/ui/components/modules/AccountSubpages'

import styles from './AccountOrderHistoryPlaceholder.module.scss'

const AccOrderHistoryPlaceholderMobile = () => {
    const ARRAY_OF_BLOCKS = Array.from({ length: ORDERS_PAGE_SIZE }, (_, i) => i)

    return (
        <div className={styles.wrapper}>
            {ARRAY_OF_BLOCKS.map((rowNumber) => {
                return <div key={rowNumber} className={styles.block} />
            })}
        </div>
    )
}

export default AccOrderHistoryPlaceholderMobile

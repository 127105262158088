import { BlogAuthor } from '@pwa-concept/modules/graphql'
import MAGENTO_DEFAULT_IMAGE from '@pwa-onilab/ui/assets/images/magentoDefaultImage.jpg'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { Image } from '@pwa-onilab/ui/components/UI/Image'
import Title from '@pwa-onilab/ui/components/UI/Title'
import { FormattedDate } from 'elements/FormattedDate'
import useGetAuthorUrl from 'hooks/urls/useGetAuthorUrl'
import { useTranslation } from 'react-i18next'

import styles from './PageAuthor.module.scss'

export interface IPageAuthor {
    author?: Pick<BlogAuthor, 'thumbnailImageUrl' | 'name' | 'id' | 'urlKey'>
    createdAt?: string
}

// TODO add schema author inside this component?
const PageAuthor = ({ author, createdAt }: IPageAuthor) => {
    if (!author) {
        return null
    }
    const authorUrl = useGetAuthorUrl()(author.urlKey)

    const { t } = useTranslation()

    return (
        <div className={styles.wrapper}>
            <Anchor to={authorUrl}>
                <Image
                    src={author.thumbnailImageUrl || MAGENTO_DEFAULT_IMAGE}
                    alt="author image"
                    className={styles.image}
                />
            </Anchor>
            <div className={styles.content}>
                <Anchor to={authorUrl}>
                    <Title
                        type="h3"
                        text={author.name || t('global.anonymous')}
                        className={styles.title}
                    />
                </Anchor>
                <FormattedDate date={createdAt} className={styles.date} />
            </div>
        </div>
    )
}

export default PageAuthor

import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { useGetStrainFamilies } from 'app/modules/strain-family'
import {
    StrainFamilyAllFamiliesColumnsDesktop,
    StrainFamilyAllFamiliesColumnsMobile,
    StrainFamilyAllFamiliesPlaceholder,
    StrainFamilyAllFamiliesTitle,
} from 'modules/StrainFamily'
import { IStrainFamilyContent } from 'modules/StrainFamily/components/StrainFamilyContent/IStrainFamilyContent'

import styles from './StrainFamilyAllFamilies.module.scss'

const StrainFamilyAllFamilies = ({ strain }: Pick<IStrainFamilyContent, 'strain'>) => {
    const { data: strainFamiliesData, loading: strainFamiliesLoading } = useGetStrainFamilies()
    const { data: storeConfigData, loading: storeConfigLoading } = useStoreConfig()
    const isMobile = useMediaBreakpoint().isBreakpointDown('xs')

    if (!strainFamiliesData?.items?.length || !storeConfigData) {
        if (strainFamiliesLoading || storeConfigLoading) {
            return <StrainFamilyAllFamiliesPlaceholder />
        }
        return null
    }

    const filteredItems = strainFamiliesData.items.filter((item) => item.urlKey !== strain.urlKey)
    const familyHomepageUrlKey = storeConfigData.familyHomepageUrlKey || 'family-strains'

    const StrainFamilyAllFamiliesColumns = isMobile
        ? StrainFamilyAllFamiliesColumnsMobile
        : StrainFamilyAllFamiliesColumnsDesktop

    return (
        <div>
            <StrainFamilyAllFamiliesTitle />
            <div className={styles.columnsWrapper}>
                <StrainFamilyAllFamiliesColumns
                    items={filteredItems}
                    familyHomepageUrlKey={familyHomepageUrlKey}
                />
            </div>
        </div>
    )
}

export default StrainFamilyAllFamilies

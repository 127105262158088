import { Resolvers } from '@pwa-concept/modules/graphql'

const Country: Resolvers['Country'] = {
    id: (_, __, { context }) => context?.id,

    availableRegions: (_, __, { context }) => context?.available_regions,
    fullNameLocale: (_, __, { context }) => context?.full_name_locale,
    fullNameEnglish: (_, __, { context }) => context?.full_name_english,

    threeLetterAbbreviation: (_, __, { context }) => context?.three_letter_abbreviation,
    twoLetterAbbreviation: (_, __, { context }) => context?.two_letter_abbreviation,
}

export default Country

import { SimpleButton } from '@pwa-onilab/ui/components/elements/Buttons'
import { useMediaBreakpoint, MediaBreakpointUp, MediaBreakpointDown } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { Title } from '@pwa-onilab/ui/components/modules/Footer/elements/Title'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren, useState, useCallback } from 'react'

import styles from './Group.module.scss'

interface IGroupProps {
    className: string
    title: string
    showArrowOnMobile: boolean
}

const Group = (
    {
        children,
        title = '',
        className,
    }: Partial<PropsWithChildren<IGroupProps>>,
) => {
    const [isActive, setActive] = useState(false)

    const onToggleHandler = useCallback(() => {
        setActive(!isActive)
    }, [isActive])

    const { isBreakpointUp } = useMediaBreakpoint()

    return (
        <div className={classNames(styles.wrapper, className)}>
            <MediaBreakpointUp name="lg">
                <Title title={title} />
            </MediaBreakpointUp>
            <MediaBreakpointDown name="lg">
                <SimpleButton
                    className={classNames(styles.button, isActive && styles.active)}
                    onClick={onToggleHandler}
                >
                    {title}
                    <Icon
                        name="chevron"
                        className={styles.icon}
                    />
                </SimpleButton>
            </MediaBreakpointDown>
            {
                (isActive || isBreakpointUp('lg')) && (
                    <>
                        {children}
                    </>
                )
            }
        </div>
    )
}

export default Group

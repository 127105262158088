import { Resolvers } from '@pwa-concept/modules/graphql'

const WishlistItemsOutput: Resolvers['WishlistItemsOutput'] = {
    items: (_, __, { context }) => context?.items?.length > 0 ? (
        context?.items?.map?.((__context) => ({
            __context,
            __typename: 'WishlistItem',
        }))
    ) : null,
    pagination: (_, __, { context }) => !!context?.page_info ? ({
        current: context?.page_info?.current_page,
        limit: context?.page_info?.page_size,
        total: context?.page_info?.total_pages,
        count: null,
        __typename: 'Pagination'
    }) : null
}

export default WishlistItemsOutput

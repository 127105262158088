import { useTranslation } from 'react-i18next'
import { classNames } from '@pwa-onilab/ui/helpers'
import { memo } from 'react'

import styles from './AccountNavigationTitle.module.scss'

interface IAccountNavigationTitle {
    className?: string
}

const AccountNavigationTitle = (
    {
        className,
    }: IAccountNavigationTitle
) => {
    const { t } = useTranslation()

    return (
        <div className={classNames(styles.title, className)}>
            {t('global.account')}
        </div>
    )
}

export default memo(AccountNavigationTitle)

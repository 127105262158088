import { classNames } from '@pwa-onilab/ui/helpers/classNames'
import { PropsWithChildren } from 'react'
import { Swiper } from 'swiper/react'

import { useSliderContext } from '../../providers'
import css from './SliderContainer.module.scss'

interface SliderContainerProps {
    className?: string
    onInit?: Swiper['onSwiper']
}

const SliderContainer = (
    {
        children,
        className,
        onInit,
    }: PropsWithChildren<SliderContainerProps>,
) => {
    const { value } = useSliderContext()

    return (
        <Swiper
            {...value}
            onSwiper={(swiper) => {
                if (typeof onInit === 'function') {
                    onInit?.(swiper)
                } else {
                    value?.onSwiper?.(swiper)
                }
            }}
            className={classNames(
                css.slider,
                className,
            )}
        >
            {children}
        </Swiper>
    )
}

export { SliderContainer }

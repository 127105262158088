import { PropsWithChildren, useEffect, useRef } from 'react'

export interface IntersectionProps {
    onIntersection?: (isIntersecting: boolean) => void
    root?: Element | Document
    threshold?: 0 | 0.25 | 0.5 | 0.75 | 1
    rootMargin?: number
    dontCheckIntersectionOnIntersectedElDown?: boolean
}

const Intersection = (
    {
        onIntersection,
        children,
        root,
        threshold,
        dontCheckIntersectionOnIntersectedElDown = false,
        // rootMargin,
    }: PropsWithChildren<IntersectionProps>) => {
    const emptyElement = useRef<HTMLSpanElement>(null)
    // не проверять пересечение, когда пересекли элемент вниз
    // dontCheckIntersectionOnIntersectedElDown

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                const isAbove = entry.boundingClientRect.top < 0

                // TODO fix naming
                const checkElementOnScrollDownWithAlreadyIntersectedElement = dontCheckIntersectionOnIntersectedElDown && !isAbove
                const isHandleIntersection = !dontCheckIntersectionOnIntersectedElDown || checkElementOnScrollDownWithAlreadyIntersectedElement

                if (isHandleIntersection) {
                    onIntersection(entry.isIntersecting)
                }
            }, {
                threshold: threshold || 0,
                root: root || null,

            },
        )

        const emptyEl = emptyElement?.current

        if (emptyEl) {
            observer.observe(emptyEl)
        }
        return () => {
            observer.disconnect()
        }
    }, [])

    return (
        <span ref={emptyElement}>
            {children}
        </span>
    )
}

export default Intersection

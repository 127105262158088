import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { WISHLIST_ROUTE } from '@pwa-onilab/ui/constants'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './Wishlist.module.scss'

const Wishlist = (
    {
        className,
    }: IClassName,
) => {
    const { t } = useTranslation()

    return (
        <Anchor
            className={classNames(styles.button, className)}
            to={WISHLIST_ROUTE}
            aria-label={t('wishlist.title')}
        >
            <Icon
                name="wishlist"
                className={styles.icon}
            />
        </Anchor>
    )
}

export default memo(Wishlist)

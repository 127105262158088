import styles from './ErrorPageNavigationList.module.scss'

const ErrorPageNavListPlaceholder = () => {
    return (
        <div className={styles.list}>
            <div className={styles.placeholder} />
            <div className={styles.placeholder} />
            <div className={styles.placeholder} />
            <div className={styles.placeholder} />
        </div>
    )
}

export default ErrorPageNavListPlaceholder

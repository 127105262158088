import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import {
    NavigationDesktopButton,
    NavigationMobileButton,
} from '@pwa-onilab/ui/components/modules/Footer/elements/Navigation'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './Navigation.module.scss'

interface INavigationProps {
    className?: string
    items: Array<{
        content: {
            urlPath?: string
            url?: string
        }
        nodeId: number
        title: string
    }>
    withArrow?: boolean
}

const Navigation = (
    {
        items,
        withArrow,
    }: INavigationProps,
) => {
    if (!items?.length) {
        return null
    }
    const isMobile = useMediaBreakpoint().isBreakpointDown('lg')
    const NavigationButton = isMobile ? NavigationMobileButton : NavigationDesktopButton

    return (
        <>
            <ul className={classNames(styles.menu, withArrow && styles.menuWithArrow)}>
                {
                    items.map(({ content, nodeId, title }) => {
                        const currentUrl = content?.urlPath || content?.url

                        if (!currentUrl || !title) {
                            return null
                        }

                        return (
                            <li key={nodeId}>
                                <NavigationButton
                                    title={title}
                                    to={currentUrl}
                                    withArrow={withArrow}
                                    isForceAbsoluteUrl={!!content?.url}
                                />
                            </li>
                        )
                    })
                }
            </ul>
        </>
    )
}

export default Navigation

import { classNames } from '@pwa-onilab/ui/helpers'

import IToggler from './IToggler'
import styles from './Toggler.module.scss'

const Toggler = ({ isActive, handleToggle, id, disabled }: IToggler) => (
    <>
        <label className={classNames(styles.label, isActive && styles.active, disabled && styles.disabled)} htmlFor={id}>
            <input
                checked={isActive}
                onChange={handleToggle}
                className={styles.checkbox}
                id={id}
                type="checkbox"
                disabled={disabled}
            />
            <span className={styles.button} />
        </label>
    </>
)

export default Toggler

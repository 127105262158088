import { PricePartProps, PriceProps } from '@pwa-onilab/ui/components/modules/Product/elements/ProductPrice/ProductPrice'
import Price from '@pwa-onilab/ui/components/UI/Price'
import { classNames } from '@pwa-onilab/ui/helpers'
import { Trans } from 'react-i18next'

import styles from './PricePerItem.module.scss'

export interface PricePerItemProps extends Pick<PriceProps, 'isCart'>, PricePartProps {

}

const PricePerItem = ({ value, isCart, className }: PricePerItemProps) => {
    const wrapperClasses = [styles.perItem]

    if (isCart) {
        wrapperClasses.push(styles.cart)
    }
    if (className) {
        wrapperClasses.push(className)
    }

    return (
        <div className={classNames(...wrapperClasses)}>
            <Trans
                i18nKey="price.perItem"
                values={{ priceValue: value }}
                components={{ price: <Price /> }}
            />
            <Trans />
        </div>
    )
}

export default PricePerItem

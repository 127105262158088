import { classNames } from '@pwa-onilab/ui/helpers'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'

import styles from './NavigationDesktopButton.module.scss'

interface INavigationDesktopButtonProps {
    className: string
    to: string
    title: string
    isForceAbsoluteUrl: boolean
}

const NavigationDesktopButton = (
    {
        className,
        to,
        title,
        isForceAbsoluteUrl,
    }: Partial<INavigationDesktopButtonProps>,
) => (
    <Anchor
        to={to}
        className={classNames(styles.link, className)}
        isForceAbsoluteUrl={isForceAbsoluteUrl}
    >
        {title}
    </Anchor>
)

export default NavigationDesktopButton

import { InputField } from '@pwa-onilab/ui/components/elements/Fields'
import {
    ADDRESS_FORM_INPUT_SET_VALUE_OPTIONS,
    IAddressFormField,
    useAddressLineRules,
} from '@pwa-onilab/ui/components/modules/AddressForm'
import { useFormContext } from 'react-hook-form'

const AddressFormAddressLineField = ({ name, placeholder, isRequired }: IAddressFormField) => {
    const { register, setValue, formState: { errors } } = useFormContext()
    const rules = useAddressLineRules(isRequired)

    return (
        <InputField
            placeholder={placeholder}
            field={name}
            rules={rules}
            setValue={setValue}
            setValueOptions={ADDRESS_FORM_INPUT_SET_VALUE_OPTIONS}
            register={register}
            errors={errors}
        />
    )
}

export default AddressFormAddressLineField

import { Resolvers } from '@pwa-concept/modules/graphql'

const CartBillingAddress: Resolvers['CartBillingAddress'] = {
    city: (_, __, { context }) => context?.city || null,
    company: (_, __, { context }) => context?.company,
    country: (_, __, { context }) => {
        return {
            __context: context?.country,
            __typename: 'CartAddressCountry',
        }
    },
    firstName: (_, __, { context }) => context?.firstname || null,
    lastName: (_, __, { context }) => context?.lastname || null,
    postCode: (_, __, { context }) => context?.postcode || null,
    region: (_, __, { context }) => {
        return {
            __context: context,
            __typename: 'CartAddressRegion',
        }
    },
    street: (_, __, { context }) => context?.street || null,
    telephone: (_, __, { context }) => context?.telephone || null,
}

export default CartBillingAddress

import { ACCOUNT_ADDRESS_TYPE_TRANSLATE_KEYS } from '@pwa-onilab/ui/components/modules/AccountAddress'

export const getAddressTypeMessage = (isDefaultBilling, isDefaultShipping) => {
    if (isDefaultBilling && isDefaultShipping) {
        return ACCOUNT_ADDRESS_TYPE_TRANSLATE_KEYS.SHIPPING_AND_BILLING
    }

    if (!(isDefaultBilling || isDefaultShipping)) {
        return ACCOUNT_ADDRESS_TYPE_TRANSLATE_KEYS.ADDITIONAL
    }

    return isDefaultShipping
        ? ACCOUNT_ADDRESS_TYPE_TRANSLATE_KEYS.ONLY_SHIPPING
        : ACCOUNT_ADDRESS_TYPE_TRANSLATE_KEYS.ONLY_BILLING
}

import { $cart } from '@pwa-concept/core/models'
import { useCartGenerateIdMutation } from '@pwa-concept/modules/cart/graphql/mutations/CartGenerateId'

const useCartGenerateId = () => {
    const [generateId] = useCartGenerateIdMutation()

    return async (isForce: boolean = false) => {
        if (!$cart.isId || isForce) {
            const oldId = $cart.id

            const { data: { cartGenerateId = null } = {} } = await generateId()

            if (cartGenerateId) {
                $cart.id = cartGenerateId
            }

            return {
                oldId,
                newId: cartGenerateId,
            }
        }

        return {}
    }
}

export default useCartGenerateId

import { Category, Filter } from '@pwa-concept/modules/graphql'
import { useCategoryNumResultsValue } from '@pwa-onilab/ui/components/modules/Category'
import { SchemaHelmet } from 'elements/SchemaHelmet'
import { getPriceMinMaxFromFilter, getSchemaHelmetProductCollection } from 'elements/SchemaHelmet/SchemaHelmetHelpers'

interface ISchemaHelmetCategoryPage {
    category: Category
    filters: Filter[]
}
const SchemaHelmetCategoryPage = ({ category, filters }: ISchemaHelmetCategoryPage) => {
    const numResultsCount = useCategoryNumResultsValue()

    if (!(category && filters)) {
        return null
    }
    const [priceMin, priceMax] = getPriceMinMaxFromFilter(filters)
    const schemaConfig = getSchemaHelmetProductCollection(category.name, priceMin, priceMax, numResultsCount)

    return <SchemaHelmet jsonConfig={schemaConfig} />
}

export default SchemaHelmetCategoryPage

import { Button, IButton } from '@pwa-onilab/ui/components/UI/Button'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

interface ISubmitButton extends Pick<IButton, 'className' | 'disabled' > {
    isLoading: boolean
}

const SubmitButton = ({ isLoading, disabled, className }: ISubmitButton) => {
    const { t } = useTranslation()

    return (
        <Button
            isBold
            isPrimary
            type="submit"
            isFullWidth
            disabled={disabled || isLoading}
            className={className}
        >
            {t(isLoading
                ? 'global.buttons.saving'
                : 'global.buttons.saveAndClose')}
        </Button>
    )
}

export default memo(SubmitButton)

import { useCart } from '@pwa-concept/modules/cart'

const useCartAddresses = () => {
    const { data, loading } = useCart()

    return {
        data: {
            billingAddress: data?.billingAddress || null,
            shippingAddress: data?.shippingAddresses?.[0] || null,
        },
        loading,
    }
}

export default useCartAddresses

import { Button } from '@pwa-onilab/ui/components/UI/Button'

import styles from './SubmitButton.module.scss'

interface SubmitButtonProps {
    text: string
    onLoadText: string
    loading?: boolean
}

// TODO rename
const SubmitButton = (
    {
        text,
        onLoadText,
        loading,
    }: SubmitButtonProps,
) => {
    return (
        <Button
            isFullWidth
            isPrimary
            isBold
            type="submit"
            disabled={loading}
            className={styles.submitButton}
        >
            {loading ? onLoadText : text}
        </Button>
    )
}

export default SubmitButton

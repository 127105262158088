import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

const getBlogAuthor: QueryResolvers['getBlogAuthor'] = async (_, { urlKey, isFull = true }) => {
    const { data: { blogAuthor: __context } = {} } = await (
        api.graphql(
            gql`
                query( $urlKey: String, $isFull: Boolean!) {
                    blogAuthor(urlKey: $urlKey) {
                        id
                        name
                        url_key
                        featured_image
                        thumbnail_featured_image
                        description

                        facebook_link @include(if: $isFull)
                        instagram_link @include(if: $isFull)
                        linkedin_link @include(if: $isFull)
                        pinterest_link @include(if: $isFull)
                        twitter_link @include(if: $isFull)
                        youtube_link @include(if: $isFull)

                        meta_title @include(if: $isFull)
                        meta_keywords @include(if: $isFull)
                        meta_description @include(if: $isFull)
                        meta_robots @include(if: $isFull)
                        
                        categories @include(if: $isFull) {
                            id
                            title
                        }
                    }
                }
            `,
        ).query({
            urlKey,
            isFull,
        })
    )

    if (!__context) {
        return null
    }

    return {
        __context,
        __typename: 'BlogAuthor',
    }
}

export default getBlogAuthor

export const MOBILE_LIST_OF_POLICY_LINKS_DATA = [
    {
        title: 'Terms of use',
        link: '/terms',
    },
    {
        title: 'Return Policy',
        link: 'returnPolicy',
    },
    {
        title: 'Privacy Policy',
        link: 'privacyPolicy',
    },
]

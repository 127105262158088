import { Product } from '@pwa-concept/modules/graphql'
import { StrainsForSaleProductsItem } from 'modules/StrainsForSale'

import styles from './StrainsForSaleProductsList.module.scss'

interface IStrainsForSaleProductsListProps {
    products: Product[]
}

const StrainsForSaleProductsList = (
    {
        products,
    }: IStrainsForSaleProductsListProps,
) => (
    <ul className={styles.list}>
        {
            products.map(({ name, url, sku }) => (
                <li>
                    <StrainsForSaleProductsItem
                        key={sku}
                        url={url}
                        name={name}
                        sku={sku}
                    />
                </li>
            ))
        }
    </ul>
)

export default StrainsForSaleProductsList

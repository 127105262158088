import { AccountOtpCreatePassword, useAccountOneTimePassword } from '@pwa-onilab/ui/components/modules/Account'
import OneTimePassword, { OneTimePasswordModal } from '@pwa-onilab/ui/components/modules/OneTimePassword'
import Loader from '@pwa-onilab/ui/components/UI/Loader'
import { memo } from 'react'

import IAccountOneTimePassword from './interface'

const AccountOTP = (
    {
        handleChangeEmail,
        isOtpModalOpen,
        closeOtpModal,
    }: IAccountOneTimePassword) => {
    const {
        checkOneTimePassword,
        resendCode,
        attemptsLeft,
        timerValue,
        customerEmail,
        isResendCode,
        isRequestOpenOtpLoading,

        token,

        isNewPasswordModalOpen,
        closeNewPasswordModal,

        onChangeEmailClick,
        onValidCodeHandler,
    } = useAccountOneTimePassword(
        {
            isOtpModalOpen,
            closeOtpModal,
            handleChangeEmail,
        })

    return (
        <>

            <OneTimePasswordModal isOpen={isOtpModalOpen} onDismiss={closeOtpModal}>
                <OneTimePassword
                    onChangeEmailClick={onChangeEmailClick}
                    onValidCode={onValidCodeHandler}
                    email={customerEmail}
                    checkOneTimePassword={checkOneTimePassword}
                    resendCode={resendCode}
                    timerValue={timerValue}
                    attemptsLeft={attemptsLeft}
                    isResendCode={isResendCode}
                />
                <Loader visible={isRequestOpenOtpLoading} isAbsolute />
            </OneTimePasswordModal>
            <AccountOtpCreatePassword
                isOpen={isNewPasswordModalOpen}
                onDismiss={closeNewPasswordModal}
                token={token}
            />
        </>
    )
}

export default memo(AccountOTP)

import { CustomerOrder } from '@pwa-concept/modules/graphql'
import { AccountOrderBlockHeader } from '@pwa-onilab/ui/components/modules/AccountSubpages'

import AccountOrderBlockMain from '../AccountOrderBlockMain/AccountOrderBlockMain'
import styles from './AccountOrderBlock.module.scss'

interface IAccountOrderBlock {
    order: CustomerOrder
}

const AccountOrderBlock = ({ order }: IAccountOrderBlock) => {
    return (
        <div className={styles.wrapper}>
            <AccountOrderBlockHeader number={order.number} />
            <AccountOrderBlockMain
                date={order.date}
                paymentName={order.paymentMethod.name}
                status={order.status}
                total={order.totals.total}
            />
        </div>
    )
}

export default AccountOrderBlock

import { useCountries } from '@pwa-concept/modules/countries'
import { useCallback } from 'react'

const useFindCountry = () => {
    const { data: countries } = useCountries()

    return useCallback((id: string) => {
        return countries?.find(({ id: countryId }) => countryId === id) || null
    }, [countries])
}

export default useFindCountry

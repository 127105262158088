import { ButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'
import { classNames } from '@pwa-onilab/ui/helpers/classNames'
import { PropsWithChildren } from 'react'
import IButton from '@pwa-onilab/ui/components/UI/Button/IButton'

import styles from './Button.module.scss'

const Button = (
    {
        className,
        isBold,
        isFullWidth,
        isPrimary,
        isPrimaryTransparent,
        isBlack,
        isBlackTransparent,
        children,
        ...ButtonInstanceProps
    }: PropsWithChildren<IButton>) => {
    const classes = classNames(
        styles.button,
        className && className,
        isBold && styles.bold,
        isFullWidth && styles.fullWidth,
        isPrimary && styles.primary,
        isPrimaryTransparent && styles.primaryTransparent,
        isBlack && styles.black,
        isBlackTransparent && styles.blackTransparent,
    )

    return (
        <ButtonInstance className={classes} {...ButtonInstanceProps}>
            {children}
        </ButtonInstance>
    )
}

export default Button

import { logger as pwaConceptLogger } from '@pwa-concept/modules/helpers/logger'
import * as Sentry from '@sentry/react'

export const setupLogger = () => {
    pwaConceptLogger.setErrorsCallback((error) => {
        if (typeof error === 'string') {
            Sentry.captureMessage(error, 'error')
        } else {
            Sentry.captureException(error)
        }
    })
    pwaConceptLogger.setLogsCallback((message) => {
        Sentry.captureMessage(message, 'log')
    })
}

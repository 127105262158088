import { IApolloMutationsBaseOptions } from '@pwa-concept/modules/common'
import {
    SharedWishlistQueryVariables,
    useSharedWishlistQuery,
} from '@pwa-concept/modules/wishlist/graphql/queries/SharedWishlist'

const useSharedWishlist = (
    variables: SharedWishlistQueryVariables,
    options?: Pick<IApolloMutationsBaseOptions, 'onCompleted' | 'onError'>,
) => {
    const { data: { sharedWishlist } = {}, loading } = useSharedWishlistQuery({
        variables,
        skip: !variables.sharingCode,
        ...options,
    })

    return {
        data: sharedWishlist,
        loading,
    }
}

export default useSharedWishlist

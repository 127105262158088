import {
    AccountSubpageEmptyButton,
    AccountSubpageEmptyTitle,
} from '@pwa-onilab/ui/components/modules/AccountSubpages'
import { IButtonInstance } from '@pwa-onilab/ui/components/UI/ButtonInstance'

import styles from './AccountSubpageEmpty.module.scss'

interface IAccountSubpageEmpty extends Pick<IButtonInstance, 'onClick'>{
    text: string
    buttonText: string
}

const AccountSubpageEmpty = ({ text, buttonText, onClick }: IAccountSubpageEmpty) => {
    return (
        <div className={styles.wrapper}>
            <AccountSubpageEmptyTitle
                className={styles.title}
                text={text}
            />
            <AccountSubpageEmptyButton
                title={buttonText}
                onClick={onClick}
                isPrimary
            />
        </div>
    )
}

export default AccountSubpageEmpty

import { $cart } from '@pwa-concept/core/models'
import { useCartRecommendedProductsQuery } from '@pwa-concept/modules/cart/graphql/queries/CartRecommendedProducts'

export const useCartRecommendedProducts = () => {
    const [cartId] = $cart.useId()

    const { data, loading, refetch } = useCartRecommendedProductsQuery({
        variables: {
            cartId,
        },
    })

    return {
        data: data?.cartRecommendedProducts || undefined,
        loading,
        refetch,
    }
}

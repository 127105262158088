import { Resolvers } from '@pwa-concept/modules/graphql'

const CustomerOrder: Resolvers['CustomerOrder'] = {
    id: (_, __, { context }) => context?.id || null,
    number: (_, __, { context }) => context?.order_number || null,
    status: (_, __, { context }) => context?.status || null,
    date: (_, __, { context }) => context?.order_date || null,
    paymentMethod: (_, __, { context }) => {
        const __context = context?.payment_methods?.[0]
        if (!__context) {
            return null
        }

        return {
            __context,
            __typename: 'CustomerOrderPaymentMethod',
        }
    },
    totals: (_, __, { context }) => {
        if (context?.total) {
            return {
                __context: context?.total,
                __typename: 'CustomerOrderTotals',
            }
        }
        return null
    },
    products: (_, __, { context }) => {
        if (!context?.items) {
            return null
        }

        return context.items.map((__context) => ({
            __context,
            __typename: 'CustomerOrderProduct',
        }))
    },
    shippingAddress: (_, __, { context }) => {
        if (context?.shipping_address) {
            return {
                __context: context?.shipping_address,
                __typename: 'CustomerOrderAddress',
            }
        }
        return null
    },
    billingAddress: (_, __, { context }) => {
        if (context?.billing_address) {
            return {
                __context: context?.billing_address,
                __typename: 'CustomerOrderAddress',
            }
        }
        return null
    },
    shippingMethod: (_, __, { context }) => {
        return context?.shipping_method || null
    },
    shippingAmount: (_, __, { context }) => {
        return context?.shipping_amount || null
    },
}

export default CustomerOrder

import { ITitle } from '@pwa-onilab/ui/components/UI/Title'
import { classNames } from '@pwa-onilab/ui/helpers'
import { AuthorPageRowTitle } from 'modules/AuthorPage'

import styles from './AuthorPagePostsRowTitle.module.scss'

const AuthorPagePostsRowTitle = (props: ITitle) => {
    return <AuthorPageRowTitle {...props} className={classNames(styles.title, props.className)} />
}

export default AuthorPagePostsRowTitle

import { module } from '@pwa-concept/core'
import { PaginationModule } from '@pwa-concept/modules/pagination'

import graphqlSchemas from './graphql/schemas'
import stores from './graphql/stores'

const CustomerOrdersModule = module(() => ({
    modules: [
        PaginationModule,
    ],
    graphqlSchemas,
    graphqlResolvers: stores?.resolvers,
    graphqlStoreFragments: stores?.fragments,
    graphqlStorePossibleTypes: stores?.possibleTypes,
    graphqlPolicies: {
        CustomerOrder: {
            keyFields: (order) => `CustomerOrder:${order.number}`,
        },
    },
}))

export default CustomerOrdersModule

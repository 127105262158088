import Flex from '@pwa-onilab/ui/components/elements/Flex/Flex'
import { MediaBreakpointDown, MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { GiftSectionItemSkeleton } from '@pwa-onilab/ui/components/widgets/GiftSection/GiftSectionItem'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './GiftSection.module.scss'

const GiftSectionSkeleton = () => (
    <section className={styles.wrapper}>
        <Container className={styles.containerSkeleton}>
            <MediaBreakpointDown name="lg">
                <GiftSectionItemSkeleton />
            </MediaBreakpointDown>
            <MediaBreakpointUp name="lg">
                <Flex direction="column">
                    <Flex
                        direction="row" justifyContent="spaceBetween"
                        className={classNames(styles.itemsListWrapper)}
                    >
                        <GiftSectionItemSkeleton />
                        <GiftSectionItemSkeleton />
                    </Flex>
                    <Flex
                        direction="row" justifyContent="spaceBetween"
                        className={classNames(styles.itemsListWrapper)}
                    >
                        <GiftSectionItemSkeleton />
                        <GiftSectionItemSkeleton />
                    </Flex>
                </Flex>
            </MediaBreakpointUp>
        </Container>
    </section>
)

export { GiftSectionSkeleton }

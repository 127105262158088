import * as Types from '../../../../graphql';

import { gql } from '@apollo/client';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

export type StrainFamiliesClientFragmentFragment = { __typename?: 'FamilyStrains', items?: Array<{ __typename?: 'FamilyStrain', id?: string | null, urlKey?: string | null, thumbnail?: string | null, name?: string | null, shortDescription?: string | null, meta?: { __typename?: 'FamilyStrainMeta', title?: string | null, description?: string | null, keywords?: string | null } | null } | null> | null, pagination?: { __typename?: 'Pagination', count?: number | null, current?: number | null, limit?: number | null, total?: number | null } | null };

export const StrainFamiliesClientFragmentFragmentDoc = gql`
    fragment StrainFamiliesClientFragment on FamilyStrains {
  items {
    id
    urlKey
    thumbnail
    name
    shortDescription
    meta {
      title
      description
      keywords
    }
  }
  pagination {
    count
    current
    limit
    total
  }
}
    `;
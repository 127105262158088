import { IProtectedInput } from '@pwa-onilab/ui/components/elements/Inputs'
import { Input } from '@pwa-onilab/ui/components/UI/Input'

const ProtectedInput = (
    {
        setValue,
        setValueOptions,
        className,
        field,
        register,
        rules,
        errors,
        placeholder = '',
        ...inputProps
    }: IProtectedInput) => {
    return (
        <Input
            {...inputProps}
            className={className}
            type="password"
            field={field}
            register={register}
            rules={rules}
            setValue={setValue}
            setValueOptions={setValueOptions}
            placeholder={placeholder}
            errors={errors}
        />
    )
}

export default ProtectedInput

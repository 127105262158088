import { RightPositionModal } from '@pwa-onilab/ui/components/elements/Modals'
import Cart from '@pwa-onilab/ui/components/modules/Cart'
import { useCartModal } from '@pwa-onilab/ui/hooks/modals'

const CartModal = () => {
    const { useIsCartModalOpen, closeModal } = useCartModal()
    const isModalOpen = useIsCartModalOpen()

    return (
        <RightPositionModal isOpen={isModalOpen} onDismiss={closeModal}>
            <Cart />
        </RightPositionModal>
    )
}

export default CartModal

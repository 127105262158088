import { CMSModuleConfigureOptions } from '@pwa-concept/modules/cms/module'
import { CmsRouteType } from '@pwa-concept/modules/graphql'
import ProductPlaceholder from '@pwa-onilab/ui/components/modules/ProductPage/Placeholder'
import { CategoryPageSkeleton } from '@pwa-onilab/ui/pages/CategoryPage'
import { CMSPageDesktopSkeleton } from '@pwa-onilab/ui/pages/CMSPage'
import Error404Page from '@pwa-onilab/ui/pages/Error404Page'
import { APP_CMS_CUSTOM_ROUTE_TYPES } from 'app/modules/cms/enums/AppCmsCustomRouteTypes'
import DefaultFallback from 'components/UI/DefaultFallback'
import AuthorPagePlaceholder from 'pages/AuthorPage/AuthorPagePlaceholder'
import BestsellersPage from 'pages/BestsellersPage'
import BlogPagePlaceholder from 'pages/BlogPage/BlogPagePlaceholder'
import BlogPostPagePlaceholder from 'pages/BlogPost/BlogPostPagePlaceholder'
import { LocationPagePlaceholder } from 'pages/LocationPage/LocationPagePlaceholder'
import { StatePagePlaceholder } from 'pages/StatePage/StatePagePlaceholder'
import StrainFamiliesPagePlaceholder from 'pages/StrainFamiliesPage/StrainFamiliesPagePlaceholder'
import StrainFamilyPagePlaceholder from 'pages/StrainFamilyPage/StrainFamilyPagePlaceholder'
import { StrainsForSalePagePlaceholder } from 'pages/StrainsForSalePage'
import WholeSalePlaceholder from 'pages/WholesalePage/WholeSalePlaceholder/WholeSalePlaceholder'
import { WorldPagePlaceholder } from 'pages/WorldPage'
import { lazy } from 'react'

export const CMS_MODULE_CONFIGURATION: CMSModuleConfigureOptions = {
    replace: {
        routes: {
            [CmsRouteType.Product]: {
                element: lazy(async () => await import('@pwa-onilab/ui/pages/ProductPage')),
                fallback: ProductPlaceholder,
            },
            [CmsRouteType.Category]: {
                element: lazy(async () => await import('@pwa-onilab/ui/pages/CategoryPage/CategoryPage')),
                fallback: CategoryPageSkeleton,
            },
            [CmsRouteType.CmsPage]: {
                element: lazy(async () => await import('@pwa-onilab/ui/pages/CMSPage')),
                fallback: CMSPageDesktopSkeleton,
            },
            [APP_CMS_CUSTOM_ROUTE_TYPES.BESTSELLERS]: {
                element: BestsellersPage,
                fallback: CategoryPageSkeleton,
            },
            [APP_CMS_CUSTOM_ROUTE_TYPES.LOCATION]: {
                element: lazy(async () => await import('pages/LocationPage')),
                fallback: LocationPagePlaceholder,
            },
            [APP_CMS_CUSTOM_ROUTE_TYPES.LOCATION_STATE]: {
                element: lazy(async () => await import('pages/StatePage')),
                fallback: StatePagePlaceholder,
            },
            [APP_CMS_CUSTOM_ROUTE_TYPES.WHOLESALE]: {
                element: lazy(async () => await import('pages/WholesalePage/WholesalePage')),
                fallback: WholeSalePlaceholder,
            },
            [APP_CMS_CUSTOM_ROUTE_TYPES.HOMEPAGE_BLOG]: {
                element: lazy(async () => await import('pages/BlogPage')),
                fallback: BlogPagePlaceholder,
            },
            [APP_CMS_CUSTOM_ROUTE_TYPES.BLOG_AUTHOR]: {
                element: lazy(async () => await import('pages/AuthorPage')),
                fallback: AuthorPagePlaceholder,
            },
            [APP_CMS_CUSTOM_ROUTE_TYPES.BLOG_POST]: {
                element: lazy(async () => await import('pages/BlogPost')),
                fallback: BlogPostPagePlaceholder,
            },
            [APP_CMS_CUSTOM_ROUTE_TYPES.HOMEPAGE_FAMILY_STRAIN]: {
                element: lazy(async () => await import('pages/StrainFamiliesPage')),
                fallback: StrainFamiliesPagePlaceholder,
            },
            [APP_CMS_CUSTOM_ROUTE_TYPES.FAMILY_STRAIN]: {
                element: lazy(async () => await import('pages/StrainFamilyPage')),
                fallback: StrainFamilyPagePlaceholder,
            },
            [APP_CMS_CUSTOM_ROUTE_TYPES.CATEGORIES_AS_STRAINS]: {
                element: lazy(async () => await import('pages/StrainsForSalePage')),
                fallback: StrainsForSalePagePlaceholder,
            },
            [APP_CMS_CUSTOM_ROUTE_TYPES.WORLD]: {
                element: lazy(async () => await import('pages/WorldPage')),
                fallback: WorldPagePlaceholder,
            },
        },
        errors: {
            404: Error404Page,
        },
        defaultFallback: DefaultFallback,
    },
}

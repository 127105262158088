import Container from '@pwa-onilab/ui/components/UI/Container'
import { BenefitsList } from '@pwa-onilab/ui/components/widgets/Benefits'
import { DisablePageBuilderStyles } from 'elements/DisablePageBuilderStyles'
import { memo } from 'react'

import styles from './Benefits.module.scss'

interface IBenefitsProps {
    items: Array<{
        icon_image: string
        link: string
        short_description: string
        title: string
    }>
}

const Benefits = (
    {
        items,
    }: IBenefitsProps,
) => {
    if (!items?.length) return null

    return (
        <DisablePageBuilderStyles>
            <Container className={styles.wrapper}>
                <BenefitsList items={items} />
            </Container>
        </DisablePageBuilderStyles>
    )
}

export default memo(Benefits)

import { useSliderContext } from '@pwa-onilab/ui/components/UI/Slider/providers'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useEffect, useRef } from 'react'
import { ScrollbarOptions } from 'swiper/types/modules/scrollbar'

import styles from './SliderScrollbar.module.scss'

const VARIANTS = {
    default: undefined,
    centeredModal: styles.variantCenteredModal,
}

interface SliderScrollbarProps {
    draggable?: boolean
    hide?: boolean
    snapOnRelease?: boolean
    className?: string
    variant?: keyof typeof VARIANTS
}

const SliderScrollbar = (
    {
        draggable = true,
        hide,
        snapOnRelease = true,
        className,
        variant = 'default',
    }: SliderScrollbarProps,
) => {
    const wrapperRef = useRef(null)
    const { slider } = useSliderContext()

    useEffect(() => {
        if (wrapperRef.current && slider && (slider?.params?.scrollbar as ScrollbarOptions)?.el !== wrapperRef.current) {
            try {
                const scrollbar = slider.params.scrollbar as ScrollbarOptions
                scrollbar.draggable = draggable
                scrollbar.hide = hide
                scrollbar.snapOnRelease = snapOnRelease
                scrollbar.el = wrapperRef.current
                scrollbar.dragClass = styles.drag
                scrollbar.draggable = draggable

                slider.scrollbar?.init?.()
                slider.scrollbar?.updateSize?.()
            } catch (e) {
                //
            }
        }

        return () => {
            if (slider?.params?.scrollbar) {
                try {
                    slider?.scrollbar.destroy?.()
                } catch (e) {
                    //
                }
            }
        }
    }, [draggable, hide, snapOnRelease, slider])

    if (!slider?.params?.scrollbar) {
        return null
    }

    return (
        <div
            className={classNames(
                styles.wrapper,
                className,
                (variant && VARIANTS?.[variant]) || VARIANTS.default,
            )}
            ref={wrapperRef}
        />
    )
}

export { SliderScrollbar }

import { module } from '@pwa-concept/core'
import { PaginationModule } from '@pwa-concept/modules/pagination'
import { BlogAuthorModule } from 'app/modules/blog-author'

import graphqlSchemas from './graphql/schemas'
import stores from './graphql/stores'

const StrainFamilyModule = module(() => ({
    modules: [
        PaginationModule,
        BlogAuthorModule,
    ],
    graphqlSchemas,
    graphqlResolvers: stores?.resolvers,
    graphqlStoreFragments: stores?.fragments,
    graphqlStorePossibleTypes: stores?.possibleTypes,
    graphqlPolicies: {
        FamilyStrain: {
            keyFields: (strain) => `FamilyStrain:${strain.urlKey}`,
        },
    },
}))

export default StrainFamilyModule

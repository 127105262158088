import { useSendDeleteAccountMassage } from '@pwa-concept/modules/customer'
import { ACCOUNT_DETAILS_ROUTE } from '@pwa-onilab/ui/constants/navigation'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const useSendPinCodeForDeleteAccount = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const sendDeleteAccountMassage = useSendDeleteAccountMassage()

    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        const sendMessage = async () => {
            try {
                setIsLoading(true)
                const { status, errorMessage } = await sendDeleteAccountMassage()
                if (errorMessage) {
                    toast.error(errorMessage)
                }

                if (status) {
                    toast.success(t('AccountPage.delete.pinRequest.success'))
                } else {
                    navigate(ACCOUNT_DETAILS_ROUTE)
                }
            } catch (error) {
                toast.error(error.message)
            } finally {
                setIsLoading(false)
            }
        }

        void sendMessage()
    }, [])

    return isLoading
}

export default useSendPinCodeForDeleteAccount

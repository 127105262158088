import { Product } from '@pwa-concept/modules/graphql'
import { useMemo } from 'react'

export const useProductsAlphabeticallyByGroup = (products: Product[]) => {
    return useMemo(() => {
        if (!products?.length) {
            return null
        }

        return Object.values(products.reduce((accumulator, currentValue) => {
            const title = currentValue.name[0]

            if (!accumulator[title]) accumulator[title] = { title, products: [currentValue] }

            else accumulator[title].products.push(currentValue)

            return accumulator
        }, {}))
    }, [products])
}

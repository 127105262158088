import { ReactComponent as LogoMin } from './blissLogoMin.svg'
import { ReactComponent as BlissMobileMenuLogo } from './blissMobileMenuLogo.svg'
import { ReactComponent as LogoPrompt } from './logoForPrompt.svg'
import { ReactComponent as LogoOnErrorPage } from './logoOnErrorPage.svg'

// TODO rename icons https://bitbucket.org/onilab/pwa-ui/pull-requests/29#comment-325410355
const IconsLogo = {
    logoMin: <LogoMin />,
    logoPrompt: <LogoPrompt />,
    blissMobileMenuLogo: <BlissMobileMenuLogo />,
    logoOnErrorPage: <LogoOnErrorPage />,
}

export default IconsLogo

import { useMutation } from '@apollo/client'
import { $auth } from '@pwa-concept/core/models'
import { CreateProductReviewMutation } from '@pwa-onilab/ui/schemas'

const useCreateProductReview = () => {
    const [createReview] = useMutation(CreateProductReviewMutation)

    return async (reviewInput) => {
        if (!$auth.isToken) {
            throw new Error('Customer isn\'t login')
        }
        const { data: review } = await createReview({
            variables: {
                input: reviewInput,
            },
        })

        return review
    }
}

export default useCreateProductReview

import { Breadcrumb } from '@pwa-concept/modules/graphql'
import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { SchemaHelmet } from 'elements/SchemaHelmet'
import {
    SCHEMA_HELMET_CONTEXT_VALUE,
    SCHEMA_HELMET_DEFAULT_SITE_URL,
} from 'elements/SchemaHelmet/SchemaHelmetConstants'
import { addSlashToEndUrl } from 'helpers'
import { useTranslation } from 'react-i18next'

const structuredDataBreadcrumbs = (breadcrumbs: Breadcrumb[], homeName: string) => {
    if (!breadcrumbs?.length) {
        return {}
    }

    const url = addSlashToEndUrl(window.location.href)

    const data = {
        '@context': SCHEMA_HELMET_CONTEXT_VALUE,
        '@type': 'BreadcrumbList',
        '@id': `${url}#breadcrumb`,
        itemListElement: [{
            '@type': 'ListItem',
            position: 1,
            item: {
                '@id': SCHEMA_HELMET_DEFAULT_SITE_URL,
                name: homeName,
            },
        }],
    }

    breadcrumbs.forEach(({ name, url }, index) => {
        const id = url?.to
            ? `${SCHEMA_HELMET_DEFAULT_SITE_URL}${url.to}`
            : addSlashToEndUrl(window.location.href)

        const crumbInfo = {
            '@type': 'ListItem',
            position: index + 2,
            item:
                {
                    '@id': id,
                    name,
                },
        }

        data.itemListElement.push(crumbInfo)
    })

    return data
}

const SchemaHelmetBreadcrumbList = ({ breadcrumbs }: {breadcrumbs: Breadcrumb[]}) => {
    const { data: storeConfig } = useStoreConfig()
    const { t } = useTranslation()
    if (!storeConfig) {
        return null
    }

    const data = structuredDataBreadcrumbs(breadcrumbs, t('global.home'))

    return <SchemaHelmet jsonConfig={data} />
}

export default SchemaHelmetBreadcrumbList

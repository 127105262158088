import {
    Breadcrumb,
    Money,
    Product,
    ProductVariant
} from "@pwa-concept/modules/graphql";

export interface IGa4EcommerceProduct {
    product: Product,
    configuredVariant?: Product,
    currentVariant?: ProductVariant
    quantity?: number
    extraFields?: { readonly [key: string]: string }
}

export interface IGa4EcommerceProductOutput {
    item_id: Product['sku'],
    item_name: Product['name'],
    discount: Money['value'],
    item_variant: Product['sku'],
    price: Money['value']
    quantity: number
    item_category?: Breadcrumb['name']
    item_category2?: Breadcrumb['name']
    item_category3?: Breadcrumb['name']
    item_category4?: Breadcrumb['name']
    item_category5?: Breadcrumb['name']
}

export type tGa4ExtractEcommerceCart = (props: IGa4EcommerceProduct) => IGa4EcommerceProductOutput

export const extractGa4EcommerceProduct: tGa4ExtractEcommerceCart = (
    {
        product,
        configuredVariant,
        currentVariant,
        quantity = 1,
        extraFields,
    }
) => {
    const configurableSelectedProductVariant = currentVariant?.product || configuredVariant
    const addedProduct = configurableSelectedProductVariant || product

    const item = {
        item_id: product.sku,
        item_name: addedProduct.name,
        discount: addedProduct.price.initial.value - addedProduct.price.current.value,
        item_variant: configurableSelectedProductVariant?.sku,
        price: addedProduct.price.current.value,
        quantity,
        ...extraFields,
    }

    product.breadcrumbs?.every((crumb, index) => {
        const categoryIndexPostfix = index ? index : ''
        const key = `item_category` + categoryIndexPostfix
        item[key] = crumb.name

        return index + 1 <= 5
    })

    return item
}

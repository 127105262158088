import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { IDefaultHelmet } from '@pwa-onilab/ui/components/elements/DefaultHelmet'
import {
    useStoreConfigBlogHomePageMetaQuery,
} from 'app/modules/store-config/graphql/queries/StoreConfigBlogHomePageMeta'

interface IBlogHomePageMeta {
    data: IDefaultHelmet['meta']
    loading: boolean
}

const useStoreConfigBlogHomePageMeta = (): IBlogHomePageMeta => {
    const { data: { storeConfigBlogHomePageMeta: data } = {}, loading } = useStoreConfigBlogHomePageMetaQuery()
    const { data: storeConfig } = useStoreConfig()

    const meta: IDefaultHelmet['meta'] = {
        ...data,
        ogType: 'article',
    }

    if (storeConfig?.ogArticlePublisher) {
        meta.customProperties = [
            {
                name: 'article:publisher',
                value: storeConfig.ogArticlePublisher,
            },
        ]
    }

    return {
        data: meta,
        loading,
    }
}

export default useStoreConfigBlogHomePageMeta

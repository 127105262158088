export const PROVIDE_GRAPHQL_POLICY = Symbol('PROVIDE_GRAPHQL_POLICY')

export const PROVIDE_GRAPHQL_POSSIBLE_TYPES = Symbol('PROVIDE_GRAPHQL_POSSIBLE_TYPES')

export const PROVIDE_GRAPHQL_LINKS = Symbol('PROVIDE_GRAPHQL_LINKS')

export const PROVIDE_GRAPHQL_SCHEMAS = Symbol('PROVIDE_GRAPHQL_SCHEMAS')

export const PROVIDE_GRAPHQL_RESOLVERS = Symbol('PROVIDE_GRAPHQL_RESOLVERS')

export const PROVIDE_GRAPHQL_STORE_FRAGMENTS = Symbol('PROVIDE_GRAPHQL_STORE_FRAGMENTS')

export const PROVIDE_GRAPHQL_STORE_POSSIBLE_TYPES = Symbol('PROVIDE_GRAPHQL_STORE_POSSIBLE_TYPES')

export const PROVIDE_GRAPHQL_FRAGMENTS = Symbol('PROVIDE_GRAPHQL_FRAGMENTS')

export const PROVIDE_GRAPHQL_INVALID_CUSTOMER_TOKEN_PATHS_EXCLUDE = Symbol('PROVIDE_GRAPHQL_INVALID_CUSTOMER_TOKEN_PATHS_EXCLUDE')

export const PROVIDE_GRAPHQL_CUSTOMER_OPERATIONS_GUARD = Symbol('PROVIDE_GRAPHQL_CUSTOMER_OPERATIONS_GUARD')

export const PROVIDE_GRAPHQL_CUSTOMER_OPERATIONS_GUARD_MIDDLEWARE = Symbol('PROVIDE_GRAPHQL_CUSTOMER_OPERATIONS_GUARD_MIDDLEWARE')

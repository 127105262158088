import { CmsPageBreadcrumbsSkeleton } from '@pwa-onilab/ui/components/modules/CmsPage'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { CmsPageMenuSkeleton } from '@pwa-onilab/ui/components/widgets/CmsPage'
import {
    CmsPageContentSkeleton,
} from '@pwa-onilab/ui/components/widgets/CmsPage/CmsPageContent/CmsPageContent.skeleton'
import { classNames } from '@pwa-onilab/ui/helpers'
import CmsPageWrapper from 'components/overrides/widgets/CmsPage/CmsPageWrapper/CmsPageWrapper'

import styles from './CMSPageDesktop.module.scss'

interface ICMSPageDesktopSkeleton {
    isOneColumnPage?: boolean
}

const CMSPageDesktopSkeleton = ({ isOneColumnPage = true }: ICMSPageDesktopSkeleton) => {
    return (
        <Container>
            <CmsPageBreadcrumbsSkeleton className={styles.breadcrumbs} />
            <CmsPageWrapper
                isOneColumnPage={isOneColumnPage}
                className={classNames(
                    styles.contentMargin,
                    isOneColumnPage && styles.contentOneColumn,
                )}
            >
                <CmsPageMenuSkeleton />
                <CmsPageContentSkeleton />
            </CmsPageWrapper>
        </Container>
    )
}
export { CMSPageDesktopSkeleton }

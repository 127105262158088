import * as Types from '../../../../graphql';

import { gql } from '@apollo/client';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

export type AppWholeSaleFragment = { __typename?: 'StoreConfigWholeSale', descriptionBlock?: string | null, topDescriptionBlock?: string | null, meta?: { __typename?: 'StoreConfigDefaultPageMeta', title?: string | null, keywords?: string | null, description?: string | null, robots?: string | null } | null };

export const AppWholeSaleFragmentDoc = gql`
    fragment AppWholeSale on StoreConfigWholeSale {
  descriptionBlock
  topDescriptionBlock
  meta {
    title
    keywords
    description
    robots
  }
}
    `;
import { MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { FaqWidgetMenu } from '@pwa-onilab/ui/components/widgets/FaqWidget'

import styles from './FaqWidgetSidebar.module.scss'

interface IFaqWidgetSidebarProps {
    items: Array<{
        items: Array<{
            title: string
            description: string
        }>
        title: string
    }>
    activeTitle: string
    setActiveTabHandler: (title: string) => void
}

const FaqWidgetSidebar = (
    {
        items,
        activeTitle,
        setActiveTabHandler,
    }: IFaqWidgetSidebarProps,
) => {
    return (
        <div className={styles.sidebar}>
            <MediaBreakpointUp name="lg">
                <div className={styles.title}>
                    FAQ
                </div>
            </MediaBreakpointUp>
            <FaqWidgetMenu
                items={items}
                activeTitle={activeTitle}
                setActiveTabHandler={setActiveTabHandler}
            />
        </div>
    )
}

export default FaqWidgetSidebar


    var doc = {"kind":"Document","definitions":[{"kind":"EnumTypeExtension","name":{"kind":"Name","value":"CmsRouteType"},"directives":[],"values":[{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"BLOG_POST"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"BLOG_CATEGORY"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"HOMEPAGE_BLOG"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"FAMILY_STRAIN"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"HOMEPAGE_FAMILY_STRAIN"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"LOCATION"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"LOCATION_STATE"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"CATEGORIES_AS_STRAINS"},"directives":[]}]},{"kind":"ObjectTypeExtension","name":{"kind":"Name","value":"CmsRoute"},"interfaces":[],"directives":[],"fields":[{"kind":"FieldDefinition","name":{"kind":"Name","value":"customId"},"arguments":[],"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"displayMode"},"arguments":[],"type":{"kind":"NamedType","name":{"kind":"Name","value":"DisplayMode"}},"directives":[]}]}],"loc":{"start":0,"end":254}};
    doc.loc.source = {"body":"extend enum CmsRouteType {\n    BLOG_POST\n    BLOG_CATEGORY\n    HOMEPAGE_BLOG\n    FAMILY_STRAIN\n    HOMEPAGE_FAMILY_STRAIN\n    LOCATION\n    LOCATION_STATE\n    CATEGORIES_AS_STRAINS\n}\n\nextend type CmsRoute {\n    customId: ID\n    displayMode: DisplayMode\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    

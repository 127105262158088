import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { HOME_ROUTE } from '@pwa-onilab/ui/constants'
import { PageIntroBlockMobile } from 'elements/BlogStatePages/elements'

const BlockPageIntroBlockMobile = ({ post }) => {
    const { data: { blogHomepageUrlKey } = {} } = useStoreConfig()

    return (
        <PageIntroBlockMobile
            backBtnUrlKey={blogHomepageUrlKey || HOME_ROUTE}
            title={post.title}
            author={post.author}
            createdAt={post.publishTime}
            imageSrc={post?.featuredImage || post?.thumbnail}
        />
    )
}

export default BlockPageIntroBlockMobile

import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import SubscriptionContent
    from 'components/overrides/modules/Footer/elements/Subscription/SubscriptionContent/SubscriptionContent'
import SubscriptionForm
    from 'components/overrides/modules/Footer/elements/Subscription/SubscriptionForm/SubscriptionForm'
import { memo } from 'react'

import styles from './Subscription.module.scss'

const Subscription = ({ className }: IClassName) => {
    return (
        <div className={classNames(styles.wrapper, className)}>
            <SubscriptionContent />
            <SubscriptionForm />
        </div>
    )
}

export default memo(Subscription)

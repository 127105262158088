import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
const ErrorPageHelmet = () => {
    const { t } = useTranslation()
    const { data: storeConfig } = useStoreConfig()
    const webSiteTitleName = storeConfig?.defaultTitle
    const webSiteTitle = `${webSiteTitleName} - ${t('errorPage.meta.title')}`

    return (
        <Helmet>
            <title>
                {webSiteTitle}
            </title>
            <meta name="title" content={webSiteTitle} />
            <meta name="description" content={t('errorPage.meta.description')} />
            <meta name="keywords" content={t('errorPage.meta.keywords')} />
        </Helmet>
    )
}

export default ErrorPageHelmet

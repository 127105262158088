
    var doc = {"kind":"Document","definitions":[{"kind":"ObjectTypeExtension","name":{"kind":"Name","value":"Mutation"},"interfaces":[],"directives":[],"fields":[{"kind":"FieldDefinition","name":{"kind":"Name","value":"cartSetBillingAddress"},"arguments":[{"kind":"InputValueDefinition","name":{"kind":"Name","value":"input"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"SetBillingAddressOnCartInput"}},"directives":[]}],"type":{"kind":"NamedType","name":{"kind":"Name","value":"Cart"}},"directives":[]}]},{"kind":"InputObjectTypeDefinition","name":{"kind":"Name","value":"SetBillingAddressOnCartInput"},"directives":[],"fields":[{"kind":"InputValueDefinition","name":{"kind":"Name","value":"billingAddress"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"BillingAddressInput"}},"directives":[]}]},{"kind":"InputObjectTypeDefinition","name":{"kind":"Name","value":"BillingAddressInput"},"directives":[],"fields":[{"kind":"InputValueDefinition","name":{"kind":"Name","value":"address"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"CartAddressInput"}},"directives":[]},{"kind":"InputValueDefinition","name":{"kind":"Name","value":"customerAddressId"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}},"directives":[]},{"kind":"InputValueDefinition","name":{"kind":"Name","value":"sameAsShipping"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}},"directives":[]},{"kind":"InputValueDefinition","name":{"kind":"Name","value":"useForShipping"},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}},"directives":[]}]}],"loc":{"start":0,"end":382}};
    doc.loc.source = {"body":"extend type Mutation {\n    cartSetBillingAddress(input: SetBillingAddressOnCartInput): Cart\n}\n\ninput SetBillingAddressOnCartInput {\n    billingAddress: BillingAddressInput,\n}\n\ninput BillingAddressInput {\n    address: CartAddressInput # CartAddressInput placed in CartSetShippingAddress.graphql\n    customerAddressId: Int,\n    sameAsShipping: Boolean,\n    useForShipping: Boolean,\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    

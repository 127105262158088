import BackButton from '@pwa-onilab/ui/components/elements/Buttons/BackButton'
import { MediaBreakpointDown } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { PropsWithChildren } from 'react'

import styles from './AuthMobileHeader.module.scss'

interface AuthMobileHeaderProps {
    children?: string
}

const AuthMobileHeader = ({ children }: PropsWithChildren<AuthMobileHeaderProps>) => {
    return (
        <MediaBreakpointDown name="lg">
            <Container isRevert>
                <div className={styles.wrapper}>
                    <BackButton className={styles.backButton} />
                    {children}
                </div>
            </Container>

        </MediaBreakpointDown>
    )
}

export default AuthMobileHeader

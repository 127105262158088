import { PricePartProps } from '@pwa-onilab/ui/components/modules/Product/elements/ProductPrice/ProductPrice'
import Price from '@pwa-onilab/ui/components/UI/Price'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './PriceWithDiscount.module.scss'

const PriceWithDiscount = (
    {
        value,
        isSearch,
        isProductPage,
        isSuccesAddToCartModal,
        className,
    }: PricePartProps) => {
    const classes = [styles.wrapper]
    if (isSearch) {
        classes.push(styles.search)
    }
    if (isProductPage) {
        classes.push(styles.productPage)
    }
    if (isSuccesAddToCartModal) {
        classes.push(styles.successAddToCartModal)
    }
    if (className) classes.push(className)
    return (
        <div className={classNames(...classes)}>
            <Price>
                {value}
            </Price>
        </div>
    )
}

export default PriceWithDiscount

import { useDeleteCustomerAccountMutation } from '@pwa-concept/modules/customer/graphql/mutations/DeleteCustomerAccount'

const useDeleteCustomerAccount = () => {
    const [deleteAccount] = useDeleteCustomerAccountMutation()

    return async (deleteCode: string) => {
        const { data: { deleteCustomerAccount } = {} } = await deleteAccount({
            variables: {
                deleteCode,
            },
            ignoreResults: true,
        })

        return deleteCustomerAccount
    }
}

export default useDeleteCustomerAccount

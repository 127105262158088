import useRouterConfig from '@pwa-concept/core/router/useRouterConfig'
import { FC } from 'react'
import {
    createBrowserRouter,
    RouterProvider as RouterProviderDom,
} from 'react-router-dom'

const RouterProvider: FC = ({ children }) => {
    const routerConfig = useRouterConfig(children)
    const router = createBrowserRouter(routerConfig)

    return <RouterProviderDom router={router} />
}

export default RouterProvider

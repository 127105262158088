import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { createPortal } from 'react-dom'

import styles from './Overlay.module.scss'

interface OverlayProps extends IClassName {
    onClick?: () => void
    additionalStyle?: object
}

const Overlay = (
    {
        onClick,
        additionalStyle,
        className,
    }: OverlayProps) => createPortal(
        <div
            onClick={onClick}
            className={classNames(styles.overlay, className)}
            style={additionalStyle}
        />,
        document.body,
)

export default Overlay

import { DEFAULT_LOCALE_CODE } from '@pwa-onilab/ui/constants/app'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { resourcesLocal } from './i18n'

/**
 * Initialize the i18n object for our app
 * Also loads our translation files
 */

i18n.use(initReactI18next)
    .init({
        lng: DEFAULT_LOCALE_CODE,
        debug: false,
        nsSeparator: false,
        keySeparator: false,
        fallbackLng: DEFAULT_LOCALE_CODE,
        resources: resourcesLocal,
    })

export default i18n

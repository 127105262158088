import Title from '@pwa-onilab/ui/components/UI/Title'
import { useTranslation } from 'react-i18next'

import styles from './StrainFamiliesTitle.module.scss'

const StrainFamiliesTitle = () => {
    const { t } = useTranslation()

    return (
        <Title
            text={t('strains.homepage.title')}
            type="h1"
            className={styles.title}
        />
    )
}

export default StrainFamiliesTitle

import {
    formatInputRangeFieldEvent,
    getValuesForInputRangeMaxChanges,
    restoreFilterPriceInputCarriage,
} from '@pwa-onilab/ui/components/elements/Inputs/InputRange/helpers'
import useInputRangeRefValueListener from '@pwa-onilab/ui/components/elements/Inputs/InputRange/hooks'
import { InputRangeField } from '@pwa-onilab/ui/components/elements/Inputs/InputRange/IInputRange'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import css from '../../InputRange.module.scss'

const InputRangeMax = (
    {
        min,
        max,
        onFinalChange,
        onChange: onChangeProp,
        currentMax,
        currentMin,
    }: InputRangeField) => {
    const { t } = useTranslation()

    const inputRef = useRef<HTMLInputElement | null>(null)
    const lastKeyCode = useRef<number | null>(null)

    const onKeyDown = (event) => {
        lastKeyCode.current = event.keyCode
    }

    const onChange = (event) => {
        if (!isFinite(event.target.value)) {
            restoreFilterPriceInputCarriage(event, currentMax)
            return
        }
        formatInputRangeFieldEvent(event, lastKeyCode.current, currentMin, max)
        onChangeProp(getValuesForInputRangeMaxChanges(event, currentMin))
    }

    const onBlur = (ev) => {
        onFinalChange?.(getValuesForInputRangeMaxChanges(ev, currentMin))
        lastKeyCode.current = null
    }

    useInputRangeRefValueListener(inputRef, currentMax)

    return (
        <div className={classNames(css.fieldsInput, css.fieldsTo)}>
            <span className={css.label}>
                {t('global.to')}
            </span>
            <label className={css.currencyWrapper}>
                <input
                    ref={inputRef}
                    type="text"
                    min={min}
                    max={max}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    onChange={onChange}
                    className={css.input}
                    placeholder={String(currentMax)}
                />
            </label>
        </div>
    )
}

export default InputRangeMax

import { useCustomerOrders } from '@pwa-concept/modules/customer-orders'
import { Pagination } from '@pwa-concept/modules/graphql'
import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { ORDERS_PAGE_SIZE } from '@pwa-onilab/ui/components/modules/AccountSubpages'
import { useCallback, useState } from 'react'

const AccountOrderHistory = () => {
    const [page, setPage] = useState<number>(1)
    // TODO use apollo optimistic response
    const [optimisticPagination, setOptimisticPagination] = useState<Pagination | null>(null)

    const { isBreakpointDown } = useMediaBreakpoint()

    const {
        data: {
            items: orders = [],
            pagination,
        } = {},
        loading,
    } = useCustomerOrders({ page, size: ORDERS_PAGE_SIZE })

    const handlePageClick = useCallback((page: number) => {
        if (isBreakpointDown('lg')) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }

        setPage(page)
        setOptimisticPagination({
            current: page,
            total: pagination?.total || optimisticPagination.total,
        })
    }, [pagination, optimisticPagination])

    const isCustomerWithOrders = !!orders?.length
    const isShowPagination = !loading || optimisticPagination

    return {
        orders,
        loading,
        handlePageClick,
        isCustomerWithOrders,
        isShowPagination,
        pagination: pagination || optimisticPagination,
    }
}

export default AccountOrderHistory

import { Wishlist } from '@pwa-concept/modules/graphql'
import { CategoryProductCard, CategoryProductCardSkeleton } from '@pwa-onilab/ui/components/modules/Product'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useRef } from 'react'

import styles from './WishlistItems.module.scss'

interface WishlistItemsProps {
    className: string
    wishlist: Wishlist
    loading: boolean
    addToCardHandler: (product) => void
}

// TODO add pagination after patching
const WishlistItems = (
    {
        className,
        wishlist,
        loading,
        addToCardHandler,
    }: Partial<WishlistItemsProps>,
) => {
    const topElementRef = useRef()

    if (!wishlist?.items?.items && !loading) return null

    // const wishlistData = {
    //     current: wishlist?.items?.page_info?.current_page,
    //     total: wishlist?.items?.page_info?.total_pages,
    //     limit: wishlist?.items?.page_info?.page_size,
    //     count: wishlist?.items_count,
    // }

    // const onLoadPageWishlistPagination = useCallback(async (page, _, isPage) => {
    //     if (isPage) {
    //         topElementRef?.current?.scrollIntoView({
    //             behavior: 'smooth',
    //             block: 'start',
    //         })
    //     }
    //
    //     await getWishlistData(page)
    // }, [getWishlistData])

    return (
        <div className={classNames(styles.wrapper, className)}>
            <div ref={topElementRef} />
            {/* {wishlistData.current > 1 && ( */}
            {/*    <PaginationFloat */}
            {/*        data={wishlistData} */}
            {/*        onLoadPage={onLoadPageWishlistPagination} */}
            {/*    /> */}
            {/* )} */}
            <div>
                <div className={classNames(styles.list, className)}>
                    {
                        wishlist?.items?.items?.map(({ product }) => (
                            <CategoryProductCard
                                key={product?.id}
                                product={product}
                                className={styles.card}
                                addToCardHandler={addToCardHandler}
                            />
                        ))
                    }
                    {loading && <CategoryProductCardSkeleton className={styles.card} skeletonCount={20} />}
                </div>
            </div>

            {/* {wishlist?.items?.page_info?.total_pages === 1 && ( */}
            {/*    <PaginationLoadMore */}
            {/*        data={wishlistData} */}
            {/*        onLoadPage={onLoadPageWishlistPagination} */}
            {/*    /> */}
            {/* )} */}
        </div>
    )
}

export default WishlistItems

import { useCustomerOrder } from '@pwa-concept/modules/customer-orders'
import { CartBillingAddress, CustomerOrderAddress } from '@pwa-concept/modules/graphql'
import { IOrderComponent, OrderAddress } from '@pwa-onilab/ui/components/modules/Order'
import { useTranslation } from 'react-i18next'

interface IOrderBillingAddress extends IOrderComponent{
    defaultBillingAddress?: CustomerOrderAddress | CartBillingAddress
}

const OrderBillingAddress = ({ orderNumber, className, defaultBillingAddress }: IOrderBillingAddress) => {
    const { data: { billingAddress } = {}, loading } = useCustomerOrder(orderNumber)
    const { t } = useTranslation()

    return (
        <OrderAddress
            address={billingAddress || defaultBillingAddress}
            title={t('Billing address')}
            className={className}
            loading={!defaultBillingAddress && loading}
        />
    )
}

export default OrderBillingAddress

import { useLazyQuery } from '@apollo/client'
import { GetProductReviewsQuery } from '@pwa-onilab/ui/schemas'

const useProductReviews = () => {
    const [getProductReviews, { loading }] = useLazyQuery(GetProductReviewsQuery)

    return {
        getProductReviews,
        loading,
    }
}

export default useProductReviews

import Flex from '@pwa-onilab/ui/components/elements/Flex/Flex'
import { MediaBreakpointDown, MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import Container from '@pwa-onilab/ui/components/UI/Container'
import { NewArrivalSlideSkeleton } from '@pwa-onilab/ui/components/widgets/NewArrival/NewArrivalSlide'
import { IWidgetHeaderSkeletonProps, WidgetHeaderSkeleton } from '@pwa-onilab/ui/components/widgets/WidgetHeader'
import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './NewArrival.module.scss'

const NewArrivalSkeleton = (
    {
        classNameWrapper,
        isSubtitle = false,
        isTo = false,
    }: IWidgetHeaderSkeletonProps,
) => (
    <section className={classNames(styles.wrapper, classNameWrapper)}>
        <Container>
            <WidgetHeaderSkeleton isSubtitle={isSubtitle} isTo={isTo} />
            <Container isRevert>
                <Flex
                    direction="row" justifyContent="spaceBetween"
                    className={classNames(styles.itemsListWrapper)}
                >
                    <MediaBreakpointDown name="lg">
                        <NewArrivalSlideSkeleton />
                    </MediaBreakpointDown>
                    <MediaBreakpointUp name="lg">
                        <NewArrivalSlideSkeleton />
                        <NewArrivalSlideSkeleton />
                    </MediaBreakpointUp>
                </Flex>
            </Container>
        </Container>
    </section>
)

export { NewArrivalSkeleton }

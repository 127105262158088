import Field from '@pwa-onilab/ui/components/UI/Field'
import { Input, InputProps } from '@pwa-onilab/ui/components/UI/Input'

interface IInputField extends InputProps {
    placeholder: string
    inputBoxClass?: string
    inputErrorClass?: string
}

const InputField = (
    {
        onChange,
        setValue,
        type = 'text',
        className,
        field,
        register,
        rules,
        errors,
        placeholder,
        inputBoxClass,
        inputErrorClass,
        ...restProps
    }: IInputField) => {
    return (
        <Field inputBoxClass={inputBoxClass} required={!!rules?.required} label={placeholder}>
            <Input
                type={type}
                field={field}
                placeholder={placeholder}
                register={register}
                setValue={setValue}
                onChange={onChange}
                errors={errors}
                rules={rules}
                inputErrorClass={inputErrorClass}
                className={className}
                {...restProps}
            />
        </Field>
    )
}

export default InputField

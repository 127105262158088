import { CustomerServiceButton, CustomerServiceModalMenu } from 'modules/Footer/CustomerService'
import useModalStates from '@pwa-onilab/ui/hooks/modals/useModalStates'

interface ICustomerServiceProps {
    items: Array<{
        content: {
            urlPath: string
        }
        nodeId: number
        title: string
    }>
}

const CustomerService = (
    {
        items,
    }: ICustomerServiceProps,
) => {
    const {
        isModalOpen,
        openModal,
        closeModal,
    } = useModalStates()

    return (
        <>
            <CustomerServiceButton openModal={openModal} />
            <CustomerServiceModalMenu
                closeModal={closeModal}
                isModalOpen={isModalOpen}
                items={items}
            />
        </>
    )
}

export default CustomerService

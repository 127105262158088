import { ISelectField } from '@pwa-onilab/ui/components/elements/Fields/SelectField'
import Field from '@pwa-onilab/ui/components/UI/Field'
import Select from '@pwa-onilab/ui/components/UI/Select'

// TODO handle errors
const SelectField = (
    {
        options,
        field,
        className,
        register,
        onValueChange,
        rules,
        placeholder,
        defaultValue,
        errors,
    }: ISelectField) => {
    return (
        <Field label={placeholder} required={!!rules?.required}>
            <Select
                options={options}
                name={field}
                className={className}
                register={register}
                rules={rules}
                onValueChange={onValueChange}
                defaultValue={defaultValue}
            />
        </Field>
    )
}

export default SelectField

import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { PropsWithChildren } from 'react'

import styles from './CmsPageWrapper.module.scss'

interface ICmsPageWrapper extends IClassName {
    isOneColumnPage?: boolean
}

const CmsPageWrapper = (
    {
        children,
        className,
        isOneColumnPage = true,
    }: PropsWithChildren<ICmsPageWrapper>,
) => (
    <div className={classNames(
        styles.wrapper,
        isOneColumnPage && styles.oneColumnWrapper,
        className)}
    >
        {children}
    </div>
)

export default CmsPageWrapper

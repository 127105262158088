import { classNames } from '@pwa-onilab/ui/helpers'
import { memo } from 'react'

import styles from './WidgetHeaderTitle.module.scss'

export interface IWidgetHeaderTitleProps {
    title: string
    className: string
}

const WidgetHeaderTitle = (
    {
        title,
        className,
    }: Partial<IWidgetHeaderTitleProps>,
) => {
    if (!title) return null

    return (
        <h2 className={classNames(styles.title, className)}>
            {title}
        </h2>
    )
}

export default memo(WidgetHeaderTitle)

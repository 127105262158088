import { Product } from '@pwa-concept/modules/graphql'
import { ga4SelectItem } from '@pwa-onilab/ga4/operations/product/selectItem'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { useCustomProductUrl } from 'hooks'

import styles from './PopularProductsSlideAnchor.module.scss'

const PopularProductsSlideAnchor = ({ product }: { product: Product}) => {
    const { name, url, sku } = product
    const {
        productLinkState,
        relativeUrl,
    } = useCustomProductUrl(url, sku)

    const sendAnalytics = () => {
        ga4SelectItem({
            itemsListName: 'widgets popular products slider',
            product,
        })
    }

    return (
        <Anchor
            key={url}
            to={relativeUrl}
            state={productLinkState}
            className={styles.productLink}
            onClick={sendAnalytics}
        >
            {name}
        </Anchor>
    )
}

export default PopularProductsSlideAnchor

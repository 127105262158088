import useCartReorderItems from '@pwa-concept/modules/cart/features/reorder-items/hooks/useCartReorderItems'
import { APOLLO_ERROR_TYPES, APOLLO_REORDER_ERRORS } from '@pwa-concept/modules/constants'
import { useCustomerOrder } from '@pwa-concept/modules/customer-orders'
import { getApolloErrorType } from '@pwa-concept/modules/helpers'
import { ReorderSuccessToast } from '@pwa-onilab/ui/components/modules/Order'
import { throwSuccessToastComponent } from '@pwa-onilab/ui/helpers'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

// TODO wait count_added_items on backend, filter errors items by sku
const useReorderButton = (orderNumber: string) => {
    const { t } = useTranslation()
    const { data: { products: orderProducts = [] } = { }, loading: orderLoading } = useCustomerOrder(orderNumber)

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const makeReorder = useCartReorderItems()

    const onClick = useCallback(async () => {
        try {
            setIsLoading(true)
            const { data: { errors } = {} } = await makeReorder(orderNumber)
            throwSuccessToastComponent(ReorderSuccessToast)
            errors.forEach((errorObj) => {
                const apolloErrorType = getApolloErrorType(errorObj.message, APOLLO_REORDER_ERRORS)
                const isNotAddedProduct = apolloErrorType === APOLLO_ERROR_TYPES.REORDER_NOT_ADD_PRODUCT

                if (isNotAddedProduct) {
                    // get error in format:
                    // Could not add the product with SKU "732013088033" to the shopping cart:
                    // The requested qty is not available
                    const errorStr = errorObj.message
                    const skuFromMessage = errorStr.substring(errorStr.indexOf('"') + 1, errorStr.lastIndexOf('"'))
                    const product = orderProducts.find((orderProduct) => orderProduct.sku === skuFromMessage)

                    toast.error(t('AccountPage.orders.reorder.errors.notAddedProduct', {
                        productName: product.name,
                        quantity: product.quantityOrdered,
                    }))
                } else {
                    toast.error(errorObj.message)
                }
            })
        } catch (error) {
            toast.error(error.message)
        } finally {
            setIsLoading(false)
        }
    }, [orderProducts])

    return {
        onClick,
        isButtonDisabled: isLoading || orderLoading,
    }
}

export default useReorderButton

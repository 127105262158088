import { useCms } from '@pwa-concept/modules/cms'
import Modal from '@pwa-onilab/ui/components/elements/Modal'
import Parser, { classNames } from '@pwa-onilab/ui/helpers'

import styles from './NavigationCmsContentModal.module.scss'

interface INavigationCmsContentModalProps {
    className: string
    isOpen: boolean
    onClose: () => void
    cmsId: string
}

const NavigationCmsContentModal = (
    {
        className,
        isOpen,
        onClose,
        cmsId,
    }: Partial<INavigationCmsContentModalProps>,
) => {
    if (!cmsId) return null

    const {
        data,
        loading,
    } = useCms({ id: cmsId })

    return (
        <Modal
            isDraggable
            isOpen={isOpen}
            onDismiss={onClose}
            className={classNames(className)}
            wrapperClassName={styles.modalWrapper}
            isOverAll
        >
            {
                loading ? (
                    <>
                        {/* TODO: placeholder */}
                        Loading...
                    </>
                ) : (
                    <Parser content={data?.content || ''} />
                )
            }
        </Modal>
    )
}

export default NavigationCmsContentModal

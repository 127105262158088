import { ErrorPage } from '@pwa-onilab/ui/components/modules/ErrorPage'
import { useTranslation } from 'react-i18next'

const Error404Page = () => {
    const { t } = useTranslation()

    return (
        <ErrorPage errorText={t('global.errors.pageNotFound')} />
    )
}

export default Error404Page

import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import useModalStates from '@pwa-onilab/ui/hooks/modals/useModalStates'
import { useCallback, useState } from 'react'

const useFaqAccordion = (items) => {
    const { isBreakpointUp, isBreakpointDown } = useMediaBreakpoint()

    const { isModalOpen, closeModal, openModal } = useModalStates()
    const { isModalOpen: isSearchModalOpen, closeModal: closeSearchModal, openModal: openSearchModal } = useModalStates()

    const [isTabChoosen, setTabChoosen] = useState(false)
    const [isTabFromSearch, setTabFromSearch] = useState(false)
    const [activeTab, setActiveTab] = useState(isBreakpointUp('lg') ? items?.[0] : '')

    const createTabFromSearch = useCallback((newTab, itTabFromSearch = true) => {
        setTabFromSearch(itTabFromSearch)

        setActiveTab(newTab)
    }, [])

    const rollbackToTab = useCallback(() => {
        setTabChoosen(false)
    }, [])

    const setActiveTabHandler = useCallback((newTab) => {
        setTabChoosen(true)

        setActiveTab(newTab)

        openModal()
    }, [])

    const onCloseModalHandler = useCallback(() => {
        if (isBreakpointDown('lg')) {
            setActiveTab('')
        }

        closeModal()
    }, [])

    return {
        isModalOpen,
        isSearchModalOpen,
        closeSearchModal,
        openSearchModal,
        isTabChoosen,
        isTabFromSearch,
        activeTab,
        createTabFromSearch,
        rollbackToTab,
        setActiveTabHandler,
        onCloseModalHandler,
    }
}

export default useFaqAccordion

import { extractFieldsFromUpdateCustomerAddressInput, updateCustomerAddressMutation }
    from '@pwa-concept/modules/customer-addresses/graphql/stores/magento2/resolvers/Mutation/helpers/updateAddress'
import { MutationResolvers } from '@pwa-concept/modules/graphql'

const updateCustomerAddress: MutationResolvers['updateCustomerAddress'] = async (_, input) => {
    const { id, input: address } = input

    const inputFields = extractFieldsFromUpdateCustomerAddressInput(address)

    const { data: { updateCustomerAddress: __context = null } = {} } = await updateCustomerAddressMutation(id, inputFields)

    if (!__context) return null

    return {
        __context,
        __typename: 'CustomerAddress',
    }
}

export default updateCustomerAddress

import { ProductsQueryInput } from '@pwa-concept/modules/graphql'
import { useProductsQuery } from '@pwa-concept/modules/product/graphql/queries/Products'

interface UseProductsInput extends Omit<ProductsQueryInput, 'id' | 'sku' | 'url'> {
    id?: Array<ProductsQueryInput['id']> | ProductsQueryInput['id']
    sku?: Array<ProductsQueryInput['sku']> | ProductsQueryInput['sku']
    url?: Array<ProductsQueryInput['url']> | ProductsQueryInput['url']
}

interface UseProductsOptions {
    skip?: boolean
}

const getFilterArray = (possibleArray) => {
    const array = Array.isArray(possibleArray) ? possibleArray : [possibleArray]
    return array.filter(Boolean)
}

const useProducts = (
    {
        id = null,
        sku = null,
        url = null,
        ...args
    }: UseProductsInput = {}, options?: UseProductsOptions,
) => {
    const { data, loading } = useProductsQuery({
        variables: {
            input: {
                ...args,
                ...(id && { id: getFilterArray(id) }),
                ...(sku && { sku: getFilterArray(sku) }),
                ...(url && { url: getFilterArray(url) }),
            },
        },
        skip: options?.skip,
    })

    return {
        data: data?.products,
        loading,
    }
}

export default useProducts

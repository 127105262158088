import { CustomerOrderProductPrice } from '@pwa-concept/modules/graphql'
import { ProductPrice } from '@pwa-onilab/ui/components/modules/Product/elements'
import { PriceProps } from '@pwa-onilab/ui/components/modules/Product/elements/ProductPrice/ProductPrice'

import styles from './OrderSummaryItemPrice.module.scss'

interface IOrderSummaryItemPrice {
    prices: CustomerOrderProductPrice
    quantity: PriceProps['productQuantity']
}

const OrderSummaryItemPrice = ({ prices, quantity }: IOrderSummaryItemPrice) => {
    return (
        <ProductPrice
            className={styles.productPrice}
            priceWithDiscountClassName={styles.priceWithDiscount}
            initialPriceClassName={styles.initialPrice}
            pricePerItemClassName={styles.perItem}
            price={{
                current: prices.finalPrice,
                initial: prices.originalPrice,
            }}
            productQuantity={quantity}
        />
    )
}

export default OrderSummaryItemPrice

import { StoreFragments } from '@pwa-concept/core'

import ConfigurableProductOptions from './ConfigurableProductOptions.graphql'
import CustomizableOptionInterface from './CustomizableOptionInterface.graphql'

import PriceRange from './PriceRange.graphql'

import ProductInterface from './ProductInterface.graphql'
import ProductInterfaceNonRequiredFields from './ProductInterfaceNonRequiredFields.graphql'

const fragments: StoreFragments = [
    ConfigurableProductOptions,
    CustomizableOptionInterface,
    PriceRange,
    ProductInterface,
    ProductInterfaceNonRequiredFields,
]

export default fragments

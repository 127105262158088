import { useCmsBlocks } from '@pwa-concept/modules/cms'
import ParserBounded from '@pwa-onilab/ui/components/elements/ParserBounded/ParserBounded'

interface IStrainsForSaleProductsProps {
    cms: string
}
const StrainsForSaleCms = (
    {
        cms
    }: IStrainsForSaleProductsProps,
) => {

    const { data, loading } = useCmsBlocks({
        variables: { identifiers: [cms] },
        skip: !cms,
    })

    const cmsBlock = data?.items?.[0]

    if (!cmsBlock) {
        return null
    }

    return <ParserBounded content={cmsBlock.content} />
}

export default StrainsForSaleCms

import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import {
    Group,
    Navigation,
    useFooterMenu,
} from '@pwa-onilab/ui/components/modules/Footer'
import { IFooterMenuOptions } from '@pwa-onilab/ui/components/modules/Footer/hooks/useFooterMenu'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import { CUSTOMER_SERVICE } from 'constants/customFooterLinks'
import { CustomerService } from 'modules/Footer/CustomerService'
import { memo } from 'react'

const FOOTER_MENU_QUERY_OPTIONS: IFooterMenuOptions = {
    fetchPolicy: 'no-cache',
}

const FooterMenu = (
    {
        className,
    }: IClassName,
) => {
    const { menu } = useFooterMenu({ options: FOOTER_MENU_QUERY_OPTIONS })
    const { isBreakpointDown } = useMediaBreakpoint()

    if (!menu?.nodes?.length) return null

    return (
        <>
            {
                menu.nodes.map(({ nodeId, nodes, title }) => {
                    if (title === CUSTOMER_SERVICE && isBreakpointDown('lg')) {
                        return (
                            <CustomerService key={nodeId} items={nodes} />
                        )
                    }

                    return (
                        <Group
                            key={nodeId}
                            title={title}
                            className={className}
                        >
                            <Navigation items={nodes} />
                        </Group>
                    )
                })
            }
        </>
    )
}

export default memo(FooterMenu)

import { Product } from '@pwa-concept/modules/graphql'
import { ProductBadges } from '@pwa-onilab/ui/components/modules/Product/elements'
import Anchor from '@pwa-onilab/ui/components/UI/Anchor'
import { ImageLazy } from '@pwa-onilab/ui/components/UI/Image/ImageLazy'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IClassName } from '@pwa-onilab/ui/interfaces'
import ProductPrice from 'components/overrides/modules/Product/elements/ProductPrice/ProductPrice'
import { useCustomProductUrl } from 'hooks'
import { CategoryProductCardAttribute } from 'modules/Product'
import CategoryProductCardAttributes
    from 'modules/Product/CategoryProductCard/CategoryProductCardAttributes/CategoryProductCardAttributes'

import styles from './SearchProductCard.module.scss'
import { checkForSaleLabel } from 'modules/Product/utils/saleLabelUtils'
import { SALE_LABEL } from 'constants/modules/labelsProduct'

interface ISearchProductCard extends IClassName {
    product: Product
}

const SearchProductCard = (
    {
        className,
        product,
    }: ISearchProductCard,
) => {
    const {
        sku,
        name,
        url,
        thumbnail: { url: imageUrl, description: imageAlt } = {},
        actualPricePerSeed,
        originalPricePerSeed,
        labels,
        thcText,
        textAttributes: {
            farvalue,
            sipValueText,
        } = {},
    } = product

    const {
        productLinkState,
        relativeUrl,
    } = useCustomProductUrl(url, sku)

    const attributes: string[] = [
        farvalue,
        thcText,
        sipValueText,
    ]

    const labelsNew = []

    if (labels === null) {
        checkForSaleLabel(product?.variants, labelsNew, SALE_LABEL)
    } else {
        checkForSaleLabel(product?.variants, labels, SALE_LABEL)
    }

    return (
        <Anchor
            className={classNames(styles.wrapper, className)}
            state={productLinkState}
            to={relativeUrl}
        >
            <ImageLazy
                src={imageUrl}
                alt={imageAlt}
                className={styles.image}
                loaderClassName={styles.image}
            />
            <ProductBadges data={labels || labelsNew} className={styles.badgeWrapper} />
            <div className={styles.mainDataWrapper}>
                <p className={styles.name}>{name}</p>
                <CategoryProductCardAttributes data={attributes} />
                <CategoryProductCardAttribute className={styles.priceWrapper}>
                    <ProductPrice
                        actualPricePerSeed={actualPricePerSeed}
                        originalPricePerSeed={originalPricePerSeed}
                        isProductPricePerSeed
                        withFromLabel
                    />
                </CategoryProductCardAttribute>
            </div>
        </Anchor>
    )
}

export default SearchProductCard

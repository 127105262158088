import Title, { ITitle } from '@pwa-onilab/ui/components/UI/Title'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useTranslation } from 'react-i18next'

import styles from './HelpTitle.module.scss'

const HelpTitle = (
    {
        className,
        type = 'h5',
    }: Pick<Partial<ITitle>, 'type' | 'className'>,
) => {
    const { t } = useTranslation()

    return (
        <Title
            type={type}
            className={classNames(styles.title, className)}
            text={t('global.canHelp')}
        />
    )
}

export default HelpTitle

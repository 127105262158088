import { classNames } from '@pwa-onilab/ui/helpers'

import styles from './ProductConfiguredOption.module.scss'

interface IProductConfiguredOption {
    valueLabel: string | number
    optionLabel: string
    wrapperClassName?: string
}

const ProductConfiguredOption = ({ valueLabel, optionLabel, wrapperClassName }: IProductConfiguredOption) => {
    return (
        <div className={classNames(styles.option, wrapperClassName)}>
            <span className={styles.optionName}>{optionLabel}: </span>
            <span>{valueLabel}</span>
        </div>
    )
}

export default ProductConfiguredOption

import * as Types from '../../../../graphql';

import { gql } from '@apollo/client';

/**
* NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
* USE `yarn cli codegen`.
*/
/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

export type BlogAuthorFragmentFragment = { __typename?: 'BlogAuthor', id?: number | null, urlKey?: string | null, imageUrl?: string | null, thumbnailImageUrl?: string | null, name?: string | null, description?: string | null, socialUrls?: { __typename?: 'SocialUrls', facebook?: string | null, instagram?: string | null, linkedin?: string | null, pinterest?: string | null, twitter?: string | null, youtube?: string | null } | null, meta?: { __typename?: 'BlogAuthorMeta', title?: string | null, keywords?: string | null, description?: string | null, robots?: string | null } | null, categories?: Array<{ __typename?: 'BlogCategory', id?: string | null, title?: string | null } | null> | null };

export const BlogAuthorFragmentFragmentDoc = gql`
    fragment BlogAuthorFragment on BlogAuthor {
  id
  urlKey
  imageUrl
  thumbnailImageUrl
  name
  description
  socialUrls @include(if: $isFull) {
    facebook
    instagram
    linkedin
    pinterest
    twitter
    youtube
  }
  meta @include(if: $isFull) {
    title
    keywords
    description
    robots
  }
  categories @include(if: $isFull) {
    id
    title
  }
}
    `;
import { useCartSetBillingAddressMutation } from '@pwa-concept/modules/cart/graphql/mutations/CartSetBillingAddress'
import { SetBillingAddressOnCartInput } from '@pwa-concept/modules/graphql'

// TODO seems like SetBillingAddressOnCartInput doesn't follow theme code style
const useCartSetBillingAddress = () => {
    const [setBillingAddress] = useCartSetBillingAddressMutation()

    return async (billingAddress: SetBillingAddressOnCartInput['billingAddress']) => {
        return await setBillingAddress({
            variables: {
                input: {
                    billingAddress,

                },
            },
            ignoreResults: true,
        })
    }
}

export default useCartSetBillingAddress

import { SearchHistory, SearchList } from '@pwa-onilab/ui/components/modules/Search'
import { ISearchCategory } from '@pwa-onilab/ui/components/modules/Search/hooks/ISearchCategory'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useTranslation } from 'react-i18next'

import styles from './SearchResultEmpty.module.scss'

interface ISearchResultEmptyProps {
    popularSearchItems: string[]
    lastSearches: string[]
    onClearLastSearches: () => void
    categories: ISearchCategory[]
}

const SearchResultEmpty = (
    {
        popularSearchItems,
        lastSearches,
        onClearLastSearches,
        categories,
    }: ISearchResultEmptyProps,
) => {
    const { t } = useTranslation()

    return (
        <div className={styles.wrapper}>
            <div className={classNames(styles.aside, !!categories?.length && styles.asideIndent)}>
                <SearchList
                    items={categories}
                    title={t('search.categories')}
                />
            </div>
            <div className={styles.content}>
                <SearchHistory
                    lastSearches={lastSearches}
                    onClearLastSearches={onClearLastSearches}
                    className={styles.history}
                />
                <SearchList
                    items={popularSearchItems}
                    title={t('search.popular.searches')}
                />
            </div>
        </div>
    )
}

export default SearchResultEmpty

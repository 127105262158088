import { IAbstractAddress, tAnyAddress } from '@pwa-onilab/ui/hooks'

const getRegionName = (address: tAnyAddress): IAbstractAddress['regionName'] => {
    switch (address.__typename) {
        case 'CartShippingAddresses':
        case 'CartBillingAddress': {
            return address.region?.label || ''
        }
        case 'CustomerAddress': {
            return address.region.region || ''
        }
        case 'CustomerOrderAddress': {
            return address.region || ''
        }
        default: {
            return null
        }
    }
}

export const getAbstractAddress = (address: tAnyAddress): IAbstractAddress => {
    const isCartAddress = address.__typename === 'CartBillingAddress' || address.__typename === 'CartShippingAddresses'

    return {
        firstName: address.firstName || null,
        middlename: (!isCartAddress && address.middleName) || null,
        lastName: address.lastName || null,
        street: address.street || null,
        city: address.city || null,
        company: address.company || null,
        telephone: address.telephone || null,

        regionName: getRegionName(address),
        postcode: (isCartAddress ? address.postCode : address.postcode) || null,
        countryId: (isCartAddress ? address.country?.code : address.countryCode) || null,
    }
}

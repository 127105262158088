import { FilterItem } from '@pwa-concept/modules/graphql'
import { COLOR_KEY, FilterOptionsModalOptions } from '@pwa-onilab/ui/components/modules/Filters'
import { IFilterOptions } from '@pwa-onilab/ui/components/modules/Filters/elements/FilterOptions/IFilterOptions'
import { useMemo } from 'react'

import styles from './FilterOptionsModalButtonDesktop.module.scss'

const FilterOptionsModalViewDesktop = ({ data: filter }: Pick<IFilterOptions, 'data'>) => {
    const isColor = filter?.items?.[0]?.key === COLOR_KEY

    const allOptionsByLetters = useMemo(() => {
        if (isColor) {
            return null
        }
        return (
            Object.entries<FilterItem[]>(
                filter?.items?.reduce<any>((all, current) => {
                    const letter = current?.name?.[0]?.toUpperCase?.()

                    if (!letter) {
                        return all
                    }

                    return {
                        ...all,
                        [letter]: [
                            ...(all?.[letter] || []),
                            current,
                        ],
                    }
                }, {}),
            )?.sort((a, b) => (
                a[0] > b[0] ? 1 : -1
            ))
        )
    }, [isColor, filter])

    return (
        <div className={styles.content}>
            {
                isColor
                    ? <FilterOptionsModalOptions options={filter.items} filter={filter} />
                    : allOptionsByLetters.map(([letter, options]) => {
                        return (
                            <FilterOptionsModalOptions
                                key={letter}
                                letter={letter}
                                options={options}
                                filter={filter}
                            />
                        )
                    })
            }
        </div>
    )
}

export default FilterOptionsModalViewDesktop

import { useMediaBreakpoint } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { FilterOptionsModalButtonDesktop, FilterOptionsModalButtonMobile } from '@pwa-onilab/ui/components/modules/Filters'
import { IFilterOptions } from '@pwa-onilab/ui/components/modules/Filters/elements/FilterOptions/IFilterOptions'

const FilterOptionsModalButton = (
    {
        data: filter,
        numResults,
    }: Pick<IFilterOptions, 'data' | 'numResults'>) => {
    const isMobile = useMediaBreakpoint().isBreakpointDown('lg')

    if (isMobile) {
        return <FilterOptionsModalButtonMobile data={filter} numResults={numResults} />
    }

    return <FilterOptionsModalButtonDesktop data={filter} />
}

export default FilterOptionsModalButton

import { MediaBreakpointDown, MediaBreakpointUp } from '@pwa-onilab/ui/components/elements/MediaBreakpoint'
import { DefaultModal } from '@pwa-onilab/ui/components/elements/Modal/DefaultModal'
import { DefaultModalProps } from '@pwa-onilab/ui/components/elements/Modal/DefaultModal/DefaultModal'
import { PortalProps } from '@pwa-onilab/ui/components/UI/Portal/Portal'
import { FC, lazy, PropsWithChildren, Suspense } from 'react'
import { BottomSheetProps } from "@pwa-onilab/ui/components/elements/Modal/BottomSheet";

export interface ModalProps extends DefaultModalProps, PortalProps, BottomSheetProps {
    isDraggable?: boolean
    animation?: FC<{ isOpen?: boolean }>
    isHideMobileCloseButton?: boolean
}

const BottomSheet = lazy(async () => await import('@pwa-onilab/ui/components/elements/Modal/BottomSheet/BottomSheet'))

// TODO. refactor isShowCloseButton and isHideMobileCloseButton on showCloseButtonDesktop and showCloseButtonMobile
// TODO wrapperClassName and className not equal into mobile and desktop variants. fix it
// TODO add variant smallCentered for default and BottomSheet, they variants should be the same or
//  variant should be renamed to DefaultModalVariant
const Modal = (
    {
        isDraggable,
        isOpen,
        onDismiss,
        variant,
        title,
        header,
        footer,
        children,
        animation, // TODO check how we use this prop, seems to be unused
        additionalStyles = {},
        wrapperClassName,
        className,
        titleClassName,
        headerClassName,
        footerClassName,
        mainClassName,
        root,
        snapPoints,
        isOverAll,
        isHideMobileCloseButton,
        isShowCloseButton,
        closeButtonClass,
        isUseBorderRadius,
        isUseDefaultPadding,
        isMobileScrollableMain,
        blocking,
        scrollLocking,
        initialFocusRef,
    }: PropsWithChildren<ModalProps>,
) => {
    const defaultModal = (
        <DefaultModal
            isOpen={isOpen}
            variant={variant}
            onDismiss={onDismiss}
            title={title}
            header={header}
            footer={footer}
            animation={animation}
            additionalStyle={additionalStyles}
            wrapperClassName={wrapperClassName}
            className={className}
            titleClassName={titleClassName}
            headerClassName={headerClassName}
            footerClassName={footerClassName}
            mainClassName={mainClassName}
            root={root}
            isShowCloseButton={isShowCloseButton}
            closeButtonClass={closeButtonClass}
            isUseBorderRadius={isUseBorderRadius}
            isUseDefaultPadding={isUseDefaultPadding}
            isOverAll={isOverAll}
            isMobileScrollableMain={isMobileScrollableMain}
        >
            {children}
        </DefaultModal>
    )

    return (
        <>
            <MediaBreakpointUp name="lg">
                {defaultModal}
            </MediaBreakpointUp>
            <MediaBreakpointDown name="lg">
                {
                    isDraggable
                        ? (
                            <Suspense fallback={null}>
                                <BottomSheet
                                    isOpen={isOpen}
                                    onDismiss={onDismiss}
                                    title={title}
                                    titleClassName={titleClassName}
                                    className={className}
                                    snapPoints={snapPoints}
                                    isOverAll={isOverAll}
                                    wrapperClassName={wrapperClassName}
                                    isShowCloseButton={isShowCloseButton && !isHideMobileCloseButton}
                                    isUseDefaultPadding={isUseDefaultPadding}
                                    closeButtonClass={closeButtonClass}
                                    header={header}
                                    headerClassName={headerClassName}
                                    footer={footer}
                                    blocking={blocking}
                                    scrollLocking={scrollLocking}
                                    initialFocusRef={initialFocusRef}
                                >
                                    {children}
                                </BottomSheet>
                            </Suspense>
                        )
                        : defaultModal
                }
            </MediaBreakpointDown>
        </>
    )
}

export default Modal

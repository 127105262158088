import Container from '@pwa-onilab/ui/components/UI/Container'
import Title from '@pwa-onilab/ui/components/UI/Title'
import { useTranslation } from 'react-i18next'

import styles from './StrainFamilyAllFamiliesTitle.module.scss'

const StrainFamilyAllFamiliesTitle = () => {
    const { t } = useTranslation()

    return (
        <Container isRevert>
            <Title
                type="h2"
                text={t('strains.familyPage.title')}
                className={styles.title}
            />
        </Container>
    )
}

export default StrainFamilyAllFamiliesTitle

import Modal from '@pwa-onilab/ui/components/elements/Modal'
import { ModalHeaderBackArrow } from '@pwa-onilab/ui/components/elements/Modal/elements'
import { PropsWithChildren } from 'react'

import styles from './GroupMobileModal.module.scss'

interface IGroupMobileModalProps {
    className: string
    isModalOpen: boolean
    closeModal: () => void
    title: string
}

const GroupMobileModal = (
    {
        children,
        isModalOpen,
        closeModal,
        title,
    }: Partial<PropsWithChildren<IGroupMobileModalProps>>,
) => (
    <Modal
        isOpen={isModalOpen}
        onDismiss={closeModal}
        variant="footerMenu"
        header={(
            <ModalHeaderBackArrow
                onDismiss={closeModal}
                className={styles.modalHeader}
                mainClassName={styles.modalHeaderTitle}
            >
                {title}
            </ModalHeaderBackArrow>
        )}
    >
        {children}
    </Modal>
)

export default GroupMobileModal

import {
    formatInputRangeFieldEvent,
    getValuesForInputRangeMinChanges,
    restoreFilterPriceInputCarriage,
} from '@pwa-onilab/ui/components/elements/Inputs/InputRange/helpers'
import useInputRangeRefValueListener from '@pwa-onilab/ui/components/elements/Inputs/InputRange/hooks'
import { InputRangeField } from '@pwa-onilab/ui/components/elements/Inputs/InputRange/IInputRange'
import { classNames } from '@pwa-onilab/ui/helpers'
import { useRef } from 'react'

import css from '../../InputRange.module.scss'

const InputRangeMin = (
    {
        min,
        max,
        onFinalChange,
        onChange: onChangeProp,
        currentMin,
        currentMax,
    }: InputRangeField) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const lastKeyCode = useRef<number | null>(null)

    const onKeyDown = (event) => {
        lastKeyCode.current = event.which || event.keyCode
    }
    const onChange = (event) => {
        if (!isFinite(event.target.value)) {
            restoreFilterPriceInputCarriage(event, currentMin)
            return
        }
        formatInputRangeFieldEvent(event, lastKeyCode.current, min, currentMax)
        onChangeProp(getValuesForInputRangeMinChanges(event, currentMax))
    }

    const onBlur = (ev) => {
        onFinalChange?.(getValuesForInputRangeMinChanges(ev, currentMax))
        lastKeyCode.current = null
    }

    useInputRangeRefValueListener(inputRef, currentMin)

    return (
        <div className={classNames(css.fieldsInput, css.fieldsFrom)}>
            <label className={css.currencyWrapper}>
                <input
                    ref={inputRef}
                    type="text"
                    min={min}
                    max={max}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    onChange={onChange}
                    className={css.input}
                />
            </label>
        </div>
    )
}

export default InputRangeMin

import Price from '@pwa-onilab/ui/components/UI/Price'
import { classNames } from '@pwa-onilab/ui/helpers'
import { IRenderThumbParams } from 'react-range/lib/types'

import css from '../../InputRange.module.scss'

const RangeThumb = ({ isDragged, value, props: args }: Omit<IRenderThumbParams, 'index'>) => {
    return (
        <div
            key={args?.key}
            style={args?.style}
            tabIndex={args?.tabIndex}
            aria-valuemax={args?.['aria-valuemax'] || ''}
            aria-valuemin={args?.['aria-valuemin'] || ''}
            aria-valuenow={args?.['aria-valuenow'] || ''}
            draggable={args?.draggable}
            ref={args?.ref}
            role={args?.role || ''}
            onKeyDown={args?.onKeyDown}
            onKeyUp={args?.onKeyUp}
            className={classNames(css.rangeThumb, isDragged && css.rangeThumbDragged)}
        >
            <div className={css.rangeThumbValue}>
                <Price>
                    {value}
                </Price>
            </div>
        </div>
    )
}

export default RangeThumb

import { IMenuObjData } from '@pwa-onilab/ui/components/modules/Menu'
import { classNames } from '@pwa-onilab/ui/helpers'
import { DropdownMenuAnchor } from 'modules/Menu'

import styles from './DropdownMenuList.module.scss'

interface IDropdownMenuListProps {
    className?: string
    items: IMenuObjData[]
    parentUrl: string
    parentTitle: string
    type: string
}

const DropdownMenuList = (
    {
        className,
        items,
        parentUrl,
        parentTitle,
        type,
    }: IDropdownMenuListProps,
) => {
    const itemsLength = items?.length

    return (
        <div className={styles.wrapper}>
            <DropdownMenuAnchor
                url={parentUrl}
                title={parentTitle}
                type={type}
                isBold
            />
            {
                itemsLength && (
                    <div className={classNames(styles.list, className)}>
                        {
                            items.map((
                                {
                                    title,
                                    url,
                                    nodeId,
                                    type,
                                }) => (
                                    <DropdownMenuAnchor
                                        type={type}
                                        key={nodeId}
                                        url={url}
                                        title={title}
                                    />
                            ))
                            }
                    </div>
                )
            }
        </div>

    )
}

export default DropdownMenuList

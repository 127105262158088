import { useStoreConfig } from '@pwa-concept/modules/store-config'
import { HOME_ROUTE } from '@pwa-onilab/ui/constants'
import { PageBackButton } from 'elements/BlogStatePages/elements'

const BlogPostPageBackButton = () => {
    const { data: { blogHomepageUrlKey } = { } } = useStoreConfig()

    return <PageBackButton urlKey={blogHomepageUrlKey || HOME_ROUTE} />
}

export default BlogPostPageBackButton

import { Pagination } from '@pwa-concept/modules/graphql'

const usePaginationPages = (pagination: Pagination, isShowAllPages?: boolean) => {
    const {
        current: currentPage,
        total: maxPage,
    } = pagination

    if (maxPage < 2) {
        return []
    }

    if (isShowAllPages) {
        const minPage = 2
        return Array.from({ length: maxPage - minPage }, (_, index) => index + minPage)
    }

    const MIN = Math.max((currentPage - 3), 1)
    const MAX = Math.min((currentPage + 2), (maxPage - 1))

    return Array.from({ length: MAX - MIN }, (_, index) => index + MIN + 1)
}

export default usePaginationPages

import { useCartModal } from '@pwa-onilab/ui/hooks'
import HomePage from '@pwa-onilab/ui/pages/HomePage'
import { useEffect } from 'react'

const CartDesktopNavigation = () => {
    const { openModal } = useCartModal()

    useEffect(() => {
        openModal()
    }, [])

    return <HomePage />
}

export default CartDesktopNavigation

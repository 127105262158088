const getUrlHost = (url) => url.split('/')[2]

// not used directly in theme yet
// but used in subprojects
export const checkIsPrevUrlFromOurSite = (prevFullUrl) => {
    const prevHost = getUrlHost(prevFullUrl)
    const curHost = getUrlHost(window.location.href)

    return prevHost === curHost
}

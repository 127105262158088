import { paginationLoadedPagesSet } from '@pwa-onilab/ui/components/modules/Pagination/paginationInterfaces'
import { Dispatch, SetStateAction, useEffect } from 'react'

export const usePaginationLoadedPages = <T = any> (
    loadedPagesSet: paginationLoadedPagesSet,
    setClientPage: Dispatch<SetStateAction<number>>,
    setServerPage: Dispatch<SetStateAction<number>>,
    setCachedData: Dispatch<SetStateAction<T>>,
    triggerInput: any,
) => {
    useEffect(() => {
        return () => {
            loadedPagesSet.clear()
        }
    }, [])

    useEffect(() => {
        if (loadedPagesSet.size === 0) {
            return
        }
        setClientPage(1)
        setServerPage(1)
        setCachedData(null)

        loadedPagesSet.clear()
    }, [triggerInput])
}

import { makeVar } from '@apollo/client'
import css from '@pwa-onilab/ui/styles/vars/_breakpoints.scss'

const BREAKPOINTS = (
    css.gridBreakpoints
        .replace(/(px|rem)/igm, '')
        .split(',')
        .map((value) => (
            value
                .trim()
                .split(' ')
        ))
        .reverse()
)

const currentBreakpointIndex = makeVar(null)

export { BREAKPOINTS, currentBreakpointIndex }

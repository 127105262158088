import { Button } from '@pwa-onilab/ui/components/UI/Button'
import Icon from '@pwa-onilab/ui/components/UI/Icon'
import { classNames } from '@pwa-onilab/ui/helpers'
import { PropsWithChildren } from 'react'

import styles from './WishlistShareViaEmailButton.module.scss'

interface WishlistShareViaEmailButtonProps {
    className: string
    onClick: () => void
}

const WishlistShareViaEmailButton = (
    {
        children,
        className,
        onClick,
    }: Partial<PropsWithChildren<WishlistShareViaEmailButtonProps>>,
) => (
    <Button
        isBlackTransparent
        className={classNames(styles.button, className)}
        onClick={onClick}
    >
        <Icon
            className={styles.share}
            name="share"
        />
        {
            children && (
                <div className={styles.text}>
                    {children}
                </div>
            )
        }
    </Button>
)

export default WishlistShareViaEmailButton

import api from '@pwa-concept/core/api'
import { QueryResolvers } from '@pwa-concept/modules/graphql'
import { gql } from 'graphql-tag'

// TODO seems like filter by order number no needed here
const customerOrders: QueryResolvers['customerOrders'] = async (_, { input }) => {
    const {
        page: currentPage,
        size: pageSize,
        number,
    } = input
    const { data: { customer = null } = {} } = await (
        api.graphql(
            gql`
                query Customer ($pageSize: Int, $currentPage: Int, $id: String) {
                    customer {
                        orders(pageSize: $pageSize, currentPage: $currentPage, filter: { number: { eq: $id } } ) {
                            items {
                                ... CustomerOrder
                            }
                            page_info {
                                current_page
                                page_size
                                total_pages
                            }
                            total_count
                        }
                    }
                }
            `,
        ).variableIf(
            !!number,
            () => ({
                filter: {
                    number: {
                        eq: number,
                    },
                },
            }),
        ).query({
            currentPage,
            pageSize,
        })
    )

    if (!customer?.orders?.items?.length) return null

    return {
        items: customer.orders.items.map((__context) => ({ __context, __typename: 'CustomerOrder' })),
        pagination: {
            count: customer.orders.total_count,
            current: customer.orders.page_info?.current_page,
            limit: customer.orders.page_info?.page_size,
            total: customer.orders.page_info?.total_pages,
            __typename: 'Pagination',
        },
    }
}

export default customerOrders

import { useCustomerAddresses } from '@pwa-concept/modules/customer-addresses'
import { CustomerAddress } from '@pwa-concept/modules/graphql'
import { useMemo } from 'react'

interface ICustomerDefaultAddressesResponse {
    data: {
        defaultShippingAddress: CustomerAddress
        defaultBillingAddress: CustomerAddress
    }
    loading: boolean
}

const useCustomerDefaultAddresses = (): ICustomerDefaultAddressesResponse => {
    const { addresses, isEmptyAddressBook, loading } = useCustomerAddresses()

    const defaultAddresses = useMemo(() => {
        if (loading || (!loading && isEmptyAddressBook)) {
            return null
        }

        let isBillingFounded = false
        let isShippingFounded = false
        const data = {
            defaultShippingAddress: null,
            defaultBillingAddress: null,
        }

        for (let i = 0; i < addresses.length; i += 1) {
            const currentAddress = addresses[i]

            if (currentAddress.defaultBilling) {
                isBillingFounded = true
                data.defaultBillingAddress = currentAddress
            }

            if (currentAddress.defaultShipping) {
                isShippingFounded = true
                data.defaultShippingAddress = currentAddress
            }

            if (isBillingFounded && isShippingFounded) {
                return data
            }
        }

        return data
    }, [addresses, isEmptyAddressBook, loading])

    return {
        data: defaultAddresses,
        loading,
    }
}

export default useCustomerDefaultAddresses

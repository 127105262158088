import { Breadcrumbs } from '@pwa-onilab/ui/components/modules/Breadcrumbs'

interface ICmsPageBreadcrumbsProps {
    className: string
    currentCmsPageTitle: string
}

const CmsPageBreadcrumbs = (
    {
        className,
        currentCmsPageTitle,
    }: Partial<ICmsPageBreadcrumbsProps>,
) => (
    <Breadcrumbs
        className={className}
        items={[{ name: currentCmsPageTitle }]}
    />
)

export default CmsPageBreadcrumbs

import { InputField } from '@pwa-onilab/ui/components/elements/Fields'
import {
    AccountDeleteFormDescription,
    AccountDeleteFormTitle,
    useAccountDeleteForm,
    useSendPinCodeForDeleteAccount,
} from '@pwa-onilab/ui/components/modules/AccountSubpages'
import styles from '@pwa-onilab/ui/components/modules/AccountSubpages/AccountDeletePage/components/AccountDeleteForm/AccountDeleteForm.module.scss'
import Loader from '@pwa-onilab/ui/components/UI/Loader'
import { Button } from '@pwa-onilab/ui/components/UI/Button'


import { useTranslation } from 'react-i18next'

const AccountDeleteForm = () => {
    const { t } = useTranslation()
    const isPinCodeSending = useSendPinCodeForDeleteAccount()

    const { formValues, onSubmit } = useAccountDeleteForm()
    const { register, setValue, handleSubmit, formState: { errors, isSubmitting } } = formValues

    return (
        <div className={styles.wrapper}>
            <AccountDeleteFormTitle />
            <AccountDeleteFormDescription />
            {/* eslint-disable-next-line  @typescript-eslint/no-misused-promises */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputField
                    className={styles.input}
                    placeholder={t('AccountPage.delete.form.pin')}
                    setValue={setValue}
                    field="pin"
                    register={register}
                    errors={errors}
                    rules={{ required: t('global.errors.required') }}
                />
                <Button
                    isBlackTransparent
                    type="submit"
                    isFullWidth
                    isBold
                    disabled={isSubmitting}
                >
                    {t('global.apply')}
                </Button>
            </form>
            <Loader visible={isPinCodeSending || isSubmitting} isAbsolute />
        </div>
    )
}

export default AccountDeleteForm

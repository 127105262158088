import { $wishlist } from '@pwa-concept/core/models'
import { IApolloMutationsBaseOptions } from '@pwa-concept/modules/common'
import { useWishlistQuery } from '@pwa-concept/modules/wishlist/graphql/queries/Wishlist'

interface IWishlistInputData {
    currentPage?: number
    pageSize?: number
    onCompleted?: (values) => void
    skip?: boolean
}

// FIXME after logout send 2 requests
const useWishlist = (input?: IWishlistInputData, baseOptions?: IApolloMutationsBaseOptions) => {
    const {
        currentPage = 1,
        pageSize = 20,
        onCompleted,
        skip,
    } = input || {}

    const { data: { wishlist = null } = {}, loading } = useWishlistQuery({
        ...baseOptions,
        variables: {
            id: $wishlist.id,
            limit: pageSize,
            page: currentPage,
        },
        skip,
        onCompleted,
    })

    return {
        wishlist,
        loading,
    }
}

export default useWishlist
